import { MandatoryTypes } from './constants';
import type { CourseState } from './types';
import type {
  MandatoryDueByType,
  MandatoryNoDueDateType,
  MandatoryRecurringCompletionDate,
  MandatoryRecurringStaticDate,
  MandatoryRecurringPublishDate,
  MandatoryRelativeDueByType,
  MandatoryType
} from '@edapp/authoring-logic';

export const findCourseIdByLessonId = (
  lessonId: string,
  courses: CourseState['courses']
): string | undefined => {
  for (const key of Object.keys(courses)) {
    const course = courses[key];
    const lesson = course.original.lessons.find(l => l.id === lessonId);
    if (!!lesson) {
      return course.original.id;
    }
  }

  return undefined;
};

export const isNewCourse = (id: string) => id === 'new';

// -----------------------------------------------------------------------------------
// ------------------------------ M A N D A T O R Y ----------------------------------
// -----------------------------------------------------------------------------------
/**
 *  Check if mandatory type is set to Fixed Due Date
 * @param mandatory
 * @returns True if mandatory type is "due-by"
 */
export const getIsDueBy = (mandatory: MandatoryType): mandatory is MandatoryDueByType =>
  mandatory.type === MandatoryTypes.DueBy;

/**
 *  Check if mandatory type is set to No Due Date [Not Fixed/RDD/Recurring]
 * @param mandatory
 * @returns True if mandatory type is "no-due-date"
 */
export const getIsNoDueDate = (mandatory: MandatoryType): mandatory is MandatoryNoDueDateType =>
  mandatory.type == MandatoryTypes.NoDueDate;

/**
 *  Check if mandatory type is set to Relative Due Date [RDD]
 * @param mandatory
 * @returns True if mandatory type is "relative-due-by"
 */
export const getIsRelativeDueBy = (
  mandatory: MandatoryType
): mandatory is MandatoryRelativeDueByType => mandatory.type === MandatoryTypes.RelativeDueBy;

/**
 *  Check if mandatory type is set to Recurring after Completion Date
 * @param mandatory
 * @returns True if mandatory type is "recurring-completion-date"
 */
export const getIsRecurringAfterCompletion = (
  mandatory: MandatoryType
): mandatory is MandatoryRecurringCompletionDate =>
  mandatory.type === MandatoryTypes.RecurringCompletionDate;

/**
 *  Check if mandatory type is set to Recurring after Publish Date
 * @param mandatory
 * @returns True if mandatory type is "recurring-publish-date"
 */
export const getIsRecurringAfterPublish = (
  mandatory: MandatoryType
): mandatory is MandatoryRecurringPublishDate =>
  mandatory.type === MandatoryTypes.RecurringPublishDate;

/**
 *  Check if mandatory type is set to Recur on Static Date
 * @param mandatory
 * @returns True if mandatory type is "recurring-static-date"
 */
export const getIsRecurringStaticDate = (
  mandatory: MandatoryType
): mandatory is MandatoryRecurringStaticDate =>
  mandatory.type === MandatoryTypes.RecurringStaticDate;

/**
 * Check whether course is set up as Mandatory
 * @param mandatory
 * @returns True: If The Mandatory Type is Mandatory: 'due-by' or 'relative-due-by' or 'no-due-date' & enabled: true
 */
export const getIsMandatory = (
  mandatory: MandatoryType
): mandatory is MandatoryNoDueDateType | MandatoryDueByType | MandatoryRelativeDueByType =>
  mandatory.enabled &&
  (mandatory.type === MandatoryTypes.NoDueDate ||
    mandatory.type === MandatoryTypes.DueBy ||
    mandatory.type === MandatoryTypes.RelativeDueBy);

/**
 * Check whether course is set up as Optional
 * @param mandatory
 * @returns True: If The Mandatory Type is no-due-date & enabled: false
 */
export const getIsOptional = (mandatory: MandatoryType): mandatory is MandatoryNoDueDateType =>
  !mandatory.enabled && mandatory.type === MandatoryTypes.NoDueDate;

/**
 * Check whether course is set up as Recurring
 * @param mandatory
 * @returns True: If The Mandatory Type is 'recurring-completion-date' or 'recurring-publish-date' or 'recurring-static-date'
 */
export const getIsRecurring = (
  mandatory: MandatoryType
): mandatory is
  | MandatoryRecurringPublishDate
  | MandatoryRecurringCompletionDate
  | MandatoryRecurringStaticDate =>
  mandatory.type === MandatoryTypes.RecurringPublishDate ||
  mandatory.type === MandatoryTypes.RecurringCompletionDate ||
  mandatory.type === MandatoryTypes.RecurringStaticDate;

/**
 * Check whether the mandatory setting has DueDate enabled (Fixed DD / Relative DD)
 * @param mandatory
 * @returns True: If The Mandatory Type has DueDate option Enabled
 */
export const getIsDueDateEnabled = (
  mandatory: MandatoryType
): mandatory is
  | MandatoryDueByType
  | MandatoryRelativeDueByType
  | MandatoryRecurringPublishDate
  | MandatoryRecurringCompletionDate
  | MandatoryRecurringStaticDate =>
  mandatory.type === MandatoryTypes.DueBy ||
  mandatory.type === MandatoryTypes.RelativeDueBy ||
  mandatory.type === MandatoryTypes.RecurringPublishDate ||
  mandatory.type === MandatoryTypes.RecurringCompletionDate ||
  mandatory.type === MandatoryTypes.RecurringStaticDate;
