import * as React from 'react';
import styled, { css } from 'styled-components';
import type { DropdownItemProps } from './types';

export const DROPDOWN_ITEM_TEST_ID = 'dropdown-item';

export const DropdownItem = React.forwardRef<HTMLElement, DropdownItemProps>((props, ref) => {
  const {
    disabled = false,
    isDisabled = false,
    as = 'li',
    testId = DROPDOWN_ITEM_TEST_ID,
    onClick,
    children,
    ...rest
  } = props;

  const internalDisabled = !!(disabled || isDisabled);

  const handleClick = onClick && !internalDisabled ? onClick : undefined;

  return (
    <Wrapper
      ref={ref}
      as={as}
      onClick={handleClick}
      isDisabled={internalDisabled}
      aria-disabled={internalDisabled}
      data-testid={testId}
      {...rest}
    >
      {children}
    </Wrapper>
  );
});

const Wrapper = styled.li<DropdownItemProps>(({ theme, onClick, href, isDisabled }) => {
  const isInteractable = !isDisabled && (onClick || href);

  return css`
    color: ${isDisabled ? theme.colors.textMuted : theme.colors.text};
    background: ${isDisabled ? theme.colors.lightGrey : theme.colors.white};
    padding: ${theme.space(0.75)}px ${theme.space(3)}px ${theme.space(0.75)}px ${theme.space(1.5)}px;
    cursor: default;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    user-select: none;

    :hover,
    :active,
    :focus {
      ${isDisabled
        ? css`
            color: ${theme.colors.textMuted};
          `
        : ''};
      text-decoration: none;
    }

    ${isInteractable
      ? css`
          cursor: pointer;
          :hover {
            background: ${theme.colors.lightBlue};
            text-decoration: none;
          }
        `
      : ''}

    &:first-of-type {
      margin-top: ${theme.space(0.5)}px;
    }
    &:last-of-type {
      margin-bottom: ${theme.space(0.5)}px;
    }
  `;
});
