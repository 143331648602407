import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-styled-flexboxgrid';

import { ErrorFallbackUI } from '@edapp/courseware-ui';
import { Select, useDebounce } from '@edapp/ed-components';
import { SearchableControl } from '@edapp/ed-components/src/common/form/select/custom-components';
import { useSearchCatalogs } from '@edapp/hippo-client';
import type { EdExceptionType } from '@edapp/request';
import { useGetUserGroupCatalogDetails } from '@rio/api/user-groups/catalogs';
import { setUserGroupItem } from '@rio/store/userGroups/actions';
import { getUserGroupId } from '@rio/store/userGroups/selectors';

import type { HandleChange, HandleInputChange, Option, OptionValue } from './types';
import { selectOptions, selectValues } from './utils';

export const Catalogs: React.FC = () => {
  const usergroupId = useSelector(getUserGroupId);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [title, setTitle] = React.useState<string | undefined>(undefined);
  const debouncedTitle = useDebounce(title, 1000);

  const [values, setValues] = React.useState<Option[] | undefined>(undefined);

  const handleChange = React.useCallback<HandleChange>(
    values => {
      setValues(values ?? []);
      dispatch(
        setUserGroupItem({
          usergroup: { catalogsCanAccess: values?.map(({ value }) => value) ?? [] }
        })
      );
    },
    [dispatch]
  );

  const handleInputChange = React.useCallback<HandleInputChange>(
    value => {
      setTitle(value);
    },
    [setTitle]
  );

  const { isLoading: isLoadingCatalogDetails } = useGetUserGroupCatalogDetails(usergroupId, {
    enabled: !!usergroupId,
    staleTime: Infinity,
    select: selectValues,
    onSuccess: data => {
      if (!values) {
        setValues(data);
      }
    }
  });

  const { data: catalogs = [], isLoading, error } = useSearchCatalogs(
    { searchTerm: debouncedTitle },
    { select: selectOptions }
  );

  return (
    <Row id="catalogs">
      <Col sm={9}>
        <label>{t('user-groups.course-collections.title')}</label>

        <p className="help-block">{t('user-groups.course-collections.description')}</p>

        {!!error ? (
          <ErrorFallbackUI
            text={(error as EdExceptionType)?.errorResponse?.message ?? error.message}
          />
        ) : (
          <Select<OptionValue>
            isMulti={true}
            value={values}
            options={catalogs}
            placeholder={t('user-groups.course-collections.search-placeholder')}
            menuPortalTarget={document.body}
            isLoading={isLoading || isLoadingCatalogDetails}
            isDisabled={isLoading || !usergroupId}
            onChange={handleChange}
            onInputChange={handleInputChange}
            noOptionsMessage={() => t('courses.import.no-results')}
            loadingMessage={() => t('user-groups.course-collections.search-placeholder-loading')}
            filterOption={({ value }) => !!values && values.every(catalog => catalog.id !== value)}
            components={{
              IndicatorSeparator: undefined,
              DropdownIndicator: undefined,
              Control: SearchableControl
            }}
          />
        )}
      </Col>
    </Row>
  );
};
