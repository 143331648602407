import type { BetterPptxState } from './types';
import { DeviceType } from '@edapp/authoring-ui';
import type { StyleConfiguration } from '@edapp/authoring-logic';

export const defaultLessonConfig: StyleConfiguration = {
  background: '',
  colors: {},
  enableCustomCSS: false,
  customCSS: '',
  direction: 'ltr',
  disableInteractionSummary: true,
  disableLessonScore: true,
  hasAnswerFeedback: true,
  hasScormExitButton: false,
  preventLogoutOnExit: false,
  indicator: true,
  logo: '',
  minimumScore: 0,
  pageNumbers: true,
  pagination: true,
  language: 'en',
  soundDisabled: false,
  preferences: {
    useParentBackgroundImage: true,
    useParentColors: true,
    useParentCustomCss: true,
    useParentLogo: true
  }
};

export const initialBetterPptxState: BetterPptxState = {
  loading: false,
  error: false,
  previewMode: false,
  selectedDevice: DeviceType.PHONE,
  courseId: '',
  lessons: [],
  selectedLessonIndex: -1,
  selectedSlideIndex: -1,
  selectedSlides: [],
  pptSlides: []
};
