import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import type { Props } from './types';

export const Arrow = styled.div`
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
`;

type TooltipContentProps = Required<Pick<Props, 'size' | 'bgColor' | 'color'>>;

export const TooltipContent = styled.div<TooltipContentProps>(
  ({ theme, size, bgColor, children, color }) => css`
    color: ${theme.colors[color]};
    background: ${theme.colors[bgColor]};
    font-family: ${theme.fontFamily};
    font-size: ${theme.fontSizes[0]}px;
    font-weight: ${theme.fontWeights[0]};
    line-height: ${theme.lineHeights[1]}px;
    border-radius: ${theme.sizes.borderRadius}px;
    user-select: none;
    ${typeof children === 'string' && `max-width:  203px; white-space: normal;`};
    ${() => {
      switch (size) {
        case 'md':
          return `padding: ${theme.space()}px;`;
        case 'xs':
          return `padding: ${theme.space(0.5)}px ${theme.space()}px;`;
        case 'sm':
        default:
          return `padding: ${theme.space(1.5)}px ${theme.space()}px;`;
      }
    }}
  `
);

type PlacementContainerProps = Required<Pick<Props, 'size' | 'bgColor' | 'showArrow'>> &
  Pick<Props, 'dropShadow' | 'maxWidth'>;

export const PlacementContainer = styled(motion.div)<PlacementContainerProps>(
  ({ theme, bgColor, dropShadow, size, showArrow, maxWidth }) => {
    const arrowWidth = size === 'md' ? theme.space() : theme.space(0.5);
    const margin = size === 'md' ? theme.space(1.5) : theme.space();

    return css`
      ${dropShadow && `filter: ${theme.dropShadows[dropShadow]};`}
      ${maxWidth && `max-width: ${maxWidth};`}
      ${
        showArrow === false &&
        css`
          ${Arrow} {
            display: none;
          }
        `
      }
      position: absolute;
      z-index: 1070;

      &[data-placement^='top'] {
        ${TooltipContent} {
          margin-bottom: ${margin}px;
        }

        ${Arrow} {
          border-width: ${arrowWidth}px ${arrowWidth}px 0;
          border-top-color: ${theme.colors[bgColor]};
          margin-bottom: ${arrowWidth}px;
          bottom: 0;
          margin-top: 0;
        }
      }

      &[data-placement^='right'] {
        ${TooltipContent} {
          margin-left: ${margin}px;
          button {
            top: -${theme.space(0.25)}px;
            right: -${theme.space(0.25)}px;
            transition: none;
          }
        }

        ${Arrow} {
          left: 0;
          border-width: ${arrowWidth}px ${arrowWidth}px ${arrowWidth}px 0;
          border-right-color: ${theme.colors[bgColor]};
          margin-left: ${arrowWidth}px;
          margin-right: 0;
        }
      }

      &[data-placement^='bottom'] {
        ${TooltipContent} {
          margin-top: ${margin}px;
        }

        ${Arrow} {
          border-width: 0 ${arrowWidth}px ${arrowWidth}px;
          border-bottom-color: ${theme.colors[bgColor]};
          top: 0;
          margin-top: ${arrowWidth}px;
          margin-bottom: 0;
        }
      }

      &[data-placement^='left'] {
        ${TooltipContent} {
          margin-right: ${margin}px;
          button {
            top: -${theme.space(0.25)}px;
            right: -${theme.space(0.25)}px;
            transition: none;
          }
        }

        ${Arrow} {
          border-width: ${arrowWidth}px 0 ${arrowWidth}px ${arrowWidth}px;
          border-left-color: ${theme.colors[bgColor]};
          right: 0;
          margin-left: 0;
          margin-right: ${arrowWidth}px;
        }
      }
    `;
  }
);
