import type { EdErrorResponseType } from '@edapp/authoring-logic';
import type { RapidRefreshFilterType, RapidRefreshSortType } from '@edapp/hippo-client';
import { createAction } from '@edapp/utils';

import type {
  FetchRapidRefreshConfigAction,
  FetchRapidRefreshLessonPreviewAction,
  RapidRefreshConfigType,
  SaveRapidRefreshConfigAction,
  SaveRapidRefreshConfigFailureAction,
  SaveRapidRefreshConfigSuccessAction,
  SetRapidRefreshConfigAction,
  SetRapidRefreshFilterAction,
  SetRapidRefreshSortAction
} from './types';

export enum RapidRefreshActionTypes {
  SET_RAPID_REFRESH_CONFIG = 'SET_RAPID_REFRESH_CONFIG',
  SAVE_RAPID_REFRESH_CONFIG = 'SAVE_RAPID_REFRESH_CONFIG',
  SAVE_RAPID_REFRESH_CONFIG_SUCCESS = 'SAVE_RAPID_REFRESH_CONFIG_SUCCESS',
  SAVE_RAPID_REFRESH_CONFIG_FAILURE = 'SAVE_RAPID_REFRESH_CONFIG_FAILURE',

  FETCH_RAPID_REFRESH_CONFIG = 'FETCH_RAPID_REFRESH_CONFIG',
  FETCH_RAPID_REFRESH_CONFIG_SUCCESS = 'FETCH_RAPID_REFRESH_CONFIG_SUCCESS',
  FETCH_RAPID_REFRESH_CONFIG_FAILURE = 'FETCH_RAPID_REFRESH_CONFIG_FAILURE',

  FETCH_RAPID_REFRESH_LESSON_PREVIEW = 'FETCH_RAPID_REFRESH_LESSON_PREVIEW',
  FETCH_RAPID_REFRESH_LESSON_PREVIEW_SUCCESS = 'FETCH_RAPID_REFRESH_LESSON_PREVIEW_SUCCESS',
  FETCH_RAPID_REFRESH_LESSON_PREVIEW_FAILURE = 'FETCH_RAPID_REFRESH_LESSON_PREVIEW_FAILURE',

  SET_RAPID_REFRESH_FILTER = 'SET_RAPID_REFRESH_FILTER',
  SET_RAPID_REFRESH_SORT = 'SET_RAPID_REFRESH_SORT'
}

export const RapidRefreshActions = {
  fetchRapidRefreshConfig: (id: string): FetchRapidRefreshConfigAction =>
    createAction(RapidRefreshActionTypes.FETCH_RAPID_REFRESH_CONFIG, { id }),

  setRapidRefreshConfig: (
    id: string,
    partialData: Partial<RapidRefreshConfigType>
  ): SetRapidRefreshConfigAction =>
    createAction(RapidRefreshActionTypes.SET_RAPID_REFRESH_CONFIG, {
      id,
      partialData
    }),

  saveRapidRefreshConfig: (
    id: string,
    oldState: RapidRefreshConfigType,
    partialData: Partial<RapidRefreshConfigType>
  ): SaveRapidRefreshConfigAction =>
    createAction(RapidRefreshActionTypes.SAVE_RAPID_REFRESH_CONFIG, {
      id,
      oldState,
      partialData
    }),

  saveRapidRefreshConfigSuccess: (
    payload: RapidRefreshConfigType
  ): SaveRapidRefreshConfigSuccessAction =>
    createAction(RapidRefreshActionTypes.SAVE_RAPID_REFRESH_CONFIG_SUCCESS, payload),

  saveRapidRefreshConfigFailure: (
    id: string,
    oldState: RapidRefreshConfigType,
    error: EdErrorResponseType
  ): SaveRapidRefreshConfigFailureAction =>
    createAction(RapidRefreshActionTypes.SAVE_RAPID_REFRESH_CONFIG_FAILURE, {
      id,
      oldState,
      error
    }),

  setRapidRefreshFilter: (
    filter: RapidRefreshFilterType | undefined
  ): SetRapidRefreshFilterAction =>
    createAction(RapidRefreshActionTypes.SET_RAPID_REFRESH_FILTER, {
      filter
    }),

  setRapidRefreshSort: (sort: RapidRefreshSortType | undefined): SetRapidRefreshSortAction =>
    createAction(RapidRefreshActionTypes.SET_RAPID_REFRESH_SORT, {
      sort
    }),

  fetchRapidRefreshLessonPreview: (id: string): FetchRapidRefreshLessonPreviewAction =>
    createAction(RapidRefreshActionTypes.FETCH_RAPID_REFRESH_LESSON_PREVIEW, { id })
};
