/**
 * Any component using this hook must be wrapped inside a styled-components ThemeProvider
 */

import { useContext } from 'react';
import { ThemeContext } from 'styled-components';

export function useTheme() {
  return useContext(ThemeContext);
}
