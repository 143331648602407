import {
  SEND_PUSH_NOTIFICATION_BUTTON_TEXT,
  SEND_PUSH_NOTIFICATION_BUTTON_TEXT_SUCCESS,
  SEND_PUSH_NOTIFICATION_BUTTON_VARIANT,
  SEND_PUSH_NOTIFICATION_BUTTON_VARIANT_SUCCESS
} from './helpers';

import type { PushNotificationState, PushNotificationActions } from './types';

export default function reducer(state: PushNotificationState, action: PushNotificationActions) {
  switch (action.type) {
    case 'CLOSE_DIALOG':
      return { ...state, dialogOpen: false };
    case 'OPEN_DIALOG':
      return { ...state, dialogOpen: true };
    case 'SET_TITLE':
      return { ...state, title: action.title };
    case 'SET_BODY':
      return { ...state, body: action.body };
    case 'BLUR_TITLE':
      return { ...state, showTitleValidationError: !state.title };
    case 'BLUR_BODY':
      return { ...state, showBodyValidationError: !state.body };
    case 'NOTIFICATION_SENT':
      return {
        ...state,
        dialogOpen: false,
        notificationSent: true,
        sendPushNotificationButtonText: SEND_PUSH_NOTIFICATION_BUTTON_TEXT_SUCCESS,
        sendPushNotificationButtonVariant: SEND_PUSH_NOTIFICATION_BUTTON_VARIANT_SUCCESS
      };
    case 'RESET_NOTIFICATION_BUTTON_SENT':
      return {
        ...state,
        notificationSent: false,
        sendPushNotificationButtonText: SEND_PUSH_NOTIFICATION_BUTTON_TEXT,
        sendPushNotificationButtonVariant: SEND_PUSH_NOTIFICATION_BUTTON_VARIANT
      };
    default:
      return state;
  }
}
