import { generatePath } from 'react-router';
import { fork, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import { getEmilyCsrfToken } from '@rio/api/hooks';
import { userGroupRoutes } from '@rio/containers/UserGroups/UserGroups';
import type { LmsStoreState } from '@rio/store/types';

import { ActionType, fetchUserGroupStatus } from './actions';
import { createUserGroupConditionsSuccess, fetchUserGroupConditions } from './conditions/actions';
import {
  ActionType as ConditionsActionType,
  createUserGroupConditions,
  updateUserGroupConditions
} from './conditions/actions';
import { sagas as conditionsSagas } from './conditions/sagas';
import type { DynamicUserGroupConditionsPayload } from './conditions/types';
import { fetchUserGroupDraws } from './draws/actions';
import { sagas as drawsSagas } from './draws/sagas';
import type {
  FetchUserGroupAction,
  FetchUserGroupSuccessAction,
  SaveUserGroupAction,
  SaveUserGroupSuccessAction,
  UserGroupItemTypeRedux,
  UserGroupSavePayload
} from './types';
import { isValidCondition } from './utils';

function* handleFetchUserGroup({ payload: { id } }: FetchUserGroupAction) {
  const csrf = getEmilyCsrfToken();

  yield put(
    RequestActions.getAuthed(
      `/v2/user-group/${id}/data`,
      ActionType.FETCH_USER_GROUP_SUCCESS,
      ActionType.FETCH_USER_GROUP_FAILURE,
      true,
      { _csrf: csrf }
    )
  );

  const { success }: { success?: FetchUserGroupSuccessAction } = yield race({
    success: take(ActionType.FETCH_USER_GROUP_SUCCESS),
    failure: take(ActionType.FETCH_USER_GROUP_FAILURE)
  });

  if (!!success) {
    yield put(fetchUserGroupDraws());

    if (success.payload.usergroup.isDynamicGroup) {
      yield put(fetchUserGroupConditions(id));
    }
  }
}

function* handleFetchUserGroupStatus({ payload: { id } }: FetchUserGroupAction) {
  if (id === 'new') {
    return;
  }

  yield put(
    RequestActions.getAuthed(
      `/api/usergroups/${id}/status`,
      ActionType.FETCH_USER_GROUP_STATUS_SUCCESS,
      ActionType.FETCH_USER_GROUP_STATUS_FAILURE
    )
  );
}

function* handleSaveUserGroup({ payload: { id, history } }: SaveUserGroupAction) {
  const { app, usergroup, userIds }: UserGroupItemTypeRedux = yield select(
    (state: LmsStoreState) => state.userGroups.userGroupItem
  );

  const { registrationSettings } = usergroup;

  const csrf = getEmilyCsrfToken();

  const emilyPayload: UserGroupSavePayload = {
    _csrf: csrf,
    application: { _id: app._id },
    user_application: app._id,
    _id: id,
    usergroup: {
      _id: id,
      app: app._id,
      name: usergroup.name,
      freezeUsers: !!usergroup.freezeUsers ? 'on' : 'off',
      userGroupContains: usergroup.userGroupContains,
      users: usergroup.userGroupContains === 'users' ? [...userIds] : [],
      groups: usergroup.userGroupContains === 'groups' ? [...usergroup.groups] : [],
      isAccountGroup: usergroup.isAccountGroup,
      isDynamicGroup: usergroup.isDynamicGroup,
      courses: [...usergroup.courses],
      draws: [...usergroup.draws],
      managers: [...usergroup.managers],
      catalogsCanAccess: [...usergroup.catalogsCanAccess],
      registrationSettings: {
        limits: {
          always_require_verification: !!registrationSettings.limits.always_require_verification
            ? 'on'
            : 'off',
          enabled: !!registrationSettings.limits.enabled ? 'on' : 'off',
          max: registrationSettings.limits.max
        }
      },
      lottery: {
        enableSpinToWin: !!usergroup.lottery.enableSpinToWin ? 'on' : 'off',
        enabled: !!usergroup.lottery.enabled ? 'on' : 'off',
        starBar: usergroup.lottery.starBar
      }
    }
  };

  yield put(
    RequestActions.postAuthed(
      `/v2/user-group/${id}/save`,
      ActionType.SAVE_USER_GROUP_SUCCESS,
      ActionType.SAVE_USER_GROUP_FAILURE,
      true,
      emilyPayload,
      undefined,
      true
    )
  );

  const { successEmily, failureEmily } = yield race({
    successEmily: take(ActionType.SAVE_USER_GROUP_SUCCESS),
    failureEmily: take(ActionType.SAVE_USER_GROUP_FAILURE)
  });

  if (failureEmily) {
    // It's being handled in the reducer
    return;
  }

  const { _id } = (successEmily as SaveUserGroupSuccessAction).payload;

  // Now, after we saved the user group - we are going to check if it's dynamic
  // If yes, we are going to call hippo - because emily couldn't do the job that
  // dynamic user group requires (parsing the conditions request body)
  if (usergroup.isDynamicGroup) {
    const hippoPayload: DynamicUserGroupConditionsPayload = {
      condition: {
        logicalTypeId: 'all',
        predicates: (usergroup.conditions || []).filter(isValidCondition).map(i => ({
          targetId: i.target.id,
          operatorId: i.operatorId,
          arguments: i.arguments,
          argumentTypeId: i.argumentTypeId
        }))
      }
    };

    const methodRequest = id !== 'new' ? updateUserGroupConditions : createUserGroupConditions;
    yield put(methodRequest(_id, hippoPayload));

    const { failureHippo } = yield race({
      successHippo: take([
        ConditionsActionType.CREATE_USER_GROUP_CONDITIONS_SUCCESS,
        ConditionsActionType.UPDATE_USER_GROUP_CONDITIONS_SUCCESS
      ]),
      failureHippo: take([
        ConditionsActionType.CREATE_USER_GROUP_CONDITIONS_FAILURE,
        ConditionsActionType.UPDATE_USER_GROUP_CONDITIONS_FAILURE
      ])
    });

    if (failureHippo) {
      // It's being handled in the reducer
      return;
    }

    // trigger/dispatch check status
    yield put(fetchUserGroupStatus(_id));
  } else {
    // we need to trigger succes, because the UI is waiting for the hippo success to finish the loading state
    yield put(createUserGroupConditionsSuccess());
  }

  if (id === 'new') {
    const route = generatePath(userGroupRoutes.userGroupItem, { groupId: _id });
    history.replace(route);
  }
}

function* watchFetchUserGroup() {
  yield takeLatest(ActionType.FETCH_USER_GROUP, handleFetchUserGroup);
}

function* watchFetchUserGroupStatus() {
  yield takeLatest(ActionType.FETCH_USER_GROUP_STATUS, handleFetchUserGroupStatus);
}

function* watchSaveUserGroup() {
  yield takeLatest(ActionType.SAVE_USER_GROUP, handleSaveUserGroup);
}

const sagas = [
  fork(watchFetchUserGroup),
  fork(watchFetchUserGroupStatus),
  fork(watchSaveUserGroup),
  ...conditionsSagas,
  ...drawsSagas
];

export default sagas;
