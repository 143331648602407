import * as React from 'react';

import { motion } from 'framer-motion';

import { ResizeContainer } from '@rio/components/common/resize-container';

export const variants = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 }
};

export const ModalTransitionContainer: React.FC<React.PropsWithChildren<{ initialHeight?: number }>> = ({
  initialHeight,
  children,
  ...rest
}) => {
  return (
    <ResizeContainer initialHeight={initialHeight} {...rest}>
      {ref => (
        <motion.div
          ref={ref}
          initial="hidden"
          animate="visible"
          variants={variants}
          transition={{ duration: 0.25, clamp: true }}
        >
          {children}
        </motion.div>
      )}
    </ResizeContainer>
  );
};

export const ModalTransition: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return <motion.div variants={variants}>{children}</motion.div>;
};
