import { delay } from 'redux-saga';
import { fork, put, select, takeLatest } from 'redux-saga/effects';

import type { AssessmentType } from '@edapp/hippo-client';
import { RequestActions } from '@edapp/request';

import type { Action, EdErrorResponseType, PaginatedResponse } from '../types';
import * as actions from './actions';
import { getAssessment } from './selectors';
import type { AssessmentSubmission, StoreState } from './types';
import { isNewAssessment } from './utils';

function* handleFetchAssessmentSummaries(action: Action<actions.FetchAssessmentSummariesPayload>) {
  const {
    // searchTerm,
    page,
    pageSize
  } = action.payload;

  yield put(
    RequestActions.getAuthed(
      '/api/assessments/summaries',
      data => ({
        type: actions.FETCH_ASSESSMENT_SUMMARIES_SUCCESS,
        payload: {
          ...data,
          currentPage: page
        }
      }),
      actions.FETCH_ASSESSMENT_SUMMARIES_FAILURE,
      false,
      {
        // searchTerm,
        page,
        pageSize
      }
    )
  );
}

function* handleFetchAssessment({ payload: { assessmentId } }: Action<actions.FetchAssessment>) {
  yield put(
    RequestActions.getAuthed(
      `/api/assessments/${assessmentId}/latest`,
      actions.FETCH_ASSESSMENT_SUCCESS,
      actions.FETCH_ASSESSMENT_FAILURE,
      undefined
    )
  );
}

function* handleFetchSubmission(action: Action<actions.FetchSubmission>) {
  const { assessmentId, submissionId } = action.payload;

  yield put(
    RequestActions.getAuthed(
      `/api/assessments/${assessmentId}/posts/${submissionId}`,
      actions.FETCH_SUBMISSION_SUCCESS,
      actions.FETCH_SUBMISSION_FAILURE
    )
  );
}

function* handleFetchSubmissionFeedback(action: Action<actions.FetchSubmissionFeedback>) {
  const { assessmentId, submissionId } = action.payload;

  yield put(
    RequestActions.getAuthed(
      `/api/assessments/${assessmentId}/posts/${submissionId}/comments/grade`,
      data => ({
        type: actions.FETCH_SUBMISSION_FEEDBACK_SUCCESS,
        payload: { feedback: data, submissionId }
      }),
      actions.FETCH_SUBMISSION_FEEDBACK_FAILURE
    )
  );
}

function* handleAddSubmissionFeedback(action: Action<actions.AddSubmissionFeedback>) {
  const { assessmentId, submissionId, content, status } = action.payload;

  yield put(
    RequestActions.postAuthed(
      `/api/assessments/${assessmentId}/posts/${submissionId}/comments/grade`,
      data => ({
        type: actions.ADD_SUBMISSION_FEEDBACK_SUCCESS,
        payload: { feedback: data, submissionId }
      }),
      actions.ADD_SUBMISSION_FEEDBACK_FAILURE,
      false,
      { content, status }
    )
  );
}

function* handleEditSubmissionFeedback(action: Action<actions.EditSubmissionFeedback>) {
  const { assessmentId, submissionId, feedbackId, status, content } = action.payload;

  yield put(
    RequestActions.patchAuthed(
      `/api/assessments/${assessmentId}/posts/${submissionId}/comments/grade/${feedbackId}`,
      data => ({
        type: actions.EDIT_SUBMISSION_FEEDBACK_SUCCESS,
        payload: { feedback: data, submissionId }
      }),
      actions.EDIT_SUBMISSION_FEEDBACK_FAILURE,
      false,
      { content, status }
    )
  );
}

function* handleFetchSubmissionComments(action: Action<actions.FetchSubmissionComments>) {
  const { assessmentId, submissionId, page, pageSize } = action.payload;

  yield put(
    RequestActions.getAuthed(
      `/api/assessments/${assessmentId}/posts/${submissionId}/comments`,
      data => ({
        type: actions.FETCH_SUBMISSION_COMMENTS_SUCCESS,
        payload: { ...data, submissionId, page }
      }),
      actions.FETCH_SUBMISSION_COMMENTS_FAILURE,
      false,
      { page, pageSize }
    )
  );
}

function* handleSetAssessment(action: Action<actions.SetAssessment>) {
  const { data, assessmentId, skipSave } = action.payload;
  if (!!skipSave) {
    return;
  }

  const oldAssessmentData: AssessmentType = yield select((s: StoreState) =>
    getAssessment(s, assessmentId)
  );
  yield put(actions.saveAssessment(assessmentId, data, oldAssessmentData));
}

function* handleSaveAssessment(action: Action<actions.SaveAssessment>) {
  yield delay(2000);

  const { assessmentId } = action.payload;
  if (isNewAssessment(assessmentId)) {
    return;
  }

  const assessmentData: AssessmentType = yield select((s: StoreState) =>
    getAssessment(s, assessmentId)
  );

  yield put(
    RequestActions.patchAuthed<{}, {}, EdErrorResponseType, actions.SaveAssessmentFailure>(
      `/api/assessments/${assessmentId}`,
      actions.SAVE_ASSESSMENT_SUCCESS,
      error => ({
        type: actions.SAVE_ASSESSMENT_FAILURE,
        payload: { assessmentId, error, assessmentStateBeforeSave: action.payload.oldData }
      }),
      false,
      assessmentData
    )
  );
}

function* handleAddSubmissionComment(action: Action<actions.AddSubmissionComment>) {
  const { content, submissionId, assessmentId } = action.payload;

  yield put(
    RequestActions.postAuthed(
      `/api/assessments/${assessmentId}/posts/${submissionId}/comments`,
      data => ({
        type: actions.ADD_SUBMISSION_COMMENT_SUCCESS,
        payload: { comment: data, submissionId, assessmentId }
      }),
      actions.ADD_SUBMISSION_COMMENT_FAILURE,
      false,
      { content }
    )
  );
}

function* handleDeleteSubmissionComment(action: Action<actions.DeleteSubmissionComment>) {
  const { submissionId, commentId } = action.payload;

  yield put(
    RequestActions.deleteAuthed(
      `/api/comments/${commentId}`,
      () => ({
        type: actions.DELETE_SUBMISSION_COMMENT_SUCCESS,
        payload: { commentId, submissionId }
      }),
      actions.DELETE_SUBMISSION_COMMENT_FAILURE,
      false
    )
  );
}

function* handleFetchAssessmentSubmissionSummaries(
  action: Action<actions.FetchAssessmentSubmissionSummariesPayload>
) {
  const { assessmentId, page, pageSize, status, created } = action.payload;

  yield put(
    RequestActions.getAuthed<PaginatedResponse<AssessmentSubmission>>(
      `api/assessments/${assessmentId}/posts`,
      response => ({
        type: actions.FETCH_ASSESSMENT_SUBMISSION_SUMMARIES_SUCCESS,
        payload: {
          ...response,
          currentPage: page,
          assessmentId
        }
      }),
      actions.FETCH_ASSESSMENT_SUBMISSION_SUMMARIES_FAILURE,
      false,
      { page, pageSize, status, created }
    )
  );
}

function* handleEditSubmissionComment(action: Action<actions.EditSubmissionComment>) {
  const { content, submissionId, commentId } = action.payload;

  yield put(
    RequestActions.patchAuthed(
      `/api/comments/${commentId}`,
      data => ({
        type: actions.EDIT_SUBMISSION_COMMENT_SUCCESS,
        payload: { comment: data, submissionId }
      }),
      actions.EDIT_SUBMISSION_COMMENT_FAILURE,
      false,
      { content }
    )
  );
}

function* watchFetchAssessmentSummaries() {
  yield takeLatest(actions.FETCH_ASSESSMENT_SUMMARIES, handleFetchAssessmentSummaries);
}

function* watchFetchAssessment() {
  yield takeLatest(actions.FETCH_ASSESSMENT, handleFetchAssessment);
}

function* watchSetAssessment() {
  yield takeLatest(actions.SET_ASSESSMENT, handleSetAssessment);
}

function* watchSaveAssessment() {
  yield takeLatest(actions.SAVE_ASSESSMENT, handleSaveAssessment);
}

function* watchFetchAssessmentSubmissionSummaries() {
  yield takeLatest(
    actions.FETCH_ASSESSMENT_SUBMISSION_SUMMARIES,
    handleFetchAssessmentSubmissionSummaries
  );
}

function* watchFetchSubmission() {
  yield takeLatest(actions.FETCH_SUBMISSION, handleFetchSubmission);
}

function* watchFetchSubmissionFeedback() {
  yield takeLatest(actions.FETCH_SUBMISSION_FEEDBACK, handleFetchSubmissionFeedback);
}

function* watchAddSubmissionFeedback() {
  yield takeLatest(actions.ADD_SUBMISSION_FEEDBACK, handleAddSubmissionFeedback);
}

function* watchEditSubmissionFeedback() {
  yield takeLatest(actions.EDIT_SUBMISSION_FEEDBACK, handleEditSubmissionFeedback);
}

function* watchFetchSubmissionComments() {
  yield takeLatest(actions.FETCH_SUBMISSION_COMMENTS, handleFetchSubmissionComments);
}

function* watchAddSubmissionComment() {
  yield takeLatest(actions.ADD_SUBMISSION_COMMENT, handleAddSubmissionComment);
}

function* watchDeleteSubmissionComments() {
  yield takeLatest(actions.DELETE_SUBMISSION_COMMENT, handleDeleteSubmissionComment);
}

function* watchEditSubmissionComment() {
  yield takeLatest(actions.EDIT_SUBMISSION_COMMENT, handleEditSubmissionComment);
}

const sagas = [
  fork(watchFetchAssessmentSummaries),
  fork(watchFetchAssessment),
  fork(watchSetAssessment),
  fork(watchSaveAssessment),
  fork(watchFetchAssessmentSubmissionSummaries),
  fork(watchFetchSubmission),
  fork(watchFetchSubmissionFeedback),
  fork(watchAddSubmissionFeedback),
  fork(watchEditSubmissionFeedback),
  fork(watchFetchSubmissionComments),
  fork(watchAddSubmissionComment),
  fork(watchDeleteSubmissionComments),
  fork(watchEditSubmissionComment)
];

export default sagas;
