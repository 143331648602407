export type PostStatus = DiscussionStatus | AssessmentStatus;

export enum DiscussionStatus {
  INCOMPLETE = 'Incomplete',
  COMPLETE = 'Complete'
}

export enum AssessmentStatus {
  NOSUBMISSION = 'NoSubmission',
  SUBMITTED = 'Submitted',
  PASSED = 'Passed',
  TRYAGAIN = 'TryAgain'
}
