import type { SearchCatalogsRequest } from './types';

export enum CatalogQueryKeyPrefixes {
  SearchCatalogs = 'search-catalogs'
}

export const catalogQueryKeys = {
  searchCatalogs: (request: SearchCatalogsRequest) => [
    CatalogQueryKeyPrefixes.SearchCatalogs,
    request
  ]
};
