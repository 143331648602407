import { put, fork, takeLatest } from 'redux-saga/effects';
import { RequestActions } from '@edapp/request';
import type {
  FetchCoursePublicDetailRatingLinksAction,
  CreateCoursePublicDetailRatingLinkAction,
  DeleteCoursePublicDetailRatingLinkAction
} from './actions';
import { ActionType, actions } from './actions';
import type { CoursePublicDetailRatingLink } from './types';

const ratingLinks = (courseId: string, publicDetailId: string) =>
  `/api/courses/${courseId}/public-details/${publicDetailId}/user-course-ratings`;
const ratingLink = (courseId: string, publicDetailId: string, ratingLinkId: string) =>
  `${ratingLinks(courseId, publicDetailId)}/${ratingLinkId}`;

function* handleFetchRatingLinks({ payload }: FetchCoursePublicDetailRatingLinksAction) {
  yield put(
    RequestActions.getAuthed<CoursePublicDetailRatingLink[]>(
      ratingLinks(payload.courseId, payload.publicDetailId),
      ratingLinks =>
        actions.fetchCoursePublicDetailRatingLinksSuccess({
          publicDetailId: payload.publicDetailId,
          ratingLinks
        }),
      ActionType.FETCH_COURSE_PUBLIC_DETAIL_RATING_LINKS_FAILURE
    )
  );
}

function* handleCreateRatingLink({ payload }: CreateCoursePublicDetailRatingLinkAction) {
  yield put(
    RequestActions.postAuthed(
      ratingLinks(payload.courseId, payload.publicDetailId),
      ActionType.CREATE_COURSE_PUBLIC_DETAIL_RATING_LINK_SUCCESS,
      ActionType.CREATE_COURSE_PUBLIC_DETAIL_RATING_LINK_FAILURE,
      false,
      payload
    )
  );
}

function* handleDeleteCreateRatingLink({ payload }: DeleteCoursePublicDetailRatingLinkAction) {
  yield put(
    RequestActions.deleteAuthed(
      ratingLink(payload.courseId, payload.publicDetailId, payload.ratingLinkId),
      () =>
        actions.deleteCoursePublicDetailRatingLinkSuccess({
          publicDetailId: payload.publicDetailId,
          id: payload.ratingLinkId
        }),
      ActionType.DELETE_COURSE_PUBLIC_DETAIL_RATING_LINK_FAILURE
    )
  );
}

function* watchFetchRatingLinks() {
  yield takeLatest(ActionType.FETCH_COURSE_PUBLIC_DETAIL_RATING_LINKS, handleFetchRatingLinks);
}

function* watchCreateRatingLink() {
  yield takeLatest(ActionType.CREATE_COURSE_PUBLIC_DETAIL_RATING_LINK, handleCreateRatingLink);
}

function* watchDeleteCreateRatingLink() {
  yield takeLatest(
    ActionType.DELETE_COURSE_PUBLIC_DETAIL_RATING_LINK,
    handleDeleteCreateRatingLink
  );
}

export const sagas = [
  fork(watchFetchRatingLinks),
  fork(watchCreateRatingLink),
  fork(watchDeleteCreateRatingLink)
];
