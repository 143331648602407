import * as React from 'react';
import { RatioCount } from '../shared';

type Props = {
  completed: number;
  total: number;
} & Omit<React.ComponentProps<typeof RatioCount>, 'over' | 'under'>;

export const LessonLimitCount: React.FC<Props> = ({ completed, total, ...rest }) => (
  <RatioCount over={completed} under={total} {...rest} />
);
