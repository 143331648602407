import { AnimatePresence, motion } from 'framer-motion';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import {
    Box, CogIcon, CopyLink, LinkIcon, MailIcon, Typography, UnlinkIcon, WarningDialog
} from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';

import { DownloadQRCode } from './DownloadQRCode';
import { InviteCodeModal } from './InviteCodeModal';
import SettingItem from './SettingItem';

export const usersRoutes = {
  inviteUsers: '/invite'
};

type Props = {
  title: string;
  description: React.ReactNode;
  showInviteByEmail?: boolean;

  inviteEnabled: boolean;
  inviteLink: string;
  inviteCode: string;

  onCopyLinkClicked?: () => void;
  onDownloadQRCode?: () => void;

  onRegenerate: () => void;
  onToggleEnabled: (enabled: boolean) => void;
};

export const InviteSettings: React.FC<Props> = ({
  title,
  description,
  showInviteByEmail,
  inviteEnabled,
  inviteLink,
  inviteCode,
  onCopyLinkClicked,
  onDownloadQRCode,
  onRegenerate,
  onToggleEnabled
}) => {
  const { t } = useTranslation();
  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const [showSettingsModal, setShowSettingsModal] = React.useState(false);

  const handleShowSettingsModal = () => {
    setShowSettingsModal(true);
  };

  const handleCloseSettingsModal = () => {
    setShowSettingsModal(false);
  };

  const handleCloseWarningModal = () => {
    setShowWarningModal(false);
  };

  const handleEnableInvite = () => {
    onToggleEnabled(true);
  };

  const handleDisableInvite = () => {
    setShowWarningModal(false);
    onToggleEnabled(false);
  };

  return (
    <>
      <WarningDialog
        title={t('registration-settings.disable-dialog.title')}
        description={t('registration-settings.disable-dialog.description')}
        confirmText={t('registration-settings.yes-disable')}
        isOpen={showWarningModal}
        onClose={handleCloseWarningModal}
        onCancel={handleCloseWarningModal}
        onConfirm={handleDisableInvite}
      />

      <InviteCodeModal
        isOpen={showSettingsModal}
        onClose={handleCloseSettingsModal}
        title={t('registration-settings.manual-code.title')}
        description={t('registration-settings.manual-code.description')}
        inviteCode={inviteCode}
        onRegenerateCode={onRegenerate}
      />

      <Box rounded={true} bgColor="white" border={true} py={5} px={6}>
        <Text variant="titleLarge" component="h2">
          {title}
        </Text>
        <Box mt={0.5}>
          <Typography variant="paragraph" color="textMuted">
            {description}
          </Typography>
        </Box>

        <AnimatePresence exitBeforeEnter={true}>
          {inviteEnabled && !!inviteLink ? (
            <motion.div
              key="invite-enabled"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 1 }}
            >
              <>
                <StyledInputBox mt={3}>
                  <CopyLink
                    link={inviteLink}
                    onCopy={onCopyLinkClicked}
                    bgColor="white"
                    buttonVariant="primary"
                  />
                </StyledInputBox>
                <Box mt={1.5}>
                  <DownloadQRCode inviteLink={inviteLink} onDownloadQRCode={onDownloadQRCode} />
                  <SettingItem
                    onClick={handleShowSettingsModal}
                    icon={CogIcon}
                    text={t('registration-settings.advanced-settings')}
                  />
                  {showInviteByEmail && (
                    <SettingItem
                      icon={MailIcon}
                      text={t('registration-settings.invite-by-email')}
                      href={usersRoutes.inviteUsers}
                    />
                  )}
                  <SettingItem
                    onClick={() => setShowWarningModal(true)}
                    icon={UnlinkIcon}
                    text={t('registration-settings.disable-link')}
                  />
                </Box>
              </>
            </motion.div>
          ) : (
            <motion.div
              key="invite-disabled"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 1 }}
            >
              <Box mt={3}>
                <SettingItem
                  onClick={handleEnableInvite}
                  icon={LinkIcon}
                  text={t('registration-settings.enable-link')}
                  disabled={inviteEnabled}
                />
              </Box>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
    </>
  );
};

const StyledInputBox = styled(Box)`
  max-width: 320px;
`;
