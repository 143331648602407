import { createMutated, mergeMutated, spreadMutated } from '@rio/store/reducerUtils';

import { RapidRefreshActionTypes } from './actions';
import type { RapidRefreshActionsUnionType, RapidRefreshState } from './types';

export const initialRapidRefreshState: RapidRefreshState = {
  byId: {},
  lessonPreviews: {},
  isLessonPreviewLoading: false,
  isLoading: false,
  loadingError: '',
  isSaving: false,
  savingError: '',
  filter: undefined,
  sort: undefined
};

export const rapidRefreshReducer = (
  state: RapidRefreshState = initialRapidRefreshState,
  action: RapidRefreshActionsUnionType
): RapidRefreshState => {
  switch (action.type) {
    case RapidRefreshActionTypes.FETCH_RAPID_REFRESH_CONFIG: {
      return { ...state, isLoading: true, loadingError: '' };
    }
    case RapidRefreshActionTypes.FETCH_RAPID_REFRESH_CONFIG_SUCCESS: {
      const rapidRefresh = action.payload;
      return {
        ...state,
        isLoading: false,
        loadingError: '',
        byId: {
          ...state.byId,
          [rapidRefresh.id]: { ...createMutated(rapidRefresh) }
        }
      };
    }
    case RapidRefreshActionTypes.FETCH_RAPID_REFRESH_CONFIG_FAILURE: {
      return { ...state, isLoading: false, loadingError: action.payload.message };
    }

    case RapidRefreshActionTypes.SAVE_RAPID_REFRESH_CONFIG: {
      const { id, partialData } = action.payload;
      return {
        ...state,
        isSaving: true,
        savingError: '',
        byId: {
          ...state.byId,
          [id]: {
            ...mergeMutated(state.byId[id], partialData)
          }
        }
      };
    }

    case RapidRefreshActionTypes.SAVE_RAPID_REFRESH_CONFIG_SUCCESS: {
      return {
        ...state,
        isSaving: false,
        savingError: '',
        byId: {
          ...state.byId,
          [action.payload.id]: {
            ...spreadMutated(state.byId[action.payload.id], { status: action.payload.status })
          }
        }
      };
    }

    case RapidRefreshActionTypes.SAVE_RAPID_REFRESH_CONFIG_FAILURE: {
      const { id, oldState, error } = action.payload;
      return {
        ...state,
        isSaving: false,
        savingError: error.message,
        byId: {
          ...state.byId,
          [id]: {
            ...spreadMutated(state.byId[id], oldState)
          }
        }
      };
    }

    case RapidRefreshActionTypes.SET_RAPID_REFRESH_FILTER: {
      const { filter } = action.payload;
      if (!!filter) {
        return {
          ...state,
          filter: { ...state.filter, ...filter }
        };
      } else {
        return {
          ...state,
          filter: undefined
        };
      }
    }

    case RapidRefreshActionTypes.SET_RAPID_REFRESH_SORT: {
      const { sort } = action.payload;
      if (!!sort) {
        return {
          ...state,
          sort: { ...state.sort, ...sort }
        };
      } else {
        return {
          ...state,
          sort: undefined
        };
      }
    }

    case RapidRefreshActionTypes.FETCH_RAPID_REFRESH_LESSON_PREVIEW: {
      return { ...state, isLessonPreviewLoading: true, loadingError: '' };
    }
    case RapidRefreshActionTypes.FETCH_RAPID_REFRESH_LESSON_PREVIEW_SUCCESS: {
      const lessonPreview = action.payload;
      return {
        ...state,
        isLessonPreviewLoading: false,
        loadingError: '',
        lessonPreviews: {
          ...state.lessonPreviews,
          [lessonPreview.id]: { ...lessonPreview }
        }
      };
    }
    case RapidRefreshActionTypes.FETCH_RAPID_REFRESH_LESSON_PREVIEW_FAILURE: {
      return { ...state, isLessonPreviewLoading: false, loadingError: action.payload.message };
    }

    default: {
      return state;
    }
  }
};
