import * as React from 'react';

import { formatDistanceToNow } from 'date-fns';
import { Trans, useTranslation } from 'react-i18next';
import { connect, useSelector } from 'react-redux';

import {
  Box,
  ChevronDownIcon,
  Divider,
  Dropdown,
  IconButton,
  Tooltip,
  Typography,
  WarningIcon
} from '@edapp/ed-components';
import { RequestUtils } from '@edapp/request';
import { getEmilyCmsCredentials } from '@rio/store/config/selectors';
import type { LmsStoreState } from '@rio/store/types';

type AuditTrailItem = {
  _id: string;
  user: {
    _id: string;
    name: string;
    email: string;
  };
  app: string;
  action: string;
  name: string;
  params: {
    'user-group': {
      _id: string;
      name: string;
    };
  };
  created: string;
};

type Response = AuditTrailItem[];
type Payload = { params: { 'user-group': string } };

type OwnProps = { groupId: string };
type Props = OwnProps & ReturnType<typeof mapStateToProps>;

const ChangeLog: React.FunctionComponent<Props> = props => {
  const { groupId } = props;

  const { t } = useTranslation();
  const { emilyCmsUrl, userToken } = useSelector(getEmilyCmsCredentials);
  const [requestState, setRequestState] = React.useState<{
    data?: AuditTrailItem[];
    error?: string;
    loading: boolean;
  }>({ data: undefined, error: undefined, loading: false });
  const params = { 'user-group': groupId };

  const auditTrailRequest = () => {
    setRequestState({ ...requestState, loading: true });
    RequestUtils.httpFetch<Response, Payload>(
      'POST',
      RequestUtils.getEndpointUrl(emilyCmsUrl, '/audit-trail'),
      userToken,
      { params }
    )
      .then(response => {
        setRequestState({ loading: false, error: undefined, data: response });
      })
      .catch(err => {
        setRequestState({ ...requestState, loading: false, error: err });
      });
  };

  React.useEffect(() => {
    auditTrailRequest();
  }, []);

  if (requestState.error) {
    return (
      <Tooltip content={requestState.error} placement="bottom">
        <WarningIcon style={{ color: '#e93f3a' }} />
      </Tooltip>
    );
  }
  if (groupId === 'new' || requestState.loading || !requestState.data) {
    return null;
  }

  const changeLogItems = (requestState.data || []).map(i => (
    <Typography as="li" key={i._id}>
      <Trans
        i18nKey="user-groups.change-log.log-item"
        values={{
          user: `${(i.user && i.user.name) || null}`,
          timestamp: formatDistanceToNow(new Date(i.created), { addSuffix: true })
        }}
        components={{
          bold: <strong />
        }}
      />
    </Typography>
  ));

  return (
    <Dropdown
      placement="bottom-end"
      trigger={
        <IconButton
          testId="user-group-changelog-button"
          icon={ChevronDownIcon}
          iconSize="sm"
          size="sm"
          right
        >
          {t('user-groups.change-log.title')}
        </IconButton>
      }
    >
      <Box bgColor="white" rounded={true} border>
        <Box p={1.5}>
          <Typography as="li" variant="subtext2" color="textMuted">
            {t('user-groups.change-log.recent-changes-title')}
          </Typography>
          {changeLogItems}
        </Box>
        {changeLogItems.length > 0 && !!props.hasAccess && (
          <>
            <Divider />
            <Box p={1.5}>
              <Typography as="a" href={`/audit-trail?params=${JSON.stringify(params)}`}>
                {t('user-groups.change-log.view-details')}
              </Typography>
            </Box>
          </>
        )}
      </Box>
    </Dropdown>
  );
};

const mapStateToProps = (state: LmsStoreState) => {
  const hasAccess = !!(state.config.userRoles || []).find(
    role => role === 'ed-admin' || role === 'account-admin' || role === 'account-owner'
  );
  return { hasAccess };
};

export default connect(mapStateToProps)(ChangeLog);
