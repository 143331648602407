import { authorProfileRoutes } from '@edapp/author-profile';
import { routes as authoringRoutes } from '@edapp/authoring-logic';
import { coursewareRoutes } from '@edapp/courseware-logic';
import { leaderBoardRoutes } from '@edapp/leaderboards';
import { UserPermission } from '@edapp/permissions';
import { Roles } from '@edapp/user-info';
import { tryLazy } from '@rio/utils/dynamic-import';
import { BillingPermission } from '@rio/utils/permissions/constants';

import type { Routes } from './types';

const AllInOne = () =>
  import(
    /* webpackChunkName: "AllInOneBuilder" */
    '@rio/containers/AllInOneBuilder/AllInOneBuilder'
  ).then(({ AllInOneBuilderEdapp }) => ({
    default: AllInOneBuilderEdapp
  }));

const Integrations = tryLazy(() =>
  import(
    /* webpackChunkName: "Integrations" */
    '@rio/containers/integrations/Integrations'
  ).then(({ Integrations: IntegrationsPage }) => ({ default: IntegrationsPage }))
);

export const routes: Routes = {
  achievements: {
    name: 'Achievements',
    path: '/achievements',
    exact: true,
    restrictions: {
      userPermission: [UserPermission.ACCESS_LMS]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "Achievements" */
        '@rio/containers/Achievements/AchievementsList/AchievementsList'
      ).then(({ AchievementsList }) => ({ default: AchievementsList }))
    ),
    getRoute: () => '/achievements'
  },
  achievement: {
    name: 'Achievement',
    path: '/achievements/:achievementId',
    exact: true,
    restrictions: {
      userPermission: [UserPermission.ACCESS_LMS]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "Achievement" */
        '@rio/containers/Achievements/Achievement/Achievement'
      ).then(({ Achievement }) => ({ default: Achievement }))
    ),
    getRoute: ({ achievementId, tab = 'recipe' }) => `/achievements/${achievementId}#${tab}`
  },
  assessment: {
    ...authoringRoutes.assessment,
    restrictions: {
      userPermission: [UserPermission.VIEW_COURSEWARE]
    },
    component: tryLazy(AllInOne)
  },
  manageComingSoon: {
    name: 'Manage Coming Soon',
    path: '/manage-coming-soon',
    exact: false,
    restrictions: {
      role: Roles.ManageTeams
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "ManageComingSoon" */
        '@rio/containers/manage-team/ManageComingSoon'
      )
    ),
    getRoute: () => '/manage-coming-soon'
  },
  courseware: {
    ...coursewareRoutes.courseware,
    redirects: ['/courses', '/v2/courses', '/'],
    restrictions: {
      userPermission: [UserPermission.VIEW_COURSEWARE]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "Courseware" */
        '@rio/containers/Courseware/CourseList/CourseList'
      )
    )
  },
  courseCollection: {
    name: 'Course Collection',
    path: '/v2/course-collection/:collectionId',
    redirects: [
      '/course-collection/:collectionId',
      '/v2/course-collection/:collectionId/settings/:tabName'
    ],
    exact: false,
    restrictions: {
      userPermission: [UserPermission.VIEW_COURSEWARE]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "CourseCollection" */
        '@rio/containers/Courseware/CourseCollection/CourseCollection'
      ).then(({ CourseCollection }) => ({ default: CourseCollection }))
    ),
    getRoute: ({ collectionId }) => `/v2/course-collection/${collectionId}`
  },
  courseReview: {
    ...authoringRoutes.courseReview,
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "CourseReview" */
        '@rio/containers/Courseware/CourseReview/CourseReviewContainer'
      ).then(({ CourseReviewContainer }) => ({ default: CourseReviewContainer }))
    ),
    getRoute: ({ courseId }) => `/course/${courseId}/review`
  },
  discussion: {
    ...authoringRoutes.discussion,
    restrictions: {
      userPermission: [UserPermission.VIEW_COURSEWARE]
    },
    component: tryLazy(AllInOne),
    getRoute: ({ lessonId, settingsTab }) =>
      `/discussion/${lessonId}` + (settingsTab ? `/settings/${settingsTab}` : '')
  },
  learnerNotifications: {
    name: 'Learner notifications',
    path: '/account-settings/learner-notifications',
    exact: true,
    restrictions: {
      billingPermission: BillingPermission.ACCESS_LEARNER_NOTIFICATIONS
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "LearnerNotifications" */
        '@rio/containers/learner-notifications'
      ).then(({ LearnerNotifications }) => ({ default: LearnerNotifications }))
    ),
    getRoute: () => '/account-settings/learner-notifications'
  },
  leaderboards: {
    ...leaderBoardRoutes.leaderboards,
    restrictions: {
      billingPermission: BillingPermission.ACCESS_LEADERBOARDS
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "Leaderboards" */
        '@rio/containers/Leaderboards/LeaderboardList/LeaderboardList'
      )
    )
  },
  leaderboard: {
    ...leaderBoardRoutes.leaderboard,
    restrictions: {
      billingPermission: BillingPermission.ACCESS_LEADERBOARDS
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "Leaderboard" */
        '@rio/containers/Leaderboards/Leaderboard/Leaderboard'
      ).then(({ Leaderboard }) => ({ default: Leaderboard }))
    )
  },
  lesson: {
    ...authoringRoutes.lesson,
    restrictions: {
      userPermission: [UserPermission.VIEW_COURSEWARE]
    },
    component: tryLazy(AllInOne)
  },
  observation: {
    ...authoringRoutes.observation,
    restrictions: {
      userPermission: [UserPermission.VIEW_COURSEWARE]
    },
    component: tryLazy(AllInOne)
  },
  path: {
    ...authoringRoutes.path,
    exact: false,
    restrictions: {
      userPermission: [UserPermission.ACCESS_LMS]
    },
    redirects: ['playlists/:playlistId'],
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "Playlist" */
        '@rio/containers/Playlists/Playlist/Playlist'
      ).then(({ Playlist }) => ({ default: Playlist }))
    ),
    getRoute: ({ playlistId }) => `/paths/${playlistId}`
  },
  rapidRefresh: {
    name: 'Rapid Refresh',
    path: '/rapid-refresh/:id',
    exact: true,
    restrictions: {
      userPermission: [UserPermission.VIEW_COURSEWARE]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "RapidRefresh" */
        '@rio/containers/Courseware/rapid-refresh/RapidRefresh'
      ).then(({ RapidRefresh }) => ({ default: RapidRefresh }))
    ),
    getRoute: ({ id }) => `/rapid-refresh/${id}`
  },
  allInOneEditor: {
    ...authoringRoutes.allInOneEditor,
    exact: false,
    component: tryLazy(AllInOne),
    getRoute: ({ courseId, settingsTab }) =>
      `/course/${courseId}/edit` + (settingsTab ? `/settings/${settingsTab}` : '')
  },
  apiToken: {
    name: 'API token',
    path: '/app-settings/api',
    exact: true,
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "APIToken" */
        '@rio/containers/api-token'
      ).then(({ APIToken }) => ({ default: APIToken }))
    ),
    getRoute: () => '/app-settings/api'
  },
  authorProfile: {
    ...authorProfileRoutes.authorProfile,
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "AuthorProfile" */
        '@rio/containers/AuthorProfile/AuthorProfile'
      )
    )
  },
  searchOrganization: {
    name: 'Search Organization',
    path: '/organizations/search',
    exact: true,
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "SearchOrganization" */
        '@rio/containers/search-organization'
      ).then(({ SearchOrganization }) => ({ default: SearchOrganization }))
    ),
    getRoute: () => '/organizations/search'
  },
  migrationTool: {
    name: 'Migration Tool',
    path: '/migration-tool',
    exact: true,
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "MigrationTool" */
        '@rio/containers/migration-tool'
      ).then(({ MigrationTool }) => ({ default: MigrationTool }))
    ),
    getRoute: () => '/migration-tool'
  },
  organizationFeatures: {
    name: "Organization's Features",
    path: '/admin/organization-features',
    exact: true,
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "Organization Features" */
        '@rio/containers/organization-features'
      ).then(({ OrganizationFeatures }) => ({ default: OrganizationFeatures }))
    ),
    getRoute: () => '/admin/organization-features'
  },
  virtualClassroom: {
    ...authoringRoutes.meeting,
    restrictions: {
      userPermission: [UserPermission.VIEW_COURSEWARE]
    },
    component: tryLazy(AllInOne)
  },
  facilitateDiscussions: {
    name: 'Discussions',
    path: '/discussions',
    exact: true,
    restrictions: {
      userPermission: [UserPermission.ACCESS_LMS]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "Discussions" */
        '@rio/containers/Discussions/Overview/Overview'
      )
    ),
    getRoute: () => '/discussions'
  },
  facilitateDiscussionPosts: {
    ...authoringRoutes.facilitateDiscussionPosts,
    restrictions: {
      userPermission: [UserPermission.ACCESS_LMS]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "DiscussionPosts" */
        '@rio/containers/Discussions/Posts/Posts'
      )
    )
  },
  facilitateAssignments: {
    name: 'Assignments',
    path: '/assignments',
    exact: true,
    restrictions: {
      userPermission: [UserPermission.ACCESS_LMS]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "Assignments" */
        '@rio/containers/Assessments/Overview/Overview'
      )
    ),
    getRoute: () => '/assignments'
  },
  facilitateAssignmentSubmissions: {
    ...authoringRoutes.facilitateAssignmentSubmissions,
    restrictions: {
      userPermission: [UserPermission.ACCESS_LMS]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "AssignmentSubmissions" */
        '@rio/containers/Assessments/SubmissionsOverview/SubmissionsOverview'
      )
    )
  },
  facilitateAssignmentSubmission: {
    name: 'Assignment Submission',
    path: '/assignments/:assignmentId/submissions/:submissionId',
    exact: true,
    restrictions: {
      userPermission: [UserPermission.ACCESS_LMS]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "AssignmentSubmission" */
        '@rio/containers/Assessments/Submission/Submission'
      )
    ),
    getRoute: ({ assignmentId, submissionId }) =>
      `/assignments/${assignmentId}/submissions/${submissionId}`
  },
  facilitatePracticalAssessments: {
    name: 'Practical Assessments',
    path: '/practical-assessments',
    exact: true,
    restrictions: {
      userPermission: [UserPermission.ACCESS_LMS]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "PracticalAssessments" */
        '@rio/containers/Observations/Overview/Overview'
      )
    ),
    getRoute: () => '/practical-assessments'
  },
  facilitatePracticalAssessment: {
    name: 'Practical Assessment',
    path: '/practical-assessments/:observationId',
    exact: true,
    restrictions: {
      userPermission: [UserPermission.ACCESS_LMS]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "PracticalAssessment" */
        '@rio/containers/Observations/LearnersList/LearnersList'
      ).then(({ LearnersList }) => ({ default: LearnersList }))
    ),
    getRoute: ({ observationId }) => `/practical-assessments/${observationId}`
  },
  facilitateGroupTraining: {
    name: 'Group Training',
    path: '/group-training',
    exact: true,
    restrictions: {
      userPermission: [UserPermission.ACCESS_LMS]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "GroupTraining" */
        '@rio/containers/GroupTraining/Overview/Overview'
      )
    ),
    getRoute: () => '/group-training'
  },
  welcome: {
    name: 'Welcome',
    path: '/welcome',
    exact: true,
    restrictions: {
      userPermission: [UserPermission.ACCESS_LMS]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "Welcome" */
        '@rio/components/welcome/Welcome'
      )
    ),
    getRoute: () => '/welcome'
  },
  banners: {
    name: 'Banners',
    path: '/banners',
    exact: true,
    restrictions: {
      userPermission: [UserPermission.EDIT_SETTINGS]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "Banners" */
        '@rio/containers/banners/Banners'
      ).then(({ Banners }) => ({ default: Banners }))
    ),
    getRoute: () => '/banners'
  },
  banner: {
    name: 'Banner',
    path: '/banner/:bannerId',
    exact: false,
    restrictions: {
      userPermission: [UserPermission.EDIT_SETTINGS]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "Banner" */
        '@rio/containers/banner/Banner'
      ).then(({ Banner }) => ({ default: Banner }))
    ),
    getRoute: ({ bannerId, tabName }) =>
      `/banner/${bannerId}` + (tabName ? `/settings/${tabName}` : '')
  },
  peerAuthorings: {
    name: 'Peer Authorings',
    path: '/peer-authoring',
    exact: true,
    restrictions: {
      userPermission: [UserPermission.EDIT_CONTENT]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "PeerAuthorings" */
        '@rio/containers/PeerAuthoring/PeerAuthoringLessonsList/PeerAuthoringLessonsList'
      )
    ),
    getRoute: () => '/peer-authoring'
  },
  peerAuthoring: {
    name: 'Peer Authoring',
    path: '/peer-authoring/:lessonId/:slideId',
    exact: true,
    restrictions: {
      userPermission: [UserPermission.EDIT_CONTENT]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "PeerAuthoring" */
        '@rio/containers/PeerAuthoring/PeerAuthoringLessonContributions/PeerAuthoringLessonContributions'
      )
    ),
    getRoute: ({ lessonId, slideId }) => `/peer-authoring/${lessonId}/${slideId}`
  },
  tagsManagement: {
    name: 'Tags Managment',
    path: '/admin/tags-managment',
    exact: true,
    restrictions: {
      role: Roles.EdAdmin
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "Tags" */
        '@rio/containers/admin/tags/Tags'
      ).then(({ Tags }) => ({ default: Tags }))
    ),
    getRoute: () => '/admin/tags-managment'
  },
  findUser: {
    name: 'Find User',
    path: '/admin/find-user',
    exact: true,
    restrictions: {
      role: Roles.EdAdmin
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "FindUser" */
        '@rio/containers/admin/find-user/FindUser'
      ).then(({ FindUser }) => ({ default: FindUser }))
    ),
    getRoute: () => '/admin/find-user'
  },
  getStarted: {
    name: 'Get Started',
    path: '/get-started',
    exact: true,
    restrictions: {
      userPermission: [UserPermission.ACCESS_LMS]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "GetStarted" */
        '@rio/containers/GetStarted/GetStarted'
      )
    ),
    getRoute: () => '/get-started'
  },
  integrations: {
    name: 'Integrations',
    path: '/integrations',
    exact: true,
    restrictions: {
      billingPermission: BillingPermission.ACCESS_INTEGRATIONS,
      userPermission: [UserPermission.EDIT_INTEGRATIONS]
    },
    component: Integrations,
    getRoute: () => '/integrations'
  },
  integration: {
    name: 'Integrations',
    path: '/integrations/:integration',
    exact: true,
    restrictions: {
      billingPermission: BillingPermission.ACCESS_INTEGRATIONS,
      userPermission: [UserPermission.EDIT_INTEGRATIONS]
    },
    component: Integrations,
    getRoute: ({ integration }) => `/integrations/${integration}`
  },

  /** Deputy callback route when connecting integration */
  integrationConnect: {
    name: 'Integrations',
    path: '/integrations/:integration/connect',
    exact: true,
    restrictions: {
      billingPermission: BillingPermission.ACCESS_INTEGRATIONS,
      userPermission: [UserPermission.EDIT_INTEGRATIONS]
    },
    component: Integrations,
    getRoute: ({ integration }) => `/integrations/${integration}`
  },
  integrationBuilder: {
    name: 'Integration Builder',
    path: '/integrations/builder/:embeddedPath',
    exact: true,
    restrictions: {
      role: Roles.EdAdmin
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "IntegrationBuilder" */
        '@rio/containers/integrations/IntegrationBuilder'
      ).then(({ IntegrationBuilder: WorkatoEmbeddedPage }) => ({
        default: WorkatoEmbeddedPage
      }))
    ),
    getRoute: ({ embeddedPath }) => `/integrations/builder/${embeddedPath}`
  },
  briefcase: {
    name: 'Briefcase',
    path: '/briefcase-document/:documentId',
    exact: false,
    restrictions: {
      userPermission: [UserPermission.EDIT_CONTENT]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "Briefcase" */
        '@rio/containers/briefcase/Briefcase'
      ).then(({ Briefcase }) => ({ default: Briefcase }))
    ),
    getRoute: ({ documentId }) => `/briefcase-document/${documentId}`
  },
  analyticsEngagementReports: {
    name: 'Analytics Engagement Reports',
    path: '/analytics/engagement/reports',
    exact: true,
    restrictions: {
      billingPermission: BillingPermission.ACCESS_ANALYTICS_ACTIONABLE_REPORTS,
      userPermission: [UserPermission.VIEW_USER_ANALYTICS]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "AnalyticsActionableReports" */
        '@rio/containers/Analytics/ActionableReports/ActionableReports'
      ).then(({ ActionableReports }) => ({
        default: ActionableReports
      }))
    ),
    getRoute: () => '/analytics/engagement/reports'
  },
  analyticsSurvey: {
    name: 'Analytics Survey',
    path: '/analytics/surveys/:lessonId',
    exact: true,
    restrictions: {
      billingPermission: BillingPermission.ACCESS_ANALYTICS_SURVEYS,
      userPermission: [UserPermission.VIEW_CONTENT_ANALYTICS]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "AnalyticsSurvey" */
        '@rio/containers/Analytics/Survey/Survey'
      ).then(({ Survey }) => ({
        default: Survey
      }))
    ),
    getRoute: ({ lessonId }) => `/analytics/surveys/${lessonId}`
  },
  analyticsSurveys: {
    name: 'Analytics Surveys',
    path: '/analytics/surveys',
    exact: true,
    restrictions: {
      billingPermission: BillingPermission.ACCESS_ANALYTICS_SURVEYS,
      userPermission: [UserPermission.VIEW_CONTENT_ANALYTICS]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "AnalyticsSurveys" */
        '@rio/containers/Analytics/Surveys/Surveys'
      ).then(({ Surveys }) => ({
        default: Surveys
      }))
    ),
    getRoute: () => '/analytics/surveys'
  },
  analyticsFjordViz: {
    name: 'Analytics',
    path: '/analytics/:name',
    exact: false,
    restrictions: {
      userPermission: [
        UserPermission.MANAGER_ANALYTICS,
        UserPermission.VIEW_CONTENT_ANALYTICS,
        UserPermission.VIEW_USER_ANALYTICS
      ]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "AnalyticsViz" */
        '@rio/containers/Analytics/FjordViz/FjordViz'
      ).then(({ FjordViz }) => ({
        default: FjordViz as React.FC
      }))
    ),
    getRoute: ({ name }) => `/analytics/${name}`
  },
  analytics: {
    name: 'Analytics',
    path: '/analytics',
    exact: false,
    restrictions: {
      userPermission: [
        UserPermission.MANAGER_ANALYTICS,
        UserPermission.VIEW_CONTENT_ANALYTICS,
        UserPermission.VIEW_USER_ANALYTICS
      ]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "AnalyticsHome" */
        '@rio/containers/Analytics/AnalyticsHome/AnalyticsHome'
      ).then(({ AnalyticsHome }) => ({
        default: AnalyticsHome
      }))
    ),
    getRoute: () => '/analytics'
  },
  auditTrail: {
    name: 'ChangeLog',
    path: '/change-log',
    exact: false,
    restrictions: {
      userPermission: [UserPermission.ACCESS_AUDIT_TRAIL]
    },
    component: tryLazy(() =>
      import('@rio/containers/change-log/ChangeLog').then(({ ChangeLog }) => ({
        default: ChangeLog
      }))
    ),
    getRoute: () => '/change-log'
  },
  appSettings: {
    // MFE Route
    name: 'App Settings',
    path: '/app-settings',
    exact: false,
    restrictions: {
      userPermission: [UserPermission.EDIT_SETTINGS]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "AppSettings" */
        '@rio/containers/AppSettings/tabs/AppSettingsTabs'
      ).then(({ AppSettingTabs }) => ({
        default: AppSettingTabs
      }))
    ),
    getRoute: () => '/app-settings'
  },
  betterPptx: {
    // MFE Route
    name: 'BetterPptx',
    path: '/better-pptx',
    exact: false,
    restrictions: {
      userPermission: [UserPermission.ACCESS_AUTHORING]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "BetterPptx" */
        '@rio/containers/better-pptx/BetterPptx'
      )
    ),
    getRoute: () => '/better-pptx'
  },
  manageTeam: {
    name: 'Manage Team',
    path: '/manage-team',
    exact: false,
    restrictions: {
      userPermission: [UserPermission.SC_MANAGE_TEAM]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "ManageTeam" */
        '@rio/containers/manage-team/ManageTeam'
      )
    ),
    getRoute: () => '/manage-team'
  },
  importCourses: {
    name: 'Import Courses',
    path: '/import-courses',
    exact: true,
    restrictions: {
      userPermission: [UserPermission.EDIT_CONTENT]
    },
    redirects: ['/import-from-catalog'],
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "ImportCourses" */
        '@rio/containers/import-courses'
      ).then(({ ImportCourses }) => ({ default: ImportCourses }))
    ),
    getRoute: () => '/import-courses'
  },
  home: {
    name: 'Home',
    path: '/home',
    exact: true,
    restrictions: {
      userPermission: [UserPermission.ACCESS_LMS]
    },
    component: tryLazy(() =>
      import(
        /* webpackChunkName: "Home" */
        '@rio/containers/home'
      ).then(({ Home }) => ({ default: Home }))
    ),
    getRoute: () => '/home'
  }
};
