import * as React from 'react';
import { usePrevious } from '@edapp/ed-components/hooks';

// Our hook
function useDebounce<TData>(value: TData, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = React.useState(value);

  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return debouncedValue;
}

const useIsChanged = <T extends any>(value: T) => usePrevious(value) !== value;

export { useIsChanged, useDebounce };
