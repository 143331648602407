import { inputStyle } from '../input';
import styled, { css } from 'styled-components';

export const StyledInput = styled.input<{ mr: number; ml: number }>`
  ${({ theme, mr = 0, ml = 0 }) => css`
    font: inherit; // for safari
    color: inherit; // for safari
    width: 100%; // for safari
    background-color: transparent;
    padding: 0;
    border: none;
    outline: none;
    text-overflow: ellipsis;
    flex-grow: 1;
    margin-right: ${theme.space(mr)}px;
    margin-left: ${theme.space(ml)}px;
    ::placeholder {
      color: ${theme.colors.textMuted};
      opacity: 1; // for firefox
    }
  `}
`;

export const StyledInputWrapper = styled.div<Parameters<typeof inputStyle>[0]>`
  ${props => css`
    ${inputStyle({ hasMargin: false, ...props })}
    ${props.isDisabled
      ? css`
          cursor: auto;
          pointer-events: none;
          user-select: none;
          opacity: 0.3;
        `
      : css`
          &:hover {
            border-color: ${props.theme.colors.greyHover};
          }
          &:focus-within {
            border-color: ${props.theme.colors.blue};
          }
        `}
  `}
`;
