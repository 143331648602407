import { useTranslation } from 'react-i18next';

import { BillingPermission, UserPermission, hasPermissions } from '@edapp/permissions';
import { useUserInfo } from '@edapp/user-info';

import { Flags, useFeatureFlags } from '../feature-flags';
import type { MenuItemType } from './types';

export const useNavigationMenu = (): MenuItemType[] => {
  const { t } = useTranslation();

  const [enableMigrationTool] = useFeatureFlags([Flags.enableMigrationTool]);

  const {
    userInfo,
    billing,
    applicationInfo: { isUnifiedExperience }
  } = useUserInfo();

  /**
   * Hide the "Content" menu item entirely if the user can only edit courses,
   * like in the case of Restricted Author.
   */
  const hasOtherEditContentPermissions =
    hasPermissions(
      [UserPermission.EDIT_BRAIN_BOOST],
      userInfo.userPermissions,
      billing.permissions
    ) ||
    hasPermissions(
      [UserPermission.VIEW_RAPID_REFRESH],
      userInfo.userPermissions,
      billing.permissions
    ) ||
    hasPermissions([UserPermission.EDIT_PATHS], userInfo.userPermissions, billing.permissions);

  const navbarMenu: MenuItemType[] = [
    {
      title: t('navigation-menu.content.title'),
      rx: '^/(course|courses|lesson|v2/courses|paths|courseware)',
      permissions: [UserPermission.VIEW_COURSEWARE],
      isExcluded: hasOtherEditContentPermissions === false,
      submenu: [
        { title: t('navigation-menu.content.options.courses'), group: '', link: '/courseware' },
        {
          title: t('navigation-menu.content.options.course-library'),
          link: '/courseware?show=content-library',
          permissions: [UserPermission.VIEW_COURSE_LIBRARY]
        },
        {
          title: t('navigation-menu.content.options.rapid-refresh-quizzes'),
          link: '/courseware#rapid-refresh',
          permissions: [UserPermission.VIEW_RAPID_REFRESH]
        },
        {
          title: t('navigation-menu.content.options.brain-boost'),
          link: '/courseware#brain-boost',
          permissions: [UserPermission.EDIT_BRAIN_BOOST],
          anyPermissions: [
            BillingPermission.ACCESS_SPACED_REPETITION,
            BillingPermission.CAN_SHOW_UPGRADE_ACTIONS
          ]
        },
        {
          title: t('navigation-menu.content.options.paths'),
          link: '/courseware#paths',
          permissions: [UserPermission.EDIT_PATHS],
          anyPermissions: [
            BillingPermission.ACCESS_PLAYLIST,
            BillingPermission.CAN_SHOW_UPGRADE_ACTIONS
          ]
        }
      ]
    },
    {
      title: t('navigation-menu.users.title'),
      permissions: [UserPermission.EDIT_USERS],
      rx: '^/(users|user|settings)',
      isExcluded: isUnifiedExperience,
      submenu: [
        { title: t('navigation-menu.users.options.invite-users'), link: '/invite' },
        { title: t('navigation-menu.users.options.users'), link: '/users' },
        { title: t('navigation-menu.users.options.user-groups'), link: '/user-groups' }
      ]
    },
    {
      title: t('navigation-menu.engage.title'),
      permissions: [BillingPermission.ACCESS_ENGAGE],
      rx: '^/(banners|draws|draw|draw-prize|prize|leaderboard|peer)',
      submenu: [
        { title: t('navigation-menu.engage.options.achievements'), link: '/achievements' },
        { title: t('navigation-menu.engage.options.banners'), link: '/banners' },
        {
          title: t('navigation-menu.engage.options.leaderboards'),
          link: '/leaderboards',
          anyPermissions: [
            BillingPermission.ACCESS_LEADERBOARDS,
            BillingPermission.CAN_SHOW_UPGRADE_ACTIONS
          ]
        },
        {
          title: t('navigation-menu.engage.options.prize-draws'),
          link: '/draws',
          permissions: [UserPermission.ACCESS_PRIZING]
        },
        {
          title: t('navigation-menu.engage.options.prize-templates'),
          link: '/prizes',
          permissions: [UserPermission.ACCESS_PRIZING]
        }
      ]
    },
    {
      title: t('navigation-menu.facilitate.title'),
      rx:
        '^/(assignments|discussions|observations|group-training|submission|peer|analytics/social-learning)',
      anyPermissions: [UserPermission.VIEW_CONTENT_SOCIAL, UserPermission.VIEW_SOCIAL_ANALYTICS],
      submenu: [
        {
          title: t('navigation-menu.facilitate.options.practical-assessments'),
          link: '/practical-assessments'
        },
        {
          title: t('navigation-menu.facilitate.options.group-training'),
          link: '/group-training'
        },
        {
          title: t('navigation-menu.facilitate.options.discussions'),
          link: '/discussions',
          anyPermissions: [BillingPermission.ACCESS_DISCUSSION_AND_ASSIGNMENT],
          hideIfDisabled: true
        },
        {
          title: t('navigation-menu.facilitate.options.assignments'),
          link: '/assignments',
          permissions: [BillingPermission.ACCESS_DISCUSSION_AND_ASSIGNMENT],
          hideIfDisabled: true
        },
        {
          title: t('navigation-menu.facilitate.options.social-learning'),
          link: '/analytics/social-learning',
          permissions: [
            BillingPermission.ACCESS_SOCIAL_LEARNING,
            UserPermission.VIEW_CONTENT_SOCIAL
          ]
        },
        {
          title: t('navigation-menu.facilitate.options.peer-authoring'),
          link: '/peer-authoring',
          permissions: [BillingPermission.ACCESS_PEER_AUTHORING, UserPermission.EDIT_CONTENT]
        }
      ]
    },
    {
      title: t('navigation-menu.analytics.title'),
      rx: '^/(analytics|v2/analytics)(?!/social-learning)',
      anyPermissions: [
        UserPermission.MANAGER_ANALYTICS,
        UserPermission.VIEW_CONTENT_ANALYTICS,
        UserPermission.VIEW_USER_ANALYTICS
      ],
      submenuGroups: [
        {
          title: t('navigation-menu.analytics.groups.performance.title'),
          submenu: [
            {
              title: t(
                'navigation-menu.analytics.groups.performance.options.performance-dashboard'
              ),
              link: '/analytics/performance-dashboard',
              anyPermissions: [
                UserPermission.MANAGER_ANALYTICS,
                UserPermission.VIEW_USER_ANALYTICS
              ],
              permissions: [BillingPermission.ACCESS_PERFORMANCE_DASHBOARD]
            },
            {
              title: t('navigation-menu.analytics.groups.performance.options.course-summary'),
              link: '/analytics/course-summary',
              permissions: [
                UserPermission.VIEW_COURSE_SUMMARY_ANALYTICS,
                BillingPermission.ACCESS_COURSE_SUMMARY
              ]
            },
            {
              title: t('navigation-menu.analytics.groups.performance.options.stars'),
              link: '/analytics/stars',
              permissions: [
                UserPermission.VIEW_USER_ANALYTICS,
                BillingPermission.ACCESS_ANALYTICS_STARS,
                UserPermission.ACCESS_PRIZING
              ],
              group: 'Performance'
            },
            {
              title: t('navigation-menu.analytics.groups.performance.options.rapid-refresh'),
              anyPermissions: [
                UserPermission.MANAGER_ANALYTICS,
                UserPermission.VIEW_USER_ANALYTICS
              ],
              permissions: [BillingPermission.ACCESS_ANALYTICS_RAPID_REFRESH],
              link: '/analytics/rapid-refresh'
            }
          ]
        },
        {
          title: t('navigation-menu.analytics.groups.knowledge.title'),
          submenu: [
            {
              title: t('navigation-menu.analytics.groups.knowledge.options.brain-boost'),
              permissions: [
                UserPermission.VIEW_USER_ANALYTICS,
                BillingPermission.ACCESS_ANALYTICS_COURSE_COMPLETION_BY_USER,
                BillingPermission.ACCESS_SPACED_REPETITION
              ],
              link: '/analytics/brain-boost'
            },
            {
              title: t(
                'navigation-menu.analytics.groups.knowledge.options.course-completion-by-user'
              ),
              link: '/analytics/course-completion-by-user',
              anyPermissions: [
                UserPermission.MANAGER_ANALYTICS,
                UserPermission.VIEW_USER_ANALYTICS
              ],
              permissions: [BillingPermission.ACCESS_ANALYTICS_COURSE_COMPLETION_BY_USER]
            },
            {
              title: t('navigation-menu.analytics.groups.knowledge.options.training-matrix'),
              link: '/analytics/training-matrix-table',
              anyPermissions: [
                UserPermission.MANAGER_ANALYTICS,
                UserPermission.VIEW_USER_ANALYTICS
              ],
              permissions: [BillingPermission.ACCESS_ANALYTICS_COURSE_COMPLETION_BY_USER]
            }
          ]
        },

        {
          title: t('navigation-menu.analytics.groups.engagement.title'),
          submenu: [
            {
              title: t('navigation-menu.analytics.groups.engagement.options.activity-dashboard'),
              link: '/analytics/activity-dashboard',
              permissions: [
                UserPermission.VIEW_USER_ANALYTICS,
                BillingPermission.ACCESS_ANALYTICS_APP_USAGE,
                BillingPermission.ACCESS_ANALYTICS_LOGIN_STATS
              ]
            },
            {
              title: t('navigation-menu.analytics.groups.engagement.options.activity-feed'),
              link: '/analytics/activity',
              permissions: [
                UserPermission.VIEW_USER_ANALYTICS,
                BillingPermission.ACCESS_ANALYTICS_ACTIVITY_FEED
              ],
              applicationFeature: 'activityFeed'
            },
            {
              title: t('navigation-menu.analytics.groups.engagement.options.reports'),
              link: '/analytics/engagement/reports',
              permissions: [
                UserPermission.VIEW_USER_ANALYTICS,
                BillingPermission.ACCESS_ANALYTICS_ACTIONABLE_REPORTS
              ]
            }
          ]
        },

        {
          title: t('navigation-menu.analytics.groups.feedback.title'),
          submenu: [
            {
              title: t('navigation-menu.analytics.groups.feedback.options.surveys'),
              link: '/analytics/surveys',
              permissions: [
                UserPermission.VIEW_CONTENT_ANALYTICS,
                BillingPermission.ACCESS_ANALYTICS_SURVEYS
              ]
            }
          ]
        }
      ]
    }
  ];

  const superAdminMenu: MenuItemType = {
    title: t('navigation-menu.admin.title'),
    rx: '^/(applications|admin)',
    permissions: ['ed-admin', 'safety-culture-admin'],
    submenu: [
      {
        title: t('navigation-menu.admin.options.search-organization'),
        link: '/organizations/search',
        permissions: ['ed-admin']
      },
      {
        title: t('navigation-menu.admin.options.accounts'),
        link: '/applications',
        permissions: ['ed-admin']
      },
      {
        title: t('navigation-menu.admin.options.find-user'),
        link: '/admin/find-user'
      },
      {
        title: t('navigation-menu.admin.options.tag-management'),
        link: '/admin/tags-managment',
        permissions: ['ed-admin']
      },
      {
        title: "Organization's Features",
        link: '/admin/organization-features',
        permissions: ['ed-admin', 'safety-culture-admin']
      }
    ]
  };

  if (enableMigrationTool) {
    superAdminMenu?.submenu?.splice(1, 0, {
      title: t('navigation-menu.admin.options.migration-tool'),
      link: '/migration-tool',
      permissions: ['ed-admin', 'safety-culture-admin']
    });
  }

  navbarMenu.push(superAdminMenu);

  return navbarMenu;
};
