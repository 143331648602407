import * as React from 'react';

import { debounce } from 'lodash-es';
import { useSelector } from 'react-redux';
import ReactAsyncSelect from 'react-select/async';
import styled, { useTheme } from 'styled-components';

import type { SelectOption } from '@edapp/ed-components';
import stylesFn from '@edapp/ed-components/src/common/form/select/stylesFn';
import { RequestUtils, useAPIAccess } from '@edapp/request';
import { getApplicationNameAutocomplete } from '@rio/api/accounts/autocomplete';
import { getEmilyCmsCredentials, getUserAppName } from '@rio/store/config/selectors';

const MIN_SEARCH_TERM_LENGTH = 3;

export const AdminAccountLookup: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const theme = useTheme();
  const customStyles = React.useMemo(
    () => stylesFn(theme, { width: 'max-content', minWidth: 240, right: 0 }),
    [theme]
  );
  const { apiUrl, token } = useAPIAccess();
  const { emilyCmsUrl } = useSelector(getEmilyCmsCredentials);
  const userAppName = useSelector(getUserAppName);

  const _handleLoadOptions = (
    searchTerm: string,
    callback: (options: SelectOption<string>[]) => void
  ) => {
    const trimmedSearchTerm = searchTerm.trim();
    if (!trimmedSearchTerm || trimmedSearchTerm.length < MIN_SEARCH_TERM_LENGTH) {
      callback([]);
      return;
    }

    getApplicationNameAutocomplete(apiUrl, token, searchTerm).then(result => {
      const options =
        result.items.map<SelectOption<string>>(app => ({
          label: app.name,
          value: app.id
        })) || [];
      callback(options);
    });
  };

  const handleLoadOptions = debounce(_handleLoadOptions, 500);

  const handleChange = (option: SelectOption<string>) => {
    setLoading(true);
    RequestUtils.httpFetch('POST', `${emilyCmsUrl}/admin/switch-account`, token, {
      'switch-account': option.value,
      'switch-account-name': option.label
    }).catch(() => {
      window.location.reload();
      setLoading(false);
    });
  };

  const getEmptyStateMessage = React.useCallback(
    ({ inputValue: searchTerm }: { inputValue: string }) => {
      const trimmedSearchTerm = searchTerm.trim();

      if (!trimmedSearchTerm) {
        return 'Please type an account name.';
      }

      if (trimmedSearchTerm.length < MIN_SEARCH_TERM_LENGTH) {
        return 'Please type at least 3 characters.';
      }

      return 'This account does not exist.';
    },
    []
  );

  return (
    <StyledSelect
      styles={customStyles}
      menuPosition="fixed"
      menuPlacement="auto"
      loadOptions={handleLoadOptions}
      onChange={handleChange}
      disabled={loading}
      placeholder={userAppName}
      components={{ IndicatorSeparator: undefined }}
      cacheOptions
      noOptionsMessage={getEmptyStateMessage}
    />
  );
};

const StyledSelect = styled(ReactAsyncSelect)`
  width: 240px;
`;
