import type { UseHippoMutationOptions, UseHippoMutationResult } from '@edapp/request';
import { useHippoMutation } from '@edapp/request';

import { updateUserProfile } from './api';
import type { UpdateUserProfileRequest } from './types';

/**
 * Update first and last name for existing admins and authors
 */
export const useUpdateUserProfile = (
  options?: UseHippoMutationOptions<void, UpdateUserProfileRequest>
): UseHippoMutationResult<void, UpdateUserProfileRequest> => {
  return useHippoMutation(
    (hippoUrl, userToken) => request => updateUserProfile(hippoUrl, userToken, request),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        options?.onSuccess?.(data, variables, context);
      }
    }
  );
};
