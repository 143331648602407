import { ModalActionTypes } from './constants';
import type { ModalType, ModalActionsUnionType } from './types';

export const initialRingoModalsState: ModalType[] = [];

const reducer = (state = initialRingoModalsState, action: ModalActionsUnionType) => {
  switch (action.type) {
    case ModalActionTypes.OPEN_MODAL: {
      const { payload } = action;
      const newOpenArray = state.slice();
      const isModalOpen =
        newOpenArray.findIndex((modal: ModalType) => modal.name === payload.name) >= 0;
      if (isModalOpen) {
        return newOpenArray;
      }
      newOpenArray.splice(state.length, 0, payload);
      return newOpenArray;
    }

    case ModalActionTypes.CLOSE_MODAL: {
      const { payload } = action;
      const newCloseArray = state.slice();
      const modalIndex = state.findIndex((modal: ModalType) => modal.name === payload);
      newCloseArray.splice(modalIndex, 1);
      return newCloseArray;
    }

    default:
      return state;
  }
};

export default reducer;
