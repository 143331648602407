export const routes = {
  leaderboards: {
    name: 'Leaderboards',
    path: '/leaderboards',
    exact: true,
    getRoute: () => '/leaderboards'
  },
  leaderboard: {
    name: 'Leaderboard',
    path: '/leaderboard/:leaderboardId',
    exact: true,
    getRoute: ({ leaderboardId }: { leaderboardId: string }) => `/leaderboard/${leaderboardId}`
  }
};
