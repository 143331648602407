import * as actions from './actions';
import type { CourseCollectionCourseItem, CourseCollectionState } from './types';
import { spreadMutated, createMutated } from '../reducerUtils';

export const initialCourseCollectionState: CourseCollectionState = {
  mutateError: '',
  mutateLoading: false,

  fetchError: '',
  fetchLoading: false,

  listFetchError: '',
  listFetchLoading: false,

  allCoursesLoading: false,

  courseCollections: {},

  courses: {
    currentPage: 1,
    totalCount: 0,
    items: []
  }
};

export const courseCollectionReducer: Reducer<CourseCollectionState> = (
  state = initialCourseCollectionState,
  action
): CourseCollectionState => {
  switch (action.type) {
    case actions.CREATE_COURSE_COLLECTION: {
      return { ...state, mutateLoading: true, mutateError: '' };
    }
    case actions.CREATE_COURSE_COLLECTION_SUCCESS: {
      return { ...state, mutateLoading: false, mutateError: '' };
    }
    case actions.CREATE_COURSE_COLLECTION_FAILURE: {
      const { message } = action.payload as actions.CreateCourseCollectionFailure;
      return { ...state, mutateLoading: false, mutateError: message };
    }

    case actions.SAVE_COURSE_COLLECTION: {
      const { collectionId, data } = action.payload as actions.SaveCourseCollection;
      return {
        ...state,
        mutateLoading: true,
        mutateError: '',
        courseCollections: {
          ...state.courseCollections,
          [collectionId]: {
            ...spreadMutated(state.courseCollections[collectionId], data)
          }
        }
      };
    }
    case actions.SAVE_COURSE_COLLECTION_SUCCESS: {
      return { ...state, mutateLoading: false, mutateError: '' };
    }
    case actions.SAVE_COURSE_COLLECTION_FAILURE: {
      const { message } = action.payload as actions.SaveCourseCollectionFailure;
      return { ...state, mutateLoading: false, mutateError: message };
    }

    case actions.FETCH_COURSE_COLLECTION: {
      return { ...state, fetchLoading: true, fetchError: '' };
    }
    case actions.FETCH_COURSE_COLLECTION_SUCCESS: {
      const courseCollection = action.payload as actions.FetchCourseCollectionSuccess;
      return {
        ...state,
        fetchLoading: false,
        fetchError: '',
        courseCollections: {
          ...state.courseCollections,
          [courseCollection.id]: {
            ...createMutated(courseCollection)
          }
        }
      };
    }
    case actions.FETCH_COURSE_COLLECTION_FAILURE: {
      const { message } = action.payload as actions.FetchCourseCollectionFailure;
      return { ...state, fetchLoading: false, fetchError: message };
    }

    case actions.FETCH_COURSE_COLLECTION_COURSE_LIST: {
      const { page } = action.payload as actions.FetchCourseCollectionCourseList;
      if (page === 1) {
        return {
          ...state,
          listFetchLoading: true,
          listFetchError: '',
          courses: { ...initialCourseCollectionState.courses }
        };
      }

      return {
        ...state,
        listFetchLoading: true,
        listFetchError: '',
        courses: { ...state.courses, currentPage: page }
      };
    }
    case actions.FETCH_COURSE_COLLECTION_COURSE_LIST_SUCCESS: {
      const {
        items,
        totalCount
      } = action.payload as actions.FetchCourseCollectionCourseListSuccess;
      const currentItemsIds = state.courses.items.map(item => item.id);
      const newItems = items.filter(item => !currentItemsIds.includes(item.id));
      return {
        ...state,
        listFetchLoading: false,
        listFetchError: '',
        courses: {
          ...state.courses,
          totalCount,
          items: [...state.courses.items, ...newItems]
        }
      };
    }
    case actions.FETCH_COURSE_COLLECTION_COURSE_LIST_FAILURE: {
      const { message } = action.payload as actions.FetchCourseCollectionFailure;
      return { ...state, listFetchLoading: false, listFetchError: message };
    }

    case actions.CHANGE_RANK_COURSE_COLLECTION_COURSE_LIST: {
      const { courseIds } = action.payload as actions.ChangeRankCourseCollectionCourseList;
      return {
        ...state,
        mutateError: '',
        mutateLoading: true,
        courses: {
          ...state.courses,
          totalCount: state.courses.totalCount,
          items: [
            ...courseIds.map(id => state.courses.items.find(courseItem => courseItem.id === id)!)
          ]
        }
      };
    }
    case actions.CHANGE_RANK_COURSE_COLLECTION_COURSE_LIST_FAILURE: {
      const { message } = action.payload as actions.ChangeRankCourseCollectionCourseListFailure;
      return { ...state, mutateError: message, mutateLoading: false };
    }
    case actions.CHANGE_RANK_COURSE_COLLECTION_COURSE_LIST_SUCCESS: {
      return { ...state, mutateError: '', mutateLoading: false };
    }

    case actions.MOVE_COURSE_TO_COLLECTION: {
      return { ...state, mutateError: '', mutateLoading: true };
    }
    case actions.MOVE_COURSE_TO_COLLECTION_SUCCESS: {
      return { ...state, mutateError: '', mutateLoading: false };
    }
    case actions.MOVE_COURSE_TO_COLLECTION_FAILURE: {
      const { message } = action.payload as actions.MoveCourseToCollectionFailure;
      return { ...state, mutateError: message, mutateLoading: false };
    }

    case actions.CLEAR_COURSE_COLLECTION: {
      return {
        ...initialCourseCollectionState,
        courseCollections: { ...state.courseCollections }
      };
    }

    case actions.DELETE_COURSE_FROM_COLLECTION: {
      const { courseId } = action.payload as actions.DeleteCourseFromCollection;
      return {
        ...state,
        courses: {
          ...state.courses,
          items: [
            ...state.courses.items.filter(
              (course: CourseCollectionCourseItem) => course.id !== courseId
            )
          ]
        }
      };
    }

    default:
      return state;
  }
};
