import * as React from 'react';

import type { MultiValueProps } from 'react-select/src/components/MultiValue';
import styled, { css } from 'styled-components';

import { Pill } from '../../../pill';
import type { Option } from '../types';

export function MultiValueContainerComponent<OptionValueType>({
  className,
  children,
  data
}: MultiValueProps<Option<OptionValueType>>) {
  return (
    <StyledPill className={className}>
      {data?.adornment}
      {children}
    </StyledPill>
  );
}

const StyledPill = styled(Pill)`
  ${({ theme }) => css`
    gap: ${theme.space(0.5)}px;
  `}
`;

export const MultiValueContainer = styled(MultiValueContainerComponent)`
  ${({ theme, selectProps }) => css`
    color: ${selectProps.isDisabled ? theme.colors.textMuted : theme.colors.text};
    padding: ${theme.space(0.25)}px ${theme.space()}px;
    margin: ${theme.sizes.pillMargin}px;
  `}
`;
