import * as React from 'react';

import { useDispatch } from 'react-redux';

import type { UserPermission } from '@edapp/permissions';
import {
  UserInfoContext,
  initialApplicationData,
  initialBillingData,
  initialUserData,
  useGetUserInfo
} from '@edapp/user-info';
import {
  updateApplicationData,
  updateBillingData,
  updateUserData,
  updateUserInfoLoading
} from '@rio/store/config/actions';
import type { ConfigStore } from '@rio/store/config/types';

type Props = {
  config: Partial<ConfigStore>;
};

export const UserInfoProvider: React.FC<React.PropsWithChildren<Props>> = ({ children, config }) => {
  const dispatch = useDispatch();

  const defaultData = React.useMemo(
    () => ({
      user: {
        ...initialUserData,
        userId: config.userId || '',
        userEmail: config.userEmail || '',
        userFirstName: config.userFirstName || '',
        userLastName: config.userLastName || '',
        userName: config.userName || '',
        userIsAdministrator: config.isUserAdmin || false,
        userApplicationId: config.userAppId || '',
        userRoles: config.userRoles || [],
        userPermissions:
          config.userPermissions?.map(p => p['permissionId'] as UserPermission) ?? [],
        hasVisitedBrainBoost: config.hasVisitedBrainBoost || false,
        hasVisitedCustomAchievement: config.hasVisitedCustomAchievement || false,
        userAvatarUrl: config.userProfile?.data.avatarUrl || ''
      },
      application: {
        ...initialApplicationData,
        name: config.userAppName || '',
        isSponsor: config.isSponsor || false,
        isUnifiedExperience: config.uxpEnabled || false
      },
      billing: {
        permissions: config.billing?.permissions || [],
        currentPlan: config.billing?.currentPlan || initialBillingData.currentPlan,
        hasParticipatedInTrial: config.billing?.hasParticipatedInTrial || false,
        planAccess: config.billing?.planAccess || {
          hasAccess: true,
          hasAccessReason: null,
          lockedDate: null,
          lessonLimit: null
        },
        planIds: config.billing?.planIds || []
      }
    }),
    [config]
  );

  const { data, isLoading } = useGetUserInfo({
    enabled: !!config.userToken,
    onSuccess: ({ user, application, billing }) => {
      dispatch(updateUserData(user));
      dispatch(updateApplicationData(application));
      dispatch(updateBillingData(billing));
      dispatch(updateUserInfoLoading(false));
    },
    placeholderData: defaultData
  });

  if (!data) {
    return (
      <UserInfoContext.Provider
        value={{
          userInfo: defaultData.user,
          applicationInfo: defaultData.application,
          billing: defaultData.billing,
          dataLoaded: !isLoading
        }}
      >
        {children}
      </UserInfoContext.Provider>
    );
  }

  return (
    <UserInfoContext.Provider
      value={{
        userInfo: data.user,
        applicationInfo: data.application,
        billing: data.billing,
        dataLoaded: !isLoading
      }}
    >
      {children}
    </UserInfoContext.Provider>
  );
};
