import type { AssessmentSubmission, AssessmentSubmissionFeedback } from './types';
import type { AssessmentType } from '@edapp/hippo-client';
import { initialAssessment } from './constants';
import type { Comment } from '../shared';
import type { StoreState } from './types';

export const getAssessment = (state: StoreState, assessmentId: string): AssessmentType => {
  const assessment = state.assessment.assessments[assessmentId];

  if (!assessment) {
    return { ...initialAssessment };
  }

  return { ...assessment.original };
};

export const getSubmission = (
  state: StoreState,
  submissionId: string
): AssessmentSubmission | undefined => {
  const submission = state.assessment.submissions[submissionId];

  return submission;
};

export const getFeedback = (
  state: StoreState,
  submissionId: string
): AssessmentSubmissionFeedback | undefined => {
  const feedback = state.assessment.feedback[submissionId];

  return feedback;
};

export const getAssessmentComments = (
  state: StoreState,
  submissionId: string
): { totalCount: number; items: Comment[]; currentPage: number } => {
  const comments = state.assessment.comments[submissionId];

  if (!comments) {
    return { totalCount: 0, items: [], currentPage: 1 };
  }

  return comments;
};

const getAssessmentSummariesState = (state: StoreState) => state.assessment.assessmentSummaries;

export const getFetchAssessmentLoadingStatus = (state: StoreState) =>
  state.assessment.fetchAssessmentLoading;

export const getFetchAssessmentError = (state: StoreState) => state.assessment.fetchAssessmentError;

export const getFetchSubmissionLoadingStatus = (state: StoreState) =>
  state.assessment.fetchSubmissionLoading;

export const getAssessmentMutateLoading = (state: StoreState) =>
  state.assessment.mutateAssessmentLoading;

export const getFetchSubmissionError = (state: StoreState) => state.assessment.fetchSubmissionError;

export const getAssessmentSummariesItems = (state: StoreState) =>
  getAssessmentSummariesState(state).items;

export const getAssessmentSummariesTotalCount = (state: StoreState) =>
  getAssessmentSummariesState(state).totalCount;

export const getAssessmentSummariesCurrentPage = (state: StoreState) =>
  getAssessmentSummariesState(state).currentPage;

export const getAssessmentSummariesLoadingStatus = (state: StoreState) =>
  getAssessmentSummariesState(state).isLoading;

export const getAssessmentSummariesError = (state: StoreState) =>
  getAssessmentSummariesState(state).error;

const getSubmissionsState = (state: StoreState) => state.assessment.submissionSummaries;

export const getSubmissionSummariesByAssessmentId = (state: StoreState) =>
  getSubmissionsState(state).byAssessmentId;

export const getSubmissionSummariesLoadingStatus = (state: StoreState) =>
  getSubmissionsState(state).isLoading;

export const getSubmissionSummariesError = (state: StoreState) => getSubmissionsState(state).error;

export const getAddSubmissionCommentLoadingStatus = (state: StoreState) =>
  state.assessment.addSubmissionCommentLoading;

export const getAddSubmissionCommentError = (state: StoreState) =>
  state.assessment.addSubmissionCommentError;

export const getAddSubmissioFeedbackLoadingStatus = (state: StoreState) =>
  state.assessment.addSubmissionFeedbackLoading;

export const getEditSubmissionFeedbackLoadingStatus = (state: StoreState) =>
  state.assessment.editSubmissionFeedbackLoading;
