import type { AuthoringTypes } from '@edapp/authoring-logic';
import { genId } from '@edapp/utils';

import type {
  LessonType,
  SaveLessonSlideAnswerType,
  SaveLessonSlideQuestionType,
  SaveLessonSlideType,
  UpdateLessonType
} from './types';

// We have to do this mapping because the backend has two different types
// When retrieving data (Http GET) we are getting "LessonApiResource"
// But, when saving (Http PUT|POST) we are saving "LessonDomain"
// Not exactly like that, but that's the idea of it...
const mapGetSlideAnswerToSaveSlideAnswer = (
  getAnswer: AuthoringTypes.Answer
): SaveLessonSlideAnswerType => ({
  answerId: getAnswer.id || genId(),
  content: getAnswer.content,
  correct: getAnswer.correct,
  text: getAnswer.text,
  type: getAnswer.type
});

const mapGetSlideQuestionToSaveSlideQuestion = (
  getQuestion: AuthoringTypes.Question
): SaveLessonSlideQuestionType => ({
  questionDetails: {
    category: getQuestion.question.category,
    content: getQuestion.question.content,
    questionId: getQuestion.question.id || genId(),
    text: getQuestion.question.text,
    type: getQuestion.question.type
  },
  answers: getQuestion.answers.map(answer => {
    return mapGetSlideAnswerToSaveSlideAnswer(answer);
  })
});

const mapGetSlideToSaveSlideType = (getSlide: AuthoringTypes.SlideType): SaveLessonSlideType => ({
  displayIndex: getSlide.displayIndex,
  id: getSlide.id,
  permanent: getSlide.permanent || false,
  metadata: {
    friedrichId: getSlide.metadata.friedrichId,
    friedrichType: getSlide.metadata.friedrichType,
    slideId: getSlide.metadata.slideId,
    questions: (getSlide.metadata.questions || []).map((question: AuthoringTypes.Question) => {
      return mapGetSlideQuestionToSaveSlideQuestion(question);
    })
  },
  name: getSlide.name as string,
  templateDescription: getSlide.templateDescription!,
  templateName: getSlide.templateName,
  type: getSlide.type,
  subtype: getSlide.subtype || 'default',
  data: getSlide.data
});

export const mapLessonTypeToUpdateLessonType = (
  lesson: LessonType,
  isCopy: boolean = false
): UpdateLessonType => ({
  courseId: lesson.courseId,
  description: lesson.description,
  displayActive: lesson.display.active,
  externalId: lesson.externalId,
  hideContentType: lesson.hideContentType,
  hideProgress: lesson.hideProgress,
  hideScore: lesson.hideScore,
  isCopy,
  isRateable: lesson.isRateable,
  lockIntervalMin: lesson.features.attemptRestriction.lockIntervalMin,
  lockOnCompletion: lesson.features.attemptRestriction.lockOnCompletion,
  metaTags: lesson.metaTags,
  planning: lesson.planning,
  platformAvailabilities: lesson.availability ? lesson.availability.platforms : [],
  prerequisites: lesson.prerequisites,
  public: lesson.public,
  resetCourseOnNumberOfAttempts: lesson.features.attemptRestriction.resetCourseOnNumberAttempts,
  styleConfiguration: lesson.configuration.styleConfiguration,
  slides: lesson.configuration.slides.map(slide => {
    return mapGetSlideToSaveSlideType(slide);
  }),
  starOptionsEnabled: lesson.features.starOptions.enabled,
  title: lesson.title,
  useCourseBranding: lesson.useCourseBranding,
  scoring: lesson.scoring,
  showStarProgressScore: lesson.showStarProgressScore
});
