import * as React from 'react';

import { Link } from 'react-router-dom';
import { Col } from 'react-styled-flexboxgrid';
import styled, { css } from 'styled-components';
import type { StyledComponentPropsWithRef } from 'styled-components';

import {
  BackgroundImage,
  Box,
  Button,
  CoursewareStatus,
  CoursewareStatusLabel,
  Typography
} from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';
import { rgba } from '@edapp/themes';

export type CardProps = {
  id: string;
  media?: React.ReactNode;
  subtext?: React.ReactNode;
  title: React.ReactNode;
  description?: React.ReactNode;
  coursewareStatus?: CoursewareStatus;
  /**
   * @deprecated Use coursewareStatus instead
   * @see CoursewareStatus
   */
  isPublished?: boolean;
  notPublishedText?: string;
  hideIsPublishedStatus?: boolean;
  isDragging?: boolean;
  isStacked?: boolean;
  selectionOverlay?: React.ReactNode;
  hoverOverlay?: React.ReactNode;
  showHoverOverlayWhenSelected?: boolean;
  bottomActions?: React.ReactNode;
  bodyLink?: { pathname: string; state?: {} };
  bodyOnClick?: () => void;
  selectable?: boolean;
  selected?: boolean;
  imageLoading?: 'eager' | 'lazy';
  showSubtext?: boolean;
  testId?: string;
} & StyledComponentPropsWithRef<typeof StyledCard>;

export const getTestId = (id: string, selected?: boolean) =>
  `content-card-contents-${id}${!!selected ? ` content-card-selected` : ''}`;

const SummaryCardComponent = React.forwardRef<HTMLDivElement, CardProps>(
  (
    {
      hideIsPublishedStatus = false,
      isDragging = false,
      isStacked = false,
      showSubtext = true,
      showHoverOverlayWhenSelected = true,
      imageLoading = 'lazy',
      id,
      media,
      subtext,
      title,
      description,
      isPublished: isPublishedStatus,
      coursewareStatus = !!isPublishedStatus ? CoursewareStatus.PUBLISHED : CoursewareStatus.DRAFT,
      notPublishedText,
      selectionOverlay,
      hoverOverlay,
      bottomActions,
      bodyOnClick,
      bodyLink,
      selectable,
      selected,
      testId,
      ...rest
    },
    ref
  ) => {
    const isPublished = isPublishedStatus || coursewareStatus === CoursewareStatus.PUBLISHED;
    const card = (
      <StyledCard
        rounded
        ref={ref}
        bgColor="white"
        selected={selected}
        isStacked={isStacked}
        testId={testId || getTestId(id)}
        selectable={selectable}
        showHoverOverlayWhenSelected={showHoverOverlayWhenSelected}
        isDragging={isDragging}
        border
        boxShadow={isDragging ? 'deep' : undefined}
        onClick={selectable !== false || selected === true ? bodyOnClick : undefined}
        {...rest}
      >
        <ImgWrapper selected={selected}>
          {typeof media === 'string' ? (
            <CardCover
              src={media}
              isPublished={isPublished}
              transformation="card_thumbnail"
              loading={imageLoading}
            />
          ) : (
            media
          )}
          {!isPublished && !hideIsPublishedStatus && (
            <TopLeftStatus coursewareStatus={coursewareStatus} testId="coursewareStatus" />
          )}

          {!!hoverOverlay && (
            <HoverOverlay data-testid="summary-card-hover-overlay">{hoverOverlay}</HoverOverlay>
          )}

          {!!(selectable && selectionOverlay) && (
            <SelectionOverlay>{selectionOverlay}</SelectionOverlay>
          )}
        </ImgWrapper>

        <CardDetails data-testid="card-details">
          {showSubtext && (
            <SubtextContainer variant="subtext" color="textMuted">
              {subtext}
            </SubtextContainer>
          )}
          <TextContainer>
            <Title<'h3'>
              data-testid={`card-title-${title}`}
              variant="titleMedium"
              component="h3"
              color={isPublished ? 'surface.text.default' : 'surface.text.weaker'}
            >
              {title}
            </Title>
            {description && (
              <Description variant="small" color={'textMuted'}>
                {description}
              </Description>
            )}
          </TextContainer>
          {bottomActions && (
            <BottomActionsArea data-testid="bottom-actions-area">{bottomActions}</BottomActionsArea>
          )}
        </CardDetails>
      </StyledCard>
    );
    return bodyLink ? (
      <StyledLink to={bodyLink} isStacked={isStacked}>
        {card}
      </StyledLink>
    ) : (
      card
    );
  }
);

const overlayStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
`;

const SelectionOverlay = styled.div`
  pointer-events: none;
  ${overlayStyle}
`;

export const HoverOverlay = styled.div`
  background-color: ${({ theme }) => rgba(theme.colors.fixedBlack, 0.8)};
  opacity: 0;
  transition: opacity ease-out 300ms;
  border-top-right-radius: ${({ theme }) => theme.sizes.borderRadius}px;
  border-top-left-radius: ${({ theme }) => theme.sizes.borderRadius}px;
  ${Button} {
    visibility: hidden;
    pointer-events: none;
  }
  ${overlayStyle}
`;

const BottomActionsArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  > *:first-child {
    margin-right: auto;
  }
`;

const hoverBoxShadow = `0 8px 16px 0 rgba(0, 0, 0, 0.16)`;

const StyledCard = styled(Box)<{
  selectable?: boolean;
  selected?: boolean;
  showHoverOverlayWhenSelected?: boolean;
  isStacked?: boolean;
  isDragging?: boolean;
}>`
  ${({
    theme,
    selected,
    selectable,
    showHoverOverlayWhenSelected,
    isStacked = false,
    isDragging = false
  }) => {
    const selectedBoxShadow = `0 0 0 1px ${theme.colors.blue}`;

    return css`
      background-color: ${isDragging ? theme.colors.grey : 'transparent'};
      min-height: 360px;
      border-radius: ${theme.sizes.borderRadius}px;
      position: relative;
      display: flex;
      flex-direction: column;
      cursor: ${selectable ? 'pointer' : 'inherit'};
      ${!!selected && `box-shadow: ${selectedBoxShadow};`}
      transition: all 300ms;

      &:hover {
        box-shadow: ${selected ? [hoverBoxShadow, selectedBoxShadow].join(', ') : hoverBoxShadow};
        ${showHoverOverlayStyle}
      }

      ${
        isStacked &&
        `
        z-index: 1;
        &:before {
          content: "";
          width: 100%;
          height: 100%;
          border: 1px solid ${theme.colors.greyHover};
          border-radius: ${theme.sizes.borderRadius}px;
          box-shadow: ${theme.shadows.shallow};
          position: absolute;
          left: ${theme.space()}px;
          top: ${theme.space()}px;
          z-index: -2;

        }
        &:after {
          content: "";
          width: 100%;
          height: 100%;
          border: 1px solid ${theme.colors.greyHover};
          border-radius: ${theme.sizes.borderRadius}px;
          box-shadow: ${theme.shadows.shallow};
          position: absolute;
          top:5px;
          left: ${theme.space(0.5)}px;
          z-index: -1;
        }
        `
      }

      ${!!(showHoverOverlayWhenSelected && selectable && selected) && showHoverOverlayStyle}

      ${Col} & {
        height: 100%;
      }
      ${
        isDragging &&
        `
        > * {
          opacity: 0;
        }
      `
      }
    `;
  }}
`;

export const CardCover = styled(BackgroundImage)<{ isPublished?: boolean }>`
  background-position: center;
  background-size: cover;
  border-radius: ${({ theme }) =>
    `${theme.sizes.borderRadius}px ${theme.sizes.borderRadius}px 0 0`};
  opacity: ${({ isPublished }) => (isPublished ? 1 : 0.3)};
  transition: opacity ease-out 300ms;
  width: 100%;
  height: 160px;
`;

export const ImgWrapper = styled.div<{ selected: boolean | undefined }>`
  position: relative;
  border-top-left-radius: ${({ theme }) => theme.sizes.borderRadius}px;
  border-top-right-radius: ${({ theme }) => theme.sizes.borderRadius}px;
  overflow: hidden;
  transition: all 100ms;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  flex-shrink: 0;
`;

export const CardDetails = styled.div(
  ({ theme }) => css`
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    background-color: ${theme.colors.white};
    border-bottom-left-radius: ${theme.sizes.borderRadius}px;
    border-bottom-right-radius: ${theme.sizes.borderRadius}px;
    padding: ${theme.space(2)}px;
  `
);

const TopLeftStatus = styled(CoursewareStatusLabel)`
  ${({ theme }) => css`
    position: absolute;
    top: ${theme.space(3)}px;
    left: ${theme.space(3)}px;
  `}
`;

const SubtextContainer = styled(Typography)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const TextContainer = styled.div`
  flex: 1 0 auto;
  margin-bottom: auto;
`;

export const Title = styled(Text)(
  ({ theme }) => css`
    display: block;
    margin: ${theme.space()}px 0 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    user-select: none;
    width: 100%;
  `
);

export const Description = styled(Typography)`
  ${({ theme }) => css`
    margin-top: ${theme.space()}px;
    margin-bottom: ${theme.space(2)}px;
    display: block;
    user-select: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;

    ${theme.lineClamp(2)}
  `}
`;

const StyledLink = styled(({ isStacked, ...rest }) => <Link {...rest} />)(
  ({ isStacked = false, theme }) =>
    `display: block;
  padding-bottom: ${isStacked ? `${theme.space(2)}px` : 0};
  &:hover {
    text-decoration: none;
  }
  &:focus {
    text-decoration: none;
  }
  ${Col} & {
    height: 100%;
  }`
);

const showHoverOverlayStyle = css`
  ${HoverOverlay} {
    opacity: 1;
    ${Button} {
      pointer-events: auto;
      visibility: visible;
    }
  }
`;

export const SummaryCard = styled(SummaryCardComponent)``;
