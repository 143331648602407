import { all } from 'redux-saga/effects';

import { FormSagas } from '@edapp/authoring-logic';
import { brainBoostSagas } from '@edapp/brain-boost';
import {
  assessmentSagas,
  courseSagas,
  coursewareSagas,
  discussionSagas,
  lessonSagas,
  tagsSagas
} from '@edapp/courseware-logic';
import actionableReportsSagas from '@rio/store/analytics/actionableReports/sagas';
import analyticsSurveysSagas from '@rio/store/analytics/surveys/sagas';
import bannersSagas from '@rio/store/banners/sagas';
import configSagas from '@rio/store/config/sagas';
import courseCollectionSagas from '@rio/store/courseCollection/sagas';
import peerAuthoringSagas from '@rio/store/peerAuthoring/sagas';
import playlistSagas from '@rio/store/playlists/sagas';
import requestSagas from '@rio/store/request/sagas';
import surveySagas from '@rio/store/survey/sagas';
import userGroupsSagas from '@rio/store/userGroups/sagas';

import { betterPPtxSagas } from './better-pptx/sagas';
import { customAchievementsSagas } from './custom-achievements/sagas';
import onboardingSagas from './onboarding/sagas';
import { rapidRefreshSagas } from './rapid-refresh/sagas';

export default function* rootSaga() {
  yield all([
    ...FormSagas,
    ...requestSagas,
    ...configSagas,
    ...surveySagas,
    ...courseCollectionSagas,
    ...userGroupsSagas,
    ...peerAuthoringSagas,
    ...courseSagas,
    ...coursewareSagas,
    ...lessonSagas,
    ...discussionSagas,
    ...assessmentSagas,
    ...actionableReportsSagas,
    ...playlistSagas,
    ...analyticsSurveysSagas,
    ...bannersSagas,
    ...tagsSagas,
    ...rapidRefreshSagas,
    ...onboardingSagas,
    ...customAchievementsSagas,
    ...brainBoostSagas,
    ...betterPPtxSagas
  ]);
}
