import { RequestActions } from '@edapp/request';
import { fork, takeLatest, put } from 'redux-saga/effects';

import * as actions from './actions';

function* handleFetchOnboardingChecklist(action: Action<actions.FetchOnboardingChecklist>) {
  const { name } = action.payload;

  yield put(
    RequestActions.getAuthed(
      `/api/onboarding/checklist/${name}`,
      actions.FETCH_ONBOARDING_CHECKLIST_SUCCESS,
      actions.FETCH_ONBOARDING_CHECKLIST_FAILURE
    )
  );
}

function* handleDismissOnboardingChecklist(action: Action<actions.DismissOnboardingChecklist>) {
  const { name } = action.payload;

  yield put(
    RequestActions.postAuthed(
      `/api/onboarding/checklist/${name}/dismiss`,
      actions.DISMISS_ONBOARDING_CHECKLIST_SUCCESS,
      actions.DISMISS_ONBOARDING_CHECKLIST_FAILURE
    )
  );
}

function* watchFetchOnboardingChecklist() {
  yield takeLatest(actions.FETCH_ONBOARDING_CHECKLIST, handleFetchOnboardingChecklist);
}
function* watchDismissOnboardingChecklist() {
  yield takeLatest(actions.DISMISS_ONBOARDING_CHECKLIST, handleDismissOnboardingChecklist);
}

const sagas = [fork(watchFetchOnboardingChecklist), fork(watchDismissOnboardingChecklist)];

export default sagas;
