import * as React from 'react';

import { connect } from 'react-redux';

import type { LmsStoreState } from '@rio/store/types';
import { BillingPermission } from '@rio/utils/permissions/constants';
import type { AccessPermissionType } from '@rio/utils/permissions/types';

export type PlanPermissionWrapperProps = {
  accessPermission: AccessPermissionType;
  children: React.ReactElement | ((permitted: boolean) => React.ReactElement);
};

type Props = PlanPermissionWrapperProps & ReturnType<typeof mapStateToProps>;

/**
 * This class only checks for permissions of Billing Plans -
 * If feature is included in your plan or not!
 */
class PlanPermissionWrapper extends React.PureComponent<Props> {
  render() {
    const { isAdmin, planPermissions, accessPermission } = this.props;
    const permitted = !!isAdmin || planPermissions.includes(BillingPermission[accessPermission]);

    if (typeof this.props.children === 'function') {
      return this.props.children(permitted);
    }

    if (!!permitted) {
      return this.props.children;
    }

    return null;
  }
}

const mapStateToProps = (state: LmsStoreState) => ({
  planPermissions: state.config.billing.permissions,
  isAdmin: state.config.isUserAdmin
});

export default connect(mapStateToProps)(PlanPermissionWrapper);
