import { fork, takeLatest, put } from 'redux-saga/effects';
import * as actions from './actions';
import { RequestActions } from '@edapp/request';

function* handleFetchPeerAuthoringLessons() {
  yield put(
    RequestActions.getAuthed(
      `api/peerAuthoring/lessons`,
      actions.FETCH_PEER_AUTHORING_LESSONS_SUCCESS,
      actions.FETCH_PEER_AUTHORING_LESSONS_FAILURE,
      false,
      { pageSize: 999 }
    )
  );
}

function* handleFetchSlideContributions(action: Action<{ lessonId: string; slideId: string }>) {
  const { lessonId, slideId } = action.payload;

  yield put(
    RequestActions.getAuthed(
      `api/peerAuthoring/${lessonId}/${slideId}/mediaWithComments`,
      actions.FETCH_SLIDE_CONTRIBUTIONS_SUCCESS,
      actions.FETCH_SLIDE_CONTRIBUTIONS_FAILURE,
      false,
      undefined
    )
  );
}

function* handleRemoveSlideContribution(
  action: Action<{ lessonId: string; slideId: string; contributionId: string }>
) {
  const { lessonId, slideId, contributionId } = action.payload;

  yield put(
    RequestActions.deleteAuthed(
      `api/peerAuthoring/${lessonId}/${slideId}/media/${contributionId}`,
      actions.REMOVE_SLIDE_CONTRIBUTION_SUCCESS,
      actions.REMOVE_SLIDE_CONTRIBUTION_FAILURE,
      false,
      undefined
    )
  );
}

function* handleRemoveContributionComment(
  action: Action<{
    lessonId: string;
    slideId: string;
    contributionId: string;
    commentId: string;
  }>
) {
  const { lessonId, slideId, contributionId, commentId } = action.payload;

  yield put(
    RequestActions.deleteAuthed(
      `api/peerAuthoring/${lessonId}/${slideId}/media/${contributionId}/comments/${commentId}`,
      actions.REMOVE_CONTRIBUTION_COMMENT_SUCCESS,
      actions.REMOVE_CONTRIBUTION_COMMENT_FAILURE,
      false,
      undefined
    )
  );
}

function* watchFetchPeerAuthoringLessons() {
  yield takeLatest(actions.FETCH_PEER_AUTHORING_LESSONS, handleFetchPeerAuthoringLessons);
}

function* watchFetchSlideContributions() {
  yield takeLatest(actions.FETCH_SLIDE_CONTRIBUTIONS, handleFetchSlideContributions);
}

function* watchRemoveSlideContribution() {
  yield takeLatest(actions.REMOVE_SLIDE_CONTRIBUTION, handleRemoveSlideContribution);
}

function* watchRemoveContributionComment() {
  yield takeLatest(actions.REMOVE_CONTRIBUTION_COMMENT, handleRemoveContributionComment);
}

const sagas = [
  fork(watchFetchPeerAuthoringLessons),
  fork(watchFetchSlideContributions),
  fork(watchRemoveSlideContribution),
  fork(watchRemoveContributionComment)
];

export default sagas;
