import type { CustomFieldResponse } from '@edapp/hippo-client';
import type { ConditionPredicate } from '@rio/components/common/ConditionComposer/types';
import { mergeDeep } from '@rio/utils/mergeDeep';

import { ActionType } from './actions';
import { ActionType as ConditionsActionType } from './conditions/actions';
import type { DynamicUserGroupConditionsPayload } from './conditions/types';
import { initialUserGroupItemState, initialUserGroupsState } from './constants';
import { ActionType as DrawsActionType } from './draws/actions';
import type { ActionTypes } from './types';
import type { Draws, UserGroupItemTypeRedux, UserGroupsState } from './types';

function mergeUserGroupItem(
  object: UserGroupItemTypeRedux,
  source: RecursivePartial<UserGroupItemTypeRedux>
) {
  return mergeDeep(object, source);
}

function mapConditions(
  predicates: DynamicUserGroupConditionsPayload['condition']['predicates'],
  customFields: CustomFieldResponse[]
) {
  return predicates.map<ConditionPredicate>(i => {
    const customField = customFields.find(cField => cField.fieldName === i.targetId);
    if (!customField) {
      throw Error(
        'Your dynamic user group contains a field that does not exist in the application settings.'
      );
    }

    return {
      argumentTypeId: i.argumentTypeId,
      arguments: i.arguments,
      operatorId: i.operatorId,
      target: {
        id: i.targetId,
        label: customField.fieldLabel
      }
    };
  });
}

export const userGroupsReducer = (
  state: UserGroupsState = initialUserGroupsState,
  action: ActionTypes
): UserGroupsState => {
  switch (action.type) {
    case ActionType.SET_USER_GROUP_ITEM: {
      const data = mergeUserGroupItem(state.userGroupItem, action.payload.userGroup);
      return {
        ...state,
        userGroupItem: { ...data }
      };
    }

    case ActionType.FETCH_USER_GROUP: {
      return {
        ...state,
        fetchLoading: true,
        fetchError: ''
      };
    }

    case ActionType.FETCH_USER_GROUP_SUCCESS: {
      return {
        ...state,
        fetchError: '',
        fetchLoading: false,
        userGroupItem: {
          ...initialUserGroupItemState,
          ...action.payload,
          draws: state.userGroupItem.draws, // draws are fetched separately
          usergroup: {
            ...initialUserGroupItemState.usergroup,
            ...action.payload.usergroup,
            conditions:
              action.payload.usergroup._id === state.userGroupItem.usergroup._id
                ? state.userGroupItem.usergroup.conditions // do not replace existing conditions with empty array in case of a refetch
                : []
          }
        }
      };
    }

    case ActionType.FETCH_USER_GROUP_FAILURE: {
      return {
        ...state,
        fetchError: action.payload.error.message,
        fetchLoading: false
      };
    }

    case ConditionsActionType.FETCH_USER_GROUP_CONDITIONS: {
      return {
        ...state,
        fetchingConditions: true,
        fetchingConditionsError: ''
      };
    }

    case ConditionsActionType.FETCH_USER_GROUP_CONDITIONS_SUCCESS: {
      return {
        ...state,
        fetchingConditions: false,
        fetchingConditionsError: '',
        userGroupItem: {
          ...mergeUserGroupItem(state.userGroupItem, {
            usergroup: {
              conditions: mapConditions(
                action.payload.condition.predicates,
                state.userGroupItem.app.customFields.user
              )
            }
          })
        }
      };
    }

    case ConditionsActionType.FETCH_USER_GROUP_CONDITIONS_FAILURE: {
      return {
        ...state,
        fetchingConditions: false,
        fetchingConditionsError: action.payload.message
      };
    }

    case DrawsActionType.FETCH_USER_GROUP_DRAWS: {
      return {
        ...state,
        fetchingDraws: true,
        fetchingDrawsError: ''
      };
    }

    case DrawsActionType.FETCH_USER_GROUP_DRAWS_SUCCESS: {
      return {
        ...state,
        fetchingDraws: false,
        userGroupItem: mergeUserGroupItem(state.userGroupItem, {
          draws: action.payload,
          usergroup: {
            draws: action.payload.reduce<Draws>(
              (acc, { id, tags }) =>
                tags.includes(state.userGroupItem.usergroup._id) ? [...acc, id] : acc,
              []
            )
          }
        })
      };
    }

    case DrawsActionType.FETCH_USER_GROUP_DRAWS_FAILURE: {
      return {
        ...state,
        fetchingDraws: false,
        fetchingDrawsError: action.payload.message
      };
    }

    case ActionType.SAVE_USER_GROUP: {
      return {
        ...state,
        mutateLoading: true,
        mutateError: ''
      };
    }

    case ActionType.SAVE_USER_GROUP_SUCCESS: {
      // we don't care about the success of emily
      // the final success comes from hippo
      return state;
    }

    case ConditionsActionType.CREATE_USER_GROUP_CONDITIONS_SUCCESS:
    case ConditionsActionType.UPDATE_USER_GROUP_CONDITIONS_SUCCESS: {
      return {
        ...state,
        mutateLoading: false,
        mutateError: ''
      };
    }

    case ConditionsActionType.CREATE_USER_GROUP_CONDITIONS_FAILURE:
    case ConditionsActionType.UPDATE_USER_GROUP_CONDITIONS_FAILURE: {
      return {
        ...state,
        mutateLoading: false,
        mutateError: action.payload.message
      };
    }

    case ActionType.SAVE_USER_GROUP_FAILURE: {
      return {
        ...state,
        mutateLoading: false,
        mutateError: action.payload.error.message
      };
    }

    case ActionType.FETCH_USER_GROUP_STATUS: {
      return { ...state, checkStatusLoading: true, checkStatusError: '' };
    }

    case ActionType.FETCH_USER_GROUP_STATUS_FAILURE: {
      return { ...state, checkStatusLoading: false, checkStatusError: action.payload.message };
    }

    case ActionType.FETCH_USER_GROUP_STATUS_SUCCESS: {
      return {
        ...state,
        checkStatusLoading: false,
        checkStatusError: '',
        allowedToEditStatus: action.payload
      };
    }

    case ActionType.FETCH_USER_GROUP_NAMES: {
      return {
        ...state,
        userGroupNames: {
          ...state.userGroupNames,
          error: '',
          loading: true
        }
      };
    }

    case ActionType.FETCH_USER_GROUP_NAMES_SUCCESS: {
      return {
        ...state,
        userGroupNames: {
          ...state.userGroupNames,
          loading: false,
          items: action.payload
        }
      };
    }

    case ActionType.FETCH_USER_GROUP_NAMES_FAILURE: {
      return {
        ...state,
        userGroupNames: {
          ...state.userGroupNames,
          loading: false,
          error: 'Unable to load user groups filter options.'
        }
      };
    }

    default:
      return state;
  }
};
