import { Box } from '@edapp/ed-components';
import * as React from 'react';
import { ChromePicker, SketchPicker } from 'react-color';
import type { PresetColor } from 'react-color/lib/components/sketch/Sketch';
import styled, { css } from 'styled-components';

const colorPickersizes = {
  sm: 16,
  md: 24,
  lg: 32
};

type ColorPickerSize = 'sm' | 'md' | 'lg';

type Props = {
  color: string;
  onChange: (color: string) => void;
  id?: string;
  testId?: string;
  presetColors?: PresetColor[];
  size?: ColorPickerSize;
  disabled?: boolean;
};

const ColorPicker: React.FC<Props> = ({
  color,
  onChange,
  id,
  testId,
  size = 'md',
  presetColors,
  disabled
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  const uniquePresetColors = [...new Set(presetColors)];

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (event.target && !containerRef?.current?.contains?.(event.target as Element)) {
        setIsOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef]);

  return (
    <StyledContainer id={id} testId={testId} ref={containerRef}>
      <StyledColorBlock
        bgColor={color}
        onClick={() => {
          if (disabled) return;
          setIsOpen(!isOpen);
        }}
        size={size}
        disabled={disabled}
      />
      {isOpen &&
        (presetColors ? (
          <StyledSketchPicker
            color={color}
            disableAlpha
            onChange={res => onChange(res.hex)}
            presetColors={uniquePresetColors}
          />
        ) : (
          <StyledChromePicker color={color} disableAlpha onChange={res => onChange(res.hex)} />
        ))}
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  position: relative;
`;

const StyledColorBlock = styled.div<{ size: ColorPickerSize; bgColor: string; disabled?: boolean }>(
  ({ theme, size, bgColor, disabled }) => css`
    margin-bottom: ${theme.space()}px;
    background-color: ${bgColor};
    width: ${colorPickersizes[size]}px;
    height: ${colorPickersizes[size]}px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: ${disabled ? 'not-allowed' : 'pointer'};
  `
);

const StyledChromePicker = styled(ChromePicker)`
  position: absolute;
  z-index: 1;
`;

const StyledSketchPicker = styled(SketchPicker)(
  ({ theme }) => css`
    position: absolute;
    z-index: 10;
    @media screen and (max-width: ${theme.flexboxgrid.breakpoints.sm}rem) {
      right: -${theme.space()}px;
    }
  `
);

// If you want to target the text for styling this will help:
//   & [id^='rc-editable-input'] {
//     font-size: 20px !important;
//   }
//   & [for^='rc-editable-input'] {
//     font-size: 20px !important;
//   }

export { ColorPicker };
