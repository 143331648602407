import type { Options } from './types';

interface Action<T, P> {
  type: T;
  payload: P;
}

export const SELECT_OPTION = 'SELECT_OPTION';
export type SelectOption<OptionValue> = Action<typeof SELECT_OPTION, OptionValue>;
export const selectOption = <OptionValue>(value: OptionValue): SelectOption<OptionValue> => ({
  type: SELECT_OPTION,
  payload: value
});

export const CLEAR_OPTION = 'CLEAR_OPTION';
export type ClearOption<OptionValue> = Action<typeof CLEAR_OPTION, OptionValue>;
export const clearOption = <OptionValue>(value: OptionValue): ClearOption<OptionValue> => ({
  type: CLEAR_OPTION,
  payload: value
});

export const RESELECT = 'RESELECT';
export type Reselect<OptionValue> = Action<
  typeof RESELECT,
  {
    options: Options<OptionValue>;
    selectedValues: ReadonlyArray<OptionValue> | undefined;
  }
>;
export const reselect = <OptionValue>(
  options: Options<OptionValue>,
  selectedValues: ReadonlyArray<OptionValue> | undefined
): Reselect<OptionValue> => ({
  type: RESELECT,
  payload: {
    options,
    selectedValues
  }
});

export const SET_FILTER_VALUE = 'SET_FILTER_VALUE';
export type SetFilterValue = Action<typeof SET_FILTER_VALUE, string>;
export const setFilterValue = (value: string): SetFilterValue => ({
  type: SET_FILTER_VALUE,
  payload: value
});

export const SET_SELECTED_VALUES = 'SET_SELECTED_VALUES';
export type SetSelectedValues<OptionValue> = Action<
  typeof SET_SELECTED_VALUES,
  {
    selectedValues: OptionValue[];
    unselectedValues: OptionValue[];
  }
>;
export const setSelectedValues = <OptionValue>(
  selectedValues: OptionValue[],
  unselectedValues: OptionValue[]
): SetSelectedValues<OptionValue> => ({
  type: SET_SELECTED_VALUES,
  payload: {
    selectedValues,
    unselectedValues
  }
});

export type ActionUnion<OptionValue> =
  | SelectOption<OptionValue>
  | ClearOption<OptionValue>
  | Reselect<OptionValue>
  | SetFilterValue
  | SetSelectedValues<OptionValue>;
