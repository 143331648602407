import type { CSSProperties } from 'react';
const regex = new RegExp(/[A-Z]/g);
const kebabCase = (str: string) => str.replace(regex, v => `-${v.toLowerCase()}`);

export const cssPropertiesToStyledCss = (style: CSSProperties) =>
  Object.keys(style).reduce((accumulator, key) => {
    // transform the key from camelCase to kebab-case
    const cssKey = kebabCase(key);
    // remove ' in value
    const cssValue = style[key].replace("'", '');
    // build the result
    // you can break the line, add indent for it if you need
    return `${accumulator}${cssKey}:${cssValue};`;
  }, '');
