import { omit } from 'lodash-es';
import type { PublicDetailsState } from '../types';
import type * as Actions from './actions';
import type { ActionType } from './actions';
import type { CoursePublicDetailCourseLink } from './types';
import type { Handler } from '../../../../types';

const arrayToObject = (
  data: CoursePublicDetailCourseLink[],
  initialState?: { [id: string]: CoursePublicDetailCourseLink }
) => data.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), initialState);

const merge = (
  stateData: PublicDetailsState['data'],
  publicDetailId: string,
  data: CoursePublicDetailCourseLink[] | CoursePublicDetailCourseLink
): PublicDetailsState['data'] => ({
  ...stateData,
  [publicDetailId]: {
    ...stateData[publicDetailId],
    courseLinks: Array.isArray(data)
      ? arrayToObject(data, stateData[publicDetailId].courseLinks)
      : { ...stateData[publicDetailId].courseLinks, [data.id]: data }
  }
});

const remove = (
  stateData: PublicDetailsState['data'],
  publicDetailId: string,
  id: string
): PublicDetailsState['data'] => ({
  ...stateData,
  [publicDetailId]: {
    ...stateData[publicDetailId],
    courseLinks: omit(stateData[publicDetailId].courseLinks, id)
  }
});

const replace = (
  stateData: PublicDetailsState['data'],
  publicDetailId: string,
  data: CoursePublicDetailCourseLink[]
): PublicDetailsState['data'] => ({
  ...stateData,
  [publicDetailId]: {
    ...stateData[publicDetailId],
    courseLinks: arrayToObject(data)
  }
});

type CourseLinkHandler<Action = null> = Handler<PublicDetailsState, Action>;

const fetchCourseLinks: CourseLinkHandler<Actions.FetchCoursePublicDetailCourseLinksAction> = state => ({
  ...state,
  loading: true
});

const fetchCourseLinksSuccess: CourseLinkHandler<Actions.FetchCoursePublicDetailCourseLinksSuccessAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: '',
  data: merge(state.data, action.payload.publicDetailId, action.payload.courseLinks)
});

const fetchCourseLinksFailure: CourseLinkHandler<Actions.FetchCoursePublicDetailCourseLinksFailureAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: action.payload.message
});

const createCourseLink: CourseLinkHandler<Actions.CreateCoursePublicDetailCourseLinkAction> = state => ({
  ...state,
  loading: true
});

const createCourseLinkSuccess: CourseLinkHandler<Actions.CreateCoursePublicDetailCourseLinkSuccessAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: '',
  data: merge(state.data, action.payload.coursePublicDetailId, action.payload)
});

const createCourseLinkFailure: CourseLinkHandler<Actions.CreateCoursePublicDetailCourseLinkFailureAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: action.payload.message
});

const createCourseLinks: CourseLinkHandler<Actions.CreateCoursePublicDetailCourseLinksAction> = state => ({
  ...state,
  loading: true
});

const createCourseLinksSuccess: CourseLinkHandler<Actions.CreateCoursePublicDetailCourseLinksSuccessAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: '',
  data: replace(state.data, action.payload.publicDetailId, action.payload.courseLinks)
});

const createCourseLinksFailure: CourseLinkHandler<Actions.CreateCoursePublicDetailCourseLinksFailureAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: action.payload.message
});

const deleteCourseLink: CourseLinkHandler<Actions.DeleteCoursePublicDetailCourseLinkAction> = state => ({
  ...state,
  loading: true
});

const deleteCourseLinkSuccess: CourseLinkHandler<Actions.DeleteCoursePublicDetailCourseLinkSuccessAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: '',
  data: remove(state.data, action.payload.publicDetailId, action.payload.id)
});

const deleteCourseLinkFailure: CourseLinkHandler<Actions.DeleteCoursePublicDetailCourseLinkFailureAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: action.payload.message
});

export type Handlers = {
  [type in ActionType]: (
    state: PublicDetailsState,
    action: Actions.ActionTypes
  ) => PublicDetailsState;
};

export const handlers: Handlers = {
  FETCH_COURSE_PUBLIC_DETAIL_COURSE_LINKS: fetchCourseLinks,
  FETCH_COURSE_PUBLIC_DETAIL_COURSE_LINKS_SUCCESS: fetchCourseLinksSuccess,
  FETCH_COURSE_PUBLIC_DETAIL_COURSE_LINKS_FAILURE: fetchCourseLinksFailure,

  CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINK: createCourseLink,
  CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINK_SUCCESS: createCourseLinkSuccess,
  CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINK_FAILURE: createCourseLinkFailure,

  CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINKS: createCourseLinks,
  CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINKS_SUCCESS: createCourseLinksSuccess,
  CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINKS_FAILURE: createCourseLinksFailure,

  DELETE_COURSE_PUBLIC_DETAIL_COURSE_LINK: deleteCourseLink,
  DELETE_COURSE_PUBLIC_DETAIL_COURSE_LINK_SUCCESS: deleteCourseLinkSuccess,
  DELETE_COURSE_PUBLIC_DETAIL_COURSE_LINK_FAILURE: deleteCourseLinkFailure
};
