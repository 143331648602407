import { omit } from 'lodash-es';
import type { CourseTopicsState } from './types';
import { initialState } from './constants';
import type * as Actions from './actions';
import type { ActionType } from './actions';
import type { Handler } from '../../../types';

type CourseTopicsHandler<Action = null> = Handler<CourseTopicsState, Action>;

const fetchCourseTopics: CourseTopicsHandler = state => ({
  ...state,
  loading: true
});

const fetchCourseTopicsSuccess: CourseTopicsHandler<Actions.FetchCourseTopicsSuccessAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: '',
  data: action.payload.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), state.data)
});

const fetchCourseTopicsFailure: CourseTopicsHandler<Actions.FetchCourseTopicsFailureAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: action.payload.message
});

const createCourseTopic: CourseTopicsHandler = state => ({
  ...state,
  loading: true
});

const createCourseTopicSuccess: CourseTopicsHandler<Actions.CreateCourseTopicSuccessAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: '',
  data: { ...state.data, [action.payload.id]: action.payload }
});

const createCourseTopicFailure: CourseTopicsHandler<Actions.CreateCourseTopicFailureAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: action.payload.message
});

const updateCourseTopic: CourseTopicsHandler = state => ({
  ...state,
  loading: true
});

const updateCourseTopicSuccess: CourseTopicsHandler<Actions.UpdateCourseTopicSuccessAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: '',
  data: { ...state.data, [action.payload.id]: action.payload }
});

const updateCourseTopicFailure: CourseTopicsHandler<Actions.UpdateCourseTopicFailureAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: action.payload.message
});

const deleteCourseTopic: CourseTopicsHandler = state => ({
  ...state,
  loading: true
});

const deleteCourseTopicSuccess: CourseTopicsHandler<Actions.DeleteCourseTopicSuccessAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: '',
  data: omit(state.data, action.payload.id)
});

const deleteCourseTopicFailure: CourseTopicsHandler<Actions.DeleteCourseTopicFailureAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: action.payload.message
});

type Handlers = {
  [type in ActionType]: (
    state: CourseTopicsState,
    action: Actions.ActionTypes
  ) => CourseTopicsState;
};

const handlers: Handlers = {
  FETCH_COURSE_TOPICS: fetchCourseTopics,
  FETCH_COURSE_TOPICS_SUCCESS: fetchCourseTopicsSuccess,
  FETCH_COURSE_TOPICS_FAILURE: fetchCourseTopicsFailure,
  CREATE_COURSE_TOPIC: createCourseTopic,
  CREATE_COURSE_TOPIC_SUCCESS: createCourseTopicSuccess,
  CREATE_COURSE_TOPIC_FAILURE: createCourseTopicFailure,
  UPDATE_COURSE_TOPIC: updateCourseTopic,
  UPDATE_COURSE_TOPIC_SUCCESS: updateCourseTopicSuccess,
  UPDATE_COURSE_TOPIC_FAILURE: updateCourseTopicFailure,
  DELETE_COURSE_TOPIC: deleteCourseTopic,
  DELETE_COURSE_TOPIC_SUCCESS: deleteCourseTopicSuccess,
  DELETE_COURSE_TOPIC_FAILURE: deleteCourseTopicFailure
};

type Reducer = (
  state: CourseTopicsState | undefined,
  action: Actions.ActionTypes
) => CourseTopicsState;

export const reducer: Reducer = (state = initialState, action) =>
  handlers[action.type]?.(state, action) || state;
