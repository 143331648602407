import type { Recipe } from '@edapp/achievements';

// Specific feature array, we need this to clear period and repeat data when switching between features.
export const SPECIFIC_FEATURES = ['course_id'];

export const EMPTY_RECIPE_STRING_VALUE = 'none';

export const EMPTY_RECIPE: Recipe = {
  action: EMPTY_RECIPE_STRING_VALUE,
  value: 1,
  measure: EMPTY_RECIPE_STRING_VALUE,
  counter: 1,
  feature: EMPTY_RECIPE_STRING_VALUE,
  featureId: null,
  period: 1,
  repeat: 'once'
};
