import type { UseHippoQueryOptions, UseHippoQueryResult } from '@edapp/request/src/types';
import type { FeaturesResponse } from '../accounts/app-features';

export type ApplicationContextRequest = {};

export type ApplicationContextResponse = {
  isSpacedRepetitionAllowed: boolean;
  isCompletionCertificateAllowed: boolean;
  totalPublishedCourses: number;
  totalLearners: number;
  totalUsers: number;
} & Partial<FeaturesResponse>;

export type ApplicationContextOptions = UseHippoQueryOptions<ApplicationContextResponse>;
export type ApplicationContextResult = UseHippoQueryResult<ApplicationContextResponse>;

export type ApplicationResponse = {
  name: string;
  provisioningType?: ProvisioningType;
  isUnifiedExperience: boolean;
  isSponsor: boolean;
};

export enum ProvisioningType {
  Manual = 'Manual',
  External = 'External'
}

export type ApplicationOptions = UseHippoQueryOptions<ApplicationResponse>;
