import { cloneDeep, mergeWith, isArray } from 'lodash-es';

const mergeCustomiser: any = (_: any, srcValue: any) => {
  if (srcValue === undefined) {
    return null;
  }
  return isArray(srcValue) ? srcValue : undefined;
};

function mergeDeep<TObject, TSource>(object: TObject, source: TSource): TObject & TSource;

function mergeDeep<TObject, TSource1, TSource2>(
  object: TObject,
  source1: TSource1,
  source2: TSource2
): TObject & TSource1 & TSource2;

function mergeDeep<TObject, TSource1, TSource2, TSource3>(
  object: TObject,
  source1: TSource1,
  source2: TSource2,
  source3: TSource3
): TObject & TSource1 & TSource2 & TSource3;

function mergeDeep<TObject, TSource1, TSource2, TSource3, TSource4>(
  object: TObject,
  source1: TSource1,
  source2: TSource2,
  source3: TSource3,
  source4: TSource4
): TObject & TSource1 & TSource2 & TSource3 & TSource4;

function mergeDeep(object: any, ...otherArgs: any[]): any {
  const clonedObject = cloneDeep(object);
  return mergeWith(clonedObject, ...[...otherArgs, mergeCustomiser]);
}

export { mergeDeep };

type ResponseItems<T> = T[];
type ResponseItemsWithKey<T> = { [key: string]: T } | {};
type MutatedType<T> = {
  original: T;
  changed: Partial<T> | RecursivePartial<T>;
};
type RecursivePartial<T> = { [P in keyof T]?: RecursivePartial<T[P]> };

export const createKeyedObjectFromResponseArray = <T>(
  items: ResponseItems<T>,
  key: string = 'id'
): ResponseItemsWithKey<T> => {
  if (!items || items.length === 0 || !key) {
    return {};
  }
  return items.reduce((acc, curr: Partial<T>) => {
    if (curr.hasOwnProperty(key)) {
      acc[curr[key]] = curr;
    }

    return acc;
  }, {});
};

export const mergeMutated = <T>(
  obj: MutatedType<T>,
  data: Partial<T> | RecursivePartial<T>
): MutatedType<T> => {
  const changedData = mergeDeep(obj.changed, data);
  const originalData = mergeDeep(obj.original, data);

  return {
    ...obj,
    changed: { ...changedData },
    original: { ...originalData }
  };
};

export const spreadMutated = <T>(
  obj: MutatedType<T>,
  data: Partial<T> | RecursivePartial<T>
): MutatedType<T> => ({
  ...obj,
  changed: { ...obj.changed, ...data },
  original: { ...obj.original, ...data }
});

export const createMutated = <T>(obj: T): MutatedType<T> => ({
  changed: {},
  original: { ...obj }
});
