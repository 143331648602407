import * as React from 'react';
import styled, { css } from 'styled-components';
import { NumberInput, Typography } from '@edapp/ed-components';

type Props = {
  label: string;
  value?: number;
  defaultValue?: number;
  min?: number;
  max?: number;
  onChange: (newValue: number) => void;
  style?: React.CSSProperties;
  suffix?: {
    value?: string;
  };
  disabled?: boolean;
  testId?: string;
};

const BoxedNumberInput: React.FC<Props> = ({
  style,
  min,
  max,
  value,
  defaultValue,
  label,
  disabled,
  testId,
  suffix,
  onChange,
  ...rest
}) => {
  const handleChange = (newValue: number | undefined) => {
    if (newValue != null) {
      onChange(newValue);
    }
  };

  return (
    <InputGroup style={style} {...rest}>
      <PrefixLabel as="label">{label}</PrefixLabel>
      <StyledNumberInput
        value={value}
        onChange={handleChange}
        defaultValue={defaultValue}
        min={min}
        max={max}
        disabled={disabled}
        data-testid={testId}
        withSuffix={!!suffix}
      />
      {suffix && <SuffixLabel as="label">{suffix.value}</SuffixLabel>}
    </InputGroup>
  );
};

const InputGroup = styled.div`
  display: flex;
  align-items: stretch;
`;

const Label = styled(Typography)(
  ({ theme }) => css`
    padding: ${theme.space()}px ${theme.space(2)}px;
    border: 1px solid ${theme.colors.grey};
    background: ${theme.colors.lightGrey};
    display: inline-flex;
    margin: 0;
  `
);

const PrefixLabel = styled(Label)(
  ({ theme }) => css`
    border-top-left-radius: ${theme.sizes.borderRadius}px;
    border-bottom-left-radius: ${theme.sizes.borderRadius}px;
    border-right: none;
  `
);

const StyledNumberInput = styled(NumberInput)<{ withSuffix: boolean }>(
  ({ theme, withSuffix }) => css`
    border: 1px solid ${theme.colors.grey};
    padding: ${theme.space()}px ${theme.space(2)}px;
    margin-bottom: 0;
    flex-grow: 1;
    flex-basis: 0;
    min-width: 80px;
    width: 100%;
    border-radius: 0 ${theme.sizes.borderRadius}px ${theme.sizes.borderRadius}px 0;
    ${withSuffix &&
    css`
      border-radius: ${theme.sizes.borderRadius}px;
    `}

    :hover {
      border-color: ${theme.colors.greyHover};
    }

    :focus {
      border-color: ${theme.colors.blue};
    }
  `
);

const SuffixLabel = styled(Label)(
  ({ theme }) => css`
    border-top-right-radius: ${theme.sizes.borderRadius}px;
    border-bottom-right-radius: ${theme.sizes.borderRadius}px;
    border-left: none;
  `
);

export { BoxedNumberInput };
