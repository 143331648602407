import * as React from 'react';

import type { FeaturesResponse } from '@edapp/hippo-client';
import { useGetFeatures } from '@edapp/hippo-client';
import { BillingPermission, hasAnyPermissions, hasPermissions } from '@edapp/permissions';
import { UserInfoSelectors, useUserInfo } from '@edapp/user-info';

import type { MenuItemType, SubmenuItemType } from './types';

type NavbarContextType = {
  isMenuSectionPermitted: (item: MenuItemType) => boolean;
  canShowUpgradeActions: boolean;
};

const NavbarContext = React.createContext<NavbarContextType>({
  isMenuSectionPermitted: () => false,
  canShowUpgradeActions: true
});

export const NavbarContextProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  // App Features
  const { data: appFeatures } = useGetFeatures({
    staleTime: Infinity,
    refetchOnWindowFocus: false
  });

  // User Info
  const { userInfo, billing } = useUserInfo();
  const isSuperAdmin = UserInfoSelectors.isEdAdmin(userInfo);

  // Billing
  const canShowUpgradeActions = hasPermissions(
    [BillingPermission.CAN_SHOW_UPGRADE_ACTIONS],
    userInfo.userPermissions,
    billing.permissions
  );

  const checkApplicationFeature = (
    item: MenuItemType | SubmenuItemType,
    appFeatures?: FeaturesResponse
  ) => {
    if (!item.applicationFeature) {
      return true;
    }

    switch (item.applicationFeature) {
      case 'activityFeed':
        return appFeatures?.hideActivityFeedEnabled === false;

      case 'avatarUploadEnabled':
        return appFeatures?.avatarUploadEnabled === true;

      case 'leaderboardsEnabled':
        return appFeatures?.leaderboardEnabled === true;

      default:
        return false;
    }
  };

  const isMenuSectionPermitted = React.useCallback(
    (item: MenuItemType | SubmenuItemType): boolean => {
      // super admin has all the sections permitted
      if (isSuperAdmin) {
        return true;
      }

      // Check is section is excluded based on provisioning type
      if (item.isExcluded) {
        return false;
      }

      // Check for feature application enabled
      const hasEnabledFeature = checkApplicationFeature(item, appFeatures);

      // Check permissions
      const hasPermission = item.permissions
        ? hasPermissions(item.permissions, userInfo.userPermissions, billing.permissions)
        : true;

      // Check conditional permissions
      const hasAnyPermission = item.anyPermissions
        ? hasAnyPermissions(item.anyPermissions, billing.permissions, userInfo.userPermissions)
        : true;

      return hasPermission && hasAnyPermission && hasEnabledFeature;
    },
    [isSuperAdmin, appFeatures, userInfo, billing]
  );

  return (
    <NavbarContext.Provider value={{ isMenuSectionPermitted, canShowUpgradeActions }}>
      {children}
    </NavbarContext.Provider>
  );
};

export const useNavbarContext = () => {
  const context = React.useContext(NavbarContext);
  if (!context) {
    throw Error('NavbarContext not available');
  }

  return context;
};
