import { combineReducers } from 'redux';

import form, { initialRingoFormState } from './form/reducer';
import language, { initialLanguageState } from './language/reducer';
import lesson from './lesson/reducer';
import modals, { initialRingoModalsState } from './modal/reducer';
import type { RingoStoreType } from './types';

export const initialRingoState: RingoStoreType = {
  lesson: null,
  form: {
    past: [],
    present: initialRingoFormState,
    future: [],
    _latestUnfiltered: initialRingoFormState,
    group: 0,
    index: 0,
    limit: 0
  },
  modals: initialRingoModalsState,
  language: initialLanguageState
};

export const reducer = combineReducers({
  lesson,
  form,
  modals,
  language
});
