import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { rgba } from '@edapp/themes';

export const TabList = styled.div(
  ({ theme }) => css`
    display: inline-block;
    white-space: nowrap;
    position: relative;
    padding: 0 ${theme.space(6)}px;
    border-bottom: 1px solid ${theme.colors.greyHover};
  `
);

export const TabIndicator = styled(motion.div)(
  ({ theme }) => css`
    height: 3px;
    position: absolute;
    bottom: 0;
    background: ${theme.colors.blue};
  `
);

export const Tab = styled(motion.button)<{ isActive: boolean }>(
  ({ theme, isActive }) => css`
    padding: ${theme.space(1.25)}px ${theme.space(2)}px;
    background: transparent;
    border: none;
    ${isActive && `color: ${theme.colors.blue};`}

    :hover {
      background-color: ${rgba(theme.colors.blue, 0.1)};
      border-radius: ${theme.sizes.borderRadius}px ${theme.sizes.borderRadius}px 0 0;
      color: ${theme.colors.blue};
      cursor: pointer;
    }
  `
);
