import type { UseHippoPaginationOptions, UseHippoPaginationResult } from '@edapp/request';
import { useHippoPagination } from '@edapp/request';

import { getCourseUsers, getUserGroupUserDetails } from './api';
import { QueryKeys } from './constants';
import type {
  GetUsersRequest,
  GetUsersResponse,
  SearchUserDetailRequest,
  SearchUserDetailsResponse
} from './types';

export function useGetUserGroupUsers(
  userGroupId: string,
  request?: Omit<SearchUserDetailRequest, 'page'>,
  options?: UseHippoPaginationOptions<SearchUserDetailsResponse>
): UseHippoPaginationResult<SearchUserDetailsResponse> {
  return useHippoPagination(
    [QueryKeys.userGroupUsers, userGroupId, request],
    (hippoUrl, userToken) => ({ pageParam }) =>
      getUserGroupUserDetails(hippoUrl, userToken, userGroupId, { ...request, page: pageParam }),
    options
  );
}

export function useGetCourseUsers(
  request?: Omit<GetUsersRequest, 'page'>,
  options?: UseHippoPaginationOptions<GetUsersResponse>
): UseHippoPaginationResult<GetUsersResponse> {
  return useHippoPagination(
    [QueryKeys.getCourseUsers, request],
    (hippoUrl, userToken) => ({ pageParam }) =>
      getCourseUsers(hippoUrl, userToken, { ...request, page: pageParam }),
    options
  );
}
