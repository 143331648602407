import * as React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '../../typography/Typography';
import { MinusIcon, TickIcon } from '../../icons';
import { Wrapper, HiddenCheckbox, VisibleCheckbox, CheckboxLabel } from './styled';
import type { Props } from './types';

export const CHECKBOX_TEST_ID = 'checkbox';
export const CHECKBOX_LABEL_TEST_ID = `${CHECKBOX_TEST_ID}-label`;
export const CHECKBOX_VISIBLE_TEST_ID = `${CHECKBOX_TEST_ID}-visible`;

const CheckboxComponent: React.FC<Props> = ({
  testId = CHECKBOX_TEST_ID,
  labelTestId = CHECKBOX_LABEL_TEST_ID,
  visibleTestId = CHECKBOX_VISIBLE_TEST_ID,
  defaultChecked,
  checked,
  onChange,
  className,
  disabled,
  undetermined,
  title,
  titleVariant,
  description,
  descriptionVariant,
  isFullWidth,
  backgroundColor,
  children,
  rounded,
  hasMargin = true,
  ...rest
}) => {
  const [isChecked, setIsChecked] = React.useState(!!(checked || defaultChecked));

  React.useEffect(() => setIsChecked(!!(checked || defaultChecked)), [defaultChecked, checked]);

  const handleChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    const newCheckedValue = changeEvent.target.checked;

    if (checked == null) {
      setIsChecked(newCheckedValue);
    }

    onChange?.(changeEvent);
  };

  return (
    <Wrapper data-testid={labelTestId} className={className}>
      <HiddenCheckbox
        checked={isChecked}
        onChange={handleChange}
        disabled={disabled}
        testId={testId}
        {...rest}
      />
      <VisibleCheckbox
        checked={isChecked}
        undetermined={undetermined}
        disabled={disabled}
        data-testid={visibleTestId}
        backgroundColor={backgroundColor}
        hasMargin={hasMargin}
      >
        {undetermined ? (
          <MinusIcon testId={`${testId}-icon-undetermined`} color="white" size="xs" />
        ) : isChecked ? (
          <TickIcon
            testId={`${testId}-icon-checked`}
            color={disabled ? 'greyHover' : 'white'}
            size="xs"
          />
        ) : null}
      </VisibleCheckbox>
      {(children || title || description) && (
        <CheckboxLabel
          variant={titleVariant || 'subtitle1'}
          disabled={disabled}
          isFullWidth={isFullWidth}
        >
          {title}
          {description && (
            <Typography color="textMuted" variant={descriptionVariant || 'small'}>
              {description}
            </Typography>
          )}
          {children}
        </CheckboxLabel>
      )}
    </Wrapper>
  );
};

export const Checkbox = styled(CheckboxComponent).attrs(({ disabled, isDisabled }) => ({
  disabled: disabled || isDisabled
}))<Props>`
  ${({ alignItems = 'flex-start', theme, disabled, rounded, hasMargin = true }) => css`
    ${
      hasMargin &&
      css`
        margin-bottom: ${theme.space(2)}px;
      `
    }
    align-items: ${alignItems};
    display: flex;
    flex-flow: row nowrap;
    cursor: ${disabled ? 'not-allowed' : 'pointer'};

    ${
      rounded &&
      css`
        > ${VisibleCheckbox} {
          border-radius: 50%;
        }
      `
    }
    ${
      !disabled &&
      css`
        &:hover > ${VisibleCheckbox} {
          border-color: ${theme.colors.greyHover};
        }

        & > ${HiddenCheckbox}:focus + ${VisibleCheckbox} {
          border-color: ${theme.colors.blue};
        }
      `
    }
  `}
`;
