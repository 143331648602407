import type { SlideType } from '../types';

/**
 * Remove empty array elements from array attributes
 * @param slide SlideType
 */
export const cleanSlide = (slide: SlideType): SlideType => {
  for (const k in slide.data) {
    if (slide.data.hasOwnProperty(k)) {
      const v = slide.data[k];
      if (Array.isArray(v)) {
        slide.data[k] = v.filter(item => item != null);
      }
    }
  }
  return slide as SlideType;
};
