import { omit } from 'lodash-es';
import type { PublicDetailsState } from '../types';
import type * as Actions from './actions';
import type { ActionType } from './actions';
import type { CoursePublicDetailExcludedSlide } from './types';
import type { Handler } from '../../../../types';

type ExcludedSlideHandler<Action = null> = Handler<PublicDetailsState, Action>;

const merge = (
  stateData: PublicDetailsState['data'],
  publicDetailId: string,
  data: CoursePublicDetailExcludedSlide[] | CoursePublicDetailExcludedSlide
): PublicDetailsState['data'] => ({
  ...stateData,
  [publicDetailId]: {
    ...stateData[publicDetailId],
    excludedSlides: Array.isArray(data)
      ? data.reduce(
          (acc, cur) => ({ ...acc, [cur.id]: cur }),
          stateData[publicDetailId].excludedSlides
        )
      : { ...stateData[publicDetailId].excludedSlides, [data.id]: data }
  }
});

const remove = (
  stateData: PublicDetailsState['data'],
  publicDetailId: string,
  id: string
): PublicDetailsState['data'] => ({
  ...stateData,
  [publicDetailId]: {
    ...stateData[publicDetailId],
    excludedSlides: omit(stateData[publicDetailId].excludedSlides, id)
  }
});

const fetchExcludedSlides: ExcludedSlideHandler<Actions.FetchCoursePublicDetailExcludedSlidesAction> = state => ({
  ...state,
  loading: true
});

const fetchExcludedSlidesSuccess: ExcludedSlideHandler<Actions.FetchCoursePublicDetailExcludedSlidesSuccessAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: '',
  data: merge(state.data, action.payload.publicDetailId, action.payload.excludedSlides)
});

const fetchExcludedSlidesFailure: ExcludedSlideHandler<Actions.FetchCoursePublicDetailExcludedSlidesFailureAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: action.payload.message
});

const createExcludedSlide: ExcludedSlideHandler<Actions.CreateCoursePublicDetailExcludedSlideAction> = state => ({
  ...state,
  loading: true
});

const createExcludedSlideSuccess: ExcludedSlideHandler<Actions.CreateCoursePublicDetailExcludedSlideSuccessAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: '',
  data: merge(state.data, action.payload.coursePublicDetailId, action.payload)
});

const createExcludedSlideFailure: ExcludedSlideHandler<Actions.CreateCoursePublicDetailExcludedSlideFailureAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: action.payload.message
});

const deleteExcludedSlide: ExcludedSlideHandler<Actions.DeleteCoursePublicDetailExcludedSlideAction> = state => ({
  ...state,
  loading: true
});

const deleteExcludedSlideSuccess: ExcludedSlideHandler<Actions.DeleteCoursePublicDetailExcludedSlideSuccessAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: '',
  data: remove(state.data, action.payload.publicDetailId, action.payload.id)
});

const deleteExcludedSlideFailure: ExcludedSlideHandler<Actions.DeleteCoursePublicDetailExcludedSlideFailureAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: action.payload.message
});

export type Handlers = {
  [type in ActionType]: (
    state: PublicDetailsState,
    action: Actions.ActionTypes
  ) => PublicDetailsState;
};

export const handlers: Handlers = {
  FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDES: fetchExcludedSlides,
  FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDES_SUCCESS: fetchExcludedSlidesSuccess,
  FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDES_FAILURE: fetchExcludedSlidesFailure,

  CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE: createExcludedSlide,
  CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_SUCCESS: createExcludedSlideSuccess,
  CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_FAILURE: createExcludedSlideFailure,

  DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE: deleteExcludedSlide,
  DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_SUCCESS: deleteExcludedSlideSuccess,
  DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_FAILURE: deleteExcludedSlideFailure
};
