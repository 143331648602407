import * as React from 'react';
import SelectableListItemGroup from './SelectableListItemGroup';
import type { Option, InternalGroupedOptions } from '../types';
import { SelectableListOption } from './SelectableListOption';

type Props<OptionValue> = {
  option: InternalGroupedOptions<OptionValue>;
  disabled?: boolean;
  labelIconStyle?: React.CSSProperties;
  itemTestId?: string;
  onItemClick: (item: Option<OptionValue>) => void;
};

// The component takes a type argument to know the type of the option values passed in
export function SelectableListOptionGroup<OptionValue>({
  option,
  disabled,
  labelIconStyle,
  itemTestId,
  onItemClick
}: Props<OptionValue>) {
  if (!(option.subCount > 0)) {
    return null;
  }

  return (
    <SelectableListItemGroup key={option.key} label={option.label}>
      {option.options.map(subOption => (
        <SelectableListOption
          key={subOption.key}
          option={subOption}
          disabled={disabled}
          labelIconStyle={labelIconStyle}
          testId={itemTestId}
          onClick={onItemClick}
        />
      ))}
    </SelectableListItemGroup>
  );
}
