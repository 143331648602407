import { RequestUtils } from '@edapp/request';

import type {
  GetUsersRequest,
  GetUsersResponse,
  SearchUserDetailRequest,
  SearchUserDetailsResponse
} from './types';

function sanitizeRequest(request?: SearchUserDetailRequest) {
  if (!!request?.userName) {
    request.userName = encodeURIComponent(request.userName);
  }

  return request;
}

export async function getUserGroupUserDetails(
  hippoUrl: string,
  userToken: string,
  userGroupId: string,
  request?: SearchUserDetailRequest
): Promise<SearchUserDetailsResponse> {
  if (!userGroupId) {
    return Promise.resolve({ pageParams: [], pages: [], totalCount: 0, items: [] });
  }

  return await RequestUtils.httpFetch<SearchUserDetailsResponse, SearchUserDetailRequest>(
    'GET',
    `${hippoUrl}/api/usergroups/${userGroupId}/users`,
    userToken,
    sanitizeRequest(request)
  );
}

export function getCourseUsers(
  hippoUrl: string,
  userToken: string,
  request?: GetUsersRequest
): Promise<GetUsersResponse> {
  return RequestUtils.httpFetch<GetUsersResponse, GetUsersRequest>(
    'GET',
    `${hippoUrl}/api/users`,
    userToken,
    request
  );
}
