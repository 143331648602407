import styled, { css } from 'styled-components';
import { CrossIcon, Pill, Input, Typography } from '@edapp/ed-components';
import type { InputProps } from '@edapp/ed-components';

export const StyledMultiPillInputWrapper = styled.div(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: ${theme.space(0.5)}px;
    border-radius: ${theme.sizes.borderRadius}px;
    width: 100%;
    background-color: ${theme.colors.white};
    border: 1px solid ${theme.colors.greyHover};
    transition: border-color ${theme.transition.slow};
    padding: ${theme.space(1)}px ${theme.space(2)}px;

    &:hover {
      border-color: ${theme.colors.greyHover};
    }

    &:focus-within {
      border-color: ${theme.colors.blue};
    }
  `
);
export const StyledInput = styled(Input)<InputProps & { transparent?: boolean }>`
  flex: 1;
  width: auto;
  height: auto;
  outline: none;
  border-radius: 0;
  min-height: 0;

  ${({ transparent }) =>
    transparent &&
    css`
      padding: 0;
      border: none;
      background-color: transparent;
    `}
`;

export const StyledPillTitle = styled(Typography)(
  ({ theme }) => css`
    ${theme.lineClamp(1)};
    word-break: break-all;
  `
);

export const StyledPill = styled(Pill)<{ error: boolean }>(
  ({ theme, error }) => css`
    display: flex;
    align-items: center;
    padding: ${theme.space(0.25)}px ${theme.space()}px;

    ${error
      ? css`
          color: ${theme.colors.red};
          background-color: ${theme.colors.lightRed};
        `
      : css`
          color: ${theme.colors.text};
          background-color: ${theme.colors.grey};
        `}
  `
);

export const StyledCrossIcon = styled(CrossIcon)(
  ({ theme }) => css`
    margin-left: ${theme.sizes.pillMargin}px;
    cursor: pointer;
  `
);
