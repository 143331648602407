import { LessonDiscriminatorEnum } from '@edapp/authoring-logic';
import type { LessonPreview } from '@edapp/content-library';
import type { LmsStoreState } from '@rio/store/types';

import { defaultLessonConfig } from './constants';

const getSelectedLessonPreview = (state: LmsStoreState): LessonPreview | null => {
  const { selectedLessonIndex, lessons } = state.betterPptx;

  if (selectedLessonIndex === -1) {
    return null;
  }

  return {
    id: lessons[selectedLessonIndex]?.id || '',
    slides: lessons[selectedLessonIndex]?.slides || [],
    config: lessons[selectedLessonIndex]?.config || defaultLessonConfig,
    lessonType: LessonDiscriminatorEnum.LESSON
  };
};

const getSelectedSlidePptCandidates = (state: LmsStoreState) => {
  const { selectedLessonIndex, selectedSlideIndex, lessons, pptSlides } = state.betterPptx;

  if (selectedLessonIndex === -1) {
    return [];
  }

  if (selectedSlideIndex === -1) {
    return [];
  }

  const selectedPptSlideIndex =
    (lessons[selectedLessonIndex]?.slides || [])[selectedSlideIndex]?.pptSlideIndex || 0;

  return pptSlides[selectedPptSlideIndex]?.conversionCandidates || [];
};

const isSlideSelected = (lessonIndex: number, slideIndex: number) => (state: LmsStoreState) => {
  return (
    state.betterPptx.selectedSlideIndex === slideIndex &&
    state.betterPptx.selectedLessonIndex === lessonIndex
  );
};

const getLessonSlides = (lessonId: string) => (state: LmsStoreState) => {
  const lesson = state.betterPptx.lessons.find(l => l.id === lessonId);
  if (!lesson) {
    return [];
  }

  return lesson.slides;
};

export const BetterPptxSelectors = {
  getSelectedLessonPreview,
  getSelectedSlidePptCandidates,
  isSlideSelected,
  getLessonSlides
};
