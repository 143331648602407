import * as actions from './actions';
import type { State } from './types';

export const getInitialState = <OptionValue>(): State<OptionValue> => {
  return {
    unselectedValues: [],
    selectedValues: [],
    filterValue: ''
  };
};

export default function reducer<OptionValue>(
  state: State<OptionValue>,
  action: actions.ActionUnion<OptionValue>
): State<OptionValue> {
  switch (action.type) {
    case actions.SELECT_OPTION:
      return {
        ...state,
        selectedValues: [...state.selectedValues, action.payload],
        unselectedValues: [...state.unselectedValues.filter(option => option !== action.payload)]
      };

    case actions.CLEAR_OPTION:
      return {
        ...state,
        selectedValues: [...state.selectedValues.filter(option => option !== action.payload)],
        unselectedValues: [...state.unselectedValues, action.payload]
      };

    // Update internal state for selected values based on new props
    case actions.RESELECT: {
      const { selectedValues: selectedValuesFromProps } = action.payload;

      const unselectedValues: OptionValue[] = [];

      // If selectedValues are not provided through props, the component is uncontrolled
      if (selectedValuesFromProps == null) {
        // Initialize by putting all options into the unselected state
        action.payload.options.forEach(option => {
          if ('options' in option) {
            option.options.forEach(subOption => {
              unselectedValues.push(subOption.value);
            });
          } else {
            unselectedValues.push(option.value);
          }
        });

        return {
          ...state,
          selectedValues: [],
          unselectedValues: [...unselectedValues]
        };
      } else {
        const selectedValues: OptionValue[] = [];

        action.payload.options.forEach(option => {
          if ('options' in option) {
            // grouped options
            option.options.forEach(subOption => {
              if (selectedValuesFromProps.includes(subOption.value)) {
                selectedValues.push(subOption.value);
              } else {
                unselectedValues.push(subOption.value);
              }
            });
          } else {
            // flat options
            if (selectedValuesFromProps.includes(option.value)) {
              selectedValues.push(option.value);
            } else {
              unselectedValues.push(option.value);
            }
          }
        });

        return {
          ...state,
          selectedValues: [...selectedValues],
          unselectedValues: [...unselectedValues]
        };
      }
    }

    case actions.SET_FILTER_VALUE:
      return {
        ...state,
        filterValue: action.payload
      };

    case actions.SET_SELECTED_VALUES: {
      const { selectedValues, unselectedValues } = action.payload;

      return {
        ...state,
        unselectedValues: [...unselectedValues],
        selectedValues: [...selectedValues]
      };
    }

    default:
      return state;
  }
}
