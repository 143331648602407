import type { History } from 'history';

import type { EdErrorResponseType } from '@edapp/authoring-logic';
import type { CourseSummaryType } from '@edapp/courseware-logic';
import type { Language } from '@rio/store/languages/types';

import type { PlaylistItemType, PlaylistTranslationType, PlaylistType } from './types';

/*
 * === === === === === === === ===
 * === === FETCH_PLAYLISTS === ===
 * === === === === === === === ===
 */

export const FETCH_PLAYLISTS = 'FETCH_PLAYLISTS';
export type FetchPlaylists = PaginatedPayload<{ searchTerm: string }>;
export const fetchPlaylists = (
  searchTerm: string = '',
  page: number = 1,
  pageSize: number = 25
): Action<FetchPlaylists> => ({
  type: FETCH_PLAYLISTS,
  payload: {
    page,
    pageSize,
    searchTerm
  }
});

export const FETCH_PLAYLISTS_SUCCESS = 'FETCH_PLAYLISTS_SUCCESS';
export type FetchPlaylistsSuccess = {
  totalCount: number;
  items: PlaylistType[];
  page: number;
};

export const FETCH_PLAYLISTS_FAILURE = 'FETCH_PLAYLISTS_FAILURE';
export type FetchPlaylistsFailure = EdErrorResponseType;

/*
 * === === === === === === === ===
 * === === FETCH_PLAYLIST_ITEM ===
 * === === === === === === === ===
 */

export const FETCH_PLAYLIST_ITEM = 'FETCH_PLAYLIST_ITEM';
export type FetchPlaylistItem = { playlistId: string };
export const fetchPlaylistItem = (playlistId: string): Action<FetchPlaylistItem> => ({
  type: FETCH_PLAYLIST_ITEM,
  payload: { playlistId }
});

export const FETCH_PLAYLIST_ITEM_SUCCESS = 'FETCH_PLAYLIST_ITEM_SUCCESS';
export type FetchPlaylistItemSuccess = PlaylistItemType;

export const FETCH_PLAYLIST_ITEM_FAILURE = 'FETCH_PLAYLIST_ITEM_FAILURE';
export type FetchPlaylistItemFailure = EdErrorResponseType;

/*
 * === === === === === === === === === ===
 * === === FETCH_PLAYLIST_COURSES ===
 * === === === === === === === === === ===
 */

export const FETCH_PLAYLIST_COURSES = 'FETCH_PLAYLIST_COURSES';
export type FetchPlaylistCourses = { playlistId: string };
export const fetchPlaylistCourses = (playlistId: string): Action<FetchPlaylistCourses> => ({
  type: FETCH_PLAYLIST_COURSES,
  payload: { playlistId }
});

export const FETCH_PLAYLIST_COURSES_SUCCESS = 'FETCH_PLAYLIST_COURSES_SUCCESS';
export type FetchPlaylistCoursesSuccess = {
  playlistId: string;
  courses: CourseSummaryType[];
};

export const FETCH_PLAYLIST_COURSES_FAILURE = 'FETCH_PLAYLIST_COURSES_FAILURE';
export type FetchPlaylistCoursesFailure = EdErrorResponseType;

/*
 * === === === === === === === ===
 * === === CREATE_PLAYLIST === ===
 * === === === === === === === ===
 */

export const CREATE_PLAYLIST = 'CREATE_PLAYLIST';
export type CreatePlaylist = { playlist: Partial<PlaylistItemType>; history: History };
export const createPlaylist = (
  playlist: Partial<PlaylistItemType>,
  history: History
): Action<CreatePlaylist> => ({
  type: CREATE_PLAYLIST,
  payload: { playlist, history }
});

export const CREATE_PLAYLIST_SUCCESS = 'CREATE_PLAYLIST_SUCCESS';
export type CreatePlaylistSuccess = string;

export const CREATE_PLAYLIST_FAILURE = 'CREATE_PLAYLIST_FAILURE';
export type CreatePlaylistFailure = EdErrorResponseType;

/*
 * === === === === === === === ===
 *  === === SET_PLAYLIST === ===
 * === === === === === === === ===
 */
export const SET_PLAYLIST = 'SET_PLAYLIST';
export type SetPlaylist = { id: string; playlist: Partial<PlaylistItemType> };
export const setPlaylist = (id: string, playlist: Partial<PlaylistItemType>) => ({
  type: SET_PLAYLIST,
  payload: { id, playlist }
});

/*
 * === === === === === === === === === === === ===
 * === === ACKNOWLEDGE_PREREQUISITES_WARNING === ===
 * === === === === === === === === === === === ===
 */
export const ACKNOWLEDGE_PREREQUISITES_WARNING = 'ACKNOWLEDGE_PREREQUISITES_WARNING';
export type AcknowledgePrerequisitesWarning = { id: string };
export const acknowledgePrerequisitesWarning = (id: string) => ({
  type: ACKNOWLEDGE_PREREQUISITES_WARNING,
  payload: { id }
});

/*
 * === === === === === === === ===
 *  === === ADD_PLAYLIST_TRANSLATION === ===
 * === === === === === === === ===
 */
export const ADD_PLAYLIST_TRANSLATION = 'ADD_PLAYLIST_TRANSLATION';
export type AddPlaylistTranslation = {
  id: string;
  languageCode: string;
  translation: PlaylistTranslationType;
};
export const addPlaylistTranslation = (
  id: string,
  languageCode: string,
  translation: PlaylistTranslationType
) => ({
  type: ADD_PLAYLIST_TRANSLATION,
  payload: { id, languageCode, translation }
});

/*
 * === === === === === === === ===
 *  === === UPDATE_PLAYLIST_TRANSLATION === ===
 * === === === === === === === ===
 */
export const UPDATE_PLAYLIST_TRANSLATION = 'UPDATE_PLAYLIST_TRANSLATION';
export type UpdatePlaylistTranslation = {
  id: string;
  languageCode: string;
  translation: PlaylistTranslationType;
};
export const updatePlaylistTranslation = (
  id: string,
  languageCode: string,
  translation: PlaylistTranslationType
) => ({
  type: UPDATE_PLAYLIST_TRANSLATION,
  payload: { id, languageCode, translation }
});

/*
 * === === === === === === === ===
 *  === === REMOVE_PLAYLIST_TRANSLATION === ===
 * === === === === === === === ===
 */
export const REMOVE_PLAYLIST_TRANSLATION = 'REMOVE_PLAYLIST_TRANSLATION';
export type RemovePlaylistTranslation = {
  id: string;
  languageCode: string;
};
export const removePlaylistTranslation = (id: string, languageCode: string) => ({
  type: REMOVE_PLAYLIST_TRANSLATION,
  payload: { id, languageCode }
});

/*
 * === === === === === === === ===
 *  === === SAVE_PLAYLIST === ===
 * === === === === === === === ===
 */

export const SAVE_PLAYLIST = 'SAVE_PLAYLIST';
export type SavePlaylist = { playlistId: string };
export const savePlaylist = (playlistId: string): Action<SavePlaylist> => ({
  type: SAVE_PLAYLIST,
  payload: { playlistId }
});

export const SAVE_PLAYLIST_SUCCESS = 'SAVE_PLAYLIST_SUCCESS';
export type SavePlaylistSuccess = {};

export const SAVE_PLAYLIST_FAILURE = 'SAVE_PLAYLIST_FAILURE';
export type SavePlaylistFailure = EdErrorResponseType;

/*
 * === === === === === === === ===
 *  === === REMOVE_PLAYLIST === ===
 * === === === === === === === ===
 */

export const REMOVE_PLAYLIST = 'REMOVE_PLAYLIST';
export type RemovePlaylist = { playlistId: string };
export const removePlaylist = (playlistId: string): Action<RemovePlaylist> => ({
  type: REMOVE_PLAYLIST,
  payload: { playlistId }
});

export const REMOVE_PLAYLIST_SUCCESS = 'REMOVE_PLAYLIST_SUCCESS';
export type RemovePlaylistSuccess = {};

export const REMOVE_PLAYLIST_FAILURE = 'REMOVE_PLAYLIST_FAILURE';
export type RemovePlaylistFailure = EdErrorResponseType;

/*
 * === === === === === === === ===
 * === === === === === === === === === ===
 * === === REMOVE_PLAYLIST_COURSE === ===
 * === === === === === === === === === ===
 */

export const REMOVE_COURSE_FROM_PLAYLIST = 'REMOVE_COURSE_FROM_PLAYLIST';
export type RemoveCourseFromPlaylist = {
  courseId: string;
  playlistId: string;
};
export const removeCourseFromPlaylist = (
  courseId: string,
  playlistId: string
): Action<RemoveCourseFromPlaylist> => ({
  type: REMOVE_COURSE_FROM_PLAYLIST,
  payload: { courseId, playlistId }
});

/* === === === === === === === ===
 *  === === ADD_COURSES === ===
 * === === === === === === === ===
 */

export const PLAYLIST_ADD_COURSES = 'PLAYLIST_ADD_COURSES';
export type PlaylistAddCourses = { id: string; courses: CourseSummaryType[] };
export const addCoursesToPlaylist = ({
  id,
  courses
}: PlaylistAddCourses): Action<PlaylistAddCourses> => ({
  type: PLAYLIST_ADD_COURSES,
  payload: { id, courses }
});

/* === === === === === === === ===
 *  === === REORDER_COURSES === ===
 * === === === === === === === ===
 */

export const PLAYLIST_REORDER_COURSES = 'PLAYLIST_REORDER_COURSES';
export type PlaylistReorderCourses = { id: string; courses: CourseSummaryType[] };
export const reorderCoursesInPlaylist = ({
  id,
  courses
}: PlaylistReorderCourses): Action<PlaylistReorderCourses> => ({
  type: PLAYLIST_REORDER_COURSES,
  payload: { id, courses }
});

export const CLEAR_DRAFT_PLAYLIST_ITEM = 'CLEAR_DRAFT_PLAYLIST_ITEM';
export const clearDraftPlaylistItem = () => ({
  type: CLEAR_DRAFT_PLAYLIST_ITEM
});

/* === === === === === === === ===
 *  === === FETCH_NEW_TRANSLATION === ===
 * === === === === === === === ===
 */

export const PLAYLIST_FETCH_NEW_TRANSLATION = 'PLAYLIST_FETCH_NEW_TRANSLATION';

export const PLAYLIST_FETCH_NEW_TRANSLATION_SUCCESS = 'PLAYLIST_FETCH_NEW_TRANSLATION_SUCCESS';

export const PLAYLIST_FETCH_NEW_TRANSLATION_FAILURE = 'PLAYLIST_FETCH_NEW_TRANSLATION_FAILURE';
export type PlaylistFetchNewTranslationError = EdErrorResponseType;

export type PlaylistFetchNewTranslation = {
  playlistId: string;
  fromLanguage: Language;
  toLanguage: Language;
};
export const fetchNewPlaylistTranslation = ({
  playlistId,
  fromLanguage,
  toLanguage
}: PlaylistFetchNewTranslation): Action<PlaylistFetchNewTranslation> => ({
  type: PLAYLIST_FETCH_NEW_TRANSLATION,
  payload: { playlistId, fromLanguage, toLanguage }
});

/* === === === === === === === ===
 *  === === LIST_CHANGE_LEXO_RANK === ===
 * === === === === === === === ===
 */
export const PLAYLIST_LIST_CHANGE_LEXO_RANK = 'PLAYLIST_LIST_CHANGE_LEXO_RANK';
export const PLAYLIST_LIST_CHANGE_LEXO_RANK_SUCCESS = 'PLAYLIST_LIST_CHANGE_LEXO_RANK_SUCCESS';
export const PLAYLIST_LIST_CHANGE_LEXO_RANK_FAILURE = 'PLAYLIST_LIST_CHANGE_LEXO_RANK_FAILURE';
export type PlaylistListChangeLexoRank = {
  playlists: PlaylistType[];
  playlistId: string;
  prevId: string | null;
  nextId: string | null;
};
export const changePlaylistLexoRank = (
  playlists: PlaylistType[],
  playlistId: string,
  prevId: string | null,
  nextId: string | null
): Action<PlaylistListChangeLexoRank> => ({
  type: PLAYLIST_LIST_CHANGE_LEXO_RANK,
  payload: { playlists, playlistId, prevId, nextId }
});
