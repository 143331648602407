export const carouselAnimVariants = {
  initial: () => ({ opacity: 0 }),
  animate: () => ({ opacity: 1 }),
  exit: (diff: number) => {
    if (diff < 0) {
      return { x: '5%', opacity: 0 };
    }

    if (diff === 0) {
      return { opacity: 0 };
    }

    return { x: '-5%', opacity: 0 };
  }
};
