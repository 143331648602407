import * as React from 'react';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import type { ScrollState } from '../../windowed-list/types';
import { InputGroup } from '../input-group/InputGroup';
import { SearchInput } from '../input/SearchInput';
import * as actions from './actions';
import SelectableListBox from './components/SelectableListBox';
import { SelectableListArrows, SelectableListLoading } from './components/SelectableListIcons';
import { SelectableListItems } from './components/SelectableListItems';
import reducer, { getInitialState } from './reducer';
import type { Option, Props, State } from './types';
import { createInternalOptions } from './utils';

export const SELECTABLE_LIST_TEST_ID = 'selectable-list';
export const SELECTABLE_LIST_UNSELECTED_BOX_TEST_ID = `${SELECTABLE_LIST_TEST_ID}-box-unselected`;
export const SELECTABLE_LIST_SELECTED_BOX_TEST_ID = `${SELECTABLE_LIST_TEST_ID}-box-selected`;
export const SELECTABLE_LIST_FILTER_INPUT_TEST_ID = `${SELECTABLE_LIST_TEST_ID}-filter-input`;
export const SELECTABLE_LIST_FILTER_CLEAR_TEST_ID = `${SELECTABLE_LIST_TEST_ID}-filter-clear`;
export const SELECTABLE_LIST_ITEM_TEST_ID = `${SELECTABLE_LIST_TEST_ID}-item`;

// The component takes a type argument to know the type of the option values passed in
export function SelectableList<OptionValue>({
  testId = SELECTABLE_LIST_TEST_ID,
  unselectedBoxTestId = SELECTABLE_LIST_UNSELECTED_BOX_TEST_ID,
  selectedBoxTestId = SELECTABLE_LIST_SELECTED_BOX_TEST_ID,
  filterInputTestId = SELECTABLE_LIST_FILTER_INPUT_TEST_ID,
  filterClearTestId = SELECTABLE_LIST_FILTER_CLEAR_TEST_ID,
  itemTestId = SELECTABLE_LIST_ITEM_TEST_ID,
  filter = false,
  options,
  selectedValues,
  unselectedBox = {},
  selectedBox = {},
  disabled,
  loading = false
}: Props<OptionValue>) {
  const [state, dispatch] = React.useReducer<
    (state: State<OptionValue>, action: actions.ActionUnion<OptionValue>) => State<OptionValue>
  >(reducer, getInitialState());

  const { t } = useTranslation();

  const [isVertical, setIsVertical] = React.useState<boolean | undefined>(undefined);

  const containerRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const filterValue = (filter && typeof filter === 'object' && filter.value) || '';
    dispatch(actions.setFilterValue(filterValue));
  }, [filter]);

  React.useEffect(() => {
    dispatch(actions.reselect(options, selectedValues));
  }, [options, selectedValues]);

  React.useLayoutEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [containerRef.current]);

  const handleResize = React.useCallback(() => {
    if (containerRef.current == null) {
      return;
    }

    const containerWidth = containerRef.current.clientWidth;
    if (containerWidth < 600) {
      setIsVertical(true);
    } else {
      setIsVertical(false);
    }
  }, [containerRef.current]);

  const handleSelect = (option: Option<OptionValue>) => {
    unselectedBox.onSelect?.(option.value);
    unselectedBox.onChange?.(
      state.unselectedValues.filter(unselected => unselected !== option.value)
    );
    selectedBox.onChange?.(state.selectedValues.concat(option.value));

    // Only update internal state if uncontrolled
    if (selectedValues == null) {
      dispatch(actions.selectOption(option.value));
    }
  };

  const handleClear = (option: Option<OptionValue>) => {
    selectedBox.onClear?.(option.value);
    selectedBox.onChange?.(state.selectedValues.filter(selected => selected !== option.value));
    unselectedBox.onChange?.(state.unselectedValues.concat(option.value));

    // Only update internal state if uncontrolled
    if (selectedValues == null) {
      dispatch(actions.clearOption(option.value));
    }
  };

  const handleSelectAll = () => {
    if (disabled) {
      return;
    }

    const selected: OptionValue[] = [];
    const unselected: OptionValue[] = [];

    options.forEach(option => {
      if ('options' in option) {
        option.options.forEach(subOption => {
          if (subOption.isDisabled && !state.selectedValues.includes(subOption.value)) {
            unselected.push(subOption.value);
          } else {
            selected.push(subOption.value);
          }
        });
      } else {
        if (option.isDisabled && !state.selectedValues.includes(option.value)) {
          unselected.push(option.value);
        } else {
          selected.push(option.value);
        }
      }
    });

    if (typeof unselectedBox.onSelectAll === 'function') {
      unselectedBox.onSelectAll(selected);
    }

    unselectedBox.onChange?.(unselected);
    selectedBox.onChange?.(selected);

    // // Only update internal state if uncontrolled
    if (selectedValues == null) {
      dispatch(actions.setSelectedValues(selected, unselected));
    }
  };

  const handleClearAll = () => {
    if (disabled) {
      return;
    }

    const selected: OptionValue[] = [];
    const unselected: OptionValue[] = [];

    options.forEach(option => {
      if ('options' in option) {
        option.options.forEach(subOption => {
          if (subOption.isDisabled && state.selectedValues.includes(subOption.value)) {
            selected.push(subOption.value);
          } else {
            unselected.push(subOption.value);
          }
        });
      } else {
        if (option.isDisabled && state.selectedValues.includes(option.value)) {
          selected.push(option.value);
        } else {
          unselected.push(option.value);
        }
      }
    });

    if (typeof selectedBox.onClearAll === 'function') {
      selectedBox.onClearAll(unselected);
    }

    unselectedBox.onChange?.(unselected);
    selectedBox.onChange?.(selected);

    // Only update internal state if uncontrolled
    if (selectedValues == null) {
      dispatch(actions.setSelectedValues(selected, unselected));
    }
  };

  // Separate props to avoid overwriting when spreading props
  const internalFilterProps = !filter || filter === true ? {} : filter;
  const { value: filterPropsValue, onChange, onClear, ...restOfFilterProps } = internalFilterProps;

  const handleFilterChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    internalFilterProps.onChange?.(changeEvent);

    const { value } = changeEvent.currentTarget;
    dispatch(actions.setFilterValue(value));
  };

  const handleClearFilter = () => {
    internalFilterProps.onClear?.();

    dispatch(actions.setFilterValue(''));
  };

  const handleFetchMoreUnselected = !!unselectedBox.onLoadMore
    ? (state: ScrollState) => {
        if (state === 'end') {
          unselectedBox.onLoadMore?.();
        }
      }
    : undefined;

  const handleFetchMoreSelected = !!selectedBox.onLoadMore
    ? (state: ScrollState) => {
        if (state === 'end') {
          selectedBox.onLoadMore?.();
        }
      }
    : undefined;

  const { unselectedOptions, selectedOptions } = createInternalOptions(
    options,
    selectedValues,
    state
  );

  const getShowCount = (showCount?: boolean | number): boolean | undefined =>
    typeof showCount === 'number' ? true : showCount;

  const getCount = (optionCount: number, showCount?: boolean | number) =>
    typeof showCount === 'number' ? showCount : optionCount;

  return (
    <div ref={containerRef} data-testid={testId}>
      {!!filter && (
        <InputGroupWithMargin>
          <SearchInput
            inputTestId={filterInputTestId}
            clearTestId={filterClearTestId}
            {...restOfFilterProps}
            value={filterPropsValue ?? state.filterValue}
            onChange={handleFilterChange}
            onClear={handleClearFilter}
          />
        </InputGroupWithMargin>
      )}

      <SelectableListContainer isVertical={!!isVertical}>
        <SelectableListBox
          testId={unselectedBoxTestId}
          isVertical={!!isVertical}
          title={unselectedBox.title || t('selectable-list.unselected', { ns: 'ed-components' })}
          onSelectAll={(!!unselectedBox.onSelectAll && handleSelectAll) || undefined}
          showCount={getShowCount(unselectedBox.showCount)}
          count={getCount(unselectedOptions.count, unselectedBox.showCount)}
          disabled={disabled}
          isCountLoading={loading}
        >
          <SelectableListItems<OptionValue>
            items={unselectedOptions}
            disabled={disabled}
            itemTestId={itemTestId}
            onItemClick={handleSelect}
            onChangeScrollState={handleFetchMoreUnselected}
          />
        </SelectableListBox>

        {loading ? (
          <SelectableListLoading isVertical={!!isVertical} />
        ) : (
          <SelectableListArrows isVertical={!!isVertical} />
        )}

        <SelectableListBox
          testId={selectedBoxTestId}
          isVertical={!!isVertical}
          title={selectedBox.title || t('selectable-list.selected', { ns: 'ed-components' })}
          onClearAll={(!!selectedBox.onClearAll && handleClearAll) || undefined}
          showCount={getShowCount(selectedBox.showCount)}
          count={getCount(selectedOptions.count, selectedBox.showCount)}
          disabled={disabled}
          isCountLoading={loading}
        >
          <SelectableListItems<OptionValue>
            items={selectedOptions}
            disabled={disabled}
            itemTestId={itemTestId}
            onItemClick={handleClear}
            onChangeScrollState={handleFetchMoreSelected}
          />
        </SelectableListBox>
      </SelectableListContainer>
    </div>
  );
}

const SelectableListContainer = styled.div<{ isVertical: boolean }>`
  flex-flow: ${({ isVertical }) => (isVertical ? 'column' : 'row')} wrap;
  display: flex;
  align-items: flex-end;
`;

const InputGroupWithMargin = styled(InputGroup)`
  width: 100%;
  max-width: 550px;
  margin-right: auto;
`;
