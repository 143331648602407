import { omit } from 'lodash-es';
import type { PublicDetailsState } from '../types';
import type * as Actions from './actions';
import type ActionType from './actions';
import type { CoursePublicDetailExcludedLesson } from './types';
import type { Handler } from '../../../../types';

const merge = (
  stateData: PublicDetailsState['data'],
  publicDetailId: string,
  data: CoursePublicDetailExcludedLesson[] | CoursePublicDetailExcludedLesson
): PublicDetailsState['data'] => ({
  ...stateData,
  [publicDetailId]: {
    ...stateData[publicDetailId],
    excludedLessons: Array.isArray(data)
      ? data.reduce(
          (acc, cur) => ({ ...acc, [cur.id]: cur }),
          stateData[publicDetailId].excludedLessons
        )
      : { ...stateData[publicDetailId].excludedLessons, [data.id]: data }
  }
});

const remove = (
  stateData: PublicDetailsState['data'],
  publicDetailId: string,
  id: string
): PublicDetailsState['data'] => ({
  ...stateData,
  [publicDetailId]: {
    ...stateData[publicDetailId],
    excludedLessons: omit(stateData[publicDetailId].excludedLessons, id)
  }
});

type ExcludedLessonHandler<Action = null> = Handler<PublicDetailsState, Action>;

const fetchExcludedLessons: ExcludedLessonHandler<Actions.FetchCoursePublicDetailExcludedLessonsAction> = state => ({
  ...state,
  loading: true
});

const fetchExcludedLessonsSuccess: ExcludedLessonHandler<Actions.FetchCoursePublicDetailExcludedLessonsSuccessAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: '',
  data: merge(state.data, action.payload.publicDetailId, action.payload.excludedLessons)
});

const fetchExcludedLessonsFailure: ExcludedLessonHandler<Actions.FetchCoursePublicDetailExcludedLessonsFailureAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: action.payload.message
});

const createExcludedLesson: ExcludedLessonHandler<Actions.CreateCoursePublicDetailExcludedLessonAction> = state => ({
  ...state,
  loading: true
});

const createExcludedLessonSuccess: ExcludedLessonHandler<Actions.CreateCoursePublicDetailExcludedLessonSuccessAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: '',
  data: merge(state.data, action.payload.coursePublicDetailId, action.payload)
});

const createExcludedLessonFailure: ExcludedLessonHandler<Actions.CreateCoursePublicDetailExcludedLessonFailureAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: action.payload.message
});

const deleteExcludedLesson: ExcludedLessonHandler<Actions.DeleteCoursePublicDetailExcludedLessonAction> = state => ({
  ...state,
  loading: true
});

const deleteExcludedLessonSuccess: ExcludedLessonHandler<Actions.DeleteCoursePublicDetailExcludedLessonSuccessAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: '',
  data: remove(state.data, action.payload.publicDetailId, action.payload.id)
});

const deleteExcludedLessonFailure: ExcludedLessonHandler<Actions.DeleteCoursePublicDetailExcludedLessonFailureAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: action.payload.message
});

export type Handlers = {
  [type in ActionType]: (
    state: PublicDetailsState,
    action: Actions.ActionTypes
  ) => PublicDetailsState;
};

export const handlers: Handlers = {
  FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSONS: fetchExcludedLessons,
  FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSONS_SUCCESS: fetchExcludedLessonsSuccess,
  FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSONS_FAILURE: fetchExcludedLessonsFailure,

  CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON: createExcludedLesson,
  CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_SUCCESS: createExcludedLessonSuccess,
  CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_FAILURE: createExcludedLessonFailure,

  DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON: deleteExcludedLesson,
  DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_SUCCESS: deleteExcludedLessonSuccess,
  DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_FAILURE: deleteExcludedLessonFailure
};
