import { genId } from '@edapp/utils';

import type { Question } from '../../store/types';
import type {
  GameElevatorData,
  GameImageWordMatchData,
  GameJeopardyData,
  GameJumbleData,
  GameMemoryData,
  GameTrueFalseData,
  Methods
} from './types';

export const methods: Methods = {
  'game-elevator'(e: GameElevatorData) {
    return e.images.map((img: any) => {
      let incorrect;

      const correct = (img.correct || []).map((i: any) => {
        return {
          correct: true,
          content: i,
          type: 'text'
        };
      });

      incorrect = (img.incorrect || []).map((i: any) => {
        return {
          correct: false,
          content: i,
          type: 'text'
        };
      });

      if (incorrect.length === 0) {
        incorrect = {
          correct: false,
          type: 'incorrect-answer'
        };
      }

      return {
        question: {
          type: 'image',
          category: e.title,
          content: img.title,
          text: img.title
        },
        answers: correct.concat(incorrect)
      };
    });
  },
  'game-image-word-match'(e: GameImageWordMatchData) {
    return e.images.map(img => {
      return {
        question: {
          type: 'image',
          category: e.title,
          content: img.image,
          text: img.image
        },
        answers: [
          {
            content: img.title,
            text: img.title,
            correct: true,
            type: 'text'
          },
          {
            content: img.title,
            text: img.title,
            correct: false,
            type: 'incorrect-answer'
          }
        ]
      };
    });
  },
  'game-jeopardy'(e: GameJeopardyData) {
    const questions: Question[] = [];
    for (const cat of e.categories) {
      if (!cat.questions) {
        continue;
      }

      for (const question of cat.questions) {
        if (!question.mix) {
          continue;
        }

        for (const mix of question.mix) {
          if (!mix.text) {
            continue;
          }

          questions.push({
            question: {
              id: genId(),
              type: 'text',
              category: cat.title,
              text: cat.title,
              content: mix.text
            },
            answers: [
              {
                id: genId(),
                content: 'true',
                text: 'true',
                type: 'text',
                correct: mix.true || false
              },
              {
                id: genId(),
                content: 'false',
                text: 'false',
                type: 'text',
                correct: !mix.true || false
              }
            ]
          });
        }
      }
    }

    return questions;
  },
  'game-jumble'(e: GameJumbleData) {
    return e.words.map((w: any) => {
      return {
        question: {
          type: 'image',
          category: e.title,
          content: w.image,
          text: w.image
        },
        answers: [
          {
            text: w.word,
            content: w.word,
            correct: true,
            type: 'text'
          },
          {
            text: w.word,
            content: w.word,
            correct: false,
            type: 'incorrect-answer'
          }
        ]
      };
    });
  },
  'game-memory'(e: GameMemoryData) {
    return e.tiles.map((tile: any) => {
      return {
        question: {
          type: 'image',
          category: e.title,
          text: tile.left,
          content: tile.left
        },
        answers: [
          {
            content: tile.right,
            text: tile.right,
            correct: true,
            type: 'image'
          },
          {
            content: tile.right,
            text: tile.right,
            correct: false,
            type: 'incorrect-answer'
          }
        ]
      };
    });
  },
  'game-next-in-order'() {
    return [];
  },
  'game-true-or-false'(e: GameTrueFalseData) {
    return e.statements.map((statement: any) => {
      return {
        question: {
          type: 'text',
          content: statement.text,
          text: statement.text,
          category: statement.text
        },
        answers: [
          {
            content: `${statement.true}`,
            text: `${statement.true}`,
            correct: true,
            type: 'text'
          },
          {
            content: `${!statement.true}`,
            text: `${!statement.true}`,
            correct: false,
            type: 'text'
          }
        ]
      };
    });
  }
};

export default methods;
