import { DEFAULT_LANGUAGE_CODE } from '@edapp/hippo-client';

import { LanguageActionTypes } from './constants';
import type { LanguageActionsUnionType, LanguageType } from './types';

export const initialLanguageState: LanguageType = {
  code: DEFAULT_LANGUAGE_CODE
};

const reducer = (state = initialLanguageState, action: LanguageActionsUnionType) => {
  switch (action.type) {
    case LanguageActionTypes.SET_RINGO_LANGUAGE: {
      return {
        ...state,
        code: action.payload ?? state.code
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
