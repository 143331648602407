import { delay } from 'redux-saga';
import { fork, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import { RequestActions } from '@edapp/request';
import { routes } from '@rio/router';
import type { LmsStoreState } from '@rio/store/types';

import * as actions from './actions';
import { getCourseCollection } from './selectors';
import type { CourseCollection } from './types';

function* handleCreateCourseCollection(action: Action<actions.CreateCourseCollection>) {
  yield delay(2500);

  yield put(
    RequestActions.postAuthed(
      `api/courseCollections`,
      actions.CREATE_COURSE_COLLECTION_SUCCESS,
      actions.CREATE_COURSE_COLLECTION_FAILURE,
      false,
      { title: action.payload.title }
    )
  );

  const { success, failure } = yield race({
    success: take(actions.CREATE_COURSE_COLLECTION_SUCCESS),
    failure: take(actions.CREATE_COURSE_COLLECTION_FAILURE)
  });

  if (failure) {
    // It's being handled in the reducer
    return;
  }

  const collectionId = (success as Action<actions.CreateCourseCollectionSuccess>).payload;

  itly.courseCollectionCreated({ course_collection_id: collectionId });

  action.payload.history.push(routes.courseCollection.getRoute({ collectionId }));
}

function* handleSaveCourseCollection(action: Action<actions.SaveCourseCollection>) {
  yield delay(1500);
  const { collectionId } = action.payload;

  const data: CourseCollection = {
    ...(yield select((s: LmsStoreState) => getCourseCollection(s, collectionId))),
    ...action.payload.data
  };

  yield put(
    RequestActions.putAuthed(
      `api/courseCollections/${collectionId}`,
      actions.SAVE_COURSE_COLLECTION_SUCCESS,
      actions.SAVE_COURSE_COLLECTION_FAILURE,
      false,
      data
    )
  );
}

function* handleFetchCourseCollection(action: Action<actions.FetchCourseCollection>) {
  yield delay(1000);

  const { collectionId } = action.payload;

  if (collectionId === 'new') {
    return;
  }

  yield put(
    RequestActions.getAuthed(
      `api/courseCollections/${collectionId}`,
      actions.FETCH_COURSE_COLLECTION_SUCCESS,
      actions.FETCH_COURSE_COLLECTION_FAILURE,
      false,
      { ...action.payload }
    )
  );
}

function* handleFetchCourseCollectionCourseList(
  action: Action<actions.FetchCourseCollectionCourseList>
) {
  yield delay(1000);

  const { collectionId, includeDraft, page, pageSize } = action.payload;

  yield put(
    RequestActions.getAuthed(
      `api/courseCollections/${collectionId}/search`,
      actions.FETCH_COURSE_COLLECTION_COURSE_LIST_SUCCESS,
      actions.FETCH_COURSE_COLLECTION_COURSE_LIST_FAILURE,
      false,
      { IncludeDraft: includeDraft, Page: page, PageSize: pageSize }
    )
  );
}

function* handleChangeRankCourseCollectionCourseList(
  action: Action<actions.ChangeRankCourseCollectionCourseList>
) {
  const { collectionId, courseIds } = action.payload;

  yield put(
    RequestActions.putAuthed(
      `api/courseCollections/${collectionId}/courses/rank`,
      actions.CHANGE_RANK_COURSE_COLLECTION_COURSE_LIST_SUCCESS,
      actions.CHANGE_RANK_COURSE_COLLECTION_COURSE_LIST_FAILURE,
      false,
      { ids: courseIds }
    )
  );
}

function* handleMoveCourseToCollection(action: Action<actions.MoveCourseToCollection>) {
  const { courseId, collectionId, includeToCollection } = action.payload;

  yield put(
    RequestActions.putAuthed(
      `/api/courses/${courseId}/courseCollections`,
      actions.MOVE_COURSE_TO_COLLECTION_SUCCESS,
      actions.MOVE_COURSE_TO_COLLECTION_FAILURE,
      false,
      {
        courseCollectionId: !!includeToCollection ? collectionId : ''
      }
    )
  );

  const { failure } = yield race({
    success: take(actions.MOVE_COURSE_TO_COLLECTION_SUCCESS),
    failure: take(actions.MOVE_COURSE_TO_COLLECTION_FAILURE)
  });

  if (failure) {
    // It's being handled in the reducer
    return;
  }

  if (includeToCollection) {
    // Track courses moved into the given collection
    itly.courseCollectionCourseMoved({
      course_collection_id: collectionId,
      course_id: courseId
    });
  }

  // after move a course - refetch from the first page
  yield put(actions.fetchCourseCollectionCourseList(1, 1000, collectionId, true));
}

function* watchCreateCourseCollection() {
  yield takeLatest(actions.CREATE_COURSE_COLLECTION, handleCreateCourseCollection);
}

function* watchSaveCourseCollection() {
  yield takeLatest(actions.SAVE_COURSE_COLLECTION, handleSaveCourseCollection);
}

function* watchFetchCourseCollection() {
  yield takeLatest(actions.FETCH_COURSE_COLLECTION, handleFetchCourseCollection);
}

function* watchFetchCourseCollectionCourseList() {
  yield takeLatest(
    actions.FETCH_COURSE_COLLECTION_COURSE_LIST,
    handleFetchCourseCollectionCourseList
  );
}

function* watchChangeRankCourseCollectionCourseList() {
  yield takeLatest(
    actions.CHANGE_RANK_COURSE_COLLECTION_COURSE_LIST,
    handleChangeRankCourseCollectionCourseList
  );
}

function* watchMoveCourseToCollection() {
  yield takeLatest(actions.MOVE_COURSE_TO_COLLECTION, handleMoveCourseToCollection);
}

const sagas = [
  fork(watchCreateCourseCollection),
  fork(watchSaveCourseCollection),
  fork(watchFetchCourseCollection),
  fork(watchFetchCourseCollectionCourseList),
  fork(watchChangeRankCourseCollectionCourseList),
  fork(watchMoveCourseToCollection)
];

export default sagas;
