import * as React from 'react';
import styled from 'styled-components';
import type { ContentLibrarySectionType, ContentLibraryCourseType } from '@edapp/content-library';
import { CoursesLoading as Loading } from './components/Loading';
import { NoResults } from './components/NoResults';
import { ContentCardsList } from '../common/content-cards-list/ContentCardsList';
import { Sections } from './components/Sections';
import type { PaginatedType } from './types';
import { useTranslation } from 'react-i18next';

type BaseProps = {
  onSelect: (id: string) => void;
  orderedSelect?: boolean;
  paginated?: PaginatedType;
  selectedCourseIds: string[];
  isSearching?: boolean;
  onChangeView: (index: number) => void;
  viewIndex: number;
  onViewBack: () => void;
  onViewAll: (sectionId: string) => void;
  selectedSection: ContentLibrarySectionType[];
  loading: boolean;
  userToken: string | null;
  hippoUrl: string;
};

export type Props = BaseProps & {
  items: (ContentLibraryCourseType | ContentLibrarySectionType)[];
};

export const SectionedCoursePicker: React.FC<Props> = props => {
  const scrollRef = React.useRef<HTMLDivElement>(null);

  return (
      <StyledCoursePickerBody ref={scrollRef}>
        <BodyContents {...props} />
      </StyledCoursePickerBody>
  );
};

const BodyContents: React.FC<Props> = ({
  items,
  orderedSelect,
  selectedCourseIds,
  onSelect,
  isSearching,
  viewIndex,
  onChangeView,
  onViewAll,
  onViewBack,
  selectedSection,
  loading
}) => {
  const { t } = useTranslation();

  if (loading) {
    return <Loading />;
  }

  if (!items.length && !loading) {
    return <NoResults />;
  }

  if (!!isSearching) {
    return (
      <ContentCardsList
        courses={items as ContentLibraryCourseType[]}
        onSelect={onSelect}
        selectedCourseIds={selectedCourseIds}
        orderedSelect={!!orderedSelect}
        selectText={t('common.select')}
        selectedText={t('common.selected')}
        byText={t('common.by')}
      />
    );
  }

  if (!isSearching) {
    return (
      <Sections
        items={items as ContentLibrarySectionType[]}
        onSelect={onSelect}
        selectedCourseIds={selectedCourseIds}
        orderedSelect={orderedSelect}
        loading={loading}
        viewIndex={viewIndex}
        onChangeView={onChangeView}
        onViewAll={onViewAll}
        onViewBack={onViewBack}
        selectedSection={selectedSection}
      />
    );
  }

  return null;
};

const StyledCoursePickerBody = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGrey};
  width: 100%;
  height: 80%;
  overflow-x: hidden;
`;
