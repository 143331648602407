import * as React from 'react';

import AnimatedNumber from 'animated-number-react';
import styled, { css } from 'styled-components';

import { Avatar, Box, Typography } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';

import { LEADERBOARD_DEBOUNCE_DELAY } from '../../constants';
import type { RankingParticipant } from '../../types';

type Props = RankingParticipant & {
  totalParticipants: number;
};
export const SummaryRankingItem: React.FC<Props> = ({
  participantName,
  participantAvatarUrl,
  totalParticipants,
  totalScore,
  rank
}) => {
  return (
    <Wrapper
      flex
      alignItems="center"
      justifyContent="space-between"
      bgColor="white"
      rounded
      p={2}
      gap={2}
    >
      <Box flex alignItems="center" gap={2}>
        <Avatar size="md" src={participantAvatarUrl} alt={participantName} />
        <Box flex alignItems="center" gap={0.5}>
          <Text variant="titleMedium" component="h3">
            {rank}
          </Text>
          <Typography variant="subtext">/ {totalParticipants}</Typography>
          <Typography variant="subtext" color="textMuted" ml={0.5}>
            You
          </Typography>
        </Box>
      </Box>
      <Typography testId="ranking-my-score">
        <AnimatedNumber
          value={totalScore}
          duration={1000}
          delay={LEADERBOARD_DEBOUNCE_DELAY}
          formatValue={(n: number) => n.toFixed(0)}
        />
      </Typography>
    </Wrapper>
  );
};

const Wrapper = styled(Box)(
  ({ theme }) => css`
    border: 1px solid ${theme.colors.blue};
  `
);
