import * as React from 'react';
import styled, { css } from 'styled-components';
import { Button } from '@edapp/ed-components';
import type { ConditionPredicate } from './types';
import { ConditionPredicateItem, makeConditionPredicate } from './ConditionPredicateItem';
import { useTranslation } from 'react-i18next';

const ConditionsContainer = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.space(2.5)}px;
    margin-bottom: ${theme.space(2.5)}px;
  `
);

type Props = {
  options: ConditionPredicate[];
  value: ConditionPredicate[];
  onChange: (newValue: ConditionPredicate[]) => void;
};

export const ConditionComposer: React.FC<Props> = ({ options, value, onChange }) => {
  const handleAddPredicate = () => {
    onChange([...value, makeConditionPredicate('', '')]);
  };
  const { t } = useTranslation();

  const handleRemovePredicate = (v: ConditionPredicate) => {
    onChange([...value.filter(i => i.target.id !== v.target.id)]);
  };

  const handleChangePredicate = (
    oldPredicate: ConditionPredicate,
    newPredicate: ConditionPredicate
  ) => {
    if (value.length === 0) {
      onChange([newPredicate]);
      return;
    }

    const newValues = value.map(i => {
      return i.target.id === oldPredicate.target.id ? newPredicate : i;
    });
    onChange([...newValues]);
  };

  const usedTargets = value.map(i => i.target.id);

  const conditionOptions = ({ target: { id } }: ConditionPredicate) =>
    options.filter(
      option =>
        value.length === 0 || !usedTargets.includes(option.target.id) || option.target.id === id
    );

  return (
    <>
      <ConditionsContainer>
        {value.length === 0 && (
          <ConditionPredicateItem
            predicate={makeConditionPredicate('', '')}
            options={options}
            onRemove={handleRemovePredicate}
            onChange={handleChangePredicate}
          />
        )}

        {value.map(condition => (
          <ConditionPredicateItem
            key={condition.target.id}
            predicate={condition}
            options={conditionOptions(condition)}
            onRemove={handleRemovePredicate}
            onChange={handleChangePredicate}
          />
        ))}
      </ConditionsContainer>

      {value.length > 0 && (
        <Button
          variant="light"
          onClick={handleAddPredicate}
          disabled={value.length === options.length}
          testId="add-predicate"
        >
          {t('user-groups.custom-fields.add-new-rule-condition')}
        </Button>
      )}
    </>
  );
};
