import * as React from 'react';
import { clamp } from 'lodash-es';

export type CarouselContextState = {
  currentStep: number;
  totalSteps: number;
  nextStep: () => void;
  lastStep: () => void;
  goToStep: (step: number) => void;
};

export const CarouselContext = React.createContext<CarouselContextState | undefined>(undefined);

export const useCarousel = () => {
  const context = React.useContext(CarouselContext);

  if (!context) {
    throw new Error('Usage of "useCarousel" should be within the CarouselProvider component');
  }

  return context;
};

export type ProviderProps = {
  totalSteps: number;
  currentStep?: number;
  infinite?: boolean;
};

export const CarouselProvider: React.FC<React.PropsWithChildren<ProviderProps>> = ({
  totalSteps,
  currentStep: defaultCurrentStep = 1,
  infinite = false,
  children
}) => {
  const clampStep = (step: number) => {
    if (!infinite) {
      return clamp(step, 1, totalSteps);
    }

    if (step > totalSteps) {
      return 1;
    } else if (step < 1) {
      return totalSteps;
    } else {
      return step;
    }
  };

  const [currentStep, setCurrentStep] = React.useState<number>(clampStep(defaultCurrentStep));

  const nextStep = () => {
    setCurrentStep(prevStep => clampStep(prevStep + 1));
  };

  const lastStep = () => {
    setCurrentStep(prevStep => clampStep(prevStep - 1));
  };

  const goToStep = (step: number) => {
    setCurrentStep(clampStep(step));
  };

  return (
    <CarouselContext.Provider value={{ currentStep, totalSteps, nextStep, lastStep, goToStep }}>
      {children}
    </CarouselContext.Provider>
  );
};
