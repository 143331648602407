import type { UseHippoPaginationOptions } from '@edapp/request';
import { useHippoPagination } from '@edapp/request';

import { getUserGroupCourseDetails } from './api';
import { QueryKeys } from './constants';
import type { SearchCourseDetailRequest, SearchCourseDetailResponses } from './types';

export function useGetUserGroupCourseDetails(
  userGroupId: string,
  request?: Omit<SearchCourseDetailRequest, 'page'>,
  options?: UseHippoPaginationOptions<SearchCourseDetailResponses>
) {
  return useHippoPagination(
    [QueryKeys.userGroupCourses, userGroupId, request],
    (hippoUrl, userToken) => ({ pageParam }) =>
      getUserGroupCourseDetails(hippoUrl, userToken, userGroupId, { ...request, page: pageParam }),
    options
  );
}
