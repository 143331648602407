import * as React from 'react';
import styled from 'styled-components';
import { Box } from '../box';
import { Crumb } from './Crumb';
import type { CrumbType, BreadcrumbProps } from './types';

export const Breadcrumb: React.FC<BreadcrumbProps> = props => {
  const filledCrumbs = props.crumbs.filter(i => !!i) as CrumbType[];
  return (
    <Box flex>
      <StyledBreadcrumbList data-testid="breadcrumb-list">
        {filledCrumbs.map((crumb: CrumbType, i: number) => (
          <Crumb
            key={`crumb-${crumb.type}-${crumb.title}`}
            isLast={i === props.crumbs.length - 1}
            {...crumb}
          />
        ))}
      </StyledBreadcrumbList>
    </Box>
  );
};

const StyledBreadcrumbList = styled.ul`
  list-style-type: none;
  white-space: nowrap;

  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  padding-left: 0;
  margin-bottom: 0;

  & > :nth-child(1) {
    margin-left: 0;
  }
`;
