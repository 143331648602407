import * as React from 'react';

import { Trans, useTranslation } from 'react-i18next';

import { Box, TrashIcon, useBreakpointMatchMedia } from '@edapp/ed-components';
import { Input, Text } from '@edapp/sc-web-ui';

import type { Props as DeleteDialogProps } from './DeleteDialog';
import { DeleteDialog } from './DeleteDialog';

type ConfirmationComponentProps = {
  subject: string;
  warningText?: string;
  confirmText?: string;
  onInputChange: (e: React.FormEvent<HTMLInputElement>) => void;
  confirmationWord: string;
};

const ConfirmationComponent: React.FC<ConfirmationComponentProps> = props => {
  const { t } = useTranslation();
  const isMobile = useBreakpointMatchMedia('sm', 'max-width');

  return (
    <>
      <Text variant="bodySmall" color="surface.text.weak">
        {props?.warningText
          ? props?.warningText
          : props.subject === 'user group'
          ? t('courses.delete-dialog.confirmation-warning-usergroups', {
              subject: props.subject
            })
          : t('courses.delete-dialog.confirmation-warning', { subject: props.subject })}
        &nbsp;
        {t('courses.delete-dialog.irreversible-action')}
      </Text>

      <Box
        pt={2}
        flex={true}
        flexDirection="column"
        alignItems="stretch"
        justifyContent="center"
        gap={0.5}
      >
        <Text variant="labelMedium" color="negative.text.default">
          <Trans
            i18nKey="courses.delete-dialog.delete-instructions"
            values={{ confirmationWord: props.confirmationWord.toLocaleLowerCase() }}
          />
        </Text>

        <Input
          autoFocus={!isMobile}
          placeholder={props.confirmationWord.toLocaleLowerCase()}
          onChange={props.onInputChange}
        />
      </Box>
    </>
  );
};

const DeleteWithConfirmationDialog: React.FC<DeleteDialogProps> = props => {
  const [hasConfirmed, setHasConfirmed] = React.useState(false);
  const { t } = useTranslation();
  const CONFIRMATION_WORD = t('common.delete') ?? 'delete';

  React.useEffect(() => {
    if (!props.isOpen) {
      setHasConfirmed(false);
    }
  }, [props.isOpen]);

  const onConfirmDelete = () => {
    if (hasConfirmed) {
      props.onDelete();
    }
  };

  const handleChangeConfirmationInput = (e: React.FormEvent<HTMLInputElement>) => {
    setHasConfirmed(
      e.currentTarget.value.toLocaleLowerCase() === CONFIRMATION_WORD.toLocaleLowerCase()
    );
  };

  return (
    <DeleteDialog
      position="middle"
      {...props}
      headerIcon={TrashIcon}
      onDelete={onConfirmDelete}
      disableConfirmButton={!hasConfirmed}
      confirmButtonVariant="danger"
      headerIconColor="red"
      confirmText={props?.confirmText}
      extraBodyContents={
        <Box flex={true} flexDirection="column">
          <ConfirmationComponent
            confirmationWord={CONFIRMATION_WORD}
            subject={props.subject || ''}
            warningText={props.warningText}
            onInputChange={handleChangeConfirmationInput}
          />
          {props.extraBodyContents}
        </Box>
      }
    />
  );
};

export { DeleteWithConfirmationDialog };
