import * as React from 'react';

import { Text } from '@edapp/sc-web-ui';

import { COURSE_LIST_VIEW, SECTIONED_COURSES_VIEW } from '../constants';
import { ItemCount, ItemIndex } from './ItemIndex';
import { ViewBackButton } from './ViewBackButton';

type Props = {
  sectionId: string;
  sectionTitle: string;
  totalCourses: number;
  totalCoursesInCategory: number;
  viewIndex: number;
  onClick: (index: number) => void;
};

const isNumber = (arg: any) => !isNaN(arg) && isFinite(arg);

export const ContentSectionCategory: React.FC<Props> = ({
  sectionTitle,
  totalCourses,
  totalCoursesInCategory,
  viewIndex,
  onClick
}) => (
  <>
    {viewIndex === COURSE_LIST_VIEW && <ViewBackButton onClick={onClick} viewIndex={viewIndex} />}
    <Text variant="titleLarge">{sectionTitle}</Text>

    {viewIndex === SECTIONED_COURSES_VIEW && isNumber(totalCourses) && (
      <ItemIndex totalCourses={totalCourses} totalCoursesInCategory={totalCoursesInCategory} />
    )}

    {viewIndex === COURSE_LIST_VIEW && isNumber(totalCourses) && (
      <ItemCount totalCourses={totalCourses} />
    )}
  </>
);
