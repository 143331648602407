import * as React from 'react';

import type { ControlProps } from 'react-select/src/components/Control';
import styled, { css, useTheme } from 'styled-components';

import { Box } from '../../../box';
import { SearchIcon } from '../../../icons';
import type { Option } from '../types';

export function SearchableControl<OptionType>(props: ControlProps<Option<OptionType>>) {
  const {
    children,
    className,
    getStyles,
    isFocused,
    isDisabled,
    innerRef,
    innerProps,
    isMulti,
    selectProps
  } = props;

  const { isSearchable, hideSearchIcon = false } = selectProps;

  const { borderColor, ...restOfStyles } = getStyles('control', props) as any;
  const theme = useTheme();

  const background = isDisabled
    ? theme.colors.lightGrey
    : isSearchable
    ? theme.colors.white
    : theme.colors.transparent;

  return (
    <StyledWrapper
      isFocused={isFocused}
      // override react-select default styles
      style={{
        ...restOfStyles,
        background,
        boxShadow: 'none'
      }}
    >
      <StyledControl
        ref={innerRef}
        className={className}
        isFocused={isFocused}
        isMulti={isMulti}
        {...innerProps}
      >
        <Box flex={true} alignItems="center" flexGrow={1} style={{ width: '100%' }}>
          {isSearchable && !hideSearchIcon && <SearchIcon size="sm" color="textMuted" />}
          {children}
        </Box>
      </StyledControl>
    </StyledWrapper>
  );
}

export const StyledWrapper = styled.div<{ isFocused: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  border-color: ${({ theme }) => theme.colors.greyHover};

  :focus {
    border-color: ${({ theme }) => theme.colors.blue};
  }

  :hover {
    border-color: ${({ theme, isFocused }) =>
      isFocused ? theme.colors.blue : theme.colors.greyHover};
  }
`;

export const StyledControl = styled.div<{ isFocused: boolean; isMulti: boolean }>`
  /* IE11 workaround to keep flex centered is to provide a height less than min-height */
  ${({ isMulti }) => !isMulti && 'height: 37px;'}
  min-height: 38px;
  flex: 1 1 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  ${({ theme }) =>
    css`
      padding: 0 ${theme.space(1.5)}px;
    `}
`;
