import * as React from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { ContentLibrarySelectors } from '@edapp/content-library';
import styled from 'styled-components';
import { Dialog, DialogWrapper, CloseDialogButton } from '@edapp/ed-components';
import { usePrevious } from '@edapp/ed-components/hooks';
import { SectionedCoursePicker } from './SectionedCoursePicker';
import { CoursePickerDialogHeader } from './CoursePickerDialogHeader';
import { without, xor } from 'lodash-es';
import type { SectionedCourseDialogProps } from './types';
import { useDebounce } from '@edapp/ed-components/hooks';
import { ContentLibraryActions } from '@edapp/content-library';
import { SECTIONED_COURSES_VIEW, COURSE_LIST_VIEW } from './constants';
import { ErrorFallbackUI } from '../common/error-fallback-ui/ErrorFallbackUI';

export const SectionedCoursePickerDialog: React.FC<SectionedCourseDialogProps> = ({
  buttonText,
  buttonLoading,
  description,
  isOpen,
  items = [],
  onButtonClick,
  onClose,
  onSearch,
  orderedSelect,
  title,
  selectionLimit,
  preSelectedCourseIds = [],
  excludedCourseIds
}: SectionedCourseDialogProps) => {
  const actions = bindActionCreators(
    {
      setSearchTerm: ContentLibraryActions.setSearch,
      fetchCourses: ContentLibraryActions.fetchCourses
    },
    useDispatch()
  );

  const loading = useSelector(ContentLibrarySelectors.getIsLoading);
  const error = useSelector(ContentLibrarySelectors.getError);

  const previousPreSelectedIds = usePrevious(preSelectedCourseIds);

  const [selectedCourseIds, setSelectedCourseIds] = React.useState<string[]>(preSelectedCourseIds);
  const [selectedSectionId, setSelectedSectionId] = React.useState('');
  const [coursePickerViewIndex, setCoursePickerViewIndex] = React.useState(SECTIONED_COURSES_VIEW);

  const selectedSection = useSelector(ContentLibrarySelectors.getSection(selectedSectionId));
  const courseSearchResults = useSelector(ContentLibrarySelectors.getCourseSearchResultItems);
  const searchTerm = useSelector(ContentLibrarySelectors.getSearchTerm);

  const debounceSearchTerm = useDebounce<string>(searchTerm, 300);
  const userToken = useSelector((state: any) => state.config.userToken);
  const hippoUrl = useSelector((state: any) => state.config.hippoUrl);

  React.useEffect(() => {
    if (!!previousPreSelectedIds && !!xor(preSelectedCourseIds, previousPreSelectedIds).length) {
      setSelectedCourseIds(preSelectedCourseIds);
    }
  }, [preSelectedCourseIds]);

  const handleClose = () => {
    setSelectedCourseIds(preSelectedCourseIds);
    onClose();
  };

  const onChangeView = (index: number) => {
    if (index === COURSE_LIST_VIEW) {
      setCoursePickerViewIndex(SECTIONED_COURSES_VIEW);
    }

    if (index === SECTIONED_COURSES_VIEW) {
      setCoursePickerViewIndex(COURSE_LIST_VIEW);
    }
  };

  const onViewBack = () => {
    setSelectedSectionId('');
  };

  const onViewAll = (sectionId: string) => {
    if (!!sectionId) {
      setSelectedSectionId(sectionId);
    }
  };

  const onSelectCourses = (id: string) => {
    if (buttonLoading) {
      return;
    }

    if (
      !!selectionLimit &&
      !selectedCourseIds.includes(id) &&
      selectedCourseIds.length >= selectionLimit
    ) {
      return;
    }

    setSelectedCourseIds(prevSelectedCourseIds =>
      prevSelectedCourseIds.includes(id)
        ? without(prevSelectedCourseIds, id)
        : [...prevSelectedCourseIds, id]
    );
  };

  const onAddCourses = () => {
    onButtonClick(selectedCourseIds);
    setSelectedCourseIds(preSelectedCourseIds);
  };

  const filteredCourseSearchResults = React.useMemo(
    () =>
      !!excludedCourseIds
        ? courseSearchResults.filter(x => !excludedCourseIds.includes(x.id))
        : courseSearchResults,
    [courseSearchResults, excludedCourseIds]
  );

  const filteredItems = React.useMemo(
    () =>
      !!excludedCourseIds
        ? items.map(item => ({
            ...item,
            courses: {
              ...item.courses,
              items: item.courses.items.filter(x => !excludedCourseIds.includes(x.id))
            }
          }))
        : items,
    [items, excludedCourseIds]
  );

  React.useEffect(() => {
    if (!isOpen) {
      actions.setSearchTerm('');
      setCoursePickerViewIndex(SECTIONED_COURSES_VIEW);
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (onSearch) {
      onSearch(searchTerm);
    }
  }, [debounceSearchTerm]);

  React.useEffect(() => {
    if (coursePickerViewIndex === COURSE_LIST_VIEW) {
      setCoursePickerViewIndex(SECTIONED_COURSES_VIEW);
    }
  }, [searchTerm]);

  return (
    <StyledDialogWrapper>
      <StyledDialog isOpen={isOpen} onClose={handleClose} usePortal={true}>
        <CloseDialogButton onClick={handleClose} />

        <CoursePickerDialogHeader
          title={title}
          description={description}
          buttonContents={buttonText}
          selectedCount={selectedCourseIds.length}
          isLoading={buttonLoading}
          onClickButton={onAddCourses}
          onSearch={(value: string) => actions.setSearchTerm(value)}
          selectionLimit={selectionLimit}
        />
        {error ? (
          <ErrorFallbackUI text={error} actionText={'Close'} action={handleClose} />
        ) : (
          <SectionedCoursePicker
            items={!!searchTerm ? filteredCourseSearchResults : filteredItems}
            isSearching={!!searchTerm}
            selectedCourseIds={selectedCourseIds}
            onSelect={onSelectCourses}
            orderedSelect={!!orderedSelect}
            viewIndex={coursePickerViewIndex}
            onChangeView={onChangeView}
            onViewAll={onViewAll}
            onViewBack={onViewBack}
            selectedSection={selectedSection}
            loading={loading}
            userToken={userToken}
            hippoUrl={hippoUrl}
          />
        )}
      </StyledDialog>
    </StyledDialogWrapper>
  );
};

const StyledDialog = styled(Dialog)`
  width: 1320px;
  height: 100%;
  flex-direction: column;
  overflow-x: hidden;
`;

const StyledDialogWrapper = styled.div`
  ${DialogWrapper} {
    overflow: hidden;
  }
`;
