import type { LessonType, StyleConfiguration } from './types';

export enum RingoActionTypes {
  // Ringo Lesson
  SET_RINGO_LESSON = 'SET_RINGO_LESSON'
}

export const fetchLessonUri = 'api/lessons';

export const initialCustomCSS = `#lesson-header-title-content,#slides{border-bottom:1px solid var(--secondary-font-colour)}:root{--primary-palette-colour:#6559FF;--secondary-palette-colour:#E9EEF6;--primary-font-colour:#FFFFFF;--secondary-font-colour:#1F2533}#lesson-menu,#slides-view,#slides-view-inner{color:var(--secondary-font-colour)!important;background-color:var(--secondary-palette-colour)!important}.btn.btn-muted,.btn.btn-primary,.btn.btn-solid,.slide-chat.show-msg-true .message--answer.active,.slide-game-memory .game-tiles .btn.btn-solid,.slide-multiple-choice-game.state-complete .selectable.correct .btn{background:var(--primary-palette-colour);color:var(--primary-font-colour)}.btn.btn-muted.__active,.btn.btn-muted:hover,.btn.btn-primary:hover,.btn.btn-primary__active,.btn.btn-solid.__active,.btn.btn-solid:hover{background:var(--primary-palette-colour);color:var(--primary-font-colour);filter:brightness(90%)}#lesson-header-nav{background:#00000040;color:#fff}#lesson-header-nav-menu-btn{border-left:1px solid var(--secondary-palette-colour)}#lesson-header-title-content{color:var(--secondary-font-colour)}#indicator{background-color:#00000040;color:var(--primary-palette-colour)}.pswp__item{background:var(--primary-palette-colour)}.selectable.active .btn{background-color:var(--secondary-palette-colour);color:var(--secondary-font-colour)}.slide-expandable-list .item.active .item-button,.slide-expandable-list .item.active .item-button:after,.slide-multiple-choice-game.state-complete .selectable.incorrect .btn{background:var(--secondary-palette-colour);color:var(--secondary-font-colour)}.slide-chat.show-msg-true .message--answer.active:after{border-color:var(--primary-palette-colour) transparent transparent}`;

export const initialStyleConfiguration: StyleConfiguration = {
  colors: {},
  hasScormExitButton: false,
  preventLogoutOnExit: false,
  disableInteractionSummary: false,
  hasAnswerFeedback: false,
  pagination: true,
  language: '',
  direction: 'ltr',
  disableLessonScore: false,
  minimumScore: 0,
  indicator: true,
  pageNumbers: true,
  logo: '',
  background: '',
  enableCustomCSS: false,
  customCSS: '',
  soundDisabled: false,
  preferences: {
    useParentBackgroundImage: true,
    useParentColors: true,
    useParentCustomCss: true,
    useParentLogo: true
  }
};

export const initialLesson: LessonType = {
  id: '',
  archiveSize: 0,
  author: '',
  showStarProgressScore: true,
  configuration: {
    isDebug: false,
    index: 0,
    isAudio: false,
    isOnline: false,
    lessonPreviewConfigurationHeight: 768,
    lessonPreviewConfigurationWidth: 400,
    narration: {},
    scale: 1,
    slides: [],
    state: '',
    title: '',
    styleConfiguration: { ...initialStyleConfiguration }
  },
  duration: 60,
  hideContentType: false,
  hideProgress: false,
  hideScore: false,
  containsSurvey: false,
  courseId: '',
  createdDateTime: new Date(),
  description: '',
  externalId: '',
  file: '',
  isArchived: false,
  isTutorial: false,
  lastBuildSha: '',
  metaTags: {},
  modified: new Date(),
  scormFile: '',
  scormFileName: '',
  useScormFile: false,
  title: '',
  useCourseBranding: false,
  courseTitle: '',
  collectionId: '',
  collectionTitle: '',
  collectionRank: 0,
  collectionIsManual: false,
  prerequisites: [],
  previewPath: '',
  previewCustomCSS: '',
  public: false,
  rank: 0,
  stars: [],
  audience: {
    allowAllUserGroups: false,
    applicationId: ''
  },
  availability: {
    platforms: []
  },
  build: {
    web: false
  },
  contribution: {
    template: '',
    isHidden: false,
    isInputLesson: false,
    isUserContributed: false
  },
  display: {
    active: false
  },
  engine: {
    scorm: {
      supportsIframe: false
    },
    thomas: {
      version: '',
      supportsIframe: false
    },
    type: ''
  },
  features: {
    starOptions: {
      enabled: false
    },
    attemptRestriction: {
      resetCourseOnNumberAttempts: undefined,
      lockOnCompletion: false,
      lockIntervalMin: 0
    }
  },
  interationalisation: {
    locale: ''
  },
  importDetails: {
    original: {
      applicationId: '',
      courseId: '',
      lessonId: '',
      documentId: ''
    },
    timestamp: new Date()
  },
  planning: {
    startDateTime: undefined,
    endDateTime: undefined
  },
  scoring: {
    enabled: false
  },
  isSponsored: false,
  isRateable: true,
  latestSavedSlidesStateIdentifier: '1'
};

export enum MandatoryTypes {
  NotSpecified = 'not-specified',
  DueBy = 'due-by',
  RelativeDueBy = 'relative-due-by',
  NoDueDate = 'no-due-date',
  RecurringCompletionDate = 'recurring-completion-date',
  RecurringPublishDate = 'recurring-publish-date',
  RecurringStaticDate = 'recurring-static-date'
}
