import * as React from 'react';
import { components as SelectComponents } from 'react-select';
import type { SingleValueProps } from 'react-select/src/components/SingleValue';
import styled, { css } from 'styled-components';
import { Box } from '../../../box';
import type { Option } from '../types';

export function SingleValue<OptionValueType>(props: SingleValueProps<Option<OptionValueType>>) {
  const { children, data } = props;

  return (
    <StyledContainer {...props}>
      <Box flex={true} gap={0.5} style={{ color: 'currentColor' }}>
        {data?.adornment}
        {children}
      </Box>
    </StyledContainer>
  );
}

const StyledContainer = styled(SelectComponents.SingleValue)(
  ({ isDisabled }) => css`
    color: ${({ theme }) => theme.colors.text};
    ${isDisabled
      ? css`
          opacity: 0.5;
        `
      : ''}
  `
);
