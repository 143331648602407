import type { SurveyStore } from '@rio/store/survey/types';

import SurveyActionTypes from './constants';

export const initialSurveyState: SurveyStore = {
  lesson: null,
  fetching: false,
  error: null
};

const reducer: Reducer<SurveyStore> = (state = initialSurveyState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SurveyActionTypes.FETCH_SURVEY:
      return {
        ...state,
        lesson: null,
        fetching: true
      };
    case SurveyActionTypes.FETCH_SURVEY_FULFILLED:
      return {
        ...state,
        lesson: payload,
        fetching: false
      };
    default:
      return { ...state };
  }
};

export default reducer;
