import { omit } from 'lodash-es';
import type { PublicDetailsState } from '../types';
import type * as Actions from './actions';
import type { ActionType } from './actions';
import type { CoursePublicDetailRatingLink } from './types';
import type { Handler } from '../../../../types';

const merge = (
  stateData: PublicDetailsState['data'],
  publicDetailId: string,
  data: CoursePublicDetailRatingLink[] | CoursePublicDetailRatingLink
): PublicDetailsState['data'] => ({
  ...stateData,
  [publicDetailId]: {
    ...stateData[publicDetailId],
    ratingLinks: Array.isArray(data)
      ? data.reduce(
          (acc, cur) => ({ ...acc, [cur.id]: cur }),
          stateData[publicDetailId].ratingLinks
        )
      : { ...stateData[publicDetailId].ratingLinks, [data.id]: data }
  }
});

const remove = (
  stateData: PublicDetailsState['data'],
  publicDetailId: string,
  id: string
): PublicDetailsState['data'] => ({
  ...stateData,
  [publicDetailId]: {
    ...stateData[publicDetailId],
    ratingLinks: omit(stateData[publicDetailId].ratingLinks, id)
  }
});

type RatingLinkHandler<Action = null> = Handler<PublicDetailsState, Action>;

const fetchRatingLinks: RatingLinkHandler<Actions.FetchCoursePublicDetailRatingLinksAction> = state => ({
  ...state,
  loading: true
});

const fetchRatingLinksSuccess: RatingLinkHandler<Actions.FetchCoursePublicDetailRatingLinksSuccessAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: '',
  data: merge(state.data, action.payload.publicDetailId, action.payload.ratingLinks)
});

const fetchRatingLinksFailure: RatingLinkHandler<Actions.FetchCoursePublicDetailRatingLinksFailureAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: action.payload.message
});

const createRatingLink: RatingLinkHandler<Actions.CreateCoursePublicDetailRatingLinkAction> = state => ({
  ...state,
  loading: true
});

const createRatingLinkSuccess: RatingLinkHandler<Actions.CreateCoursePublicDetailRatingLinkSuccessAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: '',
  data: merge(state.data, action.payload.coursePublicDetailId, action.payload)
});

const createRatingLinkFailure: RatingLinkHandler<Actions.CreateCoursePublicDetailRatingLinkFailureAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: action.payload.message
});

const deleteRatingLink: RatingLinkHandler<Actions.DeleteCoursePublicDetailRatingLinkAction> = state => ({
  ...state,
  loading: true
});

const deleteRatingLinkSuccess: RatingLinkHandler<Actions.DeleteCoursePublicDetailRatingLinkSuccessAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: '',
  data: remove(state.data, action.payload.publicDetailId, action.payload.id)
});

const deleteRatingLinkFailure: RatingLinkHandler<Actions.DeleteCoursePublicDetailRatingLinkFailureAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: action.payload.message
});

export type Handlers = {
  [type in ActionType]: (
    state: PublicDetailsState,
    action: Actions.ActionTypes
  ) => PublicDetailsState;
};

export const handlers: Handlers = {
  FETCH_COURSE_PUBLIC_DETAIL_RATING_LINKS: fetchRatingLinks,
  FETCH_COURSE_PUBLIC_DETAIL_RATING_LINKS_SUCCESS: fetchRatingLinksSuccess,
  FETCH_COURSE_PUBLIC_DETAIL_RATING_LINKS_FAILURE: fetchRatingLinksFailure,

  CREATE_COURSE_PUBLIC_DETAIL_RATING_LINK: createRatingLink,
  CREATE_COURSE_PUBLIC_DETAIL_RATING_LINK_SUCCESS: createRatingLinkSuccess,
  CREATE_COURSE_PUBLIC_DETAIL_RATING_LINK_FAILURE: createRatingLinkFailure,

  DELETE_COURSE_PUBLIC_DETAIL_RATING_LINK: deleteRatingLink,
  DELETE_COURSE_PUBLIC_DETAIL_RATING_LINK_SUCCESS: deleteRatingLinkSuccess,
  DELETE_COURSE_PUBLIC_DETAIL_RATING_LINK_FAILURE: deleteRatingLinkFailure
};
