import { ActionDialog } from '@edapp/ed-components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  open: boolean;
  onClose: () => void;
};

const UserGroupStatusDialog: React.FC<Props> = props => {
  const { t } = useTranslation();

  return (
    <ActionDialog
      isOpen={props.open}
      onClose={props.onClose}
      title={t('user-groups.save-changes-pending.title')}
      description={t('user-groups.save-changes-pending.description')}
      onCancel={props.onClose}
      cancelButtonVariant="light"
      cancelText={t('common.ok')}
    />
  );
};

export default UserGroupStatusDialog;
