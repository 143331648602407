import * as React from 'react';
import { ErrorLogger } from '@edapp/monitoring';

export const OFFLINE_ERROR_MESSAGE =
  'Network error! Please make sure you are connected to an active network and try again';
export const CONTEXT_UNINITIALISED_ERROR =
  'You need use OnlineStatusContextProvider around this component';

type OnlineStatusState = {
  isOnline?: boolean;
};

const OnlineStatusContext = React.createContext<OnlineStatusState>({});

export const OnlineStatusContextProvider: React.FC<React.PropsWithChildren<OnlineStatusState>> = ({
  children,
  ...props
}) => {
  const [isOnline, setIsOnline] = React.useState<boolean>(
    props.isOnline === undefined ? true : props.isOnline
  );

  React.useEffect(() => {
    const setOnline = () => {
      setIsOnline(true);
    };

    window.addEventListener('online', setOnline);

    return () => {
      window.removeEventListener('online', setOnline);
    };
  }, []);

  React.useEffect(() => {
    const setOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('offline', setOffline);

    return () => {
      window.removeEventListener('offline', setOffline);
    };
  }, []);

  return (
    <OnlineStatusContext.Provider value={{ isOnline }}>{children}</OnlineStatusContext.Provider>
  );
};

/**
 *
 * When we upgrade to react-query v4, use the library's offline status instead
 * and remove this hook & context
 */
export const useOnlineStatus = () => {
  const { isOnline } = React.useContext(OnlineStatusContext);

  if (isOnline === undefined) {
    ErrorLogger.captureEvent(CONTEXT_UNINITIALISED_ERROR, 'error', {});
    return {
      isOnline: false,
      errorMessage: CONTEXT_UNINITIALISED_ERROR
    };
  }

  return { isOnline, errorMessage: isOnline ? undefined : OFFLINE_ERROR_MESSAGE };
};
