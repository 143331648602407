export enum Operators {
  includes = 'includes',
  contains = 'contains',
  doesNotContain = 'doesNotContain',
  doesNotMatch = 'doesNotMatch'
}

export enum Arguments {
  smallString = 'smallString'
}
