import * as React from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { Avatar, Box, Typography } from '@edapp/ed-components';
import { useCheckPermission } from '@edapp/permissions';
import { Text } from '@edapp/sc-web-ui';
import { getCurrentPlan } from '@rio/store/config/selectors';

type Props = {
  name: string;
};
export const AccountDetails: React.FC<Props> = ({ name }) => {
  const plan = useSelector(getCurrentPlan);
  const canViewBilling = useCheckPermission({
    /**
     * User permission to allow access to billing
     * e.g. An admin can access, but a content author cannot
     */
    resource: 'BILLING',
    scope: 'VIEW',
    /**
     * Billing plan permission to allow access to billing
     */
    accessPermission: 'ACCESS_BILLING_DETAILS'
  });

  return (
    <Wrapper p={2} flex gap={1.5} alignItems="center" testId="navigation-account-details">
      <Avatar alt={name} size="md" />
      <Content flex={1} flexDirection="column">
        <Text variant="labelLarge" component="h4" clamp={1}>
          {name}
        </Text>
        <Box flex gap={0.5} testId="navigation-account-plan">
          <Typography variant="subtext" color="textMuted">
            Plan:{' '}
          </Typography>
          <Typography
            isLink={canViewBilling}
            href={canViewBilling ? '/account/billing' : undefined}
            variant="subtext"
            color={canViewBilling ? 'blue' : 'textMuted'}
          >
            {!plan.isEnterprise && !plan.isOnFreePlan && !plan.isFreemium ? 'EdApp Pro ' : ''}
            {plan.displayName}
            {plan.isTrial ? ' Free Trial' : ''}
          </Typography>
        </Box>
      </Content>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  overflow: hidden;
`;

const Content = styled(Box)`
  min-width: 0;
`;
