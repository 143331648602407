import { useHippoMutation, useHippoPagination, useHippoQuery } from '@edapp/request';
import type {
  UseHippoMutationOptions,
  UseHippoMutationResult,
  UseHippoPaginationOptions,
  UseHippoPaginationResult,
  UseHippoQueryOptions
} from '@edapp/request/src/types';
import type { InfiniteData } from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';

import {
  changeRankCourseCollections,
  deleteCourseCollection,
  getCourseCollectionFilterInit,
  getCourseCollections
} from './apis';
import { CourseCollectionQueryKeys } from './constants';
import type {
  ChangeRankRequest,
  DeleteCourseCollectionParams,
  FilterInitResponse,
  GetCourseCollectionsResponse,
  JoinedCourseCollectionsRequest
} from './types';

export function useGetCourseCollections(
  payload: Omit<JoinedCourseCollectionsRequest, 'page'>,
  options?: UseHippoPaginationOptions<GetCourseCollectionsResponse>
): UseHippoPaginationResult<GetCourseCollectionsResponse> {
  return useHippoPagination(
    [CourseCollectionQueryKeys.courseCollectionList, payload],
    (hippoUrl, userToken) => ({ pageParam }) => {
      return getCourseCollections(hippoUrl, userToken, { ...payload, page: pageParam || 1 });
    },
    options
  );
}

export function useChangeRankCourseCollections(
  options?: UseHippoMutationOptions<void, ChangeRankRequest>
): UseHippoMutationResult<void, ChangeRankRequest> {
  const client = useQueryClient();

  return useHippoMutation(
    (hippoUrl, userToken) => collectionIds =>
      changeRankCourseCollections(hippoUrl, userToken, collectionIds),
    {
      ...options,
      onMutate: request => {
        options?.onMutate?.(request);

        client.setQueriesData<InfiniteData<GetCourseCollectionsResponse>>(
          { queryKey: [CourseCollectionQueryKeys.courseCollectionList] },
          data => {
            if (!data) {
              return { pages: [], pageParams: [] };
            }

            return {
              ...data,
              pages: data.pages.map(response => ({
                ...response,
                items: response.items.sort(
                  (a, b) => request.ids.indexOf(a.id) - request.ids.indexOf(b.id)
                )
              }))
            };
          }
        );
      }
    }
  );
}

export function useDeleteCourseCollection(
  options?: UseHippoMutationOptions<void, DeleteCourseCollectionParams>
): UseHippoMutationResult<void, DeleteCourseCollectionParams> {
  const client = useQueryClient();
  return useHippoMutation(
    (hippoUrl, userToken) => ({ cascade, id }) =>
      deleteCourseCollection(hippoUrl, userToken, id, cascade),
    {
      ...options,
      onSuccess: async (...args) => {
        options?.onSuccess?.(...args);
        await client.invalidateQueries({
          queryKey: [CourseCollectionQueryKeys.courseCollectionList]
        });
      }
    }
  );
}

export const useGetCourseCollectionFilterInit = (
  options?: UseHippoQueryOptions<FilterInitResponse>
) =>
  useHippoQuery(
    [CourseCollectionQueryKeys.courseCollectionFilterInit],
    (hippoUrl, userToken) => () => getCourseCollectionFilterInit(hippoUrl, userToken),
    options
  );
