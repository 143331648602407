import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-styled-flexboxgrid';

import { Box, Input } from '@edapp/ed-components';
import { hasBillingPermission } from '@rio/store/config/selectors';
import { setUserGroupItem } from '@rio/store/userGroups/actions';
import { getUserGroupIsAccountGroup, getUserGroupTitle } from '@rio/store/userGroups/selectors';
import { BillingPermission } from '@rio/utils/permissions/constants';

type OwnProps = { groupId: string };

type Props = OwnProps;

const UserGroupItemTitle: React.FC<Props> = ({ groupId }) => {
  const userGroupTitle = useSelector(getUserGroupTitle);
  const isAccountGroup = useSelector(getUserGroupIsAccountGroup);
  const hasUserGroupAccess = useSelector(
    hasBillingPermission(BillingPermission.ACCESS_USER_GROUPS)
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  return (
    <Row middle="sm">
      <Col xs={9}>
        <Box as="h1" py={3}>
          {!!isAccountGroup && userGroupTitle}
          {hasUserGroupAccess && !isAccountGroup && (
            <Input
              value={userGroupTitle}
              onChange={e => {
                dispatch(setUserGroupItem({ usergroup: { name: e.target.value } }));
              }}
              placeholder={t('user-groups.group-name-placeholder')}
              className="form-control-inherit"
              testId="user-group-name"
            />
          )}
        </Box>
      </Col>

      {hasUserGroupAccess && (
        <Col xs={3}>
          <div className="card card-lt text-center">
            <ul className="list-unstyled text-sm block-v-md tight">
              <li className="ms-hover">
                <a
                  href={`/analytics/activity?usergroup_ids=${groupId}`}
                  id="usergroup-activity-feed"
                >
                  {t('user-groups.activity-feed')}
                </a>
              </li>
              <li className="ms-hover">
                <a
                  href={`/analytics/user-progress?usergroup_ids=${groupId}`}
                  id="usergroup-course-completions"
                >
                  {t('user-groups.course-completions')}
                </a>
              </li>
            </ul>
          </div>
        </Col>
      )}
    </Row>
  );
};

export default UserGroupItemTitle;
