import * as React from 'react';

import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { Text } from '@edapp/sc-web-ui';

import { useBreakpointMatchMedia } from '../../../../hooks';
import { Box } from '../../box/Box';
import { Button } from '../../button/Button';
import type { Props as ActionDialogProps } from './ActionDialog';
import { CONFIRM_BUTTON_TEST_ID } from './constants';

type ConfirmButtonProps = Pick<
  ActionDialogProps,
  | 'onConfirm'
  | 'loading'
  | 'error'
  | 'confirmButtonVariant'
  | 'confirmText'
  | 'disableConfirmButton'
>;

export const ConfirmButton: React.FC<ConfirmButtonProps> = ({
  onConfirm,
  loading,
  confirmButtonVariant,
  confirmText,
  error,
  disableConfirmButton
}) => {
  const isMobile = useBreakpointMatchMedia('sm', 'max-width');
  if (!onConfirm) {
    return null;
  }
  if (error) {
    return null;
  }

  return (
    <StyledButton
      variant={confirmButtonVariant || 'primary'}
      size={isMobile ? 'lg' : 'md'}
      onClick={onConfirm}
      disabled={disableConfirmButton}
      testId={CONFIRM_BUTTON_TEST_ID}
      isLoading={loading}
    >
      {confirmText}
    </StyledButton>
  );
};

type CancelButtonProps = Pick<
  ActionDialogProps,
  | 'isClosable'
  | 'error'
  | 'cancelButtonVariant'
  | 'onCancel'
  | 'onClose'
  | 'disableCancelButton'
  | 'cancelText'
>;

export const CancelButton: React.FC<CancelButtonProps> = ({
  isClosable,
  error,
  cancelButtonVariant,
  onCancel,
  onClose,
  disableCancelButton,
  cancelText
}) => {
  const { t } = useTranslation();
  const isMobile = useBreakpointMatchMedia('sm', 'max-width');
  if (!isClosable) {
    return null;
  }
  if (error) {
    return (
      <StyledButton
        variant={cancelButtonVariant || 'light'}
        onClick={onCancel ?? onClose}
        size={isMobile ? 'lg' : 'md'}
      >
        {t('common.close')}
      </StyledButton>
    );
  }
  return (
    <StyledButton
      variant={cancelButtonVariant || 'light'}
      size={isMobile ? 'lg' : 'md'}
      onClick={onCancel ?? onClose}
      disabled={disableCancelButton}
    >
      {cancelText}
    </StyledButton>
  );
};

type BodyContentsProps = Pick<
  ActionDialogProps,
  'error' | 'title' | 'description' | 'extraBodyContents'
>;

export const BodyContents: React.FC<BodyContentsProps> = ({
  error,
  extraBodyContents,
  title,
  description
}) => {
  if (error) {
    return (
      <Box mt={1} gap={1} flex flexDirection="column">
        <StyledTitle variant="titleLarge" component="h2">
          {error.title}
        </StyledTitle>
        <Text variant="bodySmall" color="surface.text.weak">
          {error.description}
        </Text>
      </Box>
    );
  }

  return (
    <Box mt={1} gap={1} flex flexDirection="column">
      <StyledTitle variant="titleLarge" component="h2">
        {title}
      </StyledTitle>
      {!!description && (
        <Text variant="bodySmall" color="surface.text.weak">
          {description}
        </Text>
      )}
      {extraBodyContents}
    </Box>
  );
};

type ButtonsFooterProps = ConfirmButtonProps &
  CancelButtonProps &
  Pick<ActionDialogProps, 'hideCancelButton' | 'hideConfirmButton' | 'extraActions'>;

export const ButtonsFooter: React.FC<ButtonsFooterProps> = ({
  isClosable,
  error,
  loading,
  cancelButtonVariant,
  confirmButtonVariant,
  onClose,
  onCancel,
  onConfirm,
  hideCancelButton,
  hideConfirmButton,
  disableCancelButton,
  disableConfirmButton,
  cancelText,
  confirmText,
  extraActions
}) => {
  const { t } = useTranslation();

  return (
    <StyledFooterContainer flex={true} gap="xs">
      {!hideCancelButton && (
        <CancelButton
          key={cancelText} // reset the state of the button when the text changes
          isClosable={isClosable}
          error={error}
          cancelButtonVariant={cancelButtonVariant}
          onClose={onClose}
          onCancel={onCancel}
          disableCancelButton={disableCancelButton}
          cancelText={cancelText || t('common.cancel')}
        />
      )}
      {!!extraActions && extraActions}
      {!hideConfirmButton && (
        <ConfirmButton
          key={confirmText} // reset the state of the button when the text changes
          onConfirm={onConfirm}
          loading={loading}
          confirmButtonVariant={confirmButtonVariant}
          confirmText={confirmText || t('common.confirm')}
          error={error}
          disableConfirmButton={disableConfirmButton}
        />
      )}
    </StyledFooterContainer>
  );
};

const StyledFooterContainer = styled(Box)(
  ({ theme }) => css`
    width: 100%;
    @media (max-width: ${theme.breakpoints.sm}rem) {
      flex-direction: column-reverse;

      ${Button} {
        width: 100%;
      }
    }
  `
);

const StyledButton = styled(Button)`
  flex: 1 1;
`;

const StyledTitle = styled(Text)`
  margin: 0;
`;
