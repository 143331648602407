import * as React from 'react';
import styled, { css } from 'styled-components';
import { Typography } from '../../../typography/Typography';

type Props = {
  label: string;
  children: React.ReactNode;
};

const SelectableListItemGroup: React.FC<Props> = ({ label, children }) => (
  <>
    <ItemGroupLabel>{label}</ItemGroupLabel>
    <ItemGroupListContainer>{children}</ItemGroupListContainer>
  </>
);

const ItemGroupLabel = styled(Typography).attrs({ as: 'div', variant: 'subtitle2' })`
  ${({ theme }) => css`
    padding: ${theme.space(2)}px ${theme.space(2)}px;
    background: ${theme.colors.grey};
  `}
`;

const ItemGroupListContainer = styled.ul`
  ${({ theme }) => css`
    padding: 0 ${theme.space(2)}px;
    border-bottom: 1px solid ${theme.colors.white};
    list-style: none;
    margin: 0;
    :hover {
      background: ${theme.colors.lightBlue};
    }
  `}
`;

export default SelectableListItemGroup;
