import type { CourseType } from '@edapp/authoring-logic';
import { useHippoMutation, useHippoQuery } from '@edapp/request';
import type {
  EdExceptionType,
  UseHippoMutationOptions,
  UseHippoMutationResult,
  UseHippoQueryOptions,
  UseHippoQueryResult
} from '@edapp/request/src/types';
import { useQueryClient } from '@tanstack/react-query';

import { CourseCollectionQueryKeys } from '../course-collections/constants';
import {
  deleteCourse,
  duplicateCourse,
  emailDesktopLink,
  getCourse,
  getCoursePrerequisites,
  getCoursesToImport,
  getMiniCourse,
  patchCourse,
  publishCourse,
  republishCourse,
  unpublishCourse,
  updateCourse
} from './apis';
import { CourseQueryKeys, DEFAULT_LANGUAGE_CODE } from './constants';
import { useAPICourseLanguage } from './providers';
import { translationQueryKeys } from './translation-links';
import type {
  CourseResponse,
  DeleteCourseRequest,
  DuplicateCourseRequest,
  GetCoursesToImportResponse,
  PrerequisitesRequest,
  PrerequisitesResponse,
  PublishCourseRequest,
  PublishCourseResponse,
  RepublishCourseRequest,
  UnpublishCourseResponse,
  UseGetMiniCourse
} from './types';

export function useGetCourse<Selection = CourseResponse>(
  courseId: string,
  options?: UseHippoQueryOptions<CourseResponse, Selection>,
  extraOption?: { forceOriginalLanguage?: boolean }
): UseHippoQueryResult<Selection> {
  const contextLanguage = useAPICourseLanguage().language;
  const language = extraOption?.forceOriginalLanguage ? DEFAULT_LANGUAGE_CODE : contextLanguage;
  return useHippoQuery<CourseResponse, Selection>(
    [CourseQueryKeys.course, courseId, language],
    (hippoUrl, userToken) => () => getCourse(hippoUrl, userToken, language, courseId),
    {
      ...options,
      enabled: !!courseId && (options?.enabled ?? true),
      retry: (_, error) =>
        !(error as EdExceptionType)?.statusCode ||
        (error as EdExceptionType).statusCode.toString().startsWith('5') // only retry on server error 5xx
    }
  );
}

export function useGetCoursePrerequisites(
  payload: PrerequisitesRequest,
  options?: UseHippoQueryOptions<PrerequisitesResponse>
): UseHippoQueryResult<PrerequisitesResponse> {
  return useHippoQuery(
    [CourseQueryKeys.getCoursePrerequisites],
    (hippoUrl, userToken) => () => getCoursePrerequisites(hippoUrl, userToken, payload),
    options
  );
}

export function usePublishCourse(
  courseId: string,
  options?: UseHippoMutationOptions<PublishCourseResponse, PublishCourseRequest>
): UseHippoMutationResult<PublishCourseResponse, PublishCourseRequest> {
  const queryClient = useQueryClient();
  return useHippoMutation(
    (hippoUrl, userToken) => payload => publishCourse(hippoUrl, userToken, courseId, payload),
    {
      ...options,
      onSuccess: async (response, request, context) => {
        // Force refetch course query (overlapping refetch requests are de-duped by react-query)
        await queryClient.refetchQueries({ queryKey: [CourseQueryKeys.course, courseId] });
        options?.onSuccess?.(response, request, context);
      }
    }
  );
}

export function useRepublishCourse(
  courseId: string,
  options?: UseHippoMutationOptions<void, RepublishCourseRequest>
): UseHippoMutationResult<void, RepublishCourseRequest> {
  const queryClient = useQueryClient();
  return useHippoMutation(
    (hippoUrl, userToken) => payload => republishCourse(hippoUrl, userToken, courseId, payload),
    {
      ...options,
      onSuccess: (...args) => {
        queryClient.invalidateQueries({ queryKey: [CourseQueryKeys.course, courseId] });
        options?.onSuccess?.(...args);
      }
    }
  );
}

export function useUnpublishCourse(
  courseId: string,
  options?: UseHippoMutationOptions<UnpublishCourseResponse, void>
): UseHippoMutationResult<UnpublishCourseResponse, void> {
  const queryClient = useQueryClient();
  return useHippoMutation(
    (hippoUrl, userToken) => () => unpublishCourse(hippoUrl, userToken, courseId),
    {
      ...options,
      onSuccess: (response, request, context) => {
        queryClient.invalidateQueries({ queryKey: [CourseQueryKeys.course, courseId] });
        options?.onSuccess?.(response, request, context);
      }
    }
  );
}

export const useGetMiniCourse: UseGetMiniCourse = (options, request) =>
  useHippoQuery(
    [CourseQueryKeys.miniCourse, request],
    (hippoUrl, userToken) => () => getMiniCourse(hippoUrl, userToken, request),
    options
  );

/**
 * Used by template picker -> import slides
 */
export function useGetCoursesToImport(
  options?: UseHippoQueryOptions<GetCoursesToImportResponse>
): UseHippoQueryResult<GetCoursesToImportResponse> {
  return useHippoQuery(
    [CourseQueryKeys.coursesToImport],
    (hippoUrl, userToken) => () => getCoursesToImport(hippoUrl, userToken),
    {
      ...options,
      select: courseToImport => ({
        ...courseToImport,
        courses: courseToImport.courses.map(course => ({
          ...course,
          // this API return lessons alphabetically, thus need to sort lessons to follow its order in the course/preview
          lessons: course.lessons.sort((lessonA, lessonB) => lessonA.rank - lessonB.rank)
        }))
      })
    }
  );
}

export function useUpdateCourse(
  courseId: string,
  options?: UseHippoMutationOptions<CourseType, CourseType>
): UseHippoMutationResult<CourseType, CourseType> {
  const queryClient = useQueryClient();
  const { language } = useAPICourseLanguage();
  return useHippoMutation(
    (hippoUrl, userToken) => payload =>
      updateCourse(
        hippoUrl,
        userToken,
        courseId,
        language === DEFAULT_LANGUAGE_CODE ? payload : { ...payload, language }
      ),
    {
      ...options,
      onSuccess: (...args) => {
        options?.onSuccess?.(...args);
        queryClient.invalidateQueries({ queryKey: [CourseQueryKeys.course, courseId] });

        // invalidate cached translation links
        queryClient.invalidateQueries({
          queryKey: translationQueryKeys.getCourseTranslationLinks(courseId)
        });
      }
    }
  );
}

export function usePatchCourse(
  courseId: string,
  options?: UseHippoMutationOptions<Partial<CourseType>, Partial<CourseType>>
): UseHippoMutationResult<Partial<CourseType>, Partial<CourseType>> {
  const queryClient = useQueryClient();
  const { language } = useAPICourseLanguage();
  return useHippoMutation(
    (hippoUrl, userToken) => payload =>
      patchCourse(
        hippoUrl,
        userToken,
        courseId,
        language === DEFAULT_LANGUAGE_CODE ? payload : { ...payload, language }
      ),
    {
      ...options,
      onSuccess: (...args) => {
        options?.onSuccess?.(...args);
        const [, data] = args;
        queryClient.setQueryData<CourseType>(
          [CourseQueryKeys.course, courseId, language],
          oldData => ({
            ...(oldData ?? ({} as CourseType)),
            ...data
          })
        );
      }
    }
  );
}

export function useDeleteCourse(
  options?: UseHippoMutationOptions<void, DeleteCourseRequest>
): UseHippoMutationResult<void, DeleteCourseRequest> {
  const client = useQueryClient();
  return useHippoMutation(
    (hippoUrl, userToken) => payload => deleteCourse(hippoUrl, userToken, payload),
    {
      ...options,
      onSuccess: async (...args) => {
        options?.onSuccess?.(...args);
        await client.invalidateQueries({
          queryKey: [CourseCollectionQueryKeys.courseCollectionList]
        });
      }
    }
  );
}

export function useDuplicateCourse(
  options?: UseHippoMutationOptions<string, DuplicateCourseRequest>
): UseHippoMutationResult<string, DuplicateCourseRequest> {
  const client = useQueryClient();
  return useHippoMutation(
    (hippoUrl, userToken) => ({ courseId, collectionId }) =>
      duplicateCourse(hippoUrl, userToken, courseId, collectionId),
    {
      ...options,
      onSuccess: async (...args) => {
        options?.onSuccess?.(...args);
        await client.invalidateQueries({
          queryKey: [CourseCollectionQueryKeys.courseCollectionList]
        });
      }
    }
  );
}

export function useEmailDesktopLink(courseId: string): UseHippoMutationResult<void> {
  return useHippoMutation((hippoUrl, userToken) => () =>
    emailDesktopLink(hippoUrl, userToken, courseId)
  );
}
