import update from 'immutability-helper';
import undoable, { includeAction } from 'redux-undo';

import { genId } from '@edapp/utils';

import {
  addDefaultSlideChallengeConfiguration,
  getTemplateIndexFromSlide,
  updateSlide,
  updateSlidesDisplayIndexes
} from '../../utils/helpers';
import {
  addCommandForObjectPath,
  duplicateSlideSection,
  removeCommandForObjectPathIndex,
  swapCommandForObjectPathIndex,
  updateCommandForObjectPathValue
} from '../../utils/update';
import { RingoActionTypes } from '../constants';
import type { RingoActionsUnionType, SlideType } from '../types';
import { FormActionTypes } from './constants';
import { cleanUpPath, editCurrentSlide } from './reducerHelper';
import { templates } from './templateData';
import type { FormActionsUnionType, FormStoreType } from './types';

export const initialRingoFormState: FormStoreType = {
  currentSlideIndex: 0,
  currentTemplateIndex: 42,
  saved: true,
  saving: false,
  savingError: null,
  slides: []
};

export const reducer = (
  state = initialRingoFormState,
  action: FormActionsUnionType | RingoActionsUnionType
): FormStoreType => {
  switch (action.type) {
    // Initial Slides Data
    case RingoActionTypes.SET_RINGO_LESSON: {
      const { slides } = action.payload.configuration;
      if (slides.length === 0) return state;

      // Index out of bounds
      const newIndex = slides[state.currentSlideIndex]
        ? state.currentSlideIndex // keep current index
        : slides.length - 1; // use last index

      const newSlide = slides[newIndex];

      return {
        ...state,
        currentSlideIndex: newIndex,
        currentTemplateIndex: getTemplateIndexFromSlide(newSlide),
        slides: [...slides]
      };
    }

    // FORM *****************************************************************************
    case FormActionTypes.SLIDE_REMOVE_ARRAY_ELEMENT: {
      const path = cleanUpPath(action.payload.path || '');
      const { currentSlideIndex, slides } = state;
      const currentSlide = slides[currentSlideIndex];

      const newSlideDataCommand = removeCommandForObjectPathIndex(
        currentSlide.data,
        path,
        action.payload.index
      );

      return editCurrentSlide(state, slides, currentSlide, currentSlideIndex, newSlideDataCommand);
    }

    case FormActionTypes.SLIDE_ADD_ARRAY_ELEMENT: {
      const path = cleanUpPath(action.payload.path || '');
      const { currentSlideIndex, slides } = state;
      const currentSlide = slides[currentSlideIndex];

      const currentTemplate = templates[state.currentTemplateIndex];

      const newSlideDataCommand = addCommandForObjectPath(currentSlide.data, path, currentTemplate);

      return editCurrentSlide(state, slides, currentSlide, currentSlideIndex, newSlideDataCommand);
    }

    case FormActionTypes.SLIDE_SWAP_ARRAY_ELEMENT: {
      const path = cleanUpPath(action.payload.path || '');
      const { currentSlideIndex, slides } = state;
      const currentSlide = slides[currentSlideIndex];

      const newSlideDataCommand = swapCommandForObjectPathIndex(
        currentSlide.data,
        path,
        action.payload.index,
        action.payload.targetIndex
      );

      return editCurrentSlide(state, slides, currentSlide, currentSlideIndex, newSlideDataCommand);
    }

    case FormActionTypes.SLIDE_DUPLICATE_ARRAY_ELEMENT: {
      const path = cleanUpPath(action.payload.path || '');
      const { currentSlideIndex, slides } = state;
      const currentSlide = slides[currentSlideIndex];

      const newSlideDataCommand = duplicateSlideSection(
        currentSlide.data,
        path,
        action.payload.index
      );

      return editCurrentSlide(state, slides, currentSlide, currentSlideIndex, newSlideDataCommand);
    }

    case FormActionTypes.SLIDE_UPDATE_ELEMENT: {
      const { currentSlideIndex, slides } = state;
      const currentSlide = slides[currentSlideIndex];

      const newCommandData = action.payload.reduce(
        (acc, item) => updateCommandForObjectPathValue(acc, cleanUpPath(item.path), item.value),
        currentSlide.data
      );

      return editCurrentSlide(state, slides, currentSlide, currentSlideIndex, newCommandData);
    }

    // NAVIGATION *****************************************************************************

    // Navigation initiated by Thomas
    // Navigation initiated by Ringo Navigation component
    case FormActionTypes.GO_TO_SLIDE: {
      const { payload } = action;
      if (state.currentSlideIndex === payload.index) {
        return state;
      }
      const newSlide = state.slides[payload.index];

      return {
        ...state,
        currentSlideIndex: payload.index,
        currentTemplateIndex: getTemplateIndexFromSlide(newSlide)
      };
    }

    case FormActionTypes.GO_NEXT_SLIDE: {
      const maxSlideIndex = state.slides.length - 1;
      const nextSlideIndex = state.currentSlideIndex + 1;
      if (nextSlideIndex > maxSlideIndex) {
        return state;
      }
      const newSlide = state.slides[nextSlideIndex];
      return {
        ...state,
        currentSlideIndex: nextSlideIndex,
        currentTemplateIndex: getTemplateIndexFromSlide(newSlide)
      };
    }

    case FormActionTypes.GO_PREVIOUS_SLIDE: {
      const previousSlideIndex = state.currentSlideIndex - 1;
      if (previousSlideIndex < 0) {
        return state;
      }
      const newSlide = state.slides[previousSlideIndex];
      return {
        ...state,
        currentSlideIndex: previousSlideIndex,
        currentTemplateIndex: getTemplateIndexFromSlide(newSlide)
      };
    }

    case FormActionTypes.REORDER_SLIDE: {
      const { payload } = action;
      const { currentSlideIndex, slides } = state;
      const dragSlide = slides[payload.index];

      let newSlideIndex = currentSlideIndex;
      const index = payload.index;
      const toIndex = payload.toIndex;
      // Moving current slide update to the new position
      if (currentSlideIndex === payload.index) {
        newSlideIndex = toIndex;
        // Moving another slide from under to above currentslide
      } else if (currentSlideIndex < index && currentSlideIndex >= toIndex) {
        newSlideIndex = currentSlideIndex + 1;
        // Moving another slide from above to under currentslide
      } else if (currentSlideIndex > index && currentSlideIndex <= toIndex) {
        newSlideIndex = currentSlideIndex - 1;
      }
      // Moving another slide from above to above currentslide or under to under
      // doesnt affect the currentSlideIndex
      let newSlides = update(slides, {
        $splice: [
          [payload.index, 1],
          [payload.toIndex, 0, dragSlide]
        ]
      });

      // Update display Index
      newSlides = updateSlidesDisplayIndexes(newSlides);

      return {
        ...state,
        currentSlideIndex: newSlideIndex,
        saved: false,
        slides: newSlides
      };
    }

    case FormActionTypes.DELETE_SLIDE: {
      const { payload } = action;
      const { currentSlideIndex, slides } = state;

      let newSlides = update(slides, { $splice: [[payload.index, 1]] });
      // Update display Index
      newSlides = updateSlidesDisplayIndexes(newSlides);

      const newCurrentSlideIndex =
        currentSlideIndex > payload.index ? currentSlideIndex - 1 : currentSlideIndex;
      // const newCurrentSlideIndex = Math.min(deletedIndex, newSlides.length - 1);

      return {
        ...state,
        saved: false,
        slides: newSlides,
        currentSlideIndex: newCurrentSlideIndex,
        currentTemplateIndex: getTemplateIndexFromSlide(newSlides[newCurrentSlideIndex])
      };
    }

    case FormActionTypes.ADD_SLIDE: {
      const { payload } = action;
      const { slides } = state;

      let insertIndex = payload.index ?? -1;
      if (insertIndex < 0 || insertIndex > slides.length - 1) {
        // insert to the end  by default
        insertIndex = slides.length - 1;
      }
      let newSlide = {
        ...payload.slide,
        id: genId()
      };

      // Replace GENUUID and add metadata
      newSlide = updateSlide(newSlide, true);

      // Add default challenge configuration if enabled for template
      const newSlideTemplateIndex = getTemplateIndexFromSlide(newSlide);
      const newSlideTemplate = templates[newSlideTemplateIndex];

      if (newSlideTemplate.features && newSlideTemplate.features.stars) {
        newSlide = addDefaultSlideChallengeConfiguration(newSlide);
      }

      let newSlides = update(slides, { $splice: [[insertIndex, 0, newSlide]] });
      // Update display Index
      newSlides = updateSlidesDisplayIndexes(newSlides);

      return {
        ...state,
        currentSlideIndex: insertIndex,
        currentTemplateIndex: newSlideTemplateIndex,
        slides: newSlides,
        saved: false
      };
    }

    case FormActionTypes.EDIT_SLIDE: {
      const { payload } = action;
      const { slides } = state;

      const newSlide = updateSlide(payload.slide, true);
      const newSlides = update(slides, {
        $splice: [[payload.index, 1, newSlide]]
      });

      return {
        ...state,
        currentSlideIndex: payload.index || 0,
        currentTemplateIndex: getTemplateIndexFromSlide(newSlide),
        slides: newSlides,
        saved: false
      };
    }

    // SAVE ***************************************************************************************
    case FormActionTypes.SET_IS_SAVING_FORM: {
      return {
        ...state,
        saved: false,
        saving: true,
        savingError: null
      };
    }

    case FormActionTypes.SAVE_FORM_FULFILLED: {
      return {
        ...state,
        saved: true,
        saving: false,
        savingError: null
      };
    }

    case FormActionTypes.SAVE_FORM_FAILED: {
      const { payload } = action;
      return {
        ...state,
        saved: false,
        saving: false,
        savingError: payload
      };
    }

    case FormActionTypes.RESET_ERROR: {
      return {
        ...state,
        saved: true,
        saving: false,
        savingError: null
      };
    }

    // SLIDE INSERTER *****************************************************************************
    case FormActionTypes.INSERT_SLIDES: {
      const { payload } = action;
      const { currentSlideIndex, slides } = state;

      const insertIndex =
        currentSlideIndex < slides.length - 1 ? currentSlideIndex + 1 : slides.length - 1;
      // Prevent inserting exit slides `permanent: true`
      const insertedSlides = payload.filter(s => !s.permanent);

      // Add id to new slide
      const updatedInsertedSlides = insertedSlides.map((slide: SlideType) => ({
        ...updateSlide(slide, true),
        id: genId()
      }));

      let newSlides = update(slides, {
        $splice: [[insertIndex, 0, ...updatedInsertedSlides]]
      });
      // Update display Index
      newSlides = updateSlidesDisplayIndexes(newSlides);

      return {
        ...state,
        currentSlideIndex: insertIndex,
        currentTemplateIndex: getTemplateIndexFromSlide(payload[0]),
        slides: newSlides,
        saved: false
      };
    }

    default:
      return state;
  }
};

const undoableForm = undoable(reducer, {
  limit: 100,
  filter: includeAction([
    FormActionTypes.ADD_SLIDE,
    FormActionTypes.EDIT_SLIDE,
    FormActionTypes.DELETE_SLIDE,
    FormActionTypes.REORDER_SLIDE,
    FormActionTypes.SLIDE_REMOVE_ARRAY_ELEMENT,
    FormActionTypes.SLIDE_ADD_ARRAY_ELEMENT,
    FormActionTypes.SLIDE_UPDATE_ELEMENT,
    FormActionTypes.INSERT_SLIDES
  ]),
  syncFilter: true
});

export default undoableForm;
