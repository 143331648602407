import * as React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import { ErrorBoundary } from '@edapp/monitoring';
import { ErrorFallbackUI } from '@edapp/courseware-ui';
import { UserGroupsRestriction } from './UserGroupsRestriction/UserGroupsRestriction';
import UserGroupItem from './UserGroupItem/UserGroupItem';
import { RouterPageView } from '@edapp/analytics-tracking';

export const userGroupRoutes = {
  userGroups: '/user-groups',
  userGroupItem: '/v2/user-group/:groupId',
  createNewUserGroup: '/v2/user-group/new'
};

type Props = any;

const UserGroups: React.FC<Props> = props => {
  return (
    <ErrorBoundary fallback={<ErrorFallbackUI />}>
      <BrowserRouter>
        <RouterPageView routesMap={userGroupRoutes}>
          <Switch>
            <Route exact={true} path={userGroupRoutes.userGroups}>
              <UserGroupsRestriction {...props} />
            </Route>
            <Redirect exact={true} from="/user-group/:groupId" to={userGroupRoutes.userGroupItem} />
            <Route exact={true} path={userGroupRoutes.userGroupItem} component={UserGroupItem} />
          </Switch>
        </RouterPageView>
      </BrowserRouter>
    </ErrorBoundary>
  );
};

export default UserGroups;
