import { RequestUtils } from '@edapp/request';
import type { RegistrationSettings } from './types';

export async function getUserGroupRegistrationSettings(
  hippoUrl: string,
  userToken: string,
  userGroupId: string
): Promise<RegistrationSettings | undefined> {
  if (userGroupId === 'new') {
    return;
  }
  return await RequestUtils.httpFetch<RegistrationSettings, undefined>(
    'GET',
    `${hippoUrl}/api/usergroups/${userGroupId}/registration-settings`,
    userToken
  );
}

export async function regenerateUserGroupInviteCode(
  hippoUrl: string,
  userToken: string,
  userGroupId: string
) {
  return await RequestUtils.httpFetch<RegistrationSettings, undefined>(
    'POST',
    `${hippoUrl}/api/usergroups/${userGroupId}/registration-settings/regenerate-invite-code`,
    userToken
  );
}

export async function updateUserGroupRegistrationSettings(
  hippoUrl: string,
  userToken: string,
  userGroupId: string,
  payload: RegistrationSettings
) {
  return await RequestUtils.httpFetch<RegistrationSettings, RegistrationSettings>(
    'PUT',
    `${hippoUrl}/api/usergroups/${userGroupId}/registration-settings`,
    userToken,
    payload
  );
}
