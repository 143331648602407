import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import { Box, Divider } from '@edapp/ed-components';
import Breadcrumb from '@rio/components/common/Breadcrumb/Breadcrumb';
import type { LmsStoreState } from '@rio/store/types';

import ChangeLog from './ChangeLog';

type OwnProps = { groupId: string };
type Props = OwnProps & ReturnType<typeof mapStateToProps>;

const UserGroupItemBreadcrumb: React.FunctionComponent<Props> = props => {
  const title = props.groupId === 'new' ? 'User Group Title' : props.userGroupTitle;
  const { t } = useTranslation();

  return (
    <>
      <Box flex={true} justifyContent="space-between" alignItems="center" py="sm">
        <Breadcrumb
          crumbs={[
            {
              type: 'route-crumb',
              title: t('common.user-groups'),
              url: '/user-groups',
              useWindowLocationHref: true
            },
            { type: 'simple-crumb', title }
          ]}
        />

        <ChangeLog groupId={props.groupId} />
      </Box>
      <Divider />
    </>
  );
};

const mapStateToProps = (state: LmsStoreState) => ({
  userGroupTitle: state.userGroups.userGroupItem.usergroup.name
});

export default connect(mapStateToProps)(UserGroupItemBreadcrumb);
