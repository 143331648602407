import { useHippoPagination } from '@edapp/request';
import { getUserGroupsWithFilters } from './api';
import { QueryKeys } from './constants';
import type {
  GetUserGroupsWithFiltersOptions,
  GetUserGroupsWithFiltersRequest,
  GetUserGroupsWithFiltersResponse,
  GetUserGroupsWithFiltersResult
} from './types';

export const useGetUserGroupsWithFilter = (
  request: GetUserGroupsWithFiltersRequest,
  options?: GetUserGroupsWithFiltersOptions
): GetUserGroupsWithFiltersResult => {
  return useHippoPagination<GetUserGroupsWithFiltersResponse>(
    [QueryKeys.userGroupsWithFilter, request],
    (hippoUrl, userToken) => ({ pageParam }) =>
      getUserGroupsWithFilters(hippoUrl, userToken, { ...request, page: pageParam || 1 }),
    options
  );
};
