import { findSubValueWithModelPath } from '../../utils/model';
import type { ModelType, SlideType, StoreState, TemplateType, ValueType } from '../types';
import { templates } from './templateData';

export const getIsUndoAvailable = (state: StoreState) => state.ringo.form.past.length > 0;
export const getIsRedoAvailable = (state: StoreState) => state.ringo.form.future.length > 0;

export const getSlides = (state: StoreState) => state.ringo.form.present.slides;

// Get Current slide in QP or outside
export const getCurrentSlide = (state: StoreState): SlideType | undefined =>
  getSlides(state)?.[getSlideIndex(state)];

// Inputs or elements
export const getInputValue = (state: StoreState, inputPath?: string): ValueType | ModelType[] => {
  const slideData = getCurrentSlide(state);
  return findSubValueWithModelPath(slideData?.data, inputPath ?? '');
};
export const getIsDisabled = (state: StoreState, inputPath?: string | undefined): boolean => {
  const slideData = getCurrentSlide(state);

  if (!slideData || !inputPath || inputPath.includes('isDisabled')) return false;
  // e.g.: the input path is "game.timer.limit" and the disable controller is "game.timer.isDisabled"
  const path = inputPath?.split('.').slice(0, -1).concat(['isDisabled']).join('.') || '';
  return !!findSubValueWithModelPath(slideData.data, path);
};
export const getMultiInputContentType = (state: StoreState, inputPath: string) => {
  const modelPathContentType = findSubValueWithModelPath(
    getCurrentSlide(state)?.data,
    inputPath + 'Type'
  );
  // if it cannot find "contentType" -> default based on the slide type
  if (!modelPathContentType) {
    const { type } = getCurrentTemplate(state);
    switch (type) {
      case 'media-collection':
        return 'video';
      case 'image-slider':
        return 'image';
      default:
        return 'text';
    }
  }
  return modelPathContentType as string;
};
export const getImageUrl = (state: StoreState) => {
  const slide = getCurrentSlide(state);
  return slide?.data?.url;
};
export const getNbElementsRepeater = (state: StoreState, inputPath: string) =>
  getInputValue(state, inputPath) ? (getInputValue(state, inputPath) as ModelType[]).length : 0;

// Scoring & Stars
export const getWeighting = (state: StoreState) => {
  const slide = getCurrentSlide(state);
  return slide?.data?.challenge_configuration?.weighting || 0;
};

export const getNumberOfStars = (state: StoreState) => {
  const slide = getCurrentSlide(state);
  // 0 stars or '' should be allowed
  return slide?.data?.challenge_configuration?.nb_stars || null;
};

// Social Learning
export const getLessonSocialEnabled = (state: StoreState) => {
  const slide = getCurrentSlide(state);
  return slide?.data?.socialLearning?.enabled || false;
};
export const getMaxResponseLength = (state: StoreState) => {
  const slide = getCurrentSlide(state);
  return slide?.data?.socialLearning?.maxResponseLength || 0;
};

// Narration
export const getAudioFile = (state: StoreState) => {
  const slide = getCurrentSlide(state);
  return slide?.data?.narration?.audio_file || '';
};
export const getProgressOnCompletion = (state: StoreState) => {
  const slide = getCurrentSlide(state);
  return slide?.data?.narration?.progress_on_completion || false;
};
export const getCanNotBeSkipped = (state: StoreState) => {
  const slide = getCurrentSlide(state);
  return slide?.data?.narration?.can_not_be_skipped || false;
};

// Saving
export const getSaving = (state: StoreState) => state.ringo.form.present.saving;
export const getSaved = (state: StoreState) => state.ringo.form.present.saved;
export const getSavingError = (state: StoreState) => state.ringo.form.present.savingError;

// Navigation
export const getSlideIndex = (state: StoreState) => state.ringo.form.present.currentSlideIndex;

// Template Picker
export const getCurrentTemplateIndex = (state: StoreState) =>
  state.ringo.form.present.currentTemplateIndex;
export const getCurrentTemplate = (state: StoreState): TemplateType =>
  templates[getCurrentTemplateIndex(state)];
