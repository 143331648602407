import { RequestUtils } from '@edapp/request';

import type { SearchCourseDetailRequest, SearchCourseDetailResponses } from './types';

function sanitizeRequest(request?: SearchCourseDetailRequest) {
  if (!!request?.courseTitle) {
    request.courseTitle = encodeURIComponent(request.courseTitle);
  }

  return request;
}

export async function getUserGroupCourseDetails(
  hippoUrl: string,
  userToken: string,
  userGroupId: string,
  request?: SearchCourseDetailRequest
): Promise<SearchCourseDetailResponses> {
  if (!userGroupId) {
    return Promise.resolve({ pageParams: [], pages: [], totalCount: 0, items: [] });
  }

  return await RequestUtils.httpFetch<SearchCourseDetailResponses, SearchCourseDetailRequest>(
    'GET',
    `${hippoUrl}/api/usergroups/${userGroupId}/courses`,
    userToken,
    sanitizeRequest(request)
  );
}
