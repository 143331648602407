import type { LmsStoreState } from '@rio/store/types';

import { initialBanner } from './constants';
import type { Banner } from './types';

export const getBanner = (state: LmsStoreState, bannerId: string): Banner => {
  const banner = state.banners.banners.find(banner => banner.id === bannerId);

  return banner ?? initialBanner;
};

export const getBannerType = (state: LmsStoreState, bannerId: string) => {
  return getBanner(state, bannerId).bannerType;
};
export const getBannerDestinationUrl = (state: LmsStoreState, bannerId: string) => {
  return getBanner(state, bannerId).destinationUrl;
};

export const getBannerMediaUrl = (state: LmsStoreState, bannerId: string) => {
  return getBanner(state, bannerId).mediaUrl;
};

export const getBannerCourseId = (state: LmsStoreState, bannerId: string) => {
  return getBanner(state, bannerId).courseId;
};

export const getBannerCourseTitle = (state: LmsStoreState, bannerId: string) => {
  return getBanner(state, bannerId).courseTitle;
};

export const getBannerIsCustomised = (state: LmsStoreState, bannerId: string) => {
  return getBanner(state, bannerId).isCustomised;
};

export const getBannerUniversalAccess = (state: LmsStoreState, bannerId: string) => {
  return getBanner(state, bannerId).universalAccessEnabled;
};

export const getBannerUserGroups = (state: LmsStoreState, bannerId: string) => {
  return getBanner(state, bannerId).userGroups;
};

export const getBannerItemLoading = (state: LmsStoreState) => state.banners.bannerItemLoading;

export const getBannerSaveLoading = (state: LmsStoreState) => state.banners.bannerSaveLoading;

export const getBannerItemError = (state: LmsStoreState) => state.banners.bannerItemError;

export const getBannerItemUnsaved = (state: LmsStoreState) => state.banners.bannerUnsaved;
