import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';

import { Box } from '@edapp/ed-components';
import { RestrictionCard } from '@rio/components/common/restriction-prompts/card';

export const UserGroupsRestriction: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Grid>
      <Row center="xs">
        <Col xs={12} sm={10}>
          <Box flex={true} justifyContent="center" m="lg">
            <RestrictionCard
              imgSrc="image/upload/v1605295254/user-groups/UG_Image.png"
              title={t('user-groups.paywall.title')}
              description={t('user-groups.paywall.description')}
              benefitTitle={t('user-groups.paywall.benefits-title')}
              benefits={[
                t('user-groups.paywall.benefit1'),
                t('user-groups.paywall.benefit2'),
                t('user-groups.paywall.benefit3'),
                t('user-groups.paywall.benefit4')
              ]}
              learnMore="https://support.edapp.com/user-groups"
            />
          </Box>
        </Col>
      </Row>
    </Grid>
  );
};
