import { Avatar, Box, Lottie, Typography } from '@edapp/ed-components';
import GOLD_COIN from '../../assets/gold-coin.json';
import SILVER_COIN from '../../assets/silver-coin.json';
import BRONZE_COIN from '../../assets/bronze-coin.json';
import styled, { css } from 'styled-components';
import * as React from 'react';
import type { RankingParticipant } from '../../types';

type Props = {
  topThreeUsers: RankingParticipant[];
};
export const TopThree: React.FC<Props> = ({ topThreeUsers }) => {
  return (
    <Box flex gap={1} alignItems="flex-end" justifyContent="center" bgColor="white" px={1}>
      {!!topThreeUsers[1] && <TopUser user={topThreeUsers[1]} lottieData={SILVER_COIN} />}
      {!!topThreeUsers[0] && <TopUser user={topThreeUsers[0]} lottieData={GOLD_COIN} isFirst />}
      {!!topThreeUsers[2] && <TopUser user={topThreeUsers[2]} lottieData={BRONZE_COIN} />}
    </Box>
  );
};

type TopUserProps = {
  user: RankingParticipant;
  lottieData: object;
  isFirst?: boolean;
};
const TopUser: React.FC<TopUserProps> = ({ user, lottieData, isFirst = false }) => (
  <UserWrapper flex={1} flexDirection="column" alignItems="stretch" gap={0.5} mb={3} mt={1}>
    <StyledLottie data={lottieData} autoplay={true} loop={true} />
    <StyledAvatar
      src={user?.participantAvatarUrl}
      alt={user?.participantName}
      size={isFirst ? 'xlg' : 'lg'}
    />
    <Box flex flexDirection="column" alignItems="stretch">
      <UserText variant="small">{user.totalScore}</UserText>
      <UserName variant="subtext" color="textMuted" lineClamp={2}>
        {user?.participantName}
      </UserName>
    </Box>
  </UserWrapper>
);

const UserWrapper = styled(Box)(
  ({ theme }) => css`
    min-width: 200px;
    @media screen and (max-width: ${theme.flexboxgrid.breakpoints.md}rem) {
      min-width: 0;
      flex: 1;
    }
  `
);
const StyledAvatar = styled(Avatar)`
  margin: 0 auto;
`;

const StyledLottie = styled(Lottie)(
  ({ theme }) => css`
    width: 64px;
    margin: 0 auto -${theme.space()}px;
    flex-shrink: 0;
    height: 100%;
  `
);

const UserText = styled(Typography)`
  text-align: center;
  width: 100%;
  text-overflow: ellipsis;
  word-wrap: break-word;
`;

const UserName = styled(UserText)(
  ({ theme }) => css`
    @media screen and (max-width: ${theme.flexboxgrid.breakpoints.md}rem) {
      min-height: 32px;
    }
  `
);
