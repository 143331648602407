import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

export type ContainerRenderProps = {
  children: React.ReactNode;
  containerId: string;
  items: string[];
  placeholder?: boolean;
  hover?: boolean;
  dragging?: boolean;
  dragOverlay?: boolean;
  isItemDropped?: boolean;
  handleProps?: React.HTMLAttributes<any>;
  onClick?(): void;
  onRemove?(): void;
};

export type ContainerProps = {
  label?: string;
  style?: React.CSSProperties;
  horizontal?: boolean;
  hover?: boolean;
  scrollable?: boolean;
  shadow?: boolean;
  renderContainer?(args: ContainerRenderProps): React.ReactElement;
} & ContainerRenderProps;

export const DefaultContainer = forwardRef<HTMLDivElement, ContainerProps>(
  (
    {
      children,
      containerId,
      handleProps,
      horizontal,
      hover,
      onClick,
      onRemove,
      label,
      placeholder,
      style,
      scrollable,
      shadow,
      dragOverlay,
      dragging,
      isItemDropped,
      renderContainer,
      items = [],
      ...props
    },
    ref
  ) => (
    <Component
      {...props}
      ref={ref}
      style={style}
      onClick={onClick}
      tabIndex={onClick ? 0 : undefined}
    >
      {renderContainer ? (
        renderContainer({
          children,
          containerId,
          onRemove,
          onClick,
          handleProps,
          placeholder,
          hover,
          dragging,
          dragOverlay,
          isItemDropped,
          items
        })
      ) : (
        <>
          {label && <Header {...handleProps}>{label}</Header>}
          {placeholder ? children : <Items>{children}</Items>}
        </>
      )}
    </Component>
  )
);

const Component = styled.div<{
  horizontal?: boolean;
  hover?: boolean;
  placeholder?: boolean;
  scrollable?: boolean;
  shadow?: boolean;
}>(
  ({ placeholder, hover, horizontal, shadow }) => css`
    display: flex;
    width: 100%;
    flex-direction: column;
    grid-auto-rows: max-content;
    overflow: hidden;
    box-sizing: border-box;
    appearance: none;
    outline: none;

    ${placeholder &&
    css`
      justify-content: center;
      align-items: center;
      cursor: pointer;
      color: rgba(0, 0, 0, 0.5);
      background-color: transparent;
      border-style: dashed;
      border-color: rgba(0, 0, 0, 0.08);

      :hover {
        border-color: rgba(0, 0, 0, 0.15);
      }
    `};
    ${horizontal &&
    css`
      width: 100%;
    `};

    ${hover &&
    css`
      opacity: 0.5;
    `}

    ${shadow &&
    css`
      box-shadow: 0 1px 10px 0 rgba(34, 33, 81, 0.1);
    `};
  `
);

const Items = styled.ul<{ scrollable?: boolean }>(
  ({ theme, scrollable }) => css`
    display: grid;
    grid-gap: ${theme.space(2)}px;
    grid-template-columns: repeat(var(--columns, 1), 1fr);
    list-style: none;
    padding-left: 0;
    margin: 0;
    ${scrollable &&
    css`
      overflow-y: auto;
    `};
  `
);

const Header = styled.div(
  ({ theme }) => css`
    display: flex;
    padding: 5px 8px 5px 20px;
    align-items: center;
    justify-content: space-between;
    background-color: ${theme.colors.white};
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    touch-action: manipulation;
    cursor: grab;
  `
);
