import { useCheckPermission } from '@rio/utils/permissions/hooks';
import type { AccessPermissionType } from '@rio/utils/permissions/types';

export const useAppSettingsPermission = (accessPermission?: AccessPermissionType) => {
  return useCheckPermission({
    resource: 'APPLICATION_SETTINGS',
    scope: 'EDIT',
    accessPermission
  });
};
