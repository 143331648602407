import { createSelector } from 'reselect';

import type { LmsStoreState } from '@rio/store/types';

import { isValidCondition } from './utils';

export const getUserGroupNames = (state: LmsStoreState) => state.userGroups.userGroupNames.items;
export const getIsUserGroupNamesLoading = (state: LmsStoreState) =>
  state.userGroups.userGroupNames.loading;
export const getUserGroupNamesError = (state: LmsStoreState) =>
  state.userGroups.userGroupNames.error;

export const getCheckStatusLoading = (state: LmsStoreState) => state.userGroups.checkStatusLoading;
export const getCheckStatusError = (state: LmsStoreState) => state.userGroups.checkStatusError;
export const getAllowedToEditStatus = (state: LmsStoreState) =>
  state.userGroups.allowedToEditStatus;

export const getIsFetchingConditions = (state: LmsStoreState) =>
  state.userGroups.fetchingConditions;
export const getUserGroupConditions = (state: LmsStoreState) =>
  state.userGroups.userGroupItem.usergroup.conditions ?? [];

export const getIsFetchingDraws = (state: LmsStoreState) => state.userGroups.fetchingDraws;
export const getFetchingDrawsError = (state: LmsStoreState) => state.userGroups.fetchingDrawsError;
export const getDraws = (state: LmsStoreState) => state.userGroups.userGroupItem.draws;

export const getCustomFields = (state: LmsStoreState) =>
  state.userGroups.userGroupItem.app.customFields.user;

export const getUserGroupName = (state: LmsStoreState) =>
  state.userGroups.userGroupItem.usergroup.name;
export const getIsDynamicUserGroup = (state: LmsStoreState) =>
  state.userGroups.userGroupItem.usergroup.isDynamicGroup;

export const getIsFetchingUserGroup = (state: LmsStoreState) => state.userGroups.fetchLoading;

export const getIsMutatingUserGroup = (state: LmsStoreState) => state.userGroups.mutateLoading;
export const getMutatingUserGroupError = (state: LmsStoreState) => state.userGroups.mutateError;

export const getUserGroupTitle = (state: LmsStoreState) =>
  state.userGroups.userGroupItem.usergroup.name;
export const getUserGroupIsAccountGroup = (state: LmsStoreState) =>
  state.userGroups.userGroupItem.usergroup.isAccountGroup;
export const getUserGroupId = (state: LmsStoreState) =>
  state.userGroups.userGroupItem.usergroup._id;
export const getUserGroupCatalogs = (state: LmsStoreState) =>
  state.userGroups.userGroupItem.usergroup.catalogsCanAccess;
export const getUserGroupContains = (state: LmsStoreState) =>
  state.userGroups.userGroupItem.usergroup.userGroupContains;
export const getSelectedCourseIds = (state: LmsStoreState) =>
  state.userGroups.userGroupItem.usergroup.courses;
export const getUserGroupLottery = (state: LmsStoreState) =>
  state.userGroups.userGroupItem.usergroup.lottery;
export const getUserGroupDraws = (state: LmsStoreState) =>
  state.userGroups.userGroupItem.usergroup.draws;

export const getIsLoadingUserGroup = createSelector(
  getIsFetchingUserGroup,
  getIsMutatingUserGroup,
  getIsFetchingConditions,
  getIsFetchingDraws,
  getCheckStatusLoading,
  (
    isFetchingUserGroup,
    isMutatingUserGroup,
    isFetchingConditions,
    isFetchingDraws,
    isCheckingStatus
  ) =>
    isFetchingUserGroup ||
    isMutatingUserGroup ||
    isFetchingConditions ||
    isFetchingDraws ||
    isCheckingStatus
);

export const getConditionsValidity = createSelector(
  getIsDynamicUserGroup,
  getUserGroupConditions,
  (isDynamicUserGroup, conditions) => {
    if (!isDynamicUserGroup) {
      return true;
    }

    if (conditions.length === 0) {
      return false;
    }

    return conditions.every(isValidCondition);
  }
);
