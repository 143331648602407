import { RequestUtils } from '@edapp/request';
import type { FeaturesRequest, FeaturesResponse } from './types';

export async function getFeatures(hippoUrl: string, userToken: string): Promise<FeaturesResponse> {
  return RequestUtils.httpFetch<FeaturesResponse>(
    'GET',
    `${hippoUrl}/api/accounts/appFeatures`,
    userToken
  );
}

export async function updateFeatures(
  hippoUrl: string,
  userToken: string,
  request: FeaturesRequest
): Promise<undefined> {
  return RequestUtils.httpFetch<undefined, FeaturesRequest>(
    'PUT',
    `${hippoUrl}/api/accounts/appFeatures`,
    userToken,
    request
  );
}
