import styled from 'styled-components';
import { Input } from './Input';
import type { InputProps } from './types';

export const SubtleInput = styled(Input).attrs<InputProps>(({ disabled, isDisabled }) => ({
  disabled: disabled || isDisabled
}))`
  && {
    background: transparent;
    border: none;

    :enabled {
      &:hover,
      &:focus {
        background: ${({ theme }) => theme.colors.lightGrey};
        border: none;
      }
    }
  }
`;
