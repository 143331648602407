import type { BannersState } from './types';
import * as actions from './actions';
import { initialBanner } from './constants';
import { replaceBanner } from './utils';

const NEW_BANNER_ID = 'new';

export const initialBannerState: BannersState = {
  banners: [],
  bannerUnsaved: false,
  bannerSaveLoading: false,
  bannerSaveError: '',
  bannerItemLoading: false,
  bannerItemError: '',
  fetchBannersLoading: false,
  fetchBannersError: '',
  removeBannerError: '',
  updateRankError: '',
  totalCount: 0
};

export const bannersReducer: Reducer<BannersState> = (state = initialBannerState, action) => {
  switch (action.type) {
    case actions.CREATE_BANNER: {
      return { ...state, bannerSaveLoading: true, bannerItemError: '' };
    }

    case actions.CREATE_BANNER_SUCCESS: {
      const id = action.payload as actions.CreateBannerSuccess;

      const items = [...state.banners];
      const newBannerIndex = items.findIndex(banner => banner.id === NEW_BANNER_ID);
      const newBanner = items[newBannerIndex] || initialBanner;
      const newBannerWithId = { ...newBanner, id };

      const newBanners = replaceBanner(newBannerWithId, items, NEW_BANNER_ID);

      return {
        ...state,
        bannerSaveLoading: false,
        bannerItemError: '',
        bannerUnsaved: false,
        banners: newBanners
      };
    }

    case actions.CREATE_BANNER_FAILURE: {
      const { message } = action.payload as actions.CreateBannerFailure;
      return { ...state, bannerSaveLoading: false, bannerItemError: message };
    }

    case actions.SET_BANNER: {
      const { id, newBannerPartial, isUnsavedChanges } = action.payload as actions.SetBanner;

      let previousBanner;
      const stateBanner = state.banners.find(b => b.id === id);

      if (id !== 'new') {
        previousBanner = stateBanner ? stateBanner : initialBanner;
      } else {
        previousBanner = stateBanner ? stateBanner : { ...initialBanner, id: 'new' };
      }

      const newBanner = { ...previousBanner, ...newBannerPartial };

      const newBanners = replaceBanner(newBanner, state.banners);

      return {
        ...state,
        bannerUnsaved: isUnsavedChanges,
        banners: newBanners
      };
    }

    case actions.SAVE_BANNER_IS_PUBLISHED: {
      return { ...state, bannerSaveLoading: true, bannerSaveError: '' };
    }

    case actions.SAVE_BANNER_IS_PUBLISHED_SUCCESS: {
      return {
        ...state,
        bannerSaveLoading: false,
        bannerUnsaved: false,
        bannerSaveError: ''
      };
    }

    case actions.SAVE_BANNER_IS_PUBLISHED_FAILURE: {
      const { message } = action.payload as actions.SaveBannerIsPublishedFailure;
      return { ...state, bannerSaveLoading: false, bannerSaveError: message };
    }

    case actions.SAVE_BANNER: {
      return { ...state, bannerSaveLoading: true, bannerSaveError: '' };
    }

    case actions.SAVE_BANNER_SUCCESS: {
      return {
        ...state,
        bannerSaveLoading: false,
        bannerSaveError: '',
        bannerUnsaved: false
      };
    }

    case actions.SAVE_BANNER_FAILURE: {
      const { message } = action.payload as actions.SaveBannerFailure;
      return { ...state, bannerSaveLoading: false, bannerSaveError: message };
    }

    case actions.FETCH_BANNER_ITEM: {
      return { ...state, bannerItemLoading: true, bannerSaveError: '' };
    }

    case actions.FETCH_BANNER_ITEM_SUCCESS: {
      const banner = action.payload as actions.FetchBannerItemSuccess;
      const newBanners = replaceBanner(banner, state.banners);

      return {
        ...state,
        bannerItemError: '',
        bannerItemLoading: false,
        banners: newBanners
      };
    }
    case actions.FETCH_BANNER_ITEM_FAILURE: {
      const { message } = action.payload as actions.FetchBannerItemFailure;
      return { ...state, bannerItemLoading: false, bannerItemError: message };
    }

    case actions.FETCH_BANNERS: {
      return { ...state, fetchBannersLoading: true, fetchBannersError: '' };
    }

    case actions.FETCH_BANNERS_SUCCESS: {
      const { items, page, totalCount } = action.payload as actions.FetchBannersSuccess;

      const banners = page === 1 ? [...items] : [...state.banners, ...items];

      return {
        ...state,
        fetchBannersLoading: false,
        fetchBannersError: '',
        banners,
        totalCount
      };
    }
    case actions.FETCH_BANNERS_FAILURE: {
      const { message } = action.payload as actions.FetchBannersFailure;

      return { ...state, fetchBannersLoading: false, fetchBannersError: message };
    }

    case actions.REMOVE_BANNER: {
      const { bannerId } = action.payload as actions.RemoveBanner;

      const newBanners = [...state.banners].filter(banner => banner.id !== bannerId);

      return {
        ...state,
        banners: newBanners,
        removeBannerError: ''
      };
    }

    case actions.REMOVE_BANNER_FAILURE: {
      const { message } = action.payload as actions.RemoveBannerFailure;
      return { ...state, removeBannerError: message };
    }

    case actions.UPDATE_RANK: {
      return {
        ...state,
        updateRankError: ''
      };
    }

    case actions.UPDATE_RANK_SUCCESS: {
      return {
        ...state,
        updateRankError: ''
      };
    }

    case actions.UPDATE_RANK_FAILURE: {
      const { message } = action.payload as actions.UpdateRankFailure;
      return {
        ...state,
        updateRankError: message
      };
    }

    case actions.REORDER_BANNERS: {
      const { banners } = action.payload as actions.ReorderBanners;
      return {
        ...state,
        banners: banners
      };
    }

    default:
      return {
        ...state
      };
  }
};
