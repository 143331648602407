/**
 * Clamp a number between two optional min and max values.
 * @param number Number being clamped between an option of two values
 * @param min The minimum value the number can be
 * @param max The maximum value the number can be
 */
export function clamp(number: number, min?: number, max?: number) {
  if (min !== undefined && number <= min) {
    return min;
  } else if (max !== undefined && number >= max) {
    return max;
  } else {
    return number;
  }
}
