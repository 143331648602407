import type * as React from 'react';

import styled from 'styled-components';

import type { LabelProps } from './Label';
import Label from './Label';

const LabelSmallWithBorder: React.FunctionComponent<LabelProps> = styled(Label)<LabelProps>`
  display: inline-block;
  font-size: 0.8em;
  margin-bottom: 0;
  padding: 4px 6px;
  position: relative;
  border-radius: 4px 4px 0 0;
  border: 1px solid #d7dfe7;
  border-bottom: none;
  color: ${({ theme }) => theme.colors.text};

  .with-emily-assets & {
    margin-bottom: 0px;
  }
`;

export default LabelSmallWithBorder;
