import { BrainBoostActionTypes } from './actions';
import type { BrainBoostActionsUnionType, BrainBoostState } from './types';

export const initialBrainBoostState: BrainBoostState = {
  filterState: {},
  spacedRepetitions: {
    courses: {},
    loading: false
  },
  settings: {
    slidesPerSession: 0,
    loading: false
  }
};

export const brainBoostReducer = (
  state: BrainBoostState = initialBrainBoostState,
  action: BrainBoostActionsUnionType
): BrainBoostState => {
  switch (action.type) {
    case BrainBoostActionTypes.SET_BRAIN_BOOST_FILTER: {
      return { ...state, filterState: { ...state.filterState, ...action.payload.filterState } };
    }
    case BrainBoostActionTypes.CLEAR_BRAIN_BOOST_FILTER: {
      return { ...state, filterState: {} };
    }
    case BrainBoostActionTypes.SET_HAS_SEEN_BRAIN_BOOST_ONBOARDING: {
      return { ...state, hasSeenBrainBoostOnboarding: true };
    }
    case BrainBoostActionTypes.GET_COURSE_SPACED_REPETITION: {
      return {
        ...state,
        spacedRepetitions: {
          ...state.spacedRepetitions,
          loading: true
        }
      };
    }
    case BrainBoostActionTypes.GET_COURSE_SPACED_REPETITION_SUCCESS: {
      return {
        ...state,
        spacedRepetitions: {
          ...state.spacedRepetitions,
          loading: false,
          courses: {
            ...state.spacedRepetitions.courses,
            [action.payload.data.courseId]: {
              ...action.payload.data
            }
          }
        }
      };
    }
    case BrainBoostActionTypes.GET_COURSE_SPACED_REPETITION_FAILURE: {
      return {
        ...state,
        spacedRepetitions: {
          ...state.spacedRepetitions,
          loading: false,
          error: action.payload.message
        }
      };
    }
    case BrainBoostActionTypes.GET_BRAIN_BOOST_SETTINGS_SUCCESS: {
      return {
        ...state,
        settings: { slidesPerSession: action.payload.slidesPerSession, loading: false }
      };
    }
    case BrainBoostActionTypes.UPDATE_BRAIN_BOOST_SETTINGS: {
      return {
        ...state,
        settings: { slidesPerSession: action.payload.slidesPerSession, loading: true }
      };
    }

    case BrainBoostActionTypes.UPDATE_BRAIN_BOOST_SETTINGS_SUCCESS: {
      return {
        ...state,
        settings: { ...state.settings, loading: false }
      };
    }

    default: {
      return state;
    }
  }
};
