import * as React from 'react';

import styled, { css } from 'styled-components';

import type { ColorsKeys } from '@edapp/themes';

import { IconButton } from '../button';
import { CrossIcon } from '../icons';

type CloseDialogProps = {
  onClick: () => void;
  testId?: string;
  color?: ColorsKeys;
  className?: string;
};

export const defaultTestId = 'close-dialog-button';

export const CloseDialogButton: React.FC<CloseDialogProps> = ({
  testId = defaultTestId,
  color = 'text',
  className,
  onClick
}) => (
  <StyledIconButton
    size="sm"
    variant="transparent"
    onClick={onClick}
    icon={CrossIcon}
    iconColor={color}
    testId={testId}
    className={className}
  />
);

const StyledIconButton = styled(IconButton)`
  ${({ theme }) => css`
    position: absolute;
    z-index: 2;
    right: ${theme.space(2)}px;
    top: ${theme.space(2)}px;
    padding: ${theme.space(1)}px;

    &:hover {
      border-radius: 50%;
    }
  `}
`;
