import styled from 'styled-components';
import { Button } from './Button';
import type { ButtonProps } from './types';

export const BUTTON_LINK_TEST_ID = 'button-link';

export const ButtonLink = styled(Button).attrs<ButtonProps<'a'>>(
  ({ testId = BUTTON_LINK_TEST_ID, ...props }) => ({
    ...props,
    as: 'a',
    'data-testid': testId
  })
)<ButtonProps<'a'>>`
  text-decoration: none;
  &:focus,
  &:hover {
    text-decoration: none;
  }
`;
