import { fork, put, takeLatest } from 'redux-saga/effects';
import { RequestActions } from '@edapp/request';
import type { FetchSurvey } from './actions';
import SurveyActionTypes, { fetchSurveyUri } from './constants';

function* handleFetchSurvey(action: Action<FetchSurvey>) {
  const { lessonId } = action.payload;
  yield put(
    RequestActions.getAuthed(
      fetchSurveyUri(lessonId),
      SurveyActionTypes.FETCH_SURVEY_FULFILLED,
      SurveyActionTypes.FETCH_SURVEY_FAILED
    )
  );
}

function* watchFetchSurvey() {
  yield takeLatest(SurveyActionTypes.FETCH_SURVEY, handleFetchSurvey);
}

const sagas = [fork(watchFetchSurvey)];

export default sagas;
