import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { Dialog, Box } from '@edapp/ed-components';

export const StyledDialog = styled(Dialog)`
  width: 900px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled(Box)(
  ({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.greyHover};
  `
);

export const Feedback = styled(motion.div)<{ error: boolean }>(
  ({ theme, error }) => css`
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    padding: ${theme.space(0.5)}px ${theme.space(2)}px;
    color: ${theme.colors.white};
    background: ${error ? theme.colors.red : theme.colors.blue};
    border-bottom-left-radius: ${theme.sizes.borderRadius}px;
    border-bottom-right-radius: ${theme.sizes.borderRadius}px;
    pointer-events: none;
  `
);
