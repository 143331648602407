import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import { Box, Checkbox } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';
import { useGetStarbars } from '@rio/api/lottery/starbars';
import type { DropdownOption } from '@rio/components/common/DropdownInput/DropdownInput';
import DropdownInput from '@rio/components/common/DropdownInput/DropdownInput';
import { setUserGroupItem } from '@rio/store/userGroups/actions';
import { getUserGroupLottery } from '@rio/store/userGroups/selectors';
import type { Lottery as LotteryType } from '@rio/store/userGroups/types';

const Block = styled(Box)`
  border-top: 1px solid ${({ theme }) => theme.colors.grey};
`;

export const Lottery: React.FC = () => {
  const { data: starBars = [], isLoading } = useGetStarbars();
  const { t } = useTranslation();

  const lottery = useSelector(getUserGroupLottery);

  const dispatch = useDispatch();

  const handleLotteryChange = (lottery: Partial<LotteryType>) => {
    dispatch(setUserGroupItem({ usergroup: { lottery } }));
  };

  const handleToggleEnabled = (enabled: boolean) => {
    handleLotteryChange({ enabled });
  };

  const handleStarbarChange = (starBar: string) => {
    handleLotteryChange({ starBar });
  };

  const handleToggleEnableSpinToWin = (enableSpinToWin: boolean) => {
    handleLotteryChange({ enableSpinToWin });
  };

  const starbarOptions = starBars.map<DropdownOption>(({ id, name }) => ({
    value: id,
    label: name
  }));

  return (
    <Row>
      <Col xs={12}>
        <Checkbox checked={lottery.enabled} onChange={e => handleToggleEnabled(e.target.checked)}>
          <Text variant="labelLarge" component="h4">
            {t('user-groups.select-prize-draws.override-settings')}
          </Text>
        </Checkbox>
      </Col>
      {!!lottery.enabled && (
        <Col xs={12}>
          <Block pt={1} ml={4}>
            <label>Star Bar Game</label>
            <p className="help-block">{t('user-groups.select-prize-draws.restrict-game-type')}</p>
            <DropdownInput
              data-testid="dropdown-starbar-game"
              value={lottery.starBar}
              onChange={handleStarbarChange}
              options={starbarOptions}
              disabled={isLoading}
              isLoading={isLoading}
            />
            <Box mt={2} ml={1}>
              <Checkbox
                checked={lottery.enableSpinToWin}
                onChange={e => handleToggleEnableSpinToWin(e.target.checked)}
                title={t('user-groups.select-prize-draws.star-bar-title')}
                description={t('user-groups.select-prize-draws.allow-star-bar-access-info')}
              />
            </Box>
          </Block>
        </Col>
      )}
    </Row>
  );
};
