import * as React from 'react';

import { DEFAULT_LANGUAGE_CODE } from './constants';

export type APILanguageContextState = {
  language: string;
  setLanguage: (language: string) => void;
};

export const APILanguageContext = React.createContext<APILanguageContextState | undefined>(
  undefined
);

export const APILanguageProvider: React.FC<React.PropsWithChildren<{
  initialLanguage?: string;
}>> = ({ initialLanguage, children }) => {
  const [language, setLanguage] = React.useState<string>(initialLanguage ?? DEFAULT_LANGUAGE_CODE);

  React.useEffect(() => {
    if (initialLanguage) {
      setLanguage(initialLanguage);
    }
  }, [initialLanguage]);

  return (
    <APILanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </APILanguageContext.Provider>
  );
};

export const useAPICourseLanguage = () => {
  const context = React.useContext(APILanguageContext);
  if (!context) {
    if (process.env.NODE_ENV === 'development') {
      console.warn('useAPICourseLanguage is undefined, default language used instead');
    }
    return { language: DEFAULT_LANGUAGE_CODE, setLanguage: () => ({}) };
  }
  return context;
};
