import type { Reducer } from 'redux';

import { LoadingActionTypes, LoadingStatus } from './constants';
import type { Action, ClearLoadingStateActionPayload, LoadingStateType } from './types';

/**
 * Listen to actions containing the following prefix
 */
const ACTION_VERBS_REGEX = /^(FETCH|SAVE|ADD|REMOVE|INITIATE|UPDATE|DELETE|CREATE)/;

/**
 * Action completion suffixes
 */
const ACTION_COMPLETION_REGEX = /_(SUCCESS|FAILURE)$/;

export const initialState: LoadingStateType = {};

/**
 * Split the action type string into the key and its completion e.g.
 * 'FETCH_COURSE_SUCCESS' => ['FETCH_COURSE', 'SUCCESS']
 * @param actionType The action type string
 */
const parseActionCompletion = (actionType: string): [string, LoadingStatus] => {
  return actionType.split(ACTION_COMPLETION_REGEX) as [string, LoadingStatus];
};

export const reducer: Reducer<LoadingStateType> = (
  state = initialState,
  action: Action<unknown>
): LoadingStateType => {
  // Handle lesson save actions with id in their names i.e. SAVE_LESSON_SUCCESS:someid
  const [type] = action.type.split(':');
  switch (type) {
    case LoadingActionTypes.CLEAR_LOADING_STATUS: {
      const newState = { ...state };
      const data = action.data as ClearLoadingStateActionPayload;
      const [key] = parseActionCompletion(data.actionType);
      delete newState[key];
      return newState;
    }
    case LoadingActionTypes.CLEAR_ALL_PENDING: {
      const newState = { ...state };
      Object.keys(state).forEach(key => {
        if (newState[key] === LoadingStatus.PENDING) {
          delete newState[key];
        }
      });
      return newState;
    }
    case LoadingActionTypes.PAUSE_ALL_PENDING: {
      const newState = { ...state };
      Object.keys(state).forEach(key => {
        if (newState[key] === LoadingStatus.PENDING) {
          newState[key] = LoadingStatus.PAUSED;
        }
      });
      return newState;
    }
    case LoadingActionTypes.RESUME_ALL_PENDING: {
      const newState = { ...state };
      Object.keys(state).forEach(key => {
        if (newState[key] === LoadingStatus.PAUSED) {
          newState[key] = LoadingStatus.PENDING;
        }
      });
      return newState;
    }
    default: {
      // Does this action type match
      if (!ACTION_VERBS_REGEX.test(type)) {
        return state;
      }
      // Split the action string into the key and its completion e.g.
      const [key, completion] = parseActionCompletion(type);

      return {
        ...state,
        [key]: completion || LoadingStatus.PENDING
      };
    }
  }
};
