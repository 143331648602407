import type { ActionWithPayload, ActionsUnion } from '@edapp/utils';
import { createAction } from '@edapp/utils';
import type { CoursePublicDetailExcludedLesson } from './types';
import type { EdErrorResponseType } from '../../../../types';

enum ActionType {
  FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSONS = 'FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSONS',
  FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSONS_SUCCESS = 'FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSONS_SUCCESS',
  FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSONS_FAILURE = 'FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSONS_FAILURE',

  CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON = 'CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON',
  CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_SUCCESS = 'CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_SUCCESS',
  CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_FAILURE = 'CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_FAILURE',

  DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON = 'DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON',
  DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_SUCCESS = 'DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_SUCCESS',
  DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_FAILURE = 'DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_FAILURE'
}
export default ActionType;

type FetchCoursePublicDetailExcludedLessons = { courseId: string; publicDetailId: string };
export type FetchCoursePublicDetailExcludedLessonsAction = ActionWithPayload<
  ActionType.FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSONS,
  FetchCoursePublicDetailExcludedLessons
>;

type FetchCoursePublicDetailExcludedLessonsSuccess = {
  publicDetailId: string;
  excludedLessons: CoursePublicDetailExcludedLesson[];
};
export type FetchCoursePublicDetailExcludedLessonsSuccessAction = ActionWithPayload<
  ActionType.FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSONS_SUCCESS,
  FetchCoursePublicDetailExcludedLessonsSuccess
>;

type FetchCoursePublicDetailExcludedLessonsFailure = EdErrorResponseType;
export type FetchCoursePublicDetailExcludedLessonsFailureAction = ActionWithPayload<
  ActionType.FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSONS_FAILURE,
  FetchCoursePublicDetailExcludedLessonsFailure
>;

type CreateCoursePublicDetailExcludedLesson = {
  courseId: string;
  publicDetailId: string;
  lessonId: string;
};
export type CreateCoursePublicDetailExcludedLessonAction = ActionWithPayload<
  ActionType.CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON,
  CreateCoursePublicDetailExcludedLesson
>;

type CreateCoursePublicDetailExcludedLessonSuccess = CoursePublicDetailExcludedLesson;
export type CreateCoursePublicDetailExcludedLessonSuccessAction = ActionWithPayload<
  ActionType.CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_SUCCESS,
  CreateCoursePublicDetailExcludedLessonSuccess
>;

type CreateCoursePublicDetailExcludedLessonFailure = EdErrorResponseType;
export type CreateCoursePublicDetailExcludedLessonFailureAction = ActionWithPayload<
  ActionType.CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_FAILURE,
  CreateCoursePublicDetailExcludedLessonFailure
>;

type DeleteCoursePublicDetailExcludedLesson = {
  courseId: string;
  publicDetailId: string;
  id: string;
};
export type DeleteCoursePublicDetailExcludedLessonAction = ActionWithPayload<
  ActionType.DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON,
  DeleteCoursePublicDetailExcludedLesson
>;

type DeleteCoursePublicDetailExcludedLessonSuccess = { publicDetailId: string; id: string };
export type DeleteCoursePublicDetailExcludedLessonSuccessAction = ActionWithPayload<
  ActionType.DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_SUCCESS,
  DeleteCoursePublicDetailExcludedLessonSuccess
>;

type DeleteCoursePublicDetailExcludedLessonFailure = EdErrorResponseType;
export type DeleteCoursePublicDetailExcludedLessonFailureAction = ActionWithPayload<
  ActionType.DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_FAILURE,
  DeleteCoursePublicDetailExcludedLessonFailure
>;

export const actions = {
  fetchCoursePublicDetailExcludedLessons: (
    payload: FetchCoursePublicDetailExcludedLessons
  ): FetchCoursePublicDetailExcludedLessonsAction =>
    createAction(ActionType.FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSONS, payload),
  fetchCoursePublicDetailExcludedLessonsSuccess: (
    payload: FetchCoursePublicDetailExcludedLessonsSuccess
  ): FetchCoursePublicDetailExcludedLessonsSuccessAction =>
    createAction(ActionType.FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSONS_SUCCESS, payload),
  fetchCoursePublicDetailExcludedLessonsFailure: (
    payload: FetchCoursePublicDetailExcludedLessonsFailure
  ): FetchCoursePublicDetailExcludedLessonsFailureAction =>
    createAction(ActionType.FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSONS_FAILURE, payload),

  createCoursePublicDetailExcludedLesson: (
    payload: CreateCoursePublicDetailExcludedLesson
  ): CreateCoursePublicDetailExcludedLessonAction =>
    createAction(ActionType.CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON, payload),
  createCoursePublicDetailExcludedLessonSuccess: (
    payload: CreateCoursePublicDetailExcludedLessonSuccess
  ): CreateCoursePublicDetailExcludedLessonSuccessAction =>
    createAction(ActionType.CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_SUCCESS, payload),
  createCoursePublicDetailExcludedLessonFailure: (
    payload: CreateCoursePublicDetailExcludedLessonFailure
  ): CreateCoursePublicDetailExcludedLessonFailureAction =>
    createAction(ActionType.CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_FAILURE, payload),

  deleteCoursePublicDetailExcludedLesson: (
    payload: DeleteCoursePublicDetailExcludedLesson
  ): DeleteCoursePublicDetailExcludedLessonAction =>
    createAction(ActionType.DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON, payload),
  deleteCoursePublicDetailExcludedLessonSuccess: (
    payload: DeleteCoursePublicDetailExcludedLessonSuccess
  ): DeleteCoursePublicDetailExcludedLessonSuccessAction =>
    createAction(ActionType.DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_SUCCESS, payload),
  deleteCoursePublicDetailExcludedLessonFailure: (
    payload: DeleteCoursePublicDetailExcludedLessonFailure
  ): DeleteCoursePublicDetailExcludedLessonFailureAction =>
    createAction(ActionType.DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_FAILURE, payload)
};

export type ActionTypes = ActionsUnion<typeof actions>;
