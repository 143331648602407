import type { History } from 'history';

import { LessonDiscriminatorEnum } from '@edapp/authoring-logic';
import type { MicroCredential } from '@edapp/authoring-logic';
import type { CoursewareStatus } from '@edapp/ed-components';

import type { CourseType, LessonItemType } from '../course/types';
import type { Action, EdErrorResponseType, RecursivePartial } from '../types';

// Fetch
export const FETCH_COURSE = 'FETCH_COURSE';
export type FetchCourse = { courseId: string };
export const fetchCourse = (courseId: string): Action<FetchCourse> => ({
  type: FETCH_COURSE,
  payload: { courseId }
});

export const FETCH_COURSE_SUCCESS = 'FETCH_COURSE_SUCCESS';
export type FetchCourseSuccess = CourseType;

export const FETCH_COURSE_FAILURE = 'FETCH_COURSE_FAILURE';
export type FetchCourseFailure = EdErrorResponseType;

export const SAVE_COURSE_LEADERBOARD = 'SAVE_COURSE_LEADERBOARD';
export type SaveCourseLeaderboard = { courseId: string; isEnabled: boolean };

export const saveCourseLeaderboard = (
  courseId: string,
  isEnabled: boolean,
  oldCourseData: CourseType
): Action => ({
  type: SAVE_COURSE_LEADERBOARD,
  payload: { courseId, isEnabled, oldCourseData }
});

export const SET_COURSE_LEADERBOARD = 'SET_COURSE_LEADERBOARD';
export type SetCourseLeaderboard = {
  courseId: string;
  isEnabled: boolean;
};

export const setCourseLeaderboard = (
  courseId: string,
  isEnabled: boolean
): Action<SetCourseLeaderboard> => ({
  type: SET_COURSE_LEADERBOARD,
  payload: { courseId, isEnabled }
});

export const SAVE_COURSE_LEADERBOARD_SUCCESS = 'SAVE_COURSE_LEADERBOARD_SUCCESS';
export type SaveCourseLeaderboardSuccess = {
  courseId: string;
  leaderboard: { id: string; isEnabled: boolean };
};

// Set & Save

// Set
export const SET_COURSE = 'SET_COURSE';
export type SetCourse = {
  courseId: string;
  data: RecursivePartial<CourseType>;
};

export const SET_COURSE_SKIP_SAVE = 'SET_COURSE_SKIP_SAVE';
export type SetCourseSkipSave = {
  courseId: string;
  data: RecursivePartial<CourseType>;
};
export const setCourse = (
  courseId: string,
  data: RecursivePartial<CourseType>
): Action<SetCourse> => ({
  type: SET_COURSE,
  payload: { courseId, data }
});

export const setCourseSkipSave = (
  courseId: string,
  data: RecursivePartial<CourseType>
): Action<SetCourse> => ({
  type: SET_COURSE_SKIP_SAVE,
  payload: { courseId, data }
});

// Save
export const SAVE_COURSE = 'SAVE_COURSE';
export type SaveCourse = {
  courseId: string;
  data: RecursivePartial<CourseType>;
  oldCourseData: CourseType;
};
export const saveCourse = (
  courseId: string,
  data: RecursivePartial<CourseType>,
  oldCourseData: CourseType
): Action<SaveCourse> => ({
  type: SAVE_COURSE,
  payload: { courseId, data, oldCourseData }
});

export const SAVE_COURSE_SUCCESS = 'SAVE_COURSE_SUCCESS';
export type SaveCourseSuccess = {
  id: string;
  microCredential: MicroCredential;
};

export const SAVE_COURSE_FAILURE = 'SAVE_COURSE_FAILURE';
export type SaveCourseFailure = {
  courseId: string;
  courseStateBeforeSave: CourseType;
  error: EdErrorResponseType;
};

// Create Course
export const CREATE_COURSE = 'CREATE_COURSE';
export type CreateCourse = {
  collectionId: string | undefined;
  history: History;
  title: string;
  description?: string;
  image?: string;
};
export const createCourse = (
  collectionId: string | undefined,
  history: History,
  title: string,
  description?: string,
  image?: string
): Action<CreateCourse> => ({
  type: CREATE_COURSE,
  payload: { collectionId, history, title, description, image }
});

export const CREATE_COURSE_SUCCESS = 'CREATE_COURSE_SUCCESS';
export type CreateCourseSuccess = { id: string };

export const CREATE_COURSE_FAILURE = 'CREATE_COURSE_FAILURE';
export type CreateCourseFailure = EdErrorResponseType;

// Update Course
export const UPDATE_COURSE_TAGS = 'UPDATE_COURSE_TAGS';
export const UPDATE_COURSE_TAGS_SUCCESS = 'UPDATE_COURSE_TAGS_SUCCESS';
export const UPDATE_COURSE_TAGS_FAILURE = 'UPDATE_COURSE_TAGS_FAILURE';
export type UpdateCourseTags = {
  courseId: string;
  tags: string[];
};

export const updateCourseTags = (
  courseId: string,
  tags: string[] = []
): Action<UpdateCourseTags> => ({
  type: UPDATE_COURSE_TAGS,
  payload: {
    courseId,
    tags
  }
});

//Enable Group Training
export const UPDATE_GROUP_TRAINING = 'UPDATE_GROUP_TRAINING';
export const UPDATE_GROUP_TRAINING_SUCCESS = 'UPDATE_GROUP_TRAINING_SUCCESS';
export const UPDATE_GROUP_TRAINING_FAILURE = 'UPDATE_GROUP_TRAINING_FAILURE';

export type UpdateGroupTraining = {
  courseId: string;
  isGroupTrainingEnabled: boolean;
};

export const updateGroupTraining = (
  courseId: string,
  isGroupTrainingEnabled: boolean
): Action<UpdateGroupTraining> => ({
  type: UPDATE_GROUP_TRAINING,
  payload: {
    courseId,
    isGroupTrainingEnabled
  }
});

// Schedule Course
export const SET_SCHEDULE_COURSE = 'SET_SCHEDULE_COURSE';
export type ScheduleCourse = {
  courseId: string;
  publishOn?: string;
};
export const setScheduleCourse = (scheduleConfig: ScheduleCourse): Action<ScheduleCourse> => ({
  type: SET_SCHEDULE_COURSE,
  payload: scheduleConfig
});

// Publish Course
export const SET_PUBLISH_COURSE = 'SET_PUBLISH_COURSE';
export type PublishCourse = {
  courseId: string;
  tags?: string[];
};
export const setPublishCourse = (publishConfig: PublishCourse): Action<PublishCourse> => ({
  type: SET_PUBLISH_COURSE,
  payload: publishConfig
});

// Unpublish Course
export const SET_UNPUBLISH_COURSE = 'SET_UNPUBLISH_COURSE';
export type UnpublishCourse = {
  courseId: string;
  status: CoursewareStatus;
};
export const setUnpublishCourse = (unpublishConfig: UnpublishCourse): Action<UnpublishCourse> => ({
  type: SET_UNPUBLISH_COURSE,
  payload: unpublishConfig
});

export const SET_UNPUBLISH_COURSE_SUCCESS = 'SET_UNPUBLISH_COURSE_SUCCESS';

export const SET_UNPUBLISH_COURSE_FAILURE = 'SET_UNPUBLISH_COURSE_FAILURE';
export type SetUnpublishCourseFailure = {
  courseId: string;
  prevStatus: CoursewareStatus;
  error: EdErrorResponseType;
};

// Set Course Visibility - To be Deprecated with v3 Publish/Unpublish
export const SET_COURSE_VISIBILITY = 'SET_COURSE_VISIBILITY';
export type SetCourseVisibility = {
  courseId: string;
  visibleToLearners: boolean;
  tags?: string[];
};

// Reorder Lesson
export const REORDER_LESSONS = 'REORDER_LESSONS';

export type ReorderLessons = {
  courseId: string;
  lessonRank: LessonRank[];
  originalLessons: LessonItemType[];
};

type LessonRank = {
  moduleId: string | null | undefined;
  lessonId: string;
};
export const reorderLessons = ({
  courseId,
  lessonRank,
  originalLessons
}: ReorderLessons): Action<ReorderLessons> => ({
  type: REORDER_LESSONS,
  payload: { courseId, lessonRank, originalLessons }
});

export const REORDER_LESSONS_SUCCESS = 'REORDER_LESSONS_SUCCESS';

export const REORDER_LESSONS_FAILURE = 'REORDER_LESSONS_FAILURE';
export type ReorderLessonsFailure = {
  courseId: string;
  lessons: LessonItemType[];
  error: EdErrorResponseType;
};

// Delete Lesson
export const DELETE_LESSON = 'DELETE_LESSON';
export type DeleteLesson = { courseId: string; lessonId: string; type: LessonDiscriminatorEnum };
export const deleteLesson = (
  courseId: string,
  lessonId: string,
  type = LessonDiscriminatorEnum.LESSON
): Action<DeleteLesson> => ({
  type: DELETE_LESSON,
  payload: { courseId, lessonId, type }
});

export const DELETE_LESSON_SUCCESS = 'DELETE_LESSON_SUCCESS';
export type DeleteLessonSuccess = DeleteLesson;

export const DELETE_LESSON_FAILURE = 'DELETE_LESSON_FAILURE';
export type DeleteLessonFailure = EdErrorResponseType;

// Clear
export const CLEAR_COURSE = 'CLEAR_COURSE';
export type ClearCourse = {};
export const clearCourse = (): Action<ClearCourse> => ({
  type: CLEAR_COURSE,
  payload: {}
});

// Course User Groups

// Set
export const SET_COURSE_USER_GROUPS = 'SET_COURSE_USER_GROUPS';
export type SetCourseUserGroups = {
  courseId: string;
  data: {
    universalAccess: boolean;
    userGroups: string[];
  };
};

export const setCourseUserGroups = (
  courseId: string,
  universalAccess: boolean,
  userGroups: string[]
): Action<SetCourseUserGroups> => ({
  type: SET_COURSE_USER_GROUPS,
  payload: { courseId, data: { universalAccess, userGroups } }
});

// Save
export const SAVE_COURSE_USER_GROUPS = 'SAVE_COURSE_USER_GROUPS';
export type SaveCourseUserGroups = {
  courseId: string;
  data: RecursivePartial<CourseType>;
  oldCourseData: CourseType;
};

export const saveCourseUserGroups = (
  courseId: string,
  data: RecursivePartial<CourseType>,
  oldCourseData: CourseType
): Action<SaveCourseUserGroups> => ({
  type: SAVE_COURSE_USER_GROUPS,
  payload: { courseId, data, oldCourseData }
});

// Success / Failure
export const SAVE_COURSE_USER_GROUPS_SUCCESS = 'SAVE_COURSE_USER_GROUPS_SUCCESS';
export const SAVE_COURSE_USER_GROUPS_FAILURE = 'SAVE_COURSE_USER_GROUPS_FAILURE';

// Course Prerequisites

// Set
export const SET_COURSE_PREREQUISITES = 'SET_COURSE_PREREQUISITES';
export type SetCoursePrerequisites = {
  courseId: string;
  data: {
    prerequisites: string[];
  };
};

export const setCoursePrerequisites = (
  courseId: string,
  prerequisites: string[]
): Action<SetCoursePrerequisites> => ({
  type: SET_COURSE_PREREQUISITES,
  payload: { courseId, data: { prerequisites } }
});

// Save
export const SAVE_COURSE_PREREQUISITES = 'SAVE_COURSE_PREREQUISITES';
export type SaveCoursePrerequisites = {
  courseId: string;
  data: RecursivePartial<CourseType>;
  oldCourseData: CourseType;
};

export const saveCoursePrerequisites = (
  courseId: string,
  data: RecursivePartial<CourseType>,
  oldCourseData: CourseType
): Action<SaveCoursePrerequisites> => ({
  type: SAVE_COURSE_PREREQUISITES,
  payload: { courseId, data, oldCourseData }
});

// Success / Failure
export const SAVE_COURSE_PREREQUISITES_SUCCESS = 'SAVE_COURSE_PREREQUISITES_SUCCESS';
export const SAVE_COURSE_PREREQUISITES_FAILURE = 'SAVE_COURSE_PREREQUISITES_FAILURE';
