import * as React from 'react';
import { StyledBox, StyledCheckbox, CheckboxLabel } from './styled';
import type { ItemsCheckListItemProp } from './types';

export const ItemsCheckListItem: React.VFC<ItemsCheckListItemProp> = ({
  id,
  displayText,
  isDisabled,
  isSelected,
  handleItemClick
}) => {
  const [isItemSelected, setIsItemSelected] = React.useState(isSelected);

  React.useEffect(() => {
    setIsItemSelected(isSelected);
  }, [isSelected]);

  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault();
    handleItemClick(id);
  };

  return (
    <StyledBox
      flex
      alignItems="center"
      key={id}
      onClick={!isDisabled ? handleClick : undefined}
      isDisabled={isDisabled}
    >
      <StyledCheckbox
        checked={isItemSelected}
        testId={`item-checkbox-${id}`}
        isDisabled={isDisabled}
      />
      <CheckboxLabel isDisabled={isDisabled}>{displayText}</CheckboxLabel>
    </StyledBox>
  );
};
