import type { PrerequisiteResponse, PrerequisitesResponse } from '@edapp/hippo-client';

const hasDependency = (
  forId: string,
  lookupItem: PrerequisiteResponse,
  data: PrerequisitesResponse
): boolean => {
  if (lookupItem.prerequisites.includes(forId)) {
    return true;
  }

  for (const prereqId of lookupItem.prerequisites) {
    const item = data.find(x => x.id === prereqId);
    if (!item) {
      return false;
    }

    if (item.prerequisites.includes(lookupItem.id)) {
      // already got a recursive depedency
      // break away from infinite loop
      return true;
    }

    return hasDependency(forId, item, data);
  }

  return false;
};

/**
 *
 * This is to avoid a recursive dependency on prerequisites:
 *  - If A depends on B,
 *    B cannot depend on A.
 *
 *  - If A depends on B and B depends on C,
 *    C cannot depend on A or B
 *
 * @param forId - the id of the item that's been prompted for
 * @param data - all data of all courseware and their prereqs
 * @returns
 */
export const filterPrerequisitesDeadlocks = (forId: string, data: PrerequisitesResponse) => {
  const result = [];

  for (const item of data) {
    if (item.id === forId) {
      continue; // remove itself
    }

    const found = hasDependency(forId, item, data);
    if (!found) {
      result.push(item);
    }
  }

  return result;
};
