import * as React from 'react';
import styled, { css } from 'styled-components';
import type { MultiValueGenericProps } from 'react-select/src/components/MultiValue';
import type { Option } from '../types';

export function MultiValueLabel<OptionValueType>({
  children,
  selectProps
}: MultiValueGenericProps<Option<OptionValueType>>) {
  return (
    <SpanWithCharLimit $maxCharacters={selectProps.maxCharacters}>{children}</SpanWithCharLimit>
  );
}
export const SpanWithCharLimit = styled.span<{ $maxCharacters?: number }>`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-all;

  ${({ $maxCharacters }) =>
    $maxCharacters &&
    css`
      max-width: ${$maxCharacters}ch;
    `}
`;
