import { applyMiddleware, combineReducers, createStore } from 'redux';
import { compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// defaults to localStorage for web
import createSagaMiddleware from 'redux-saga';

import { AuthoringReducer } from '@edapp/authoring-logic';
import { brainBoostReducer, initialBrainBoostState } from '@edapp/brain-boost';
import {
  assessmentReducer,
  courseReducer,
  coursewareReducer,
  discussionReducer,
  initialAssessmentState,
  initialCourseReducerState,
  initialCoursewareState,
  initialDiscussionState,
  initialLessonState,
  lessonReducer,
  tagsReducer
} from '@edapp/courseware-logic';
import { LoadingReducer } from '@edapp/loading';
import { RequestReducer } from '@edapp/request';
import { initialAnalyticsState } from '@rio/store/analytics/constants';
import { analyticsReducer } from '@rio/store/analytics/reducer';
import rootSaga from '@rio/store/rootSagas';
import type { LmsStoreState } from '@rio/store/types';
import { mergeDeep } from '@rio/utils/mergeDeep';

import { bannersReducer, initialBannerState } from './banners/reducer';
import { initialBetterPptxState } from './better-pptx/constants';
import { betterPptxReducer } from './better-pptx/reducer';
import { conferenceReducer, initialConferenceState } from './conference/reducer';
import configReducer, { initialConfigState } from './config/reducer';
import type { ConfigStore } from './config/types';
import { courseCollectionReducer, initialCourseCollectionState } from './courseCollection/reducer';
import {
  customAchievementsReducer,
  initialCustomAchievementsState
} from './custom-achievements/reducer';
import { initialOnboardingState, onboardingReducer } from './onboarding/reducer';
import { initialPeerAuthoringState, peerAuthoringReducer } from './peerAuthoring/reducer';
import { initialPlaylistState, playlistsReducer } from './playlists/reducer';
import { initialRapidRefreshState, rapidRefreshReducer } from './rapid-refresh/reducer';
import surveyReducer, { initialSurveyState } from './survey/reducer';
import { initialUserGroupsState } from './userGroups/constants';
import { userGroupsReducer } from './userGroups/reducer';

const sagaMiddleware = createSagaMiddleware();

export const initialStoreState: LmsStoreState = {
  config: initialConfigState,
  request: RequestReducer.intialRequestState,
  ringo: AuthoringReducer.initialRingoState,
  survey: initialSurveyState,
  courseCollection: initialCourseCollectionState,
  course: initialCourseReducerState,
  courseware: initialCoursewareState,
  lesson: initialLessonState,
  discussion: initialDiscussionState,
  assessment: initialAssessmentState,
  userGroups: initialUserGroupsState,
  peerAuthoring: initialPeerAuthoringState,
  loading: LoadingReducer.initialState,
  analytics: initialAnalyticsState,
  playlists: initialPlaylistState,
  banners: initialBannerState,
  conference: initialConferenceState,
  tags: [],
  rapidRefresh: initialRapidRefreshState,
  onboarding: initialOnboardingState,
  customAchievements: initialCustomAchievementsState,
  brainBoost: initialBrainBoostState,
  betterPptx: initialBetterPptxState
};

const rapidRefreshPersistConfig = {
  key: 'ED-rapidRefresh',
  storage: storage,
  whitelist: ['sort']
};

const betterPptxPersistConfig = {
  key: 'ED-betterPptx',
  storage: storage
};

export const rootReducer = combineReducers<LmsStoreState>({
  request: RequestReducer.reducer,
  config: configReducer,
  ringo: AuthoringReducer.reducer,
  survey: surveyReducer,

  courseCollection: courseCollectionReducer,
  course: courseReducer,
  courseware: coursewareReducer,
  lesson: lessonReducer,
  discussion: discussionReducer,
  assessment: assessmentReducer,
  userGroups: userGroupsReducer,
  peerAuthoring: peerAuthoringReducer,
  loading: LoadingReducer.reducer,
  analytics: analyticsReducer,
  playlists: playlistsReducer,
  banners: bannersReducer,
  conference: conferenceReducer,
  tags: tagsReducer,
  rapidRefresh: persistReducer(rapidRefreshPersistConfig, rapidRefreshReducer),
  onboarding: onboardingReducer,
  customAchievements: customAchievementsReducer,
  brainBoost: brainBoostReducer,
  betterPptx: persistReducer(betterPptxPersistConfig, betterPptxReducer)
});

export const configureStore = (config: RecursivePartial<ConfigStore>, preloadedData: {}) => {
  const composeEnhancers =
    process.env.NODE_ENV === 'production' ? compose : composeWithDevTools({ name: 'LMS Ed Redux' });
  const createDevStoreWithMiddleware = composeEnhancers(applyMiddleware(sagaMiddleware))(
    createStore
  );

  const mergedInitialStoreState = mergeDeep({}, initialStoreState, preloadedData);

  const store = createDevStoreWithMiddleware(rootReducer, {
    ...mergedInitialStoreState,
    config: mergeDeep({}, initialStoreState.config, config)
  });

  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return { store, persistor };
};

export default configureStore;
