import * as React from 'react';

import styled, { css } from 'styled-components';

import { Box } from '@edapp/ed-components';
import { validators } from '@edapp/utils';

import type { FormInputProps } from './FormInput';
import { FormInput } from './FormInput';

type FullNameInputProps = {
  labelFirstName?: React.ReactNode;
  labelLastName?: React.ReactNode;
  forceStacked?: boolean;
} & Pick<FormInputProps, 'disabled' | 'validateFields'>;

export const FullNameInput: React.FC<FullNameInputProps> = ({
  labelFirstName = 'First name',
  labelLastName = 'Last name',
  forceStacked = false,
  disabled,
  validateFields
}) => {
  return (
    <InputsWrapper forceStacked={forceStacked} flex justifyContent="space-between" gap={2}>
      <InputWrapper forceStacked={forceStacked}>
        <FormInput
          name="firstName"
          label={labelFirstName}
          isFullWidth
          disabled={disabled}
          validate={validators.required}
          validateFields={validateFields}
        />
      </InputWrapper>
      <InputWrapper forceStacked={forceStacked}>
        <FormInput
          name="lastName"
          label={labelLastName}
          isFullWidth
          disabled={disabled}
          validate={validators.required}
          validateFields={validateFields}
        />
      </InputWrapper>
    </InputsWrapper>
  );
};

type InputBase = {
  forceStacked: boolean;
};

const InputsWrapper = styled(Box)<InputBase>(({ theme, forceStacked }) => {
  if (forceStacked)
    return css`
      flex-direction: column;
    `;
  else
    return css`
      @media screen and (max-width: ${theme.breakpoints.sm}rem) {
        flex-direction: column;
      }
    `;
});

const InputWrapper = styled(Box)<InputBase>(({ theme, forceStacked }) => {
  if (forceStacked)
    return css`
      width: 100%;
    `;
  else
    return css`
      @media screen and (max-width: ${theme.breakpoints.sm}rem) {
        width: 100%;
      }
      width: 50%;
    `;
});
