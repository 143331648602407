import * as React from 'react';

import { createBrowserHistory } from 'history';
import { BrowserRouter, Router as ReactRouter, Route, Switch } from 'react-router-dom';

import { ErrorFallbackUI, ErrorNotFound } from '@edapp/courseware-ui';
import { ErrorBoundary } from '@edapp/monitoring';
import { useScPlatformContext } from '@edapp/sc-web-ui';
import {
  hasEditContentRole as checkHasEditContentRole,
  hasManageTeamRole as checkHasManageTeamRole,
  useUserInfo
} from '@edapp/user-info';

import { useHistoryListener, useUXPLocationChangedListener } from './hooks';
import { withRouteDefinition } from './route-definition';
import { routes } from './routes';

const Routes: React.FC = () => {
  useHistoryListener();
  useUXPLocationChangedListener();

  return (
    <Switch>
      {Object.values(routes)
        .reduce<React.ReactElement[]>(withRouteDefinition, [])
        .concat(<Route key="not-found" component={ErrorNotFound} />)}
    </Switch>
  );
};

export const Router: React.FC = () => (
  <ErrorBoundary fallback={<ErrorFallbackUI />}>
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  </ErrorBoundary>
);

export const TrainingRouter: React.FC = () => {
  const history = createBrowserHistory({ basename: '/training/manage' });
  const { isSCMobile } = useScPlatformContext();

  const {
    userInfo: { userRoles }
  } = useUserInfo();
  const hasManageTeamRole = checkHasManageTeamRole(userRoles);
  const hasEditContentRole = checkHasEditContentRole(userRoles);

  if (isSCMobile && hasManageTeamRole && !hasEditContentRole) {
    // Redirecting for users that only have manage teams role and is not admin, content or restricted author
    // TODO: https://safetyculture.atlassian.net/browse/TRAINING-539
    routes.manageComingSoon.redirects = ['/courseware'];
  }

  return (
    <ErrorBoundary fallback={<ErrorFallbackUI />}>
      <ReactRouter history={history}>
        <Routes />
      </ReactRouter>
    </ErrorBoundary>
  );
};
