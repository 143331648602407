import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { Box, Divider, Typography } from '@edapp/ed-components';
import { useFeatureFlags } from '@edapp/feature-flags';
import { useLogout } from '@rio/api/accounts/authentication';
import { Flags } from '@rio/components/feature-flags';
import { routes } from '@rio/router';
import {
  getIsOnFreemiumPlan,
  hasBillingPermission,
  isSuperAdmin as superAdminSelector
} from '@rio/store/config/selectors';
import { BillingPermission } from '@rio/utils/permissions/constants';

import { NavBillingLimits } from '../nav-billing-limits/NavBillingLimits';
import { useAppSettingsPermission } from './hooks';

type Props = {
  openSwitchAccount: () => void;
};
export const MenuItems: React.FC<Props> = ({ openSwitchAccount }) => {
  const { t } = useTranslation('translation');
  const isSuperAdmin = useSelector(superAdminSelector);
  const isFreemium = useSelector(getIsOnFreemiumPlan);
  // Account settings
  const accessEditSetting = useAppSettingsPermission();
  const accessPublicProfile = accessEditSetting && isFreemium;
  const accessAccountDetails = useAppSettingsPermission('ACCESS_ACCOUNT_DETAILS') && isSuperAdmin;
  const accessBillingDetails = useAppSettingsPermission('ACCESS_BILLING_DETAILS');
  const accessSSO = useAppSettingsPermission('ACCESS_SSO');
  const accessAPI = useAppSettingsPermission('ACCESS_API');
  const accessIntegration = useAppSettingsPermission('ACCESS_INTEGRATIONS');
  const accessLearnerNotifications = useAppSettingsPermission('ACCESS_LEARNER_NOTIFICATIONS');
  const [isAdvancedIntegrationsEnabled] = useFeatureFlags([Flags.enableAdvancedIntegrations]);
  const accessAdvancedIntegration = isSuperAdmin && isAdvancedIntegrationsEnabled;
  // Support settings
  const accessSupportCentre = useSelector(
    hasBillingPermission(BillingPermission.ACCESS_SUPPORT_CENTRE)
  );
  const accessSwitchAccount = useSelector(
    hasBillingPermission(BillingPermission.ACCESS_SWITCH_ACCOUNTS)
  );

  const { logout } = useLogout();

  const handleLogout = React.useCallback(() => {
    logout();
  }, []);

  return (
    <>
      <NavBillingLimits />
      <Divider />
      {accessEditSetting && (
        <>
          <Box py={1} textAlign="left">
            <Typography
              as="p"
              variant="subtext2"
              color="textMuted"
              testId="profile-dropdown-group-settings"
              py={1}
              px={2}
            >
              {t('navigation-menu.setting.account-settings')}
            </Typography>
            {accessPublicProfile && (
              <Item href="/public-profile">{t('navigation-menu.setting.public-profile')}</Item>
            )}
            <Item href="/app-settings">{t('navigation-menu.setting.app-settings')}</Item>
            {accessLearnerNotifications && (
              <Item href="/account-settings/learner-notifications">
                {t('app-settings.engagement.learner-notifications')}
              </Item>
            )}
            {accessAccountDetails && (
              <Item href="/account">{t('navigation-menu.setting.account-details')}</Item>
            )}
            {accessBillingDetails && (
              <Item href="/account/billing">{t('billing.breadcrumbs.billing')}</Item>
            )}
            {accessSSO && <Item href="/app-settings/sso">{t('navigation-menu.setting.sso')}</Item>}
            {isSuperAdmin && <Item href="/lms-sso">{t('navigation-menu.setting.lms-sso')}</Item>}
            {accessAPI && <Item href="/app-settings/api">{t('api-token.name')}</Item>}
            {accessIntegration && (
              <Item href="/integrations">{t('navigation-menu.setting.integrations')}</Item>
            )}
            {accessAdvancedIntegration && (
              <Item
                href={routes.integrationBuilder.getRoute({ embeddedPath: 'recipes?fid=projects' })}
              >
                {t('navigation-menu.setting.advanced-integrations')}
              </Item>
            )}
          </Box>
          <Divider />
        </>
      )}
      {accessSupportCentre && (
        <>
          <Box py={1} textAlign="left">
            <Typography
              as="p"
              variant="subtext2"
              color="textMuted"
              testId="profile-dropdown-group-help"
              py={1}
              px={2}
            >
              {t('common.help')}
            </Typography>
            <Item href="https://support.edapp.com/" target="_blank" rel="noopener noreferrer">
              {t('navigation-menu.setting.support-centre')}
            </Item>
          </Box>
          <Divider />
        </>
      )}

      <Box py={1} testId="account-options-block">
        {accessSwitchAccount && (
          <>
            <Item as="button" testId="menu-switch-account" onClick={openSwitchAccount}>
              {t('navigation-menu.setting.switch-accounts')}
            </Item>
          </>
        )}
        <Item testId="logout" onClick={handleLogout}>
          {t('navigation-menu.setting.sign-out')}
        </Item>
      </Box>
    </>
  );
};

const Item = styled(Typography)(
  ({ theme }) => css`
    display: block;
    padding: ${theme.space()}px ${theme.space(2)}px;
    width: 100%;
    text-align: left;
    text-decoration: none;
    background: none;
    border: none;
    :hover {
      background: ${theme.colors.lightBlue};
      text-decoration: none;
    }
  `
);
Item.defaultProps = {
  as: 'a'
};
