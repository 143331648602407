import * as React from 'react';
import { Form } from 'react-final-form';

import { Box, Button, Loading, Typography } from '@edapp/ed-components';
import { FullNameInput } from '@edapp/registration';
import { Text } from '@edapp/sc-web-ui';
import { useFetchUser } from '@edapp/user-info';
import { useUpdateUserProfile } from '@rio/api/accounts/user-profile';

import type { UpdateUserProfileRequest } from '@rio/api/accounts/user-profile/types';
import styled from 'styled-components';

type Fields = Pick<UpdateUserProfileRequest, 'firstName' | 'lastName'>;

export const UpdateName: React.FC = () => {
  const { mutateAsync: updateName, isLoading } = useUpdateUserProfile();
  const { data: userInfo, refetch: fetchUserInfo, isLoading: isLoadingUserInfo } = useFetchUser();
  const userId = userInfo?.userId || '';
  const firstName = userInfo?.userFirstName || '';
  const lastName = userInfo?.userLastName || '';

  const initialValues = React.useMemo<Fields>(() => ({ firstName, lastName }), [
    firstName,
    lastName
  ]);

  const handleSubmit = (values: Fields) => {
    updateName(
      { firstName: values.firstName, lastName: values.lastName, userId },
      {
        onSuccess: () => {
          fetchUserInfo();
        }
      }
    );
  };

  if (isLoadingUserInfo) {
    return <Loading />;
  }
  return (
    <Box textAlign="left">
      <StyledText variant="titleLarge" component="h2">
        Add details
      </StyledText>
      <Typography mb={3}>We’ll need your name to display your comments.</Typography>
      <Box mt={3}>
        <Form<Fields> onSubmit={handleSubmit} initialValues={initialValues}>
          {({ handleSubmit, submitting }) => {
            const loading = isLoading || submitting;
            return (
              <form onSubmit={handleSubmit}>
                <FullNameInput disabled={loading} />
                <Box mt={3} flex justifyContent="right">
                  <Button
                    type="submit"
                    variant="primary"
                    testId="submit-details"
                    isLoading={loading}
                  >
                    Start review
                  </Button>
                </Box>
              </form>
            );
          }}
        </Form>
      </Box>
    </Box>
  );
};

const StyledText = styled(Text)`
  margin-top: 0;
`
