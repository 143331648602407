import { put, fork, takeLatest } from 'redux-saga/effects';
import { RequestActions } from '@edapp/request';
import * as actions from './actions';

function* handlefetchCourseTags() {
  yield put(
    RequestActions.getAuthed(
      `/api/tags`,
      actions.FETCH_TAGS_SUCCESS,
      actions.FETCH_TAGS_FAILURE,
      false
    )
  );
}

function* watchFetchTags() {
  yield takeLatest(actions.FETCH_TAGS, handlefetchCourseTags);
}

const sagas = [fork(watchFetchTags)];
export default sagas;
