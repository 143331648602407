import type { CourseCollection } from './types';

export const initialCourseCollection: CourseCollection = {
  id: '',
  created: '',
  modified: '',
  numberOfCourses: 0,
  isManual: false,
  lessonsInFirstCourse: [],
  courses: [],
  thumbnail: '',
  title: '',
  description: '',
  brandingTextColor: 'white',
  brandingImage: '',
  externalIdentifier: ''
};
