import styled from 'styled-components';
import { IconButton, CrossIcon } from '@edapp/ed-components';

export const CourseInfoCloseButton = styled(IconButton).attrs({
  icon: CrossIcon,
  iconColor: 'textMuted',
  variant: 'transparent'
})`
  position: absolute;
  top: ${({ theme }) => theme.space()}px;
  right: ${({ theme }) => theme.space()}px;

  :hover,
  :focus {
    background-color: transparent !important;
    cursor: default;
    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;
