import { RingoActionTypes } from '../constants';
import { FormActionTypes } from '../form/constants';
import type { FormActionsUnionType } from '../form/types';
import type { AuthoringMiniLessonType, RingoActionsUnionType } from '../types';

const reducer = (
  state: AuthoringMiniLessonType | null = null,
  action: RingoActionsUnionType | FormActionsUnionType
) => {
  switch (action.type) {
    case RingoActionTypes.SET_RINGO_LESSON:
      return action.payload;

    case FormActionTypes.SAVE_FORM_FULFILLED: {
      if (!state) return null;

      return {
        ...state,
        latestSavedSlidesStateIdentifier:
          action.payload.latestSavedSlidesStateIdentifier || state.latestSavedSlidesStateIdentifier
      };
    }

    default:
      return state;
  }
};
export default reducer;
