import { useCallback, useEffect, useMemo } from 'react';

import { usePersistedState } from '@edapp/ed-components';
import { CourseAccessEnum, CourseListStatusEnum } from '@edapp/hippo-client';
import { useCheckPermission } from '@edapp/permissions';

import type {
  FilterState,
  FilterStateComparisonMap,
  ResetFilterState,
  UpdateFilterState,
  UseFilterState
} from './types';

export const useFilterState: UseFilterState = userId => {
  const canFilterByAccess = useCheckPermission({
    resource: 'COURSE',
    scope: 'FILTER_BY_ACCESS'
  });

  const defaultFilterState = useMemo<FilterState>(
    () => ({
      access: canFilterByAccess ? CourseAccessEnum.ALL : CourseAccessEnum.OWNED_OR_SHARED,
      status: CourseListStatusEnum.ALL,
      usergroupIds: [],
      locales: [],
      universalAccess: undefined,
      isGroupTrainingEnabled: undefined,
      date: undefined
    }),
    [canFilterByAccess]
  );

  const [filterState, setFilterState] = usePersistedState<FilterState>(
    defaultFilterState,
    `ed:course-list:filter:${userId}`
  );

  useEffect(() => {
    if (!canFilterByAccess && filterState.access !== CourseAccessEnum.OWNED_OR_SHARED) {
      setFilterState(prev => ({ ...prev, access: CourseAccessEnum.OWNED_OR_SHARED }));
    }
  }, [canFilterByAccess, filterState.access, setFilterState]);

  const updateFilterState = useCallback<UpdateFilterState>(
    newFilterState => {
      setFilterState(prevState => ({ ...prevState, ...newFilterState }));
    },
    [setFilterState]
  );

  const resetFilterState = useCallback<ResetFilterState>(() => {
    setFilterState(defaultFilterState);
  }, [defaultFilterState, setFilterState]);

  const filterStateComparisonMap = useMemo<FilterStateComparisonMap>(
    () => ({
      searchTerm: searchTerm => !searchTerm,
      access: access => access === defaultFilterState.access,
      status: status => status === defaultFilterState.status,
      usergroupIds: usergroupIds => usergroupIds.length === 0,
      locales: locales => locales.length === 0,
      universalAccess: universalAccess => universalAccess === defaultFilterState.universalAccess,
      isGroupTrainingEnabled: isGroupTrainingEnabled =>
        isGroupTrainingEnabled === defaultFilterState.isGroupTrainingEnabled,
      date: date => date === defaultFilterState.date
    }),
    [defaultFilterState]
  );

  const isFiltered = useMemo<boolean>(
    () => Object.entries(filterState).some(([key, value]) => !filterStateComparisonMap[key](value)),
    [filterState, filterStateComparisonMap]
  );

  return {
    defaultFilterState,
    filterState,
    isFiltered,
    updateFilterState,
    resetFilterState
  };
};
