import type { ApplicationResponse, BillingPlanDetailsType } from '@edapp/hippo-client';

import type { UserResponse } from './types';

export const UserInfoSelectors = {
  isAdmin: (user?: UserResponse): boolean => user?.userRoles.includes('account-admin') || false,
  isOwner: (user?: UserResponse): boolean => user?.userRoles.includes('account-owner') || false,
  isSafetyCultureAdmin: (user?: UserResponse): boolean =>
    user?.userRoles.includes('safety-culture-admin') || false,
  isEdAdmin: (user?: UserResponse): boolean => user?.userRoles.includes('ed-admin') || false,
  isSuperAdmin: (user?: UserResponse): boolean =>
    user?.userRoles.includes('ed-admin') ||
    user?.userRoles.includes('safety-culture-admin') ||
    false,
  isAnonymousUser: (user?: UserResponse) => user?.userRoles.includes('anonymous-user'),
  isFacilitator: (user?: UserResponse) => user?.userRoles.includes('facilitator') || false,
  isSeoAdmin: (user?: UserResponse): boolean => user?.userRoles.includes('seo-admin') || false,
  isReviewer: (user?: UserResponse): boolean => user?.userRoles.includes('reviewer') || false,
  isContentAuthor: (user?: UserResponse): boolean =>
    user?.userRoles.includes('content-author') || false
};

export const ApplicationInfoSelectors = {
  isUXP: (applicationInfo?: ApplicationResponse): boolean =>
    applicationInfo?.isUnifiedExperience || false,
  isSponsor: (applicationInfo?: ApplicationResponse): boolean => applicationInfo?.isSponsor || false
};

export const BillingSelectors = {
  getIsOnFreemiumPlan: (billing?: BillingPlanDetailsType): boolean =>
    billing?.currentPlan.isFreemium || false,
  getIsOnFreePlan: (billing?: BillingPlanDetailsType): boolean =>
    billing?.currentPlan.isOnFreePlan || false,
  isEnterprise: (billing?: BillingPlanDetailsType): boolean =>
    billing?.currentPlan?.isEnterprise || false
};
