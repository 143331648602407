import * as React from 'react';

import type { FlexBasisProperty } from 'csstype';
import styled, { css } from 'styled-components';

import type { SCUITheme } from '@edapp/sc-web-ui';
import { Badge, Card, HStack, Text, Thumbnail, VStack } from '@edapp/sc-web-ui';
import type { ThemeCommonType } from '@edapp/themes';

import type { CardPickerItemProps, Props } from './types';

export const CardPickerItem: React.FC<CardPickerItemProps> = ({
  title,
  description,
  icon,
  badgeText,
  onClick,
  selected,
  isDisabled = false,
  hidden,
  ...rest
}) => {
  return (
    <HoverCard
      spacing="s4"
      onClick={!isDisabled ? onClick : null}
      isSelected={selected}
      isDisabled={isDisabled}
      data-testId={rest.testId || 'card-picker-item'}
      {...rest}
    >
      <VStack spacing="s3" align="center">
        {icon && (
          <Thumbnail bgColor="accent.bg.weakest" iconColor="accent.text.default" icon={icon} />
        )}

        <VStack spacing="s05" align="center">
          <Text
            variant="labelMedium"
            textAlign="center"
            color={isDisabled ? 'surface.text.disabled' : 'surface.text.default'}
          >
            {title}
          </Text>

          {description && (
            <Text variant="bodyExtraSmall" textAlign="center" color="surface.text.weaker">
              {description}
            </Text>
          )}
        </VStack>

        {!!badgeText && (
          <Badge size="small" variant="information">
            {badgeText}
          </Badge>
        )}
      </VStack>
    </HoverCard>
  );
};

const HoverCard = styled(Card)<{
  isDisabled: boolean;
  basis: FlexBasisProperty<1>;
  theme: ThemeCommonType & SCUITheme;
}>(
  ({ theme, isDisabled, basis = '100%' }) => css`
    flex: 1 1 ${basis};
    max-width: ${basis};
    box-sizing: content-box;
    ${!isDisabled &&
    css`
      cursor: pointer;
      :hover {
        background-color: ${theme._ui.colors.surface.bg.hover};
      }
    `}
  `
);

export const CardPicker: React.FC<React.PropsWithChildren<Props>> = ({
  children,
  items,
  ...rest
}) => (
  <HStack spacing="s4" wrap="wrap" align="stretch" {...rest}>
    {children || items.map((item, index) => <CardPickerItem key={index} {...item} />)}
  </HStack>
);
