import * as React from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import styled from 'styled-components';
import { Divider } from '@edapp/ed-components';
import { ContentSectionCategory } from './ContentSectionCategory';
import { SECTIONED_COURSES_VIEW, SECTIONED_COURSES_VIEW_MAX_COURSE_COUNT } from '../constants';
import { ViewAllButton } from './ViewAllButton';

type Props = {
  sectionId: string;
  sectionTitle: string;
  totalCoursesInCategory: number;
  onChangeView: (index: number) => void;
  viewIndex: number;
  onViewBack: () => void;
  onViewAll: (sectionId: string) => void;
  totalCourses: number;
};

export const ContentSectionHeader: React.FC<Props> = ({
  sectionId,
  sectionTitle,
  onChangeView,
  viewIndex,
  totalCoursesInCategory,
  onViewBack,
  onViewAll,
  totalCourses
}) => (
  <StyledRow center="xs" middle="xs">
    <Col>
      <ContentSectionCategory
        viewIndex={viewIndex}
        sectionId={sectionId}
        sectionTitle={sectionTitle}
        totalCourses={totalCourses}
        totalCoursesInCategory={totalCoursesInCategory}
        onClick={() => {
          onChangeView(viewIndex);
          onViewBack();
        }}
      />
    </Col>

    <StyledColDivider>
      <Divider />
    </StyledColDivider>

    {viewIndex === SECTIONED_COURSES_VIEW &&
      totalCoursesInCategory > SECTIONED_COURSES_VIEW_MAX_COURSE_COUNT && (
        <Col>
          <ViewAllButton
            onClick={() => {
              onChangeView(viewIndex);
              onViewAll(sectionId);
            }}
          />
        </Col>
      )}
  </StyledRow>
);

const StyledRow = styled(Row)`
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  padding: ${({ theme }) => `${theme.space(3)}px ${theme.space(4.5)}px 0`};
`;

const StyledColDivider = styled(Col)`
  flex: 1 1 auto;
`;
