import * as React from 'react';

import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import type { ColorsKeys } from '@edapp/themes';
import { downloadUrl } from '@edapp/utils';

import type { ButtonProps } from '../../../common';
import { useBreakpointMatchMedia } from '../../hooks/useMatchMedia';
import { Box } from '../box/Box';
import { IconButton } from '../button/IconButton';
import { SubtleInput } from '../form/input/SubtleInput';
import { CircleTickIcon, CopyIcon, QRCodeIcon } from '../icons';
import { QRCode } from '../qr-code/QRCode';
import { Tooltip } from '../tooltip';

type Props = {
  link: string;
  bgColor?: ColorsKeys;

  /** a callback to tell when user has clicked to copy */
  onCopy?: () => void;
  copyText?: string;
  buttonVariant?: ButtonProps['variant'];
  qrCodeFilename?: string;
  isDisabled?: boolean;
  /** a callback to tell when user has clicked the QRCode */
  onQRCodeClick?: () => void;
};

const CopyLink: React.FC<Props> = ({
  link,
  qrCodeFilename,
  bgColor = 'lightGrey',
  onCopy,
  onQRCodeClick,
  copyText,
  buttonVariant,
  isDisabled
}) => {
  const { t } = useTranslation();
  const [isTooltipOpen, setIsTooltipOpen] = React.useState(false);
  const [hasCopied, setHasCopied] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const isMobile = useBreakpointMatchMedia('sm', 'max-width');
  const qrCodeRef = React.useRef<HTMLCanvasElement>(null);

  // after 3sec reset state to be copy again
  React.useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;
    if (!!hasCopied) {
      timeout = setTimeout(() => {
        setHasCopied(false);
        setIsTooltipOpen(false);
      }, 3000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [hasCopied, setHasCopied]);

  const handleInputClick = () => {
    if (isDisabled) return;
    handleCopyClick();
  };

  const handleCopyClick = async () => {
    setHasCopied(true);
    setIsTooltipOpen(true);
    inputRef.current?.select();
    onCopy?.();

    if (document.execCommand) {
      document.execCommand('copy');
    } else {
      await navigator.clipboard.writeText(link);
    }
  };

  const handleQRCodeDownload = () => {
    if (!qrCodeRef.current) {
      return;
    }
    downloadUrl(
      qrCodeRef.current.toDataURL(),
      // Turn the course title into a filename safe string for download
      qrCodeFilename ? `${qrCodeFilename.replace(/[^a-zA-Z0-9]/gi, '_')}.png` : 'qr_code.png'
    );
  };

  const tooltipContent = (
    <Box flex={true} flexDirection="row" alignItems="center">
      <CircleTickIcon size="sm" color="green" />
      <Box ml="xs">{t('common.copied')}</Box>
    </Box>
  );

  return (
    <StyledLinkContainer flex={true} flexDirection="column">
      <HiddenQRCode size={140} value={link} ref={qrCodeRef} />

      <Box
        flex={true}
        flexDirection={isMobile ? 'column' : 'row'}
        gap={1.5}
        testId="copy-link-layout"
      >
        <Box
          flex
          flexGrow={1}
          alignItems="center"
          bgColor={bgColor}
          px="xs"
          rounded={true}
          border={true}
        >
          <Input
            testId="copy-link-input"
            readOnly={true}
            ref={inputRef}
            value={link}
            onClick={handleInputClick}
            isFullWidth={true}
            overflowEllipsis={false}
            disabled={isDisabled}
          />
        </Box>

        <Box flex={true} gap={1.5} alignItems="center" testId="copy-link-buttons">
          <Tooltip content={t('common.download-qr-code')} placement="bottom">
            <IconButton
              testId="copy-link-qr-button"
              icon={QRCodeIcon}
              onClick={onQRCodeClick || handleQRCodeDownload}
              disabled={isDisabled}
            />
          </Tooltip>

          <Tooltip
            isOpen={isTooltipOpen && !!hasCopied}
            content={tooltipContent}
            placement="bottom"
          >
            <IconButton
              testId="copy-link-copy-button"
              icon={CopyIcon}
              variant={buttonVariant}
              onClick={handleCopyClick}
              isFullWidth={isMobile}
              disabled={isDisabled}
            >
              {copyText ?? t('common.copy')}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    </StyledLinkContainer>
  );
};

const Input = styled(SubtleInput)(
  ({ theme }) => css`
    flex-grow: 1 ${theme.lineClamp(1)};

    margin: 0px ${theme.space()}px;
    padding: 0px;

    min-width: 105px;

    /* reset disabled state from input */
    cursor: text;
    color: ${theme.colors.textMuted};

    && {
      :enabled {
        &:hover,
        &:focus {
          background: ${theme.colors.transparent};
        }
      }
    }
    :disabled {
      cursor: not-allowed;
    }
  `
);

const StyledLinkContainer = styled(Box)`
  width: 100%;
`;

/**
 * We don't want to show the QR code, but we need a DOM
 * element to render the QR code to so that we can download it.
 */
const HiddenQRCode = styled(QRCode)`
  display: none;
`;

export { CopyLink };
