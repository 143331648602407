import { LabelledTextInput } from '@edapp/ed-components';
import type { InputProps } from '@edapp/ed-components/src/common/form/input';
import type { FieldValidator } from 'final-form';
import * as React from 'react';
import { useField } from 'react-final-form';

export type FormInputProps = {
  name: string;
  validate?: FieldValidator<string>;
  label?: React.ReactNode;
  validateFields?: string[];
} & Omit<InputProps, 'value' | 'hasMargin'>;

export const FormInput: React.FC<FormInputProps> = ({
  name,
  validate,
  label,
  validateFields,
  ...props
}) => {
  const { input, meta } = useField(name, { validate, validateFields: validateFields });

  const submitError = !meta.modifiedSinceLastSubmit && meta.submitError;
  const validationError = meta.touched && meta.error;
  const error = validationError || submitError;

  return (
    <LabelledTextInput
      label={label}
      isFullWidth
      testId={name}
      {...input}
      {...props}
      error={error}
    />
  );
};
