import * as React from 'react';
import styled, { css } from 'styled-components';
import { useIntersectionObserver } from '../../../hooks';
import { mergeRefs } from '../../../utils';
import { useTransformSrc, useTransformMediaQueries } from './hooks';
import type { BackgroundImageProps } from './types';

export const BackgroundImage = React.forwardRef(
  (
    { loading = 'lazy', src, mediaQueries, transformation, ...rest }: BackgroundImageProps,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const internalRef = React.useRef<HTMLDivElement>(null);

    const shouldUseIntersectionObserver =
      loading === 'lazy' && typeof window !== 'undefined' && 'IntersectionObserver' in window;

    const isVisible = useIntersectionObserver(internalRef, {
      disabled: !shouldUseIntersectionObserver
    });

    const transformedSrc = useTransformSrc(src, transformation);

    const transformedMediaQueries = useTransformMediaQueries(src, mediaQueries, transformation);

    return (
      <Styled
        ref={mergeRefs([ref, internalRef])}
        isVisible={isVisible}
        src={transformedSrc}
        mediaQueries={transformedMediaQueries}
        {...rest}
      />
    );
  }
);

type StyledProps = {
  isVisible: boolean;
  src?: string;
  mediaQueries?: string;
};

const Styled = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['src'].includes(prop) && defaultValidatorFn(prop)
})<StyledProps>(
  ({ isVisible, src, mediaQueries }) => css`
    ${!!(isVisible && src) && `background-image: url(${src});`}
    ${!!mediaQueries && mediaQueries}
  `
);
