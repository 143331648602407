import type { ModelConvertedToFormType, ValueType } from '../store/types';

export const findSubValueWithPath = (data: ModelConvertedToFormType, path: string): any => {
  if (!path || path === '') {
    return data;
  } else {
    const paths = path.split(/\./);
    if (paths) {
      let firstPath = paths.shift();
      const rest = paths.join('.');
      if (firstPath) {
        const pathIsArray = firstPath.match(/\[(\d+)\]/);
        let pathIsArrayIndex = -1;
        if (pathIsArray) {
          pathIsArrayIndex = parseInt(pathIsArray[1], 10);
          firstPath = firstPath.replace('[' + pathIsArrayIndex + ']', '');
        }
        if (data && data[firstPath] !== undefined) {
          if (pathIsArrayIndex > -1) {
            return findSubValueWithPath(data[firstPath][pathIsArrayIndex], rest);
          } else {
            return findSubValueWithPath(data[firstPath], rest);
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
    return null;
  }
};

export const findSubValueWithModelPath = (
  model: ModelConvertedToFormType,
  path: string
): ValueType => {
  return findSubValueWithPath(model, path.replace('model.', ''));
};
