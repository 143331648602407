import * as React from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { itly } from '@edapp/analytics-tracking';
import {
  Box,
  ConfettiIcon,
  IconButton,
  Pill,
  RocketIcon,
  Typography,
  useBreakpointMatchMedia
} from '@edapp/ed-components';
import { fetchOnboardingChecklist } from '@rio/store/onboarding/actions';
import type { LmsStoreState } from '@rio/store/types';

const NavGetStartedProgress: React.FC = () => {
  const isMobile = useBreakpointMatchMedia('md', 'max-width');
  const dispatch = useDispatch();
  const { percent, incomplete, loading, error, isDismissed } = useSelector(
    (state: LmsStoreState) => state.onboarding.checklist,
    shallowEqual
  );
  const isCompleted = percent === 1;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const href = '/get-started';

    e.preventDefault();
    e.stopPropagation();

    itly.checklistOpened({
      opened_from: 'navbar',
      opened_from_page: document.body.getAttribute('data-page-name') ?? document.title
    });

    if (href) {
      window.location.href = href;
    }
  };

  React.useEffect(() => {
    if (loading === undefined) {
      dispatch(fetchOnboardingChecklist('main'));
    }
  }, []);

  return loading === false && !isDismissed && !error ? (
    <Wrapper>
      <IconButton
        onClick={handleClick}
        icon={isCompleted ? ConfettiIcon : RocketIcon}
        iconColor="blue"
        size={isMobile ? 'md' : 'xs'}
        isFullWidth={isMobile}
        testId="get-started-button"
      >
        {isCompleted ? (
          <Typography variant="subtext">All tasks complete!</Typography>
        ) : (
          <Box flex gap={1} alignItems="center">
            <Typography variant={isMobile ? 'button' : 'subtext'} color="blue">
              Get started
            </Typography>
            <OvalPill space="mini" backgroundColor="red" textColor="white">
              {incomplete}
            </OvalPill>
          </Box>
        )}
      </IconButton>
    </Wrapper>
  ) : null;
};

const Wrapper = styled.span`
  @media (min-width: 1080px) {
    animation-name: heartbeat;
    animation-duration: 1.5s;
    animation-iteration-count: 3;
    animation-delay: 10s;
  }

  @keyframes heartbeat {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    14% {
      -webkit-transform: scale(1.1);
      transform: scale(1.2);
    }
    28% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    42% {
      -webkit-transform: scale(1.1);
      transform: scale(1.2);
    }
    70% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;

const OvalPill = styled(Pill)`
  padding: 0;
  width: 20px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  display: inline-block;
`;

export default NavGetStartedProgress;
