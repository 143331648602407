import * as React from 'react';
import styled from 'styled-components';
import type { ColorsKeys } from '@edapp/themes';
import type { IconComponent } from '@edapp/ed-components';

export type Props = {
  icon: IconComponent;
  iconColor?: ColorsKeys;
};

export const Header: React.FC<Props> = ({ icon, iconColor = 'text' }) => {
  const Icon = icon;

  return (
    <BigIconWrapper>
      <Icon size="lg" color={iconColor} />
    </BigIconWrapper>
  );
};

const BigIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
