import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import { rgba } from '@edapp/themes';

import { Typography } from '../typography/Typography';

export const TabList = styled.div<{
  isCentered: boolean;
  hasPadding: boolean;
  hasBorderBottom: boolean;
}>(
  ({ theme, isCentered, hasPadding, hasBorderBottom }) => css`
    display: inline-block;
    white-space: nowrap;
    position: relative;
    ${hasPadding && `padding: 0 ${theme.space(6)}px;`}
    ${hasBorderBottom && `border-bottom: 1px solid ${theme.colors.greyHover};`}
    ${
      isCentered &&
      `
      display: flex;
      justify-content: center;
    `
    }
  `
);

export const TabIndicator = styled(motion.div)<{ activeStyle: 'top' | 'bottom' }>(
  ({ theme, activeStyle }) => css`
    height: ${theme.space(0.5)}px;
    position: absolute;
    background: ${theme.colors.blue};
    ${activeStyle}: 0;
  `
);

export const Tab = styled(motion.button)(
  ({ theme }) => css`
    padding: ${theme.space(2)}px ${theme.space(3)}px;
    background: transparent;
    border: none;

    &:hover {
      background-color: ${rgba(theme.colors.blue, 0.1)};
      border-radius: ${theme.sizes.borderRadius}px ${theme.sizes.borderRadius}px 0 0;
      cursor: pointer;
      ${Typography} {
        color: ${theme.colors.blue};
      }
    }
  `
);
