import { createAction } from '@edapp/utils';
import type {
  CreateUserGroupConditions,
  CreateUserGroupConditionsSuccess,
  CreateUserGroupConditionsFailure,
  UpdateUserGroupConditions,
  UpdateUserGroupConditionsSuccess,
  UpdateUserGroupConditionsFailure,
  FetchUserGroupConditions,
  FetchUserGroupConditionsSuccess,
  FetchUserGroupConditionsFailure
} from './types';

export enum ActionType {
  FETCH_USER_GROUP_CONDITIONS = 'FETCH_USER_GROUP_CONDITIONS',
  FETCH_USER_GROUP_CONDITIONS_SUCCESS = 'FETCH_USER_GROUP_CONDITIONS_SUCCESS',
  FETCH_USER_GROUP_CONDITIONS_FAILURE = 'FETCH_USER_GROUP_CONDITIONS_FAILURE',

  CREATE_USER_GROUP_CONDITIONS = 'CREATE_USER_GROUP_CONDITIONS',
  CREATE_USER_GROUP_CONDITIONS_SUCCESS = 'CREATE_USER_GROUP_CONDITIONS_SUCCESS',
  CREATE_USER_GROUP_CONDITIONS_FAILURE = 'CREATE_USER_GROUP_CONDITIONS_FAILURE',

  UPDATE_USER_GROUP_CONDITIONS = 'UPDATE_USER_GROUP_CONDITIONS',
  UPDATE_USER_GROUP_CONDITIONS_SUCCESS = 'UPDATE_USER_GROUP_CONDITIONS_SUCCESS',
  UPDATE_USER_GROUP_CONDITIONS_FAILURE = 'UPDATE_USER_GROUP_CONDITIONS_FAILURE'
}

// #########
//   Fetch
// #########
export const fetchUserGroupConditions: FetchUserGroupConditions = userGroupId =>
  createAction(ActionType.FETCH_USER_GROUP_CONDITIONS, { userGroupId });
export const fetchUserGroupConditionsSuccess: FetchUserGroupConditionsSuccess = payload =>
  createAction(ActionType.FETCH_USER_GROUP_CONDITIONS_SUCCESS, payload);
export const fetchUserGroupConditionsFailure: FetchUserGroupConditionsFailure = payload =>
  createAction(ActionType.FETCH_USER_GROUP_CONDITIONS_FAILURE, payload);

// #########
//  Create
// #########
export const createUserGroupConditions: CreateUserGroupConditions = (userGroupId, conditions) =>
  createAction(ActionType.CREATE_USER_GROUP_CONDITIONS, { userGroupId, conditions });
export const createUserGroupConditionsSuccess: CreateUserGroupConditionsSuccess = () =>
  createAction(ActionType.CREATE_USER_GROUP_CONDITIONS_SUCCESS, {});
export const createUserGroupConditionsFailure: CreateUserGroupConditionsFailure = payload =>
  createAction(ActionType.CREATE_USER_GROUP_CONDITIONS_FAILURE, payload);

// #########
//  Update
// #########
export const updateUserGroupConditions: UpdateUserGroupConditions = (userGroupId, conditions) =>
  createAction(ActionType.UPDATE_USER_GROUP_CONDITIONS, { userGroupId, conditions });
export const updateUserGroupConditionsSuccess: UpdateUserGroupConditionsSuccess = () =>
  createAction(ActionType.UPDATE_USER_GROUP_CONDITIONS_SUCCESS, {});
export const updateUserGroupConditionsFailure: UpdateUserGroupConditionsFailure = payload =>
  createAction(ActionType.UPDATE_USER_GROUP_CONDITIONS_FAILURE, payload);
