import { RequestUtils } from '@edapp/request';

import type { SearchCatalogs } from './types';

export const searchCatalogs: SearchCatalogs = (hippoUrl, userToken, request) =>
  RequestUtils.httpFetch(
    'GET',
    `${hippoUrl}/api/catalogs`,
    userToken,
    !!request?.searchTerm
      ? { ...request, searchTerm: encodeURIComponent(request.searchTerm) }
      : request
  );
