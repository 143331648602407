import type { Answer, TemplateInputType } from '../../store/types';
import type {
  CarouselData,
  CategoriseData,
  CircleTheAnswerData,
  ImageMultipleChoiceData,
  MCQChatData,
  MatrixData,
  Methods,
  MultipleChoiceData
} from './types';

export const methods: Methods = {
  carousel: (e: CarouselData) => {
    return {
      question: {
        type: 'text',
        content: e.title,
        category: e.title,
        text: e.title
      },
      answers: e.answers.map((a: any) => {
        return {
          type: 'text',
          content: a.content,
          text: a.content,
          correct: !!a.correct
        };
      })
    };
  },
  categorise: (e: CategoriseData) => {
    return {
      question: {
        type: e.titleType,
        content: e.title,
        category: e.title,
        text: e.title
      },
      answers: e.categories.map((c: any) => {
        return {
          correct: c.correct,
          content: c.text,
          text: c.text,
          type: 'text'
        };
      })
    };
  },
  chat: (e: MCQChatData) => {
    return {
      question: {
        type: 'text',
        text: e.message.content,
        content: e.message.content,
        category: e.title
      },
      answers: [
        {
          type: 'text',
          text: e.answers.correct,
          content: e.answers.correct,
          correct: true
        }
      ].concat(
        e.answers.incorrect.map(a => {
          return {
            type: 'text',
            text: a,
            content: a,
            correct: false
          };
        })
      ) as Answer[]
    };
  },
  'multiple-choice-game': (e: MultipleChoiceData) => {
    return {
      question: {
        type: e.titleType,
        content: e.title,
        category: e.title,
        text: e.title
      },
      answers: e.answers.length
        ? e.answers
            .filter((a: any) => {
              return a != null;
            })
            .map((a: any) => {
              return {
                type: 'text',
                content: a.content,
                text: a.content,
                correct: a.correct != null && a.correct
              };
            })
        : []
    };
  },
  'circle-the-answer': (e: CircleTheAnswerData) => {
    return {
      question: {
        type: e.titleType,
        content: e.title,
        text: e.title,
        category: e.title
      },
      answers: [
        {
          type: 'text' as TemplateInputType,
          content: e.answers.correct,
          text: e.answers.correct,
          correct: true
        }
      ].concat(
        e.answers.incorrect.map((a: any) => {
          return {
            content: a,
            correct: false,
            text: a,
            type: 'text'
          };
        })
      ) as Answer[]
    };
  },
  'image-multiple-choice': (e: ImageMultipleChoiceData) => {
    return {
      question: {
        type: e.titleType,
        content: e.title,
        text: e.title,
        category: e.title
      },
      answers: e.answers.map((a: any) => {
        return {
          type: 'image',
          text: a.image,
          content: a.image,
          correct: a.correct != null && a.correct
        };
      })
    };
  },
  matrix: (e: MatrixData) => {
    return e.columns.map(column => {
      return {
        question: {
          category: e.title,
          content: column.title,
          text: column.title,
          type: 'text'
        },
        answers: [
          {
            type: 'text',
            content: column.correct,
            text: column.correct,
            correct: true
          }
        ].concat(
          column.incorrect.map((a: any) => {
            return {
              type: 'text',
              content: a,
              text: a,
              correct: false
            };
          })
        ) as Answer[]
      };
    });
  }
};

export default methods;
