import type { ScrollingObj } from './types';

// Email entered by user is saved in localstorage on signup input
export const EMAIL_LOCALSTORAGE = 'EMAIL_LOCALSTORAGE';

export const BillingRoutes = {
  // Account
  billing: '/account/billing',
  paymentEdit: '/account/billing/edit',
  // Subscribe
  selectPlans: '/subscribe/select-plan',
  selectPlanConfirmation: '/subscribe/confirm',
  subscriptionComplete: '/subscribe/complete',
  freemiumTermsAndConditions: '/subscribe/terms-and-conditions',
  upgrade: '/subscribe/upgrade'
};

export const billingCardScrollObj: ScrollingObj = {
  offset: -50,
  align: 'top',
  duration: 600,
  ease: 'inOutSine'
};

export const freeCardScrollObj: ScrollingObj = {
  offset: -50,
  align: 'middle',
  duration: 600,
  ease: 'inOutSine'
};

export const getBillingPlansUri = '/api/billing/plans';

export const switchPlanRequestUri = `/api/billing/plan`;

export const registerFreemiumRequestUri = '/api/register/freemium';
