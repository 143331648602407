import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { Typography } from '../../../typography/Typography';
import { MatchMedia } from '../../../match-media/MatchMedia';
import { DotIcon } from '../../../icons';
import type { SharedTestingProps } from '../../../../types';
import { Skeleton } from '../../../skeleton/Skeleton';

export const BOX_HEIGHT = 200;

type Props = {
  isVertical: boolean;
  title: string;
  count: number;
  onSelectAll?: () => void;
  onClearAll?: () => void;
  onScroll?: (e: React.UIEvent<HTMLUListElement>) => void;
  showCount?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  isCountLoading?: boolean;
} & SharedTestingProps;

const SelectableListBox: React.FC<Props> = ({
  testId,
  isVertical,
  title,
  count,
  onSelectAll,
  onClearAll,
  onScroll,
  showCount,
  disabled,
  children,
  isCountLoading
}) => {
  const { t } = useTranslation();
  return (
    <Container data-testid={testId} isVertical={isVertical}>
      <Header>
        <Typography variant="subtitle1">{title}</Typography>
        {showCount && (
          <MatchMedia query={'only screen and (min-width: 1024px)'}>
            {isMatch => {
              if (!isMatch) {
                return null;
              }

              return (
                <>
                  <StyledDotIcon size="xs" />
                  {isCountLoading ? (
                    <Skeleton variant="text" width="7ch" />
                  ) : (
                    <Typography variant="normal">
                      {t('selectable-list-box.item_count', { count, ns: 'ed-components' })}
                    </Typography>
                  )}
                </>
              );
            }}
          </MatchMedia>
        )}
        <HeaderOptions>
          {!!onSelectAll && (
            <HeaderOption
              as="div"
              color="textMuted"
              isDisabled={disabled}
              onClick={(!disabled && typeof onSelectAll === 'function' && onSelectAll) || undefined}
            >
              {t('selectable-list-box.select-all', { ns: 'ed-components' })}
            </HeaderOption>
          )}
          {!!onClearAll && (
            <HeaderOption
              as="div"
              color="textMuted"
              isDisabled={disabled}
              onClick={(!disabled && typeof onClearAll === 'function' && onClearAll) || undefined}
            >
              {t('selectable-list-box.clear-all', { ns: 'ed-components' })}
            </HeaderOption>
          )}
        </HeaderOptions>
      </Header>
      <Box onScroll={onScroll} isDisabled={disabled}>
        {children}
      </Box>
    </Container>
  );
};

const Container = styled.div<Pick<Props, 'isVertical'>>(
  ({ isVertical }) => css`
    ${!isVertical && 'min-width: 42%;'}
    ${isVertical && `width: 100%;`}
  display: flex;
    flex-flow: column nowrap;
    flex-basis: 0px;
    flex-grow: 1;
    cursor: default;
    user-select: none;
  `
);

const Header = styled.div`
  padding: ${({ theme }) => theme.space()}px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
`;

const StyledDotIcon = styled(DotIcon)`
  margin: 0 ${({ theme }) => theme.space()}px;
`;

const HeaderOptions = styled.div`
  margin-left: auto;
`;

const HeaderOption = styled(Typography)<{ isDisabled?: boolean }>(
  ({ theme, isDisabled }) => css`
    margin: 0 ${theme.space()}px;
    transition: color ${theme.transition.fast};

    ${!isDisabled &&
    css`
      :hover {
        color: ${theme.colors.text};
        cursor: pointer;
      }
    `}
  `
);

const Box = styled.ul<{ isDisabled?: boolean }>`
  ${({ theme, isDisabled }) => css`
    border: 1px solid ${theme.colors.grey};
    border-radius: ${theme.sizes.borderRadius}px;
    background: ${isDisabled ? theme.colors.lightGrey : theme.colors.white};
    transition: border-color ${theme.transition.slow};
    height: ${BOX_HEIGHT}px;
    list-style: none;
    display: flex;
    flex-flow: column nowrap;
    margin: 0;
    padding: 0;
    box-sizing: content-box;
    overflow: hidden;

    ${!isDisabled &&
    css`
      :hover {
        border-color: ${theme.colors.greyHover};
      }
    `}
  `}
`;

export default SelectableListBox;
