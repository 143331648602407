import * as React from 'react';
import { Typography, Box } from '@edapp/ed-components';

type Props = {
  text?: string;
};

export const NoResults: React.FC<Props> = ({ text = 'No results' }) => (
  <Box flex={true} justifyContent="center" mt="lg">
    <Typography variant="paragraph">{text}</Typography>
  </Box>
);

