import { createSelector } from 'reselect';

import { LessonDiscriminatorEnum } from '@edapp/authoring-logic';

import { initialCourseState, initialMandatoryState } from './constants';
import type { CourseType, LessonItemType } from './types';
import type { StoreState } from './types';
import { getIsMandatory, getIsOptional, getIsRecurring } from './utils';

function getCourse(state: StoreState, courseId: string): CourseType {
  const course = state.course.courses[courseId];
  if (!course) {
    return { ...initialCourseState };
  }

  return { ...course.original };
}

const getCourses = (state: StoreState) => state.course.courses;

const getCourseById = (courseId: string) =>
  createSelector(getCourses, courses => {
    return !!courses[courseId] ? { ...courses[courseId].original } : initialCourseState;
  });

const getIsCoursePublished = (courseId: string) =>
  createSelector(getCourseById(courseId), course => course.display.active);

const getCourseMutateError = (state: StoreState) => state.course.courseMutateError;
const getCourseMutateLoading = (state: StoreState) => state.course.courseMutateLoading;
const getCourseFetchError = (state: StoreState) => state.course.courseFetchError;
const getCourseFetchLoading = (state: StoreState) => state.course.courseFetchLoading;

const getCoursePlanningStartDate = (courseId: string) =>
  createSelector(getCourseById(courseId), (course: CourseType) =>
    !!course.planning && !!course.planning.startDateTime
      ? course.planning?.startDateTime
      : undefined
  );

const getCoursePlanningEndDate = (courseId: string) =>
  createSelector(getCourseById(courseId), (course: CourseType) => course.planning?.endDateTime);

const getCourseMandatory = (courseId: string) =>
  createSelector(
    getCourseById(courseId),
    (course: CourseType) => course.mandatory ?? initialMandatoryState
  );

/**
 * Check if the course is configured as Mandatory
 */
const getIsCourseMandatory = (courseId: string) =>
  createSelector(getCourseById(courseId), (course: CourseType) => {
    const mandatory = course.mandatory ?? initialMandatoryState;
    if (!mandatory.enabled) {
      return false;
    }
    return getIsMandatory(mandatory);
  });

/**
 * Check if the course is configured as Optional
 */
const getIsCourseOptional = (courseId: string) =>
  createSelector(getCourseById(courseId), (course: CourseType) => {
    const mandatory = course.mandatory ?? initialMandatoryState;
    return getIsOptional(mandatory);
  });

/**
 * Check if the course is configured as Recurring
 */
const getIsCourseRecurring = (courseId: string) =>
  createSelector(getCourseById(courseId), (course: CourseType) => {
    const mandatory = course.mandatory ?? initialMandatoryState;

    return getIsRecurring(mandatory);
  });

const getCoursePrerequisites = (courseId: string) =>
  createSelector(getCourseById(courseId), (course: CourseType) => course.prerequisites);

const getCourseUniversalAccess = (courseId: string) =>
  createSelector(getCourseById(courseId), (course: CourseType) => course.universalAccess);

const getCourseUserGroups = (courseId: string) =>
  createSelector(getCourseById(courseId), (course: CourseType) => course.userGroups);

const getCourseLanguage = (courseId: string) =>
  createSelector(
    getCourseById(courseId),
    (course: CourseType) => course.internationalisation.locale
  );

const getStartDate = (courseId: string) =>
  createSelector(getCourseById(courseId), (course: CourseType) => {
    const planningStartDate = course.planning?.startDateTime;
    return planningStartDate ? new Date(planningStartDate) : undefined;
  });

const getEndDate = (courseId: string) =>
  createSelector(getCourseById(courseId), (course: CourseType) => {
    const planningEndDate = course.planning?.endDateTime;
    return planningEndDate ? new Date(planningEndDate) : undefined;
  });

const getMinEndDate = (courseId: string) =>
  createSelector(getCourseById(courseId), (course: CourseType) => {
    return course.planning?.startDateTime;
  });

const getIsNewCourse = (courseId: string) =>
  createSelector(getCourseById(courseId), (course: CourseType) => course.id === 'new');

const getCourseTags = (courseId: string) =>
  createSelector(getCourseById(courseId), (course: CourseType) => course.tags || []);

const getCourseSequentialPlaylistIds = (courseId: string) =>
  createSelector(getCourseById(courseId), (course: CourseType) => course.sequentialPlaylists || []);

const getCourseVisibility = (courseId: string) =>
  createSelector(getCourseById(courseId), (course: CourseType) => course.display.active);

const getCourseStatus = (courseId: string) =>
  createSelector(getCourseById(courseId), (course: CourseType) => course.courseStatus);

const getCourseTitle = (courseId: string) =>
  createSelector(getCourseById(courseId), (course: CourseType) => course.title);

const getFirstLessonInCourse = (courseId: string) =>
  createSelector(getCourseById(courseId), course => course.lessons[0]);

const getFirstLessonTypedLessonInCourse = (courseId: string) =>
  createSelector(getCourseById(courseId), course => course?.lessons?.find(lessonWithLessonType));

const lessonWithLessonType = (lesson: LessonItemType) =>
  lesson.lessonType === LessonDiscriminatorEnum.LESSON;

const getFullFirstLessonInCourse = (courseId: string) => (state: StoreState) => {
  const firstLesson = getFirstLessonInCourse(courseId)(state);
  if (!firstLesson) {
    return;
  }

  const lesson = state.lesson.lessons[firstLesson.id];
  if (!lesson) {
    return;
  }

  return lesson.original;
};

const getCourseCompletionCriteria = (courseId: string) =>
  createSelector(getCourseById(courseId), course => course.completionCriteria);

const getCourseCertificate = (courseId: string) =>
  createSelector(getCourseCompletionCriteria(courseId), criteria => criteria.certificate);

const getIsGroupTrainingEnabled = (courseId: string) =>
  createSelector(getCourseById(courseId), course => course.isGroupTrainingEnabled);

const getIsFromContentLibrary = (courseId: string) =>
  createSelector(getCourseById(courseId), course => course.isFromContentLibrary);

export {
  getCourse,
  getCourseById,
  getIsCoursePublished,
  getCoursePlanningStartDate,
  getCoursePlanningEndDate,
  getCourseMandatory,
  getIsCourseMandatory,
  getIsCourseOptional,
  getIsCourseRecurring,
  getCoursePrerequisites,
  getCourseSequentialPlaylistIds,
  getCourseUniversalAccess,
  getCourseUserGroups,
  getCourseLanguage,
  getStartDate,
  getEndDate,
  getMinEndDate,
  getIsNewCourse,
  getCourseTags,
  getCourseVisibility,
  getCourseStatus,
  getCourseMutateError,
  getCourseMutateLoading,
  getCourseFetchError,
  getCourseFetchLoading,
  getCourseTitle,
  getFirstLessonInCourse,
  getFullFirstLessonInCourse,
  getCourseCompletionCriteria,
  getCourseCertificate,
  getFirstLessonTypedLessonInCourse,
  getIsGroupTrainingEnabled,
  getIsFromContentLibrary
};
