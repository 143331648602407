import styled from 'styled-components';
import { Typography } from '@edapp/ed-components';

export const Description = styled(Typography)`
  display: block;
  margin-bottom: ${({ theme }) => theme.space(2)}px;
`;
export const Required = styled(Typography)`
  margin-left: ${({ theme }) => theme.space()}px;
`;
