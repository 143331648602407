import * as React from 'react';

import styled from 'styled-components';

import type { ColorsKeys } from '@edapp/themes';

import type { Variant as ButtonVariant } from '../../button/types';
import type { IconComponent } from '../../icons';
import { CircleErrorOutline } from '../../icons/CircleErrorOutline';
import { WarningIcon } from '../../icons/WarningIcon';
import type { Props as DialogContentProps } from '../dialog-content/DialogContent';
import { DialogContent } from '../dialog-content/DialogContent';
import { BodyContents, ButtonsFooter } from './components';

export type Props = {
  // Header icon
  headerIcon?: IconComponent;
  headerIconColor?: ColorsKeys;
  // Title text
  title?: string;
  // Description text
  description?: React.ReactNode;
  // Loading status
  loading?: boolean;
  // Error status
  error?: {
    title: string;
    description: React.ReactNode;
  };
  /** Callback triggered on close */
  onClose?: () => void;
  /** Callback triggered on cancel */
  onCancel?: () => void;
  /** Callback triggered on confirmation */
  onConfirm?: () => void;
  /** Additional actions, which can be button components */
  extraActions?: React.ReactElement;
  /** Confirmation button text */
  confirmText?: string;
  /** Cancel button text */
  cancelText?: string;
  /** What's displayed in the dialog body */
  extraBodyContents?: React.ReactElement;
  /** Disable Confirm button */
  disableConfirmButton?: boolean;
  /** Disable Cancel button */
  disableCancelButton?: boolean;
  /** Confirm button styles */
  confirmButtonVariant?: ButtonVariant;
  /** Cancel button styles */
  cancelButtonVariant?: ButtonVariant;
  /**
   * Hide cancel button
   */
  hideCancelButton?: boolean;
  /**
   * Hide confirm button
   */
  hideConfirmButton?: boolean;
} & Omit<DialogContentProps, 'bodyContents'>;

export const ActionDialog: React.FC<Props> = ({
  headerIcon = WarningIcon,
  headerIconColor,
  confirmText,
  cancelText,
  onCancel,
  onConfirm,
  onClose,
  isClosable = true,
  title,
  description,
  loading,
  error,
  extraActions,
  extraBodyContents,
  disableConfirmButton,
  disableCancelButton,
  confirmButtonVariant,
  cancelButtonVariant,
  hideCancelButton,
  hideConfirmButton = false,
  ...rest
}) => {
  return (
    <StyledDialog
      onClose={onClose}
      headerIcon={error ? CircleErrorOutline : headerIcon}
      headerIconColor={error ? 'red' : headerIconColor}
      isClosable={isClosable}
      hideCloseX
      bodyContents={
        <BodyContents
          error={error}
          description={description}
          title={title}
          extraBodyContents={extraBodyContents}
        />
      }
      footer={
        <ButtonsFooter
          isClosable={isClosable}
          error={error}
          loading={loading}
          cancelButtonVariant={cancelButtonVariant}
          confirmButtonVariant={confirmButtonVariant}
          onClose={onClose}
          onCancel={onCancel}
          onConfirm={onConfirm}
          hideCancelButton={hideCancelButton}
          hideConfirmButton={hideConfirmButton}
          disableCancelButton={disableCancelButton}
          disableConfirmButton={disableConfirmButton}
          cancelText={cancelText}
          confirmText={confirmText}
          extraActions={extraActions}
        />
      }
      {...rest}
    />
  );
};

const StyledDialog = styled(DialogContent)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.sm}rem) {
    max-width: 400px;
    border-radius: ${({ theme }) => theme.sizes.borderRadius * 6}px;
  }
`;
