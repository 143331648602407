import { fork, put, race, select, take, takeLatest } from 'redux-saga/effects';

import type {
  Achievement,
  AchievementListItem,
  AchievementRequest,
  ComponentsResponse
} from '@edapp/achievements';
import { AchievementStatus } from '@edapp/achievements';
import { itly } from '@edapp/analytics-tracking';
import { RequestActions } from '@edapp/request';
import type { TranslateResponse } from '@rio/store/languages/types';

import { CustomAchievementsActionTypes, CustomAchievementsActions } from './actions';
import { EMPTY_RECIPE } from './constants';
import { getCustomAchievement } from './selectors';
import type {
  BulkDeleteCustomAchievementAction,
  DeleteCustomAchievementAction,
  FetchCustomAchievementAction,
  FetchCustomAchievementTranslationAction
} from './types';

function* handleFetchCustomAchievement(action: FetchCustomAchievementAction) {
  const { id } = action.payload;

  yield put(
    RequestActions.getAuthed<Achievement>(
      `api/custom-achievements/${id}`,
      CustomAchievementsActions.fetchCustomAchievementSuccess,
      CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_FAILURE
    )
  );
}

function* handleFetchCustomAchievementList() {
  yield put(
    RequestActions.getAuthed<AchievementListItem[]>(
      `api/custom-achievements`,
      CustomAchievementsActions.fetchCustomAchievementsListSuccess,
      CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_LIST_FAILURE
    )
  );
}

function getAchievementRequest(achievement: Achievement): AchievementRequest {
  return {
    id: achievement?.id,
    achievers: achievement.achievers,
    badgeBorderColor: achievement.badgeBorderColor,
    badgeMediaUrl: achievement.badgeMediaUrl,
    colorScheme: achievement.colorScheme,
    completion: achievement.completion,
    description: achievement.description,
    title: achievement.title,
    isPublished: achievement.isPublished,

    localeCode: achievement.defaultLocale ? achievement.defaultLocale.code : null,
    translations:
      achievement.translations?.map(translation => ({
        localeCode: translation.locale.code,
        values: translation.values
      })) ?? null,
    // Recipe can be partial when manipulating it with the recipe builder
    // This just pads it with the possible missing info
    recipes: achievement.recipes.map(recipe => ({ ...EMPTY_RECIPE, ...recipe }))
  };
}

function* handleTrackStatus(achievement: Achievement) {
  if (achievement?.status === AchievementStatus.PUBLISHED) {
    itly.customAchievementLive();
    return;
  }
  if (achievement?.status === AchievementStatus.DRAFT && achievement?.isLocked) {
    itly.customAchievementArchived();
    return;
  }
}

function* handleCreateCustomAchievementSuccess() {
  const achievement = getCustomAchievement(yield select());
  if (!achievement) {
    return;
  }

  // Track Custom Achievement created
  itly.customAchievementCreated({
    features: achievement.recipes.map(recipe => recipe.feature!)
  });
  yield handleTrackStatus(achievement);
}

function* handleSetCustomAchievementSuccess() {
  const achievement = getCustomAchievement(yield select());
  if (!achievement) {
    return;
  }
  yield handleTrackStatus(achievement);
}

function* handleDeleteCustomAchievementSuccess() {
  itly.customAchievementDeleted();
}

function* handleCreateCustomAchievement() {
  const achievement = getCustomAchievement(yield select());
  if (!achievement) {
    return;
  }

  yield put(
    RequestActions.postAuthed<Achievement>(
      `api/custom-achievements`,
      CustomAchievementsActions.createCustomAchievementSuccess,
      CustomAchievementsActionTypes.CREATE_CUSTOM_ACHIEVEMENT_FAILURE,
      false,
      getAchievementRequest(achievement)
    )
  );
}

function* handleSetCustomAchievement() {
  const achievement = getCustomAchievement(yield select());
  if (!achievement) {
    return;
  }

  yield put(
    RequestActions.putAuthed<Achievement>(
      `api/custom-achievements`,
      CustomAchievementsActions.setCustomAchievementSuccess,
      CustomAchievementsActionTypes.SET_CUSTOM_ACHIEVEMENT_FAILURE,
      false,
      getAchievementRequest(achievement)
    )
  );
}

function* handleFetchNewAchievementTranslation(action: FetchCustomAchievementTranslationAction) {
  const { fromLanguage, toLanguage } = action.payload;

  const achievement = getCustomAchievement(yield select());
  let translationResponse: TranslateResponse;
  yield put(
    RequestActions.postAuthed<TranslateResponse>(
      `/api/localization/translate`,
      data => {
        translationResponse = data;
        return {
          type: CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_TRANSLATION_SUCCESS,
          payload: {}
        };
      },
      CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_TRANSLATION_FAILURE,
      false,
      {
        sourceLocale: fromLanguage.code,
        targetLocale: toLanguage.code,
        sourceValues: {
          title: achievement?.title || '',
          description: achievement?.description || '',
          completion: achievement?.completion || ''
        }
      }
    )
  );

  const { success } = yield race({
    success: take(CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_TRANSLATION_SUCCESS),
    failure: take(CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_TRANSLATION_FAILURE)
  });
  if (success) {
    yield put(
      CustomAchievementsActions.addTranslationCustomAchievement(translationResponse!, fromLanguage)
    );
  }
}

function* handleDeleteCustomAchievement(action: DeleteCustomAchievementAction) {
  yield put(
    RequestActions.deleteAuthed(
      `api/custom-achievements/${action.payload.id}`,
      () => CustomAchievementsActions.deleteCustomAchievementSuccess(action.payload.id),
      CustomAchievementsActionTypes.DELETE_CUSTOM_ACHIEVEMENT_FAILURE,
      false,
      undefined
    )
  );
}

function* handleBulkDeleteCustomAchievement(action: BulkDeleteCustomAchievementAction) {
  yield put(
    RequestActions.deleteAuthed(
      `api/custom-achievements`,
      () => CustomAchievementsActions.bulkDeleteCustomAchievementSuccess(action.payload.ids),
      CustomAchievementsActionTypes.DELETE_CUSTOM_ACHIEVEMENT_FAILURE,
      false,
      action.payload.ids
    )
  );
}

function* handleFetchCustomAchievementComponents() {
  yield put(
    RequestActions.getAuthed<ComponentsResponse>(
      `api/custom-achievements/components`,
      data => CustomAchievementsActions.fetchCustomAchievementComponentsSuccess(data),
      CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENTS_COMPONENTS_FAILURE,
      false,
      undefined
    )
  );
}

function* handleHasViewedCustomAchievements() {
  yield put(
    RequestActions.postAuthed(
      `api/users/visit-custom-achievements`,
      CustomAchievementsActionTypes.SET_HAS_VIEWED_CUSTOM_ACHIEVEMENTS_SUCCESS,
      CustomAchievementsActionTypes.SET_HAS_VIEWED_CUSTOM_ACHIEVEMENTS_FAILURE
    )
  );
}

function* watchFetchCustomAchievement() {
  yield takeLatest(
    CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT,
    handleFetchCustomAchievement
  );
}

function* watchFetchCustomAchievementList() {
  yield takeLatest(
    CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_LIST,
    handleFetchCustomAchievementList
  );
}

function* watchCreateCustomAchievement() {
  yield takeLatest(
    CustomAchievementsActionTypes.CREATE_CUSTOM_ACHIEVEMENT,
    handleCreateCustomAchievement
  );
}

function* watchSetCustomAchievement() {
  yield takeLatest(
    CustomAchievementsActionTypes.SET_CUSTOM_ACHIEVEMENT,
    handleSetCustomAchievement
  );
}

function* watchFetchCustomAchievementTranslation() {
  yield takeLatest(
    CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_TRANSLATION,
    handleFetchNewAchievementTranslation
  );
}

function* watchDeleteCustomAchievement() {
  yield takeLatest(
    CustomAchievementsActionTypes.DELETE_CUSTOM_ACHIEVEMENT,
    handleDeleteCustomAchievement
  );
}

function* watchFetchCustomAchievementComponents() {
  yield takeLatest(
    CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENTS_COMPONENTS,
    handleFetchCustomAchievementComponents
  );
}

function* watchCreateCustomAchievementSuccess() {
  yield takeLatest(
    CustomAchievementsActionTypes.CREATE_CUSTOM_ACHIEVEMENT_SUCCESS,
    handleCreateCustomAchievementSuccess
  );
}

function* watchSetCustomAchievementSuccess() {
  yield takeLatest(
    CustomAchievementsActionTypes.SET_CUSTOM_ACHIEVEMENT_SUCCESS,
    handleSetCustomAchievementSuccess
  );
}

function* watchDeleteCustomAchievementSuccess() {
  yield takeLatest(
    CustomAchievementsActionTypes.DELETE_CUSTOM_ACHIEVEMENT_SUCCESS,
    handleDeleteCustomAchievementSuccess
  );
}

function* watchBulkDeleteCustomAchievements() {
  yield takeLatest(
    CustomAchievementsActionTypes.BULK_DELETE_CUSTOM_ACHIEVEMENT,
    handleBulkDeleteCustomAchievement
  );
}

function* watchBulkDeleteCustomAchievementsSuccess() {
  yield takeLatest(
    CustomAchievementsActionTypes.BULK_DELETE_CUSTOM_ACHIEVEMENT_SUCCESS,
    handleDeleteCustomAchievementSuccess
  );
}

function* watchHasViewedCustomAchievements() {
  yield takeLatest(
    CustomAchievementsActionTypes.SET_HAS_VIEWED_CUSTOM_ACHIEVEMENTS,
    handleHasViewedCustomAchievements
  );
}

export const customAchievementsSagas = [
  fork(watchFetchCustomAchievement),
  fork(watchFetchCustomAchievementList),
  fork(watchCreateCustomAchievement),
  fork(watchSetCustomAchievement),
  fork(watchFetchCustomAchievementTranslation),
  fork(watchDeleteCustomAchievement),
  fork(watchFetchCustomAchievementComponents),
  fork(watchCreateCustomAchievementSuccess),
  fork(watchSetCustomAchievementSuccess),
  fork(watchDeleteCustomAchievementSuccess),
  fork(watchBulkDeleteCustomAchievements),
  fork(watchBulkDeleteCustomAchievementsSuccess),
  fork(watchHasViewedCustomAchievements)
];
