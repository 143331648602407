import * as React from 'react';

import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { collapseTransition } from '@edapp/courseware-ui';
import {
  Avatar,
  BaseIcon,
  Box,
  ChevronDownIcon,
  ChevronUpIcon,
  Dropdown,
  Typography,
  useBreakpointMatchMedia
} from '@edapp/ed-components';
import {
  getCurrentUserEmail,
  getUserAppName,
  getUserFirstName,
  getUserLastName,
  isSuperAdmin as superAdminSelector
} from '@rio/store/config/selectors';

import { SwitchAccountModal } from '../switch-account-modal/SwitchAccountModal';
import { AccountDetails } from './AccountDetails';
import { MenuItems } from './MenuItems';

export const ProfileDropdown: React.FC = () => {
  const firstName = useSelector(getUserFirstName);
  const lastName = useSelector(getUserLastName);
  const appName = useSelector(getUserAppName);
  const isSuperAdmin = useSelector(superAdminSelector);
  const email = useSelector(getCurrentUserEmail);
  const name = isSuperAdmin ? appName : !!firstName ? `${firstName} ${lastName}` : email;

  const [isCollapsed, setIsCollapsed] = React.useState(true);
  const [isSwitchAccountOpen, setSwitchAccountOpen] = React.useState(false);

  const toggleMenu = () => setIsCollapsed(current => !current);
  const openSwitchAccount = () => setSwitchAccountOpen(true);
  const closeSwitchAccount = () => setSwitchAccountOpen(false);

  const isMobile = useBreakpointMatchMedia('md', 'max-width');
  if (isMobile) {
    return (
      <Box>
        <Trigger py={2} px={1} flex gap={1} alignItems="center" onClick={toggleMenu}>
          <Avatar alt={name} data-testid="navigation-user-avatar" />
          <Typography>{name}</Typography>
          <StyledIcon as={isCollapsed ? ChevronDownIcon : ChevronUpIcon} size="xs" />
        </Trigger>
        {!isCollapsed && (
          <motion.div
            variants={collapseTransition}
            initial="initial"
            animate="expand"
            exit="collapse"
          >
            <MenuItems openSwitchAccount={openSwitchAccount} />
          </motion.div>
        )}
        <SwitchAccountModal isOpen={isSwitchAccountOpen} onClose={closeSwitchAccount} />
      </Box>
    );
  }
  return (
    <>
      <StyledDropdown
        trigger={
          <Trigger flex gap={1} alignItems="center" onClick={toggleMenu}>
            <Avatar alt={name} data-testid="navigation-user-avatar" />
          </Trigger>
        }
        matchTriggerWidth={false}
        placement="bottom-end"
        closeOnOutsideClick
      >
        <AccountDetails name={name} />
        <MenuItems openSwitchAccount={openSwitchAccount} />
      </StyledDropdown>
      <SwitchAccountModal isOpen={isSwitchAccountOpen} onClose={closeSwitchAccount} />
    </>
  );
};

const StyledDropdown = styled(Dropdown)`
  width: 280px;
  > ul {
    align-items: stretch;
  }
`;

const Trigger = styled(Box)(
  ({ theme }) => css`
    cursor: pointer;
    @media screen and (max-width: ${theme.flexboxgrid.breakpoints.md}rem) {
      :hover {
        background-color: ${theme.colors.lightBlue};
      }
    }
  `
);
Trigger.defaultProps = {
  testId: 'userSettingsDropdown'
};

const StyledIcon = styled(BaseIcon)`
  margin-left: auto;
`;
