import { uniqBy } from 'lodash-es';
import type { Reducer } from 'redux';

import { LessonDiscriminatorEnum } from '@edapp/authoring-logic';
import * as courseActions from '@edapp/courseware-logic/src/course/actions';
import { createMutated, mergeMutated, spreadMutated } from '@edapp/utils';

import * as actions from './actions';
import type { AssessmentState } from './types';

export const initialAssessmentState: AssessmentState = {
  assessmentSummaries: {
    items: [],
    currentPage: 1,
    totalCount: 0,
    isLoading: true,
    error: ''
  },
  assessments: {},
  submissions: {},
  feedback: {},
  comments: {},
  fetchAssessmentLoading: false,
  fetchAssessmentError: '',
  mutateAssessmentLoading: false,
  mutateAssessmentError: '',
  fetchSubmissionLoading: false,
  fetchSubmissionError: '',
  fetchSubmissionFeedbackLoading: false,
  fetchSubmissionFeedbackError: '',
  addSubmissionFeedbackLoading: false,
  addSubmissionFeedbackError: '',
  editSubmissionFeedbackLoading: false,
  editSubmissionFeedbackError: '',
  submissionSummaries: {
    byAssessmentId: {},
    isLoading: true,
    error: ''
  },
  fetchSubmissionCommentsLoading: false,
  fetchSubmissionCommentsError: '',
  deleteSubmissionCommentLoading: false,
  deleteSubmissionCommentError: '',
  addSubmissionCommentLoading: false,
  addSubmissionCommentError: ''
};

export const assessmentReducer: Reducer<AssessmentState> = (
  state = initialAssessmentState,
  action
) => {
  switch (action.type) {
    case actions.FETCH_ASSESSMENT_SUMMARIES: {
      const { page } = action.payload as actions.FetchAssessmentSummariesPayload;

      return {
        ...state,
        assessmentSummaries: {
          ...state.assessmentSummaries,
          items: page === 1 ? [] : state.assessmentSummaries.items,
          isLoading: true,
          error: ''
        }
      };
    }

    case actions.FETCH_ASSESSMENT_SUMMARIES_SUCCESS: {
      const {
        items,
        currentPage,
        totalCount
      } = action.payload as actions.FetchAssessmentSummariesSuccessPayload;
      const currentSummaries = state.assessmentSummaries.items;

      return {
        ...state,
        assessmentSummaries: {
          items: currentPage === 1 ? items : currentSummaries.concat(items),
          isLoading: false,
          error: '',
          currentPage,
          totalCount
        }
      };
    }

    case actions.FETCH_ASSESSMENT_SUMMARIES_FAILURE: {
      const { message } = action.payload as actions.FetchAssessmentSummariesFailurePayload;

      return {
        ...state,
        assessmentSummaries: {
          ...state.assessmentSummaries,
          isLoading: false,
          error: message
        }
      };
    }

    case actions.FETCH_ASSESSMENT: {
      return { ...state, fetchAssessmentLoading: true, fetchAssessmentError: '' };
    }
    case actions.FETCH_ASSESSMENT_SUCCESS: {
      const assessment = action.payload as actions.FetchAssessmentSuccess;
      return {
        ...state,
        fetchAssessmentLoading: false,
        fetchAssessmentError: '',
        assessments: {
          ...state.assessments,
          [assessment.id]: {
            ...createMutated(assessment)
          }
        }
      };
    }
    case actions.FETCH_ASSESSMENT_FAILURE: {
      const { message } = action.payload as actions.FetchAssessmentFailure;
      return { ...state, fetchAssessmentLoading: false, fetchAssessmentError: message };
    }

    case actions.SAVE_ASSESSMENT: {
      const { data, assessmentId } = action.payload as actions.SaveAssessment;

      const currentAssessment = state.assessments[assessmentId];

      if (!currentAssessment) {
        return state;
      }

      return {
        ...state,
        mutateAssessmentLoading: true,
        mutateAssessmentError: '',
        assessments: {
          ...state.assessments,
          [assessmentId]: {
            ...mergeMutated(currentAssessment, data)
          }
        }
      };
    }
    case actions.SAVE_ASSESSMENT_SUCCESS: {
      return { ...state, mutateAssessmentLoading: false, mutateAssessmentError: '' };
    }
    case actions.SAVE_ASSESSMENT_FAILURE: {
      const {
        error,
        assessmentId,
        assessmentStateBeforeSave
      } = action.payload as actions.SaveAssessmentFailure;

      const currentAssessment = state.assessments[assessmentId];

      if (!currentAssessment) {
        return state;
      }

      return {
        ...state,
        mutateAssessmentLoading: false,
        mutateAssessmentError: error.message,
        assessments: {
          ...state.assessments,
          [assessmentId]: {
            ...spreadMutated(currentAssessment, assessmentStateBeforeSave)
          }
        }
      };
    }

    case courseActions.DELETE_LESSON_SUCCESS: {
      const { lessonId, type } = action.payload as courseActions.DeleteLessonSuccess;

      if (type !== LessonDiscriminatorEnum.ASSESSMENT) {
        return state;
      }

      const { [lessonId]: deletedAssessment, ...newAssessments } = state.assessments;

      return {
        ...state,
        assessments: newAssessments
      };
    }

    case actions.FETCH_ASSESSMENT_SUBMISSION_SUMMARIES: {
      const {
        page,
        assessmentId
      } = action.payload as actions.FetchAssessmentSubmissionSummariesPayload;

      const currentSummaries = state.submissionSummaries.byAssessmentId[assessmentId] ?? {
        items: [],
        currentPage: 1,
        totalCount: 0
      };

      return {
        ...state,
        submissionSummaries: {
          ...state.submissionSummaries,
          byAssessmentId: {
            ...state.submissionSummaries.byAssessmentId,
            [assessmentId]: {
              ...currentSummaries,
              items: page === 1 ? [] : currentSummaries.items
            }
          },
          isLoading: true,
          error: ''
        }
      };
    }

    case actions.FETCH_ASSESSMENT_SUBMISSION_SUMMARIES_SUCCESS: {
      const {
        assessmentId,
        items,
        currentPage,
        totalCount
      } = action.payload as actions.FetchAssessmentSubmissionSummariesSuccessPayload;
      const currentSummaries = state.submissionSummaries.byAssessmentId[assessmentId]?.items || [];

      return {
        ...state,
        submissionSummaries: {
          ...state.submissionSummaries,
          byAssessmentId: {
            ...state.submissionSummaries.byAssessmentId,
            [assessmentId]: {
              items: currentPage === 1 ? items : currentSummaries.concat(items),
              currentPage,
              totalCount
            }
          },
          isLoading: false,
          error: ''
        }
      };
    }

    case actions.FETCH_ASSESSMENT_SUBMISSION_SUMMARIES_FAILURE: {
      const {
        message
      } = action.payload as actions.FetchAssessmentSubmissionSummariesFailurePayload;

      return {
        ...state,
        submissionSummaries: {
          ...state.submissionSummaries,
          isLoading: false,
          error: message
        }
      };
    }

    case actions.FETCH_SUBMISSION: {
      return { ...state, fetchSubmissionLoading: true, fetchSubmissionError: '' };
    }
    case actions.FETCH_SUBMISSION_SUCCESS: {
      const submission = action.payload as actions.FetchSubmissionSuccess;
      return {
        ...state,
        fetchSubmissionLoading: false,
        fetchSubmissionError: '',
        submissions: {
          ...state.submissions,
          [submission.id]: submission
        }
      };
    }
    case actions.FETCH_SUBMISSION_FAILURE: {
      const { message } = action.payload as actions.FetchSubmissionFailure;
      return { ...state, fetchSubmissionLoading: false, fetchSubmissionError: message };
    }

    case actions.FETCH_SUBMISSION_FEEDBACK: {
      return { ...state, fetchSubmissionFeedbackLoading: true, fetchSubmissionFeedbackError: '' };
    }
    case actions.FETCH_SUBMISSION_FEEDBACK_SUCCESS: {
      const { submissionId, feedback } = action.payload as actions.FetchSubmissionFeedbackSuccess;
      return {
        ...state,
        fetchSubmissionFeedbackLoading: false,
        fetchSubmissionFeedbackError: '',
        feedback: {
          ...state.feedback,
          [submissionId]: feedback
        }
      };
    }
    case actions.FETCH_SUBMISSION_FEEDBACK_FAILURE: {
      const { message } = action.payload as actions.FetchSubmissionFeedbackFailure;
      return {
        ...state,
        fetchSubmissionFeedbackLoading: false,
        fetchSubmissionFeedbackError: message
      };
    }

    case actions.ADD_SUBMISSION_FEEDBACK: {
      return { ...state, addSubmissionFeedbackLoading: true, addSubmissionFeedbackError: '' };
    }
    case actions.ADD_SUBMISSION_FEEDBACK_SUCCESS: {
      const { submissionId, feedback } = action.payload as actions.AddSubmissionFeedbackSuccess;
      return {
        ...state,
        addSubmissionFeedbackLoading: false,
        addSubmissionFeedbackError: '',
        feedback: {
          ...state.feedback,
          [submissionId]: feedback
        }
      };
    }
    case actions.ADD_SUBMISSION_FEEDBACK_FAILURE: {
      const { message } = action.payload as actions.AddSubmissionFeedbackFailure;
      return {
        ...state,
        addSubmissionFeedbackLoading: false,
        addSubmissionFeedbackError: message
      };
    }

    case actions.EDIT_SUBMISSION_FEEDBACK: {
      return { ...state, editSubmissionFeedbackLoading: true, editSubmissionFeedbackError: '' };
    }
    case actions.EDIT_SUBMISSION_FEEDBACK_SUCCESS: {
      const { submissionId, feedback } = action.payload as actions.EditSubmissionFeedbackSuccess;
      return {
        ...state,
        editSubmissionFeedbackLoading: false,
        editSubmissionFeedbackError: '',
        feedback: {
          ...state.feedback,
          [submissionId]: feedback
        }
      };
    }
    case actions.EDIT_SUBMISSION_FEEDBACK_FAILURE: {
      const { message } = action.payload as actions.EditSubmissionFeedbackFailure;
      return {
        ...state,
        editSubmissionFeedbackLoading: false,
        editSubmissionFeedbackError: message
      };
    }

    case actions.FETCH_SUBMISSION_COMMENTS: {
      return { ...state, fetchSubmissionCommentsLoading: true, fetchSubmissionCommentsError: '' };
    }
    case actions.FETCH_SUBMISSION_COMMENTS_SUCCESS: {
      const {
        totalCount,
        items,
        submissionId,
        page
      } = action.payload as actions.FetchSubmissionCommentsSuccess;
      const currentItems = state.comments[submissionId]?.items || [];
      const reversedItems = items.reverse();
      return {
        ...state,
        fetchPostsLoading: false,
        fetchPostsError: '',
        comments: {
          ...state.comments,
          [submissionId]: {
            totalCount,
            items: page === 1 ? reversedItems : uniqBy(reversedItems.concat(currentItems), 'id'),
            currentPage: page
          }
        }
      };
    }
    case actions.FETCH_SUBMISSION_COMMENTS_FAILURE: {
      const { message } = action.payload as actions.FetchSubmissionCommentsFailure;
      return {
        ...state,
        fetchSubmissionCommentsLoading: false,
        fetchSubmissionCommentsError: message
      };
    }
    case actions.ADD_SUBMISSION_COMMENT: {
      return { ...state, addSubmissionCommentLoading: true, addSubmissionCommentError: '' };
    }
    case actions.ADD_SUBMISSION_COMMENT_SUCCESS: {
      const { submissionId, comment } = action.payload as actions.AddSubmissionCommentSuccess;

      const currentKeyedState = state.comments[submissionId] ?? {
        items: [],
        totalCount: 0,
        currentPage: 1
      };
      const nextKeyedState = {
        items: [...currentKeyedState.items, comment],
        totalCount: currentKeyedState.totalCount + 1,
        currentPage: currentKeyedState.currentPage
      };
      return {
        ...state,
        addSubmissionCommentLoading: false,
        addSubmissionCommentError: '',
        comments: {
          ...state.comments,
          [submissionId]: nextKeyedState
        }
      };
    }
    case actions.ADD_SUBMISSION_COMMENT_FAILURE: {
      const { message } = action.payload as actions.AddSubmissionCommentFailure;
      return { ...state, addSubmissionCommentLoading: false, addSubmissionCommentError: message };
    }

    case actions.DELETE_SUBMISSION_COMMENT: {
      return { ...state, deleteSubmissionCommentLoading: true, deleteSubmissionCommentError: '' };
    }
    case actions.DELETE_SUBMISSION_COMMENT_SUCCESS: {
      const { submissionId, commentId } = action.payload as actions.DeleteSubmissionCommentSuccess;
      const currentKeyedState = state.comments[submissionId] ?? {
        items: [],
        totalCount: 0,
        currentPage: 1
      };
      const nextKeyedState = {
        items: [...currentKeyedState.items.filter(item => item.id !== commentId)],
        totalCount: currentKeyedState.totalCount > 0 ? currentKeyedState.totalCount - 1 : 0,
        currentPage: currentKeyedState.currentPage
      };
      return {
        ...state,
        deleteCommentLoading: false,
        deleteCommentError: '',
        comments: {
          ...state.comments,
          [submissionId]: nextKeyedState
        }
      };
    }
    case actions.DELETE_SUBMISSION_COMMENT_FAILURE: {
      const { message } = action.payload as actions.DeleteSubmissionCommentFailure;
      return {
        ...state,
        deleteSubmissionCommentLoading: false,
        deleteSubmissionCommentError: message
      };
    }

    case actions.EDIT_SUBMISSION_COMMENT: {
      return { ...state, editSubmissionCommentLoading: true, editSubmissionCommentError: '' };
    }
    case actions.EDIT_SUBMISSION_COMMENT_SUCCESS: {
      const { submissionId, comment } = action.payload as actions.EditSubmissionCommentSuccess;

      const currentKeyedState = state.comments[submissionId] ?? {
        items: [],
        totalCount: 0,
        currentPage: 1
      };
      const nextKeyedState = {
        items: currentKeyedState.items.map(item => (item.id === comment.id ? comment : item)),
        totalCount: currentKeyedState.totalCount,
        currentPage: currentKeyedState.currentPage
      };
      return {
        ...state,
        editCommentLoading: false,
        editCommentError: '',
        comments: {
          ...state.comments,
          [submissionId]: nextKeyedState
        }
      };
    }
    case actions.EDIT_SUBMISSION_COMMENT_FAILURE: {
      const { message } = action.payload as actions.EditSubmissionCommentFailure;
      return { ...state, editSubmissionCommentLoading: false, editSubmissionCommentError: message };
    }

    default:
      return state;
  }
};
