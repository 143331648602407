import { RequestUtils } from '@edapp/request';

import type { CatalogDetailsResponse } from './types';

export const getUserGroupCatalogDetails = (
  hippoUrl: string,
  userToken: string,
  userGroupId: string
): Promise<CatalogDetailsResponse> =>
  !userGroupId || userGroupId === 'new'
    ? Promise.resolve([])
    : RequestUtils.httpFetch<CatalogDetailsResponse>(
        'GET',
        `${hippoUrl}/api/usergroups/${userGroupId}/catalog-details`,
        userToken
      );
