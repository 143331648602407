import { LeftSmIcon, Typography } from '@edapp/ed-components';
import * as React from 'react';
import styled from 'styled-components';

type Props = {
  onClick: (index: number) => void;
  viewIndex: number;
};

export const ViewBackButton: React.FC<Props> = ({ onClick, viewIndex }) => (
  <StyledBackButton as="a" href="#" onClick={() => onClick(viewIndex)}>
    <StyledBackIcon size="sm" />
  </StyledBackButton>
);

const StyledBackButton = styled(Typography)`
  line-height: 20px;
  color: ${({ theme }) => theme.colors.textMuted};
  padding-right: ${({ theme }) => `${theme.space()}px`};
`;

const StyledBackIcon = styled(LeftSmIcon)`
  display: inline-block;
  vertical-align: middle;
  > path {
    fill: currentColor;
  }
`;
