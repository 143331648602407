import { LoadingStatus } from './constants';
import type { StoreState } from './types';

export const getLoadingStatus = (state: StoreState, loadingAction: string) => {
  return state.loading[loadingAction];
};

export const isLoading = (state: StoreState, loadingAction: string): boolean => {
  return state.loading[loadingAction] === LoadingStatus.PENDING;
};
