import * as React from 'react';

import styled from 'styled-components';

import { Text } from '@edapp/sc-web-ui';
import type { ColorsKeys } from '@edapp/themes';

import { Box } from '../box/Box';

type Props = {
  backgroundColor?: ColorsKeys;
  color?: ColorsKeys;
};

const DownloadingContainer = styled.span<Props>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  line-height: 1em;
  font-size: 24px;
  border: 1px solid transparent;
  border-radius: 50%;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;

  ${props => props.backgroundColor && `background: ${props.theme.colors[props.backgroundColor]};`};
  ${props => props.color && `color: ${props.theme.colors[props.color]};`}
`;

const DownloadDot = styled.span`
  animation: wave 450ms 150ms infinite alternate ease-in-out;
  display: inline-block;
  width: 3px;
  border-bottom: 3px solid;
  height: 0;
  margin-left: 1px;
  border-radius: 50%;
  background: #fff;
  vertical-align: middle;

  &:first-child {
    animation-delay: 0ms;
    margin-left: 0;
  }

  &:last-child {
    animation-delay: 300ms;
  }

  @keyframes wave {
    from {
      transform: translate3d(0, -50%, 0);
    }
    to {
      transform: translate3d(0, 50%, 0);
    }
  }
`;

const Downloading: React.FC<Props> = props => {
  return (
    <Box flex={true} justifyContent="center">
      <Text variant="headlineSmall">
        <DownloadingContainer {...props}>
          <DownloadDot />
          <DownloadDot />
          <DownloadDot />
        </DownloadingContainer>
      </Text>
    </Box>
  );
};

export { Downloading };
