import * as React from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { BoxedNumberInput } from '@edapp/courseware-ui';
import { Checkbox } from '@edapp/ed-components';
import { UserGroupInviteLink } from '@edapp/invite-users-ui';
import type { LmsStoreState } from '@rio/store/types';
import { setUserGroupItem } from '@rio/store/userGroups/actions';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const UserGroupRegistration: React.FunctionComponent<Props> = props => {
  const { t } = useTranslation();

  return (
    <div className="row">
      <UserGroupInviteLink />

      {!!props.registration.enable_registration_with_invite_code && (
        <div className="padded-form-group">
          <label className="tight">
            <strong>{t('user-groups.registration-limits.title')}</strong>
          </label>
          <p className="help-block">
            <Trans
              i18nKey="user-groups.registration-limits.remaining-user-quota"
              values={{
                approvedUserCount: props.approvedUsersAmount,
                pendingUserCount: props.pendingUsersAmount
              }}
              components={{
                bold: <strong />
              }}
            />
          </p>

          <Checkbox
            checked={props.registration.limits.always_require_verification}
            onChange={e =>
              props.setUserGroupItem({
                usergroup: {
                  registrationSettings: {
                    limits: { always_require_verification: e.target.checked }
                  }
                }
              })
            }
            title={t('user-groups.registration-limits.require-verification.title')}
            description={t('user-groups.registration-limits.require-verification.description')}
          />

          {!props.registration.limits.always_require_verification && (
            <div className="padded-form-group">
              <Checkbox
                checked={props.registration.limits.enabled}
                onChange={e =>
                  props.setUserGroupItem({
                    usergroup: {
                      registrationSettings: {
                        limits: { enabled: e.target.checked }
                      }
                    }
                  })
                }
                title={t('user-groups.registration-limits.require-verification-after-limit')}
              />

              {props.registration.limits.enabled && (
                <>
                  <BoxedNumberInput
                    label={t('user-groups.registration-limits.limit-label')}
                    value={props.registration.limits.max || 0}
                    onChange={newValue => {
                      props.setUserGroupItem({
                        usergroup: {
                          registrationSettings: {
                            limits: { max: +newValue || 0 }
                          }
                        }
                      });
                    }}
                  />
                  <p className="help-block">
                    {t('user-groups.registration-limits.suggest-verification-when-limit-reached')}
                  </p>
                </>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = ({ userGroups: { userGroupItem } }: LmsStoreState) => ({
  registration: userGroupItem.usergroup.registrationSettings,
  approvedUsersAmount: userGroupItem.usergroup.users.approved,
  pendingUsersAmount: userGroupItem.usergroup.users.pending
});

const mapDispatchToProps = (dispatch: any) => ({
  setUserGroupItem: bindActionCreators(setUserGroupItem, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(UserGroupRegistration);
