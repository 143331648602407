import { put, fork, takeLatest } from 'redux-saga/effects';
import { RequestActions } from '@edapp/request';
import type {
  FetchCoursePublicDetailExcludedSlidesAction,
  CreateCoursePublicDetailExcludedSlideAction,
  DeleteCoursePublicDetailExcludedSlideAction
} from './actions';
import { ActionType, actions } from './actions';
import type { CoursePublicDetailExcludedSlide } from './types';

const excludedSlides = (courseId: string, publicDetailId: string) =>
  `/api/courses/${courseId}/public-details/${publicDetailId}/excluded-slides`;
const excludedSlide = (courseId: string, publicDetailId: string, excludedSlideId: string) =>
  `${excludedSlides(courseId, publicDetailId)}/${excludedSlideId}`;

function* handleFetchExcludedSlides({ payload }: FetchCoursePublicDetailExcludedSlidesAction) {
  yield put(
    RequestActions.getAuthed<CoursePublicDetailExcludedSlide[]>(
      excludedSlides(payload.courseId, payload.publicDetailId),
      excludedSlides =>
        actions.fetchCoursePublicDetailExcludedSlidesSuccess({
          publicDetailId: payload.publicDetailId,
          excludedSlides
        }),
      ActionType.FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDES_FAILURE
    )
  );
}

function* handleCreateExcludedSlide({ payload }: CreateCoursePublicDetailExcludedSlideAction) {
  yield put(
    RequestActions.postAuthed(
      excludedSlides(payload.courseId, payload.publicDetailId),
      ActionType.CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_SUCCESS,
      ActionType.CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_FAILURE,
      false,
      payload
    )
  );
}

function* handleDeleteExcludedSlide({ payload }: DeleteCoursePublicDetailExcludedSlideAction) {
  yield put(
    RequestActions.deleteAuthed(
      excludedSlide(payload.courseId, payload.publicDetailId, payload.id),
      () =>
        actions.deleteCoursePublicDetailExcludedSlideSuccess({
          publicDetailId: payload.publicDetailId,
          id: payload.id
        }),
      ActionType.DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_FAILURE
    )
  );
}

function* watchFetchExcludedSlides() {
  yield takeLatest(
    ActionType.FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDES,
    handleFetchExcludedSlides
  );
}

function* watchCreateExcludedSlide() {
  yield takeLatest(
    ActionType.CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE,
    handleCreateExcludedSlide
  );
}

function* watchDeleteExcludedSlide() {
  yield takeLatest(
    ActionType.DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE,
    handleDeleteExcludedSlide
  );
}

export const sagas = [
  fork(watchFetchExcludedSlides),
  fork(watchCreateExcludedSlide),
  fork(watchDeleteExcludedSlide)
];
