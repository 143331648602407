import * as React from 'react';

export type CanvaAPIAccessContextState = {
  apiKey: string;
  apiAccessDisabled: boolean;
};

export const CanvaAPIAccessContext = React.createContext<CanvaAPIAccessContextState | undefined>(
  undefined
);
export const useCanvaAPIAccess = () => {
  const context = React.useContext(CanvaAPIAccessContext);
  if (!context) {
    throw new Error(
      'Usage of "useCanvaAPIAccess" should be within the CanvaAPIAccessContext.Provider component'
    );
  }
  return context;
};
