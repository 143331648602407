import type { DiscussionType } from '@edapp/hippo-client';

export const initialDiscussion: DiscussionType = {
  id: '',
  courseId: '',
  description: '',
  title: '',
  isPublished: false,
  externalId: '',
  prerequisites: [],
  postRequired: false,
  commentRequired: false,
  visibleToAllUsers: true,
  planning: { startDateTime: '', endDateTime: '' }
};

export const initialPaginatedState = {
  items: [],
  totalCount: 0,
  currentPage: 1
};

export const NEW_ID = 'new';
