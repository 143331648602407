import type { RapidRefreshConfigType, RapidRefreshLessonPreviewType } from './types';
import { RapidRefreshStatusEnum } from '@edapp/hippo-client';
import { initialStyleConfiguration } from '@edapp/courseware-logic';

export const newRapidRefresh: Readonly<RapidRefreshConfigType> = {
  id: '',
  lessonId: '',
  leaderboardEnabled: false,
  startDate: new Date().toString(),
  endDate: new Date().toString(),
  frequency: 'once',
  totalSessions: 0,
  totalQuestions: 0,
  language: 'en',
  title: '',
  description: '',
  background: '',
  logo: '',
  thumbnail: '',
  backgroundColour: '',
  textColour: '',
  enableCustomCss: false,
  customCss: '',
  universalAccess: false,
  userGroups: [],
  prerequisites: [],
  status: RapidRefreshStatusEnum.DRAFT,
  retriesEnabled: false
};

export const initialLessonPreview: RapidRefreshLessonPreviewType = {
  id: '',
  slides: [],
  styleConfiguration: { ...initialStyleConfiguration }
};
