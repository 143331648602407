import type { AssessmentType } from '@edapp/hippo-client';

export const initialAssessment: AssessmentType = {
  id: '',
  courseId: '',
  description: '',
  title: '',
  isPublished: false,
  externalId: '',
  prerequisites: [],
  postRequired: false,
  commentRequired: false,
  visibleToAllUsers: true,
  planning: { startDateTime: '', endDateTime: '' },
  minimumScore: 0,
  gradeByPercentage: false
};

export const NEW_ID = 'new';
