import { itly } from '@edapp/analytics-tracking';
import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { routes } from './routes';
import type { UseHistoryListener } from './types';

export const useHistoryListener: UseHistoryListener = () => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(({ hash, pathname }) => {
      const pageName = Object.values(routes).find(({ path }) => path === pathname)?.name;

      if (history.action !== 'POP') {
        itly.page('', pageName ? pageName + (hash ? ` ${hash}` : '') : '');
      }
    });

    return () => {
      unlisten();
    };
  }, [history]);
};

export const useUXPLocationChangedListener: UseHistoryListener = () => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(({ pathname, search }) => {
      if (document.cookie?.indexOf('unified-experience-session') === -1) return;
      window.parent.postMessage(
        JSON.stringify({
          type: 'location_changed',
          path: pathname + search
        }),
        '*'
      );
    });

    return () => {
      unlisten();
    };
  }, [history]);
};
