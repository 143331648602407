import type { ActionWithPayload, ActionsUnion } from '@edapp/utils';
import { createAction } from '@edapp/utils';
import type { CoursePublicDetailRatingLink } from './types';
import type { EdErrorResponseType } from '../../../../types';

export enum ActionType {
  FETCH_COURSE_PUBLIC_DETAIL_RATING_LINKS = 'FETCH_COURSE_PUBLIC_DETAIL_RATING_LINKS',
  FETCH_COURSE_PUBLIC_DETAIL_RATING_LINKS_SUCCESS = 'FETCH_COURSE_PUBLIC_DETAIL_RATING_LINKS_SUCCESS',
  FETCH_COURSE_PUBLIC_DETAIL_RATING_LINKS_FAILURE = 'FETCH_COURSE_PUBLIC_DETAIL_RATING_LINKS_FAILURE',

  CREATE_COURSE_PUBLIC_DETAIL_RATING_LINK = 'CREATE_COURSE_PUBLIC_DETAIL_RATING_LINK',
  CREATE_COURSE_PUBLIC_DETAIL_RATING_LINK_SUCCESS = 'CREATE_COURSE_PUBLIC_DETAIL_RATING_LINK_SUCCESS',
  CREATE_COURSE_PUBLIC_DETAIL_RATING_LINK_FAILURE = 'CREATE_COURSE_PUBLIC_DETAIL_RATING_LINK_FAILURE',

  DELETE_COURSE_PUBLIC_DETAIL_RATING_LINK = 'DELETE_COURSE_PUBLIC_DETAIL_RATING_LINK',
  DELETE_COURSE_PUBLIC_DETAIL_RATING_LINK_SUCCESS = 'DELETE_COURSE_PUBLIC_DETAIL_RATING_LINK_SUCCESS',
  DELETE_COURSE_PUBLIC_DETAIL_RATING_LINK_FAILURE = 'DELETE_COURSE_PUBLIC_DETAIL_RATING_LINK_FAILURE'
}

type FetchCoursePublicDetailRatingLinks = { courseId: string; publicDetailId: string };
export type FetchCoursePublicDetailRatingLinksAction = ActionWithPayload<
  ActionType.FETCH_COURSE_PUBLIC_DETAIL_RATING_LINKS,
  FetchCoursePublicDetailRatingLinks
>;

type FetchCoursePublicDetailRatingLinksSuccess = {
  publicDetailId: string;
  ratingLinks: CoursePublicDetailRatingLink[];
};
export type FetchCoursePublicDetailRatingLinksSuccessAction = ActionWithPayload<
  ActionType.FETCH_COURSE_PUBLIC_DETAIL_RATING_LINKS_SUCCESS,
  FetchCoursePublicDetailRatingLinksSuccess
>;

type FetchCoursePublicDetailRatingLinksFailure = EdErrorResponseType;
export type FetchCoursePublicDetailRatingLinksFailureAction = ActionWithPayload<
  ActionType.FETCH_COURSE_PUBLIC_DETAIL_RATING_LINKS_FAILURE,
  FetchCoursePublicDetailRatingLinksFailure
>;

type CreateCoursePublicDetailRatingLink = {
  courseId: string;
  publicDetailId: string;
  ratingId: string;
};
export type CreateCoursePublicDetailRatingLinkAction = ActionWithPayload<
  ActionType.CREATE_COURSE_PUBLIC_DETAIL_RATING_LINK,
  CreateCoursePublicDetailRatingLink
>;

type CreateCoursePublicDetailRatingLinkSuccess = CoursePublicDetailRatingLink;
export type CreateCoursePublicDetailRatingLinkSuccessAction = ActionWithPayload<
  ActionType.CREATE_COURSE_PUBLIC_DETAIL_RATING_LINK_SUCCESS,
  CreateCoursePublicDetailRatingLinkSuccess
>;

type CreateCoursePublicDetailRatingLinkFailure = EdErrorResponseType;
export type CreateCoursePublicDetailRatingLinkFailureAction = ActionWithPayload<
  ActionType.CREATE_COURSE_PUBLIC_DETAIL_RATING_LINK_FAILURE,
  CreateCoursePublicDetailRatingLinkFailure
>;

type DeleteCoursePublicDetailRatingLink = {
  courseId: string;
  publicDetailId: string;
  ratingLinkId: string;
};
export type DeleteCoursePublicDetailRatingLinkAction = ActionWithPayload<
  ActionType.DELETE_COURSE_PUBLIC_DETAIL_RATING_LINK,
  DeleteCoursePublicDetailRatingLink
>;

type DeleteCoursePublicDetailRatingLinkSuccess = { publicDetailId: string; id: string };
export type DeleteCoursePublicDetailRatingLinkSuccessAction = ActionWithPayload<
  ActionType.DELETE_COURSE_PUBLIC_DETAIL_RATING_LINK_SUCCESS,
  DeleteCoursePublicDetailRatingLinkSuccess
>;

type DeleteCoursePublicDetailRatingLinkFailure = EdErrorResponseType;
export type DeleteCoursePublicDetailRatingLinkFailureAction = ActionWithPayload<
  ActionType.DELETE_COURSE_PUBLIC_DETAIL_RATING_LINK_FAILURE,
  DeleteCoursePublicDetailRatingLinkFailure
>;

export const actions = {
  fetchCoursePublicDetailRatingLinks: (
    payload: FetchCoursePublicDetailRatingLinks
  ): FetchCoursePublicDetailRatingLinksAction =>
    createAction(ActionType.FETCH_COURSE_PUBLIC_DETAIL_RATING_LINKS, payload),
  fetchCoursePublicDetailRatingLinksSuccess: (
    payload: FetchCoursePublicDetailRatingLinksSuccess
  ): FetchCoursePublicDetailRatingLinksSuccessAction =>
    createAction(ActionType.FETCH_COURSE_PUBLIC_DETAIL_RATING_LINKS_SUCCESS, payload),
  fetchCoursePublicDetailRatingLinksFailure: (
    payload: FetchCoursePublicDetailRatingLinksFailure
  ): FetchCoursePublicDetailRatingLinksFailureAction =>
    createAction(ActionType.FETCH_COURSE_PUBLIC_DETAIL_RATING_LINKS_FAILURE, payload),

  createCoursePublicDetailRatingLink: (
    payload: CreateCoursePublicDetailRatingLink
  ): CreateCoursePublicDetailRatingLinkAction =>
    createAction(ActionType.CREATE_COURSE_PUBLIC_DETAIL_RATING_LINK, payload),
  createCoursePublicDetailRatingLinkSuccess: (
    payload: CreateCoursePublicDetailRatingLinkSuccess
  ): CreateCoursePublicDetailRatingLinkSuccessAction =>
    createAction(ActionType.CREATE_COURSE_PUBLIC_DETAIL_RATING_LINK_SUCCESS, payload),
  createCoursePublicDetailRatingLinkFailure: (
    payload: CreateCoursePublicDetailRatingLinkFailure
  ): CreateCoursePublicDetailRatingLinkFailureAction =>
    createAction(ActionType.CREATE_COURSE_PUBLIC_DETAIL_RATING_LINK_FAILURE, payload),

  deleteCoursePublicDetailRatingLink: (
    payload: DeleteCoursePublicDetailRatingLink
  ): DeleteCoursePublicDetailRatingLinkAction =>
    createAction(ActionType.DELETE_COURSE_PUBLIC_DETAIL_RATING_LINK, payload),
  deleteCoursePublicDetailRatingLinkSuccess: (
    payload: DeleteCoursePublicDetailRatingLinkSuccess
  ): DeleteCoursePublicDetailRatingLinkSuccessAction =>
    createAction(ActionType.DELETE_COURSE_PUBLIC_DETAIL_RATING_LINK_SUCCESS, payload),
  deleteCoursePublicDetailRatingLinkFailure: (
    payload: DeleteCoursePublicDetailRatingLinkFailure
  ): DeleteCoursePublicDetailRatingLinkFailureAction =>
    createAction(ActionType.DELETE_COURSE_PUBLIC_DETAIL_RATING_LINK_FAILURE, payload)
};

export type ActionTypes = ActionsUnion<typeof actions>;
