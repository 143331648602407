import * as React from 'react';

import { trim } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled, { css } from 'styled-components';

import { CrossIcon, IconButton } from '@edapp/ed-components';
import type { DropdownOption } from '@rio/components/common/DropdownInput/DropdownInput';
import DropdownInput from '@rio/components/common/DropdownInput/DropdownInput';
import { Arguments, Operators } from '@rio/store/userGroups/conditions/constants';

import MultiPillInput from '../MultiPillInput/MultiPillInput';
import type { ConditionPredicate } from './types';

export const removePredicateId = 'remove-predicate';

export const makeConditionPredicate = (
  targetId: string,
  targetLabel: string,
  args: string[] = [],
  argumentTypeId = Arguments.smallString,
  operatorId = Operators.includes
): ConditionPredicate => ({
  target: { id: targetId, label: targetLabel },
  arguments: args,
  argumentTypeId,
  operatorId
});

type Props = {
  predicate: ConditionPredicate;
  options: ConditionPredicate[];
  onChange: (prevValue: ConditionPredicate, newValue: ConditionPredicate) => void;
  onRemove: (value: ConditionPredicate) => void;
};

export const ConditionPredicateItem: React.FunctionComponent<Props> = ({
  predicate,
  options,
  onChange,
  onRemove
}) => {
  const { t } = useTranslation();
  const handleChangeTargetId = (newId: string) => {
    const option = options.find(opt => opt.target.id === newId);
    const newTargetLabel = option?.target.label ?? newId;
    onChange(predicate, {
      ...predicate,
      target: { id: newId, label: newTargetLabel }
    });
  };

  const handleChangeOperator = (operator: Operators) => {
    onChange(predicate, { ...predicate, operatorId: operator });
  };

  const handleChangeArguments = (value: Array<{ id: string; title: string }>) => {
    if (!value || value.length === 0) return;
    const newArguments = value.map(i => trim(i.title)).filter(i => !!i);
    onChange(predicate, { ...predicate, arguments: newArguments });
  };

  const handleRemove = () => {
    onRemove(predicate);
  };

  const pills = predicate.arguments.map((i, index) => ({
    id: `${index}`,
    title: i
  }));

  const fieldOptions = options.map<DropdownOption>(({ target }) => ({
    label: target.label,
    value: target.id
  }));

  const operatorOptions = Object.keys(Operators).map((operator: Operators) => ({
    label:
      operator === Operators.includes
        ? t('user-groups.custom-fields.operator.matches-exactly')
        : operator === Operators.contains
        ? t('user-groups.custom-fields.operator.contains')
        : operator === Operators.doesNotContain
        ? t('user-groups.custom-fields.operator.does-not-contain')
        : operator === Operators.doesNotMatch
        ? t('user-groups.custom-fields.operator.does-not-match')
        : operator,
    value: operator
  }));

  return (
    <Predicate>
      <Col sm={3}>
        <DropdownInput
          options={fieldOptions}
          value={predicate.target.id}
          onChange={handleChangeTargetId}
        />
      </Col>

      <Col sm={3}>
        <DropdownInput
          options={operatorOptions}
          value={predicate.operatorId}
          onChange={handleChangeOperator}
        />
      </Col>

      <Col sm={5}>
        <MultiPillInput
          pills={pills}
          onChange={handleChangeArguments}
          data-testid="condition-values"
        />
      </Col>

      <Col sm={1}>
        <IconButton testId={removePredicateId} icon={CrossIcon} onClick={handleRemove} />
      </Col>
    </Predicate>
  );
};

const Predicate = styled(Row)(
  ({ theme }) => css`
    margin-top: ${theme.space(1)}px;
    margin-bottom: ${theme.space(1)}px;
  `
);
