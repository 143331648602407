import { differenceInCalendarDays } from 'date-fns';
import { pluralize } from 'humanize-plus';

export function getDaysPassed(startDate: Date, endDate = new Date()): number {
  const diffInDays = (new Date(endDate).valueOf() - new Date(startDate).valueOf()) / 86_400_000;
  return Math.ceil(diffInDays);
}

export function getCalendarDaysWithin(startDate: Date, endDate: Date): number {
  return differenceInCalendarDays(endDate, startDate);
}

export function getDaysRemaining(endDate: Date, startDate = new Date()): string {
  const diffInDays = (new Date(endDate).valueOf() - new Date(startDate).valueOf()) / 86_400_000;
  const diffInDaysRoundedDown = Math.floor(diffInDays);
  const daysRemaining = Math.max(0, diffInDaysRoundedDown);

  return `${daysRemaining} ${pluralize(daysRemaining, 'day')}`;
}
