import styled, { css } from 'styled-components';
import { Typography, Checkbox, Box, ScrollShadowBox } from '@edapp/ed-components';

export const CheckboxLabel = styled(Typography)<{ isDisabled?: boolean }>(
  ({ theme, isDisabled }) => css`
    width: 100%;
    margin-top: ${theme.space(0.5)}px;
    padding-right: ${theme.space(0.5)}px;
    ${isDisabled &&
    css`
      color: ${theme.colors.textMuted};
    `}
  `
);

export const StyledSelectAllCheckbox = styled(Checkbox)`
  margin: 0;
  padding: ${({ theme }) => `${theme.space(0.5)}px ${theme.space(1.5)}px`};
  margin-bottom: ${({ theme }) => theme.space(0.5)}px;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin: 0;
  padding: ${({ theme }) => `${theme.space(1)}px ${theme.space(1.5)}px`};
`;

export const StyledBox = styled(Box)<{ isDisabled?: boolean }>(
  ({ theme, isDisabled }) => css`
    cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
    border-radius: ${theme.sizes.borderRadius * 2}px;
    :hover {
      background-color: ${isDisabled ? theme.colors.transparent : theme.colors.lightBlue};
    }
  `
);

export const ItemListBox = styled(ScrollShadowBox)(
  ({ theme }) => css`
    padding: ${theme.space(1)}px;
    border: 1px solid ${theme.colors.grey};
    border-radius: ${theme.sizes.borderRadius * 2}px;
    height: auto;
    min-height: 210px;
    overflow: auto;
  `
);
