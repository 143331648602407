import styled, { css } from 'styled-components';
import { Typography } from '../../../typography/Typography';

type Props = {
  isDisabled?: boolean;
};

const SelectableListItem = styled(Typography)<Props>(
  ({ theme, isDisabled }) => css`
    color: ${isDisabled ? theme.colors.textMuted : theme.colors.text};
    padding: ${theme.space()}px ${theme.space(2)}px;
    background: ${isDisabled ? theme.colors.lightGrey : theme.colors.white};

    display: flex;
    flex-direction: row;
    align-items: center;

    ${!isDisabled &&
      css`
        :hover {
          background: ${theme.colors.lightBlue};
        }
      `}
  `
);

export default SelectableListItem;
