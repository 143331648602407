import { ContentLibraryTypes } from './types';

export const ContentLibraryConstants: ContentLibraryTypes = {
  TITLE: 'Select courses to import into your account',
  DESCRIPTION:
    'Import courses from our free, world-class library, then edit, rebrand and personalize your courses as you wish.',
  IMPORT_BUTTON_TEXT: 'Import Selected Courses',
  PAGE_INDEX: 1,
  PAGE_SIZE: 999
};

export const CourseLibraryQueryKeys = {
  CL_INDUSTRIES_QUERY_KEY: 'cl-industries',
  CL_SECTIONS_QUERY_KEY: 'cl-sections',
  CL_SECTION_QUERY_KEY: 'cl-section',
  CL_SEARCH_QUERY_KEY: 'cl-search',
  CL_COURSE_QUERY_KEY: 'cl-course',
  CL_SUGGESTIONS_QUERY_KEY: 'cl-suggestions'
};
