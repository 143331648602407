import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { Button, Divider, Downloading, InfoIcon, Tooltip } from '@edapp/ed-components';
import { saveUserGroup } from '@rio/store/userGroups/actions';
import {
  getAllowedToEditStatus,
  getCheckStatusError,
  getConditionsValidity,
  getIsLoadingUserGroup,
  getUserGroupName
} from '@rio/store/userGroups/selectors';

const SaveButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const ErrorIcon = styled(InfoIcon)`
  margin-right: ${({ theme }) => theme.space(1)}px;
`;

type Props = {
  groupId: string;
};

export const SaveUserGroup: React.FC<Props> = ({ groupId }) => {
  const title = useSelector(getUserGroupName);
  const checkStatusError = useSelector(getCheckStatusError);
  const allowedToEditStatus = useSelector(getAllowedToEditStatus);
  const isLoadingUserGroup = useSelector(getIsLoadingUserGroup);
  const conditionsValid = useSelector(getConditionsValidity);

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const handleSaveUserGroup = () => {
    dispatch(saveUserGroup(groupId, history));
  };

  const error = !title
    ? t('user-groups.save-group.missing-title-error')
    : !conditionsValid
    ? t('user-groups.save-group.no-custom-fields-for-dynamic-group')
    : '';

  const handleSave = () => {
    if (!!error) {
      return;
    }

    handleSaveUserGroup();
  };

  const checkStatusDisableSaveBtn =
    isLoadingUserGroup || !!checkStatusError || !allowedToEditStatus;

  return (
    <>
      <Tooltip isOpen={!error ? false : undefined} content={error} placement="bottom">
        <SaveButton
          testId={'user-group-save-button'}
          variant="primary"
          isFullWidth={true}
          onClick={handleSave}
          disabled={!!checkStatusDisableSaveBtn || !!error}
        >
          {!!checkStatusDisableSaveBtn ? (
            <>
              {t('user-groups.save-group.loading')}
              <Downloading />
            </>
          ) : (
            <>
              {!!error && <ErrorIcon />}
              {t('common.save')}
            </>
          )}
        </SaveButton>
      </Tooltip>

      {!!checkStatusError && (
        <>
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          <small>{checkStatusError}</small>
        </>
      )}

      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
    </>
  );
};
