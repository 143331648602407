import * as React from 'react';
import { Form } from 'react-final-form';
import styled, { css } from 'styled-components';

// import { useLogin } from '@rio/api/accounts/authentication';
import { Box, Button, Label, Typography } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';
import { validators } from '@edapp/utils';
import { useLinkAccount } from '@rio/api/accounts/authentication';
import { PasswordFormInput } from '@rio/components/ed/password-input/PasswordFormInput';
import { FormInput } from '@rio/components/form/FormInput';

import type { FormProps } from 'react-final-form';
type Fields = {
  email: string;
  password: string;
};

type Props = {
  onLink: () => void;
};

export const LinkAccountForm: React.FC<Props> = ({ onLink }) => {
  const { mutateAsync: linkAccount } = useLinkAccount();
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const handleSubmit: FormProps<Fields>['onSubmit'] = React.useCallback(async values => {
    try {
      const response = await linkAccount({ login: values.email, password: values.password });
      if (response.success === true) {
        setIsSubmitted(true);
        onLink();
      } else if (response.error_message) {
        throw new Error(
          response.error_message.includes('Invalid username/password') // rename because there is no username here
            ? 'Your password is incorrect.'
            : response.error_message
        );
      } else {
        throw new Error('');
      }
    } catch (err) {
      if (err?.statusCode === 403) {
        return { password: 'Session expired, please refresh the page' };
      }
      return { password: err.message || 'An unexpected error has occurred.' };
    }
  }, []);

  return (
    <StyledContainer>
      <Text variant="titleLarge" component="h2">
        Link an account
      </Text>
      <Typography>Link an EdApp account for easy switching.</Typography>
      <Form<Fields>
        onSubmit={handleSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <StyledForm onSubmit={handleSubmit}>
              <Label htmlFor="email">Email</Label>
              <FormInput
                name="email"
                placeholder="Email"
                isFullWidth
                validate={validators.required}
              />
              <Box mt={2} />
              <Label htmlFor="password">Password</Label>
              <PasswordFormInput
                name="password"
                validate={validators.required}
                placeholder="Password"
                isFullWidth
              />
              <Box flex justifyContent="flex-end" mt={3} alignItems="center">
                <Button
                  variant="primary"
                  size="md"
                  type="submit"
                  isLoading={submitting || isSubmitted}
                >
                  Link account
                </Button>
              </Box>
            </StyledForm>
          );
        }}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  text-align: left;
`;

const StyledForm = styled.form(
  ({ theme }) => css`
    margin-top: ${theme.space(3)}px;
  `
);
