import styled, { css } from 'styled-components';
import { Input } from '../input/Input';
import { Typography } from '../../typography/Typography';
import type { VisibleCheckboxProps, CheckboxLabelProps } from './types';

export const Wrapper = styled.label`
  position: relative;
  margin: 0;
`;

export const HiddenCheckbox = styled(Input).attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const VisibleCheckbox = styled.div<VisibleCheckboxProps>`
  ${({ theme, checked, undetermined, disabled, backgroundColor, hasMargin }) => css`
    color: ${disabled ? theme.colors.grey : theme.colors.white};
    width: ${theme.space(2)}px;
    height: ${theme.space(2)}px;
    box-sizing: border-box;
    border-radius: ${theme.sizes.borderRadius / 2}px;
    background: ${disabled
      ? theme.colors.lightGrey
      : checked || undetermined
      ? !!backgroundColor
        ? theme.colors[backgroundColor]
        : theme.colors.blue
      : theme.colors.white};
    border-color: ${disabled
      ? theme.colors.lightGrey
      : checked || undetermined
      ? !!backgroundColor
        ? theme.colors[backgroundColor]
        : theme.colors.blue
      : theme.colors.grey};
    transition: all ${theme.transition.slow};
    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-style: solid;
    ${hasMargin &&
    css`
      margin-top: 3px;
    `}
  `}
`;

export const CheckboxLabel = styled(Typography)<CheckboxLabelProps>`
  ${({ theme, disabled, isFullWidth }) => css`
    color: ${disabled ? theme.colors.textMuted : theme.colors.text};
    margin-left: ${theme.space(1.5)}px;
    ${!!isFullWidth &&
    css`
      width: 100%;
    `}
    display: flex;
    flex-direction: column;
    user-select: none;
  `}
`;
