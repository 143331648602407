import axios from 'axios';
import * as qs from 'qs';
import { featureFlagsRoute } from '@edapp/feature-flags';
import { ErrorLogger } from '@edapp/monitoring';
import type { FeatureFlagsResponse, APIConfig, Api, FeatureFlagsQueryParams } from './types';
import type { Flags } from './constants';

export function API({ baseUrl, token }: APIConfig): Api {
  const api = axios.create({
    baseURL: baseUrl,
    paramsSerializer: {
      serialize: params => qs.stringify(params, { encode: false })
    },
    headers: {
      Accept: 'application/json',
      ...(!!token ? { token } : undefined)
    }
  });

  async function getFeatureFlags(flags?: Flags[]): Promise<FeatureFlagsResponse> {
    try {
      const { data } = await api.get<FeatureFlagsResponse>(featureFlagsRoute, {
        params: { key: flags } as FeatureFlagsQueryParams
      });
      return data;
    } catch (err) {
      ErrorLogger.captureException(err, { baseUrl, flags });
      return Promise.resolve([]);
    }
  }

  return {
    getFeatureFlags: getFeatureFlags
  };
}
