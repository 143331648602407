import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal, unstable_batchedUpdates } from 'react-dom';
import type {
  CancelDrop,
  CollisionDetection,
  DropAnimation,
  KeyboardCoordinateGetter,
  Modifiers,
  UniqueIdentifier
} from '@dnd-kit/core';
import {
  closestCenter,
  defaultDropAnimation,
  DndContext,
  DragOverlay,
  getFirstCollision,
  MeasuringStrategy,
  pointerWithin,
  rectIntersection
} from '@dnd-kit/core';
import type { AnimateLayoutChanges, SortingStrategy } from '@dnd-kit/sortable';
import {
  arrayMove,
  defaultAnimateLayoutChanges,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import type { ItemProps } from './DefaultItem';
import { DefaultItem } from './DefaultItem';
import type { ContainerProps } from './DefaultContainer';
import { DefaultContainer } from './DefaultContainer';
import { tempIdGenerator } from './utils';
import { useDndSensors } from './hooks';

export type Items = Record<string, string[]>;

const animateLayoutChanges: AnimateLayoutChanges = args =>
  args.isSorting || args.wasDragging ? defaultAnimateLayoutChanges(args) : true;

const dropAnimation: DropAnimation = {
  ...defaultDropAnimation,
  dragSourceOpacity: 0.5
};

type Props = {
  adjustScale?: boolean;
  cancelDrop?: CancelDrop;
  columns?: number;
  coordinateGetter?: KeyboardCoordinateGetter;
  items?: Items;
  initialContainerId?: string;
  renderItem?: ItemProps['renderItem'];
  renderContainer?: ContainerProps['renderContainer'];
  strategy?: SortingStrategy;
  modifiers?: Modifiers;
  scrollable?: boolean;
  vertical?: boolean;
  disabled?: boolean;
  withPlaceholder?: boolean;
  onAddContainer?: (containerId: string, items: Items, containers: string[]) => void;
  onRemoveContainer?: (containerId: string, items: Items, containers: string[]) => void;
  onMove?: (items: Items, containers: string[]) => void;
};

const ADD_CONTAINER_ID = 'placeholder';

/**
 * Multicontainer DnD component
 * @param adjustScale
 * @param cancelDrop
 * @param columns
 * @param disabled
 * @param initialItems
 * @param initialContainerId container where items would revert to when any container is deleted
 * @param coordinateGetter
 * @param modifiers
 * @param renderItem (itemId: string) => React.ReactNode; customise the appearance of your items
 * @param renderContainer (containerId: string) => React.ReactNode; customise the appearance of the container header
 * @param strategy
 * @param vertical
 * @param scrollable
 * @param onAddContainer
 * @param onRemoveContainer
 * @param onMove
 * @constructor
 * @type I item type
 * @type C container type
 */
export function MultipleContainers({
  adjustScale = false,
  cancelDrop,
  disabled,
  items: initialItems,
  modifiers,
  initialContainerId,
  renderItem,
  renderContainer,
  strategy = verticalListSortingStrategy,
  vertical = false,
  scrollable,
  withPlaceholder = true,
  onAddContainer,
  onRemoveContainer,
  onMove
}: Props) {
  const [items, setItems] = useState<Items>(initialItems || {});
  const [containers, setContainers] = useState(Object.keys(items));
  const [activeId, setActiveId] = useState<string | null>(null);
  const lastOverId = useRef<UniqueIdentifier | null>(null);
  const recentlyMovedToNewContainer = useRef(false);
  const recentlyDroppedContainerId = useRef<string>();
  const isSortingContainer = activeId ? containers.includes(activeId) : false;

  /**
   * Custom collision detection strategy optimized for multiple containers
   *
   * - First, find any droppable containers intersecting with the pointer.
   * - If there are none, find intersecting containers with the active draggable.
   * - If there are no intersecting containers, return the last matched intersection
   *
   */
  const collisionDetectionStrategy: CollisionDetection = useCallback(
    args => {
      if (activeId && activeId in items) {
        return closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(container => container.id in items)
        });
      }

      // Start by finding any intersecting droppable
      const pointerIntersections = pointerWithin(args);

      const intersections =
        pointerIntersections.length > 0
          ? // If there are droppables intersecting with the pointer, return those
            pointerIntersections
          : rectIntersection(args);

      const overId = getFirstCollision(intersections, 'id');

      if (overId != null) {
        /* The following lines causes a bug: if a container contains more than one item then it won't be detected somehow */
        // if (overId in items) {
        //   const containerItems = items[overId];

        //   // If a container is matched, and it contains items (columns 'A', 'B', 'C')
        //   if (containerItems.length > 0) {
        //     // Return the closest droppable within that container
        //     overId =
        //       closestCenter({
        //         ...args,
        //         droppableContainers: args.droppableContainers.filter(
        //           container => container.id !== overId && containerItems.includes(container.id)
        //         )
        //       })[0]?.id ?? containerItems?.[containerItems.length - 1];
        //   }
        // }
        lastOverId.current = overId;
        return [{ id: overId }];
      }

      // If pointer goes above the first container, return the first container
      const initialContainer = args.droppableContainers.find(
        container => container.id === initialContainerId
      );
      if (
        vertical &&
        !!args.pointerCoordinates &&
        !!initialContainer?.rect.current &&
        args.pointerCoordinates.y < initialContainer.rect.current.top
      ) {
        return [{ id: initialContainerId! }];
      }

      // When a draggable item moves to a new container, the layout may shift
      // and the `overId` may become `null`. We manually set the cached `lastOverId`
      // to the id of the draggable item that was moved to the new container, otherwise
      // the previous `overId` will be returned which can cause items to incorrectly shift positions
      if (recentlyMovedToNewContainer.current) {
        lastOverId.current = activeId;
      }

      // If no droppable is matched, return the last match
      return lastOverId.current ? [{ id: lastOverId.current }] : [];
    },
    [activeId, items]
  );
  const [clonedItems, setClonedItems] = useState<Items | null>(null);
  const sensors = useDndSensors();

  const findContainer = (id: string) => {
    if (id in items) {
      return id;
    }
    return Object.keys(items).find(key => items[key].includes(id));
  };

  const getIndex = (id: string) => {
    const container = findContainer(id);
    if (!container) {
      return -1;
    }
    return items[container].indexOf(id);
  };

  const onDragCancel = () => {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      setItems(clonedItems);
    }

    setActiveId(null);
    setClonedItems(null);
  };

  useEffect(() => {
    requestAnimationFrame(() => {
      recentlyMovedToNewContainer.current = false;
    });
  }, [items]);

  useEffect(() => {
    if (!initialItems || JSON.stringify(initialItems) === JSON.stringify(items)) return;

    requestAnimationFrame(() => {
      unstable_batchedUpdates(() => {
        setContainers(Object.keys(initialItems));
        setItems(initialItems);
        setActiveId(null);
        setClonedItems(null);
      });
    });
  }, [initialItems]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={collisionDetectionStrategy}
      measuring={{
        droppable: {
          strategy: MeasuringStrategy.Always
        }
      }}
      onDragStart={({ active }) => {
        setActiveId(active.id);
        setClonedItems(items);
        recentlyDroppedContainerId.current = undefined;
      }}
      onDragOver={({ active, over }) => {
        const overId = over?.id;

        if (!overId || active.id in items) {
          return;
        }

        const overContainer = findContainer(overId);
        const activeContainer = findContainer(active.id);

        if (!overContainer || !activeContainer) {
          return;
        }

        if (activeContainer !== overContainer) {
          setItems(items => {
            const activeItems = items[activeContainer];
            const overItems = items[overContainer];
            const overIndex = overItems.indexOf(overId);
            const activeIndex = activeItems.indexOf(active.id);

            let newIndex: number;

            if (overId in items) {
              newIndex = overItems.length + 1;
            } else {
              const isBelowOverItem =
                over &&
                active.rect.current.translated &&
                active.rect.current.translated.top > over.rect.top + over.rect.height;

              const modifier = isBelowOverItem ? 1 : 0;

              newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
            }

            recentlyMovedToNewContainer.current = true;

            return {
              ...items,
              [activeContainer]: items[activeContainer].filter(item => item !== active.id),
              [overContainer]: [
                ...items[overContainer].slice(0, newIndex),
                items[activeContainer][activeIndex],
                ...items[overContainer].slice(newIndex, items[overContainer].length)
              ]
            };
          });
        }
      }}
      onDragEnd={({ active, over }) => {
        const overId = over?.id;
        let newContainers = [...containers];
        if (active.id in items && overId) {
          setContainers(containers => {
            const activeIndex = containers.indexOf(active.id);
            const overIndex = containers.indexOf(overId);
            const reorderedContainers = arrayMove(containers, activeIndex, overIndex);
            newContainers = [...reorderedContainers];
            return reorderedContainers;
          });
        }

        const activeContainer = findContainer(active.id);

        if (!activeContainer) {
          setActiveId(null);
          return;
        }

        if (!overId) {
          setActiveId(null);
          return;
        }

        if (overId === ADD_CONTAINER_ID) {
          const newContainerId = getNextContainerId();

          unstable_batchedUpdates(() => {
            setContainers(containers => [...containers, newContainerId]);
            setItems(items => ({
              ...items,
              [activeContainer]: items[activeContainer].filter(id => id !== activeId),
              [newContainerId]: [active.id]
            }));
            onAddContainer?.(
              newContainerId,
              {
                ...items,
                [activeContainer]: items[activeContainer].filter(id => id !== activeId),
                [newContainerId]: [active.id]
              },
              [...containers, newContainerId]
            );
            setActiveId(null);
          });
          return;
        }

        const overContainer = findContainer(overId);

        if (overContainer) {
          recentlyDroppedContainerId.current = overContainer;
          const activeIndex = items[activeContainer].indexOf(active.id);
          const overIndex = items[overContainer].indexOf(overId);

          if (activeIndex !== overIndex) {
            setItems(items => ({
              ...items,
              [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex)
            }));
          }
          onMove?.(
            {
              ...items,
              [overContainer]: arrayMove(items[overContainer], activeIndex, overIndex)
            },
            newContainers
          );
        }
        setActiveId(null);
      }}
      cancelDrop={cancelDrop}
      onDragCancel={onDragCancel}
      modifiers={modifiers}
    >
      <SortableContext
        items={[...containers, ADD_CONTAINER_ID]}
        strategy={vertical ? verticalListSortingStrategy : horizontalListSortingStrategy}
      >
        {containers.map(containerId => (
          <DroppableContainer
            key={containerId}
            containerId={containerId}
            initialContainerId={initialContainerId}
            label={`Column ${containerId}`}
            items={items[containerId]}
            disabled={disabled}
            scrollable={scrollable}
            isItemDropped={recentlyDroppedContainerId.current === containerId}
            renderContainer={renderContainer}
            onRemove={() => handleRemove(containerId)}
          >
            <SortableContext items={items[containerId]} strategy={strategy}>
              {items[containerId].map((itemId, index) => {
                return (
                  <SortableItem
                    disabled={disabled || isSortingContainer}
                    key={itemId}
                    itemId={itemId}
                    index={index}
                    renderItem={renderItem}
                    containerId={containerId}
                    getIndex={getIndex}
                  />
                );
              })}
            </SortableContext>
          </DroppableContainer>
        ))}
        {withPlaceholder && (
          <DroppableContainer
            containerId={ADD_CONTAINER_ID}
            disabled={disabled || isSortingContainer}
            initialContainerId={initialContainerId}
            renderContainer={renderContainer}
            items={[]}
            onClick={handleAddColumn}
            placeholder
          >
            + Add column
          </DroppableContainer>
        )}
      </SortableContext>
      {createPortal(
        <DragOverlay adjustScale={adjustScale} dropAnimation={dropAnimation} zIndex={9999}>
          {!disabled && activeId
            ? containers.includes(activeId)
              ? renderContainerDragOverlay(activeId)
              : renderSortableItemDragOverlay(activeId)
            : null}
        </DragOverlay>,
        document.body
      )}
    </DndContext>
  );

  function renderSortableItemDragOverlay(id: string) {
    return <DefaultItem itemId={id} renderItem={renderItem} transform={null} dragOverlay />;
  }

  function renderContainerDragOverlay(containerId: string) {
    return (
      <DefaultContainer
        containerId={containerId}
        label={`Column ${containerId}`}
        dragOverlay
        renderContainer={renderContainer}
        items={items[containerId]}
      >
        {items[containerId].map(itemId => (
          <DefaultItem key={itemId} itemId={itemId} transform={null} renderItem={renderItem} />
        ))}
      </DefaultContainer>
    );
  }

  function handleRemove(containerID: UniqueIdentifier) {
    unstable_batchedUpdates(() => {
      setContainers(containers => containers.filter(id => id !== containerID));
      if (initialContainerId) {
        // Move all the items from the removed container to the initial container
        // return the new list of items
        setItems(items => {
          const newItems = {
            ...items,
            [initialContainerId]: [...items[initialContainerId], ...items[containerID]]
          };
          delete newItems[containerID];
          return newItems;
        });

        const newItems = {
          ...items,
          [initialContainerId]: [...items[initialContainerId], ...items[containerID]]
        };
        delete newItems[containerID];
        onRemoveContainer?.(containerID, newItems, [
          ...containers.filter(id => id !== containerID)
        ]);
      }
    });
  }

  function handleAddColumn() {
    if (disabled) return;
    const newContainerId = getNextContainerId();
    unstable_batchedUpdates(() => {
      setContainers(containers => [...containers, newContainerId]);
      setItems(items => ({
        ...items,
        [newContainerId]: []
      }));
      onAddContainer?.(
        newContainerId,
        {
          ...items,
          [newContainerId]: []
        },
        [...containers, newContainerId]
      );
    });
  }

  function getNextContainerId() {
    return tempIdGenerator('temp-container');
  }
}

type SortableItemProps = {
  containerId: string;
  itemId: string;
  index: number;
  disabled?: boolean;
  getIndex(id: string): number;
  renderItem: ItemProps['renderItem'];
};

function SortableItem({ disabled, itemId, index, renderItem }: SortableItemProps) {
  const {
    setNodeRef,
    listeners,
    attributes,
    isDragging,
    isSorting,
    transform,
    transition
  } = useSortable({
    id: itemId
  });
  const mounted = useMountStatus();
  const mountedWhileDragging = isDragging && !mounted;

  return (
    <DefaultItem
      ref={disabled ? undefined : setNodeRef}
      itemId={itemId}
      dragging={isDragging}
      disabled={disabled}
      sorting={isSorting}
      index={index}
      transition={transition}
      transform={transform}
      fadeIn={mountedWhileDragging}
      listeners={listeners}
      attributes={attributes}
      renderItem={renderItem}
    />
  );
}

function DroppableContainer({
  children,
  disabled,
  containerId,
  initialContainerId,
  items,
  ...props
}: ContainerProps & {
  disabled?: boolean;
  initialContainerId?: string;
}) {
  const {
    active,
    attributes,
    listeners,
    over,
    setNodeRef,
    transition,
    transform,
    isDragging
  } = useSortable({
    id: containerId,
    data: {
      type: 'container',
      children: items
    },
    animateLayoutChanges
  });

  const isOverContainer = over
    ? (containerId === over.id && active?.data.current?.type !== 'container') ||
      items.includes(over.id)
    : false;
  const isContainerOverInitial =
    active?.data.current?.type === 'container' && containerId === initialContainerId;
  const ref = disabled || isContainerOverInitial ? undefined : setNodeRef;

  return (
    <DefaultContainer
      ref={ref}
      containerId={containerId}
      hover={isOverContainer}
      dragging={isDragging}
      items={items}
      style={{
        transition,
        transform: CSS.Translate.toString(transform)
      }}
      handleProps={{
        ...attributes,
        ...listeners
      }}
      {...props}
    >
      {children}
    </DefaultContainer>
  );
}

function useMountStatus() {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 500);
    return () => clearTimeout(timeout);
  }, []);
  return isMounted;
}
