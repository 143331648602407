import { pluralize } from 'humanize-plus';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled, { css } from 'styled-components';

import {
    Box, Button, CircleTickIcon, Loading, SearchInput, Typography
} from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';

import type { CourseDialogHeaderProps } from './types';

const getSelectedText = (selectedCount: number, selectionLimit?: number) =>
  `${selectedCount}${!!selectionLimit ? `/${selectionLimit}` : ''} ${pluralize(
    selectedCount,
    'course'
  )} selected`;

export const CoursePickerDialogHeader: React.FC<CourseDialogHeaderProps> = ({
  showSelectedCount = true,
  title,
  description,
  buttonContents,
  selectedCount,
  selectionLimit: selectedLimit,
  isLoading,
  onClickButton,
  onSearch
}) => {
  const { t } = useTranslation();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => onSearch(e.currentTarget.value);
  const onClear = () => onSearch('');
  return (
    <StyledCoursePickerHeader pt="lg" pb="sm">
      <Row middle="xs">
        <Col xs={12} md={9}>
          <Text variant="titleLarge" component="h2">
            {title}
          </Text>

          {description && (
            <StyledParagraph variant="normal" as="p" color="textMuted">
              {description}
            </StyledParagraph>
          )}

          <Row>
            <Col xs={12} sm={12} md={7}>
              <StyledSearchInput
                isFullWidth={true}
                placeholder={t('common.search-placeholder')}
                disabled={!!isLoading}
                onChange={onChange}
                onClear={onClear}
              />
            </Col>
          </Row>
        </Col>

        <StyledCol xs={12} md={3}>
          <StyledRow end="xs">
            <Col xs={6} sm={4} md={11}>
              {isLoading && <StyledLoading />}

              {!isLoading && (
                <Button
                  onClick={onClickButton}
                  variant="primary"
                  size="lg"
                  disabled={!selectedCount}
                >
                  {buttonContents}
                </Button>
              )}
            </Col>
          </StyledRow>

          {showSelectedCount && (
            <StyledSelectCount>
              <StyledCircleTickIcon size="md" selectedCount={selectedCount} />
              <Typography variant="subtitle2" as="span" color="textMuted">
                {getSelectedText(selectedCount, selectedLimit)}
              </Typography>
            </StyledSelectCount>
          )}
        </StyledCol>
      </Row>
    </StyledCoursePickerHeader>
  );
};

export const StyledCoursePickerHeader = styled(Box)`
  padding-left: ${({ theme }) => theme.space(6)}px;
  padding-right: ${({ theme }) => theme.space(6)}px;
  box-shadow: ${({ theme }) => theme.shadows.shallow};
  flex: 1 0 auto;
  z-index: 1;
`;

const StyledCol = styled(Col)`
  display: flex !important;
  flex-direction: column;
  align-items: flex-end;
`;

const StyledRow = styled(Row)`
  width: 100%;
`;

const StyledParagraph = styled(Typography)`
  margin-top: 0;
`;

const StyledSearchInput = styled(SearchInput)`
  margin-top: ${({ theme }) => theme.space()}px;
  box-sizing: border-box;
`;

const StyledLoading = styled(Loading)`
  display: flex;
  justify-content: center;
  align-self: center;
  padding: 4px;
`;

const StyledSelectCount = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${({ theme }) => theme.space(2)}px;
  padding-right: ${({ theme }) => theme.space(3)}px;
  text-transform: uppercase;
`;

const StyledCircleTickIcon = styled(CircleTickIcon)<Pick<CourseDialogHeaderProps, 'selectedCount'>>`
  ${({ theme, selectedCount }) => css`
    margin-right: ${theme.space()}px;
    width: ${theme.space(2)}px;
    height: ${theme.space(2)}px;
    color: ${!!selectedCount ? theme.colors.blue : theme.colors.textMuted};
  `}
`;
