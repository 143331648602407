import { fork, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import type { CourseSummaryType } from '@edapp/courseware-logic';
import { RequestActions } from '@edapp/request';
import { routes } from '@rio/router';
import type { LmsStoreState } from '@rio/store/types';

import * as actions from './actions';
import { initialPlaylist } from './constants';
import { completionText as defaultCompletionText } from './constants';
import { getPlaylist, getPlaylistCourses } from './selectors';
import type { PlaylistItemType } from './types';

function* handleCreatePlaylist(action: Action<actions.CreatePlaylist>) {
  const { payload } = action;
  const courses: CourseSummaryType[] = yield select((s: LmsStoreState) =>
    getPlaylistCourses(s, 'new')
  );

  const {
    defaultLocale,
    translations,
    ...playlistData
  }: Partial<PlaylistItemType> = payload.playlist;

  yield put(
    RequestActions.postAuthed(
      `api/playlists`,
      actions.CREATE_PLAYLIST_SUCCESS,
      actions.CREATE_PLAYLIST_FAILURE,
      false,
      {
        ...initialPlaylist,
        ...playlistData,
        localeCode: defaultLocale?.code,
        translations: translations?.map(translation => ({
          values: translation.values,
          localeCode: translation.locale.code
        })),
        courses: courses.map(course => course.id)
      }
    )
  );

  const { success, failure } = yield race({
    success: take(actions.CREATE_PLAYLIST_SUCCESS),
    failure: take(actions.CREATE_PLAYLIST_FAILURE)
  });

  if (failure) {
    // It's being handled in the reducer
    return;
  }
  const id = (success as Action<actions.CreatePlaylistSuccess>).payload;

  payload.history.replace(routes.path.getRoute({ playlistId: id }));
}

function* handleSavePlaylist(action: Action<actions.SavePlaylist>) {
  const { payload } = action;

  const courses: CourseSummaryType[] = yield select((s: LmsStoreState) =>
    getPlaylistCourses(s, payload.playlistId)
  );

  const {
    defaultLocale,
    translations,
    ...playlistData
  }: PlaylistItemType = yield select((s: LmsStoreState) => getPlaylist(s, payload.playlistId));

  yield put(
    RequestActions.putAuthed(
      `api/playlists/${payload.playlistId}`,
      actions.SAVE_PLAYLIST_SUCCESS,
      actions.SAVE_PLAYLIST_FAILURE,
      false,
      {
        ...playlistData,
        localeCode: defaultLocale?.code,
        translations: translations?.map(translation => ({
          values: translation.values,
          localeCode: translation.locale.code
        })),
        courses: courses.map(course => course.id)
      }
    )
  );

  const { success } = yield race({
    success: take(actions.SAVE_PLAYLIST_SUCCESS),
    failure: take(actions.SAVE_PLAYLIST_FAILURE)
  });

  if (success) {
    itly.changePlaylistType({
      playlist_id: playlistData.id,
      playlist_type: playlistData.playlistType
    });
  }
}

function* handleRemovePlaylist(action: Action<actions.RemovePlaylist>) {
  const { payload } = action;
  yield put(
    RequestActions.deleteAuthed(
      `/api/playlists/${payload.playlistId}`,
      actions.REMOVE_PLAYLIST_SUCCESS,
      () => ({
        type: actions.REMOVE_PLAYLIST_FAILURE,
        payload: {
          type: 'Error',
          message: `Something went wrong while deleting that playlist. Please refresh your browser or click here!`
        }
      })
    )
  );
}

function* handleFetchPlaylists(action: Action<actions.FetchPlaylists>) {
  const { page, pageSize, searchTerm } = action.payload;

  yield put(
    RequestActions.getAuthed(
      `api/playlists`,
      data => ({
        type: actions.FETCH_PLAYLISTS_SUCCESS,
        payload: {
          ...data,
          page
        }
      }),
      actions.FETCH_PLAYLISTS_FAILURE,
      false,
      { Page: page, PageSize: pageSize, SearchTerm: searchTerm }
    )
  );
}

function* handleFetchPlaylistItem(action: Action<actions.FetchPlaylistItem>) {
  const { payload } = action;
  yield put(
    RequestActions.getAuthed(
      `api/playlists/${payload.playlistId}`,
      actions.FETCH_PLAYLIST_ITEM_SUCCESS,
      actions.FETCH_PLAYLIST_ITEM_FAILURE,
      false
    )
  );
}

function* handleFetchPlaylistCourses(action: Action<actions.FetchPlaylistCourses>) {
  const { payload } = action;
  yield put(
    RequestActions.getAuthed(
      `api/playlists/${payload.playlistId}/courses`,
      data => ({
        type: actions.FETCH_PLAYLIST_COURSES_SUCCESS,
        payload: { courses: data, playlistId: payload.playlistId }
      }),
      actions.FETCH_PLAYLIST_COURSES_FAILURE,
      false
    )
  );
}

function* handleFetchNewPlaylistTranslation(action: Action<actions.PlaylistFetchNewTranslation>) {
  const { playlistId, fromLanguage, toLanguage } = action.payload;

  const playlist: PlaylistItemType = yield select((s: LmsStoreState) => getPlaylist(s, playlistId));

  yield put(
    RequestActions.postAuthed(
      `/api/localization/translate`,
      actions.PLAYLIST_FETCH_NEW_TRANSLATION_SUCCESS,
      actions.PLAYLIST_FETCH_NEW_TRANSLATION_FAILURE,
      false,
      {
        sourceLocale: fromLanguage.code,
        targetLocale: toLanguage.code,
        sourceValues: {
          title: playlist.title || '',
          description: playlist.description || '',
          completionText: playlist.completionText || defaultCompletionText
        }
      }
    )
  );

  const { success, failure } = yield race({
    success: take(actions.PLAYLIST_FETCH_NEW_TRANSLATION_SUCCESS),
    failure: take(actions.PLAYLIST_FETCH_NEW_TRANSLATION_FAILURE)
  });

  if (failure) {
    return;
  }

  if (success) {
    yield put({
      type: actions.ADD_PLAYLIST_TRANSLATION,
      payload: {
        id: playlistId,
        translation: success.payload
      }
    });

    yield put({
      type: actions.SET_PLAYLIST,
      payload: {
        id: playlistId,
        playlist: { defaultLocale: fromLanguage }
      }
    });
  }
}

function* handleChangePlaylistLexoRank(action: Action<actions.PlaylistListChangeLexoRank>) {
  const { playlistId, prevId, nextId } = action.payload;

  yield put(
    RequestActions.putAuthed(
      `/api/playlists/${playlistId}/rank`,
      actions.PLAYLIST_LIST_CHANGE_LEXO_RANK_SUCCESS,
      actions.PLAYLIST_LIST_CHANGE_LEXO_RANK_FAILURE,
      false,
      {
        previousPlaylistId: prevId,
        nextPlaylistId: nextId
      }
    )
  );
}

function* watchChangePlaylistLexoRank() {
  yield takeLatest(actions.PLAYLIST_LIST_CHANGE_LEXO_RANK, handleChangePlaylistLexoRank);
}

function* watchCreatePlaylist() {
  yield takeLatest(actions.CREATE_PLAYLIST, handleCreatePlaylist);
}

function* watchSavePlaylist() {
  yield takeLatest(actions.SAVE_PLAYLIST, handleSavePlaylist);
}

function* watchRemovePlaylist() {
  yield takeLatest(actions.REMOVE_PLAYLIST, handleRemovePlaylist);
}

function* watchFetchPlaylists() {
  yield takeLatest(actions.FETCH_PLAYLISTS, handleFetchPlaylists);
}

function* watchFetchPlaylistItems() {
  yield takeLatest(actions.FETCH_PLAYLIST_ITEM, handleFetchPlaylistItem);
}

function* watchFetchPlaylistCourses() {
  yield takeLatest(actions.FETCH_PLAYLIST_COURSES, handleFetchPlaylistCourses);
}

function* watchFetchNewPlaylistTranslation() {
  yield takeLatest(actions.PLAYLIST_FETCH_NEW_TRANSLATION, handleFetchNewPlaylistTranslation);
}

const sagas = [
  fork(watchCreatePlaylist),
  fork(watchSavePlaylist),
  fork(watchRemovePlaylist),
  fork(watchFetchPlaylists),
  fork(watchFetchPlaylistItems),
  fork(watchFetchPlaylistCourses),
  fork(watchFetchNewPlaylistTranslation),
  fork(watchChangePlaylistLexoRank)
];

export default sagas;
