import * as React from 'react';
import { WarningIcon } from '../../icons';
import { ActionDialog } from '../action/ActionDialog';

export type Props = {
  title: string;
} & React.ComponentProps<typeof ActionDialog>;

const WarningDialog: React.FC<Props> = props => {
  return (
    <ActionDialog
      headerIcon={WarningIcon}
      headerIconColor="orange"
      confirmButtonVariant="warning"
      {...props}
    />
  );
};

export { WarningDialog };
