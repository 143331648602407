import * as React from 'react';
import styled from 'styled-components';
import { ChaptersIcon, GridIcon, DocumentIcon, Typography } from '@edapp/ed-components';
import { LessonDiscriminatorEnum } from '@edapp/authoring-logic';
import { getLessonTypeIcon, getLessonTypeText } from '../../utils/lesson';
import type { IconComponent } from '@edapp/ed-components';

type CardTypes = 'SEQUENCED' | 'FREEFORM' | LessonDiscriminatorEnum;

type Props = {
  type?: CardTypes;
};

const getCardIcon = (type: CardTypes): IconComponent => {
  switch (type) {
    case 'SEQUENCED':
      return ChaptersIcon;
    case 'FREEFORM':
      return GridIcon;
    case LessonDiscriminatorEnum.LESSON:
    case LessonDiscriminatorEnum.DISCUSSION:
    case LessonDiscriminatorEnum.ASSESSMENT:
    case LessonDiscriminatorEnum.OBSERVATION:
    case LessonDiscriminatorEnum.VIRTUAL_CLASSROOM:
      return getLessonTypeIcon(type);
    default:
      return DocumentIcon;
  }
};

const getCardLabel = (type: CardTypes) => {
  switch (type) {
    case 'SEQUENCED':
      return 'Sequenced Playlist';
    case 'FREEFORM':
      return 'Freeform Playlist';
    case LessonDiscriminatorEnum.LESSON:
    case LessonDiscriminatorEnum.DISCUSSION:
    case LessonDiscriminatorEnum.ASSESSMENT:
    case LessonDiscriminatorEnum.OBSERVATION:
    case LessonDiscriminatorEnum.VIRTUAL_CLASSROOM:
      return getLessonTypeText(type);
    default:
      return '';
  }
};

export const SummaryCardTypeText: React.FC<Props> = ({ type }) => {
  if (!type) {
    return null;
  }

  const Icon = getCardIcon(type);

  return (
    <Type>
      <Icon size="sm" color="textMuted" />
      <TypeLabel variant="subtext" color="textMuted">
        {getCardLabel(type)}
      </TypeLabel>
    </Type>
  );
};

const Type = styled.div`
  display: flex;
  align-items: center;
`;

const TypeLabel = styled(Typography)`
  margin-left: ${({ theme }) => theme.space(0.5)}px;
`;
