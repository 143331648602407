export enum RapidRefreshSortEnum {
  CREATED = 'created',
  MODIFIED = 'modified',
  SCHEDULED = 'scheduled',
  ALPHABETICALLY = 'alphabetically'
}

export type RapidRefreshSortType = {
  by: RapidRefreshSortEnum;
  direction: 'asc' | 'desc';
};

export enum RapidRefreshStatusEnum {
  ALL = 'all',
  DRAFT = 'draft',
  SCHEDULED = 'scheduled',
  IN_PROGRESS = 'in-progress',
  ENDED = 'ended'
}

export type RapidRefreshFilterType = Partial<{
  searchTerm: string;
  status: RapidRefreshStatusEnum;
}>;
