import {
  FETCH_PEER_AUTHORING_LESSONS,
  FETCH_PEER_AUTHORING_LESSONS_SUCCESS,
  FETCH_SLIDE_CONTRIBUTIONS_SUCCESS,
  FETCH_SLIDE_CONTRIBUTIONS,
  REMOVE_SLIDE_CONTRIBUTION,
  REMOVE_CONTRIBUTION_COMMENT
} from './actions';
import type { PeerAuthoringState } from './types';

export const initialPeerAuthoringState: PeerAuthoringState = {
  lessons: [],
  currentSlideId: null,
  currentSlideLessonId: null,
  currentSlideContributions: []
};

export const peerAuthoringReducer: Reducer<PeerAuthoringState> = (
  state = initialPeerAuthoringState,
  action
): PeerAuthoringState => {
  switch (action.type) {
    case FETCH_PEER_AUTHORING_LESSONS: {
      return initialPeerAuthoringState;
    }
    case FETCH_PEER_AUTHORING_LESSONS_SUCCESS: {
      return {
        ...state,
        lessons: action.payload.items
      };
    }
    case FETCH_SLIDE_CONTRIBUTIONS: {
      return {
        ...state,
        currentSlideContributions: [],
        currentSlideId: action.payload.slideId,
        currentSlideLessonId: action.payload.lessonId
      };
    }
    case FETCH_SLIDE_CONTRIBUTIONS_SUCCESS: {
      return {
        ...state,
        currentSlideContributions: action.payload.items
      };
    }
    case REMOVE_SLIDE_CONTRIBUTION: {
      const { currentSlideContributions, currentSlideId, currentSlideLessonId } = state;
      const { lessonId, slideId, contributionId } = action.payload;

      if (lessonId !== currentSlideLessonId || slideId !== currentSlideId) {
        return state;
      }

      const updatedContributions = currentSlideContributions.filter(
        contribution => contribution.id !== contributionId
      );

      return {
        ...state,
        currentSlideContributions: updatedContributions
      };
    }
    case REMOVE_CONTRIBUTION_COMMENT: {
      const { lessonId, slideId, contributionId, commentId } = action.payload;
      const { currentSlideContributions } = state;

      const indexOfCommentContribution = currentSlideContributions.findIndex(
        contribution =>
          contribution.id === contributionId &&
          contribution.lessonId === lessonId &&
          contribution.slideId === slideId
      );

      const commentContribution = currentSlideContributions[indexOfCommentContribution];
      if (
        !commentContribution ||
        !commentContribution.comments ||
        commentContribution.comments.length < 1
      ) {
        return state;
      }

      const updatedContribution = {
        ...commentContribution
      };
      updatedContribution.comments = updatedContribution.comments.filter(
        comment => comment.id !== commentId
      );
      updatedContribution.commentCount = updatedContribution.comments.length;

      return {
        ...state,
        currentSlideContributions: [
          ...currentSlideContributions.slice(0, indexOfCommentContribution),
          updatedContribution,
          ...currentSlideContributions.slice(indexOfCommentContribution + 1)
        ]
      };
    }
    default:
      return state;
  }
};
