import * as React from 'react';
import { StatusLabel } from '../status-label/StatusLabel';
import { coursewareStatusLabelColor, getCoursewareStatusLabelText } from './constants';
import type { CoursewareStatusLabelProps } from './types';

export const CoursewareStatusLabel: React.VFC<CoursewareStatusLabelProps> = ({
  coursewareStatus,
  ...rest
}) => {
  const color = coursewareStatusLabelColor[coursewareStatus];
  const label = getCoursewareStatusLabelText(coursewareStatus);
  return <StatusLabel background={color} label={label} {...rest} />;
};
