import type { Methods, ReorderData, TapInOrderData } from './types';

// Generated by CoffeeScript 1.12.4
export const methods: Methods = {
  reorder: (e: ReorderData) => {
    return {
      question: {
        type: 'text',
        content: e.title,
        category: e.title,
        text: e.title
      },
      answers: [
        {
          correct: true,
          content: e.list.join(' | '),
          type: 'sequence',
          text: e.list.join(' | ')
        },
        {
          content: e.list.join(' | '),
          correct: false,
          type: 'incorrect-answer',
          text: e.list.join(' | ')
        }
      ]
    };
  },
  'tap-in-order': (e: TapInOrderData) => {
    return {
      question: {
        type: 'text',
        content: e.title,
        text: e.title,
        category: e.title
      },
      answers: [
        {
          correct: true,
          content: e.list.join(' | '),
          type: 'sequence',
          text: e.list.join(' | ')
        },
        {
          type: 'incorrect-answer',
          content: e.list.join(' | '),
          correct: false,
          text: e.list.join(' | ')
        }
      ]
    };
  }
};

export default methods;
