import { useQueryClient } from '@tanstack/react-query';
import type { UseHippoQueryOptions, UseHippoMutationOptions } from '@edapp/request/src/types';
import { useHippoMutation, useHippoQuery } from '@edapp/request';
import type { RegistrationSettings } from './types';
import { registrationSettingsQueryKey } from './constants';
import { getRegistrationSettings, regenerateInviteCode, updateSettings } from './apis';
import {
  getUserGroupRegistrationSettings,
  regenerateUserGroupInviteCode,
  updateUserGroupRegistrationSettings
} from './user-group-api';

export function useGetRegistrationSettings(options?: UseHippoQueryOptions<RegistrationSettings>) {
  return useHippoQuery(
    [registrationSettingsQueryKey],
    (hippoUrl, userToken) => () => getRegistrationSettings(hippoUrl, userToken),
    options
  );
}

export function useRegenerateInviteCode(
  options?: UseHippoMutationOptions<RegistrationSettings, {}>
) {
  const queryClient = useQueryClient();

  return useHippoMutation(
    (hippoUrl, userToken) => () => regenerateInviteCode(hippoUrl, userToken),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData<RegistrationSettings>([registrationSettingsQueryKey], data);
        options?.onSuccess?.(data, variables, context);
      }
    }
  );
}

export function useSaveRegistrationSettings(
  options?: UseHippoMutationOptions<RegistrationSettings, RegistrationSettings>
) {
  const queryClient = useQueryClient();

  return useHippoMutation(
    (hippoUrl, userToken) => payload => updateSettings(hippoUrl, userToken, payload),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData<RegistrationSettings>([registrationSettingsQueryKey], data);
        options?.onSuccess?.(data, variables, context);
      }
    }
  );
}

export function useGetUserGroupRegistrationSettings(
  userGroupId: string,
  options?: UseHippoQueryOptions<RegistrationSettings | undefined>
) {
  return useHippoQuery(
    [registrationSettingsQueryKey, userGroupId],
    (hippoUrl, userToken) => () =>
      getUserGroupRegistrationSettings(hippoUrl, userToken, userGroupId),
    options
  );
}

export function useRegenerateUserGroupInviteCode(
  userGroupId: string,
  options?: UseHippoMutationOptions<RegistrationSettings, {}>
) {
  const queryClient = useQueryClient();

  return useHippoMutation(
    (hippoUrl, userToken) => () => regenerateUserGroupInviteCode(hippoUrl, userToken, userGroupId),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData<RegistrationSettings>([registrationSettingsQueryKey], data);
        options?.onSuccess?.(data, variables, context);
      }
    }
  );
}

export function useSaveUserGroupRegistrationSettings(
  userGroupId: string,
  options?: UseHippoMutationOptions<RegistrationSettings, RegistrationSettings>
) {
  const queryClient = useQueryClient();

  return useHippoMutation(
    (hippoUrl, userToken) => payload =>
      updateUserGroupRegistrationSettings(hippoUrl, userToken, userGroupId, payload),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData<RegistrationSettings>(
          [registrationSettingsQueryKey, userGroupId],
          data
        );
        options?.onSuccess?.(data, variables, context);
      }
    }
  );
}
