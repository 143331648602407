import * as React from 'react';
import styled, { css } from 'styled-components';
import { useBreakpointMatchMedia } from '../../hooks/useMatchMedia';
import { Dialog } from '../dialog/Dialog';

type Props = React.ComponentProps<typeof Dialog> & {
  /**
   * Whether to show dark overlay or not that will block the rest of the screen
   */
  withOverlay?: boolean;
  /**
   * Whether to show flat top for bottom sheet on mobile screens
   */
  bottomSheetFlatTop?: boolean;
};

/**
 * Turn into bottom sheet on mobile screens
 */
export const ResponsiveDialog: React.FC<Props> = ({ children, withOverlay = false, ...rest }) => {
  const isMobile = useBreakpointMatchMedia('sm', 'max-width') ?? true;

  // avoid overlay to cover the entire screen making it unclickable. Only applicable for mobile bottom sheet
  const mobileDialogWrapperStyle = withOverlay ? undefined : { top: 'unset', overflow: 'visible' };
  const dialogWrapperStyle = isMobile ? mobileDialogWrapperStyle : {};

  return (
    <StyledDialog
      key={String(isMobile)} // need to force rerender dialog to change position
      isOpen
      useBackdrop={withOverlay}
      usePortal={withOverlay}
      {...rest}
      isMobile={isMobile}
      dialogWrapperStyle={{ ...dialogWrapperStyle, ...(rest.dialogWrapperStyle ?? {}) }}
      position={isMobile ? 'bottom' : rest.position}
    >
      {children}
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)<{
  bottomSheetFlatTop?: boolean;
  isMobile: boolean;
}>(
  ({ theme, isMobile, bottomSheetFlatTop }) => css`
    padding: ${theme.space(4)}px;
    @media (max-width: ${theme.flexboxgrid.breakpoints.sm}rem) {
      padding: ${theme.space(2)}px;
      padding-top: ${theme.space(3)}px;
    }

    ${isMobile &&
    css`
      ${
        bottomSheetFlatTop
          ? css`
              border-radius: 0;
            `
          : css`
              border-radius: ${theme.sizes.borderRadius * 6}px ${theme.sizes.borderRadius * 6}px 0 0;
            `
      }
      padding-bottom: calc(env(safe-area-inset-bottom) + ${theme.space(3)}px);
      width: 100%;
      top: auto;
    `};
  `
);
