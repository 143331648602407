import { RequestUtils } from '@edapp/request';
import type { RegistrationSettings } from './types';

export async function getRegistrationSettings(
  hippoUrl: string,
  userToken: string
): Promise<RegistrationSettings> {
  return await RequestUtils.httpFetch<RegistrationSettings, undefined>(
    'GET',
    `${hippoUrl}/api/accounts/registration-settings`,
    userToken
  );
}

export async function updateSettings(
  hippoUrl: string,
  userToken: string,
  payload: RegistrationSettings
): Promise<RegistrationSettings> {
  return await RequestUtils.httpFetch<RegistrationSettings, RegistrationSettings>(
    'PUT',
    `${hippoUrl}/api/accounts/registration-settings`,
    userToken,
    payload
  );
}

export async function regenerateInviteCode(
  hippoUrl: string,
  userToken: string
): Promise<RegistrationSettings> {
  return await RequestUtils.httpFetch<RegistrationSettings, undefined>(
    'POST',
    `${hippoUrl}/api/accounts/registration-settings/regenerate-invite-code`,
    userToken
  );
}
