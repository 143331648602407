import React from 'react';
import styled from 'styled-components';

import { Alert, Box, Button, Typography } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';

type Props = {
  onClickOk: () => void;
};

export const ForgetPasswordSuccess: React.FC<Props> = ({ onClickOk }) => {
  return (
    <StyledContainer>
      <Text variant="titleLarge" component="h2">
        Reset password
      </Text>
      <Typography>We’ll send you a link to reset your password.</Typography>
      <Box mt={3} />
      <Alert variant="success" showIcon justifyContent="left" alignItems="flex-start">
        <Box>
          <Typography color="green">All done</Typography>
          <Box>Check your inbox for a link from us.</Box>
        </Box>
      </Alert>
      <Box mt={3} flex justifyContent="flex-end">
        <Button variant="primary" onClick={onClickOk}>
          Back to log in
        </Button>
      </Box>
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  text-align: left;
`;
