import type { ActionDialogProps } from '@edapp/ed-components';
import { ActionDialog, WarningIcon } from '@edapp/ed-components';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

export type Props = {
  /** Subject for deletion */
  subject?: string;
  /** Optional text to override warning message */
  warningText?: string;
  /** Deletion handler */
  onDelete: () => void;
} & ActionDialogProps;

export const DeleteDialog: React.FC<Props> = ({
  headerIcon = WarningIcon,
  confirmText,
  onDelete,
  subject,
  position,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <ActionDialog
      position={position || 'top'}
      headerIcon={headerIcon}
      title={t('delete-dialog.title')}
      onConfirm={onDelete}
      confirmText={confirmText || t('common.delete')}
      withOverlay
      {...rest}
    />
  );
};
