import type { History } from 'history';

import type { EdErrorResponseType } from '@edapp/authoring-logic';

import type { Banner } from './types';

/*
 * === === === === === === === ===
 * === === FETCH_BANNERS === ===
 * === === === === === === === ===
 */

export const FETCH_BANNERS = 'FETCH_BANNERS';
export type FetchBanners = PaginatedPayload<{ includeCourseBanners?: boolean }>;
export const fetchBanners = ({
  page,
  pageSize,
  includeCourseBanners = true
}: Partial<FetchBanners>): Action<FetchBanners> => {
  return {
    type: FETCH_BANNERS,
    payload: {
      page: page ?? 1,
      pageSize: pageSize ?? 25,
      includeCourseBanners
    }
  };
};

export const FETCH_BANNERS_SUCCESS = 'FETCH_BANNERS_SUCCESS';
export type FetchBannersSuccess = {
  totalCount: number;
  items: Banner[];
  page: number;
};

export const FETCH_BANNERS_FAILURE = 'FETCH_BANNERS_FAILURE';
export type FetchBannersFailure = EdErrorResponseType;

/*
 * === === === === === === === ===
 * === === FETCH_BANNER_ITEM === ===
 * === === === === === === === ===
 */

export const FETCH_BANNER_ITEM = 'FETCH_BANNER_ITEM';
export type FetchBannerItem = { bannerId: string };
export const fetchBannerItem = (bannerId: string): Action<FetchBannerItem> => ({
  type: FETCH_BANNER_ITEM,
  payload: { bannerId }
});

export const FETCH_BANNER_ITEM_SUCCESS = 'FETCH_BANNER_ITEM_SUCCESS';
export type FetchBannerItemSuccess = Banner;

export const FETCH_BANNER_ITEM_FAILURE = 'FETCH_BANNER_ITEM_FAILURE ';
export type FetchBannerItemFailure = EdErrorResponseType;

/*
 * === === === === === === === ===
 * === === CREATE_BANNER === ===
 * === === === === === === === ===
 */

export const CREATE_BANNER = 'CREATE_BANNER';
export type CreateBanner = { banner: Partial<Banner>; history: History };
export const createBanner = (banner: Partial<Banner>, history: History): Action<CreateBanner> => ({
  type: CREATE_BANNER,
  payload: { banner, history }
});

export const CREATE_BANNER_SUCCESS = 'CREATE_BANNER_SUCCESS';
export type CreateBannerSuccess = string;

export const CREATE_BANNER_FAILURE = 'CREATE_BANNER_FAILURE';
export type CreateBannerFailure = EdErrorResponseType;

/*
 * === === === === === === === ===
 * === === SET_BANNER === ===
 * === === === === === === === ===
 */

export const SET_BANNER = 'SET_BANNER';
export type SetBanner = {
  id: string;
  newBannerPartial: Partial<Omit<Banner, 'id'>>;
  isUnsavedChanges: boolean;
};
export const setBanner = (
  id: string,
  newBannerPartial: Partial<Banner>,
  isUnsavedChanges: boolean = true
) => ({
  type: SET_BANNER,
  payload: { id, newBannerPartial, isUnsavedChanges }
});

/*
 * === === === === === === === === === ===
 * === === SAVE_BANNER_IS_PUBLISHED === ===
 * === === === === === === === === === ===
 */

export const SAVE_BANNER_IS_PUBLISHED = 'SAVE_BANNER_IS_PUBLISHED';
export type SaveBannerIsPublished = { bannerId: string; isPublished: boolean };
export const saveBannerIsPublished = (bannerId: string, isPublished: boolean) => ({
  type: SAVE_BANNER_IS_PUBLISHED,
  payload: { bannerId, isPublished }
});

export const SAVE_BANNER_IS_PUBLISHED_SUCCESS = 'SAVE_BANNER_IS_PUBLISHED_SUCCESS';
export type SaveBannerIsPublishedSuccess = {};

export const SAVE_BANNER_IS_PUBLISHED_FAILURE = 'SAVE_BANNER_IS_PUBLISHED_FAILURE';
export type SaveBannerIsPublishedFailure = EdErrorResponseType;

/*
 * === === === === === === === ===
 * === === SAVE_BANNER === ===
 * === === === === === === === ===
 */

export const SAVE_BANNER = 'SAVE_BANNER';
export type SaveBanner = { bannerId: string };
export const saveBanner = (bannerId: string): Action<SaveBanner> => ({
  type: SAVE_BANNER,
  payload: { bannerId }
});

export const SAVE_BANNER_SUCCESS = 'SAVE_BANNER_SUCCESS';
export type SaveBannerSuccess = {};

export const SAVE_BANNER_FAILURE = 'SAVE_BANNER_FAILURE';
export type SaveBannerFailure = EdErrorResponseType;

/*
 * === === === === === === === ===
 * === === REMOVE_BANNER === ===
 * === === === === === === === ===
 */

export const REMOVE_BANNER = 'REMOVE_BANNER';
export type RemoveBanner = { bannerId: string };
export const removeBanner = (bannerId: string): Action<RemoveBanner> => ({
  type: REMOVE_BANNER,
  payload: { bannerId }
});

export const REMOVE_BANNER_SUCCESS = 'REMOVE_BANNER_SUCCESS';
export type RemoveBannerSuccess = {};

export const REMOVE_BANNER_FAILURE = 'REMOVE_BANNER_FAILURE';
export type RemoveBannerFailure = EdErrorResponseType;

/*
 * === === === === === === === ===
 * === === REORDER_BANNERS === ===
 * === === === === === === === ===
 */

export const REORDER_BANNERS = 'REORDER_BANNERS';
export type ReorderBanners = { banners: Banner[] };
export const reorderBanners = ({ banners }: ReorderBanners): Action<ReorderBanners> => ({
  type: REORDER_BANNERS,
  payload: { banners }
});

/*
 * === === === === === === === ===
 * === === UPDATE_RANK === ===
 * === === === === === === === ===
 */

export const UPDATE_RANK = 'UPDATE_RANK';
export type UpdateRank = { ids: string[] };
export const updateRank = ({ ids }: UpdateRank): Action<UpdateRank> => ({
  type: UPDATE_RANK,
  payload: { ids }
});

export const UPDATE_RANK_SUCCESS = 'UPDATE_RANK_SUCCESS';
export type UpdateRankSuccess = {};

export const UPDATE_RANK_FAILURE = 'UPDATE_RANK_FAILURE';
export type UpdateRankFailure = EdErrorResponseType;
