import type { ActionWithPayload } from '@edapp/utils';
import * as CourseActions from '@edapp/courseware-logic/src/course/actions';
import { ConferenceActionTypes } from './actions';
import type { ConferenceState, ConferenceActionsUnionType } from './types';
import { LessonDiscriminatorEnum } from '@edapp/authoring-logic';

export const initialConferenceState: ConferenceState = {
  byId: {},
  isLoading: false,
  loadingError: '',
  isSaving: false,
  savingError: ''
};

export const conferenceReducer = (
  state = initialConferenceState,
  action:
    | ConferenceActionsUnionType
    | ActionWithPayload<
        typeof CourseActions.DELETE_LESSON_SUCCESS,
        CourseActions.DeleteLessonSuccess
      >
): ConferenceState => {
  switch (action.type) {
    case ConferenceActionTypes.FETCH_CONFERENCE: {
      return {
        ...state,
        isLoading: true,
        loadingError: ''
      };
    }

    case ConferenceActionTypes.FETCH_CONFERENCE_SUCCESS: {
      const { payload: conference } = action;

      return {
        ...state,
        byId: {
          ...state.byId,
          [conference.id]: conference
        },
        isLoading: false
      };
    }

    case ConferenceActionTypes.FETCH_CONFERENCE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        loadingError: action.payload.message
      };
    }

    case ConferenceActionTypes.CREATE_CONFERENCE:
    case ConferenceActionTypes.UPDATE_CONFERENCE: {
      return {
        ...state,
        isSaving: true,
        savingError: ''
      };
    }

    case ConferenceActionTypes.CREATE_CONFERENCE_SUCCESS:
    case ConferenceActionTypes.UPDATE_CONFERENCE_SUCCESS: {
      const { payload: conference } = action;

      return {
        ...state,
        byId: {
          ...state.byId,
          [conference.id]: conference
        },
        isSaving: false
      };
    }

    case ConferenceActionTypes.CREATE_CONFERENCE_FAILURE:
    case ConferenceActionTypes.UPDATE_CONFERENCE_FAILURE: {
      return {
        ...state,
        isSaving: false,
        savingError: action.payload.message
      };
    }

    case CourseActions.DELETE_LESSON_SUCCESS: {
      const { lessonId, type } = action.payload;

      if (type !== LessonDiscriminatorEnum.VIRTUAL_CLASSROOM) {
        return state;
      }

      const { [lessonId]: deletedConference, ...restOfConferences } = state.byId;

      return {
        ...state,
        byId: restOfConferences
      };
    }

    default:
      return state;
  }
};
