import type { AuthoringTypes } from '@edapp/authoring-logic';
import type { CoursewareVersionType, LessonDiscriminatorEnum } from '@edapp/authoring-logic';
import type { RequestTypes } from '@edapp/request';

import type { Action, EdErrorResponseType, RecursivePartial } from '../types';
import type { LessonSiblingType, LessonType } from './types';

// Fetch Mini Courses
export const FETCH_MINI_COURSES = 'FETCH_MINI_COURSES';
export type FetchMiniCourses = {};
export const fetchMiniCourses = (): Action<FetchMiniCourses> => ({
  type: FETCH_MINI_COURSES,
  payload: {}
});

export const FETCH_MINI_COURSES_SUCCESS = 'FETCH_MINI_COURSES_SUCCESS';
export type FetchMiniCoursesSuccess = RequestTypes.PaginatedResponse<
  AuthoringTypes.CourseSummaryType
>;

export const FETCH_MINI_COURSES_FAILURE = 'FETCH_MINI_COURSES_FAILURE';
export type FetchMiniCoursesFailure = EdErrorResponseType;

// Fetch
export const FETCH_LESSON = 'FETCH_LESSON';
export type FetchLesson = { lessonId: string; versionType: CoursewareVersionType };
export const fetchLesson = (
  lessonId: string,
  versionType: CoursewareVersionType
): Action<FetchLesson> => ({
  type: FETCH_LESSON,
  payload: { lessonId, versionType }
});

export const FETCH_LESSON_SUCCESS = 'FETCH_LESSON_SUCCESS';
export type FetchLessonSuccess = LessonType;

export const FETCH_LESSON_FAILURE = 'FETCH_LESSON_FAILURE';
export type FetchLessonFailure = EdErrorResponseType;

// Fetch Lesson Siblings
export const FETCH_LESSON_SIBLINGS = 'FETCH_LESSON_SIBLINGS';
export type FetchLessonSiblings = { courseId: string; versionType?: CoursewareVersionType };
export const fetchLessonSiblings = (courseId: string): Action<FetchLessonSiblings> => ({
  type: FETCH_LESSON_SIBLINGS,
  payload: { courseId }
});

export const FETCH_LESSON_SIBLINGS_SUCCESS = 'FETCH_LESSON_SIBLINGS_SUCCESS';
export type FetchLessonSiblingsSuccess = { courseId: string; lessons: LessonSiblingType[] };

export const FETCH_LESSON_SIBLINGS_FAILURE = 'FETCH_LESSON_SIBLINGS_FAILURE';
export type FetchLessonSiblingsFailure = EdErrorResponseType;

// Set & Save
export const SET_LESSON = 'SET_LESSON';
export type SetLesson = { lessonId: string; data: RecursivePartial<LessonType>; skipSave: boolean };
export const setLesson = (
  lessonId: string,
  data: RecursivePartial<LessonType>,
  skipSave: boolean = false
): Action<SetLesson> => ({
  type: SET_LESSON,
  payload: { lessonId, data, skipSave }
});

export const SAVE_LESSON = 'SAVE_LESSON';
export type SaveLesson = {
  lessonId: string;
  data: RecursivePartial<LessonType>;
  oldData: LessonType;
};
export const saveLesson = (
  lessonId: string,
  data: RecursivePartial<LessonType>,
  oldData: LessonType
): Action<SaveLesson> => ({
  type: SAVE_LESSON,
  payload: { lessonId, data, oldData }
});

export const SAVE_LESSON_SUCCESS = 'SAVE_LESSON_SUCCESS';

export const SAVE_LESSON_FAILURE = 'SAVE_LESSON_FAILURE';
export type SaveLessonFailure = {
  lessonId: string;
  lessonStateBeforeSave: LessonType;
  error: EdErrorResponseType;
};

// Create

// Bulk Publish Lessons
export const SET_BULK_PUBLISH_LESSON = 'SET_BULK_PUBLISH_LESSON';
export type BulkPublishLesson = {
  lessonIds: string[];
};
export const setBulkPublishLesson = (
  bulkPublishLessonPayload: BulkPublishLesson
): Action<BulkPublishLesson> => ({
  type: SET_BULK_PUBLISH_LESSON,
  payload: bulkPublishLessonPayload
});

// Move/Copy Lesson
export const COPY_LESSON_TO_COURSE = 'COPY_LESSON_TO_COURSE';
export type CopyLessonToCourse = {
  lessonId: string;
  courseId: string;
  skipModule: boolean;
  lessonType: LessonDiscriminatorEnum;
};
export const copyLessonToCourse = (
  lessonId: string,
  courseId: string,
  skipModule: boolean,
  lessonType: LessonDiscriminatorEnum
): Action<CopyLessonToCourse> => ({
  type: COPY_LESSON_TO_COURSE,
  payload: { lessonId, courseId, skipModule, lessonType }
});

export const COPY_LESSON_TO_COURSE_SUCCESS = 'COPY_LESSON_TO_COURSE_SUCCESS';
export type CopyLessonToCourseSuccess = {
  newLessonId: string;
  success: boolean;
};

export const COPY_LESSON_TO_COURSE_FAILURE = 'COPY_LESSON_TO_COURSE_FAILURE';
export type CopyLessonToCourseFailure = EdErrorResponseType;

// Clear
export const CLEAR_LESSON = 'CLEAR_LESSON';
export type ClearLesson = {};
export const clearLesson = (): Action<ClearLesson> => ({
  type: CLEAR_LESSON,
  payload: {}
});
