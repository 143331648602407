import * as React from 'react';

import type { Props } from './types';

export const PILL_TEST_ID = 'pill';

export const PillComponent: React.FC<Props> = ({
  testId = PILL_TEST_ID,
  className,
  children,
  onClick,
  ...rest
}) => (
  <span
    className={className}
    data-testid={testId}
    onClick={onClick}
    aria-label={rest['aria-label']}
  >
    {children}
  </span>
);
