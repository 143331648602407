import * as React from 'react';
import type { OptionProps } from 'react-select/src/components/Option';
import { components as SelectComponents } from 'react-select';
import type { Option as OptionType } from '../types';

/**
 * You may supply adornment components to the Option
 *
 * @example
 * `
 * <Select options={[{label: "Apple", value: "apple", adornment: "🍎" }]} />
 * `
 */
export function Option<OptionValueType>(props: OptionProps<OptionType<OptionValueType>>) {
  const { children, label, data } = props;
  // to avoid the first option being auto-focused
  const [isFocused, setIsFocused] = React.useState<boolean>(false);

  return (
    <SelectComponents.Option
      {...props}
      data-testid={label}
      isFocused={isFocused}
      innerProps={{
        ...props.innerProps,
        onMouseOver: () => setIsFocused(true),
        // @ts-expect-error incorrect typing from react-select, safe to ignore
        onMouseLeave: () => setIsFocused(false)
      }}
    >
      {children}
      <div style={{ float: 'right' }}>{data?.adornment}</div>
    </SelectComponents.Option>
  );
}
