import { delay } from 'redux-saga';
import { fork, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { DEFAULT_LANGUAGE_CODE } from '@edapp/hippo-client';
import { RequestActions } from '@edapp/request';

import { getFirstLessonFromFirstCourse, updateSlidesDisplayIndexes } from '../../utils/helpers';
import { getRingoLanguage } from '../language/selectors';
import { getLessonId, getLessonLatestStateIdentifier } from '../selectors';
import type { SlideType, StoreState } from '../types';
import Actions from './actions';
import { FormActionTypes, fetchLessonConfigUri, fetchOtherCoursesUri } from './constants';
import { getSaving } from './selectors';
import type { FormActionsMap } from './types';
import { cleanSlide } from './utils';

function* handleSaveForm(isForceSave: boolean) {
  if (!isForceSave) {
    // Wait for the previous save to finish
    const isSaving: ReturnType<typeof getSaving> = yield select(getSaving);
    if (isSaving) {
      yield race({
        success: take(FormActionTypes.SAVE_FORM_FULFILLED),
        failure: take(FormActionTypes.SAVE_FORM_FAILED)
      });
    }
  }
  // Mark it as saving to prevent multiple saves
  yield put(Actions.setIsSavingForm());

  const latestSavedSlidesStateIdentifier: ReturnType<typeof getLessonLatestStateIdentifier> = yield select(
    getLessonLatestStateIdentifier
  );
  const lessonId: ReturnType<typeof getLessonId> = yield select(getLessonId);

  const patch = (stateSlides: SlideType[], lessonId: string, language: string) => {
    // We need this because there is a BE bug which sometimes sets the displayIndex to "0"
    const slides = updateSlidesDisplayIndexes(stateSlides);
    const cleanSlides = slides.map((slide: SlideType) => cleanSlide(slide));

    return RequestActions.patchAuthed(
      `api/lesson-authoring/${lessonId}`,
      FormActionTypes.SAVE_FORM_FULFILLED,
      FormActionTypes.SAVE_FORM_FAILED,
      false,
      {
        slides: cleanSlides,
        latestSavedSlidesStateIdentifier,
        language: language === DEFAULT_LANGUAGE_CODE ? undefined : language
      }
    );
  };
  const stateSlides: SlideType[] = yield select(
    (state: StoreState) => state.ringo.form.present.slides
  );

  const language: string = yield select(getRingoLanguage);

  yield put(patch(stateSlides, lessonId ?? '', language));
}

function* handleFetchOtherCourses() {
  yield put(
    RequestActions.getAuthed(
      fetchOtherCoursesUri,
      FormActionTypes.FETCH_OTHER_COURSES_FULFILLED,
      FormActionTypes.FETCH_OTHER_COURSES_FAILED,
      false
    )
  );
}

function* handleFetchLessonConfig(
  action:
    | FormActionsMap<FormActionTypes.FETCH_LESSON_CONFIG>
    | FormActionsMap<FormActionTypes.FETCH_OTHER_COURSES_FULFILLED>
) {
  const { payload } = action;
  let lessonId;
  if (typeof payload === 'string') {
    lessonId = payload;
  } else {
    const lesson = getFirstLessonFromFirstCourse(payload.courses);
    if (!lesson) {
      return; // skip call - since there is no lessons
    }

    lessonId = lesson.id;
  }

  if (lessonId) {
    yield put(
      RequestActions.getAuthed(
        fetchLessonConfigUri(lessonId),
        FormActionTypes.FETCH_LESSON_CONFIG_FULFILLED,
        FormActionTypes.FETCH_LESSON_CONFIG_FAILED,
        true
      )
    );
  }
}

function* handleManualSaveForm() {
  yield delay(1000); // to prevent race condition
  yield handleSaveForm(true);
}

function* watchSaveForm() {
  yield takeLatest(FormActionTypes.SAVE_FORM, handleManualSaveForm);
}

function* handleShouldAutoSave() {
  yield delay(1000); // to prevent race condition
  yield handleSaveForm(false);
}

function* watchShouldAutosave() {
  yield takeLatest(
    [
      FormActionTypes.REORDER_SLIDE,
      FormActionTypes.DELETE_SLIDE,
      FormActionTypes.ADD_SLIDE,
      FormActionTypes.EDIT_SLIDE,
      FormActionTypes.INSERT_SLIDES,
      // Slide update
      FormActionTypes.SLIDE_UPDATE_ELEMENT,
      FormActionTypes.SLIDE_ADD_ARRAY_ELEMENT,
      FormActionTypes.SLIDE_REMOVE_ARRAY_ELEMENT,
      FormActionTypes.SLIDE_SWAP_ARRAY_ELEMENT,
      FormActionTypes.SLIDE_DUPLICATE_ARRAY_ELEMENT
    ],
    handleShouldAutoSave
  );
}

function* watchFetchOtherCourses() {
  yield takeLatest(FormActionTypes.FETCH_OTHER_COURSES, handleFetchOtherCourses);
}

function* watchFetchLessonConfig() {
  yield takeLatest(FormActionTypes.FETCH_LESSON_CONFIG, handleFetchLessonConfig);
  yield takeLatest(FormActionTypes.FETCH_OTHER_COURSES_FULFILLED, handleFetchLessonConfig);
}

const sagas = [
  fork(watchSaveForm),
  fork(watchFetchOtherCourses),
  fork(watchFetchLessonConfig),
  fork(watchShouldAutosave)
];

export default sagas;
