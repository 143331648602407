import React from 'react';

export const useApiUnauthorized = () => {
  const [isUnauthorized, setIsUnauthorized] = React.useState(false);
  const isUnauthorizedRef = React.useRef(false);

  React.useEffect(() => {
    const handler = () => {
      if (!isUnauthorizedRef.current) {
        isUnauthorizedRef.current = true;
        setIsUnauthorized(true);
      }
    };
    window.addEventListener('api-unauthorized', handler);
    return () => {
      window.removeEventListener('api-unauthorized', handler);
    };
  }, []);

  return { isUnauthorized };
};
