import { CREATE_COURSE_SUCCESS } from '@edapp/courseware-logic';
import { ProvisioningType } from '@edapp/hippo-client';
import type { UserPermissionType } from '@rio/utils/permissions/types';

import { ConfigActionTypes } from './constants';
import type { ConfigActionsUnionType, ConfigStore } from './types';

export const initialConfigState: ConfigStore = {
  environment: 'development',
  monitoring: false,
  emilyApiUrl: '',
  emilyCmsUrl: '',
  hippoUrl: '',
  featureFlagsUrl: '',
  learnersAppUrl: '',
  romeoUrl: '',
  websiteUrl: '',
  supportUrl: '',
  youTubeUrl: '',
  userToken: '',
  fetching: false,
  error: null,
  userAppId: '',
  userAppName: '',
  userInfoLoading: true,
  userId: '',
  userName: '',
  userEmail: '',
  userFirstName: '',
  userLastName: '',
  userProfile: {
    data: {
      applicationId: '',
      id: '',
      firstName: '',
      lastName: '',
      email: '',
      customFields: [],
      avatarUrl: ''
    }
  },
  windowSize: {
    height: 0,
    width: 0
  },
  archiveSize: 0,
  unoptimisedVideos: 0,
  hasVisitedCustomAchievement: false,
  hasVisitedBrainBoost: false,
  isUserAdmin: false,
  trackingEnabled: false,
  brazeEnabled: false,
  userRoles: [],
  userPermissions: [],
  provisioningTypePermissions: [],
  provisioningType: ProvisioningType.Manual,
  uxpEnabled: false,
  fetchingUserRoles: false,
  safetyCultureUrl: {
    au: '',
    eu: '',
    us: ''
  },
  cloudinaryConfig: {
    publicApiKey: '',
    getSignedUploadUrl: '',
    CDN: '',
    URL: '',
    imageFolder: '',
    imageDesktopTransformation: ''
  },
  isSponsor: false,
  billing: {
    planAccess: {
      hasAccess: true,
      hasAccessReason: null,
      lockedDate: null,
      lessonLimit: null
    },
    limits: [],
    permissions: [],
    planIds: [],
    stripeKey: '',
    currentPlan: {
      id: '',
      type: 'AuthoringAndDelivery',
      name: '',
      minimumBillingUsers: 0,
      price: 0,
      isStripeBillable: true,
      features: [],
      limits: [],
      rank: 0,
      isVisible: true,
      displayName: '',
      startDate: '',
      endDate: '',
      isOnFreePlan: true,
      isEnterprise: false,
      isFreemium: false,
      isTrial: false
    },
    hasParticipatedInTrial: false,
    invoice: {
      latest: {
        id: null,
        isLoading: false,
        error: ''
      },
      retry: {
        isLoading: false,
        error: ''
      },
      byId: {}
    }
  },
  publicBranchKey: '',
  publicBrazeApiKey: '',
  publicCanvaApiKey: '',
  publicKnockApiKey: '',
  fjordUrl: ''
};

const reducer = (
  state: ConfigStore = initialConfigState,
  action: ConfigActionsUnionType
): ConfigStore => {
  switch (action.type) {
    case ConfigActionTypes.SET_TOKEN: {
      const { token } = action.payload;

      return {
        ...state,
        userToken: token
      };
    }

    case ConfigActionTypes.WINDOW_RESIZED: {
      const { height, width } = action.payload;

      return {
        ...state,
        windowSize: {
          height,
          width
        }
      };
    }

    case ConfigActionTypes.FETCH_USER_TOKEN:
      return {
        ...state,
        fetching: true
      };

    case ConfigActionTypes.FETCH_USER_TOKEN_FULFILLED:
      return {
        ...state,
        userToken: action.payload.token,
        fetching: false
      };

    case ConfigActionTypes.UPDATE_USER_DATA:
      const {
        userApplicationId,
        userId,
        userFirstName,
        userLastName,
        userEmail,
        userCustomFields,
        userAvatarUrl,
        userPermissions,
        userRoles,
        hasVisitedBrainBoost,
        hasVisitedCustomAchievement,
        userName,
        provisioningTypePermissions,
        userIsAdministrator
      } = action.payload;

      return {
        ...state,
        userId: userId || state.userId,
        userAppId: userApplicationId || state.userAppId,
        userEmail: userEmail || state.userEmail,
        userFirstName: userFirstName || state.userFirstName,
        userLastName: userLastName || state.userLastName,
        userName: userName || state.userName,
        hasVisitedBrainBoost: hasVisitedBrainBoost ?? state.hasVisitedBrainBoost,
        hasVisitedCustomAchievement:
          hasVisitedCustomAchievement || state.hasVisitedCustomAchievement,
        userPermissions:
          userPermissions?.map((perm: string) => {
            return { permissionId: perm } as UserPermissionType;
          }) || state.userPermissions,
        userRoles: userRoles || state.userRoles,
        userProfile: {
          data: {
            applicationId: userApplicationId || state.userAppId,
            id: userId || state.userId,
            firstName: userFirstName ?? '',
            lastName: userLastName ?? '',
            avatarUrl: userAvatarUrl ?? '',
            email: userEmail ?? '',
            customFields: (userCustomFields as string[]) ?? []
          }
        },
        provisioningTypePermissions:
          provisioningTypePermissions || state.provisioningTypePermissions,
        userInfoLoading: false,
        isUserAdmin: state.isUserAdmin
      };

    case ConfigActionTypes.UPDATE_USER_INFO_LOADING:
      return {
        ...state,
        userInfoLoading: action.payload
      };

    case ConfigActionTypes.UPDATE_APPLICATION_DATA:
      return {
        ...state,
        provisioningType: action.payload.provisioningType || state.provisioningType,
        uxpEnabled: action.payload.isUnifiedExperience || state.uxpEnabled,
        isSponsor: action.payload.isSponsor || state.isSponsor,
        userAppName: action.payload.name || state.userAppName
      };

    // Fast permissions -- optimistically add new course id to user permission group ids where exists
    case CREATE_COURSE_SUCCESS:
      return {
        ...state,
        userPermissions: state.userPermissions.map(permission => {
          if (!!permission.courseIds) {
            permission.courseIds.push(action.payload.id);
          }
          return permission;
        })
      };

    case ConfigActionTypes.FETCH_LATEST_INVOICE: {
      return {
        ...state,
        billing: {
          ...state.billing,
          invoice: {
            ...state.billing.invoice,
            latest: {
              ...state.billing.invoice.latest,
              isLoading: true,
              error: ''
            }
          }
        }
      };
    }

    case ConfigActionTypes.FETCH_LATEST_INVOICE_FULFILLED: {
      const { payload: latestInvoice } = action;

      const newLatestState = {
        ...state.billing.invoice.latest,
        isLoading: false
      };

      const newByIdState = {
        ...state.billing.invoice.byId
      };

      if (latestInvoice) {
        newLatestState.id = latestInvoice.id;
        newByIdState[latestInvoice.id] = latestInvoice;
      }

      return {
        ...state,
        billing: {
          ...state.billing,
          invoice: {
            ...state.billing.invoice,
            latest: newLatestState,
            byId: newByIdState
          }
        }
      };
    }

    case ConfigActionTypes.FETCH_LATEST_INVOICE_FAILED: {
      return {
        ...state,
        billing: {
          ...state.billing,
          invoice: {
            ...state.billing.invoice,
            latest: {
              ...state.billing.invoice.latest,
              error: action.payload.message
            }
          }
        }
      };
    }

    case ConfigActionTypes.RETRY_PAY_INVOICE: {
      return {
        ...state,
        billing: {
          ...state.billing,
          invoice: {
            ...state.billing.invoice,
            retry: {
              isLoading: true,
              error: ''
            }
          }
        }
      };
    }

    case ConfigActionTypes.RETRY_PAY_INVOICE_FULFILLED: {
      const { payload: retriedInvoice } = action;

      return {
        ...state,
        billing: {
          ...state.billing,
          invoice: {
            ...state.billing.invoice,
            retry: {
              ...state.billing.invoice.retry,
              isLoading: false
            },
            byId: {
              ...state.billing.invoice.byId,
              [retriedInvoice.id]: retriedInvoice
            }
          }
        }
      };
    }

    case ConfigActionTypes.UPDATE_USER_FIRST_AND_LAST_NAME: {
      return {
        ...state,
        userFirstName: action.payload.firstName,
        userLastName: action.payload.lastName,
        userProfile: {
          ...state.userProfile,
          data: {
            ...state.userProfile.data,
            firstName: action.payload.firstName,
            lastName: action.payload.lastName
          }
        }
      };
    }

    case ConfigActionTypes.UPDATE_BILLING_DATA:
      return {
        ...state,
        billing: {
          ...state.billing,
          permissions: action.payload.permissions ?? [],
          currentPlan: {
            ...state.billing.currentPlan,
            ...action.payload.currentPlan
          },
          hasParticipatedInTrial: action.payload.hasParticipatedInTrial ?? false,
          planAccess: {
            ...state.billing.planAccess,
            ...action.payload.planAccess
          },
          planIds: action.payload.planIds ?? []
        }
      };

    default:
      return state;
  }
};

export default reducer;
