import { ActionDialog, Button, IconButton, TickIcon } from '@edapp/ed-components';
import { notifyParentWindowBlockNavigation } from '@edapp/utils';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { NavigationGuard } from '../navigation-guard/NavigationGuard';

type Props = {
  saved: boolean;
  saving: boolean;
  onClick: () => void;
  disabled?: boolean;
  beforeUnloadWarning?: boolean;
  onLeaveConfirm?: () => void;
  buttonText?: string;
  allowSubRoute?: string;
} & React.ComponentProps<typeof Button>;

const SaveButton: React.FC<Props> = ({
  saved,
  saving,
  disabled,
  onClick,
  beforeUnloadWarning = false,
  onLeaveConfirm,
  buttonText,
  allowSubRoute = undefined,
  ...rest
}) => {
  const { t } = useTranslation();

  if (!buttonText) {
    buttonText = t('all-in-one-builder.save-changes');
  }

  const onButtonClick = () => {
    if (!!saved) {
      return;
    }

    onClick();
  };

  const buttonDisabled = !!disabled || !!saving;

  React.useEffect(() => {
    notifyParentWindowBlockNavigation(!saved, t('common.discard-changes'));
  }, [saved, t]);

  if (!!saving) {
    return (
      <>
        <AnimatedButton
          variant="light"
          children={t('all-in-one-builder.savings')}
          onClick={onButtonClick}
          disabled={buttonDisabled}
          {...rest}
        />
        {beforeUnloadWarning && (
          <SaveButtonGuard
            saved={saved}
            allowSubRoute={allowSubRoute}
            onLeaveConfirm={onLeaveConfirm}
          />
        )}
      </>
    );
  } else if (!saving && !!saved && !disabled) {
    return (
      <AnimatedIconButton
        icon={TickIcon}
        variant="light"
        children={t('common.saved')}
        onClick={onButtonClick}
        disabled={buttonDisabled}
        {...rest}
      />
    );
  } else {
    return (
      <>
        <AnimatedButton
          variant="primary"
          children={buttonText}
          onClick={onButtonClick}
          disabled={buttonDisabled}
          {...rest}
        />
        {beforeUnloadWarning && (
          <SaveButtonGuard
            saved={saved}
            allowSubRoute={allowSubRoute}
            onLeaveConfirm={onLeaveConfirm}
          />
        )}
      </>
    );
  }
};

type GuardProps = Pick<Props, 'saved' | 'onLeaveConfirm'> & {
  isVisible?: boolean;
  allowSubRoute?: string;
  onClose?: () => void;
};
export const SaveButtonGuard = ({
  saved,
  onLeaveConfirm,
  isVisible,
  onClose,
  allowSubRoute
}: GuardProps) => {
  const { t } = useTranslation();
  const [allowNavigation, setAllowNavigation] = React.useState(false);

  return (
    <NavigationGuard
      when={!saved}
      message={t('authoring.anonymous-navbar.exit-dialog.title')}
      allowNavigation={allowNavigation}
      allowOnlyPathname={true}
      allowSubRoute={allowSubRoute}
    >
      {(isGuardVisible, setIsGuardVisible) => (
        <ActionDialog
          isOpen={isGuardVisible || isVisible}
          onClose={() => {
            setIsGuardVisible(false);
            onClose?.();
          }}
          title={t('authoring.anonymous-navbar.exit-dialog.title')}
          description={t('common.discard-changes')}
          onConfirm={() => {
            if (onLeaveConfirm) {
              onLeaveConfirm();
            }
            setAllowNavigation(true);
          }}
          confirmText={t('common.leave-page')}
        />
      )}
    </NavigationGuard>
  );
};
const saveButtonTransition = css`
  transition: background-color 0.5s ease;
`;

const AnimatedIconButton = styled(IconButton)`
  ${saveButtonTransition}
`;

const AnimatedButton = styled(Button)`
  ${saveButtonTransition}
`;

export { SaveButton };
