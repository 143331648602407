import * as React from 'react';

import { useTranslation } from 'react-i18next';
import type { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router';

import { itly } from '@edapp/analytics-tracking';
import {
  useGetUserGroupRegistrationSettings,
  useRegenerateUserGroupInviteCode,
  useSaveUserGroupRegistrationSettings
} from '@edapp/hippo-client';

import { InviteSettings } from './InviteSettings';

const UserGroupInviteLink: React.FC<RouteComponentProps<{ groupId: string }>> = ({ match }) => {
  const { groupId } = match.params;
  const { t } = useTranslation();

  const { data, refetch } = useGetUserGroupRegistrationSettings(groupId, {
    staleTime: Infinity
  });
  const { mutate: regenerateInviteCode } = useRegenerateUserGroupInviteCode(groupId, {
    onSuccess: () => refetch()
  });
  const { mutate: saveRegistrationSettings } = useSaveUserGroupRegistrationSettings(groupId, {
    onSuccess: () => refetch()
  });

  const handleRegenerateInviteCode = React.useCallback(() => {
    regenerateInviteCode({});
  }, [regenerateInviteCode]);

  const handleToggleEnabled = React.useCallback(
    (enabled: boolean) => {
      saveRegistrationSettings({ enableRegistrationWithInviteCode: enabled });

      if (enabled) {
        itly.enableInviteLink({
          group_id: groupId,
          invite_code: data?.inviteCode,
          invite_link: data?.inviteLink
        });
      } else {
        itly.disableInviteLink({
          group_id: groupId,
          invite_code: data?.inviteCode,
          invite_link: data?.inviteLink
        });
      }
    },
    [saveRegistrationSettings]
  );

  const handleCopyLinkClicked = React.useCallback(() => {
    itly.inviteLinkCopied({
      group_id: groupId,
      invite_code: data?.inviteCode,
      invite_link: data?.inviteLink
    });
  }, []);

  const handleDownloadQRCode = React.useCallback(() => {
    itly.inviteLinkQrCodeDownloaded({
      group_id: groupId,
      invite_code: data?.inviteCode,
      invite_link: data?.inviteLink
    });
  }, []);

  const isNewUserGroup = groupId === 'new';
  if (isNewUserGroup) {
    return null;
  }

  return (
    <InviteSettings
      title={t('registration-settings.user-group-title')}
      description={t('registration-settings.user-group-description')}
      inviteEnabled={data?.enableRegistrationWithInviteCode || false}
      inviteLink={data?.inviteLink || ''}
      inviteCode={data?.inviteCode || ''}
      onCopyLinkClicked={handleCopyLinkClicked}
      onDownloadQRCode={handleDownloadQRCode}
      onRegenerate={handleRegenerateInviteCode}
      onToggleEnabled={handleToggleEnabled}
    />
  );
};

export default withRouter(UserGroupInviteLink);
