import * as React from 'react';
import type { AnimationLifecycles } from 'framer-motion';
import { AnimatePresence } from 'framer-motion';
import { usePrevious } from '../../hooks';
import type { Props, ContainerVariants } from './types';
import { CarouselContent, CarouselContents, FullWidthBox, Dot, ContentContainer } from './styled';
import { carouselAnimVariants } from './animations';
import { CarouselProvider, useCarousel } from './CarouselContext';

const Content: React.FC<React.PropsWithChildren<Props>> = ({
  showDots = true,
  header,
  overflow = 'auto',
  currStep,
  children,
  ...rest
}) => {
  const [isMounted, setIsMounted] = React.useState(false);
  const { totalSteps, currentStep, goToStep } = useCarousel();

  React.useEffect(() => {
    setIsMounted(true);
  }, []);

  // To navigate to required step in conjunction with react-router
  React.useEffect(() => {
    goToStep(currStep || 1);
  }, [currStep]);

  // For hiding overflow and disabling buttons while animations are in progress
  const [isAnimating, setIsAnimating] = React.useState<boolean>(false);
  // Every time the Carousel content (children) changes, we recalculate its dimensions
  // in order to resize the height
  const [contentHeight, setContentHeight] = React.useState(0);

  const handleAnimationStart = () => {
    setIsAnimating(true);
  };
  const handleAnimationComplete: AnimationLifecycles['onAnimationComplete'] = (definition) => {
    if (!!(definition as ContainerVariants)?.height) {
      setIsAnimating(false);
    }
  };

  const contentCallbackRef = React.useCallback(
    (contentElement: HTMLDivElement) => {
      if (!contentElement || !isMounted) {
        return;
      }

      setContentHeight(contentElement.getBoundingClientRect().height);

      const observer = new MutationObserver(() => {
        setContentHeight(contentElement.getBoundingClientRect().height);
      });

      observer.observe(contentElement, { attributes: true, childList: true, subtree: true });

      return () => {
        observer.disconnect();
      };
    },
    [isMounted]
  );

  // Diff between current and previous step is  passed into AnimatePresence and
  // animated content wrapper, in order to animate transitions in different directions
  const prevStep = usePrevious(currentStep);
  const stepDiff = currentStep - (prevStep ?? 0);

  const containerVariants: ContainerVariants = {
    height: contentHeight
  };

  return (
    <CarouselContents flex={true} flexDirection="column" justifyContent="flex-start" {...rest}>
      {header}
      {showDots && (
        <FullWidthBox flex={true} flexDirection="row" mb="sm">
          {[...new Array(totalSteps)].map((_, i) => (
            <Dot key={i} isActive={currentStep === i + 1} />
          ))}
        </FullWidthBox>
      )}

      <ContentContainer
        initial={false}
        animate={containerVariants}
        transition={{ clamp: true }}
        isAnimating={isAnimating}
        overflow={overflow}
        onAnimationStart={handleAnimationStart}
        onAnimationComplete={handleAnimationComplete}
      >
        <AnimatePresence initial={false} custom={stepDiff} exitBeforeEnter={true}>
          <CarouselContent
            key={currentStep}
            ref={contentCallbackRef}
            variants={carouselAnimVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            custom={stepDiff}
            transition={{ duration: 0.25, clamp: true }}
          >
            {React.Children.toArray(children)[currentStep - 1]}
          </CarouselContent>
        </AnimatePresence>
      </ContentContainer>
    </CarouselContents>
  );
};

export const Carousel: React.FC<React.PropsWithChildren<Props>> = ({ currentStep, infinite, children, ...props }) => {
  const steps = React.Children.toArray(children).filter(React.isValidElement);

  return (
    <CarouselProvider
      currentStep={currentStep}
      infinite={infinite}
      totalSteps={React.Children.count(steps)}
    >
      <Content currStep={currentStep} {...props}>
        {steps}
      </Content>
    </CarouselProvider>
  );
};
