import { createSelector } from 'reselect';

import type { CourseSummaryType } from '@edapp/courseware-logic';
import type { LmsStoreState } from '@rio/store/types';

import { initialPlaylist } from './constants';
import type { PlaylistItemType } from './types';

export const getPlaylistDirty = (state: LmsStoreState) => state.playlists.playlistDirty;

export const getPlaylistList = (state: LmsStoreState) => state.playlists.playlists;

export const getFetchPlaylistsError = (state: LmsStoreState) => state.playlists.fetchPlaylistsError;

export const getFetchPlaylistsLoading = (state: LmsStoreState) =>
  state.playlists.fetchPlaylistsLoading;

export const getPlaylistsTotalCount = (state: LmsStoreState) => state.playlists.totalCount;

export const getRemovePlaylistError = (state: LmsStoreState) => state.playlists.removePlaylistError;

export const getPlaylistErrorState = (state: LmsStoreState) => {
  return state.playlists.fetchPlaylistItemError || state.playlists.mutatePlaylistItemError;
};

export const getPlaylistLoadingState = (state: LmsStoreState) => {
  return state.playlists.fetchPlaylistItemLoading;
};

export const getPlaylist = (state: LmsStoreState, playlistId: string): PlaylistItemType => {
  const playlist = state.playlists.playlistItems[playlistId];

  if (!playlist) {
    return { ...initialPlaylist };
  }

  return playlist;
};

export const getPlaylistsByIds = (
  state: LmsStoreState,
  playlistIds: string[]
): PlaylistItemType[] => {
  return playlistIds.map(id => state.playlists.playlistItems[id]);
};

export const getPlaylistCourses = (
  state: LmsStoreState,
  playlistId: string
): CourseSummaryType[] => {
  const courses = state.playlists.playlistCourses[playlistId];

  if (!courses) {
    return [];
  }

  return courses;
};

export const getPlaylistCoursesWithPrereqs = (playlistId: string) =>
  createSelector(
    (state: LmsStoreState) => getPlaylistCourses(state, playlistId),
    courses => {
      return courses.filter(course => course.hasPrerequisites);
    }
  );

export const getPlaylistCourseIds = (playlistId: string) =>
  createSelector(
    (state: LmsStoreState) => getPlaylistCourses(state, playlistId),
    courses => {
      return courses.map(course => course.id) || [];
    }
  );

export const getIsFreePlaylist = (playlistId: string) =>
  createSelector(
    (state: LmsStoreState) => getPlaylist(state, playlistId),
    playlist => {
      return playlist?.playlistType === 'Free';
    }
  );

export const getIsSequentialPlaylist = (playlistId: string) =>
  createSelector(
    (state: LmsStoreState) => getPlaylist(state, playlistId),
    playlist => {
      return playlist?.playlistType === 'Sequential';
    }
  );

export const getIsAcknowledgedWarning = (playlistId: string) =>
  createSelector(
    (state: LmsStoreState) => getPlaylist(state, playlistId),
    playlist => {
      return playlist.isAcknowledgedWarning;
    }
  );

export const selectFetchPlaylistTranslationLoading = (state: LmsStoreState): boolean =>
  state.playlists.fetchPlaylistTranslationLoading;

export const selectPlaylistTranslations = (state: LmsStoreState, playlistId: string) =>
  state.playlists.playlistItems[playlistId]?.translations;

export const selectPlaylistDefaultLocale = (state: LmsStoreState, playlistId: string) => {
  return state.playlists.playlistItems[playlistId]?.defaultLocale;
};

export const selectPlaylistTranslationLanguages = (state: LmsStoreState, playlistId: string) => {
  const translations = selectPlaylistTranslations(state, playlistId);
  return translations?.map(translation => translation.locale).filter(language => !!language);
};

export const selectTranslationMissingCourses = (
  state: LmsStoreState,
  playlistId: string,
  translationCode: string
) => {
  const courseTranslations = getPlaylistCourses(state, playlistId).map(
    course => course.locales || []
  );

  return !!courseTranslations.find(
    translations =>
      !translations.find(
        translation => translation.code.toLocaleLowerCase() === translationCode.toLocaleLowerCase()
      )
  );
};
