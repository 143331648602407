import * as React from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { Loading, Typography } from '@edapp/ed-components';
import { ConditionComposer } from '@rio/components/common/ConditionComposer/ConditionComposer';
import { makeConditionPredicate } from '@rio/components/common/ConditionComposer/ConditionPredicateItem';
import type { ConditionPredicate } from '@rio/components/common/ConditionComposer/types';
import { setUserGroupItem } from '@rio/store/userGroups/actions';
import {
  getCustomFields,
  getIsFetchingConditions,
  getUserGroupConditions
} from '@rio/store/userGroups/selectors';

const Loader = styled(Loading)`
  display: block;
  margin: ${({ theme }) => theme.space(1)}px 50%;
`;

export const CustomFieldRules: React.FC = () => {
  const loadingConditions = useSelector(getIsFetchingConditions);
  const conditions = useSelector(getUserGroupConditions);
  const customFields = useSelector(getCustomFields);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  if (customFields.length === 0) {
    return (
      <div>
        <label>{t('user-groups.custom-fields.title')}</label>
        <p className="help-block">
          <Trans
            i18nKey="user-groups.custom-fields.no-fields-message"
            components={{
              anchor: (
                <Typography
                  isLink={true}
                  weight="600"
                  href="/app-settings#panel-user-custom-fields"
                />
              )
            }}
          />
        </p>
      </div>
    );
  }

  const options = customFields.map<ConditionPredicate>(i =>
    makeConditionPredicate(i.fieldName, i.fieldLabel)
  );

  const value = conditions.map<ConditionPredicate>(i => {
    const customField = customFields.find(c => c.fieldName === i.target.id);
    if (!customField && !!i.target.id) {
      throw Error(
        'Your dynamic user group contains a field that does not exist in the application settings.'
      );
    }

    return makeConditionPredicate(
      i.target.id,
      !!customField ? customField.fieldLabel : '',
      i.arguments,
      i.argumentTypeId,
      i.operatorId
    );
  });

  const onChange = (newValue: ConditionPredicate[]) => {
    dispatch(setUserGroupItem({ usergroup: { conditions: [...newValue] } }));
  };

  return (
    <>
      <label className="tight">{t('user-groups.custom-fields.title')}</label>
      <div className="text-muted tight">{t('user-groups.custom-fields.rules-description')}</div>

      {loadingConditions ? (
        <Loader />
      ) : (
        <ConditionComposer onChange={onChange} options={options} value={value} />
      )}
    </>
  );
};
