import { createAction } from '@edapp/utils';

import { FormActionTypes } from './constants';

export type SlideUpdateValueType = string | number | object | boolean | null;
export type SlideUpdateElementPayload = Array<{ value: SlideUpdateValueType; path: string }>;

export default {
  saveForm: () => createAction(FormActionTypes.SAVE_FORM),
  setIsSavingForm: () => createAction(FormActionTypes.SET_IS_SAVING_FORM),
  slideUpdateElement: (slideUpdates: SlideUpdateElementPayload) =>
    createAction(FormActionTypes.SLIDE_UPDATE_ELEMENT, slideUpdates),
  slideAddArrayElement: (path: string) =>
    createAction(FormActionTypes.SLIDE_ADD_ARRAY_ELEMENT, { path }),
  slideRemoveArrayElement: (index: number, path: string) =>
    createAction(FormActionTypes.SLIDE_REMOVE_ARRAY_ELEMENT, { index, path }),
  slideSwapArrayElement: (path: string, index: number, targetIndex: number) =>
    createAction(FormActionTypes.SLIDE_SWAP_ARRAY_ELEMENT, { path, index, targetIndex }),
  slideDuplicateArrayElement: (path: string, index: number) =>
    createAction(FormActionTypes.SLIDE_DUPLICATE_ARRAY_ELEMENT, { path, index }),
  resetError: () => createAction(FormActionTypes.RESET_ERROR)
};
