import type { RetryOptions } from '@edapp/utils/src/retry';
import type { GetSessionRetryCount, SetSessionRetryCount } from './types';
import { CHUNK_LOAD_ERROR_NAME, RETRY_SESSION_KEY } from './constants';

export const errorFilter: RetryOptions['errorFilter'] = error =>
  error?.name === CHUNK_LOAD_ERROR_NAME;

export const getSessionRetryCount: GetSessionRetryCount = () =>
  parseInt(window.sessionStorage.getItem(RETRY_SESSION_KEY) || '') || 0;

export const setSessionRetryCount: SetSessionRetryCount = retry =>
  window.sessionStorage.setItem(RETRY_SESSION_KEY, `${retry}`);
