import * as React from 'react';

import { pluralize } from 'humanize-plus';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { CoursewareVersionType, routes } from '@edapp/authoring-logic';
import { SelectableList, Typography } from '@edapp/ed-components';
import { useGetCoursePrerequisites } from '@edapp/hippo-client/src/courses/hooks';
import type { EdExceptionType } from '@edapp/request/src/types';

import { ErrorFallbackUI } from '../common/error-fallback-ui/ErrorFallbackUI';
import type { Option, OptionValue, Props } from './types';
import { filterPrerequisitesDeadlocks } from './utils';

export const Prerequisites: React.FC<Props> = ({
  id,
  originalCourseId,
  sequentialPlaylists,
  prerequisites,
  onChange: onChangePrerequisites
}) => {
  const { isLoading, isFetching, isError, error, data = [], refetch } = useGetCoursePrerequisites(
    { versionType: CoursewareVersionType.Latest },
    { gcTime: Infinity }
  );

  const { t } = useTranslation();

  if (isError) {
    return (
      <ErrorFallbackUI
        text={(error as EdExceptionType)?.errorResponse?.message || 'Failed to fetch prerequisites'}
        action={refetch}
      />
    );
  }

  const handleSelectPrerequisite = (option: OptionValue) => {
    onChangePrerequisites([...prerequisites, option]);
  };

  const handleSelectAllPrerequisites = (options: ReadonlyArray<OptionValue>) => {
    onChangePrerequisites(
      options.reduce<string[]>(
        (acc, cur) => (!acc.includes(cur) ? [...acc, cur] : acc),
        prerequisites
      )
    );
  };

  const handleClearPrerequisite = (option: OptionValue) => {
    onChangePrerequisites(prerequisites.filter(prereq => prereq !== option));
  };

  const handleClearAllPrerequisites = (options: ReadonlyArray<OptionValue>) => {
    onChangePrerequisites(prerequisites.filter(prereq => !options.includes(prereq)));
  };

  const options = filterPrerequisitesDeadlocks(id, data).map<Option>(item => {
    const count = (item.linkedCourseIds || []).length + 1;
    const suffix = count > 1 ? ` ( ${count} ${pluralize(count, 'language')} )` : '';

    return {
      key: item.id,
      value: item.id,
      label: item.title + suffix
    };
  });

  if (sequentialPlaylists.length > 0) {
    return (
      <Typography color="navyMuted" variant="small">
        {t('courseware.course-settings.prerequisites.sequential-course.blocked-message-1')} <br />
        {t('courseware.course-settings.prerequisites.sequential-course.blocked-message-2')}{' '}
        {sequentialPlaylists.map((playlist, idx, list) => (
          <React.Fragment key={playlist.id}>
            <StyledLink to={routes.path.getRoute({ playlistId: playlist.id })}>
              {playlist.title}
            </StyledLink>
            {idx < list.length - 1 && <Typography>, </Typography>}
          </React.Fragment>
        ))}
      </Typography>
    );
  }

  if (!originalCourseId || originalCourseId === id) {
    return (
      <SelectableList
        options={options}
        selectedValues={prerequisites}
        unselectedBox={{
          title: t('courseware.course-settings.prerequisites.search-all-courses'),
          showCount: true,
          onSelect: handleSelectPrerequisite,
          onSelectAll: handleSelectAllPrerequisites
        }}
        selectedBox={{
          title: t('courseware.course-settings.prerequisites.title'),
          showCount: true,
          onClear: handleClearPrerequisite,
          onClearAll: handleClearAllPrerequisites
        }}
        filter={{
          placeholder: t('lesson.search-course', { ns: 'translation' })
        }}
        disabled={isLoading || isError}
        loading={isFetching}
      />
    );
  }

  return (
    <Typography color="navyMuted" variant="small">
      <Trans
        i18nKey="courseware.course-settings.prerequisites.translated-course"
        components={{
          internalLink: (
            <StyledLink
              to={routes.allInOneEditor.getRoute({
                courseId: originalCourseId
              })}
            />
          )
        }}
      />
    </Typography>
  );
};

const StyledLink = styled(Link)(
  ({ theme }) => css`
    color: ${theme.colors.blue};
    :hover {
      color: ${theme.colors.blue};
    }
  `
);
