import { createAction } from '@edapp/utils';
import type {
  FetchUserGroupDraws,
  FetchUserGroupDrawsSuccess,
  FetchUserGroupDrawsFailure
} from './types';

export enum ActionType {
  FETCH_USER_GROUP_DRAWS = 'FETCH_USER_GROUP_DRAWS',
  FETCH_USER_GROUP_DRAWS_SUCCESS = 'FETCH_USER_GROUP_DRAWS_SUCCESS',
  FETCH_USER_GROUP_DRAWS_FAILURE = 'FETCH_USER_GROUP_DRAWS_FAILURE'
}

// ########
//  Fetch
// ########
export const fetchUserGroupDraws: FetchUserGroupDraws = () =>
  createAction(ActionType.FETCH_USER_GROUP_DRAWS);
export const fetchUserGroupDrawsSuccess: FetchUserGroupDrawsSuccess = payload =>
  createAction(ActionType.FETCH_USER_GROUP_DRAWS_SUCCESS, payload);
export const fetchUserGroupDrawsFailure: FetchUserGroupDrawsFailure = payload =>
  createAction(ActionType.FETCH_USER_GROUP_DRAWS_FAILURE, payload);
