import * as React from 'react';
import type { ContentLibrarySectionType, ContentLibraryCourseType } from '@edapp/content-library';
import { ContentSectionHeader } from './ContentSectionHeader';
import { SECTIONED_COURSES_VIEW, SECTIONED_COURSES_VIEW_MAX_COURSE_COUNT } from '../constants';
import styled from 'styled-components';
import { ContentCardsList } from '../../common/content-cards-list/ContentCardsList';
import { useTranslation } from 'react-i18next';

type Props = {
  items: ContentLibrarySectionType[];
  selectedCourseIds: string[];
  orderedSelect: boolean;
  viewIndex: number;
  onSelect: (id: string) => void;
  onChangeView: (index: number) => void;
  onViewBack: () => void;
  onViewAll: (sectionId: string) => void;
};

export const Section: React.FC<Props> = ({
  items,
  orderedSelect,
  onChangeView,
  onSelect,
  onViewAll,
  onViewBack,
  selectedCourseIds,
  viewIndex
}) => {
  const { t } = useTranslation();

  const getSectionCourseItems = (
    view: typeof SECTIONED_COURSES_VIEW,
    items: ContentLibraryCourseType[]
  ) => {
    return view === SECTIONED_COURSES_VIEW
      ? items.slice(0, SECTIONED_COURSES_VIEW_MAX_COURSE_COUNT)
      : items;
  };

  return (
    <SectionWrapper>
      {items.map((section: ContentLibrarySectionType, i) => {
        const courseCount =
          viewIndex === SECTIONED_COURSES_VIEW &&
          section?.courses?.totalCount > SECTIONED_COURSES_VIEW_MAX_COURSE_COUNT
            ? SECTIONED_COURSES_VIEW_MAX_COURSE_COUNT
            : section?.courses?.totalCount;

        return (
          <React.Fragment key={`${section.name}-${i}`}>
            <ContentSectionHeader
              sectionId={section.id}
              sectionTitle={section.name}
              totalCourses={courseCount}
              totalCoursesInCategory={section?.courses?.totalCount}
              onChangeView={onChangeView}
              onViewBack={onViewBack}
              onViewAll={onViewAll}
              viewIndex={viewIndex}
            />

            <ContentCardsList
              courses={getSectionCourseItems(viewIndex, section.courses.items)}
              onSelect={onSelect}
              selectedCourseIds={selectedCourseIds}
              orderedSelect={orderedSelect}
              selectText={t('common.select')}
              selectedText={t('common.selected')}
              byText={t('common.by')}
            />
          </React.Fragment>
        );
      })}
    </SectionWrapper>
  );
};

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
