import * as React from 'react';

type Options = {
  disabled?: boolean;
} & IntersectionObserverInit;

const defaultOptions: IntersectionObserverInit = {
  rootMargin: '300px'
};

export function useIntersectionObserver(
  ref: React.RefObject<HTMLElement>,
  options: Options = {}
): boolean {
  const { disabled, ...observerOptions } = {
    ...defaultOptions,
    ...options
  };
  const [isVisible, setIsVisible] = React.useState(!!disabled);

  React.useEffect(() => {
    if (!disabled && ref.current) {
      const observerCallback: IntersectionObserverCallback = entries => {
        const [firstEntry] = entries;

        if (firstEntry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      };

      const observer = new IntersectionObserver(observerCallback, observerOptions);

      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [disabled]);

  return isVisible;
}
