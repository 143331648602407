import * as React from 'react';
import type { Props } from './types';

export const CoursewareSvg: React.FC<Props> = ({ width = 180, height }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 160 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="80" cy="80" r="65" fill="#DCF6FF" />
    <path
      d="M135 62C136.657 62 138 63.3431 138 65L138 86L108 86L108 65C108 63.3431 109.343 62 111 62L135 62Z"
      fill="#83D9F9"
    />
    <path
      d="M138 86L138 94C138 95.6569 136.657 97 135 97L111 97C109.343 97 108 95.6569 108 94L108 86L138 86Z"
      fill="white"
    />
    <path
      d="M49 62C50.6569 62 52 63.3431 52 65L52 86L22 86L22 65C22 63.3431 23.3431 62 25 62L49 62Z"
      fill="#83D9F9"
    />
    <path
      d="M52 86L52 94C52 95.6569 50.6569 97 49 97L25 97C23.3431 97 22 95.6569 22 94L22 86L52 86Z"
      fill="white"
    />
    <g filter="url(#filter0_d_1316_6511)">
      <path
        d="M100 53C102.209 53 104 54.7909 104 57L104 91L56 91L56 57C56 54.7909 57.7909 53 60 53L100 53Z"
        fill="#83D9F9"
      />
      <path
        d="M104 91L104 105C104 107.209 102.209 109 100 109L60 109C57.7909 109 56 107.209 56 105L56 91L104 91Z"
        fill="white"
      />
      <path
        d="M104 91L56 91L56 86L85.6027 67.4983C92.6887 63.0696 102.031 66.8792 104 75L104 91Z"
        fill="#02BAFE"
      />
      <path
        d="M80 65C80 58.3726 74.6274 53 68 53C61.3726 53 56 58.3726 56 65C56 71.6274 61.3726 77 68 77C74.6274 77 80 71.6274 80 65Z"
        fill="#00BAFF"
      />
      <path
        d="M61 96.5C61 96.2239 61.2239 96 61.5 96H84.896C85.1722 96 85.396 96.2239 85.396 96.5C85.396 96.7761 85.1722 97 84.896 97H61.5C61.2239 97 61 96.7761 61 96.5Z"
        fill="#E3EBFA"
      />
      <path
        d="M63.3098 100.585C63.3697 100.401 63.6303 100.401 63.6902 100.585L64.0164 101.589C64.0432 101.672 64.1199 101.727 64.2066 101.727H65.2621C65.4558 101.727 65.5364 101.975 65.3797 102.089L64.5257 102.71C64.4556 102.761 64.4263 102.851 64.4531 102.933L64.7793 103.937C64.8391 104.121 64.6282 104.275 64.4715 104.161L63.6176 103.54C63.5475 103.489 63.4525 103.489 63.3824 103.54L62.5285 104.161C62.3718 104.275 62.1609 104.121 62.2207 103.937L62.5469 102.933C62.5737 102.851 62.5444 102.761 62.4743 102.71L61.6203 102.089C61.4636 101.975 61.5442 101.727 61.7379 101.727H62.7934C62.8801 101.727 62.9568 101.672 62.9836 101.589L63.3098 100.585Z"
        fill="#00BAFF"
      />
      <path
        d="M92.0693 100.037L99.5925 117.748L101.506 112.069L107.172 117.772L109.707 115.253L104.04 109.55L109.731 107.674L92.0693 100.037Z"
        fill="#6559FF"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_1316_6511"
        x="44"
        y="45"
        width="77.7314"
        height="88.772"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.352941 0 0 0 0 0.321569 0 0 0 0 0.501961 0 0 0 0.08 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1316_6511" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1316_6511"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
