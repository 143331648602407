import * as React from 'react';

import { useHistory, useLocation } from 'react-router';

import type { TabKeys, TabsType, UseTabRouter } from './types';

export function useTabRouter<Tabs extends TabKeys>(tabs: TabsType<Tabs>): UseTabRouter<Tabs> {
  const { pathname, hash } = useLocation();
  const history = useHistory();

  const handleSetTab = (tab: Tabs) => {
    history.replace(`${pathname}#${String(tab)}`);
  };

  const handleClearTab = () => {
    history.replace(pathname);
  };

  const activeTab = React.useMemo(
    () => (!!hash ? (Object.keys(tabs).find(tab => tab === hash.substring(1)) as Tabs) : undefined),
    [hash]
  );

  const routeTabs = React.useMemo(() => {
    const newRouteTabs: TabsType<Tabs> = {};

    for (const tabKey in tabs) {
      newRouteTabs[tabKey] = {
        ...tabs[tabKey],
        onClick: () => {
          handleSetTab(tabKey);
          tabs[tabKey]?.onClick?.();
        }
      };
    }

    return newRouteTabs;
  }, [tabs]);

  return { activeTab, tabs: routeTabs, setTab: handleSetTab, clearTab: handleClearTab };
}
