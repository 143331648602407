import * as React from 'react';
import styled from 'styled-components';
import { StarIcon, Typography } from '@edapp/ed-components';
import { kFormatter } from '@edapp/ed-components/src/utils';

type Props = {
  averageRating: number;
  numberOfRatings: number;
  darkMode?: boolean;
  onClick?: (e: React.MouseEvent) => void;
};

export const Rating: React.FC<Props> = ({
  onClick,
  averageRating,
  numberOfRatings,
  darkMode = false
}) => {
  const numRatings = kFormatter(numberOfRatings);

  return (
    <RatingWrapper onClick={onClick} variant="subtext" color="blue">
      <StyledStarIcon color="blue" />
      {parseFloat(averageRating.toFixed(1))}
      <StyledTypography variant="subtext" color={darkMode ? 'white' : 'textMuted'}>
        ({numRatings})
      </StyledTypography>
    </RatingWrapper>
  );
};

const StyledStarIcon = styled(StarIcon)`
  height: 12px;
  width: 12px;
  margin-top: -${({ theme }) => theme.space(0.25)}px;
`;

const StyledTypography = styled(Typography)`
  margin-left: ${({ theme }) => theme.space(0.25)}px;
`;

const RatingWrapper = styled(Typography)<{ darkMode?: boolean }>`
  display: flex;
  align-items: center;
  &:hover {
    color: ${({ theme }) => theme.colors.blueHover};
    ${StyledTypography} {
      color: ${({ theme, darkMode = false }) =>
        darkMode ? theme.colors.white : theme.colors.text};
    }
    ${StyledStarIcon} {
      path {
        fill: ${({ theme }) => theme.colors.blueHover};
      }
    }
  }
`;
