import * as React from 'react';

import { without } from 'lodash-es';
import styled from 'styled-components';

import type { CourseSummaryType } from '@edapp/courseware-logic/src/course/types';
import { CloseDialogButton, Dialog } from '@edapp/ed-components';
import { useDebounce } from '@edapp/ed-components/hooks';

import { CoursePickerDialogBody } from './CoursePickerDialogBody';
import { CoursePickerDialogHeader } from './CoursePickerDialogHeader';
import type { CourseDialogProps } from './types';

export const CoursePickerDialog: React.FC<CourseDialogProps> = ({
  isOpen,
  onClose,
  items,
  title,
  description,
  buttonText,
  onButtonClick,
  buttonLoading,
  error,
  loadingItems,
  orderedSelect,
  paginated,
  onSearch
}) => {
  const [selectedCourseIds, setSelectedCourseIds] = React.useState<string[]>([]);
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const [filteredItemList, setFilteredItemList] = React.useState<CourseSummaryType[]>([]);
  const debounceSearchTerm = useDebounce<string>(searchTerm, 300);

  const onSelect = (id: string) => {
    if (buttonLoading) {
      return;
    }

    const selectedIds = selectedCourseIds.includes(id)
      ? without(selectedCourseIds, id)
      : [...selectedCourseIds, id];

    setSelectedCourseIds(selectedIds);
  };

  React.useEffect(() => {
    setSelectedCourseIds([]);
  }, [isOpen]);

  const onAddCourses = () => {
    onButtonClick(selectedCourseIds);
  };

  const filterItemList = (data: CourseSummaryType[], query: string) => {
    if (!query) {
      setFilteredItemList(data);
      return;
    }

    setFilteredItemList(
      data.filter((course: CourseSummaryType) =>
        course.title.toLowerCase().includes(query.toLowerCase())
      )
    );
  };

  React.useEffect(() => {
    setSearchTerm('');
  }, [isOpen]);

  React.useEffect(() => {
    if (onSearch) {
      onSearch(searchTerm);
    }
  }, [debounceSearchTerm]);

  React.useEffect(() => {
    if (items && items.length) {
      filterItemList(items, searchTerm);
    }
  }, [searchTerm, items]);

  return (
    <StyledDialog isOpen={isOpen} onClose={onClose}>
      <CloseDialogButton onClick={onClose} />
      <CoursePickerDialogHeader
        title={title}
        description={description}
        buttonContents={buttonText}
        selectedCount={selectedCourseIds.length}
        isLoading={buttonLoading}
        onClickButton={onAddCourses}
        onSearch={(value: string) => setSearchTerm(value)}
      />
      <CoursePickerDialogBody
        items={filteredItemList || []}
        selectedIds={selectedCourseIds}
        onSelect={onSelect}
        error={error}
        loading={loadingItems}
        onClose={onClose}
        orderedSelect={!!orderedSelect}
        paginated={paginated}
      />
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)`
  width: 1314px;
  height: 756px;
  flex-direction: column;
`;
