import type { ModelConvertedToFormType, TemplateType } from '../store/types';
import type { SlideUpdateValueType } from '../store/form/actions';
import { cloneDeep, set, isArray, get, unset, pullAt, toPath, find } from 'lodash-es';
import { genId } from '@edapp/utils';

export const updateCommandForObjectPathValue = (
  data: object,
  path: string,
  value: SlideUpdateValueType
): any => {
  const newData = cloneDeep(data);
  return set(newData, path, value); // this mutates the object
};

export const removeCommandForObjectPathIndex = (
  object: object,
  path: string,
  index: number
): any => {
  const newData = cloneDeep(object);

  if (isArray(get(newData, path))) {
    pullAt(get(newData, path), index); // this mutates the object
  } else {
    const newPath = `${path}[${index}]`;
    unset(newData, newPath); // this mutates the object
  }

  return newData;
};

export const swapCommandForObjectPathIndex = (
  data: object,
  path: string,
  index: number,
  targetIndex: number
): any => {
  const clonedData = cloneDeep(data);

  const arrayData = get(clonedData, path);

  if (isArray(arrayData)) {
    [arrayData[index], arrayData[targetIndex]] = [arrayData[targetIndex], arrayData[index]];
  }

  return clonedData;
};

export const duplicateSlideSection = (data: object, path: string, index: number): any => {
  const clonedData = cloneDeep(data);

  const arrayData = get(clonedData, path);

  if (isArray(arrayData) && arrayData[index]) {
    const duplicatedElement = cloneDeep(arrayData[index]);
    if (duplicatedElement.hasOwnProperty('id')) {
      duplicatedElement.id = null;
    }

    const newArrayData = [
      ...arrayData.slice(0, index),
      duplicatedElement,
      ...arrayData.slice(index)
    ];

    set(clonedData, path, newArrayData);
  }

  return clonedData;
};

const buildObjectFromRepeater = (
  model: ModelConvertedToFormType | undefined,
  template: TemplateType
): object | string => {
  if (model && model.type === 'repeater' && model.elements) {
    switch (template.type) {
      case 'image-pair':
        return ['', ''];
      default:
        const newValue = buildObjectFromRepeater(model.elements[0], template);
        return [newValue];
    }
  } else {
    return '';
  }
};

const buildPropertyForType = (
  model: ModelConvertedToFormType
): string | number | boolean | object => {
  // More special type initialisation maybe needed
  if (model.default !== undefined && model.default !== null) {
    return model.default;
  }

  switch (model.type) {
    case 'boolean':
      return false;
    case 'image-region':
      return {
        yMax: 1.0,
        xMax: 1.0,
        yMin: 0.0,
        xMin: 0.0
      };
    case 'uuid':
      return genId();
    default:
      return '';
  }
};

const isRepeater = (model: ModelConvertedToFormType | undefined) =>
  !!model && model.type === 'repeater' && !!model.elements;

export const addCommandForObjectPath = (
  object: object,
  path: string,
  template: TemplateType
): any => {
  const newData = cloneDeep(object);
  const newPath = toPath(path);

  let templateModel: ModelConvertedToFormType | undefined = template.model;

  newPath.forEach((property: string) => {
    const isNumber = !isNaN(+property);

    if (!templateModel || (isRepeater(templateModel) && isNumber)) {
      return;
    }

    templateModel = find(templateModel.elements, { name: property });
  });

  if (!templateModel) {
    return newData; // Should we throw? It means that action is wrong... probably dev error!?
  }

  let newItem: string | object | boolean | number = '';

  // Node is a repeater
  if (isRepeater(templateModel) && templateModel.elements) {
    newItem = {};

    templateModel.elements.forEach(e => {
      if (!!e.name) {
        newItem[e.name] =
          e.type === 'repeater' ? buildObjectFromRepeater(e, template) : buildPropertyForType(e);

        // For multi-type repeater, set a default contentType of the first type option
        if (e.type === 'multi' && e.elements) {
          newItem[e.name + 'Type'] = e.elements[0].type;
        }
      } else {
        newItem = buildPropertyForType(e);
      }
    });
  }

  if (isArray(get(newData, path))) {
    (get(newData, path) as any[]).push(newItem); // this mutates the object
  } else {
    set(newData, path, newItem); // this mutates the object
  }

  return newData;
};

export default updateCommandForObjectPathValue;
