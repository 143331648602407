import { createSelector } from 'reselect';

import { CONTENT_LIBRARY_APPLICATION_ID } from '@edapp/content-library';
import type { UserPermission } from '@edapp/permissions';
import type { RoleId } from '@edapp/user-info';
import { Roles } from '@edapp/user-info';
import type { LmsStoreState } from '@rio/store/types';
import type { BillingPermission } from '@rio/utils/permissions/constants';

export const getWindowSize = (state: LmsStoreState) => state.config.windowSize;
export const getUserAppId = (state: LmsStoreState) =>
  state.config.userProfile.data?.applicationId || state.config.userAppId;
export const getUserAppName = (state: LmsStoreState) => state.config.userAppName;
export const getUserId = (state: LmsStoreState) =>
  state.config.userProfile.data?.id || state.config.userId;
export const getUserName = (state: LmsStoreState) => state.config.userName;
export const getUserFirstName = (state: LmsStoreState) =>
  state.config.userProfile.data?.firstName || state.config.userFirstName;
export const getUserLastName = (state: LmsStoreState) =>
  state.config.userProfile.data?.lastName || state.config.userLastName;
export const getSafetyCultureUrl = (state: LmsStoreState) => state.config.safetyCultureUrl;
export const getArchiveSize = (state: LmsStoreState) => state.config.archiveSize;
export const getUnoptimisedVideos = (state: LmsStoreState) => state.config.unoptimisedVideos;
export const getIsUserAdmin = (state: LmsStoreState) => state.config.isUserAdmin;
export const getUserToken = (state: LmsStoreState) => state.config.userToken;
export const getHippoUrl = (state: LmsStoreState) => state.config.hippoUrl;
export const getFjordUrl = (state: LmsStoreState) => state.config.fjordUrl;
export const getFeatureFlagsUrl = (state: LmsStoreState) => state.config.featureFlagsUrl;
export const getBranchKey = (state: LmsStoreState) => state.config.publicBranchKey;
export const getEmilyApiUrl = (state: LmsStoreState) => state.config.emilyApiUrl;
export const getEmilyCmsUrl = (state: LmsStoreState) => state.config.emilyCmsUrl;
export const getRomeoUrl = (state: LmsStoreState) => state.config.romeoUrl;
export const getWebsiteUrl = (state: LmsStoreState) => state.config.websiteUrl;
export const getSupportUrl = (state: LmsStoreState) => state.config.supportUrl;
export const getYouTubeUrl = (state: LmsStoreState) => state.config.youTubeUrl;
export const getLearnersAppUrl = (state: LmsStoreState) => state.config.learnersAppUrl;

export const getStripeKey = (state: LmsStoreState) => state.config.billing.stripeKey;
export const getIsTrialAvailable = (state: LmsStoreState) =>
  !!state.config.billing.currentPlan.isOnFreePlan && !state.config.billing.hasParticipatedInTrial;
export const getCurrentPlan = (state: LmsStoreState) => state.config.billing.currentPlan;
export const getIsOnTrialPlan = (state: LmsStoreState) =>
  !!state.config.billing.currentPlan.isTrial;
export const getIsOnPaidPlan = (state: LmsStoreState) =>
  !!(state.config.billing.currentPlan.price > 0 && !getIsOnTrialPlan(state));
export const getIsOnFreemiumPlan = (state: LmsStoreState) =>
  state.config.billing.currentPlan.isFreemium;
export const getIsOnEnterprisePlan = (state: LmsStoreState) =>
  state.config.billing.currentPlan.isEnterprise;
export const getIsNotOnFreePlan = (state: LmsStoreState) =>
  state.config.billing.currentPlan.isEnterprise ||
  state.config.billing.currentPlan.price > 0 ||
  state.config.billing.currentPlan.isFreemium ||
  !!state.config.billing.currentPlan.isTrial;
export const getIsOnFreePlan = (state: LmsStoreState) => !getIsNotOnFreePlan(state);
export const getIsSponsor = (state: LmsStoreState) => state.config.isSponsor;
export const getHasAccess = (state: LmsStoreState) => !!state.config.billing.planAccess.hasAccess;
export const getHasAccessReason = (state: LmsStoreState) =>
  state.config.billing.planAccess.hasAccessReason;
export const getPlanDisplayName = (state: LmsStoreState) =>
  state.config.billing.currentPlan.displayName;
export const getPlanStartDate = (state: LmsStoreState) =>
  state.config.billing.currentPlan.startDate;
export const getPlanAccessLockedDate = (state: LmsStoreState) =>
  state.config.billing.planAccess.lockedDate;
export const getPlanEndDate = (state: LmsStoreState) => state.config.billing.currentPlan.endDate;
export const getUserRoles = (state: LmsStoreState) => state.config.userRoles;

export const hasRole = (role: RoleId) => (state: LmsStoreState) =>
  state.config.userRoles.includes(role);
export const hasARole = (roles: RoleId[]) => (state: LmsStoreState) =>
  state.config.userRoles.some(x => roles.includes(x));
export const getIsAnonymousUser = hasRole(Roles.AnonymousUser);
export const isSeoAdmin = hasRole(Roles.SeoAdmin);
export const isSuperAdmin = hasARole([Roles.EdAdmin, Roles.SafetyCultureAdmin]);
export const isSafetyCultureAdmin = hasRole(Roles.SafetyCultureAdmin);
export const isAccountAdmin = hasRole(Roles.Admin);
export const isAccountOwner = hasRole(Roles.Owner);

export const getBillingPermissions = (state: LmsStoreState) => state.config.billing.permissions;
export const hasBillingPermission = (perm: BillingPermission) => (state: LmsStoreState) =>
  isSuperAdmin(state) || state.config.billing.permissions.includes(perm);
export const getUserPermissions = (state: LmsStoreState) => state.config.userPermissions;
export const getProvisioningTypePermissions = (state: LmsStoreState) =>
  state.config.provisioningTypePermissions;
export const getProvisioningType = (state: LmsStoreState) => state.config.provisioningType;
export const isUXPEnabled = (state: LmsStoreState) => state.config.uxpEnabled;
export const hasPermission = (perm: UserPermission[]) => (state: LmsStoreState): boolean =>
  state.config.userPermissions.some(({ permissionId }) => perm.includes(permissionId));
export const isUserInfoLoading = (state: LmsStoreState) => state.config.userInfoLoading;
export const hasAccessToRoute = (
  billingPermission?: BillingPermission,
  userPermission?: UserPermission[],
  role?: RoleId
) =>
  createSelector(
    !!billingPermission ? hasBillingPermission(billingPermission) : () => true,
    !!userPermission ? hasPermission(userPermission) : () => true,
    !!role ? hasRole(role) : () => true,
    (hasAccess, hasPermission, hasRole) => hasAccess && hasPermission && hasRole
  );

export const getCurrentUserState = (state: LmsStoreState) => state.config.userProfile;
export const getCurrentUserEmail = (state: LmsStoreState) =>
  state.config.userProfile.data?.email || state.config.userEmail;
export const getCanvaApiKey = (state: LmsStoreState) => state.config.publicCanvaApiKey;
export const getKnockApiKey = (state: LmsStoreState) => state.config.publicKnockApiKey;
export const getCloudinaryConfig = (state: LmsStoreState) => state.config.cloudinaryConfig;
export const getLatestInvoiceId = (state: LmsStoreState) => state.config.billing.invoice.latest.id;
export const getSignedUploadUrl = (state: LmsStoreState) =>
  `${state.config.hippoUrl}${state.config.cloudinaryConfig.getSignedUploadUrl}`;

export const getIsLatestInvoiceLoading = (state: LmsStoreState) =>
  state.config.billing.invoice.latest.isLoading;

export const getInvoicesById = (state: LmsStoreState) => state.config.billing.invoice.byId;
export const getBrazeApiKey = (state: LmsStoreState) => state.config.publicBrazeApiKey;

export const getSuccessfullyCopiedLessonId = (state: LmsStoreState) =>
  state.lesson.successfullyCopiedLessonId;

export const getLatestInvoice = createSelector(
  [getLatestInvoiceId, getInvoicesById],
  (latestInvoiceId, invoicesById) => {
    if (latestInvoiceId == null) {
      return undefined;
    }

    return invoicesById[latestInvoiceId];
  }
);

export const getHasObservationsPermissions = createSelector(
  [isSuperAdmin, getUserRoles, isAccountAdmin, isAccountOwner],
  (isSuperAdmin, userRoles, isAccountAdmin, isAccountOwner) => {
    return (
      isSuperAdmin ||
      isAccountAdmin ||
      isAccountOwner ||
      !!userRoles.find(role => role === Roles.Facilitator) ||
      !!userRoles.find(role => role === Roles.ManageTeams)
    );
  }
);

export const isAdminOrAuthor = createSelector(
  [isSuperAdmin, isAccountAdmin, isAccountOwner, getUserRoles],
  (isSuperAdmin, isAccountAdmin, isAccountOwner, userRoles) => {
    return (
      isSuperAdmin ||
      isAccountAdmin ||
      isAccountOwner ||
      !!userRoles.find(role => role === Roles.ContentAuthor)
    );
  }
);

export const getIsRetryingInvoicePayment = (state: LmsStoreState) =>
  state.config.billing.invoice.retry.isLoading;

export const getLessonLimit = (state: LmsStoreState) => state.config.billing.planAccess.lessonLimit;

export const getIsContentLibraryApplication = (state: LmsStoreState) =>
  state.config.userAppId === CONTENT_LIBRARY_APPLICATION_ID;

export const getHippoCredentials = (store: LmsStoreState) => ({
  hippoUrl: getHippoUrl(store),
  userToken: getUserToken(store) || ''
});

export const getEmilyCmsCredentials = (store: LmsStoreState) => ({
  emilyCmsUrl: getEmilyCmsUrl(store),
  userToken: getUserToken(store) || ''
});

export const getUserHasVisitedCustomAchievement = (store: LmsStoreState) =>
  store.config.hasVisitedCustomAchievement;

export const getUserHasVisitedBrainBoost = (store: LmsStoreState) =>
  store.config.hasVisitedBrainBoost;
