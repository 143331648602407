/**
 * This is a wrapper around the react-window FixedSizeList component,
 * with the ability to click arrows to paginate.
 */
import * as React from 'react';

import { FixedSizeList } from 'react-window';
import type { ListOnScrollProps } from 'react-window';
import styled, { css } from 'styled-components';

import { usePrevious } from '../../hooks';
import { mergeRefs } from '../../utils';
import type { IWindowedList, Position, Props, ScrollState } from './types';

const Component = <ItemData extends any>(
  {
    showScrollbar = true,
    initialScrollOffset,
    useDragToScroll,
    outerRef,
    onScroll,
    onChangeScrollState,
    className,
    noScrollTouch,
    ...rest
  }: Props<ItemData> & { noScrollTouch?: boolean },
  ref: React.Ref<IWindowedList>
) => {
  // Tracks scroll position.
  const scrollContainerRef = React.useRef<HTMLDivElement>(null);

  const [scrollState, setScrollState] = React.useState<ScrollState>();

  const prevScrollState = usePrevious(scrollState);

  // Trigger onChangeScrollState if handler is provided, and scroll state has changed
  React.useEffect(() => {
    if (onChangeScrollState && scrollState && prevScrollState !== scrollState) {
      onChangeScrollState(scrollState);
    }
  }, [onChangeScrollState, prevScrollState, scrollState]);

  const positionRef = React.useRef<Position>({
    scrollTop: 0,
    scrollLeft: 0,
    x: 0,
    y: 0,
    // To know which direction the pointer was last moving in, so we can scroll toward the
    // list item in that direction when the pointer is released
    lastDeltaX: 0,
    lastDeltaY: 0,
    isTracking: false
  });

  const scrollOffsetRef = React.useRef<number>(initialScrollOffset ?? 0);

  const handleScroll = (scrollProps: ListOnScrollProps) => {
    onScroll?.(scrollProps);

    handleChangeScrollPosition(scrollProps.scrollOffset);
  };

  const itemsPerPage =
    // For horizontal lists, width must be a number. For vertical lists, height must be
    // a number. Otherwise, they can be a string like "50%"
    (rest.layout === 'horizontal' ? (rest.width as number) : (rest.height as number)) /
    rest.itemSize;

  // Called on every change in scroll position, whether by manual scrolling or external
  // pagination
  const handleChangeScrollPosition = React.useCallback(
    (newScrollPosition: number) => {
      scrollOffsetRef.current = newScrollPosition;

      if (onChangeScrollState && scrollContainerRef.current) {
        const endScrollPosition =
          rest.layout === 'horizontal'
            ? scrollContainerRef.current.scrollWidth
            : scrollContainerRef.current.scrollHeight;
        const lastPagePosition = endScrollPosition - rest.itemSize * itemsPerPage;

        if (newScrollPosition === 0) {
          setScrollState('start');
        } else if (newScrollPosition >= lastPagePosition) {
          setScrollState('end');
        } else {
          setScrollState('middle');
        }
      }
    },
    [onChangeScrollState, rest.itemSize, itemsPerPage]
  );

  const smoothScrollTo = React.useCallback(
    (newScrollPosition: number) => {
      scrollContainerRef.current?.scrollTo({
        behavior: 'smooth',
        ...(rest.layout === 'horizontal' ? { left: newScrollPosition } : { top: newScrollPosition })
      });

      handleChangeScrollPosition(newScrollPosition);
    },
    [rest.layout]
  );

  // Pagination can be performed from the parent by attaching a ref to this component:
  //
  // const listRef = React.useRef<IWindowedList>(null);
  //
  //
  // return (
  //   <>
  //     <button onClick={() => listRef.current?.goToNextPage()}>
  //       Next Page
  //     </button>
  //     <WindowedList ref={listRef} ... />
  //   </>
  // );
  React.useImperativeHandle(
    ref,
    () => ({
      goToPreviousPage: () => {
        if (scrollContainerRef.current) {
          const currentItemIndex = Math.ceil(scrollOffsetRef.current / rest.itemSize);
          const targetItemIndex = currentItemIndex - itemsPerPage;
          const newScrollPosition = targetItemIndex * rest.itemSize;

          smoothScrollTo(Math.max(newScrollPosition, 0));
        }
      },
      goToNextPage: () => {
        if (scrollContainerRef.current) {
          const currentItemIndex = Math.floor(scrollOffsetRef.current / rest.itemSize);
          const targetItemIndex = currentItemIndex + itemsPerPage;
          const newScrollPosition = targetItemIndex * rest.itemSize;
          const scrollContainerSize =
            rest.layout === 'horizontal'
              ? scrollContainerRef.current.scrollWidth
              : scrollContainerRef.current.scrollHeight;
          const maxScrollPosition = scrollContainerSize - rest.itemSize * itemsPerPage;

          smoothScrollTo(Math.min(newScrollPosition, maxScrollPosition));
        }
      }
    }),
    [rest.itemSize, smoothScrollTo, itemsPerPage]
  );

  const handlePointerDown = (e: React.PointerEvent) => {
    if (!noScrollTouch) return;

    // Track initial position information and attach event listeners to continue
    // tracking as the pointer moves.
    if (scrollContainerRef.current) {
      positionRef.current = {
        scrollLeft: scrollContainerRef.current.scrollLeft,
        scrollTop: scrollContainerRef.current.scrollTop,
        x: e.clientX,
        y: e.clientY,
        lastDeltaX: 0,
        lastDeltaY: 0,
        isTracking: true
      };
    }
  };

  const handlePointerMove = (e: React.PointerEvent) => {
    if (!noScrollTouch) return;

    // We calculate how far the pointer has moved since the position was last updated,
    //  and scroll that amount
    if (scrollContainerRef.current && positionRef.current.isTracking) {
      const deltaX = e.clientX - positionRef.current.x;
      const deltaY = e.clientY - positionRef.current.y;

      positionRef.current.lastDeltaX = deltaX;
      positionRef.current.lastDeltaY = deltaY;

      scrollContainerRef.current.scrollLeft = positionRef.current.scrollLeft - deltaX;
      scrollContainerRef.current.scrollTop = positionRef.current.scrollTop - deltaY;
    }
  };

  const handleTouchEnd = () => {
    if (!noScrollTouch) return;

    if (scrollContainerRef.current) {
      // Depending on the last moved direction, we smooth scroll to the closest item
      // toward that direction.
      const lastDelta =
        rest.layout === 'horizontal'
          ? positionRef.current.lastDeltaX
          : positionRef.current.lastDeltaY;

      if (lastDelta > 0) {
        const closestItemIndex = Math.floor(scrollOffsetRef.current / rest.itemSize);
        smoothScrollTo(closestItemIndex * rest.itemSize);
      } else if (lastDelta < 0) {
        const closestItemIndex = Math.ceil(scrollOffsetRef.current / rest.itemSize);
        smoothScrollTo(closestItemIndex * rest.itemSize);
      }

      positionRef.current.isTracking = false;
    }
  };

  return (
    <Container
      className={className}
      showScrollbar={showScrollbar}
      onPointerDown={handlePointerDown}
      onPointerMove={handlePointerMove}
      onTouchEnd={handleTouchEnd}
      onPointerUp={handleTouchEnd}
      onPointerLeave={handleTouchEnd}
      onTouchCancel={handleTouchEnd}
    >
      <FixedSizeList<ItemData>
        outerRef={mergeRefs([scrollContainerRef, outerRef])}
        onScroll={handleScroll}
        initialScrollOffset={initialScrollOffset}
        {...rest}
      />
    </Container>
  );
};

const Container = styled.div<{ showScrollbar: boolean }>(
  ({ showScrollbar }) => css`
    position: relative;
    user-select: none;

    ${// Styles the list container element rendered by react-window
    !showScrollbar &&
    `
      > div {
        &::-webkit-scrollbar {
          width: 0px;
          background: transparent;
        }
        scrollbar-width: none;
        -ms-overflow-style: none;
      }
    `}
  `
);

export const WindowedList = React.forwardRef(Component) as <ItemData extends any>(
  props: Props<ItemData> & { ref?: React.Ref<IWindowedList> }
) => React.ReactElement;
