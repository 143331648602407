import { RequestActions } from '@edapp/request';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { BrainBoostActions, BrainBoostActionTypes } from './actions';
import type {
  GetSpacedRepetitionAction,
  SpacedRepCourseConfig,
  UpdateBrainBoostSettingsAction
} from './types';

function* fetchSpacedRepetitionForCourse({ payload: { id } }: GetSpacedRepetitionAction) {
  yield put(
    RequestActions.getAuthed<SpacedRepCourseConfig>(
      `api/spacedrepetition/config/course/${id}`,
      BrainBoostActions.getCourseSpacedRepetitionSuccess,
      BrainBoostActionTypes.GET_COURSE_SPACED_REPETITION_FAILURE
    )
  );
}

function* fetchBrainBoostSettings() {
  yield put(
    RequestActions.getAuthed(
      `api/brainboost/settings`,
      BrainBoostActionTypes.GET_BRAIN_BOOST_SETTINGS_SUCCESS,
      BrainBoostActionTypes.GET_BRAIN_BOOST_SETTINGS_FAILURE
    )
  );
}

function* updateBrainBoostSettings({
  payload: { slidesPerSession }
}: UpdateBrainBoostSettingsAction) {
  yield put(
    RequestActions.postAuthed(
      `api/brainboost/settings`,
      BrainBoostActionTypes.UPDATE_BRAIN_BOOST_SETTINGS_SUCCESS,
      BrainBoostActionTypes.UPDATE_BRAIN_BOOST_SETTINGS_FAILURE,
      false,
      { slidesPerSession }
    )
  );
}

function* updateHasSeenBrainBoostOnboarding() {
  yield put(
    RequestActions.postAuthed(
      `/api/users/visit-brain-boost`,
      BrainBoostActionTypes.SET_HAS_SEEN_BRAIN_BOOST_ONBOARDING_SUCCESS,
      BrainBoostActionTypes.SET_HAS_SEEN_BRAIN_BOOST_ONBOARDING_FAILURE
    )
  );
}

function* watchGetSpacedRepetitionForCourse() {
  yield takeLatest(
    BrainBoostActionTypes.GET_COURSE_SPACED_REPETITION,
    fetchSpacedRepetitionForCourse
  );
}

function* watchGetBrainBoostSettings() {
  yield takeLatest(BrainBoostActionTypes.GET_BRAIN_BOOST_SETTINGS, fetchBrainBoostSettings);
}

function* watchUpdatetBrainBoostSettings() {
  yield takeLatest(BrainBoostActionTypes.UPDATE_BRAIN_BOOST_SETTINGS, updateBrainBoostSettings);
}

function* watchHasSeenBBOnboarding() {
  yield takeLatest(
    BrainBoostActionTypes.SET_HAS_SEEN_BRAIN_BOOST_ONBOARDING,
    updateHasSeenBrainBoostOnboarding
  );
}

export const brainBoostSagas = [
  fork(watchGetSpacedRepetitionForCourse),
  fork(watchGetBrainBoostSettings),
  fork(watchUpdatetBrainBoostSettings),
  fork(watchHasSeenBBOnboarding)
];
