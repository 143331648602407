import * as React from 'react';

import styled, { css } from 'styled-components';

export type Props = {};

export const Footer: React.FC<React.PropsWithChildren<Props>> = ({ children }) => (
  <StyledFooter>{children}</StyledFooter>
);

const StyledFooter = styled.div(
  ({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${theme.space(3)}px;
  `
);
