import * as React from 'react';

import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import type { SelectComponentsConfig } from 'react-select/src/components';
import type { Styles as ReactSelectStyles } from 'react-select/src/styles';

import { useTheme } from '../../../hooks/useTheme';
import {
  ClearIndicator,
  DropdownIndicator,
  MultiValueContainer,
  MultiValueLabel,
  MultiValueRemove,
  Option,
  SearchableControl,
  SelectContainer,
  SingleValue,
  VirtualizedMenuList
} from './custom-components';
import stylesFn from './stylesFn';
import type { Option as OptionType, Props } from './types';
import { flatten, noOptionsFiltering, simpleOptionsFilter } from './utils';

export const VIRTUALIZED_THRESHOLD = 60;
export const SELECT_TEST_ID = 'select';

export function Select<OptionValueType>({
  className,
  options,
  isDisabled,
  disabled,
  filterOption,
  isBackendFiltering,
  isSearchable = true,
  components,
  styles,
  placeholder,
  ...rest
}: Props<OptionValueType>) {
  const theme = useTheme();
  const { t } = useTranslation();
  const customStyles: ReactSelectStyles = React.useMemo(() => stylesFn(theme), [theme]);
  // Add a 'disabled' prop to match the rest of our input components
  const internalDisabled = isDisabled || disabled;

  // Flatten any grouped options to determine how many rows will need to
  // be rendered, and render a virtualized list if it surpasses our limit
  const flattenedOptions = flatten(options);
  const flattenedOptionCount = (flattenedOptions && flattenedOptions.length) || 0;
  const isVirtualized = flattenedOptionCount >= VIRTUALIZED_THRESHOLD;

  // ⚠️ Warning: Do not add components as inline functions below!
  // Explanation here: https://react-select.com/components#defining-components
  const customComponents: SelectComponentsConfig<OptionType<OptionValueType>> = {
    SingleValue,
    MultiValueContainer,
    MultiValueRemove,
    ClearIndicator,
    MultiValueLabel,
    SelectContainer,
    IndicatorSeparator: undefined,
    DropdownIndicator: isSearchable ? undefined : DropdownIndicator,
    Control: SearchableControl,
    Option
  };

  if (isVirtualized) {
    customComponents.MenuList = VirtualizedMenuList;
  }

  return (
    <ReactSelect
      classNamePrefix="ed-select"
      className={className}
      styles={{ ...customStyles, ...styles }}
      components={{
        ...customComponents,
        ...components
      }}
      placeholder={placeholder ?? t('common.placeholder.select')}
      options={options}
      isDisabled={internalDisabled}
      isSearchable={isSearchable}
      filterOption={isBackendFiltering ? noOptionsFiltering : filterOption ?? simpleOptionsFilter}
      {...rest}
    />
  );
}
