import * as React from 'react';
import type { IconComponent } from '@edapp/ed-components';
import { Typography, Box } from '@edapp/ed-components';
import styled from 'styled-components';

const SettingItem: React.FC<{
  text: string;
  icon: IconComponent;
  href?: string;
  disabled?: boolean;
  target?: React.HTMLAttributeAnchorTarget;
  innerRef?: React.MutableRefObject<HTMLAnchorElement | null>;
  onClick?: () => void;
}> = ({ text, icon: IconComponent, disabled = false, onClick, innerRef, href, target }) => {
  const color = disabled ? 'textMuted' : 'textBlue';

  return (
    <Box disabled={disabled}>
      <Anchor ref={innerRef} href={href} target={target} onClick={onClick} disabled={disabled}>
        <IconComponent color={color} />
        <Typography ml={1} variant="normal" color={color}>
          {text}
        </Typography>
      </Anchor>
    </Box>
  );
};

const Anchor = styled.a<{ disabled: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
  &:hover {
    color: ${({ theme }) => theme.colors.blueHover};
  }
  &:focus {
    text-decoration: none;
  }
`;

export default SettingItem;
