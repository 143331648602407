import { call, fork, put, select, takeLatest } from 'redux-saga/effects';

import type { EdErrorResponseType } from '@edapp/authoring-logic';
import type { DrawsResponse } from '@rio/api/lottery/draws';
import { getLotteryDraws } from '@rio/api/lottery/draws';
import { getHippoCredentials } from '@rio/store/config/selectors';

import { ActionType, fetchUserGroupDrawsFailure, fetchUserGroupDrawsSuccess } from './actions';

function* handleFetchUserGroupDraws() {
  const { hippoUrl, userToken } = getHippoCredentials(yield select());

  try {
    const response: DrawsResponse = yield call(getLotteryDraws, hippoUrl, userToken);
    yield put(fetchUserGroupDrawsSuccess(response));
  } catch (error) {
    yield put(fetchUserGroupDrawsFailure(error as EdErrorResponseType));
  }
}

function* watchFetchUserGroupDraws() {
  yield takeLatest(ActionType.FETCH_USER_GROUP_DRAWS, handleFetchUserGroupDraws);
}

export const sagas = [fork(watchFetchUserGroupDraws)];
