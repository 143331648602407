import type { LmsStoreState } from '@rio/store/types';

export const getCustomAchievement = (state: LmsStoreState) =>
  state.customAchievements.achievementState.achievement;

export const getCustomAchievementIsPublishModalOpen = (state: LmsStoreState) =>
  state.customAchievements.achievementState.isPublishModalOpen;
export const getCustomAchievementDescription = (state: LmsStoreState) =>
  state.customAchievements.achievementState.achievement?.description;

export const getCustomAchievementRecipes = (state: LmsStoreState) =>
  state.customAchievements.achievementState.achievement?.recipes;
export const getCustomAchievementId = (state: LmsStoreState) =>
  state.customAchievements.achievementState.achievement?.id;
export const getCustomAchievementTranslations = (state: LmsStoreState) =>
  state.customAchievements.achievementState.achievement?.translations;
export const getCustomAchievementAchievers = (state: LmsStoreState) =>
  state.customAchievements.achievementState.achievement?.achievers || [];

export const getCustomAchievementsList = (state: LmsStoreState) =>
  state.customAchievements.achievementsListState.achievementsList;

export const getCustomAchievementsValidation = (state: LmsStoreState) =>
  state.customAchievements.achievementState.validationState;

export const getCustomAchievementIsLocked = (state: LmsStoreState) =>
  state.customAchievements.achievementState.achievement?.isLocked;

export const getCustomAchievementsTitleValidation = (state: LmsStoreState) =>
  state.customAchievements.achievementState.validationState.title;

export const getCustomAchievementsIsIsSaving = (state: LmsStoreState) =>
  state.customAchievements.achievementState.isSaving;

export const getCustomAchievementsIsTranslationLoading = (state: LmsStoreState) =>
  state.customAchievements.achievementState.isTranslationLoading;

export const getCustomAchievementDefaultLocale = (state: LmsStoreState) =>
  state.customAchievements.achievementState.achievement?.defaultLocale;

export const getCustomAchievementIsModified = (state: LmsStoreState) =>
  state.customAchievements.achievementState.isModified;

export const getCustomAchievementsAchievementError = (state: LmsStoreState) =>
  state.customAchievements.achievementState.error;

export const getCustomAchievementsAchievementIsLoading = (state: LmsStoreState) =>
  state.customAchievements.achievementState.isLoading;

export const getCustomAchievementsListError = (state: LmsStoreState) =>
  state.customAchievements.achievementsListState.error;

export const getCustomAchievementsListIsLoading = (state: LmsStoreState) =>
  state.customAchievements.achievementsListState.isLoading;

export const getCustomAchievementsListIsDeleting = (state: LmsStoreState) =>
  state.customAchievements.achievementsListState.isDeleting;

export const getCustomAchievementsComponentsError = (state: LmsStoreState) =>
  state.customAchievements.componentsState.error;

export const getCustomAchievementsComponentsIsLoading = (state: LmsStoreState) =>
  state.customAchievements.componentsState.isLoading;

export const getCustomAchievementsComponents = (state: LmsStoreState) =>
  state.customAchievements.componentsState.components;

export const getHasViewedCustomAchievements = (state: LmsStoreState) =>
  state.customAchievements.achievementsListState.hasViewedCA;
