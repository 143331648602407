import styled from 'styled-components';
import { Typography } from '../../typography/Typography';
import type { LabelProps } from './types';

export const LABEL_TEST_ID = 'label';

export const Label = styled(Typography).attrs<LabelProps>(
  ({ testId = LABEL_TEST_ID, mb = 0.5, variant = 'subtitle1' }) => ({
    'data-testid': testId,
    as: 'label',
    variant,
    mb
  })
)<LabelProps>`
  display: flex;
`;
