import type { BillingPlanDetailsType } from './types';
import { RequestUtils } from '@edapp/request';

export function getBillingDetails(
  hippoUrl: string,
  userToken: string
): Promise<BillingPlanDetailsType> {
  return RequestUtils.httpFetch<BillingPlanDetailsType>(
    'GET',
    `${hippoUrl}/api/billing/plan`,
    userToken
  );
}
