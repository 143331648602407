// Action Types
export enum ConfigActionTypes {
  WINDOW_RESIZED = 'WINDOW_RESIZED',

  FETCH_USER_TOKEN = 'FETCH_USER_TOKEN',
  FETCH_USER_TOKEN_FULFILLED = 'FETCH_USER_TOKEN_FULFILLED',
  FETCH_USER_TOKEN_FAILED = 'FETCH_USER_TOKEN_FAILED',

  SET_TOKEN = 'SET_TOKEN',

  UPDATE_USER_FIRST_AND_LAST_NAME = 'UPDATE_USER_FIRST_AND_LAST_NAME',

  FETCH_LATEST_INVOICE = 'FETCH_LATEST_INVOICE',
  FETCH_LATEST_INVOICE_FULFILLED = 'FETCH_LATEST_INVOICE_FULFILLED',
  FETCH_LATEST_INVOICE_FAILED = 'FETCH_LATEST_INVOICE_FAILED',

  RETRY_PAY_INVOICE = 'RETRY_PAY_INVOICE',
  RETRY_PAY_INVOICE_FULFILLED = 'RETRY_PAY_INVOICE_FULFILLED',
  RETRY_PAY_INVOICE_FAILED = 'RETRY_PAY_INVOICE_FAILED',

  UPDATE_USER_DATA = 'UPDATE_USER_DATA',
  UPDATE_USER_INFO_LOADING = 'UPDATE_USER_LOADING',
  UPDATE_APPLICATION_DATA = 'UPDATE_APPLICATION_DATA',
  UPDATE_APPLICATION_LOADING = 'UPDATE_APPLICATION_LOADING',

  UPDATE_BILLING_DATA = 'UPDATE_BILLING_DATA',
  UPDATE_BILLING_LOADING = 'UPDATE_BILLING_LOADING'
}

// Endpoints
export const fetchUserTokenUri = () => `user/current/token`;
export const createNewCourseModule = () => `api/modules`;
export const fetchLatestInvoiceUri = () => `api/billing/invoice/latest`;
export const retryPayInvoiceUri = (invoiceId: string) => `api/billing/invoice/${invoiceId}/retry`;
