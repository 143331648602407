import * as React from 'react';
import styled, { css } from 'styled-components';

import { CoursewareCount } from '../../summary-card/courseware-count/CoursewareCount';
import {
  Box,
  CoursewareStatus,
  CoursewareStatusLabel,
  Thumbnail,
  Typography
} from '@edapp/ed-components';
import { workaroundImagePath } from './utils';
import type { PermissionResourcesType } from '@edapp/permissions';
import { CoursewareCardActions } from './CoursewareCardActions';

export type LandscapeCoursewareCardProps = {
  id: string;
  collectionId: string;
  title: string;
  description?: string;
  customAction?: React.ReactNode;
  thumbnail?: string;
  coursewareCount: number;
  coursewareCountType?: CoursewareCountType;
  isPublished?: boolean;
  coursewareStatus?: CoursewareStatus;
  showCopyCourse?: boolean;
  showDelete?: boolean;
  onClick?: () => void;
  className?: string;
  featuredText?: React.ReactNode | string;
  resourceId?: string;
  resource?: PermissionResourcesType;
  isDragging?: boolean;
  isStacked?: boolean;
  handleDeleteCourse?: (id: string) => void;
  handleDeleteCourseCollection?: (id: string, title: string, count: number) => void;
  handleDuplicateCourse?: (id: string, collectionId?: string) => void;
};

export type CoursewareCountType = 'lesson' | 'course' | 'none';

export const getTestId = (id: string) => `landscape-courseware-card-contents-${id}`;

const LandscapeCoursewareCard: React.FC<LandscapeCoursewareCardProps> = React.forwardRef(
  (
    {
      title,
      description,
      customAction,
      thumbnail,
      coursewareCount,
      coursewareCountType,
      isPublished: isPublishedFlag,
      coursewareStatus = !!isPublishedFlag ? CoursewareStatus.PUBLISHED : CoursewareStatus.DRAFT,
      showCopyCourse,
      showDelete,
      onClick,
      id,
      collectionId,
      className,
      featuredText,
      resource,
      resourceId,
      isDragging,
      isStacked = false,
      handleDeleteCourse,
      handleDeleteCourseCollection,
      handleDuplicateCourse
    },
    ref: React.Ref<HTMLDivElement>
  ) => {
    const cardThumbnailStyle =
      !!thumbnail && typeof thumbnail === 'string' ? `url(${workaroundImagePath(thumbnail)})` : '';

    const isPublished = coursewareStatus === CoursewareStatus.PUBLISHED;

    const coursewareCountColor = !isPublished || isStacked ? 'textMuted' : 'text';

    return (
      <>
        <ClassWrapper ref={ref} className={className}>
          <CoursewareCardContents
            rounded
            border
            bgColor="white"
            onClick={onClick}
            isStacked={isStacked}
            testId={getTestId(id)}
            boxShadow={isDragging ? 'deep' : undefined}
          >
            <ThumbWrapper>
              <Thumbnail
                imageUrl={cardThumbnailStyle}
                disabled={!isPublished}
                isStacked={isStacked}
              />
              {!isPublished && !isStacked && (
                <StyledCoursewareStatusLabel coursewareStatus={coursewareStatus} />
              )}
            </ThumbWrapper>
            <TextContentContainer>
              <FeaturedText variant="subtext" color={'textMuted'}>
                {featuredText}
              </FeaturedText>
              <StyledCardTitle
                isPublished={isPublished}
                isStacked={isStacked}
                variant="subtitle1"
                testId="cardTitle"
              >
                {title}
              </StyledCardTitle>
              <StyledDescription color={'textMuted'} variant="small" testId="cardDescription">
                {description}
              </StyledDescription>
              <ItemCountAndSelect
                justifyContent={coursewareCountType !== 'none' ? 'space-between' : 'flex-end'}
              >
                {coursewareCountType !== 'none' && (
                  <CoursewareCount
                    coursewareCount={coursewareCount}
                    coursewareCountType={coursewareCountType}
                    color={coursewareCountColor}
                  />
                )}
                {customAction && customAction}
                <CoursewareCardActions
                  id={id}
                  collectionId={collectionId}
                  title={title}
                  description={description}
                  thumbnail={thumbnail}
                  contentProvider={featuredText as string}
                  coursewareCount={coursewareCount}
                  showCopyCourse={showCopyCourse}
                  showDelete={showDelete}
                  resource={resource || 'COURSE'}
                  resourceId={resourceId ?? id}
                  handleDeleteCourse={handleDeleteCourse}
                  handleDeleteCourseCollection={handleDeleteCourseCollection}
                  handleDuplicateCourse={handleDuplicateCourse}
                />
              </ItemCountAndSelect>
            </TextContentContainer>
          </CoursewareCardContents>
        </ClassWrapper>
      </>
    );
  }
);

const ClassWrapper = styled.div`
  z-index: 1;
  position: relative;
`;

const CoursewareCardContents = styled(Box)<{ isStacked?: boolean }>`
  ${({ theme, isStacked }) => css`
    display: flex;
    position: relative;
    flex-direction: row;
    padding: ${theme.space(2)}px;
    background-color: ${theme.colors.white};
    cursor: pointer;
    transition: box-shadow 300ms ease-in-out;

    &:hover {
      box-shadow: ${theme.shadows.middle};
    }

    ${isStacked &&
    `
      &::before {
        content: "";
        width: 100%;
        height: 100%;
        border: 1px solid ${theme.colors.greyHover};
        background-color: ${theme.colors.white};
        border-radius: ${theme.sizes.borderRadius}px;
        box-shadow: ${theme.shadows.shallow};
        position: absolute;
        left: ${theme.space()}px;
        top: ${theme.space()}px;
        z-index: -2;
        
      }
      &::after {
        content: "";
        width: 100%;
        height: 100%;
        border: 1px solid ${theme.colors.greyHover};
        background-color: ${theme.colors.white};
        border-radius: ${theme.sizes.borderRadius}px;
        box-shadow: ${theme.shadows.shallow};
        position: absolute;
        top: ${theme.space(0.5)}px;
        left: ${theme.space(0.5)}px;
        z-index: -1;
      }
      `}
  `}
`;

const ThumbWrapper = styled.div`
  position: relative;
`;

const StyledCoursewareStatusLabel = styled(CoursewareStatusLabel)`
  ${({ theme }) => css`
    position: absolute;
    bottom: ${theme.space()}px;
    right: ${theme.space()}px;
  `}
`;

const TextContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: hidden;
  margin-left: ${({ theme }) => theme.space(2)}px;
  width: 100%;
`;

const FeaturedText = styled(Typography)`
  padding-bottom: ${({ theme }) => theme.space(0.5)}px;
`;

const StyledCardTitle = styled(Typography)<{
  isPublished: boolean | undefined;
  isStacked: boolean;
}>`
  ${({ theme, isPublished, isStacked }) => css`
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    display: block;
    margin: 0;
    color: ${isPublished || isStacked ? theme.colors.text : theme.colors.textMuted};

    @supports (-webkit-line-clamp: 2) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  `}
`;

const StyledDescription = styled(Typography)`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  display: block;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

const ItemCountAndSelect = styled.div<{ justifyContent: 'space-between' | 'flex-end' }>`
  margin-top: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: center;
  width: 100%;
`;

export default styled(LandscapeCoursewareCard)(
  ({ theme }) => css`
    width: 100%;
    user-select: none;
    margin-bottom: ${theme.space(3)}px;
    background-color: ${theme.colors.white};
    border-radius: ${theme.sizes.borderRadius}px;
  `
);
