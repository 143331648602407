import { Button } from '@edapp/ed-components';
import styled, { css } from 'styled-components';

export const CustomisableButton = styled(Button)<{ color?: string }>(
  ({ color = 'white' }) => css`
    color: ${color};
    border-color: ${color};
    :not([disabled]):hover,
    :active {
      color: ${color};
    }
  `
);
CustomisableButton.defaultProps = {
  variant: 'pale'
};
