import * as React from 'react';
import styled, { useTheme } from 'styled-components';
import { WindowedList } from '../../../windowed-list/WindowedList';
import type { Option, InternalOptions, InternalOption } from '../types';
import { BOX_HEIGHT } from './SelectableListBox';
import { SelectableListOptionGroup } from './SelectableListOptionGroup';
import { SelectableListOption } from './SelectableListOption';
import type { ScrollState } from '../../../windowed-list/types';

type Props<OptionValue> = {
  items: InternalOptions<OptionValue>;
  disabled?: boolean;
  itemTestId?: string;
  onItemClick: (item: Option<OptionValue>) => void;
  onChangeScrollState?: (scrollState: ScrollState) => void;
};

// The component takes a type argument to know the type of the option values passed in
export function SelectableListItems<OptionValue>({
  items,
  disabled,
  itemTestId,
  onItemClick,
  onChangeScrollState
}: Props<OptionValue>) {
  const theme = useTheme();

  const labelIconStyle = {
    marginRight: `${theme.space()}px`,
    minWidth: `${theme.space(2)}px`,
    minHeight: `${theme.space(2)}px`
  };

  const Item = (option: InternalOption<OptionValue>) =>
    'options' in option ? (
      <SelectableListOptionGroup
        key={option.key}
        option={option}
        disabled={disabled}
        labelIconStyle={labelIconStyle}
        itemTestId={itemTestId}
        onItemClick={onItemClick}
      />
    ) : (
      <SelectableListOption
        key={option.key}
        option={option}
        disabled={disabled}
        labelIconStyle={labelIconStyle}
        testId={itemTestId}
        onClick={onItemClick}
      />
    );

  return !items.options.some(option => option && 'options' in option) ? (
    <WindowedList
      width="100%"
      height={BOX_HEIGHT}
      itemCount={items.count}
      itemSize={40}
      itemData={items}
      onChangeScrollState={onChangeScrollState}
    >
      {({ data, index, style }) => {
        const option = data.options[index];
        return !!option ? <div style={style}>{Item(option)}</div> : null;
      }}
    </WindowedList>
  ) : (
    <List>{items.options.map(Item)}</List>
  );
}

const List = styled.div`
  overflow: auto;
`;
