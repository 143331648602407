import type { ActionDialogProps, ButtonVariant } from '@edapp/ed-components';
import { ActionDialog, CircleTickIcon } from '@edapp/ed-components';
import * as React from 'react';

export type SuccessDialogProps = {
  onConfirm?: () => void;
  confirmText: string;
  confirmButtonVariant?: ButtonVariant;
} & Pick<ActionDialogProps, 'isOpen' | 'title' | 'description'> & {
    testId?: string;
  };

export const SuccessDialog: React.FC<SuccessDialogProps> = ({
  onConfirm,
  confirmText,
  confirmButtonVariant = 'primary',
  ...props
}) => (
  <ActionDialog
    {...props}
    headerIcon={CircleTickIcon}
    headerIconColor="green"
    cancelButtonVariant={confirmButtonVariant}
    cancelText={confirmText}
    onCancel={onConfirm}
    onClose={onConfirm}
  />
);
