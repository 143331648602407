import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { Box } from '../box';
import type { CSSProperties } from 'react';

export const CarouselContent = styled(motion.div)`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const CarouselContents = styled(Box)`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

export const FullWidthBox = styled(Box)`
  width: 100%;
`;

export const Dot = styled.div<{ isActive: boolean }>(
  ({ theme, isActive }) => css`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${isActive ? theme.colors.blue : theme.colors.grey};
    transition: background-color 250ms ease-in-out;

    :not(:last-child) {
      margin-right: ${theme.space(1)}px;
    }
  `
);

export const ContentContainer = styled(motion.div).withConfig({
  shouldForwardProp: (prop, defaultValidator) =>
    (prop as string) !== 'overflow' && defaultValidator(prop)
})<{
  isAnimating: boolean;
  overflow: CSSProperties['overflow'];
}>`
  overflow: ${({ isAnimating, overflow }) => (isAnimating ? 'hidden' : overflow)};
  position: relative;
`;
