import * as React from 'react';

import styled from 'styled-components';

import { useNavbarContext } from '../context';
import { SubMenuOptionItem } from '../styled';
import type { NavItemType } from '../types';

type Props = {
  item: NavItemType;
};

export const SubMenuItem: React.FC<Props> = ({ item }) => {
  const currentPath = window.location.pathname;

  const { canShowUpgradeActions, isMenuSectionPermitted } = useNavbarContext();
  const hasAccess = isMenuSectionPermitted(item);
  if (!hasAccess && (!canShowUpgradeActions || item.hideIfDisabled)) {
    return null;
  }

  return (
    <SubMenuOptionItem disabled={!hasAccess} isActive={item.link === currentPath}>
      <Link disabled={!hasAccess} href={item.link}>
        {item.title}
      </Link>
    </SubMenuOptionItem>
  );
};

const Link = styled.a<{ disabled: boolean }>`
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'visible')};
`;
