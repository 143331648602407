import type { PlaylistItemType } from './types';

export const completionText = 'Congratulations! You have completed this path.';

export const initialPlaylist: PlaylistItemType = {
  id: '',
  title: '',
  description: '',
  isPublished: false,
  coverImage: '',
  coverImageTextColor: 'white',
  universalAccessEnabled: true,
  userGroups: [],
  completionImage: '',
  completionText,
  defaultLocale: null,
  translations: null,
  playlistType: 'Sequential',
  isAcknowledgedWarning: false
};
