/* istanbul ignore file */
import type {
  ActionableReportItem,
  AdditionalActionableReportsProps
} from '../actionableReports/types';

import type { Surveys, SurveysFilterState } from '../surveys/types';
import type { Filters, FilterType } from './filters';

export enum AnalyticsPageType {
  ACTIONABLE_REPORTS = 'actionableReports',
  SURVEYS = 'surveys'
}

export type AnalyticsState = {
  [AnalyticsPageType.ACTIONABLE_REPORTS]: AnalyticsReportType<
    Pick<Filters, FilterType.COURSES_FILTER | FilterType.USER_GROUPS_FILTER>,
    ActionableReportItem,
    AdditionalActionableReportsProps
  >;

  [AnalyticsPageType.SURVEYS]: {
    data: AnalyticsData<Surveys>;
    filter: SurveysFilterState;
  };
};

export type AnalyticsReportType<TF, TD, AdditionalProps> = {
  filters: TF;
  data: Omit<PaginatedResponse<TD>, 'currentPage'> & Pagination & ResponseState;
} & AdditionalProps;

export type AnalyticsData<TD> = {
  items: TD;
  totalCount: number;
} & Pagination &
  ResponseState;

type ResponseState = {
  error: string;
  isLoading: boolean;
};

// * PAGINATION
export type Pagination = {
  page: number;
  pageSize: number;
};
