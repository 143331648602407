import * as React from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import type { SelectableListOption } from '@edapp/ed-components';
import { SelectableList, Typography } from '@edapp/ed-components';
import type { RequestTypes } from '@edapp/request';
import { flattenPages } from '@edapp/request';
import type {
  SearchUserDetailResponse,
  SearchUserDetailsResponse
} from '@rio/api/user-groups/users';
import { UserType, useGetUserGroupUsers } from '@rio/api/user-groups/users';
import type { LmsStoreState } from '@rio/store/types';
import { setUserGroupItem } from '@rio/store/userGroups/actions';
import { getUserGroupId } from '@rio/store/userGroups/selectors';
import { useDebounce } from '@rio/utils/hooks';
import type { InfiniteData } from '@tanstack/react-query';

import { useSelectableListCounter } from './hooks';

type OptionValue = string;
type Option = SelectableListOption<OptionValue>;

const pageSize = 25;

function mapOption({ id, name }: SearchUserDetailResponse): Option {
  return {
    key: id,
    value: id,
    label: name
  };
}

function mapOptions(
  options: Option[],
  { items }: RequestTypes.PaginatedResponse<SearchUserDetailResponse>
): Option[] {
  return items.reduce<Option[]>(
    (acc, cur) => (acc.every(({ value }) => value !== cur.id) ? [...acc, mapOption(cur)] : acc),
    options
  );
}

export const Users: React.FC = () => {
  const { t } = useTranslation();
  const userGroupId = useSelector(getUserGroupId);
  const isAccountGroup = useSelector(
    (store: LmsStoreState) => store.userGroups.userGroupItem.usergroup.isAccountGroup
  );
  const selectedUserIds = useSelector(
    (store: LmsStoreState) => store.userGroups.userGroupItem.userIds
  );
  const appSSO = useSelector((store: LmsStoreState) => store.userGroups.userGroupItem.app.sso);

  const [options, setOptions] = React.useState<Option[]>([]);

  const [userName, setUserName] = React.useState<string | undefined>(undefined);
  const debouncedUserName = useDebounce(userName, 500);

  const handleSetOptions = (data: InfiniteData<SearchUserDetailsResponse>) => {
    setOptions(prev => mapOptions(prev, flattenPages(data)));
  };

  const selected = useGetUserGroupUsers(
    userGroupId,
    {
      pageSize,
      unSelected: false,
      userName: debouncedUserName,
      userType: UserType.Users
    },
    {
      staleTime: Infinity,
      onSuccess: handleSetOptions
    }
  );

  const unselected = useGetUserGroupUsers(
    userGroupId,
    {
      pageSize,
      unSelected: true,
      userName: debouncedUserName,
      userType: UserType.Users
    },
    {
      staleTime: Infinity,
      onSuccess: handleSetOptions
    }
  );

  const { selectedCount, unselectedCount, handleSelect, handleDeselect } = useSelectableListCounter(
    selected.totalCount,
    unselected.totalCount,
    userName
  );

  const unselectedIsLoading = unselected.isLoading || unselected.isFetchingNextPage;
  const selectedIsLoading = selected.isLoading || selected.isFetchingNextPage;

  const dispatch = useDispatch();

  const handleUsersChange = (userIds: string[]) => {
    dispatch(setUserGroupItem({ userIds }));
  };

  const handleSelectUser = (userId: OptionValue) => {
    const user = options.find(({ value }) => value === userId);

    if (!user) {
      throw new Error(`Couldn't find user: ${userId}`);
    }

    handleUsersChange([...selectedUserIds, userId]);
    handleSelect(user);
  };

  const handleClearUser = (userId: OptionValue) => {
    const user = options.find(({ value }) => value === userId);

    if (!user) {
      throw new Error(`Couldn't find user: ${userId}`);
    }

    handleUsersChange(selectedUserIds.filter(id => id !== userId));
    handleDeselect(user);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.currentTarget.value || undefined);
  };

  const handleClearFilter = () => {
    setUserName(undefined);
  };

  const handleSelectedChange = (courses: OptionValue[]) => {
    if (courses.length < pageSize && !selectedIsLoading && selected.hasNextPage) {
      selected.fetchNextPage?.();
    }
  };

  const handleUnselectedChange = (courses: OptionValue[]) => {
    if (courses.length < pageSize && !unselectedIsLoading && unselected.hasNextPage) {
      unselected.fetchNextPage?.();
    }
  };

  const ssoEnabled = appSSO.enabled && appSSO.assignGroups;

  return (
    <div>
      {!!ssoEnabled && (
        <p className="help-block">{t('user-groups.select-users.sso-enabled-info')}</p>
      )}

      <label>{t('user-groups.select-users.title')}</label>
      {!isAccountGroup && (
        <>
          <Typography as="p" color="textMuted" mb={1}>
            {t('user-groups.select-users.search-label')}
          </Typography>
          <SelectableList<OptionValue>
            options={options}
            selectedValues={selectedUserIds}
            disabled={!!isAccountGroup}
            unselectedBox={{
              title: t('user-groups.select-users.all-users'),
              showCount: unselectedCount,
              onSelect: handleSelectUser,
              onChange: handleUnselectedChange,
              onLoadMore: unselected.fetchNextPage
            }}
            selectedBox={{
              title: t('user-groups.select-users.selected-users'),
              showCount: selectedCount,
              onClear: handleClearUser,
              onChange: handleSelectedChange,
              onLoadMore: selected.fetchNextPage
            }}
            filter={{
              placeholder: t('user-groups.select-users.search-placeholder'),
              value: userName,
              onChange: handleFilterChange,
              onClear: handleClearFilter
            }}
            loading={unselectedIsLoading || selectedIsLoading}
          />
        </>
      )}

      {!!isAccountGroup && (
        <div className="text-muted">
          <Trans
            i18nKey="user-groups.default-all-users-group.no-editing-allowed-message"
            components={{ bold: <strong /> }}
          />
          <div>
            {t('user-groups.default-all-users-group.total-users', {
              count: selected.totalCount ?? 0
            })}
          </div>
        </div>
      )}
    </div>
  );
};
