import * as React from 'react';
import type { MenuItemType } from '../types';
import { useNavbarContext } from '../context';
import { MenuItemWithSubmenu } from './MenuItemWithSubmenu';

type Props = {
  item: MenuItemType;
};

export const MenuItem: React.FC<Props> = ({ item }) => {
  const { isMenuSectionPermitted } = useNavbarContext();

  const isVisible = isMenuSectionPermitted(item);
  if (!isVisible) {
    return null;
  }

  if (item.submenu || item.submenuGroups) {
    return <MenuItemWithSubmenu item={item} />;
  }

  return (
    <li key={item.title.toLowerCase() + '-menu-item'} id={item.title.toLowerCase() + '-menu-item'}>
      <a href={item.link}>{item.title}</a>
    </li>
  );
};
