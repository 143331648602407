import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import type { HippoError, HippoQueryFunction, UseHippoQueryResult } from '@edapp/request';
import { getEmilyCmsCredentials } from '@rio/store/config/selectors';
import type { MutationFunction, QueryFunction, QueryKey } from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';

import type {
  EmilyMutationFunction,
  EmilyQueryFunction,
  UseEmilyMutationOptions,
  UseEmilyMutationResult,
  UseEmilyQueryOptions
} from './types';

export const getEmilyCsrfToken = () =>
  document.querySelector<HTMLInputElement>('input[name="_csrf"]')?.value;

function useEmilyQueryFn<TResponse>(
  queryFn: HippoQueryFunction<TResponse>
): QueryFunction<TResponse> {
  const { emilyCmsUrl, userToken } = useSelector(getEmilyCmsCredentials);
  return queryFn(emilyCmsUrl, userToken);
}

function useEmilyMutationFn<TResponse, TRequest>(
  mutationFn: EmilyMutationFunction<TResponse, TRequest>
): MutationFunction<TResponse, TRequest> {
  const csrfToken = getEmilyCsrfToken();
  const { emilyCmsUrl, userToken } = useSelector(getEmilyCmsCredentials);
  return mutationFn(emilyCmsUrl, userToken, csrfToken);
}

export function useEmilyQuery<TResponse>(
  queryKey: QueryKey,
  queryFn: EmilyQueryFunction<TResponse>,
  options?: UseEmilyQueryOptions<TResponse>
): UseHippoQueryResult<TResponse> {
  const query = useQuery<TResponse, Error>({
    queryKey,
    queryFn: useEmilyQueryFn(queryFn),
    ...options
  });

  // onSuccess
  useEffect(() => {
    if (!query.isSuccess) {
      return; // nothing to do
    }

    options?.onSuccess?.(query.data);
  }, [options, query.data, query.isSuccess]);

  return query;
}

export function useEmilyMutation<TResponse, TRequest, TError = HippoError>(
  mutationFn: EmilyMutationFunction<TResponse, TRequest>,
  options?: UseEmilyMutationOptions<TResponse, TRequest, TError>
): UseEmilyMutationResult<TResponse, TRequest, TError> {
  const mutation = useMutation<TResponse, TError, TRequest>({
    mutationFn: useEmilyMutationFn(mutationFn),
    ...options
  });

  return {
    ...mutation,
    isLoading: mutation.status === 'pending'
  };
}
