import { put, fork, takeLatest } from 'redux-saga/effects';
import { RequestActions } from '@edapp/request';
import type {
  FetchCoursePublicDetailAction,
  CreateCoursePublicDetailAction,
  UpdateCoursePublicDetailAction
} from './actions';
import { ActionType } from './actions';
import { sagas as courseLinkSagas } from './course-links/sagas';
import { sagas as excludedLessonsSagas } from './excluded-lessons/sagas';
import { sagas as excludedSlidesSagas } from './excluded-slides/sagas';
import { sagas as ratingLinksSagas } from './rating-links/sagas';
import { sagas as urlSlugsSagas } from './url-slugs/sagas';

const publicDetails = (courseId: string) => `/api/courses/${courseId}/public-details`;
const publicDetail = (courseId: string, id: string) => `${publicDetails(courseId)}/${id}`;

function* handleFetchPublicDetail({ payload }: FetchCoursePublicDetailAction) {
  yield put(
    RequestActions.getAuthed(
      publicDetails(payload.courseId),
      ActionType.FETCH_COURSE_PUBLIC_DETAIL_SUCCESS,
      ActionType.FETCH_COURSE_PUBLIC_DETAIL_FAILURE
    )
  );
}

function* handleCreatePublicDetail({ payload }: CreateCoursePublicDetailAction) {
  yield put(
    RequestActions.postAuthed(
      publicDetails(payload.courseId),
      ActionType.CREATE_COURSE_PUBLIC_DETAIL_SUCCESS,
      ActionType.CREATE_COURSE_PUBLIC_DETAIL_FAILURE,
      false,
      payload
    )
  );
}

function* handleUpdatePublicDetail({ payload }: UpdateCoursePublicDetailAction) {
  yield put(
    RequestActions.patchAuthed(
      publicDetail(payload.courseId, payload.id),
      ActionType.UPDATE_COURSE_PUBLIC_DETAIL_SUCCESS,
      ActionType.UPDATE_COURSE_PUBLIC_DETAIL_FAILURE,
      false,
      payload
    )
  );
}

function* watchFetchPublicDetail() {
  yield takeLatest(ActionType.FETCH_COURSE_PUBLIC_DETAIL, handleFetchPublicDetail);
}

function* watchCreatePublicDetail() {
  yield takeLatest(ActionType.CREATE_COURSE_PUBLIC_DETAIL, handleCreatePublicDetail);
}

function* watchUpdatePublicDetail() {
  yield takeLatest(ActionType.UPDATE_COURSE_PUBLIC_DETAIL, handleUpdatePublicDetail);
}

export const sagas = [
  fork(watchFetchPublicDetail),
  fork(watchCreatePublicDetail),
  fork(watchUpdatePublicDetail),
  ...courseLinkSagas,
  ...excludedLessonsSagas,
  ...excludedSlidesSagas,
  ...ratingLinksSagas,
  ...urlSlugsSagas
];
