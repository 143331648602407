import * as React from 'react';
import { Box } from '@edapp/ed-components';

type Props = {
  className?: string;
};

const FormGroup: React.FC<React.PropsWithChildren<Props>> = ({ className, children }) => {
  return (
    <Box className={className} pb="sm">
      {children}
    </Box>
  );
};

export { FormGroup };
