import * as React from 'react';

import { Typography } from '@edapp/ed-components';
import { pluralize } from 'humanize-plus';
import type { CoursewareCountType } from '../../types';
import type { ColorsKeys } from '@edapp/themes';

type Props = {
  coursewareCount: number;
  coursewareCountType?: CoursewareCountType;
  color?: ColorsKeys;
};

export const CoursewareCount: React.FC<Props> = ({
  coursewareCount,
  coursewareCountType = 'lesson',
  color
}) => (
  <Typography variant="subtitle2" color={color || 'text'}>{`${coursewareCount} ${pluralize(
    coursewareCount,
    coursewareCountType,
    `${coursewareCountType}s`
  )}`}</Typography>
);
