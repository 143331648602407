import React from 'react';

import { Form } from 'react-final-form';
import type { FormProps } from 'react-final-form';
import styled, { css } from 'styled-components';

import { InfoBox } from '@edapp/courseware-ui';
import { Box, Button, Label, Typography } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';
import { validators } from '@edapp/utils';
import { useLogin } from '@rio/api/accounts/authentication';
import { PasswordFormInput } from '@rio/components/ed/password-input/PasswordFormInput';
import { FormInput } from '@rio/components/form/FormInput';

type Fields = {
  email: string;
  password: string;
};

type Props = {
  email?: string;
  onSuccess: () => void;
  onClickForgetPassword: () => void;
};

export const LoginModal: React.FC<Props> = ({ email = '', onSuccess, onClickForgetPassword }) => {
  const { mutateAsync: login } = useLogin();
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [invalidLogin, setInvalidLogin] = React.useState(false);

  const handleSubmit: FormProps<Fields>['onSubmit'] = React.useCallback(async values => {
    setInvalidLogin(false);
    try {
      const response = await login({ nameOrEmail: values.email, password: values.password });
      if (response === true) {
        setIsSubmitted(true);
        onSuccess();
      } else if (typeof response === 'object' && response.error_message) {
        if (response.error_message.includes('Invalid username/password')) {
          setInvalidLogin(true);
          return;
        }
        throw new Error(response.error_message);
      } else {
        throw new Error('');
      }
    } catch (err) {
      if (err?.statusCode === 403) {
        return { password: 'Session expired, please refresh the page' };
      }
      return { password: err.message || 'An unexpected error has occurred.' };
    }
  }, []);

  return (
    <StyledContainer>
      <StyledTitle variant="titleLarge" component="h2">
        Log in
      </StyledTitle>
      <Typography>Log in to begin reviewing this course.</Typography>
      {invalidLogin && (
        <StyledErrorBox
          title="Uh-oh"
          description="Your email or password is incorrect."
          variant="error"
        />
      )}

      <Form<Fields>
        initialValues={{ email }}
        onSubmit={handleSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <StyledForm onSubmit={handleSubmit}>
              <Label htmlFor="email">Email</Label>
              <FormInput
                name="email"
                placeholder="Email"
                isFullWidth
                disabled={!!email}
                validate={validators.required}
              />
              <Box mt={2} />
              <Label htmlFor="password">Password</Label>
              <PasswordFormInput
                name="password"
                validate={validators.required}
                placeholder="Password"
                isFullWidth
              />
              <Box flex justifyContent="space-between" mt={3} alignItems="center">
                <Box flex flexDirection="column">
                  <Typography
                    role="button"
                    isLink
                    color="blue"
                    tabIndex={0}
                    onClick={onClickForgetPassword}
                  >
                    Forgot your password?
                  </Typography>
                  <Typography
                    role="button"
                    isLink={true}
                    as="a"
                    href={'/sso-login?coming-from=' + window.location.pathname}
                    color="blue"
                    tabIndex={0}
                  >
                    Log in with SSO
                  </Typography>
                </Box>
                <Button
                  variant="primary"
                  size="md"
                  type="submit"
                  isLoading={submitting || isSubmitted}
                >
                  Let’s do this
                </Button>
              </Box>
            </StyledForm>
          );
        }}
      />
    </StyledContainer>
  );
};

const StyledTitle = styled(Text)`
  margin-top: 0px;
`;

const StyledContainer = styled(Box)`
  text-align: left;
`;

const StyledForm = styled.form(
  ({ theme }) => css`
    margin-top: ${theme.space(3)}px;
  `
);

const StyledErrorBox = styled(InfoBox)`
  ${({ theme }) => css`
    margin-top: ${theme.space(3)}px;
  `}
`;
