// This function is a workaround until the back-end is ready to handle assets
// We have three different ways to handle assets
// 1) Old Emily Images (save) - it saves only filename and it's hardcoded in the jade files to append "/images/" in the front
// 2) New Emily Images (save) - it saves straight to the S3 bucket - the full url
// 3) New Hippo Images (fetch) - return url or old images from emily without the "/images/" prefix
export function workaroundImagePath(imgSrc: string) {
  if (!imgSrc) {
    return '';
  }

  const isUrl = /[http|https]:\/\//;
  if (!!isUrl.test(imgSrc)) {
    return imgSrc;
  } else {
    return `/images/${imgSrc}`;
  }
}
