import * as React from 'react';
import styled, { css } from 'styled-components';

type Props = {
  src: string;
  width: number;
  height: number;
  hasCheckeredBackground?: boolean;
} & React.HTMLProps<HTMLDivElement>;

const ImageUploadPreviewComponent: React.FC<Props> = ({
  hasCheckeredBackground = true,
  className,
  src,
  width,
  height,
  ...rest
}) => (
  <div className={className} {...rest}>
    {hasCheckeredBackground && <CheckeredBackground size={Math.max(width, height)} />}
    {!!src && <Img src={src} />}
  </div>
);

const Img = styled.div<Pick<Props, 'src'>>(
  ({ src }) => css`
    background: url("${src}") no-repeat center center;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `
);

const CheckeredBackground = styled.div<{ size: number }>(
  ({ size }) => css`
    background-size: ${size / 10}px ${size / 10}px;
    background-position: 0 0, 0 ${size / 20}px, ${size / 20}px -${size / 20}px, -${size / 20}px 0px;
    background-image: linear-gradient(45deg, #ccc 25%, transparent 25%),
      linear-gradient(-45deg, #ccc 25%, transparent 25%),
      linear-gradient(45deg, transparent 75%, #ccc 75%),
      linear-gradient(-45deg, transparent 75%, #ccc 75%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  `
);

export const ImageUploadPreview = styled(ImageUploadPreviewComponent)<Props>(
  ({ width, height }) => css`
    width: ${width}px;
    height: ${height}px;
    position: relative;
    flex-shrink: 0;
  `
);
