import type { Banner } from './types';

export const replaceBanner = (newBanner: Banner, bannerList: Banner[], previousId?: string) => {
  const copyBannerList = [...bannerList];
  const id = previousId || newBanner.id;
  const bannerIndex = copyBannerList.findIndex(banner => banner.id === id);
  if (bannerIndex >= 0) {
    copyBannerList.splice(bannerIndex, 1, newBanner);
  } else {
    copyBannerList.unshift(newBanner);
  }

  return copyBannerList;
};
