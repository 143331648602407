import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { Col } from 'react-styled-flexboxgrid';

import { BillingRoutes } from '@edapp/billing';
import { Box, Button, Typography } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';

import { ContentContainer, Image, ImageCol, StyledRow, StyledTickIcon } from './styled';
import type { Props } from './types';

export const RestrictionBlock: React.FC<Props> = ({
  imgSrc,
  title,
  description,
  benefitTitle,
  benefits,
  learnMore
}) => {
  const { t } = useTranslation();
  return (
    <StyledRow>
      <Col xs={12}>
        <Box mb={4}>
          <Text variant="titleMedium" component="h3">
            {title}
          </Text>
        </Box>
      </Col>
      <ImageCol xs={false} md={6}>
        <Image loading="eager" src={imgSrc} />
      </ImageCol>
      <Col xs={12} md={6}>
        <ContentContainer flex={true} flexDirection="column" gap={2}>
          {!!description && <Typography variant="paragraph">{description}</Typography>}
          {!!benefitTitle && <Text variant="labelLarge">{benefitTitle}:</Text>}
          {benefits.map((benefit, index) => (
            <Box
              key={`list-item-${index}`}
              mb={index === benefits.length - 1 ? undefined : 2}
              flex={true}
            >
              <StyledTickIcon size="md" color="blue" />
              <Typography variant="paragraph">{benefit}</Typography>
            </Box>
          ))}
        </ContentContainer>
      </Col>
      <Col xs={12}>
        <Box flex={true} mt={4} justifyContent="flex-end" alignItems="center" flexWrap="wrap">
          {!!learnMore && (
            <Typography
              color="blue"
              as="a"
              href={learnMore}
              target="blank"
              textDecoration="none"
              mr={2}
            >
              {t('common.learn-more')}
            </Typography>
          )}
          <a href={BillingRoutes.selectPlans}>
            <Button variant="primary">{t('common.upgrade-to-pro')}</Button>
          </a>
        </Box>
      </Col>
    </StyledRow>
  );
};
