import { isArray } from 'lodash-es';

import type { AuthoringTypes, Question } from '@edapp/authoring-logic';

import GAME from './game';
import MCQ from './mcq';
import NUMBER from './number';
import ORDER from './order';
import RELATIONSHIP from './relationship';
import SENTENCE from './sentence';

const templateCategories: {} = {
  mcq: MCQ,
  sentence: SENTENCE,
  number: NUMBER,
  relation: RELATIONSHIP,
  order: ORDER,
  game: GAME
};

const questionTemplates: {} = {};
for (const k of Object.keys(templateCategories)) {
  const v = templateCategories[k];
  for (const kk of Object.keys(v)) {
    const vv = v[kk];
    questionTemplates[kk] = vv;
  }
}

/**
 * Extract all the data necessary for metadata used in
 * - template rendering via stripped metadata
 * - template interactions via metadata.questions
 * @param slide
 * @returns questions to be added to metadata
 */
export const extractQuestions = (slide: AuthoringTypes.SlideType): Question[] => {
  if (slide && slide.data && questionTemplates[slide.type] != null) {
    let questions = questionTemplates[slide.type](slide.data);
    if (!isArray(questions)) {
      questions = [questions];
    }
    return questions.map((q: any) => {
      return {
        ...q,
        slideId: slide.id
      };
    });
  } else {
    return [];
  }
};
