import * as React from 'react';
import Select, { components as SelectComponent } from 'react-select';
import { find } from 'lodash-es';
import type { OptionProps } from 'react-select/src/components/Option';
import type { SingleValueProps } from 'react-select/src/components/SingleValue';
import type { ContainerProps } from 'react-select/src/components/containers';
import { useTheme } from 'styled-components';
import type { ThemeCommonType } from '@edapp/themes';
import { ChevronDownIcon, Box } from '@edapp/ed-components';
import type { DirectionProperty } from 'csstype';

const DropdownInputOption: React.FC<OptionProps<DropdownOption>> = props => {
  return (
    <SelectComponent.Option
      {...props}
      innerProps={
        {
          ...props.innerProps,
          'data-testid': `dropdown-input-option-${props.data.value}`
        } as any
      }
    />
  );
};

const DropdownInputValue: React.FC<SingleValueProps<DropdownOption>> = props => {
  return (
    <SelectComponent.SingleValue
      {...props}
      innerProps={{
        ...props.innerProps,
        'data-testid': `dropdown-input-value-${props.data.value}`
      }}
    />
  );
};

/**
 * @deprecated
 */
const DropdownInputContainer: React.FC<ContainerProps<DropdownOption>> = props => {
  return (
    <SelectComponent.SelectContainer
      {...props}
      data-testid={`dropdown-input`}
      innerProps={
        {
          ...props.innerProps,
          'data-testid': props.selectProps['data-testid']
        } as any
      }
    />
  );
};

const DropdownArrow = () => (
  <Box mr={1} flex alignItems="center">
    <ChevronDownIcon size="md" />
  </Box>
);

const baseStyles = (Theme: ThemeCommonType) => ({
  option: (styles: any, state: any) => ({
    ...styles,
    color: Theme.colors.text,
    backgroundColor: state.isSelected ? '#F8F9FA' : Theme.colors.transparent,
    fontWeight: state.isSelected ? 'bold' : 'normal',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '&:hover': {
      ...styles['&:hover'],
      color: Theme.colors.text,
      backgroundColor: Theme.colors.lightGrey
    }
  }),
  control: (styles: any, state: any) => {
    return {
      ...styles,
      '&:hover': {
        ...styles['&:hover'],
        borderColor: state.isFocused ? Theme.colors.blue : Theme.colors.greyHover
      },
      '&:focus': {
        ...styles['&:focus'],
        borderColor: Theme.colors.blue,
        boxShadow: 'none'
      },
      borderColor: state.isFocused ? Theme.colors.blue : Theme.colors.grey,
      boxShadow: state.menuIsOpen ? 'inset 0 0 4px rgba(0, 0, 0, 0.1)' : 'none',
      backgroundColor: state.menuIsOpen ? Theme.colors.lightGrey : Theme.colors.white,
      width: '100%',
      paddingRight: '5px'
    };
  },
  menuList: (styles: any) => ({
    ...styles,
    overflowX: 'hidden',
    zIndex: 1000,
    top: '100%',
    left: 0,
    padding: 0,
    borderRadius: '4px',
    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.12)'
  }),
  menu: (styles: any) => ({
    ...styles,
    marginTop: '1px',
    overflowX: 'hidden',
    zIndex: 3
  }),
  placeholder: (styles: any) => ({
    ...styles,
    color: Theme.colors.textMuted,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }),
  singleValue: (styles: any) => ({ ...styles, fontWeight: 'bold' }),
  indicatorSeparator: (styles: any) => ({ ...styles, display: 'none' }),
  dropdownIndicator: (styles: any) => ({
    ...styles,
    color: '#333',
    '&:hover': {
      ...styles['&:hover'],
      color: '#333'
    }
  }),
  indicatorsContainer: (styles: any) => ({
    ...styles,
    color: '#333'
  }),
  clearIndicator: (styles: any) => ({
    ...styles,
    display: 'none'
  }),
  groupHeading: (styles: any) => ({
    ...styles,
    margin: 0,
    color: '#939BA7',
    whiteSpace: 'nowrap',
    fontSize: '15px',
    fontWeight: '400',
    lineHeight: '1.42857'
  }),
  group: (styles: any) => ({
    ...styles,
    borderBottom: '1px solid #E5E5E5'
  })
});

/**
 * @deprecated
 */
export type DropdownOption<T = string> = {
  label: string;
  options?: Array<{
    label: string;
    groupLabel?: string;
    value: T;
  }>;
  value?: T;
};

type DropdownProps = {
  /** Dropdown default value */
  defaultValue?: string;
  /**
   * Dropdown onChange
   * @param value new dropdown value
   */
  onChange: (value: string, name?: string) => void;

  onInputChange?: (value: string) => string;
  /** Dropdown Name */
  name?: string;
  /** Dropdown value */
  value?: string | number;
  /** Dropdown options */
  options?: DropdownOption[];
  /** Dropdown searchable */
  searchable?: boolean;
  /** Dropdown clearable */
  clearable?: boolean;
  placeholder?: string;
  disabled?: boolean;
  optionComponent?: React.ComponentClass<any> | React.FunctionComponent<any>;
  inputRenderer?: React.ComponentClass<any> | React.FunctionComponent<any>;
  className?: string;
  noResultsText?: string;
  ArrowRenderer?: React.ComponentClass<any> | React.FunctionComponent<any>;
  filterOption?: (option: any, filter: string) => boolean;
  'data-testid'?: string;
  isLoading?: boolean;
  direction?: DirectionProperty;
};

/**
 * Finds the DropdownOption object that contains the value
 * @param options Array of DropdownOption
 * @param value A value to find
 */
const findOptionWithValue = (
  options: DropdownOption[] = [],
  value: string | number | boolean | undefined
) => {
  let found: DropdownOption | DropdownOption['options'] | undefined;
  for (const o of options) {
    found = !!o.options
      ? (find(o.options, (go: DropdownOption) => go.value === value) as DropdownOption['options'])
      : o.value === value
      ? o
      : undefined;
    if (found) {
      break;
    }
  }
  return found;
};

/**
 * @deprecated
 * Please use ed-components 'Select' instead
 */
const DropdownInput = (props: DropdownProps) => {
  const components = { ...SelectComponent };

  // If you override the generic component, you need to implement your own `data-testid`
  components.Option = props.optionComponent || DropdownInputOption;

  // If you override the generic component, you need to implement your own `data-testid`
  components.SingleValue = props.inputRenderer || DropdownInputValue;

  // If you override the generic component, you need to implement your own `data-testid`
  components.DropdownIndicator = props.ArrowRenderer || DropdownArrow;

  components.SelectContainer = DropdownInputContainer;

  const optionValue = findOptionWithValue(props.options, props.value || props.defaultValue);
  const theme = useTheme();
  const styles = baseStyles(theme);

  return (
    <Select
      name={props.name}
      value={optionValue || ''}
      onChange={(selectedOption: DropdownOption) =>
        props.onChange((selectedOption && selectedOption.value) || '')
      }
      options={props.options}
      isSearchable={props.searchable}
      isClearable={props.clearable}
      placeholder={props.placeholder}
      isDisabled={props.disabled}
      components={components}
      onInputChange={props.onInputChange}
      className={props.className || 'Select'}
      noResultsText={props.noResultsText}
      filterOption={props.filterOption}
      styles={styles}
      classNamePrefix={'Select'}
      data-testid={props['data-testid']}
      isLoading={props.isLoading}
      isRtl={props.direction === 'rtl'}
    />
  );
};

export default DropdownInput;
