import type {
  Achievement,
  AchievementListItem,
  AchievementTranslationResponse,
  ComponentsResponse,
  Language
} from '@edapp/achievements';
import type { ActionWithPayload } from '@edapp/utils';
import { createAction } from '@edapp/utils';
import type { TranslateResponse } from '@rio/store/languages/types';

import type {
  AddCustomAchievementAction,
  AddCustomAchievementTranslationAction,
  BulkDeleteCustomAchievementAction,
  BulkDeleteCustomAchievementSuccessAction,
  ClearCustomAchievementAction,
  CreateCustomAchievementAction,
  CreateCustomAchievementSuccessAction,
  CustomAchievementsValidationState,
  DeleteCustomAchievementAction,
  DeleteCustomAchievementSuccessAction,
  FetchCustomAchievementAction,
  FetchCustomAchievementComponentsSuccessAction,
  FetchCustomAchievementSuccessAction,
  FetchCustomAchievementTranslationAction,
  FetchCustomAchievementsListAction,
  FetchCustomAchievementsListSuccessAction,
  ModifyCustomAchievementAction,
  ModifyTranslationCustomAchievementAction,
  RemoveTranslationCustomAchievementAction,
  SetCustomAchievementAction,
  SetCustomAchievementSuccessAction,
  SetValidityCustomAchievementAction
} from './types';

export enum CustomAchievementsActionTypes {
  SET_CUSTOM_ACHIEVEMENT = 'SET_CUSTOM_ACHIEVEMENT',
  SET_CUSTOM_ACHIEVEMENT_SUCCESS = 'SET_CUSTOM_ACHIEVEMENT_SUCCESS',
  SET_CUSTOM_ACHIEVEMENT_FAILURE = 'SET_CUSTOM_ACHIEVEMENT_FAILURE',

  SET_IS_PUBLISH_MODAL_OPEN = 'SET_IS_PUBLISH_MODAL_OPEN',

  CREATE_CUSTOM_ACHIEVEMENT = 'CREATE_CUSTOM_ACHIEVEMENT',
  CREATE_CUSTOM_ACHIEVEMENT_SUCCESS = 'CREATE_CUSTOM_ACHIEVEMENT_SUCCESS',
  CREATE_CUSTOM_ACHIEVEMENT_FAILURE = 'CREATE_CUSTOM_ACHIEVEMENT_FAILURE',

  CLEAR_CUSTOM_ACHIEVEMENT = 'CLEAR_CUSTOM_ACHIEVEMENT',

  DELETE_CUSTOM_ACHIEVEMENT = 'DELETE_CUSTOM_ACHIEVEMENT',
  DELETE_CUSTOM_ACHIEVEMENT_SUCCESS = 'DELETE_CUSTOM_ACHIEVEMENT_SUCCESS',
  DELETE_CUSTOM_ACHIEVEMENT_FAILURE = 'DELETE_CUSTOM_ACHIEVEMENT_FAILURE',

  BULK_DELETE_CUSTOM_ACHIEVEMENT = 'BULK_DELETE_CUSTOM_ACHIEVEMENT',
  BULK_DELETE_CUSTOM_ACHIEVEMENT_SUCCESS = 'BULK_DELETE_CUSTOM_ACHIEVEMENT_SUCCESS',
  BULK_DELETE_CUSTOM_ACHIEVEMENT_FAILURE = 'BULK_DELETE_CUSTOM_ACHIEVEMENT_FAILURE',

  FETCH_CUSTOM_ACHIEVEMENT = 'FETCH_CUSTOM_ACHIEVEMENT',
  FETCH_CUSTOM_ACHIEVEMENT_SUCCESS = 'FETCH_CUSTOM_ACHIEVEMENT_SUCCESS',
  FETCH_CUSTOM_ACHIEVEMENT_FAILURE = 'FETCH_CUSTOM_ACHIEVEMENT_FAILURE',

  FETCH_CUSTOM_ACHIEVEMENTS_COMPONENTS = 'FETCH_CUSTOM_ACHIEVEMENTS_COMPONENTS',
  FETCH_CUSTOM_ACHIEVEMENTS_COMPONENTS_SUCCESS = 'FETCH_CUSTOM_ACHIEVEMENTS_COMPONENTS_SUCCESS',
  FETCH_CUSTOM_ACHIEVEMENTS_COMPONENTS_FAILURE = 'FETCH_CUSTOM_ACHIEVEMENTS_COMPONENTS_FAILURE',

  FETCH_CUSTOM_ACHIEVEMENT_TRANSLATION = 'FETCH_CUSTOM_ACHIEVEMENT_TRANSLATION',
  FETCH_CUSTOM_ACHIEVEMENT_TRANSLATION_SUCCESS = 'FETCH_CUSTOM_ACHIEVEMENT_TRANSLATION_SUCCESS',
  FETCH_CUSTOM_ACHIEVEMENT_TRANSLATION_FAILURE = 'FETCH_CUSTOM_ACHIEVEMENT_TRANSLATION_FAILURE',

  FETCH_CUSTOM_ACHIEVEMENT_LIST = 'FETCH_CUSTOM_ACHIEVEMENT_LIST',
  FETCH_CUSTOM_ACHIEVEMENT_LIST_SUCCESS = 'FETCH_CUSTOM_ACHIEVEMENT_LIST_SUCCESS',
  FETCH_CUSTOM_ACHIEVEMENT_LIST_FAILURE = 'FETCH_CUSTOM_ACHIEVEMENT_LIST_FAILURE',

  SET_HAS_VIEWED_CUSTOM_ACHIEVEMENTS = 'SET_HAS_VIEWED_CUSTOM_ACHIEVEMENTS',
  SET_HAS_VIEWED_CUSTOM_ACHIEVEMENTS_SUCCESS = 'SET_HAS_VIEWED_CUSTOM_ACHIEVEMENTS_SUCCESS',
  SET_HAS_VIEWED_CUSTOM_ACHIEVEMENTS_FAILURE = 'SET_HAS_VIEWED_CUSTOM_ACHIEVEMENTS_FAILURE',

  MODIFY_CUSTOM_ACHIEVEMENT = 'MODIFY_CUSTOM_ACHIEVEMENT',
  ADD_NEW_CUSTOM_ACHIEVEMENT = 'ADD_NEW_CUSTOM_ACHIEVEMENT',
  REMOVE_TRANSLATION_CUSTOM_ACHIEVEMENT = 'REMOVE_TRANSLATION_CUSTOM_ACHIEVEMENT',
  MODIFY_TRANSLATION_CUSTOM_ACHIEVEMENT = 'MODIFY_TRANSLATION_CUSTOM_ACHIEVEMENT',
  ADD_TRANSLATION_CUSTOM_ACHIEVEMENT = 'ADD_TRANSLATION_CUSTOM_ACHIEVEMENT',
  SET_VALIDITY_CUSTOM_ACHIEVEMENT = 'SET_VALIDITY_CUSTOM_ACHIEVEMENT',
  SET_RECIPE_FEATURE_ID_TITLE = 'SET_RECIPE_FEATURE_ID_TITLE'
}

export const CustomAchievementsActions = {
  fetchCustomAchievement: (id: string): FetchCustomAchievementAction =>
    createAction(CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT, { id }),
  fetchCustomAchievementSuccess: (data: Achievement): FetchCustomAchievementSuccessAction =>
    createAction(CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_SUCCESS, { data }),

  fetchCustomAchievementComponents: (): ActionWithPayload<
    CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENTS_COMPONENTS,
    undefined
  > => createAction(CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENTS_COMPONENTS, undefined),

  setIsPublishModalOpen: (
    isOpen: boolean
  ): ActionWithPayload<CustomAchievementsActionTypes.SET_IS_PUBLISH_MODAL_OPEN, boolean> =>
    createAction(CustomAchievementsActionTypes.SET_IS_PUBLISH_MODAL_OPEN, isOpen),

  setHasViewedCustomAchievements: () =>
    createAction(CustomAchievementsActionTypes.SET_HAS_VIEWED_CUSTOM_ACHIEVEMENTS),

  fetchCustomAchievementComponentsSuccess: (
    data: ComponentsResponse
  ): FetchCustomAchievementComponentsSuccessAction =>
    createAction(CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENTS_COMPONENTS_SUCCESS, {
      data
    }),

  fetchCustomAchievementTranslation: (
    fromLanguage: Language,
    toLanguage: Language
  ): FetchCustomAchievementTranslationAction =>
    createAction(CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_TRANSLATION, {
      fromLanguage,
      toLanguage
    }),

  fetchCustomAchievementsList: (): FetchCustomAchievementsListAction =>
    createAction(CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_LIST),
  fetchCustomAchievementsListSuccess: (
    data: AchievementListItem[]
  ): FetchCustomAchievementsListSuccessAction =>
    createAction(CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_LIST_SUCCESS, { data }),

  setCustomAchievement: (id: string): SetCustomAchievementAction =>
    createAction(CustomAchievementsActionTypes.SET_CUSTOM_ACHIEVEMENT, {
      id
    }),
  setCustomAchievementSuccess: (data: Achievement): SetCustomAchievementSuccessAction =>
    createAction(CustomAchievementsActionTypes.SET_CUSTOM_ACHIEVEMENT_SUCCESS, {
      data
    }),

  createCustomAchievement: (): CreateCustomAchievementAction =>
    createAction(CustomAchievementsActionTypes.CREATE_CUSTOM_ACHIEVEMENT),
  createCustomAchievementSuccess: (data: Achievement): CreateCustomAchievementSuccessAction =>
    createAction(CustomAchievementsActionTypes.CREATE_CUSTOM_ACHIEVEMENT_SUCCESS, {
      data
    }),

  clearCustomAchievement: (): ClearCustomAchievementAction =>
    createAction(CustomAchievementsActionTypes.CLEAR_CUSTOM_ACHIEVEMENT, {}),

  deleteCustomAchievement: (id: string): DeleteCustomAchievementAction =>
    createAction(CustomAchievementsActionTypes.DELETE_CUSTOM_ACHIEVEMENT, {
      id
    }),

  deleteCustomAchievementSuccess: (id: string): DeleteCustomAchievementSuccessAction =>
    createAction(CustomAchievementsActionTypes.DELETE_CUSTOM_ACHIEVEMENT_SUCCESS, {
      id
    }),

  bulkDeleteCustomAchievement: (ids: string[]): BulkDeleteCustomAchievementAction =>
    createAction(CustomAchievementsActionTypes.BULK_DELETE_CUSTOM_ACHIEVEMENT, {
      ids
    }),

  bulkDeleteCustomAchievementSuccess: (ids: string[]): BulkDeleteCustomAchievementSuccessAction =>
    createAction(CustomAchievementsActionTypes.BULK_DELETE_CUSTOM_ACHIEVEMENT_SUCCESS, {
      ids
    }),

  modifyCustomAchievement: (achievement: Partial<Achievement>): ModifyCustomAchievementAction =>
    createAction(CustomAchievementsActionTypes.MODIFY_CUSTOM_ACHIEVEMENT, {
      achievement
    }),

  addCustomAchievement: (achievement: Achievement): AddCustomAchievementAction =>
    createAction(CustomAchievementsActionTypes.ADD_NEW_CUSTOM_ACHIEVEMENT, {
      achievement
    }),

  removeTranslationCustomAchievement: (code: string): RemoveTranslationCustomAchievementAction =>
    createAction(CustomAchievementsActionTypes.REMOVE_TRANSLATION_CUSTOM_ACHIEVEMENT, {
      code
    }),

  modifyTranslationCustomAchievement: (
    translation: AchievementTranslationResponse
  ): ModifyTranslationCustomAchievementAction =>
    createAction(CustomAchievementsActionTypes.MODIFY_TRANSLATION_CUSTOM_ACHIEVEMENT, {
      translation
    }),

  addTranslationCustomAchievement: (
    res: TranslateResponse,
    fromLanguage: Language
  ): AddCustomAchievementTranslationAction =>
    createAction(CustomAchievementsActionTypes.ADD_TRANSLATION_CUSTOM_ACHIEVEMENT, {
      res,
      fromLanguage
    }),

  updateValidationCustomAchievement: (
    validity: CustomAchievementsValidationState
  ): SetValidityCustomAchievementAction =>
    createAction(CustomAchievementsActionTypes.SET_VALIDITY_CUSTOM_ACHIEVEMENT, {
      validity
    }),

  setCustomAchievementRecipeTitle: (
    featureId: string,
    title: string
  ): ActionWithPayload<
    CustomAchievementsActionTypes.SET_RECIPE_FEATURE_ID_TITLE,
    { featureId: string; title: string }
  > => createAction(CustomAchievementsActionTypes.SET_RECIPE_FEATURE_ID_TITLE, { featureId, title })
};
