import styled from 'styled-components';
import { Loading as EdLoading } from '@edapp/ed-components';

const Loading = styled(EdLoading)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export { Loading };
