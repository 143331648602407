import { updateSlideMetadata, updateSlidesDisplayIndexes } from '../../utils/helpers';
import type { FormStoreType, SlideType } from '../types';

/**
 * Edit currentSlide and replace in the slides list
 * @param state
 * @param newDataCommand
 */
export const editCurrentSlide = (
  state: FormStoreType,
  slides: ReadonlyArray<SlideType>,
  currentSlide: SlideType,
  currentSlideIndex: number,
  newDataCommand: any
) => {
  let newSlide: SlideType = {
    ...currentSlide,
    data: { ...newDataCommand }
  };

  newSlide = updateSlideMetadata(newSlide);

  // Immutability helper type problem with $splice
  let newSlides = slides.slice();
  newSlides.splice(currentSlideIndex, 1, newSlide);

  // Update display Index
  newSlides = updateSlidesDisplayIndexes(newSlides);

  return {
    ...state,
    saved: false,
    slides: newSlides
  };
};

/*
  Removes `model` word from the beginning of the path
*/
export const cleanUpPath = (path?: string) => {
  if (!path) {
    return '';
  }

  return path.replace(/^model\./, '');
};
