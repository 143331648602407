import * as React from 'react';
import styled, { css } from 'styled-components';

import { Box, CardPicker, IconButton, PlusIcon, Typography } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';

import type { CardPickerItemProps } from '@edapp/ed-components';
type ListProps = {
  onAdd: () => void;
  accounts: CardPickerItemProps[];
};

export const AccountList: React.FC<ListProps> = ({ accounts, onAdd }) => {
  const handleAddAccount = () => onAdd();
  return (
    <Box textAlign="left">
      <Text variant="titleLarge" component="h2">
        Switch accounts
      </Text>
      <Typography mb={3} as="p">
        Switch between different EdApp accounts.
      </Typography>
      <StyledCardPicker items={accounts} />
      <IconButton
        icon={PlusIcon}
        onClick={handleAddAccount}
        variant="tertiary"
        iconColor="blue"
        testId="link-account-btn"
      >
        Link an account
      </IconButton>
    </Box>
  );
};

const StyledCardPicker = styled(CardPicker)(
  ({ theme }) => css`
    justify-content: stretch;
    align-items: stretch;
    gap: ${theme.space()}px;
    margin-bottom: ${theme.space()}px;
    > * {
      flex: 1 0 100%;
      max-width: none;
      align-items: flex-start;
      padding: ${theme.space(1.5)}px ${theme.space(2)}px;
    }
  `
);
