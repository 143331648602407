import * as React from 'react';

import { useSelector } from 'react-redux';

import { useFeatureFlags } from '@edapp/feature-flags';
import { Feeds, NotificationsDropdown, NotificationsProvider } from '@edapp/notifications';
import { useUserNotificationAuthToken } from '@rio/api/notifications';
import { Flags } from '@rio/components/feature-flags';
import { getKnockApiKey, getUserId, hasBillingPermission } from '@rio/store/config/selectors';
import { BillingPermission } from '@rio/utils/permissions/constants';

export const NavNotifications: React.FC = () => {
  const [enableNotificationCentre] = useFeatureFlags([Flags.enableNotificationCentre]);
  const hasAccessToNotificationCentre = useSelector(
    hasBillingPermission(BillingPermission.ACCESS_NOTIFICATION_CENTRE)
  );
  const knockApiKey = useSelector(getKnockApiKey);
  const userId = useSelector(getUserId);
  const { data, isSuccess } = useUserNotificationAuthToken({
    enabled: !!userId && enableNotificationCentre && hasAccessToNotificationCentre,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    gcTime: Infinity
  });

  if (!isSuccess) {
    return null;
  }

  return (
    <NotificationsProvider
      apiKey={knockApiKey}
      feed={Feeds.InAppLms}
      userId={userId}
      knockToken={data.token}
    >
      <NotificationsDropdown />
    </NotificationsProvider>
  );
};
