import * as React from 'react';

import type { CardPickerItemProps } from '@edapp/ed-components';
import { DialogContent } from '@edapp/ed-components';
import { useGetLinkedAccounts, useSwitchAccount } from '@rio/api/accounts/authentication';
import {
  ModalTransition,
  ModalTransitionContainer
} from '@rio/containers/Courseware/CourseReview/modals/Transition';

import { AccountList } from './AccountList';
import { LinkAccountForm } from './LinkAccountForm';

enum Step {
  AccountsList,
  LinkAcount
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
};
export const SwitchAccountModal: React.FC<Props> = ({ isOpen, onClose }) => {
  const [linkViewVisible, setLinkViewVisible] = React.useState(false);
  const { data, refetch } = useGetLinkedAccounts({
    enabled: isOpen
  });
  const { mutateAsync: switchAccount } = useSwitchAccount();

  // list of accounts user linked
  const accounts: CardPickerItemProps[] = React.useMemo(
    () =>
      data?.accounts?.map(a => ({
        title: a.user.email,
        description: a.user.name,
        onClick: () => handleSwitchAccount(a.user._id)
      })) || [],
    [data]
  );

  React.useEffect(() => {
    if (!isOpen && linkViewVisible) {
      showListView();
    }
  }, [isOpen]);

  const showLinkView = () => setLinkViewVisible(true);
  const showListView = () => setLinkViewVisible(false);

  const handleSwitchAccount = (id: string) => {
    switchAccount({ id }).then(() => {
      window.location.reload();
    });
  };
  const handleLinkAccount = () => {
    refetch();
    showListView();
  };

  return (
    <DialogContent
      isOpen={isOpen}
      onClose={onClose}
      size="xs"
      testId="switch-account-modal"
      bodyContents={
        <ModalTransitionContainer initialHeight={200}>
          {linkViewVisible ? (
            <ModalTransition key={Step.AccountsList}>
              <LinkAccountForm onLink={handleLinkAccount} />
            </ModalTransition>
          ) : (
            <ModalTransition key={Step.LinkAcount}>
              <AccountList accounts={accounts} onAdd={showLinkView} />
            </ModalTransition>
          )}
        </ModalTransitionContainer>
      }
    />
  );
};
