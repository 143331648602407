import * as React from 'react';

import { useTranslation } from 'react-i18next';

import { BillingRoutes } from '@edapp/billing';
import { Alert, Box, ButtonLink, Typography } from '@edapp/ed-components';
import { BillingPermission, useCheckBillingPermission } from '@edapp/permissions';

import { StyledFullWidthBox } from './styled';
import type { Props } from './types';

export const RestrictionAlert: React.FC<Props> = ({ prompt, onClickUpgrade, ...props }) => {
  const { t } = useTranslation();
  const canShowUpgradeActions = useCheckBillingPermission(
    BillingPermission.CAN_SHOW_UPGRADE_ACTIONS
  );

  return (
    <Box {...props}>
      <Alert showIcon={true} variant="info">
        <StyledFullWidthBox
          flex
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          gap={2}
        >
          <Typography variant="small">{prompt}</Typography>
          {canShowUpgradeActions && (
            <Box flexShrink={0}>
              <ButtonLink
                variant="primary"
                size="sm"
                onClick={onClickUpgrade}
                href={BillingRoutes.selectPlans}
              >
                {t('common.upgrade-to-pro')}
              </ButtonLink>
            </Box>
          )}
        </StyledFullWidthBox>
      </Alert>
    </Box>
  );
};
