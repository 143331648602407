import * as React from 'react';
import { useHistory, Prompt } from 'react-router';
import type { Location } from 'history';
import { useBeforeUnload } from '@edapp/ed-components/hooks';

type Props = {
  when: boolean;
  message: string;
  allowNavigation?: boolean;
  allowOnlyPathname?: boolean;
  allowSubRoute?: string;
  children?: (isVisible: boolean, setIsVisible: (isVisible: boolean) => void) => React.ReactNode;
};

export const NavigationGuard: React.FC<Props> = ({
  allowNavigation = false,
  allowOnlyPathname = false,
  allowSubRoute = undefined,
  when,
  message,
  children
}) => {
  const history = useHistory();

  const [nextLocation, setNextLocation] = React.useState<Location | null>(null);
  const [isVisible, setIsVisible] = React.useState(false);

  useBeforeUnload(() => {
    // Do not display unload prompt if condition for blocking
    // navigation is unfulfilled
    if (!when || allowNavigation) {
      return false;
    }

    // Display customized message if provided (browser-dependent)
    if (message) {
      return message;
    }

    // Display default warning prompt (browser-dependent)
    return true;
  });

  React.useEffect(() => {
    if (allowNavigation && nextLocation?.pathname) {
      let url = nextLocation.pathname;
      if (nextLocation.hash) {
        url = url.concat(nextLocation.hash);
      }
      history.push(url);
    }
  }, [allowNavigation, nextLocation]);

  const handleBlockNavigation = (location: Location) => {
    // use this to not guard against unsaved changes when reside within the same page (dialog pop up route)
    // for example, /playlists/new -> click settings -> /playlists/new/settings/enrollment
    if (allowSubRoute && location.pathname.startsWith(allowSubRoute)) {
      return true;
    }

    if (allowOnlyPathname && history.location.pathname === location.pathname) {
      return true;
    }
    if (allowNavigation && nextLocation) {
      return true;
    }

    setNextLocation(location);
    setIsVisible(true);
    return false;
  };

  return (
    <>
      <Prompt when={when} message={children ? handleBlockNavigation : message} />
      {children && children(isVisible, setIsVisible)}
    </>
  );
};
