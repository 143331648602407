import type { ContentLibraryCourseType, Industry } from '@edapp/content-library';
import { RequestUtils } from '@edapp/request';

import type { CustomHeader } from '../types';
import type {
  FetchContentLibrarySectionRequest,
  FetchContentLibrarySectionResponse,
  FetchContentLibrarySectionsRequest,
  FetchContentLibrarySectionsResponse,
  GetContentLibrarySuggestionRequest,
  GetContentLibrarySuggestionResponse,
  ImportCoursesRequest,
  ImportCoursesResponse,
  SearchContentLibraryRequest,
  SearchContentLibraryResponse
} from './types';

export function fetchCourseLibraryIndustries(hippoUrl: string, userToken: string) {
  return async () => {
    const response = await RequestUtils.httpFetch<{ industries: Industry[] }>(
      'GET',
      `${hippoUrl}/api/content-library/industries`,
      userToken
    );
    return response.industries;
    // return import('./mock-industries.json').then(res => res.default.industries);
  };
}

type FetchSectionsOptions = {
  page?: number;
  pageSize?: number;
  coursesPage?: number;
  coursesPageSize?: number;
};

export function fetchCourseLibrarySections(
  hippoUrl: string,
  userToken: string,
  payload: FetchContentLibrarySectionsRequest
) {
  return RequestUtils.httpFetch<FetchContentLibrarySectionsResponse, FetchSectionsOptions>(
    'GET',
    `${hippoUrl}/api/content-library/sections`,
    userToken,
    payload
  );
  // return import('./mock-sections.json').then(res => res.default) as Promise<
  //   FetchContentLibrarySectionsResponse
  // >;
}

export function getContentLibrarySuggestions(
  hippoUrl: string,
  userToken: string,
  payload: GetContentLibrarySuggestionRequest
) {
  return RequestUtils.httpFetch<
    GetContentLibrarySuggestionResponse,
    GetContentLibrarySuggestionRequest
  >('POST', `${hippoUrl}/api/content-library/search-suggestions`, userToken, payload);
}

export function searchContentLibrary(
  hippoUrl: string,
  userToken: string,
  payload: SearchContentLibraryRequest
) {
  return RequestUtils.httpFetch<SearchContentLibraryResponse, SearchContentLibraryRequest>(
    'POST',
    `${hippoUrl}/api/content-library/courses/search`,
    userToken,
    payload
  );
}

export function fetchCourseLibraryCourse(hippoUrl: string, userToken: string, courseId: string) {
  return RequestUtils.httpFetch<ContentLibraryCourseType>(
    'GET',
    `${hippoUrl}/api/content-library/course/${courseId}`,
    userToken
  );
}

export function fetchCourseLibrarySection(
  hippoUrl: string,
  userToken: string,
  payload: FetchContentLibrarySectionRequest
) {
  return RequestUtils.httpFetch<FetchContentLibrarySectionResponse, FetchSectionsOptions>(
    'GET',
    `${hippoUrl}/api/content-library/section/${payload.sectionId}`,
    userToken,
    {
      coursesPageSize: payload.pageSize,
      coursesPage: payload.page
    }
  );
  // return import('./mock-section.json').then(res => res.default) as Promise<
  //   ContentLibrarySectionType
  // >;
}

export function importCourses(
  hippoUrl: string,
  userToken: string,
  payload: ImportCoursesRequest,
  header: CustomHeader
) {
  return RequestUtils.httpFetch<ImportCoursesResponse, ImportCoursesRequest>(
    'POST',
    `${hippoUrl}/api/my-courses`,
    userToken,
    payload,
    undefined,
    header
  );
}
