import * as React from 'react';

import { Col, Row } from 'react-styled-flexboxgrid';
import styled from 'styled-components';

import type { ContentLibraryCourseType } from '@edapp/content-library';
import { Box } from '@edapp/ed-components';
import { Button, Card, Text, icons } from '@edapp/sc-web-ui';

import { getTestId } from '../../summary-card/SummaryCard';
import { CardHoverOverlay } from '../card-hover-overlay/CardHoverOverlay';
import { Logo, LogoWrapper } from '../card-logo/styled';

type Props = {
  courses: ContentLibraryCourseType[];
  selectedCourseIds?: string[];
  orderedSelect?: boolean;
  onSelect?: (id: string) => void;
  className?: string;
  selectText: string;
  selectedText: string;
  byText: string;
};
export const testId = 'content-card-list';

export const ContentCardsList: React.FC<Props> = ({
  courses,
  selectedCourseIds = [],
  onSelect,
  className,
  selectText,
  selectedText,
  byText
}) => {
  const getSelectedIndex = (id: string) => selectedCourseIds.indexOf(id);

  return (
    <Wrapper className={className} data-testid={testId}>
      <Row>
        {courses.map((course: ContentLibraryCourseType) => {
          const selectedIndex = getSelectedIndex(course.id);
          const isSelected = selectedIndex > -1;
          const handleClick = () => onSelect?.(course.id);

          return (
            <StyledCol key={course.id} lg={3} md={4} xs={6}>
              <StyledBox onClick={handleClick} testId={getTestId(course.id)}>
                <StyledCard isSelected={isSelected}>
                  <Card.CourseLayout
                    title={course.title}
                    description={course.description}
                    subtext={
                      course.companyName ? (
                        <Box pt={0.5}>
                          <Text
                            variant="labelSmall"
                            data-testid="card-subtext"
                            color="surface.text.weaker"
                          >
                            {byText}{' '}
                            <Text
                              variant="labelSmall"
                              data-testid="card-subtext"
                              color="accent.text.default"
                            >
                              {course.companyName}
                            </Text>
                          </Text>
                        </Box>
                      ) : undefined
                    }
                    numberOfLessons={course.lessonsCount}
                    src={(course?.media as string) || course.brandingImage}
                    thumbnail={
                      !!course.logo ? (
                        <LogoWrapper logoBackgroundColor={course.logoBackgroundColor || 'navy'}>
                          <Logo src={course.logo || ''} transformation="card_thumbnail" />
                        </LogoWrapper>
                      ) : undefined
                    }
                    overlay={
                      <CardHoverOverlay>
                        <Button
                          variation={isSelected ? 'primary' : 'secondary'}
                          size="sm"
                          endIcon={isSelected ? <icons.Check /> : undefined}
                        >
                          {isSelected ? selectedText : selectText}
                        </Button>
                      </CardHoverOverlay>
                    }
                    overlayOptions={{ onlyOnHover: isSelected ? false : true }}
                  />
                </StyledCard>
              </StyledBox>
            </StyledCol>
          );
        })}
      </Row>
    </Wrapper>
  );
};

const StyledCol = styled(Col)`
  margin-bottom: ${({ theme }) => theme.space(3)}px;
  & > div {
    height: 100%;
  }
`;

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGrey};
  padding: ${({ theme }) => `${theme.space(3)}px ${theme.space(6)}px 0`};
  width: 100%;
`;
const StyledBox = styled(Box)`
  cursor: pointer;
`;

const StyledCard = styled(Card)`
  height: 360px;
`;
