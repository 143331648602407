import { FilterType } from '../types/filters';
import type { AnalyticsPageType, AnalyticsState } from '../types/types';
import { ReportTypes } from './types';

export const initialActionableReportsState: AnalyticsState[AnalyticsPageType.ACTIONABLE_REPORTS] = {
  data: {
    items: [],
    totalCount: 0,
    page: 1,
    pageSize: 25,
    error: '',
    isLoading: false
  },
  filters: {
    [FilterType.COURSES_FILTER]: {
      items: [],
      selectedItemIds: '',
      isLoading: false
    },
    [FilterType.USER_GROUPS_FILTER]: {
      items: [],
      selectedItemIds: [],
      isLoading: false
    }
  },
  isClearSelectedOptions: false,
  reportType: ReportTypes.NOT_COMPLETED
};
