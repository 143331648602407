export const FETCH_PEER_AUTHORING_LESSONS = 'FETCH_PEER_AUTHORING_LESSONS';
export const FETCH_PEER_AUTHORING_LESSONS_SUCCESS = 'FETCH_PEER_AUTHORING_LESSONS_SUCCESS';
export const FETCH_PEER_AUTHORING_LESSONS_FAILURE = 'FETCH_PEER_AUTHORING_LESSONS_FAILURE';

export const FETCH_SLIDE_CONTRIBUTIONS = 'FETCH_SLIDE_CONTRIBUTIONS';
export const FETCH_SLIDE_CONTRIBUTIONS_SUCCESS = 'FETCH_SLIDE_CONTRIBUTIONS_SUCCESS';
export const FETCH_SLIDE_CONTRIBUTIONS_FAILURE = 'FETCH_SLIDE_CONTRIBUTIONS_FAILURE';

export const REMOVE_SLIDE_CONTRIBUTION = 'REMOVE_SLIDE_CONTRIBUTION';
export const REMOVE_SLIDE_CONTRIBUTION_SUCCESS = 'REMOVE_SLIDE_CONTRIBUTION_SUCCESS';
export const REMOVE_SLIDE_CONTRIBUTION_FAILURE = 'REMOVE_SLIDE_CONTRIBUTION_FAILURE';

export const REMOVE_CONTRIBUTION_COMMENT = 'REMOVE_CONTRIBUTION_COMMENT';
export const REMOVE_CONTRIBUTION_COMMENT_SUCCESS = 'REMOVE_CONTRIBUTION_COMMENT_SUCCESS';
export const REMOVE_CONTRIBUTION_COMMENT_FAILURE = 'REMOVE_CONTRIBUTION_COMMENT_FAILURE';

// Fetch lessons with peer authoring slides in them, for the current application
export const fetchPeerAuthoringLessons = () => ({
  type: FETCH_PEER_AUTHORING_LESSONS,
  payload: {}
});

export const fetchPeerAuthoringSlideContributions = (lessonId: string, slideId: string) => ({
  type: FETCH_SLIDE_CONTRIBUTIONS,
  payload: {
    lessonId,
    slideId
  }
});

export const removePeerAuthoringSlideContribution = (
  lessonId: string,
  slideId: string,
  contributionId: string
) => ({
  type: REMOVE_SLIDE_CONTRIBUTION,
  payload: {
    lessonId,
    slideId,
    contributionId
  }
});

export const removePeerAuthoringContributionComment = (
  lessonId: string,
  slideId: string,
  contributionId: string,
  commentId: string
) => ({
  type: REMOVE_CONTRIBUTION_COMMENT,
  payload: {
    lessonId,
    slideId,
    contributionId,
    commentId
  }
});
