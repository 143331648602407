import type { EdErrorResponseType } from '@edapp/authoring-logic';

import type { Checklist } from './types';

export const FETCH_ONBOARDING_CHECKLIST = 'FETCH_ONBOARDING_CHECKLIST';
export type FetchOnboardingChecklist = { name: string };
export const fetchOnboardingChecklist = (name: string): Action<FetchOnboardingChecklist> => ({
  type: FETCH_ONBOARDING_CHECKLIST,
  payload: { name }
});

export const FETCH_ONBOARDING_CHECKLIST_SUCCESS = 'FETCH_ONBOARDING_CHECKLIST_SUCCESS';
export type FetchOnboardingChecklistSuccess = Checklist;

export const FETCH_ONBOARDING_CHECKLIST_FAILURE = 'FETCH_ONBOARDING_CHECKLIST_FAILURE';
export type FetchOnboardingChecklistFailure = EdErrorResponseType;

export const DISMISS_ONBOARDING_CHECKLIST = 'DISMISS_ONBOARDING_CHECKLIST';
export type DismissOnboardingChecklist = { name: string };
export const dismissOnboardingChecklist = (name: string): Action<DismissOnboardingChecklist> => ({
  type: DISMISS_ONBOARDING_CHECKLIST,
  payload: { name }
});

export const DISMISS_ONBOARDING_CHECKLIST_SUCCESS = 'DISMISS_ONBOARDING_CHECKLIST_SUCCESS';
export type DismissOnboardingChecklistSuccess = any;

export const DISMISS_ONBOARDING_CHECKLIST_FAILURE = 'DISMISS_ONBOARDING_CHECKLIST_FAILURE';
export type DismissOnboardingChecklistFailure = EdErrorResponseType;
