import * as React from 'react';
import { Box, Typography } from '@edapp/ed-components';
import { ColorPicker } from '../color-picker/ColorPicker';
import { TextColorThumbnail } from '../text-color-thumbnail/TextColorThumbnail';
import type { ColorPalette } from './types';

export type Props = {
  color: string;
  textColor: string;
  colorLabel: string;
  textColorLabel: string;
  presetColors: string[];
  onChangeColor: (palette: ColorPalette) => void;
  palette: ColorPalette;
  type: 'primary' | 'secondary';
};

export const ColorPickerInputField: React.FC<Props> = ({
  color,
  textColor,
  colorLabel,
  textColorLabel,
  presetColors,
  onChangeColor,
  palette,
  type
}) => {
  const handleChangeColor = (selectedColor: string, variant: 'palette' | 'text') => {
    if (type === 'primary') {
      if (variant === 'palette') {
        onChangeColor({ ...palette, primaryColor: selectedColor });
      }
      if (variant === 'text') {
        onChangeColor({ ...palette, primaryFontColor: selectedColor });
      }
    } else {
      if (variant === 'palette') {
        onChangeColor({ ...palette, secondaryColor: selectedColor });
      }
      if (variant === 'text') {
        onChangeColor({ ...palette, secondaryFontColor: selectedColor });
      }
    }
  };

  return (
    <Box flex gap={1}>
      <TextColorThumbnail size="sm" fontColor={textColor} backgroundColor={color} />
      <Box p={0.5}>
        <Box flex alignItems="row" gap={0.5}>
          <ColorPicker
            id={`${type}-color-picker`}
            testId={`${type}-color-picker`}
            color={color}
            onChange={(color: string) => handleChangeColor(color, 'palette')}
            presetColors={presetColors}
            disabled={false}
          />
          <Typography>{colorLabel}</Typography>
        </Box>
        <Box flex alignItems="row" gap={0.5}>
          <ColorPicker
            id={`${type}-text-color-picker`}
            testId={`${type}-text-color-picker`}
            color={textColor}
            onChange={(color: string) => handleChangeColor(color, 'text')}
            presetColors={presetColors}
            disabled={false}
          />
          <Typography>{textColorLabel}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
