import React from 'react';
import styled from 'styled-components';
import { Box, Loading } from '@edapp/ed-components';

export const LoadingIndicator: React.FC = () => {
  return (
    <StyledLoadingBody flex alignItems="center">
      <Loading />
    </StyledLoadingBody>
  );
};

const StyledLoadingBody = styled(Box)`
  height: 150px;
`;
