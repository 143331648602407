import { union } from 'lodash-es';
import type { AnyAction } from 'redux';

import type { CourseTitles, UserGroupNames } from '../types/filters';
import type { AnalyticsPageType, AnalyticsState } from '../types/types';
import * as actions from './actions';
import { initialActionableReportsState } from './constants';
import {
  resetReportItemsPage,
  setCurrentPage,
  setDataItems,
  sortByAlphabeticalOrderInAscendingOrder
} from './utils';

export const actionableReportsReducer = (
  state: AnalyticsState[AnalyticsPageType.ACTIONABLE_REPORTS] = initialActionableReportsState,
  action: AnyAction
) => {
  const { type } = action;

  switch (type) {
    case actions.SET_REPORT_TYPE:
      return resetReportItemsPage({
        ...state,
        data: {
          ...state.data,
          items: initialActionableReportsState.data.items
        },
        filters: {
          coursesFilter: {
            ...state.filters.coursesFilter,
            selectedItemIds: initialActionableReportsState.filters.coursesFilter.selectedItemIds
          },
          userGroupsFilter: {
            ...state.filters.userGroupsFilter,
            selectedItemIds: initialActionableReportsState.filters.userGroupsFilter.selectedItemIds
          }
        },
        reportType: action.payload.reportType
      });

    case actions.SET_REPORT_TYPE_FAILURE: {
      return {
        ...state,
        reportType: initialActionableReportsState.reportType
      };
    }

    case actions.SET_CLEAR_FILTERS:
      return {
        ...state,
        isClearSelectedOptions: action.payload.isClearFilters
      };

    case actions.SET_CLEAR_FILTERS_FAILURE: {
      return {
        ...state,
        isClearSelectedOptions: false
      };
    }

    case actions.FETCH_COURSE_TITLES: {
      return {
        ...state,
        filters: {
          ...state.filters,
          coursesFilter: {
            ...state.filters.coursesFilter,
            isLoading: true
          }
        }
      };
    }

    case actions.FETCH_COURSE_TITLES_SUCCESS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          coursesFilter: {
            ...state.filters.coursesFilter,
            items: sortByAlphabeticalOrderInAscendingOrder(
              action.payload,
              'title'
            ) as CourseTitles[],
            isLoading: false
          }
        }
      };
    }

    case actions.FETCH_COURSE_TITLES_FAILURE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          coursesFilter: {
            ...state.filters.coursesFilter,
            isLoading: false
          }
        }
      };
    }

    case actions.FETCH_USER_GROUP_NAMES: {
      return {
        ...state,
        filters: {
          ...state.filters,
          userGroupsFilter: {
            ...state.filters.userGroupsFilter,
            isLoading: true
          }
        }
      };
    }

    case actions.FETCH_USER_GROUP_NAMES_SUCCESS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          userGroupsFilter: {
            ...state.filters.userGroupsFilter,
            isLoading: false,
            items: sortByAlphabeticalOrderInAscendingOrder(
              action.payload,
              'name'
            ) as UserGroupNames[]
          }
        }
      };
    }

    case actions.FETCH_USER_GROUP_NAMES_FAILURE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          userGroupsFilter: {
            ...state.filters.userGroupsFilter,
            isLoading: false
          }
        }
      };
    }

    case actions.SET_SELECTED_COURSE_IDS:
      return resetReportItemsPage({
        ...state,
        data: {
          ...state.data,
          items: []
        },
        filters: {
          ...state.filters,
          coursesFilter: {
            ...state.filters.coursesFilter,
            selectedItemIds: action.payload.selectedCourseIds
          }
        }
      });

    case actions.SET_SELECTED_COURSE_IDS_FAILURE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          coursesFilter: {
            ...state.filters.coursesFilter,
            selectedItemIds: initialActionableReportsState.filters.coursesFilter.selectedItemIds,
            isLoading: false
          }
        }
      };
    }

    case actions.SET_SELECTED_USER_GROUP_IDS:
      return resetReportItemsPage({
        ...state,
        data: {
          ...state.data,
          items: []
        },
        filters: {
          ...state.filters,
          userGroupsFilter: {
            ...state.filters.userGroupsFilter,
            selectedItemIds: union(
              state.filters.userGroupsFilter.selectedItemIds,
              action.payload.selectedUserGroupIds
            )
          }
        }
      });

    case actions.SET_SELECTED_USER_GROUP_IDS_FAILURE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          userGroupsFilter: {
            ...state.filters.userGroupsFilter,
            selectedItemIds: initialActionableReportsState.filters.userGroupsFilter.selectedItemIds,
            isLoading: false
          }
        }
      };
    }

    case actions.REMOVE_SELECTED_USER_GROUP_ID:
      return resetReportItemsPage({
        ...state,
        data: {
          ...state.data,
          items: initialActionableReportsState.data.items
        },
        filters: {
          ...state.filters,
          userGroupsFilter: {
            ...state.filters.userGroupsFilter,
            selectedItemIds: action.payload.selectedUserGroupIds ?? []
          }
        }
      });

    case actions.REMOVE_SELECTED_USER_GROUP_ID_FAILURE: {
      return {
        ...state,
        filters: {
          ...state.filters,
          userGroupsFilter: {
            ...state.filters.userGroupsFilter,
            selectedItemIds: initialActionableReportsState.filters.userGroupsFilter.selectedItemIds
          }
        }
      };
    }

    case actions.FETCH_REPORT_ITEMS: {
      return {
        ...state,
        data: {
          ...state.data,
          isLoading: true
        }
      };
    }

    case actions.FETCH_REPORT_ITEMS_SUCCESS: {
      return {
        ...state,
        data: {
          ...state.data,
          isLoading: false,
          items: setDataItems(state, action.payload.items),
          page: setCurrentPage(state),
          totalCount: action.payload.totalCount
        }
      };
    }

    case actions.FETCH_REPORT_ITEMS_FAILURE: {
      return resetReportItemsPage({
        ...state,
        data: {
          ...state.data,
          isLoading: false
        }
      });
    }

    default:
      return state;
  }
};
