import React, { useState } from 'react';

import { motion } from 'framer-motion';

import { MenuIcon, useMatchMedia } from '@edapp/ed-components';
import { useAppearance } from '@edapp/themes';
import { breakpoints } from '@edapp/themes/src/common/breakpoints';
import {
  ApplicationInfoSelectors,
  BillingSelectors,
  UserInfoSelectors,
  useUserInfo
} from '@edapp/user-info';
import { AuthenticationModal } from '@rio/components/authentication-modal/AuthenticationModal';
import MobileBanner from '@rio/components/mobile-banner/MobileBanner';
import Navigation from '@rio/containers/navigation/Navigation';
import SideFeedback from '@rio/containers/navigation/SideFeedback';

import { MenuItem } from './components/MenuItem';
import { useNavigationMenu } from './constants';
import { NavbarContextProvider } from './context';
import {
  Navbar,
  NavbarCollapse,
  NavbarContainer,
  NavbarLeft,
  NavbarLogo,
  NavbarRight,
  NavbarToggle
} from './styled';

export const NavbarMenu: React.FC = () => {
  const isMobile = useMatchMedia(`screen and (max-width: ${breakpoints.sm}rem)`);
  const { isDarkMode } = useAppearance();

  const { userInfo, billing, applicationInfo, dataLoaded } = useUserInfo();

  const isUnifiedExperience = ApplicationInfoSelectors.isUXP(applicationInfo);
  const isOwner = UserInfoSelectors.isOwner(userInfo);
  const isAdmin = UserInfoSelectors.isAdmin(userInfo);
  const isEnterprise = BillingSelectors.isEnterprise(billing);

  const [expandMobileMenu, setExpandMobileMenu] = useState(false);
  // Hide collapsible menu on smaller screens (users need to toggle it manually)
  // On desktop, it should be expanded by default since there is no toggle button
  const shouldHideMenuInitially = useMatchMedia(`screen and (max-width: ${breakpoints.md}rem)`);

  const navigationMenu = useNavigationMenu();

  const logoLink = !isEnterprise && (isOwner || isAdmin) ? '/get-started' : '/home';
  const logoUrl = isDarkMode
    ? 'https://media.edapp.com/edapp.com/assets/img/branding/ed-logo-short-white-svg.svg'
    : 'https://media.edapp.com/edapp.com/assets/img/branding/ed-logo-short-svg.svg';

  return (
    <NavbarContextProvider>
      <Navbar data-testid="navbar-menu">
        {isMobile && <MobileBanner />}
        <NavbarContainer>
          {!isUnifiedExperience && (
            <NavbarLogo data-testid="navbar-logo" href={logoLink}>
              <img src={logoUrl} alt="Ed CMS" />
            </NavbarLogo>
          )}

          {/* Logic for rendering the navbar toggle button */}
          {userInfo && dataLoaded && (
            <NavbarToggle
              data-testid="navbar-menu-toggle"
              variant="pale-dark"
              onClick={() => setExpandMobileMenu(!expandMobileMenu)}
            >
              <MenuIcon size="lg" />
            </NavbarToggle>
          )}

          {/* Logic for rendering the collapsible navbar */}
          {(!shouldHideMenuInitially || expandMobileMenu) && (
            <motion.div
              initial={{ opacity: 0, height: '0' }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: '0' }}
              transition={{ duration: 0.4, ease: 'easeInOut', clamp: true }}
            >
              <NavbarCollapse>
                {userInfo && dataLoaded && (
                  <>
                    <SideFeedback />
                    <NavbarLeft data-testid="navbar-menu-items">
                      {navigationMenu.map((item, index) => (
                        <MenuItem key={index} item={item} />
                      ))}
                    </NavbarLeft>
                  </>
                )}
                <NavbarRight>
                  <Navigation />
                </NavbarRight>
              </NavbarCollapse>
            </motion.div>
          )}
        </NavbarContainer>
      </Navbar>
      {/* In my opinion this should not be tied to the navbar, but this is carried over from Emily. Until we get rid of all Emily pages, we have to rely on this */}
      <AuthenticationModal />
    </NavbarContextProvider>
  );
};
