import styled from 'styled-components';
import { Box } from '@edapp/ed-components';

export const CourseInfoCard = styled(Box)`
  height: 388px;
  width: 304px;
  position: relative;

  :before {
    content: '';
    display: block;
    position: absolute;
    top: 42px;
    left: 0;
    right: 0;
    background: linear-gradient(
      180deg,
      ${({ theme }) => theme.colors.navyHover} 0%,
      transparent 100%
    );
    height: 20px;
    z-index: 2;
  }
`;
