import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ActionDialog, MailIcon } from '@edapp/ed-components';
import { useHippoRequest } from '@edapp/request/src/hooks';
import { getHippoUrl, getUserToken } from '@rio/store/config/selectors';

import type { Payload } from './types';

type Props = {
  actionsRequestUri: string;
  dialogBodyContent?: string;
  onClose: () => void;
  onNotificationSent: () => void;
  isDialogOpen: boolean;
  payload: Payload;
};

const PushNotificationDialog: React.FC<Props> = ({
  actionsRequestUri,
  dialogBodyContent,
  isDialogOpen,
  onClose,
  payload,
  onNotificationSent
}) => {
  const { t } = useTranslation();
  const defaultDialogBodyContent = t(
    'analytics.actionable-reports.push-notification-dialog.content'
  );

  const dialogBody = dialogBodyContent ? dialogBodyContent : defaultDialogBodyContent;

  const hippoUrl = useSelector(getHippoUrl);
  const userToken = useSelector(getUserToken);

  const request = useHippoRequest({
    uri: actionsRequestUri,
    method: 'POST',
    userToken,
    hippoUrl,
    skip: true,
    onSuccess: onNotificationSent
  });

  const onCloseDialog = () => {
    onClose();
    request.reset();
  };

  return (
    <ActionDialog
      isOpen={isDialogOpen}
      onClose={onCloseDialog}
      headerIcon={MailIcon}
      title={
        request?.error
          ? t('analytics.actionable-reports.push-notification-dialog.error')
          : !!request.loading
          ? t('analytics.actionable-reports.push-notification-dialog.sending')
          : t('analytics.actionable-reports.push-notification-dialog.confirmation')
      }
      description={
        request?.error
          ? t('analytics.actionable-reports.push-notification-dialog.error')
          : dialogBody
      }
      position="top"
      confirmButtonVariant="light"
      onConfirm={async () => {
        await request.fetch({ ...payload });
      }}
      disabled={request.loading}
      onCancel={onCloseDialog}
    />
  );
};

export default PushNotificationDialog;
