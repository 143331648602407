import { CircleTickIcon, InfoIcon } from '@edapp/ed-components';
import * as React from 'react';
import type { PostStatus } from '../types';
import { AssessmentStatus, DiscussionStatus } from '../types';
import type { PostPillProps } from './PostPill';
import { useTranslation } from 'react-i18next';

type StatusToProps = Omit<PostPillProps, 'onClick'>;

export const usePostStatus = (status?: PostStatus): StatusToProps => {
  const { t } = useTranslation();
  return React.useMemo(() => {
    switch (status) {
      case AssessmentStatus.SUBMITTED:
        return {
          pillBackgroundColor: 'blue',
          pillTextColor: 'white',
          pillIcon: InfoIcon,
          text: t('assignments.status.pending-grade')
        };

      case AssessmentStatus.TRYAGAIN:
        return {
          pillBackgroundColor: 'red',
          pillTextColor: 'white',
          pillIcon: InfoIcon,
          text: t('assignments.status.try-again')
        };

      case AssessmentStatus.PASSED:
        return {
          pillBackgroundColor: 'green',
          pillTextColor: 'white',
          pillIcon: CircleTickIcon,
          text: t('assignments.status.passed')
        };

      case AssessmentStatus.NOSUBMISSION:
        return {
          pillBackgroundColor: 'blue',
          pillTextColor: 'white',
          pillIcon: InfoIcon,
          text: t('assignments.status.no-submission')
        };

      case DiscussionStatus.COMPLETE:
        return {
          pillBackgroundColor: 'green',
          pillTextColor: 'white',
          pillIcon: CircleTickIcon,
          text: t('all-in-one-builder.discussions.status.completed')
        };

      case DiscussionStatus.INCOMPLETE:
      default:
        return {
          pillBackgroundColor: 'blue',
          pillTextColor: 'white',
          pillIcon: InfoIcon,
          text: t('all-in-one-builder.discussions.status.incomplete')
        };
    }
  }, [status]);
};
