import { useCallback, useMemo } from 'react';

import type { ContentLibraryCourseType, Industry } from '@edapp/content-library';
import { flattenPages, useHippoMutation, useHippoPagination, useHippoQuery } from '@edapp/request';
import type { RequestTypes } from '@edapp/request';
import type {
  UseHippoMutationOptions,
  UseHippoMutationResult,
  UseHippoPaginationOptions,
  UseHippoQueryOptions,
  UseHippoQueryResult
} from '@edapp/request/src/types';
import { useScPlatformContext } from '@edapp/sc-web-ui//src/ScMobilePlatformContext';
import { useQueryClient } from '@tanstack/react-query';
import type { InfiniteData } from '@tanstack/react-query';

import {
  fetchCourseLibraryCourse,
  fetchCourseLibraryIndustries,
  fetchCourseLibrarySection,
  fetchCourseLibrarySections,
  getContentLibrarySuggestions,
  importCourses,
  searchContentLibrary
} from './api';
import { CourseLibraryQueryKeys } from './constants';
import type {
  FetchContentLibrarySectionResponse,
  FetchContentLibrarySectionsResponse,
  GetContentLibrarySuggestionRequest,
  GetContentLibrarySuggestionResponse,
  ImportCoursesRequest,
  ImportCoursesResponse,
  SearchContentLibraryRequest,
  SearchContentLibraryResponse
} from './types';

export function useCourseLibraryIndustriesQuery(
  options?: UseHippoQueryOptions<Industry[]>
): UseHippoQueryResult<Industry[]> {
  return useHippoQuery<Industry[]>(
    [CourseLibraryQueryKeys.CL_INDUSTRIES_QUERY_KEY],
    (hippoUrl, userToken) => fetchCourseLibraryIndustries(hippoUrl, userToken),
    options
  );
}

export function useCourseLibrarySectionsQuery(
  options?: UseHippoPaginationOptions<FetchContentLibrarySectionsResponse>
) {
  const queryClient = useQueryClient();
  const queryKey = [CourseLibraryQueryKeys.CL_SECTIONS_QUERY_KEY];

  const response = useHippoPagination<FetchContentLibrarySectionsResponse>(
    queryKey,
    (hippoUrl, userToken) => ({ pageParam }) =>
      fetchCourseLibrarySections(hippoUrl, userToken, {
        page: pageParam || 1,
        pageSize: 3,
        coursesPageSize: 49
      }),
    options
  );

  const resetPagination = useCallback(() => {
    queryClient.setQueryData<InfiniteData<FetchContentLibrarySectionsResponse>>(
      queryKey,
      oldData => {
        if (!oldData) return undefined;
        return {
          ...oldData,
          pages: oldData.pages.slice(0, 1),
          pageParams: oldData.pageParams.slice(0, 1)
        };
      }
    );
  }, []);

  return useMemo(
    () => ({
      ...response,
      resetPagination,
      data: flattenPages(response.data)
    }),
    [response]
  );
}

export function useGetContentLibrarySuggestions(
  params: GetContentLibrarySuggestionRequest,
  options?: UseHippoQueryOptions<GetContentLibrarySuggestionResponse>
): UseHippoQueryResult<GetContentLibrarySuggestionResponse> {
  return useHippoQuery(
    [CourseLibraryQueryKeys.CL_SUGGESTIONS_QUERY_KEY, params.searchTerm],
    (hippoUrl, userToken) => () => {
      // no suggestions if search term is less than 2 characters, the API returns error
      if (params.searchTerm.trim().length < 2) {
        return Promise.resolve({
          authorSuggestions: [],
          industrySuggestions: []
        });
      }
      return getContentLibrarySuggestions(hippoUrl, userToken, params);
    },
    options
  );
}

type FormattedSearchCourseLibraryResponse = SearchContentLibraryResponse['courses'] &
  Pick<SearchContentLibraryResponse, 'industries'>;

export function useSearchCourseLibraryQuery(
  params: SearchContentLibraryRequest,
  options?: UseHippoPaginationOptions<FormattedSearchCourseLibraryResponse>
) {
  const queryClient = useQueryClient();
  const result = useHippoPagination<FormattedSearchCourseLibraryResponse>(
    [CourseLibraryQueryKeys.CL_SEARCH_QUERY_KEY, params],
    (hippoUrl, userToken) => async ({ pageParam }) => {
      const result = await searchContentLibrary(hippoUrl, userToken, {
        ...params,
        pageSize: 20,
        page: pageParam || 1
      });
      return {
        pages: [],
        pageParams: [],
        industries: result.industries,
        totalCount: result.courses.totalCount,
        items: result.courses.items
      };
    },
    options
  );
  return useMemo(() => {
    // Don't update industry list when users select an industry, need to keep it.
    const prevData = queryClient.getQueryData<
      RequestTypes.UseHippoPaginationResult<FormattedSearchCourseLibraryResponse>['data']
    >([CourseLibraryQueryKeys.CL_SEARCH_QUERY_KEY, { ...params, filterByTagsIds: [] }]);

    return {
      ...result,
      data: {
        courses: flattenPages(result.data),
        industries: prevData?.pages?.[0]?.industries || result.data?.pages?.[0]?.industries || []
      }
    };
  }, [result]);
}

export function useCourseLibraryCourseQuery(
  courseId: string,
  options?: UseHippoQueryOptions<ContentLibraryCourseType>
) {
  return useHippoQuery<ContentLibraryCourseType>(
    [CourseLibraryQueryKeys.CL_COURSE_QUERY_KEY, courseId],
    (hippoUrl, userToken) => () => fetchCourseLibraryCourse(hippoUrl, userToken, courseId),
    options
  );
}

type CourseLibraryQueryResponse = FetchContentLibrarySectionResponse['courses'] &
  Pick<FetchContentLibrarySectionResponse, 'id' | 'name'>;
export function useCourseLibrarySectionQuery(
  sectionId: string,
  options?: UseHippoPaginationOptions<CourseLibraryQueryResponse>
) {
  const result = useHippoPagination(
    [CourseLibraryQueryKeys.CL_SECTION_QUERY_KEY, sectionId],
    (hippoUrl, userToken) => async ({ pageParam }) => {
      const result = await fetchCourseLibrarySection(hippoUrl, userToken, {
        sectionId,
        page: pageParam || 1,
        pageSize: 20
      });
      return {
        pages: [],
        pageParams: [],
        totalCount: result.courses.totalCount,
        items: result.courses.items,
        name: result.name,
        id: result.id
      };
    },
    options
  );
  return useMemo(() => {
    return {
      ...result,
      data: {
        id: result.data?.pages?.[0]?.id,
        name: result.data?.pages?.[0]?.name,
        courses: flattenPages(result.data)
      }
    };
  }, [result]);
}

export function useImportCourses(
  options?: UseHippoMutationOptions<ImportCoursesResponse, ImportCoursesRequest>
): UseHippoMutationResult<ImportCoursesResponse, ImportCoursesRequest> {
  const { platform } = useScPlatformContext();

  return useHippoMutation<ImportCoursesResponse, ImportCoursesRequest>(
    (hippoUrl, userToken) => payload =>
      importCourses(hippoUrl, userToken, payload, { 'user-platform': platform || 'web' }),
    options
  );
}
