import styled, { css } from 'styled-components';

import { BackgroundImage } from '@edapp/ed-components';

export const LogoWrapper = styled.div<{
  logoBackgroundColor: 'white' | 'navy' | undefined;
  noBorder?: boolean;
}>(
  ({ theme, logoBackgroundColor, noBorder }) => css`
    width: ${theme.space(12)}px;
    height: ${theme.space(7)}px;
    padding: ${theme.space(0.5)}px;
    box-sizing: border-box;
    background-color: ${logoBackgroundColor === 'navy'
      ? theme.colors.navyHover
      : theme.colors.white};
    border-radius: ${theme.sizes.borderRadius * 2}px;
    ${!noBorder &&
    css`
      border: 1px solid ${theme.colors.greyHover};
      box-shadow: ${theme.shadows.middle};
    `}
    position: relative;
    z-index: 1;
    @media screen and (max-width: ${theme.flexboxgrid.breakpoints.sm}rem) {
      height: ${theme.space(5)}px;
      width: ${theme.space(7.5)}px;
    }
  `
);

export const Logo = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  z-index: 101;
`;
