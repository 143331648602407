import { fork, put, takeLatest } from 'redux-saga/effects';

import type { EdErrorResponseType } from '@edapp/authoring-logic';
import { RequestActions } from '@edapp/request';

import {
  ActionType,
  createUserGroupConditionsFailure,
  createUserGroupConditionsSuccess,
  fetchUserGroupConditionsFailure,
  fetchUserGroupConditionsSuccess,
  updateUserGroupConditionsFailure,
  updateUserGroupConditionsSuccess
} from './actions';
import type {
  CreateUserGroupConditionsAction,
  CreateUserGroupConditionsFailurePayload,
  CreateUserGroupConditionsSuccessPayload,
  DynamicUserGroupConditionsPayload,
  FetchUserGroupConditionsAction,
  FetchUserGroupConditionsFailurePayload,
  FetchUserGroupConditionsSuccessPayload,
  UpdateUserGroupConditionsAction,
  UpdateUserGroupConditionsFailurePayload,
  UpdateUserGroupConditionsSuccessPayload
} from './types';

function* handleFetchUserGroupConditinos({
  payload: { userGroupId }
}: FetchUserGroupConditionsAction) {
  yield put(
    RequestActions.getAuthed<
      DynamicUserGroupConditionsPayload,
      FetchUserGroupConditionsSuccessPayload,
      EdErrorResponseType,
      FetchUserGroupConditionsFailurePayload
    >(
      `/api/usergroups/${userGroupId}/conditions`,
      fetchUserGroupConditionsSuccess,
      fetchUserGroupConditionsFailure
    )
  );
}

function* handleCreateUserGroupConditions({
  payload: { userGroupId, conditions }
}: CreateUserGroupConditionsAction) {
  yield put(
    RequestActions.postAuthed<
      undefined,
      CreateUserGroupConditionsSuccessPayload,
      EdErrorResponseType,
      CreateUserGroupConditionsFailurePayload
    >(
      `/api/usergroups/${userGroupId}/conditions`,
      createUserGroupConditionsSuccess,
      createUserGroupConditionsFailure,
      false,
      conditions
    )
  );
}

function* handleUpdateUserGroupConditions({
  payload: { userGroupId, conditions }
}: UpdateUserGroupConditionsAction) {
  yield put(
    RequestActions.putAuthed<
      undefined,
      UpdateUserGroupConditionsSuccessPayload,
      EdErrorResponseType,
      UpdateUserGroupConditionsFailurePayload
    >(
      `/api/usergroups/${userGroupId}/conditions`,
      updateUserGroupConditionsSuccess,
      updateUserGroupConditionsFailure,
      false,
      conditions
    )
  );
}

function* watchFetchUserGroupConditions() {
  yield takeLatest(ActionType.FETCH_USER_GROUP_CONDITIONS, handleFetchUserGroupConditinos);
}

function* watchCreateUserGroupConditions() {
  yield takeLatest(ActionType.CREATE_USER_GROUP_CONDITIONS, handleCreateUserGroupConditions);
}

function* watchUpdateUserGroupConditions() {
  yield takeLatest(ActionType.UPDATE_USER_GROUP_CONDITIONS, handleUpdateUserGroupConditions);
}

export const sagas = [
  fork(watchFetchUserGroupConditions),
  fork(watchCreateUserGroupConditions),
  fork(watchUpdateUserGroupConditions)
];
