type Views = {
  SECTIONED_COURSES_VIEW: number;
  COURSE_LIST_VIEW: number;
};

export const views: Views = {
  SECTIONED_COURSES_VIEW: 0,
  COURSE_LIST_VIEW: 1
};

const SECTIONED_COURSES_VIEW_MAX_COURSE_COUNT = 8;

const { SECTIONED_COURSES_VIEW, COURSE_LIST_VIEW } = views;

export { SECTIONED_COURSES_VIEW_MAX_COURSE_COUNT, SECTIONED_COURSES_VIEW, COURSE_LIST_VIEW };
