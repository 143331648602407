import { isEmpty } from 'lodash-es';
import type { Reducer } from 'redux';

import { createKeyedObjectFromResponseArray } from '@rio/store/reducerUtils';

import type { AnalyticsPageType, AnalyticsState } from '../types/types';
import { SurveysActionTypes } from './actions';
import { initialSurveysState } from './constants';

export const surveysReducer: Reducer<AnalyticsState[AnalyticsPageType.SURVEYS]> = (
  state = initialSurveysState,
  action
) => {
  const { type } = action;

  switch (type) {
    case SurveysActionTypes.SET_SURVEYS_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload
        }
      };

    case SurveysActionTypes.FETCH_SURVEYS:
      return {
        ...state,
        data: {
          ...state.data,
          isLoading: true,
          error: ''
        }
      };

    case SurveysActionTypes.FETCH_SURVEYS_SUCCESS:
      const keyedSurveyItems = createKeyedObjectFromResponseArray(action.payload.items, 'courseId');
      const items =
        isEmpty(state.data.items) || action.payload.page === 1
          ? keyedSurveyItems
          : { ...state.data.items, ...keyedSurveyItems };
      return {
        ...state,
        data: {
          ...state.data,
          items: { ...items },
          page:
            Object.keys(items).length < action.payload.totalCount
              ? action.payload.page + 1
              : action.payload.page,
          isLoading: false,
          totalCount: action.payload.totalCount
        }
      };

    case SurveysActionTypes.FETCH_SURVEYS_FAILURE:
      return {
        ...state,
        data: {
          ...state.data,
          isLoading: false,
          error: 'Unable to load surveys at this time. Please try again later.'
        }
      };

    default:
      return {
        ...state
      };
  }
};
