import { getUserAuthToken } from '@edapp/notifications';
import { useHippoQuery } from '@edapp/request';

import { QueryKeys } from './constants';
import type { UseGetUserAuthToken } from './types';

/**
 * Get JWT Token for Knock's authentication https://docs.knock.app/in-app-ui/security-and-authentication
 */
export const useUserNotificationAuthToken: UseGetUserAuthToken = options =>
  useHippoQuery(
    [QueryKeys.userNotificationAuthToken],
    (hippoUrl, userToken) => () => getUserAuthToken(hippoUrl, userToken),
    options
  );
