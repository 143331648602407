import { lazy } from 'react';
import { retry } from '@edapp/utils';
import type { TryLazy } from './types';
import { errorFilter, getSessionRetryCount, setSessionRetryCount } from './utils';
import { CHUNK_LOAD_ERROR_NAME, MAX_RELOADS } from './constants';

export const tryLazy: TryLazy = factory =>
  lazy(async () => {
    try {
      return await retry(factory, { errorFilter });
    } catch (error) {
      if (error?.name !== CHUNK_LOAD_ERROR_NAME) {
        throw error;
      }

      const sessionRetryCount = getSessionRetryCount();

      if (sessionRetryCount < MAX_RELOADS) {
        setSessionRetryCount(sessionRetryCount + 1);
        window.location.reload();
      }

      throw error;
    }
  });
