import type { FetchSurveys } from './actions';
import { SurveysActionTypes } from './actions';
import { put, fork, takeLatest, select } from 'redux-saga/effects';
import { RequestActions } from '@edapp/request';
import { getSurveysFilterState, getSurveysPage, getSurveysPageSize } from './selectors';

function* handleFetchSurveys(action: Action<FetchSurveys>) {
  const page = getSurveysPage(yield select());
  const pageSize = getSurveysPageSize(yield select());
  const filterState = getSurveysFilterState(yield select());

  yield put(
    RequestActions.getAuthed(
      'api/analytics/survey/courses',
      responseFromServer => ({
        type: SurveysActionTypes.FETCH_SURVEYS_SUCCESS,
        payload: {
          page: action.payload?.page ?? page,
          ...responseFromServer
        }
      }),
      SurveysActionTypes.FETCH_SURVEYS_FAILURE,
      false,
      { page: action.payload?.page ?? page, pageSize, ...filterState }
    )
  );
}

function* watchFetchSurveys() {
  yield takeLatest(SurveysActionTypes.FETCH_SURVEYS, handleFetchSurveys);
}

const sagas = [fork(watchFetchSurveys)];

export default sagas;
