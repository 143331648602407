import type { AnalyticsPageType, AnalyticsState } from '../types/types';

export const COURSE_STATUSES = {
  ALL: { label: 'All', value: 'all' },
  DRAFTED: { label: 'Drafted', value: 'drafted' },
  PUBLISHED: { label: 'Published', value: 'published' },
  ARCHIVED: { label: 'Archived', value: 'archived' }
};

export const SURVEY_TYPES = {
  ALL: { label: 'All', value: 'all' },
  FREE_TEXT: { label: 'Free Text Survey', value: 'free-text-survey' },
  MULTIPLE_CHOICE: { label: 'Multiple Choice Survey', value: 'multiple-choice-survey' },
  SLIDER: { label: 'Slider Survey', value: 'slider-survey' },
  QUADRANT: { label: 'Quadrant Survey', value: 'quadrant-survey' }
};

export const initialSurveysState: AnalyticsState[AnalyticsPageType.SURVEYS] = {
  data: {
    items: {},
    isLoading: false,
    page: 1,
    pageSize: 20,
    error: '',
    totalCount: 0
  },
  filter: {
    searchTerm: '',
    courseStatus: COURSE_STATUSES.ALL.value,
    surveyType: SURVEY_TYPES.ALL.value
  }
};
