import * as React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box, Button, Dialog, Image, Typography } from '@edapp/ed-components';
import { ErrorLogger } from '@edapp/monitoring';
import { Text } from '@edapp/sc-web-ui';

const LOCAL_STORAGE_KEY = 'IE_DEPRECATED_DIALOG_LAST_DISMISSED';
const IE_LOGO_URL = '/edapp.com/assets/img/ie11-logo.png';

export const IEDeprecatedDialog: React.FC = () => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);

  React.useEffect(() => {
    if (shouldShowDialog()) {
      updateDismissedDate();
      setIsOpen(true);
    }
  }, []);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <MaxWidthDialog isOpen={isOpen} isClosable={false}>
      <FullSizeBox
        flex={true}
        flexDirection="column"
        textAlign="center"
        alignItems="center"
        justifyContent="center"
        py={6}
        px={8}
      >
        <IELogo src={IE_LOGO_URL} loading="eager" />
        <Box mt="sm">
          <Text variant="titleLarge" component="h2">
            {t('ie-deprecated-dialog.title')}
          </Text>
        </Box>
        <FullWidthTypography variant="paragraph" color="textMuted" mb="md">
          {t('ie-deprecated-dialog.description')}
        </FullWidthTypography>
        <Button variant="primary" onClick={handleClose}>
          {t('messages.ok-got-it')}
        </Button>
      </FullSizeBox>
    </MaxWidthDialog>
  );
};

function shouldShowDialog(): boolean {
  try {
    const lastDismissedDate = window.localStorage.getItem(LOCAL_STORAGE_KEY);

    if (!lastDismissedDate) {
      return true;
    }

    return Date.now() - +lastDismissedDate >= 86_400_000;
  } catch (err) {
    ErrorLogger.captureException(err);
    return false;
  }
}

function updateDismissedDate(): void {
  window.localStorage.setItem(LOCAL_STORAGE_KEY, `${Date.now()}`);
}

const MaxWidthDialog = styled(Dialog)`
  width: 600px;
`;

const FullSizeBox = styled(Box)`
  width: 100%;
  height: 100%;
`;

const FullWidthTypography = styled(Typography)`
  width: 100%;
`;

const IELogo = styled(Image)`
  width: 60px;
  height: 60px;
`;
