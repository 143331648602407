import type { DirectionProperty } from 'csstype';
import type { StateWithHistory } from 'redux-undo';

import type { CoursewareStatus } from '@edapp/ed-components';
import type { MeetingType, ObservationType } from '@edapp/hippo-client';
import type { CourseUserPermissions } from '@edapp/permissions';
import type { ActionsUnion, RecursivePartial } from '@edapp/utils';

import type RingoActions from './actions';
import type { MandatoryTypes } from './constants';
import type { FormStoreType } from './form/types';
import type { LanguageType } from './language/types';
import type { ModalType } from './modal/types';

export type { FileFormatType } from '@edapp/utils';
export type { SlideUpdateElementPayload, SlideUpdateValueType } from './form/actions';
export type { FormStoreType } from './form/types';

export type RingoActionsUnionType = ActionsUnion<typeof RingoActions>;

export type StoreState = {
  ringo: RingoStoreType;
};

// Ringo
export type RingoStoreType = {
  form: StateWithHistory<FormStoreType>;
  modals: ModalType[];
  lesson: AuthoringMiniLessonType | null;
  language: LanguageType;
};

export type AuthoringMiniLessonType = {
  id: LessonType['id'];
  courseId: LessonType['courseId'];
  title: LessonType['title'];
  description: LessonType['description'];
  configuration: Pick<LessonType['configuration'], 'styleConfiguration' | 'slides'>;
  features: Pick<LessonType['features'], 'starOptions'>;
  display: {
    active: boolean;
  };
  latestSavedSlidesStateIdentifier?: LessonType['latestSavedSlidesStateIdentifier'];
};

export type CategoryWrapperType = {
  category: CategoryType;
  templates: TemplateType[];
};

export type RawCategory = Omit<CategoryType, 'templateIndices'>;

export type CategoryType = {
  name: string;
  description: string;
  templateIndices: number[];
  rank: number;
  display?: string;
  background?: string;
  visible?: boolean;
  permissions?: string[];
};

export type LessonType = {
  id: string;
  archiveSize: number;
  author: string;
  configuration: LessonConfigurationType;
  containsSurvey: boolean;
  courseId: string;
  createdDateTime: Date;
  description: string;
  duration?: number;
  externalId: string;
  file: string;
  isArchived: boolean;
  isTutorial: boolean;
  lastBuildSha: string;
  metaTags: {};
  modified: Date;
  scormFile: string;
  scormFileName: string;
  useScormFile: boolean;
  title: string;
  useCourseBranding: boolean;
  courseTitle: string;
  collectionId: string;
  collectionTitle: string;
  collectionRank: number;
  collectionIsManual: boolean;
  prerequisites: string[];
  previewPath: string;
  previewCustomCSS: string;
  public: boolean;
  rank: number;
  hideScore: boolean;
  hideProgress: boolean;
  hideContentType: boolean;
  showStarProgressScore: boolean;
  stars: Array<{
    id: string;
    applicationId: string;
    dateCreated: Date;
    idToken: string;
    number: number;
    slide: number;
    slideId: string;
    token: string;
    type: string;
    deleted: boolean;
  }>;
  audience: {
    allowAllUserGroups: boolean;
    applicationId: string;
  };
  availability: {
    platforms: string[];
  } | null;
  build: {
    web: boolean;
  };
  contribution: {
    template: string;
    isHidden: boolean;
    isInputLesson: boolean;
    isUserContributed: boolean;
  };
  display: {
    active: boolean;
  };
  engine: {
    scorm: {
      supportsIframe: boolean;
    };
    thomas: {
      version: string;
      supportsIframe: boolean;
    };
    type: string;
  };
  features: LessonFeaturesType;
  interationalisation: {
    locale: string;
  };
  importDetails: {
    original: {
      applicationId: string;
      courseId: string;
      lessonId: string;
      documentId: string;
    };
    timestamp: Date;
  };
  planning: PlanningType;
  scoring: {
    enabled: boolean;
  };
  lessonType?: LessonDiscriminatorEnum;
  isSponsored: boolean;
  isRateable: boolean;
  /**
   * lesson version for incremental saving to avoid race conditions
   */
  latestSavedSlidesStateIdentifier: string;
};

export type LessonItemType = {
  id: string;
  lessonType: LessonDiscriminatorEnum;
  moduleId?: string | null;
  description: string;
  title: string;
  isPublished: boolean;
  previewUri: string;
  slides: SlideType[];
  config: StyleConfiguration;
  useCourseBranding: boolean;
  features?: {
    areStarsEnabled?: boolean;
  };
  totalStars?: number;
  duration?: number;
  latestSavedSlidesStateIdentifier: LessonType['latestSavedSlidesStateIdentifier'];
};

export type PlanningType = {
  startDateTime?: string;
  endDateTime?: string;
};

export type DueByType = {
  date?: Date;
  daysSinceUnlocked?: number;
};

export type CourseInternationalisationType = {
  locale: string;
};

export type CourseGenerationStatus = 'Generating' | 'Generated';
export type CourseGenerationPhases =
  | 'preparing-outline'
  | 'generating-slides'
  | 'processing-images';

export type CourseType = {
  id: string;
  title: string;
  description: string;
  courseStatus: CoursewareStatus;
  applicationId?: string; // the application id of the original course, can be used to fetch app settings

  config: StyleConfiguration;
  brandingImage: string;
  brandingTextColor: TextColor;
  thumbnail: string;

  logo: string;
  logoBackgroundColor: 'navy' | 'white';
  libraryImage: string;

  created?: Date;
  modified?: Date;

  completionCriteria: CourseCompletionCriteriaType;
  metaTags: {};

  universalAccess: boolean;
  userGroups: string[];

  totalNumberOfUsers?: number;
  timeSpent?: number;

  usageInformation?: {
    importedTimes: number;
    estimatedDurationInSeconds: number;
  };

  isSponsored: boolean;

  companyName: string;
  applicationProfileId: string;
  microCredential: MicroCredential | null;

  lessons: LessonItemType[];
  modules: ModuleItemType[];
  internationalisation: CourseInternationalisationType;

  prerequisites: string[];
  sequentialPlaylists: TitleType[];

  planning: PlanningType;
  mandatory?: MandatoryType;
  dueBy: DueByType;
  publishedDate?: Date;
  publishOn?: Date;
  unpublishOn?: Date;
  display: {
    active: boolean;
  };

  thomasUrl: string;
  externalIdentifier: string;
  hasNextLessonButton: boolean;

  collectionId: string;
  collectionTitle: string;
  collectionRank: number;
  collectionIsManual: boolean;

  isContentReady: boolean;
  isFromContentLibrary: boolean;
  isInappropriate: boolean;
  tags: string[];

  leaderboard: {
    id: string;
    isEnabled: boolean;
  } | null;
  isRapidRefresh?: boolean;
  creationType?: CourseCreationType;
  isGroupTrainingEnabled?: boolean;
  versionNumber: number;
  hasUnpublishedChanges: boolean;
  userPermissions: CourseUserPermissions[];

  // Create with AI
  generationStatus: CourseGenerationStatus;
  generationPhase?: CourseGenerationPhases;

  owner?: {
    id: string;
    displayName: string; // full name > username > email
  };

  supportedLanguages?: Array<SupportedLanguage>;
  language?: string;
};

export type CourseCreationType =
  | 'manual'
  | 'pptx'
  | 'ai generated'
  | 'ai document transformation'
  | 'content library'
  | 'rapid refresh';

export type CoursePreviewCourseType = Pick<
  CourseType,
  | 'id'
  | 'applicationId'
  | 'title'
  | 'description'
  | 'thomasUrl'
  | 'modules'
  | 'universalAccess'
  | 'userGroups'
  | 'courseStatus'
  | 'publishOn'
  | 'versionNumber'
  | 'hasUnpublishedChanges'
  | 'userPermissions'
  | 'mandatory'
  | 'publishedDate'
> & {
  lessons: CoursePreviewLessonType[];
  image?: string;
  config?: CourseType['config'];
  completionCriteria?: CourseType['completionCriteria'];
  internationalisation?: CourseType['internationalisation'];
  brandingImage?: CourseType['brandingImage'];
  brandingTextColor?: CourseType['brandingTextColor'];
  isSponsored?: CourseType['isSponsored'];
  isFromContentLibrary?: CourseType['isFromContentLibrary'];
  tags?: CourseType['tags'];
  planning?: CourseType['planning'];
  generationStatus?: CourseType['generationStatus'];
  collectionId?: CourseType['collectionId'];
  collectionIsManual?: CourseType['collectionIsManual'];
};

export type CoursePreviewLessonType = Pick<
  LessonItemType,
  | 'id'
  | 'title'
  | 'description'
  | 'lessonType'
  | 'config'
  | 'slides'
  | 'moduleId'
  | 'useCourseBranding'
  | 'features'
  | 'duration'
  | 'totalStars'
  | 'isPublished'
> &
  Partial<Pick<ObservationType, 'formUrl' | 'formioToken'>> &
  Partial<Pick<MeetingType, 'startDate' | 'endDate' | 'link' | 'recordingLink'>>;

// #region GET `/api/courses`
export type CourseSummaryType = {
  id: string;
  modified: Date;
  created: Date;
  title: string;
  externalIdentifier: string;
  brandingImage: string;
  brandingTextColor: 'white' | 'black';
  originalCourseId: string;
  lessonsCount: number;
  completionMilestone: string;
  completionLessonsPercentage: number;
  completionLessonsRequired: string[];
  isPublished: boolean;
  thumbnail: string;
  hasTranslationLink: boolean;
  hasPrerequisites: boolean;
  courseStatus: CoursewareStatus;
  description?: string;
  libraryImage: string;
  isContentReady: boolean;
  isFromContentLibrary: boolean;
  isSponsored: boolean;
  isInappropriate: boolean;
  isRapidRefresh?: boolean;
  companyName: string;
  logo?: string;
  logoBackgroundColor?: 'white' | 'navy';
  tags: string[];
  applicationProfileId: string;
  locale: string;
  locales?: Language[];
  universalAccess?: boolean;
  groupTrainingInfo?: GroupTrainingCourseInfo;
  versionNumber: number;
  userPermissions: CourseUserPermissions[];
  owner?: {
    id: string;
    displayName: string;
  };
} & Pick<CourseType, 'generationStatus'>;

export type CourseSummaryTypeCourseId = { courseId: string } & Omit<CourseSummaryType, 'id'>;

export type ModuleItemType = {
  id: string | null;
  courseId: string;
  title: string;
};

export type Language = {
  name: string;
  code: string;
};

export type GroupTrainingCourseInfo = {
  isEnabled: boolean;
  inProgressSessionId?: string;
  hasCompletedSession: boolean;
};

export type MicroCredential = {
  id: string;
  title: string;
  description: string;
  image: string;
  active: boolean;
  previewUrl: string;
};

export type CourseCompletionCriteriaType = {
  certificate: {
    enabled: boolean;
  };
  lessons: string[];
  milestone: string;
  openToComplete: boolean;
  percentage: number;
};

export type LessonFeaturesType = {
  starOptions: {
    enabled: boolean;
  };
  attemptRestriction: {
    resetCourseOnNumberAttempts?: number;
    lockOnCompletion: boolean;
    lockIntervalMin: number;
  };
};

export type MultiTypeInput = 'text' | 'image' | 'video' | 'audio' | 'iframe';

export type TemplateInputType =
  | 'text'
  | 'html'
  | 'boolean'
  | 'audio'
  | 'video'
  | 'iframe'
  | 'image'
  | 'number'
  | 'int'
  | 'string'
  | 'select'
  | 'image-region'
  | 'uuid'
  | 'scorm'
  | 'aicc'
  | 'sequence';

type AnswerFieldType = 'incorrect-answer' | 'not-specified' | 'required-points';

export type AnswerQuestionCoreType = TemplateInputType | AnswerFieldType;
type AnswerQuestionCore = {
  id?: string;
  type: AnswerQuestionCoreType;
  text: string;
};

export type Answer = AnswerQuestionCore & {
  content: string;
  correct: boolean;
};

export type QuestionDetails = AnswerQuestionCore & {
  content: string;
  category: string;
};

export type Question = {
  question: QuestionDetails;
  answers: Answer[];
};

export type SlideType = {
  id: string;
  type: string;
  subtype: string;
  displayIndex: string | number;
  templateName: string;
  name?: number | string;
  templateDescription?: string;
  permanent?: boolean;
  isLastSlide?: boolean;
  data:
    | ({
        name: string;
        path: string;
        type: TemplateInputType;
        elements?: ModelConvertedToFormType[];
        url: string;
        challenge_configuration: {
          nb_stars?: number | null;
          weighting?: number;
        };
        narration: {
          audio_file: string;
          can_not_be_skipped?: boolean;
          progress_on_completion?: boolean;
        };
        socialLearning: {
          enabled: boolean;
          maxResponseLength?: number;
        };
        questions: Array<Question>;
      } & TemplateExampleType)
    | any;
  metadata?:
    | {
        questions: Array<Question>;
      }
    | any;
  strippedMetadata?:
    | {
        questions: Array<RecursivePartial<Question>>;
      }
    | any;
  display?: string;
};

export type ModelFieldType = TemplateInputType | 'model' | 'repeater' | 'section' | 'multi';
export type ModelConvertedToFormType = ModelType & {
  name: string;
  path: string;
  elements?: ModelConvertedToFormType[];
};

export type ModelType<T = ModelFieldType | string> = {
  type: T;
  types?: TemplateInputType[];
  allows?: string[];
  default?: ValueType;
  display?: string;
  enum?: string[];
  help?: string;
  hidden?: boolean;
  label?: {
    type?: TemplateInputType | string;
    default?: string;
    help?: string;
  };
  max?: number;
  min?: number;
  translation?: boolean; // if the field is visible in the translation view
};

export type RegionType = {
  xMax: number;
  xMin: number;
  yMax: number;
  yMin: number;
};

export type ValueType = string | number | boolean | RegionType | null;

export type RawThomasTemplate = Omit<TemplateType, 'model'> & {
  model: Omit<ModelType, 'type'>; // model root doesnt have a type
};

export type TemplateType = {
  display: string;
  type: ModelFieldType | string;
  examples: TemplateExampleType[];
  model: ModelConvertedToFormType;

  category: string;
  description: string;
  visible?: boolean;
  help?: string;
  prompt?: {
    default: string;
  };
  links?: {
    academy: string;
  };
  features?: {
    narration?: boolean;
    stars?: boolean;
    social?: boolean;
    online?: boolean; // slides that requires internet connection to work
  };
  challenge_configuration?: {
    weighting: number;
    nb_stars: number;
  };
  hideFrom?: Array<'freemium' | 'sponsor' | string>;
};

export type TemplateExampleType = {
  subtype: string;
  [modelProperty: string]: any;
};

// Components
export type InputComponentProps = {
  /** Input model */
  model?: ModelConvertedToFormType;
  /** Input path */
  path?: string;
};

export type ColorScheme = {
  background?: string;
  text?: TextColor;
  experimental?: boolean;
  theme?: string;
  primaryPaletteColor?: string;
  secondaryPaletteColor?: string;
  primaryFontColor?: string;
  secondaryFontColor?: string;
};

export type TextColor = 'white' | 'black' | string;

export type StyleConfiguration = {
  background: string;
  colors?: ColorScheme;
  enableCustomCSS: boolean;
  customCSS: string;
  direction: DirectionProperty;
  disableInteractionSummary: boolean;
  disableLessonScore: boolean;
  hasAnswerFeedback: boolean;
  hasScormExitButton: boolean;
  preventLogoutOnExit: boolean;
  indicator: boolean;
  logo: string;
  minimumScore: number;
  pageNumbers: boolean;
  pagination: boolean;
  language: string;
  soundDisabled: boolean;
  preferences: StyleConfigurationPreferences;
};

export type StyleConfigurationPreferences = {
  useParentLogo: boolean;
  useParentBackgroundImage: boolean;
  useParentCustomCss: boolean;
  useParentColors: boolean;
};

export type EditStyleConfiguration = {
  useCourseBranding: boolean;
  enableCustomCss: boolean;
  customCss: PropertyStyleConfiguration;
  background: PropertyStyleConfiguration;
  colors?: ColorSchemeConfiguration;
  logo: PropertyStyleConfiguration;
};

export type PropertyStyleConfiguration = {
  useParentValue?: boolean;
  useEmptyValue?: boolean;
  parentValue?: string;
  value?: string;
};

export type ColorSchemeConfiguration = {
  useParentValue?: boolean;
  parentValue?: ColorScheme;
  value?: ColorScheme;
};

export type LessonConfigurationType = {
  slides: SlideType[];
  styleConfiguration: StyleConfiguration;
  isDebug: boolean;
  index: number;
  isAudio: boolean;
  isOnline: boolean;
  lessonPreviewConfigurationHeight: number;
  lessonPreviewConfigurationWidth: number;
  scale: number;
  state: string;
  title: string;
  narration: {};
};

export enum LessonDiscriminatorEnum {
  LESSON = 'Lesson',
  DISCUSSION = 'Discussion',
  ASSESSMENT = 'Assessment',
  OBSERVATION = 'Observation',
  // Conference was renamed to virtual classroom after the feature was deployed,
  // so visible elements are named 'virtual classroom' but backend still uses 'conference'
  VIRTUAL_CLASSROOM = 'Conference'
}

export enum CoursewareVersionType {
  Latest = 'Latest',
  Published = 'Published'
}

type Mandatory<Type extends MandatoryTypes> = {
  type: Type;
  enabled: boolean;
};
export type MandatoryNoDueDateType = Mandatory<MandatoryTypes.NoDueDate>;
export type MandatoryDueByType = Mandatory<MandatoryTypes.DueBy> & {
  date: string;
};
export type MandatoryRelativeDueByType = Mandatory<MandatoryTypes.RelativeDueBy> & {
  days: number;
};
export type MandatoryRecurringCompletionDate = Mandatory<MandatoryTypes.RecurringCompletionDate> & {
  days: number;
  expiryMonths: number;
};
export type MandatoryRecurringPublishDate = Mandatory<MandatoryTypes.RecurringPublishDate> & {
  days: number;
  expiryMonths: number;
};
export type MandatoryRecurringStaticDate = Mandatory<MandatoryTypes.RecurringStaticDate> & {
  days: number;
  expiryMonths: number;
  month: number;
  dayOfMonth: number;
};
export type MandatoryType =
  | MandatoryNoDueDateType
  | MandatoryDueByType
  | MandatoryRelativeDueByType
  | MandatoryRecurringCompletionDate
  | MandatoryRecurringPublishDate
  | MandatoryRecurringStaticDate;

type TitleType = {
  id: string;
  title: string;
};

export type DeviceType = {
  name: string;
  manufacturer: string;
  partial?: boolean;
  size: {
    width: number;
    height: number;
  };
};

export type SupportedLanguage = {
  code: string;
  isOriginalLanguage: boolean;
  isSelected: boolean;
  name: string;
};
