import 'proxy-polyfill/proxy.min.js';
import 'raf/polyfill';
import 'url-search-params-polyfill';

import * as React from 'react';

import 'core-js/es';
import { merge } from 'lodash-es';
import qs from 'qs';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { itly } from '@edapp/analytics-tracking';
import type { LoadOptions } from '@edapp/analytics-tracking';
import { initializeBraze } from '@edapp/braze-ui';
import { OnlineStatusContextProvider } from '@edapp/courseware-logic';
import { AssetContext, Loading } from '@edapp/ed-components';
import { CanvaAPIAccessContext } from '@edapp/media-library';
import { ConsoleAdapter, DatadogAdapter, ErrorLogger } from '@edapp/monitoring';
import { APIAccessProvider } from '@edapp/request';
import { OrgSettingsContext } from '@edapp/sc-org-settings-context';
import { ScPlatformContextProvider } from '@edapp/sc-web-ui';
import { AppearanceContextProvider } from '@edapp/themes';
import type { SchemeType } from '@edapp/themes';
import { SCHEME_COOKIE, setCookie } from '@edapp/themes/src/common/AppearanceContext';
import { initI18NOnce } from '@edapp/translations';
import { isIE } from '@edapp/utils';
import { WebviewUxpContextProvider } from '@edapp/uxp-webview';
import loadable from '@loadable/component';
import type { DefaultComponent, LoadableComponent } from '@loadable/component';
import { EnvironmentProvider } from '@rio/components/environment/EnvironmentProvider';
import { FeatureFlagsProvider } from '@rio/components/feature-flags';
import { UserInfoProvider } from '@rio/components/user/UserInfoProvider';
import { Navbar } from '@rio/containers/nav-bar/Navbar';
import Navigation from '@rio/containers/navigation/Navigation';
import configureStore from '@rio/store/configureStore';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { GlobalStyle } from './GlobalStyle';
import { IEDeprecatedDialog } from './IEDeprecatedDialog';
import { ThemeProvider } from './ThemeProvider';
import { multiTabLogoutListener } from './utils/multiTabLogout';
import './wdyr';

export const asyncRetryFailureMessage = 'Failed to retry';
/**
 *
 * @param fn Function to retry.
 * @param args Arguments to provide the function.
 * @param errorName Name of error to catch and retry for. Any other error will not cause a retry.
 * @param maxRetries Maximum number of retries.
 * @returns
 */
export const asyncRetry = async <T extends (...args: any[]) => any>(
  fn: T,
  args: Parameters<T>,
  errorName: string,
  maxRetries: number = 3
): Promise<Awaited<ReturnType<T>>> => {
  try {
    const res = await fn(...args);
    return res;
  } catch (e) {
    if (e?.name !== errorName) {
      throw e; // Any unexpected errors are immediately rethrown.
    }
    if (maxRetries - 1 === 0) {
      throw new Error(asyncRetryFailureMessage);
    }
    return asyncRetry(fn, args, errorName, maxRetries - 1);
  }
};

const maxReloads = 2;
const retrySessionKey = 'CHUNKLOAD_REFRESH_RETRY_COUNT';
const tryLoadable: <T>(
  loadFn: (...args: any[]) => Promise<DefaultComponent<T>>
) => LoadableComponent<T> = loadFn => {
  return loadable(async () => {
    try {
      return await asyncRetry(loadFn, [], 'ChunkLoadError');
    } catch (e) {
      if (e?.message === asyncRetryFailureMessage) {
        const sessionRetryCount =
          parseInt(window.sessionStorage.getItem(retrySessionKey) || '') || 0;
        if (sessionRetryCount < maxReloads) {
          window.sessionStorage.setItem(retrySessionKey, `${sessionRetryCount + 1}`);
          window.location.reload();
        }
      }
      throw e;
    }
  });
};

const componentList = {
  // BetterPptx: tryLoadable<{}>('@rio/containers/better-pptx/BetterPptx'),
  BetterPptx: tryLoadable(() =>
    import(
      /* webpackChunkName: "BetterPptx" */
      '@rio/containers/better-pptx/BetterPptx'
    )
  ),
  Preview: tryLoadable(() =>
    import(
      /* webpackChunkName: "Preview" */
      '@rio/containers/preview/Preview'
    )
  ),
  AppSettings: tryLoadable(() =>
    import(
      /* webpackChunkName: "AppSettings" */
      '@rio/containers/AppSettings/AppSettings'
    )
  ),
  UserGroups: tryLoadable(() =>
    import(
      /* webpackChunkName: "UserGroups" */
      '@rio/containers/UserGroups/UserGroups'
    )
  ),
  Billing: tryLoadable(() =>
    import(
      /* webpackChunkName: "Billing" */
      '@rio/containers/Billing/Billing'
    )
  ),
  BillingModals: tryLoadable(() =>
    import(
      /* webpackChunkName: "BillingModals" */
      '@rio/containers/BillingModals/BillingModals'
    )
  ),
  BillingUnsubscribed: tryLoadable(() =>
    import(
      /* webpackChunkName: "BillingUnsubscribed" */
      '@rio/containers/BillingUnsubscribed/BillingUnsubscribed'
    )
  ),
  /* ------ Navigations ------- */
  Navigation: Navigation,
  SideFeedback: tryLoadable(() =>
    import(
      /* webpackChunkName: "SideFeedback" */
      '@rio/containers/navigation/SideFeedback'
    )
  ),
  /* --------------------------- */
  Users: tryLoadable(() =>
    import(
      /* webpackChunkName: "Users" */
      '@rio/containers/Users/Users'
    )
  ),
  UserListActions: tryLoadable(() =>
    import(
      /* webpackChunkName: "UserListActions" */
      '@rio/containers/Users/list/user-list-actions/UserListActions'
    ).then(({ UserListActions }) => UserListActions)
  ),
  UploadButton: tryLoadable(() =>
    import(
      /* webpackChunkName: "UploadButton" */
      '@rio/containers/upload-button/UploadButton'
    )
  ),
  DeleteBtnDialog: tryLoadable(() =>
    import(
      /* webpackChunkName: "DeleteBtnDialog" */
      '@rio/containers/delete-btn-dialog/DeleteBtnDialog'
    )
  ),
  LmsSso: tryLoadable(() =>
    import(
      /* webpackChunkName: "LmsSso" */
      '@rio/containers/LmsSso/LmsSsoContainer'
    )
  ),
  UserGroupRestrictionSupportLinkBox: tryLoadable(() =>
    import(
      /* webpackChunkName: "UserGroupRestrictionSupportLink" */
      '@rio/containers/UserGroups/UserGroupRestrictionSupportLinkBox'
    )
  ),
  NoAccess: tryLoadable(() =>
    import(
      /* webpackChunkName: "NoAccess" */
      '@rio/containers/Error/NoAccess'
    )
  ),
  MobileBanner: tryLoadable(() =>
    import(
      /* webpackChunkName: "MobileBanner" */
      '@rio/components/mobile-banner/MobileBanner'
    )
  ),
  RegisterInvitation: tryLoadable(() =>
    import(
      /* webpackChunkName: "RegisterInvitation" */
      '@rio/containers/RegisterInvitation/RegisterInvitation'
    )
  ),
  Navbar: Navbar, // no lazy for navs
  AppSettingsMenu: tryLoadable(() =>
    import(
      /* webpackChunkName: "AppSettingsMenu" */
      '@rio/containers/AppSettings/AppSettingsMenu'
    ).then(({ AppSettingsMenu }) => AppSettingsMenu)
  ),

  Rio: tryLoadable(() =>
    import(
      /* webpackChunkName: "Rio" */
      './router'
    ).then(({ Router }) => Router)
  )
};

const rehydrateRio = () => {
  let config = {};
  let preloadedData: any = {};

  const collection = document.querySelectorAll('[data-react-component]');
  const preloadElements = document.querySelectorAll('[data-react-preload]');
  const configElements = document.querySelectorAll('[data-react-config]');

  if (preloadElements && preloadElements.length > 0) {
    Array.from(preloadElements).forEach(preloadElement => {
      try {
        const preload = JSON.parse(preloadElement.textContent || '');
        preloadedData = merge({}, preloadedData, preload);
      } catch (e) {
        ErrorLogger.captureException(e, { preload: preloadElement.textContent || '' });
        throw e;
      }
    });
  }

  if (configElements && configElements.length > 0) {
    // Reversing config elements as a workaround for issue caused by react-component.jade data-react-config overwriting
    // the initial config state specified for the lesson in authoring-tool.jade data-react-config.
    Array.from(configElements)
      .reverse()
      .forEach(configElement => {
        try {
          const dataConfig = JSON.parse(configElement.textContent || '');
          config = merge({}, config, dataConfig);
        } catch (e) {
          ErrorLogger.captureException(e, { config: configElement.textContent || '' });
          throw e;
        }
      });
  }

  // We should only init store instance once to share across multiple DOM renders
  const { store, persistor } = configureStore(config, preloadedData);

  Array.from(collection).forEach(element => {
    const componentName = element.getAttribute('data-react-component');
    const Component = componentName && componentList[componentName];
    if (!Component || !componentName) {
      ErrorLogger.captureEvent('Missing component', 'error', { componentName });
      return;
    }

    // Get props data per component
    const propsElements = element.querySelectorAll('[data-react-props]');

    let dataProps = {};
    if (propsElements && propsElements.length > 0) {
      Array.from(propsElements).forEach(propsElement => {
        try {
          const props = JSON.parse(propsElement.textContent || '');
          dataProps = merge({}, dataProps, props);
        } catch (e) {
          ErrorLogger.captureException(e, { props: propsElement.textContent || '' });
          throw e;
        }
      });
    }

    const config = store.getState().config;

    ErrorLogger.init(() =>
      config.monitoring
        ? new DatadogAdapter({
            applicationId: config.monitoring.applicationId,
            clientToken: config.monitoring.clientToken,
            service: 'lms',
            env: config.monitoring.environment,
            version: config.monitoring.version,
            sampleRate: config.monitoring.sampleRate,
            replaySampleRate: config.monitoring.replaySampleRate
          })
        : new ConsoleAdapter()
    );

    ErrorLogger.setUserContext({
      applicationId: config.userAppId,
      id: config.userId,
      email: config.userEmail,
      name: config.userEmail
    });

    // init Tracking Iteratively
    const { sc_user_id, lang } = qs.parse(location.search.substring(1));
    const loadOptions: LoadOptions = {
      environment:
        store.getState().config.environment === 'ed_production_aws' ? 'production' : 'development',
      disabled: !store.getState().config.trackingEnabled
    };

    itly.load(loadOptions);

    itly.identify(config.userId, {
      email: config.userEmail || config.userName,
      firstname: config.userFirstName,
      lastname: config.userLastName,
      roles: config.userRoles,
      application_id: config.userAppId, // needed for hubspot
      application_name: config.userAppName, // needed for hubspot
      sc_user_id: sc_user_id?.toString(),
      language: lang?.toString() || 'en-US'
    });
    itly.group(config.userAppId, {
      amplitudeType: 'Application',
      application_id: config.userAppId,
      application_name: config.userAppName
    });

    // Init Braze
    if (store.getState().config.brazeEnabled) {
      initializeBraze(config.publicBrazeApiKey);
    }

    const queryClient = new QueryClient();

    const onSchemeChange = (selectedScheme: SchemeType) => {
      setCookie(SCHEME_COOKIE, selectedScheme);
      document
        .getElementsByTagName('body')[0]
        .classList.toggle(
          'dark-mode',
          selectedScheme === 'ed-dark' || selectedScheme === 'sc-dark'
        );
    };

    initI18NOnce({
      detection: {
        order: ['querystring', 'localStorage', 'navigator'],
        caches: ['localStorage'],
        lookupQuerystring: 'lang'
      },
      supportedLngs: false,
      debug: process.env.NODE_ENV !== 'production',
      ns: [
        'translation',
        'tables',
        'sc-web-ui',
        'ed-components',
        'slides',
        'formio',
        'certificates',
        'lesson-navigation',
        'leaderboard'
      ]
    }).then(i18n => {
      const root = createRoot(element);
      root.render(
        <I18nextProvider i18n={i18n}>
          <AppearanceContextProvider onSchemeChange={onSchemeChange}>
            <ThemeProvider i18n={i18n}>
              <GlobalStyle />
              <ScPlatformContextProvider>
                <Provider store={store}>
                  <EnvironmentProvider config={config}>
                    <OrgSettingsContext.Provider value={{ enableAiFeatures: true }}>
                      <AssetContext.Provider
                        value={{
                          baseUrl: 'https://media.edapp.com',
                          transformedHostnames: ['media.edapp.com', 'res.cloudinary.com/edapp']
                        }}
                      >
                        <PersistGate loading={<Loading />} persistor={persistor}>
                          <APIAccessProvider
                            token={config.userToken || ''}
                            apiUrl={config.hippoUrl}
                          >
                            <CanvaAPIAccessContext.Provider
                              value={{ apiKey: config.publicCanvaApiKey, apiAccessDisabled: false }}
                            >
                              <QueryClientProvider client={queryClient}>
                                <OnlineStatusContextProvider>
                                  <DndProvider backend={HTML5Backend}>
                                    <FeatureFlagsProvider>
                                      <UserInfoProvider config={config}>
                                        <WebviewUxpContextProvider
                                          openWebview={() => false}
                                          closeWebview={() => false}
                                        >
                                          <Component {...dataProps} />

                                          {isIE() && <IEDeprecatedDialog />}
                                        </WebviewUxpContextProvider>
                                      </UserInfoProvider>
                                    </FeatureFlagsProvider>
                                  </DndProvider>

                                  <ReactQueryDevtools position="bottom" />
                                </OnlineStatusContextProvider>
                              </QueryClientProvider>
                            </CanvaAPIAccessContext.Provider>
                          </APIAccessProvider>
                        </PersistGate>
                      </AssetContext.Provider>
                    </OrgSettingsContext.Provider>
                  </EnvironmentProvider>
                </Provider>
              </ScPlatformContextProvider>
            </ThemeProvider>
          </AppearanceContextProvider>
        </I18nextProvider>
      );
    });

    element.removeAttribute('data-react-component');
  });
};

// self-start
rehydrateRio();

multiTabLogoutListener();

// expose rehydration through the window for other consumers to use
(window as any).rehydrateRio = rehydrateRio;
