import { RequestUtils } from '@edapp/request';
import type { UpdateUserProfileRequest } from './types';

export async function updateUserProfile(
  hippoUrl: string,
  userToken: string,
  request: UpdateUserProfileRequest
): Promise<void> {
  return RequestUtils.httpFetch(
    'PATCH',
    `${hippoUrl}/api/users/${request.userId}/profile`,
    userToken,
    request
  );
}
