import * as React from 'react';

import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { Dropdown, IconButton, OptionsVIcon, Tooltip } from '@edapp/ed-components';
import type { DropdownProps } from '@edapp/ed-components/src/common/dropdown/types';

type Props = React.ComponentProps<typeof IconButton> & {
  closeOnClickItem?: boolean;
  tooltipContent?: string;
  /**
   * Virtual offset to trigger auto-position dropdown if dropdown gets cut off
   */
  bottomOffset?: number;
  /**
   * With tooltip or not
   */
  withTooltip?: boolean;
  /**
   * Fallback placement when offside
   */
  fallbackPlacements?: Array<DropdownProps['placement']>;
  /**
   * Override the default z-index, useful for dropdowns inside a panel/modal
   */
  zIndex?: number;
} & Pick<React.ComponentProps<typeof Dropdown>, 'placement' | 'menuPortalTarget'>;

export const ThreeDotMenu: React.FC<Props> = ({
  children,
  closeOnClickItem = true,
  tooltipContent = 'common.more',
  className,
  bottomOffset = 0,
  withTooltip = true,
  placement = 'bottom-end',
  fallbackPlacements,
  zIndex,
  menuPortalTarget,
  ...rest
}) => {
  const [isTooltipVisible, setIsTooltipVisible] = React.useState<boolean>(false);
  const { t } = useTranslation();

  return (
    <StyledDropdown
      isOpen={rest.isOpen}
      placement={placement}
      onClick={() => setIsTooltipVisible(false)}
      closeOnClickItem={closeOnClickItem}
      keepOpenOnClickTrigger={false}
      menuPortalTarget={menuPortalTarget}
      zIndex={zIndex}
      modifiers={[
        {
          name: 'flip',
          options: { padding: { bottom: bottomOffset }, fallbackPlacements }
        }
      ]}
      trigger={
        // div to pass ref
        <div className={className}>
          <Tooltip
            isOpen={withTooltip ? isTooltipVisible : false}
            content={t(tooltipContent)}
            placement="bottom"
            size="xs"
            showArrow={false}
            usePortal
          >
            <IconButton
              icon={OptionsVIcon}
              size="sm"
              iconColor="text"
              variant="transparent"
              {...rest}
              onMouseEnter={() => {
                withTooltip && setIsTooltipVisible(true);
                rest.onMouseEnter?.();
              }}
              onMouseLeave={() => {
                withTooltip && setIsTooltipVisible(false);
                rest.onMouseLeave?.();
              }}
            />
          </Tooltip>
        </div>
      }
    >
      {children}
    </StyledDropdown>
  );
};

const StyledDropdown = styled(Dropdown)<{ zIndex?: number }>(
  ({ zIndex }) => css`
    ${typeof zIndex !== 'undefined' &&
    css`
      z-index: ${zIndex};
    `}
  `
);
