import * as qs from 'qs';
import { delay } from 'redux-saga';
import { all, fork, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { initialCourseState } from '@edapp/courseware-logic';
import type { CourseType } from '@edapp/courseware-logic';
import { ErrorLogger } from '@edapp/monitoring';
import type { ValidationErrorResponseType } from '@edapp/request';
import { RequestActions } from '@edapp/request';
import type { ActionFromActionType } from '@edapp/utils';
import { QS_PPTX_URL } from '@rio/components/import-pptx/constants';
import type { LmsStoreState } from '@rio/store/types';

import type { BetterPptxActionsUnionType } from './actions';
import { BetterPptxActionTypes, BetterPptxActions } from './actions';
import { BetterPptxSelectors } from './selectors';
import type { Lesson, SaveLessonTitlesPayload, SaveSlidesPayload, Slide } from './types';

const MAX_SAVE_SLIDES_RETRIES = 5;
let SAVE_SLIDES_RETRIES = 0;

type SaveSlidesRaceType = {
  success: {};
  failure: { payload: ValidationErrorResponseType };
};

type Action<ActionType extends string> = ActionFromActionType<
  BetterPptxActionsUnionType,
  ActionType
>;

function* handleSaveLessons() {
  yield delay(1000);

  const lessons: Lesson[] = yield select<LmsStoreState>(s => s.betterPptx.lessons);
  const courseId: string = yield select<LmsStoreState>(s => s.betterPptx.courseId);

  const payload: SaveLessonTitlesPayload = {
    lessons: lessons.map(i => ({ id: i.id, title: i.title }))
  };
  yield put(
    RequestActions.putAuthed(
      `/api/courses/${courseId}/pptx-lessons`,
      BetterPptxActionTypes.BETTER_PPTX_SAVE_LESSONS_SUCCESS,
      BetterPptxActionTypes.BETTER_PPTX_SAVE_LESSONS_FAILURE,
      false,
      payload
    )
  );
}

function* handleSaveSlides(action: Action<BetterPptxActionTypes.BETTER_PPTX_SAVE_SLIDES>) {
  const { lessonId } = action.payload;

  const slides = action.payload.slides.map((s, si) => ({ ...s, displayIndex: si + 1 }));

  yield put(
    RequestActions.patchAuthed(
      `/api/lesson-authoring/${lessonId}`,
      BetterPptxActionTypes.BETTER_PPTX_SAVE_SLIDES_SUCCESS,
      BetterPptxActionTypes.BETTER_PPTX_SAVE_SLIDES_FAILURE,
      false,
      { slides } as SaveSlidesPayload
    )
  );

  const { success, failure }: SaveSlidesRaceType = yield race({
    success: take(BetterPptxActionTypes.BETTER_PPTX_SAVE_SLIDES_SUCCESS),
    failure: take(BetterPptxActionTypes.BETTER_PPTX_SAVE_SLIDES_FAILURE)
  });

  if (success) {
    SAVE_SLIDES_RETRIES = 0;
    return; // all g
  }

  if (SAVE_SLIDES_RETRIES >= MAX_SAVE_SLIDES_RETRIES) {
    ErrorLogger.captureEvent('Better PPTX - Max retries - stopped trying to save', 'error', {
      lessonId,
      slides,
      failure
    });
    return;
  }

  SAVE_SLIDES_RETRIES++;
  // if it fails to save slide
  // 0. log to sentry
  // 1. remove slide from the suggestions (happens in reducer)
  // 2. switch slide to simple image (happens in reducer)
  // 3. retrigger save

  ErrorLogger.captureEvent('Better PPTX - Failed to save slides', 'error', {
    lessonId,
    slides,
    error: failure.payload.message
  });

  const adjustmentEffects = Object.keys(failure.payload.validationErrors).map(key => {
    const [slideIndexKeyName] = key.split('.');
    const slideIndex = parseInt(slideIndexKeyName.replace('Slide ', '')) - 1;
    return put(BetterPptxActions.adjustFailedToSaveSlide(lessonId, slideIndex));
  });
  yield all(adjustmentEffects);

  const updatedSlides: Slide[] = yield select<LmsStoreState>(
    BetterPptxSelectors.getLessonSlides(lessonId)
  );
  yield put(BetterPptxActions.saveSlides(lessonId, updatedSlides));
}

function* handleCreateCourse() {
  const courseId: string = yield select<LmsStoreState>(s => s.betterPptx.courseId);

  if (!!courseId) {
    // already has courseId - no need to recreate! (helps development)
    yield put(BetterPptxActions.createCourseSuccess(courseId));
    return;
  }

  const data: CourseType = {
    ...initialCourseState,
    title: 'Untitled course',
    creationType: 'pptx'
  };
  yield put(
    RequestActions.postAuthed(
      `/api/courses`,
      BetterPptxActionTypes.BETTER_PPTX_CREATE_COURSE_SUCCESS,
      BetterPptxActionTypes.BETTER_PPTX_CREATE_COURSE_FAILURE,
      false,
      data
    )
  );

  const { success } = yield race({
    success: take(BetterPptxActionTypes.BETTER_PPTX_CREATE_COURSE_SUCCESS),
    failure: take(BetterPptxActionTypes.BETTER_PPTX_CREATE_COURSE_FAILURE)
  });

  if (success) {
    const parsedQueryString = qs.parse(window.location.search.substring(1));
    const pptUrl = parsedQueryString[QS_PPTX_URL]?.toString();
    if (!pptUrl) return;
    yield put(BetterPptxActions.transformPptx(pptUrl));
  }

  // failure handled elsewhere
}

function* handleDeleteCourse() {
  const courseId: string = yield select<LmsStoreState>(s => s.betterPptx.courseId);

  if (!courseId) {
    return;
  }

  yield put(
    RequestActions.deleteAuthed(
      `/api/courses/${courseId}`,
      BetterPptxActionTypes.BETTER_PPTX_DELETE_COURSE_SUCCESS,
      BetterPptxActionTypes.BETTER_PPTX_DELETE_COURSE_FAILURE,
      false
    )
  );
}

function* handleTransformPptx(action: Action<BetterPptxActionTypes.BETTER_PPTX_TRANSFORM_PPTX>) {
  yield put(
    RequestActions.postAuthed(
      `/api/lessons/pptx/transform`,
      BetterPptxActionTypes.BETTER_PPTX_TRANSFORM_PPTX_SUCCESS,
      BetterPptxActionTypes.BETTER_PPTX_TRANSFORM_PPTX_FAILURE,
      false,
      { url: action.payload.pptUrl }
    )
  );

  const { failure } = yield race({
    success: take(BetterPptxActionTypes.BETTER_PPTX_TRANSFORM_PPTX_SUCCESS),
    failure: take(BetterPptxActionTypes.BETTER_PPTX_TRANSFORM_PPTX_FAILURE)
  });

  if (failure) {
    yield put(BetterPptxActions.deleteCourse());
  }
}

function* handleFinalisePptx() {
  yield put(BetterPptxActions.saveLessons());

  const { success } = yield race({
    success: take(BetterPptxActionTypes.BETTER_PPTX_SAVE_LESSONS_SUCCESS),
    failure: take(BetterPptxActionTypes.BETTER_PPTX_SAVE_LESSONS_FAILURE)
  });

  if (!success) {
    // failure to be handled elsewhere
    return;
  }

  const lessons: Lesson[] = yield select<LmsStoreState>(s => s.betterPptx.lessons);

  const saveSlideEffects = lessons.map(l => put(BetterPptxActions.saveSlides(l.id!, l.slides)));
  yield all(saveSlideEffects);

  const successOfEffects = lessons.map(l =>
    take((action: any) => {
      return (
        action.type === BetterPptxActionTypes.BETTER_PPTX_SAVE_SLIDES_SUCCESS &&
        action.payload.id === l.id
      );
    })
  );
  yield all(successOfEffects);

  yield put(BetterPptxActions.clear());
  const firstLessonId = lessons[0].id;
  window.location.href = `lesson/${firstLessonId}/edit?isPptxContent=true`;
}

function* handleSlideActionToTriggerSave(
  action: Action<
    | BetterPptxActionTypes.BETTER_PPTX_CHANGE_SLIDE
    | BetterPptxActionTypes.BETTER_PPTX_DELETE_SLIDE
    | BetterPptxActionTypes.BETTER_PPTX_REORDER_SLIDE
  >
) {
  const { lessonIndex } = action.payload;
  const lesson: Lesson = yield select<LmsStoreState>(s => s.betterPptx.lessons[lessonIndex]);
  if (!lesson.id) {
    return; // not ready to auto-save yet
  }

  yield put(BetterPptxActions.saveSlides(lesson.id, lesson.slides));
}

function* watchReorderSlide() {
  yield takeLatest(BetterPptxActionTypes.BETTER_PPTX_REORDER_SLIDE, handleSlideActionToTriggerSave);
}

function* watchChangeSlide() {
  yield takeLatest(BetterPptxActionTypes.BETTER_PPTX_CHANGE_SLIDE, handleSlideActionToTriggerSave);
}

function* watchDeleteSlide() {
  yield takeLatest(BetterPptxActionTypes.BETTER_PPTX_DELETE_SLIDE, handleSlideActionToTriggerSave);
}

function* watchSaveSlides() {
  yield takeLatest(BetterPptxActionTypes.BETTER_PPTX_SAVE_SLIDES, handleSaveSlides);
}

function* watchSaveLessons() {
  yield takeLatest(
    [
      BetterPptxActionTypes.BETTER_PPTX_SAVE_LESSONS,
      BetterPptxActionTypes.BETTER_PPTX_ADD_LESSON,
      BetterPptxActionTypes.BETTER_PPTX_SET_LESSON,
      BetterPptxActionTypes.BETTER_PPTX_DELETE_LESSON,
      BetterPptxActionTypes.BETTER_PPTX_REORDER_LESSON
    ],
    handleSaveLessons
  );
}

function* watchCreateCourse() {
  yield takeLatest(BetterPptxActionTypes.BETTER_PPTX_CREATE_COURSE, handleCreateCourse);
}

function* watchDeleteCourse() {
  yield takeLatest(BetterPptxActionTypes.BETTER_PPTX_DELETE_COURSE, handleDeleteCourse);
}

function* watchTransformPptx() {
  yield takeLatest(BetterPptxActionTypes.BETTER_PPTX_TRANSFORM_PPTX, handleTransformPptx);
}

function* watchFinalisePptx() {
  yield takeLatest(BetterPptxActionTypes.BETTER_PPTX_FINALISE, handleFinalisePptx);
}

export const betterPPtxSagas = [
  fork(watchSaveLessons),
  fork(watchSaveSlides),
  fork(watchCreateCourse),
  fork(watchTransformPptx),
  fork(watchFinalisePptx),
  fork(watchDeleteSlide),
  fork(watchChangeSlide),
  fork(watchReorderSlide),
  fork(watchDeleteCourse)
];
