import type * as React from 'react';
import styled from 'styled-components';
import type { LabelProps } from './Label';
import Label from './Label';

const LabelSmall: React.FunctionComponent<LabelProps> = styled(Label)<LabelProps>`
  display: block;
  font-size: 0.8em;
  margin-bottom: 0;
  padding: 4px 6px 4px 0;
`;

export default LabelSmall;
