import * as React from 'react';
import styled from 'styled-components';
import { CrossIcon } from '../../../icons';
import type { MultiValueProps } from 'react-select/src/components/MultiValue';
import type { Option } from '../types';

function MultiValueRemoveComponent<OptionValueType>({
  innerProps,
  data,
  ...rest
}: MultiValueProps<Option<OptionValueType>>) {
  return <CrossIcon size="xs" {...innerProps} {...rest} data-testid="pill-clear-button" />;
}

export const MultiValueRemove = styled(MultiValueRemoveComponent)`
  margin-left: ${({ theme }) => theme.sizes.pillMargin}px;
  padding: 0;
  cursor: pointer;
`;
