import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ApplicationInfoSelectors, useUserInfo } from '@edapp/user-info';
import { Banner, icons } from '@edapp/sc-web-ui';

const HideBannerRoutes = ['/practical-assessments'];

const MobileBanner: React.FC = () => {
  const { t } = useTranslation('translation');
  const [showBanner, setShowBanner] = React.useState<boolean>(true);
  const { applicationInfo } = useUserInfo();
  const isUxp = ApplicationInfoSelectors.isUXP(applicationInfo);
  const isHideBannerRoute: boolean = React.useMemo(
    () =>
      HideBannerRoutes.some(route => {
        return window.location.pathname === route;
      }),
    []
  );

  const closeBanner = () => {
    setShowBanner(false);
  };

  React.useEffect(() => {
    if (isHideBannerRoute) {
      setShowBanner(false);
    }
  }, [isHideBannerRoute]);

  if (isUxp) {
    return null; // UXP has their own internal banner
  }
  return showBanner ? (
    <StyledBanner
      icon={<icons.CircleInfo />}
      status="information"
      closeProps={{ onClick: closeBanner }}
    >
      {t('mobile-banner.text')}
    </StyledBanner>
  ) : null;
};

const StyledBanner = styled(Banner)`
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.blue};
`;
export default MobileBanner;
