import * as React from 'react';
import { Label } from '../input';
import { Box } from '../../box';
import { Typography } from '../../typography';
import type { LabelledInputProps } from './types';

/**
 * Wrapper component to add labels, helper text and error messages.
 * Intended for wrapping basic input components
 */
export const LabelledInput: React.FC<React.PropsWithChildren<LabelledInputProps>> = ({
  name,
  label,
  error,
  helperText,
  spacing,
  isFullWidth,
  className,
  children
}) => {
  return (
    <Box
      flex={true}
      flexDirection="column"
      flexBasis={isFullWidth ? '100%' : undefined}
      {...spacing}
      className={className}
    >
      {label && <Label htmlFor={name}>{label}</Label>}

      {children}

      {!error && helperText && (
        <Typography variant="subtext" color="textMuted" mt={0.5} testId={`${name}-helper-text`}>
          {helperText}
        </Typography>
      )}

      {error && typeof error !== 'boolean' && (
        <Typography variant="subtext" color="red" mt={0.5} testId={`${name}-input-error`}>
          {error}
        </Typography>
      )}
    </Box>
  );
};
