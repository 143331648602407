import * as React from 'react';
import type { TabItems as TabItemsType, Props, TabKeys, Tab as TabType, TabsType } from './types';
import { TabList, TabIndicator, Tab } from './styled';
import { Carousel, useCarousel } from '../carousel';
import { useResizeObserver } from '../../../hooks';
import { Typography } from '../typography/Typography';

export const TabItems: React.FC<TabItemsType> = ({
  tabs,
  onClickTab,
  isCentered,
  hasPadding = false,
  hasBorderBottom = false,
  activeStyle
}) => {
  const { bounds, ref: tabListRef } = useResizeObserver<HTMLDivElement>();
  const tabRefs = React.useRef<Map<number, HTMLButtonElement | null>>(new Map());
  const [indicatorStyle, setIndicatorStyle] = React.useState<{ left: number; right: number }>({
    left: 0,
    right: 0
  });

  const { currentStep: currentTab, goToStep: goToTab } = useCarousel();

  const handleSetIndicatorStyle = (containerRect: DOMRect, tabRect: DOMRect) => {
    setIndicatorStyle({
      left: tabRect.left - containerRect.left,
      right: containerRect.right - tabRect.right
    });
  };

  React.useEffect(() => {
    const container = tabListRef.current;
    const tab = tabRefs.current.get(currentTab - 1);

    if (!container || !tab) {
      return;
    }

    handleSetIndicatorStyle(container.getBoundingClientRect(), tab.getBoundingClientRect());
  }, [currentTab, tabListRef, bounds]);

  const handleClickTab = (index: number) => {
    onClickTab?.(index);
    goToTab(index + 1);
    tabs[index].onClick?.();
  };

  return (
    <TabList
      ref={tabListRef}
      isCentered={!!isCentered}
      hasPadding={hasPadding}
      hasBorderBottom={hasBorderBottom}
      data-testid="tab-list"
    >
      <TabIndicator
        layout="position"
        initial={false}
        style={indicatorStyle}
        activeStyle={activeStyle}
      />
      {tabs.map(({ title, dataTestId }, index) => (
        <Tab
          type="button"
          key={title}
          ref={element => tabRefs.current.set(index, element)}
          onClick={() => handleClickTab(index)}
          data-testid={dataTestId}
        >
          <Typography color={currentTab - 1 === index ? 'blue' : 'text'}>{title}</Typography>
        </Tab>
      ))}
    </TabList>
  );
};

function reduceTabs<Tabs extends TabKeys>(tabs: TabsType<Tabs>): [string[], TabType[]] {
  return Object.entries<TabType | undefined>(tabs).reduce<[string[], TabType[]]>(
    (acc, [key, value]) =>
      !!value
        ? [
            [...acc[0], key],
            [...acc[1], value]
          ]
        : acc,
    [[], []]
  );
}

export function Tabs<Tabs extends TabKeys>({
  tabs,
  activeTab,
  overflow,
  onClickTab,
  isCentered,
  hasPadding,
  hasBorderBottom,
  activeStyle = 'bottom',
  ...rest
}: Props<Tabs>) {
  const [keys, values] = reduceTabs<Tabs>(tabs);
  const currentStep = activeTab !== undefined ? keys.indexOf(`${String(activeTab)}`) + 1 : 1;

  return (
    <Carousel
      currentStep={currentStep}
      header={
        <TabItems
          tabs={values}
          onClickTab={onClickTab}
          isCentered={isCentered}
          hasPadding={hasPadding}
          hasBorderBottom={hasBorderBottom}
          activeStyle={activeStyle}
        />
      }
      showDots={false}
      overflow={overflow}
      {...rest}
    >
      {values.map<React.ReactNode>(({ title, content }, index) => (
        <React.Fragment key={`tabs-${title}-${index}`}>{content}</React.Fragment>
      ))}
    </Carousel>
  );
}
