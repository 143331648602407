import * as React from 'react';

import { AnimatePresence } from 'framer-motion';
import * as ReactDOM from 'react-dom';
import { Manager, Popper, Reference } from 'react-popper';
import styled from 'styled-components';

import { canUseDOM } from '@edapp/utils';

import { mergeRefs } from '../../utils';
import { Arrow, PlacementContainer, TooltipContent } from './styled';
import type { Props } from './types';

export const TOOLTIP_TEST_ID = 'tooltip';

export const TooltipComponent = React.forwardRef<HTMLDivElement, Props>(
  (
    {
      testId = TOOLTIP_TEST_ID,
      placement = 'top',
      usePortal = false,
      size = 'sm',
      bgColor = 'navyHover',
      showArrow = true,
      delay = 0,
      closeDelay = 0,
      children: child,
      dropShadow,
      content,
      isOpen,
      maxWidth,
      className,
      anchorRef,
      ...props
    },
    ref
  ) => {
    const [isOpenState, setIsOpenState] = React.useState(isOpen ?? false);

    const openTimeoutRef = React.useRef<NodeJS.Timeout>();
    const closeTimeoutRef = React.useRef<NodeJS.Timeout>();

    const handleChangeOpenState = React.useCallback(() => {
      clearTimeout(openTimeoutRef.current);
      clearTimeout(closeTimeoutRef.current);

      openTimeoutRef.current = setTimeout(() => {
        setIsOpenState(true);
      }, delay);
    }, [delay]);

    const handleChangeCloseState = React.useCallback(
      (override?: boolean) => {
        clearTimeout(openTimeoutRef.current);
        clearTimeout(closeTimeoutRef.current);

        const onCloseDelay = override ? 0 : closeDelay || delay;
        closeTimeoutRef.current = setTimeout(() => {
          setIsOpenState(false);
        }, onCloseDelay);
      },
      [delay, closeDelay]
    );

    React.useEffect(() => {
      if (isOpen) {
        handleChangeOpenState();
      } else {
        // If isOpen is explicitly false we don't want to delay closing
        handleChangeCloseState(isOpen === false);
      }
    }, [isOpen]);

    const tooltipEventHandlers = React.useMemo(
      () =>
        isOpen == null
          ? {
              onTouchStart: (event: React.TouchEvent) => {
                child.props.onTouchStart?.(event);
                handleChangeOpenState();
              },
              onTouchEnd: (event: React.TouchEvent) => {
                child.props.onTouchEnd?.(event);
                handleChangeCloseState();
              },
              onMouseEnter: (event: React.MouseEvent) => {
                child.props.onMouseEnter?.(event);
                handleChangeOpenState();
              },
              // onMouseOver is no longer work for disabled elements on newer versions of Chrome & Safari
              onMouseOver: (event: React.MouseEvent) => {
                child.props.onMouseOver?.(event);
                handleChangeOpenState();
              },
              onMouseLeave: (event: React.MouseEvent) => {
                child.props.onMouseLeave?.(event);
                handleChangeCloseState();
              },
              // Workaround - onMouseLeave is not triggered on disabled element
              onPointerLeave: (event: React.MouseEvent) => {
                child.props.onMouseLeave?.(event);
                handleChangeCloseState();
              },
              onFocus: (event: React.FocusEvent) => {
                child.props.onFocus?.(event);
                handleChangeOpenState();
              },
              onBlur: (event: React.FocusEvent) => {
                child.props.onBlur?.(event);
                handleChangeCloseState();
              },
              onMouseOut: (event: React.MouseEvent) => {
                child.props.onBlur?.(event);
                handleChangeCloseState();
              }
            }
          : {},
      [child, isOpen]
    );

    if (!canUseDOM()) {
      return null;
    }

    const createTooltip = ({
      testId = TOOLTIP_TEST_ID,
      ref: externalRef,
      placement,
      content
    }: Partial<Props> & { ref: React.Ref<HTMLDivElement> }) => (
      <AnimatePresence>
        {isOpenState && (
          <Popper placement={placement}>
            {({ ref, style, arrowProps, placement: actualPlacement }) => (
              <PlacementContainer
                ref={mergeRefs([ref, externalRef])}
                size={size}
                bgColor={bgColor}
                dropShadow={dropShadow}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                style={style}
                data-placement={actualPlacement}
                showArrow={showArrow}
                maxWidth={maxWidth}
              >
                <Arrow ref={arrowProps.ref} style={arrowProps.style} />
                <TooltipContent
                  className={className}
                  size={size}
                  bgColor={bgColor}
                  data-testid={testId}
                  color={props.color || 'fixedWhite'}
                  {...tooltipEventHandlers}
                >
                  {content}
                </TooltipContent>
              </PlacementContainer>
            )}
          </Popper>
        )}
      </AnimatePresence>
    );

    const tooltip = createTooltip({ ref, placement, testId, content });

    return (
      <Manager>
        <Reference>
          {({ ref }) =>
            React.cloneElement(child, {
              ref: mergeRefs([ref, anchorRef]),
              ...tooltipEventHandlers
            })
          }
        </Reference>
        {usePortal ? ReactDOM.createPortal(tooltip, document.body) : tooltip}
      </Manager>
    );
  }
);

export const Tooltip = styled(TooltipComponent)<Props>``;
