import { createAction } from '@edapp/utils';
import type { SurveysFilterState } from './types';

export enum SurveysActionTypes {
  FETCH_SURVEYS = 'FETCH_SURVEYS',
  FETCH_SURVEYS_SUCCESS = 'FETCH_SURVEYS_SUCCESS',
  FETCH_SURVEYS_FAILURE = 'FETCH_SURVEYS_FAILURE',
  SET_SURVEYS_FILTER = 'SET_SURVEYS_FILTER'
}

export type FetchSurveys = { page?: number };

export const SurveysActions = {
  fetchSurveys: (payload?: FetchSurveys) => createAction(SurveysActionTypes.FETCH_SURVEYS, payload),

  setSurveysFilter: (payload: SurveysFilterState) =>
    createAction(SurveysActionTypes.SET_SURVEYS_FILTER, payload)
};
