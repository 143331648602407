import { useHippoQuery } from '@edapp/request';
import type { UseHippoQueryOptions } from '@edapp/request';

import { getStarbars } from './api';
import { QueryKeys } from './constants';
import type { StarBarsResponse } from './types';

export function useGetStarbars(options?: UseHippoQueryOptions<StarBarsResponse>) {
  return useHippoQuery(
    [QueryKeys.starbars],
    (apiUrl, token) => () => getStarbars(apiUrl, token),
    options
  );
}
