import * as React from 'react';
import styled from 'styled-components';
import { pluralize } from 'humanize-plus';
import { Typography } from '@edapp/ed-components';

type ItemCountProps = {
  totalCourses: number;
  fixture?: string;
};

export const ItemCount: React.FC<ItemCountProps> = ({ totalCourses, fixture = 'course' }) => (
  <StyledText color="textMuted" as="p">{`${totalCourses} ${pluralize(
    totalCourses,
    fixture
  )}`}</StyledText>
);

type ItemIndexProps = {
  totalCourses: number;
  totalCoursesInCategory: number;
  fixture?: string;
};

export const ItemIndex: React.FC<ItemIndexProps> = ({
  totalCourses,
  totalCoursesInCategory,
  fixture = 'course'
}) => (
  <StyledText color="textMuted" as="p">
    {`${totalCourses} of ${totalCoursesInCategory} ${pluralize(totalCoursesInCategory, fixture)}`}
  </StyledText>
);

export const StyledText = styled(Typography)`
  display: inline-block;
  padding-left: ${({ theme }) => `${theme.space()}px`};
`;
