import { fork, put, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import type { ActionFromActionType } from '@edapp/utils';

import {
  ConfigActionTypes,
  fetchLatestInvoiceUri,
  fetchUserTokenUri,
  retryPayInvoiceUri
} from './constants';
import type { ConfigActionsUnionType } from './types';

type ConfigAction<ActionType extends string> = ActionFromActionType<
  ConfigActionsUnionType,
  ActionType
>;

function* handleFetchUserToken() {
  yield put(
    RequestActions.getUnauthed(
      fetchUserTokenUri(),
      ConfigActionTypes.FETCH_USER_TOKEN_FULFILLED,
      ConfigActionTypes.FETCH_USER_TOKEN_FAILED,
      true
    )
  );
}

function* watchFetchUserToken() {
  yield takeLatest(ConfigActionTypes.FETCH_USER_TOKEN, handleFetchUserToken);
}

function* handleFetchLatestInvoice() {
  yield put(
    RequestActions.getAuthed(
      fetchLatestInvoiceUri(),
      ConfigActionTypes.FETCH_LATEST_INVOICE_FULFILLED,
      ConfigActionTypes.FETCH_LATEST_INVOICE_FAILED,
      false
    )
  );
}

function* watchFetchLatestInvoice() {
  yield takeLatest(ConfigActionTypes.FETCH_LATEST_INVOICE, handleFetchLatestInvoice);
}

function* handleRetryPayInvoice(action: ConfigAction<ConfigActionTypes.RETRY_PAY_INVOICE>) {
  yield put(
    RequestActions.postAuthed(
      retryPayInvoiceUri(action.payload.id),
      ConfigActionTypes.RETRY_PAY_INVOICE_FULFILLED,
      ConfigActionTypes.RETRY_PAY_INVOICE_FAILED,
      false
    )
  );
}

function* watchRetryPayInvoice() {
  yield takeLatest(ConfigActionTypes.RETRY_PAY_INVOICE, handleRetryPayInvoice);
}

const sagas = [
  fork(watchFetchUserToken),
  fork(watchFetchLatestInvoice),
  fork(watchRetryPayInvoice)
];

export default sagas;
