import type {
  ApplicationContextResponse,
  ApplicationResponse,
  BillingPlanDetailsType} from '@edapp/hippo-client';
import {
  getApplicationAndContext,
  getBillingDetails
} from '@edapp/hippo-client';
import { RequestUtils } from '@edapp/request';
import type { UserInfo, UserResponse } from './types';

export function getUser(hippoUrl: string, userToken: string): Promise<UserResponse> {
  return RequestUtils.httpFetch<UserResponse>('GET', `${hippoUrl}/api/users/me`, userToken);
}

export type GetUserInfo = (hippoUrl: string, userToken: string) => Promise<UserInfo>;

export const getUserInfo: GetUserInfo = async (hippoUrl, userToken) => {
  const [user, application, billing] = await Promise.all<
    [
      Promise<UserResponse>,
      Promise<ApplicationResponse & ApplicationContextResponse>,
      Promise<BillingPlanDetailsType>
    ]
  >([
    getUser(hippoUrl, userToken),
    getApplicationAndContext(hippoUrl, userToken),
    getBillingDetails(hippoUrl, userToken)
  ]);

  return {
    user,
    application,
    billing
  };
};
