import { Roles } from './constants';
import type { RoleId, UserResponse } from './types';

export const isAdmin = (roles: RoleId[]) =>
  roles.find(
    role =>
      role === 'account-admin' ||
      role === 'account-owner' ||
      role === 'ed-admin' ||
      role === 'safety-culture-admin'
  );

export const hasContentAuthorRole = (roles: RoleId[]) =>
  roles.find(role => role === 'content-author');

export const hasRestrictedAuthorRole = (roles: RoleId[]) =>
  roles.find(role => role === 'restricted-author');

export const hasReviewerRole = (roles: RoleId[]) => roles.includes('reviewer');

/**
 * Restricted authors have limited access to content editing
 * However if they also have an admin or content author role, they should have full access
 */
export const isRestrictedAuthor = (roles?: RoleId[]) => {
  if (!roles) {
    return false;
  }
  const hasRestrictedAuthor = hasRestrictedAuthorRole(roles);
  const isAdminUser = isAdmin(roles);
  const hasContentAuthor = hasContentAuthorRole(roles);

  return hasRestrictedAuthor && !isAdminUser && !hasContentAuthor;
};

export const isStrictlyReviewer = (user?: UserResponse): boolean => {
  if (!user) {
    return false;
  }
  const isReviewer = hasReviewerRole(user.userRoles);
  const isAdminUser = isAdmin(user.userRoles);
  const isContentAuthor = hasContentAuthorRole(user.userRoles);

  return !isAdminUser && !isContentAuthor && isReviewer;
};

export const hasManageTeamRole = (roles?: RoleId[]) =>
  roles ? roles.includes(Roles['ManageTeams']) : false;

export const hasEditContentRole = (roles?: RoleId[]) =>
  roles
    ? [Roles['Admin'], Roles['ContentAuthor'], Roles['RestrictedAuthor']].some(role =>
        roles.includes(role)
      )
    : false;
