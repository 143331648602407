import * as React from 'react';

import { connect } from 'react-redux';
import { Col, Row } from 'react-styled-flexboxgrid';

import { Box, Divider } from '@edapp/ed-components';
import { isSafetyCultureAdmin } from '@rio/store/config/selectors';
import type { LmsStoreState } from '@rio/store/types';

import { Contains } from './Contains';
import { Courses } from './Courses';
import { CustomFieldRules } from './CustomFieldRules';
import { Draws } from './Draws';
import Freeze from './Freeze';
import { Groups } from './Groups';
import { Lottery } from './Lottery';
import { Managers } from './Managers';
import Registration from './Registration';
import { SaveUserGroup } from './SaveUserGroup';
import { Users } from './Users';
import { Catalogs } from './catalogs';
import { UserGroupPushNotification } from './push-notification';

type OwnProps = { groupId: string };
type Props = OwnProps & ReturnType<typeof mapStateToProps>;

class UserGroupItemContent extends React.Component<Props> {
  render() {
    return (
      <Row className="user-group-item-content-container">
        <Col xs={9}>
          <Box py={2}>
            {!this.props.isAccountGroup && (
              <>
                <Contains groupId={this.props.groupId} />

                <Divider style={{ marginTop: 20, marginBottom: 20 }} />
              </>
            )}

            {this.props.userGroupContains === 'users' && !this.props.isDynamicGroup && <Users />}
            {this.props.userGroupContains === 'groups' && !this.props.isDynamicGroup && (
              <Groups groupId={this.props.groupId} />
            )}
            {this.props.userGroupContains === 'users' && !!this.props.isDynamicGroup && (
              <CustomFieldRules />
            )}

            <Divider style={{ marginTop: 20, marginBottom: 20 }} />

            <Managers />

            <Divider style={{ marginTop: 20, marginBottom: 20 }} />

            <Courses />

            <Divider style={{ marginTop: 20, marginBottom: 20 }} />

            <Draws />

            <Divider style={{ marginTop: 20, marginBottom: 20 }} />

            {!this.props.isAccountGroup &&
              !this.props.isDynamicGroup &&
              this.props.userGroupContains === 'users' && (
                <>
                  <Registration />
                  <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                </>
              )}

            <Lottery />

            {this.props.isEdAdmin && (
              <>
                <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                {!this.props.isSafetyCultureAdmin && <Catalogs />}

                <Divider style={{ marginTop: 20, marginBottom: 20 }} />

                <Freeze />
              </>
            )}
          </Box>
        </Col>
        <Col xs={3}>
          <Box py={3}>
            <SaveUserGroup groupId={this.props.groupId} />
            <UserGroupPushNotification userGroupId={this.props.groupId} />
          </Box>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state: LmsStoreState) => ({
  isAccountGroup: state.userGroups.userGroupItem.usergroup.isAccountGroup,
  isDynamicGroup: state.userGroups.userGroupItem.usergroup.isDynamicGroup,
  userGroupContains: state.userGroups.userGroupItem.usergroup.userGroupContains,
  isEdAdmin: state.config.isUserAdmin,
  isSafetyCultureAdmin: isSafetyCultureAdmin(state)
});

export default connect(mapStateToProps)(UserGroupItemContent);
