export * from './color';
export * from './typography';
export * from './space';

export const cssToJS = (css: string) =>
  css.split(';').reduce(function (ruleMap, ruleString) {
    const rulePair = ruleString.replace('\n', '').trim().split(':');
    if (!rulePair[0]) return ruleMap;

    ruleMap[rulePair[0].replace(/-./g, x => x[1].toUpperCase()).trim()] = rulePair[1].trim();
    return ruleMap;
  }, {});
