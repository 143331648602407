import * as React from 'react';

import { format, isAfter } from 'date-fns';
import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { Box, CalendarIcon, Typography } from '@edapp/ed-components';
import { Countdown } from '@rio/components/account-plan-details/limits/shared';
import { getPlanEndDate } from '@rio/store/config/selectors';

const NavProTrialTimeRemaining: React.FC = () => {
  const planEndDateString = useSelector(getPlanEndDate);

  const renderFn = React.useCallback((daysRemaining: string) => {
    return (
      <Typography as="span" variant="subtext" color="text">
        <strong>{daysRemaining}</strong> left on your trial
      </Typography>
    );
  }, []);

  if (!planEndDateString) {
    return (
      <Background>
        <Typography as="span" variant="subtext" color="white">
          EdApp Pro free trial
        </Typography>
      </Background>
    );
  }

  const planEndDate = new Date(planEndDateString);

  if (isAfter(new Date(), planEndDate)) {
    return (
      <Background>
        <Typography as="span" variant="subtext" color="text">
          Your free trial ended on <strong>{format(planEndDate, 'PP')}</strong>
        </Typography>
      </Background>
    );
  }

  return (
    <Background flex alignItems="center" py={0.5} px={1} gap={0.75}>
      <CalendarIcon size="xs" />
      <Countdown date={planEndDateString}>{renderFn}</Countdown>
    </Background>
  );
};

const Background = styled(Box)(
  ({ theme }) => css`
    background: ${theme.colors.lightGrey};
    border-radius: ${theme.sizes.borderRadius * 1.5}px;
    user-select: none;
    > * {
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
    }
  `
);

export default NavProTrialTimeRemaining;
