import type { StoreState } from './types';

export const getLesson = (state: StoreState) => state.ringo.lesson;
export const getLessonConfig = (state: StoreState) =>
  state.ringo.lesson?.configuration.styleConfiguration;
export const getLessonId = (state: StoreState) => getLesson(state)?.id;
export const getLessonFeatures = (state: StoreState) => state.ringo.lesson?.features;
export const getLessonStarsEnabled = (state: StoreState) => {
  const lessonFeatures = getLessonFeatures(state);
  return !!lessonFeatures?.starOptions?.enabled || true; // true is default in emily lesson model
};
export const getLessonLatestStateIdentifier = (state: StoreState) =>
  state.ringo.lesson?.latestSavedSlidesStateIdentifier ?? '1';
