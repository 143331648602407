import type { CourseType } from '@edapp/authoring-logic';
import { RequestUtils } from '@edapp/request';

import { DEFAULT_LANGUAGE_CODE } from './constants';
import type {
  CourseRequest,
  CourseResponse,
  DeleteCourseRequest,
  GetCoursesToImportResponse,
  GetMiniCourse,
  MiniCourseRequest,
  MiniCourseResponse,
  PrerequisitesRequest,
  PrerequisitesResponse,
  PublishCourseRequest,
  PublishCourseResponse,
  RepublishCourseRequest,
  UnpublishCourseResponse
} from './types';

export async function getCourse(
  hippoUrl: string,
  userToken: string,
  language: string,
  courseId: string
): Promise<CourseResponse> {
  return await RequestUtils.httpFetch<CourseResponse, CourseRequest>(
    'GET',
    `${hippoUrl}/api/courses/${courseId}`,
    userToken,
    language === DEFAULT_LANGUAGE_CODE
      ? undefined
      : {
          language
        }
  );
}

export function getCoursePrerequisites(
  hippoUrl: string,
  userToken: string,
  payload: PrerequisitesRequest
): Promise<PrerequisitesResponse> {
  return RequestUtils.httpFetch<PrerequisitesResponse, PrerequisitesRequest>(
    'GET',
    `${hippoUrl}/api/courses/prerequisites`,
    userToken,
    payload
  );
}

export function publishCourse(
  hippoUrl: string,
  userToken: string,
  courseId: string,
  payload: PublishCourseRequest
): Promise<PublishCourseResponse> {
  return RequestUtils.httpFetch<PublishCourseResponse, PublishCourseRequest>(
    'POST',
    `${hippoUrl}/api/courses/${courseId}/publish`,
    userToken,
    payload
  );
}

export function republishCourse(
  hippoUrl: string,
  userToken: string,
  courseId: string,
  payload: RepublishCourseRequest
): Promise<void> {
  return RequestUtils.httpFetch<void, RepublishCourseRequest>(
    'POST',
    `${hippoUrl}/api/courses/${courseId}/republish`,
    userToken,
    payload
  );
}

export function unpublishCourse(
  hippoUrl: string,
  userToken: string,
  courseId: string
): Promise<UnpublishCourseResponse> {
  return RequestUtils.httpFetch<UnpublishCourseResponse, {}>(
    'POST',
    `${hippoUrl}/api/courses/${courseId}/unpublish`,
    userToken,
    {}
  );
}

export const getMiniCourse: GetMiniCourse = (hippoUrl, userToken, request) =>
  RequestUtils.httpFetch<MiniCourseResponse, MiniCourseRequest>(
    'GET',
    `${hippoUrl}/api/courses/mini`,
    userToken,
    request,
    undefined,
    undefined,
    undefined,
    undefined,
    true
  );

export function getCoursesToImport(
  hippoUrl: string,
  userToken: string
): Promise<GetCoursesToImportResponse> {
  return RequestUtils.httpFetch<GetCoursesToImportResponse>(
    'GET',
    `${hippoUrl}/api/courses/contentNavigation`,
    userToken
  );
}

export function updateCourse(
  hippoUrl: string,
  userToken: string,
  courseId: string,
  payload: CourseType
): Promise<CourseType> {
  return RequestUtils.httpFetch<CourseType, CourseType>(
    'PUT',
    `${hippoUrl}/api/courses/${courseId}`,
    userToken,
    payload
  );
}

export function patchCourse(
  hippoUrl: string,
  userToken: string,
  courseId: string,
  payload: Partial<CourseType>
): Promise<Partial<CourseType>> {
  return RequestUtils.httpFetch<Partial<CourseType>, Partial<CourseType>>(
    'PATCH',
    `${hippoUrl}/api/courses/${courseId}`,
    userToken,
    payload
  );
}

export const deleteCourse = (
  hippoUrl: string,
  userToken: string,
  payload: DeleteCourseRequest
): Promise<void> => {
  return RequestUtils.httpFetch(
    'DELETE',
    `${hippoUrl}/api/courses/${payload.courseId}`,
    userToken,
    payload
  );
};

export const duplicateCourse = (
  hippoUrl: string,
  userToken: string,
  courseId: string,
  collectionId?: string
): Promise<string> => {
  return RequestUtils.httpFetch('POST', `${hippoUrl}/api/courses/copy`, userToken, {
    courseId,
    collectionId
  });
};

export const emailDesktopLink = (
  hippoUrl: string,
  userToken: string,
  courseId: string
): Promise<void> => {
  return RequestUtils.httpFetch(
    'POST',
    `${hippoUrl}/api/courses/${courseId}/edit-on-desktop-link`,
    userToken
  );
};
