import * as actions from './actions';
import isEmpty from 'lodash-es/isEmpty';
import type { OnboardingState } from './types';

const initialOnboardingState: OnboardingState = {
  checklist: {
    isDismissed: false,
    items: [],
    total: 0,
    percent: 0,
    complete: 0,
    incomplete: 0
  }
};

const onboardingReducer: Reducer<OnboardingState> = (state = initialOnboardingState, action) => {
  switch (action.type) {
    case actions.FETCH_ONBOARDING_CHECKLIST:
    case actions.DISMISS_ONBOARDING_CHECKLIST: {
      state.checklist = {
        ...state.checklist,
        loading: true
      };

      return state;
    }
    case actions.FETCH_ONBOARDING_CHECKLIST_SUCCESS: {
      if (!isEmpty(action.payload)) {
        const payload = action.payload as actions.FetchOnboardingChecklistSuccess;
        const items = payload.items || [];
        const total = items.length;
        const complete = items.filter(({ completed }) => completed).length;
        const percent = total ? complete / total : total;

        state.checklist = {
          loading: false,
          total,
          complete,
          percent,
          incomplete: total - complete,
          ...payload
        };
      } else {
        // Recognize no content as dismissed since existing users doesn't have checklist
        state.checklist = {
          ...state.checklist,
          isDismissed: true
        };
      }

      return state;
    }
    case actions.FETCH_ONBOARDING_CHECKLIST_FAILURE:
    case actions.DISMISS_ONBOARDING_CHECKLIST_FAILURE: {
      const errorResponse = action.payload as actions.FetchOnboardingChecklistFailure;

      state.checklist = {
        ...state.checklist,
        loading: false,
        error: errorResponse.message || errorResponse.code
      };

      return state;
    }
    case actions.DISMISS_ONBOARDING_CHECKLIST_SUCCESS: {
      state.checklist = {
        ...state.checklist,
        loading: false,
        isDismissed: true
      };

      return state;
    }

    default:
      return state;
  }
};

export { initialOnboardingState, onboardingReducer };
