import * as React from 'react';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import type { ButtonVariant } from '@edapp/ed-components';
import { IconButton, InfoIcon, Input, TextArea, Tooltip, Typography } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';

import LabelSmall from '../Label/LabelSmall';
import LabelSmallWithBorder from '../Label/LabelSmallWithBorder';
import { FormGroup } from '../form-group/FormGroup';
import PushNotificationDialog from './PushNotificationDialog';
import { DELAY_IN_MS, SEND_PUSH_NOTIFICATION_BUTTON_TEXT } from './helpers';
import reducer from './reducer';
import type { Payload, PushNotificationProps, PushNotificationState } from './types';

const initialState: PushNotificationState = {
  body: '',
  dialogOpen: false,
  notificationSent: false,
  sendPushNotificationButtonText: SEND_PUSH_NOTIFICATION_BUTTON_TEXT,
  sendPushNotificationButtonVariant: 'light' as ButtonVariant,
  title: '',
  showTitleValidationError: false,
  showBodyValidationError: false
};

function PushNotification({
  actionsRequestUri,
  bodyFieldPlaceholder,
  className,
  dialogBodyContent,
  disabled,
  formFieldDefaults,
  payload,
  reportType,
  titleFieldPlaceholder,
  description,
  heading
}: PushNotificationProps) {
  const { t } = useTranslation();
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const isDisabled = !!disabled;
  const error = typeof disabled === 'string' ? disabled : undefined;

  const { title, body } = state;

  const pushNotificationPayload: Payload = {
    body,
    title,
    ...payload
  };

  const onCloseDialog = () => dispatch({ type: 'CLOSE_DIALOG' });

  const onOpenDialog = () => dispatch({ type: 'OPEN_DIALOG' });

  const onChangeTitle = (value: string) =>
    dispatch({
      type: 'SET_TITLE',
      title: value
    });

  const onChangeBody = (value: string) =>
    dispatch({
      type: 'SET_BODY',
      body: value
    });

  const onBlurTitle = () => dispatch({ type: 'BLUR_TITLE' });

  const onBlurBody = () => dispatch({ type: 'BLUR_BODY' });

  const onNotificationSent = () => dispatch({ type: 'NOTIFICATION_SENT' });

  const resetNotificationButtonState = () => dispatch({ type: 'RESET_NOTIFICATION_BUTTON_SENT' });

  const resetNotificationButtonStateWithDelay = (delay: number = DELAY_IN_MS) => {
    return setTimeout(() => {
      resetNotificationButtonState();
    }, delay);
  };

  React.useEffect(() => {
    if (!formFieldDefaults) {
      return;
    }

    if (formFieldDefaults.title) {
      onChangeTitle(formFieldDefaults.title);
    }

    if (formFieldDefaults.body) {
      onChangeBody(formFieldDefaults.body);
    }
  }, [formFieldDefaults]);

  React.useEffect(() => {
    const timeout = resetNotificationButtonStateWithDelay();
    return () => {
      clearTimeout(timeout);
    };
  }, [state.notificationSent]);

  React.useEffect(() => {
    resetNotificationButtonState();
  }, [reportType]);

  return (
    <div className={className}>
      <Text component="h4" variant="titleMedium">
        {heading || t('analytics.actionable-reports.push-notification.title')}
      </Text>
      <Typography as="p" color="textMuted">
        {description || t('analytics.actionable-reports.push-notification.description')}
      </Typography>

      <FormGroup>
        <LabelSmallWithBorder text={t('common.title')} />
        <Input
          onChange={e => onChangeTitle(e.currentTarget.value)}
          placeholder={titleFieldPlaceholder}
          value={state.title}
          onBlur={onBlurTitle}
          error={!state.title && state.showTitleValidationError}
          disabled={isDisabled}
          hasMargin={false}
          isFullWidth={true}
        />
        {!state.title && state.showTitleValidationError && (
          <ValidationError>
            <LabelSmall text={t('analytics.actionable-reports.push-notification.empty-warning')} />
          </ValidationError>
        )}
      </FormGroup>

      <FormGroup>
        <LabelSmallWithBorder text={t('analytics.actionable-reports.push-notification.body')} />
        <TextArea
          onChange={e => onChangeBody(e.currentTarget.value)}
          placeholder={bodyFieldPlaceholder}
          value={state.body}
          onBlur={onBlurBody}
          error={!state.body && state.showBodyValidationError}
          disabled={isDisabled}
          hasMargin={false}
          isFullWidth={true}
          fitContents
        />
        {!state.body && state.showBodyValidationError && (
          <ValidationError>
            <LabelSmall text={t('analytics.actionable-reports.push-notification.empty-warning')} />
          </ValidationError>
        )}
      </FormGroup>

      <Tooltip isOpen={!error ? false : undefined} content={error} placement="bottom">
        <IconButton
          icon={!!error ? InfoIcon : undefined}
          disabled={isDisabled || state.notificationSent || !state.body || !state.title}
          isFullWidth={true}
          onClick={onOpenDialog}
          variant={state.sendPushNotificationButtonVariant}
        >
          {state.sendPushNotificationButtonText}
        </IconButton>
      </Tooltip>

      <PushNotificationDialog
        actionsRequestUri={actionsRequestUri}
        dialogBodyContent={dialogBodyContent}
        isDialogOpen={state.dialogOpen}
        onClose={onCloseDialog}
        onNotificationSent={onNotificationSent}
        payload={pushNotificationPayload}
      />
    </div>
  );
}

const ValidationError = styled.div`
  padding-left: 5px;
  padding-top: 10px;
  color: ${({ theme }) => theme.colors.red};
`;

export default PushNotification;
