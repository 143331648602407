import * as React from 'react';

import { useSelector } from 'react-redux';

import {
  Box,
  ButtonLink,
  CogIcon,
  IconButtonLink,
  useBreakpointMatchMedia
} from '@edapp/ed-components';
import { AdminAccountLookup, ProfileDropdown } from '@rio/components/layout/navigation';
import NavGetStartedProgress from '@rio/components/layout/navigation/NavGetStartedProgress';
import NavProTrialTimeRemaining from '@rio/components/layout/navigation/nav-billing-limits/NavProTrialTimeRemaining';
import { NavNotifications } from '@rio/components/layout/navigation/notifications';
import {
  getIsOnEnterprisePlan,
  getIsOnFreePlan,
  getIsOnFreemiumPlan,
  getIsOnTrialPlan,
  getUserRoles,
  hasBillingPermission,
  isUXPEnabled,
  isSuperAdmin as superAdminSelector
} from '@rio/store/config/selectors';
import { BillingPermission, useCheckPermission } from '@rio/utils/permissions/constants';

export const Navigation: React.FC = () => {
  const isSuperAdmin = useSelector(superAdminSelector);
  const isEnterprise = useSelector(getIsOnEnterprisePlan);
  const isFreemium = useSelector(getIsOnFreemiumPlan);
  const isOnFreePlan = useSelector(getIsOnFreePlan);
  const isOnTrial = useSelector(getIsOnTrialPlan);
  const hasGetStartedChecklistPermission = useSelector(
    hasBillingPermission(BillingPermission.ACCESS_GET_STARTED_CHECKLIST)
  );
  const userRoles = useSelector(getUserRoles);
  const isMobile = useBreakpointMatchMedia('md', 'max-width');
  const isUXP = useSelector(isUXPEnabled);
  const canAccessGetStartedChecklist =
    !isEnterprise &&
    !isSuperAdmin &&
    (userRoles.includes('account-admin') || userRoles.includes('account-owner')) &&
    hasGetStartedChecklistPermission;

  const canEditAppSettings = useCheckPermission({
    resource: 'APPLICATION_SETTINGS',
    scope: 'EDIT'
  });
  const canViewBilling = useCheckPermission({
    /**
     * User permission to allow access to billing
     * e.g. An admin can access, but a content author cannot
     */
    resource: 'BILLING',
    scope: 'VIEW',
    /**
     * Billing plan permission to allow access to billing
     */
    accessPermission: 'ACCESS_BILLING_DETAILS'
  });

  return (
    <Box
      flex="1 1 auto"
      flexDirection={isMobile ? 'column-reverse' : 'row'}
      justifyContent={isMobile ? 'center' : 'space-between'}
      alignItems={isMobile ? 'stretch' : 'center'}
      gap={1}
      textAlign={isMobile ? 'center' : undefined}
      pb={isMobile ? 2 : 0}
      testId="navbar"
    >
      {isFreemium && (
        <ButtonLink href="/subscribe/select-plan" variant="primary" size={isMobile ? 'md' : 'xs'}>
          Explore Plans
        </ButtonLink>
      )}
      {!isUXP && isOnFreePlan && canViewBilling && (
        <ButtonLink
          href="/subscribe/select-plan"
          variant="primary"
          size={isMobile ? 'md' : 'xs'}
          testId="navigation-explore-plans-button"
        >
          Try EdApp Pro
        </ButtonLink>
      )}
      {isOnTrial && !isMobile && <NavProTrialTimeRemaining />}
      <Box
        flex="1 0 auto"
        justifyContent="flex-end"
        alignItems={isMobile ? 'stretch' : 'center'}
        flexDirection={isMobile ? 'column-reverse' : 'row'}
        gap={1}
      >
        {canAccessGetStartedChecklist && <NavGetStartedProgress />}
        {!isMobile && <NavNotifications />}
        {isSuperAdmin && <AdminAccountLookup />}
        {isUXP && canEditAppSettings && (
          <IconButtonLink href="/app-settings" icon={CogIcon} iconSize="md" variant="transparent" />
        )}
        {!isUXP && <ProfileDropdown />}
      </Box>
    </Box>
  );
};

export default Navigation;
