import type { State, Options, InternalOptions, InternalGroupedOptions } from './types';

// Iterate over the options and sort them into selected and unselected
// in a single pass by comparing against selected values, also keeping track
// of total counts and counts for grouped options.
// If selected values are provided by props, they take precedence over
// internal state.
export function createInternalOptions<OptionValue>(
  options: Options<OptionValue>,
  selectedValuesFromProps: ReadonlyArray<OptionValue> | undefined,
  state: State<OptionValue>
) {
  const selectedValues = selectedValuesFromProps || state.selectedValues;

  const unselectedOptions: InternalOptions<OptionValue> = {
    options: [],
    count: 0
  };

  const selectedOptions: InternalOptions<OptionValue> = {
    options: [],
    count: 0
  };

  options.forEach(option => {
    if ('options' in option) {
      // For nested options grouped under a label:
      // Keeps track of grouped option count to know whether the group
      // label should be displayed (does not display if group is empty)
      const unselectedGroup: InternalGroupedOptions<OptionValue> = {
        ...option,
        subCount: 0,
        options: []
      };

      const selectedGroup: InternalGroupedOptions<OptionValue> = {
        ...option,
        subCount: 0,
        options: []
      };

      unselectedOptions.options.push(unselectedGroup);
      selectedOptions.options.push(selectedGroup);

      option.options.forEach(subOption => {
        if (!matchesFilter([option.label, subOption.label], state.filterValue)) {
          return;
        }

        if (selectedValues.includes(subOption.value)) {
          selectedGroup.options.push(subOption);
          selectedGroup.subCount += 1;
          selectedOptions.count += 1;
        } else {
          unselectedGroup.options.push(subOption);
          unselectedGroup.subCount += 1;
          unselectedOptions.count += 1;
        }
      });
    } else {
      // For ungrouped, 'flat' options
      if (!matchesFilter(option.label, state.filterValue)) {
        return;
      }

      if (selectedValues.includes(option.value)) {
        selectedOptions.options.push(option);
        selectedOptions.count += 1;
      } else {
        unselectedOptions.options.push(option);
        unselectedOptions.count += 1;
      }
    }
  });

  return {
    unselectedOptions,
    selectedOptions
  };
}

export function matchesFilter(input: string | string[], filterValue: string): boolean {
  if (!filterValue) {
    return true;
  }

  const filter = filterValue.toLowerCase();

  function comparer(input: string) {
    return input.toLowerCase().includes(filter);
  }

  return Array.isArray(input) ? input.some(comparer) : comparer(input);
}
