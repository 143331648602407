import type { Question, TemplateInputType } from '../../store/types';
import type {
  DialDistributionData,
  Methods,
  NumberQuestionData,
  NumberSentenceData,
  RatioData
} from './types';

const numberQuestion = (e: NumberQuestionData): Question => {
  return {
    question: {
      type: 'text',
      content: e.title,
      category: e.title,
      text: e.title
    },
    answers: [
      {
        content: e.answer.value.toString(),
        text: e.answer.value.toString(),
        correct: true,
        type: 'number' as TemplateInputType
      },
      {
        content: e.answer.value.toString(),
        text: e.answer.value.toString(),
        correct: false,
        type: 'incorrect-answer' as TemplateInputType
      }
    ]
  };
};

export const methods: Methods = {
  dial: numberQuestion,
  'number-picker': numberQuestion,
  'pie-chart': numberQuestion,
  slider: numberQuestion,
  ratio: (e: RatioData) => {
    return e.ratio.bars.map((bar: any) => {
      return {
        question: {
          type: 'text',
          text: bar?.title ?? '',
          category: e.title,
          content: bar?.title ?? 0
        },
        answers: [
          {
            type: 'number',
            text: bar?.value ?? '',
            content: bar?.value ?? 0,
            correct: true
          },
          {
            correct: false,
            content: bar?.value ?? '',
            text: bar?.value ?? '',
            type: 'incorrect-answer'
          }
        ]
      };
    });
  },
  'number-sentence': (e: NumberSentenceData) => {
    return {
      question: {
        category: e.title,
        type: 'text',
        content: e.title,
        text: e.title
      },
      answers: e.numbers.map((n: any) => {
        return {
          type: 'number',
          content: n.number,
          text: n.number,
          correct: n.correct
        };
      })
    };
  },
  'dial-distribution': (e: DialDistributionData) => {
    return {
      question: {
        type: 'text',
        content: e.title,
        text: e.title,
        category: e.title
      },
      answers: e.dial.incorrect.concat(e.dial.correct).map((n: any) => {
        return {
          type: 'number',
          text: n,
          content: n,
          correct: n === e.dial.correct
        };
      })
    };
  }
};

export default methods;
