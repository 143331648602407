import * as React from 'react';

import { Box } from '@edapp/ed-components';
import { LessonLimitCount } from '@rio/components/account-plan-details/limits/free-plan/LessonLimitCount';
import { LessonLimitLabel } from '@rio/components/account-plan-details/limits/free-plan/LessonLimitLabel';
import { LessonLimitProgressBar } from '@rio/components/account-plan-details/limits/free-plan/LessonLimitProgressBar';
import { LessonLimitResetCountdown } from '@rio/components/account-plan-details/limits/free-plan/LessonLimitResetCountdown';
import type { LessonLimit } from '@rio/store/config/types';

type Props = {
  lessonLimit: LessonLimit | null;
};

const NavFreePlanLessonLimits: React.FC<Props> = ({ lessonLimit }) => {
  if (!lessonLimit) {
    return null;
  }

  return (
    <Box flex={true} flexDirection="column" pb="sm" px="sm">
      <Box flex={true} alignItems="center" justifyContent="space-between">
        <LessonLimitLabel color="text" />
        <LessonLimitCount
          completed={lessonLimit.lessonsCompleted}
          total={lessonLimit.lessonLimit}
          color="text"
        />
      </Box>
      <LessonLimitProgressBar value={lessonLimit.lessonsCompleted / lessonLimit.lessonLimit} />
      <LessonLimitResetCountdown date={lessonLimit.endDate} color="textMuted" />
    </Box>
  );
};

export default NavFreePlanLessonLimits;
