import * as React from 'react';
import type { LabelledTextInputProps } from './types';
import { StyledInput, StyledInputWrapper } from './styled';
import { WarningIcon } from '../../icons';
import { LabelledInput } from './LabelledInput';

/**
 * A fully features text input that allows for
 * labels, error messages, prefix and suffix components.
 */
export const LabelledTextInput: React.FC<LabelledTextInputProps> = ({
  label,
  helperText,
  inputSuffix,
  inputPrefix,
  testId,
  error,
  // wrapper props
  isFullWidth,
  isTransparent,
  spacing,
  className,
  // native input props
  ...inputProps
}) => {
  const inputId = inputProps.id || inputProps.name;

  return (
    <LabelledInput
      name={inputProps.name}
      isFullWidth={isFullWidth}
      spacing={spacing}
      label={label}
      helperText={helperText}
      error={error}
      className={className}
    >
      <StyledInputWrapper
        error={!!error}
        isFullWidth={isFullWidth}
        isDisabled={inputProps.disabled}
        isTransparent={isTransparent}
        className="input-wrapper"
      >
        {inputPrefix}
        <StyledInput
          id={inputId}
          data-testid={testId ?? 'input'}
          ml={inputPrefix ? 1 : 0}
          mr={inputSuffix ? 1 : 0}
          {...inputProps}
        />
        {error && !inputSuffix ? <WarningIcon color="red" /> : inputSuffix}
      </StyledInputWrapper>
    </LabelledInput>
  );
};
