import { t } from 'i18next';
import { createSelector } from 'reselect';

import type { LmsStoreState } from '@rio/store/types';

import { initialLessonPreview, newRapidRefresh } from './constants';
import type { RapidRefreshLessonPreviewType } from './types';

// Full object selector
const getRapidRefreshListById = (state: LmsStoreState) => state.rapidRefresh.byId;

export const getRapidRefreshById = (id: string) =>
  createSelector(getRapidRefreshListById, byId => byId[id]?.original ?? newRapidRefresh);

// Specific property selectors
export const getRapidRefreshTitle = (id: string) =>
  createSelector(getRapidRefreshById(id), rapidRefresher => rapidRefresher.title);

export const getRapidRefreshStatus = (id: string) =>
  createSelector(getRapidRefreshById(id), rapidRefresher => rapidRefresher.status);

export const getRapidRefreshEnrolment = (id: string) =>
  createSelector(getRapidRefreshById(id), rapidRefresher =>
    rapidRefresher.universalAccess ? t('courses.all-learners') : t('courses.selected-user-groups')
  );

export const getRapidRefreshId = (id: string) =>
  createSelector(getRapidRefreshById(id), rapidRefresher => rapidRefresher.id);

export const getRapidRefreshLessonPreview = (id: string) => (
  state: LmsStoreState
): RapidRefreshLessonPreviewType => state.rapidRefresh.lessonPreviews[id] || initialLessonPreview;

// Loading / error state selectors
export const getRapidRefreshSavingLoading = (state: LmsStoreState) => {
  return state.rapidRefresh.isSaving;
};

export const getRapidRefreshSavingError = (state: LmsStoreState) => {
  return state.rapidRefresh.savingError;
};

export const getRapidRefreshFetchLoading = (state: LmsStoreState) => {
  return state.rapidRefresh.isLoading;
};

export const getRapidRefreshFetchError = (state: LmsStoreState) => {
  return state.rapidRefresh.loadingError;
};

export const getRapidRefreshFetchLessonPreviewLoading = (state: LmsStoreState) => {
  return state.rapidRefresh.isLessonPreviewLoading;
};

export const getRapidRefreshFilter = (state: LmsStoreState) => {
  return state.rapidRefresh.filter;
};

export const getRapidRefreshSort = (state: LmsStoreState) => {
  return state.rapidRefresh.sort;
};
