import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyle = createGlobalStyle(
  ({ theme }) => css`
    #hubspot-messages-iframe-container {
      z-index: 1000 !important; // the default value is MAX_INT 2^31 and it will overlap full screen video in safari.
    }
    .react-wrapper .row:before,
    .react-wrapper .row:after {
      display: none;
    }

    body {
      background-color: ${theme.colors.background};
    }
  `
);
