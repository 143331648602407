import * as React from 'react';
import type { DraggableAttributes, DraggableSyntheticListeners } from '@dnd-kit/core';
import type { Transform } from '@dnd-kit/utilities';
import styled, { css } from 'styled-components';

export type ItemRenderProps = {
  dragOverlay?: boolean;
  dragging?: boolean;
  sorting?: boolean;
  index?: number;
  fadeIn?: boolean;
  transform: Transform | null;
  listeners?: DraggableSyntheticListeners;
  attributes?: DraggableAttributes;
  style?: React.CSSProperties;
  transition?: string;
  itemId: string;
  ref: React.ForwardedRef<HTMLLIElement>;
};

export type ItemProps = {
  height?: number;
  disabled?: boolean;
  onRemove?(): void;
  renderItem?(args: ItemRenderProps): React.ReactElement;
} & ItemRenderProps;

export const DefaultItem = React.memo(
  React.forwardRef<HTMLLIElement, Omit<ItemProps, 'ref'>>(
    (
      {
        dragOverlay,
        dragging,
        disabled,
        fadeIn,
        height,
        index,
        listeners,
        attributes,
        onRemove,
        renderItem,
        sorting,
        transition,
        transform,
        itemId,
        ...props
      },
      ref
    ) => {
      React.useEffect(() => {
        if (!dragOverlay) {
          return;
        }

        document.body.style.cursor = 'grabbing';

        return () => {
          document.body.style.cursor = '';
        };
      }, [dragOverlay]);

      if (renderItem) {
        return renderItem({
          dragOverlay: Boolean(dragOverlay),
          dragging: Boolean(dragging),
          sorting: Boolean(sorting),
          index,
          fadeIn: Boolean(fadeIn),
          listeners,
          attributes,
          transform,
          transition,
          itemId,
          ref
        });
      }

      return (
        <Wrapper
          fadeIn={fadeIn}
          dragOverlay={dragOverlay}
          transition={transition}
          transform={transform}
          ref={ref}
        >
          <Content data-cypress="draggable-item" {...listeners} {...props} tabIndex={0}>
            {itemId}
          </Content>
        </Wrapper>
      );
    }
  )
);

const Wrapper = styled.li<{
  fadeIn?: boolean;
  dragOverlay?: boolean;
  transition?: string | null;
  transform?: Transform | null;
}>(
  ({ fadeIn, dragOverlay, transition, transform }) => css`
    display: flex;
    box-sizing: border-box;
    transform: translate3d(var(--translate-x, 0), var(--translate-y, 0), 0) scaleX(var(--scale-x, 1)) scaleY(var(--scale-y, 1));
    transform-origin: 0 0;
    touch-action: manipulation;

    ${
      fadeIn &&
      css`
        animation: fadeIn 500ms ease;
      `
    }

    ${
      dragOverlay &&
      css`
        --scale: 1.05;
        --box-shadow: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
          0 1px calc(3px / var(--scale-x, 1)) 0 rgba(34, 33, 81, 0.15);
        --box-shadow-picked-up: 0 0 0 calc(1px / var(--scale-x, 1)) rgba(63, 63, 68, 0.05),
          -1px 0 15px 0 rgba(34, 33, 81, 0.01), 0px 15px 15px 0 rgba(34, 33, 81, 0.25);
        z-index: 999;
      `
    }
    ${
      transition &&
      css`
        transition: ${[transition].filter(Boolean).join(', ')};
      `
    };

    ${
      transform &&
      css`
        transform: translate3D(${Math.round(transform.x)}px, ${Math.round(transform.y)}px, 0)
          scaleX(${transform.scaleX}) scaleY(${transform.scaleY});
      `
    }
  `
);

const Content = styled.li<{
  dragging?: boolean;
  dragOverlay?: boolean;
  disabled?: boolean;
}>(
  ({ dragging, dragOverlay }) => css`
    position: relative;
    list-style: none;
    transform-origin: 50% 50%;
    -webkit-tap-highlight-color: transparent;

    transform: scale(var(--scale, 1));
    transition: box-shadow 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);

    touch-action: manipulation;
    cursor: grab;
    ${dragging &&
    css`
      opacity: 0.5;
      z-index: 0;
    `}

    ${dragOverlay &&
    css`
      cursor: inherit;
      animation: pop 200ms cubic-bezier(0.18, 0.67, 0.6, 1.22);
      transform: scale(var(--scale));
      box-shadow: var(--box-shadow-picked-up);
      opacity: 1;
    `}
  `
);
