import type { UserGroupItemTypeRedux, UserGroupsState } from './types';

export const initialUserGroupItemState: UserGroupItemTypeRedux = {
  app: {
    _id: '',
    dynamicUserGroupEnabled: false,
    sso: {
      assignGroups: false,
      enabled: false
    },
    customFields: {
      user: []
    }
  },
  groups: [],
  userIds: [],
  draws: [],
  usergroup: {
    _id: '',
    name: '',
    courses: [],
    catalogsCanAccess: [],
    draws: [],
    freezeUsers: false,
    groups: [],
    managers: [],
    lottery: {
      enableSpinToWin: false,
      enabled: false,
      starBar: ''
    },
    userGroupContains: 'users',
    isAccountGroup: false,
    isDynamicGroup: false,
    conditions: [],
    registrationSettings: {
      enable_registration_with_invite_code: false,
      invite_code: '',
      limits: {
        always_require_verification: false,
        enabled: false,
        max: 0
      }
    },
    users: {
      approved: 0,
      pending: 0
    }
  }
};

export const initialUserGroupsState: UserGroupsState = {
  checkStatusError: '',
  checkStatusLoading: false,
  allowedToEditStatus: true,

  fetchLoading: false,
  fetchError: '',

  fetchingConditions: false,
  fetchingConditionsError: '',

  fetchingDraws: false,
  fetchingDrawsError: '',

  mutateLoading: false,
  mutateError: '',

  userGroupItem: initialUserGroupItemState,
  userGroupNames: {
    loading: false,
    error: '',
    items: []
  }
};
