import type { CourseTitles, UserGroupNames } from '../types/filters';
import type { AnalyticsPageType, AnalyticsState } from '../types/types';
import type { ActionableReportItem } from './types';

export const sortByAlphabeticalOrderInAscendingOrder = (
  options: Array<UserGroupNames | CourseTitles>,
  sortKey: string
): Array<UserGroupNames | CourseTitles> => {
  if (!Array.isArray(options) || options.length < 1 || !sortKey) {
    return [];
  }

  return options.sort(
    (firstOption: UserGroupNames | CourseTitles, secondOption: UserGroupNames | CourseTitles) => {
      const firstOptionKey = firstOption[sortKey].toLowerCase();
      const secondOptionKey = secondOption[sortKey].toLowerCase();

      if (firstOptionKey < secondOptionKey) {
        return -1;
      }

      if (firstOptionKey > secondOptionKey) {
        return 1;
      }

      return 0;
    }
  );
};

export const sortByAlphabeticalOrderInDescendingOrder = (
  options: Array<UserGroupNames | CourseTitles>,
  sortKey: string
): Array<UserGroupNames | CourseTitles> => {
  if (!Array.isArray(options) || options.length < 1 || !sortKey) {
    return [];
  }

  return options.sort(
    (firstOption: UserGroupNames | CourseTitles, secondOption: UserGroupNames | CourseTitles) => {
      const firstOptionKey = firstOption[sortKey].toLowerCase();
      const secondOptionKey = secondOption[sortKey].toLowerCase();

      if (firstOptionKey < secondOptionKey) {
        return 1;
      }

      if (firstOptionKey > secondOptionKey) {
        return -1;
      }

      return 0;
    }
  );
};

export const resetReportItemsPage = (
  state: AnalyticsState[AnalyticsPageType.ACTIONABLE_REPORTS]
) => {
  return {
    ...state,
    data: {
      ...state.data,
      page: 1,
      pageSize: 25
    }
  };
};

export const isEmptyReportItems = (state: AnalyticsState[AnalyticsPageType.ACTIONABLE_REPORTS]) =>
  state.data.items.length === 0;

export const setDataItems = (
  state: AnalyticsState[AnalyticsPageType.ACTIONABLE_REPORTS],
  actionPayload: ActionableReportItem[]
) => (isEmptyReportItems(state) ? actionPayload : [...state.data.items, ...actionPayload]);

export const setCurrentPage = (state: AnalyticsState[AnalyticsPageType.ACTIONABLE_REPORTS]) =>
  isEmptyReportItems(state) ? state.data.page : state.data.page + 1;
