export enum FormActionTypes {
  // Slides
  SLIDE_REMOVE_ARRAY_ELEMENT = 'SLIDE_REMOVE_ARRAY_ELEMENT',
  SLIDE_ADD_ARRAY_ELEMENT = 'SLIDE_ADD_ARRAY_ELEMENT',
  SLIDE_UPDATE_ELEMENT = 'SLIDE_UPDATE_ELEMENT',
  SLIDE_SWAP_ARRAY_ELEMENT = 'SLIDE_SWAP_ARRAY_ELEMENT',
  SLIDE_DUPLICATE_ARRAY_ELEMENT = 'SLIDE_DUPLICATE_ARRAY_ELEMENT',

  // Navigation
  GO_PREVIOUS_SLIDE = 'NAVIGATION_PREVIOUS_SLIDE',
  GO_NEXT_SLIDE = 'NAVIGATION_NEXT_SLIDE',
  GO_TO_SLIDE = 'NAVIGATION_GO_TO_SLIDE',
  REORDER_SLIDE = 'REORDER_SLIDE',
  DELETE_SLIDE = 'DELETE_SLIDE',
  ADD_SLIDE = 'ADD_SLIDE',
  EDIT_SLIDE = 'EDIT_SLIDE',

  // Save
  SAVE_FORM = 'SAVE_FORM',
  SET_IS_SAVING_FORM = 'SET_IS_SAVING_FORM',
  SAVE_FORM_FULFILLED = 'SAVE_FORM_FULFILLED',
  SAVE_FORM_FAILED = 'SAVE_FORM_FAILED',
  CHANGE_AUTOSAVE = 'CHANGE_AUTOSAVE',

  // SlideInserter
  FETCH_OTHER_COURSES = 'FETCH_OTHER_COURSES',
  FETCH_OTHER_COURSES_FULFILLED = 'FETCH_OTHER_COURSES_FULFILLED',
  FETCH_OTHER_COURSES_FAILED = 'FETCH_OTHER_COURSES_FAILED',
  FETCH_LESSON_CONFIG = 'FETCH_LESSON_CONFIG',
  FETCH_LESSON_CONFIG_FULFILLED = 'FETCH_LESSON_CONFIG_FULFILLED',
  FETCH_LESSON_CONFIG_FAILED = 'FETCH_LESSON_CONFIG_FAILED',
  INSERT_SLIDES = 'INSERT_SLIDES',

  // Reset error
  RESET_ERROR = 'RESET_ERROR'
}

// Endpoints
export const fetchOtherCoursesUri = 'api/courses/contentNavigation';
export const fetchLessonConfigUri = (lessonId: string) => `api/lessons/${lessonId}`;
