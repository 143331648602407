import * as React from 'react';

import { shallowEqual, useSelector } from 'react-redux';

import { getCurrentPlan, getLessonLimit } from '@rio/store/config/selectors';

import NavFreePlanLessonLimits from './NavFreePlanLessonLimits';
import NavProTrialLimits from './NavProTrialLimits';

export const NavBillingLimits: React.FC = () => {
  const currentPlan = useSelector(getCurrentPlan, shallowEqual);
  const lessonLimit = useSelector(getLessonLimit, shallowEqual);
  return (
    <>
      {!!lessonLimit && <NavFreePlanLessonLimits lessonLimit={lessonLimit} />}
      {currentPlan.isTrial && <NavProTrialLimits />}
    </>
  );
};
