import styled, { css } from 'styled-components';

import { PillComponent } from './Pill';
import type { Props } from './types';

const getSpace = css<Pick<Props, 'space'>>(({ theme, space }) => {
  switch (space) {
    case 'medium':
      return `${theme.space()}px ${theme.space(2)}px`;

    case 'mini-even':
      return `${theme.space(0.5)}px`;

    case 'mini':
    default:
      return `${theme.space(0.5)}px ${theme.space()}px`;
  }
});

export const Pill = styled(PillComponent)(
  ({ backgroundColor = 'grey', theme, textColor, onClick }) => css`
    font-size: ${theme.fontSizes[0]}px;
    line-height: ${theme.lineHeights[1]}px;

    padding: ${getSpace};

    ${onClick && `cursor: pointer;`}

    background-color: ${theme.colors[backgroundColor] ?? backgroundColor};
    color: ${!textColor ? theme.colors.text : theme.colors[textColor] ?? textColor};
    display: inline-flex;
    align-items: center;
    border-radius: 28px;
    user-select: none;
  `
);
