import { t } from 'i18next';

import { updateSlideMetadata } from '@edapp/authoring-logic';
import { genId } from '@edapp/utils';
import { mergeDeep } from '@rio/utils/mergeDeep';

import type { BetterPptxActionsUnionType } from './actions';
import { BetterPptxActionTypes } from './actions';
import { initialBetterPptxState } from './constants';
import type { BetterPptxState, Lesson, PptSlide, Slide } from './types';
import { createLesson, createSimpleImageSlide, updateLesson, updateLessonById } from './utils';

export const betterPptxReducer = (
  state: BetterPptxState = initialBetterPptxState,
  action: BetterPptxActionsUnionType
): BetterPptxState => {
  switch (action.type) {
    case BetterPptxActionTypes.BETTER_PPTX_ADD_LESSON: {
      const newLessons = [...state.lessons, createLesson(action.payload.title, [])];
      return {
        ...state,
        lessons: newLessons,
        selectedSlides: [],
        selectedLessonIndex: newLessons.length - 1,
        selectedSlideIndex: -1
      };
    }

    case BetterPptxActionTypes.BETTER_PPTX_SELECT_LESSON: {
      if (action.payload.lessonIndex === state.selectedLessonIndex) {
        return state;
      }

      return {
        ...state,
        selectedSlides: [],
        selectedLessonIndex: action.payload.lessonIndex,
        selectedSlideIndex: 0
      };
    }

    case BetterPptxActionTypes.BETTER_PPTX_SELECT_SLIDE: {
      if (action.payload.slideIndex === state.selectedSlideIndex) {
        return state;
      }

      return { ...state, selectedSlideIndex: action.payload.slideIndex };
    }

    case BetterPptxActionTypes.BETTER_PPTX_SET_LESSON: {
      return updateLesson(state, action.payload.lessonIndex, l =>
        mergeDeep(l, action.payload.lesson)
      );
    }

    case BetterPptxActionTypes.BETTER_PPTX_DELETE_SLIDE: {
      const { lessonIndex, slideIndex } = action.payload;
      const newState = updateLesson(state, lessonIndex, l => {
        l.slides.splice(slideIndex, 1);
        return { ...l, slides: [...l.slides] };
      });
      return { ...newState, selectedSlides: [] };
    }

    case BetterPptxActionTypes.BETTER_PPTX_DELETE_LESSON: {
      const copyLessons = [...state.lessons];
      copyLessons.splice(action.payload.lessonIndex, 1);
      return { ...state, lessons: [...copyLessons], selectedSlides: [] };
    }

    case BetterPptxActionTypes.BETTER_PPTX_TRANSFORM_PPTX_SUCCESS: {
      const pptSlides = action.payload.pptSlides.map<PptSlide>((s, i) => ({
        ...s,
        conversionCandidates: [
          ...s.conversionCandidates.map(c => ({
            ...c,
            id: genId(),
            pptSlideIndex: i,
            suggestedByPpt: true
          }))
        ]
      }));

      const lesson: Lesson = createLesson(
        t('all-in-one-builder.new-lesson.default-title'),
        pptSlides
      );

      // insert simple image with pptx preview
      const pptSlidesWithSimpleImage = pptSlides.map<PptSlide>((s, i) => ({
        ...s,
        conversionCandidates: [
          ...s.conversionCandidates,
          createSimpleImageSlide(s.pptImage.url, i, true)
        ]
      }));

      return {
        ...state,
        loading: false,
        selectedLessonIndex: 0,
        selectedSlideIndex: 0,
        lessons: [lesson],
        pptSlides: pptSlidesWithSimpleImage
      };
    }

    case BetterPptxActionTypes.BETTER_PPTX_CHANGE_SLIDE: {
      const { newSlide, lessonIndex, slideIndex } = action.payload;

      return updateLesson(state, lessonIndex, l => {
        return {
          ...l,
          slides: l.slides.map<Slide>((i, si) => {
            if (slideIndex === si) {
              return updateSlideMetadata(newSlide);
            }
            return i;
          })
        };
      });
    }

    case BetterPptxActionTypes.BETTER_PPTX_REORDER_SLIDE: {
      const { fromIndex, toIndex, lessonIndex } = action.payload;

      if (toIndex < 0 || toIndex >= state.lessons[lessonIndex].slides.length) {
        return state;
      }

      const temp = state.lessons[lessonIndex].slides[fromIndex];
      state.lessons[lessonIndex].slides[fromIndex] = state.lessons[lessonIndex].slides[toIndex];
      state.lessons[lessonIndex].slides[toIndex] = temp;

      return {
        ...state,
        lessons: [...state.lessons],
        selectedSlides: [],
        selectedSlideIndex: toIndex
      };
    }

    case BetterPptxActionTypes.BETTER_PPTX_REORDER_LESSON: {
      const { fromIndex, toIndex } = action.payload;

      if (toIndex < 0 || toIndex >= state.lessons.length) {
        return state;
      }

      const temp = state.lessons[fromIndex];
      state.lessons[fromIndex] = state.lessons[toIndex];
      state.lessons[toIndex] = temp;

      return { ...state, selectedSlides: [], lessons: [...state.lessons] };
    }

    case BetterPptxActionTypes.BETTER_PPTX_SELECT_DEVICE_TYPE: {
      return { ...state, selectedDevice: action.payload.deviceType };
    }

    case BetterPptxActionTypes.BETTER_PPTX_SET_PREVIEW_MODE: {
      return { ...state, previewMode: action.payload.previewMode };
    }

    case BetterPptxActionTypes.BETTER_PPTX_CLEAR: {
      return { ...initialBetterPptxState };
    }

    case BetterPptxActionTypes.BETTER_PPTX_CREATE_COURSE:
    case BetterPptxActionTypes.BETTER_PPTX_TRANSFORM_PPTX: {
      return { ...state, loading: true };
    }

    case BetterPptxActionTypes.BETTER_PPTX_TRANSFORM_PPTX_FAILURE: {
      return { ...state, error: true, loading: false };
    }

    case BetterPptxActionTypes.BETTER_PPTX_CREATE_COURSE_SUCCESS: {
      return { ...state, courseId: action.payload.id, loading: false };
    }

    case BetterPptxActionTypes.BETTER_PPTX_SAVE_LESSONS_SUCCESS: {
      const { lessons } = action.payload;
      const newLessons = state.lessons.map((l, li) => ({ ...l, ...lessons[li] }));
      return { ...state, lessons: newLessons };
    }

    case BetterPptxActionTypes.BETTER_PPTX_SELECT_SLIDES: {
      return { ...state, selectedSlides: [...action.payload.slideIndexes] };
    }

    case BetterPptxActionTypes.BETTER_PPTX_MOVE_SLIDES: {
      const { fromLessonIndex, toLessonIndex, slideIndexes } = action.payload;

      // remove slides from lesson
      const removedSlides: Slide[] = [];
      let newLessons = state.lessons.map((l, li) => {
        if (li !== fromLessonIndex) {
          return l; // not the one
        }

        const filteredSlides = l.slides.filter((s, si) => {
          if (slideIndexes.includes(si)) {
            removedSlides.push(s);
            return false;
          } else {
            return true;
          }
        });
        return { ...l, slides: filteredSlides };
      });

      // add slides to lesson
      newLessons = newLessons.map((l, li) => {
        if (li !== toLessonIndex) {
          return l; // not the one
        }
        return { ...l, slides: [...l.slides, ...removedSlides] };
      });

      return {
        ...state,
        selectedSlides: [],
        selectedLessonIndex: toLessonIndex,
        selectedSlideIndex: 0,
        lessons: [...newLessons]
      };
    }

    case BetterPptxActionTypes.BETTER_PPTX_ADJUST_FAILED_TO_SAVE_SLIDE: {
      const { lessonId, slideIndex } = action.payload;

      const lesson = state.lessons.find(l => l.id === lessonId);
      if (!lesson) {
        return state;
      }

      const slideWithError = lesson.slides[slideIndex];

      // remove slide from the suggestions
      const newPptSlides = state.pptSlides.map((ppt, index) => {
        if (index !== slideWithError.pptSlideIndex) {
          return ppt; // not the one
        }

        return {
          ...ppt,
          conversionCandidates: ppt.conversionCandidates.filter(i => i.id !== slideWithError.id)
        };
      });

      // switch slide to Single image
      const simpleImageSlide = newPptSlides[slideWithError.pptSlideIndex].conversionCandidates.find(
        s => s.templateName === 'Single image'
      );
      if (!simpleImageSlide) {
        return state;
      }
      const newState = updateLessonById(state, lessonId, lesson => {
        const newSlides = lesson.slides.map((s, si) => {
          if (si !== slideIndex) {
            return s; // not the one
          }

          return simpleImageSlide;
        });

        return { ...lesson, slides: newSlides };
      });

      return { ...newState, pptSlides: newPptSlides };
    }

    default: {
      return state;
    }
  }
};
