import { uniqBy } from 'lodash-es';
import type { Reducer } from 'redux';

import { LessonDiscriminatorEnum } from '@edapp/authoring-logic';
import * as courseActions from '@edapp/courseware-logic/src/course/actions';
import { createMutated, mergeMutated, spreadMutated } from '@edapp/utils';

import * as actions from './actions';
import { initialDiscussion, initialPaginatedState } from './constants';
import type { DiscussionState } from './types';

export const initialDiscussionState: DiscussionState = {
  discussionSummaries: {
    items: [],
    currentPage: 1,
    totalCount: 0,
    isLoading: true,
    error: ''
  },
  discussions: {},
  posts: {},
  comments: {},
  fetchDiscussionLoading: false,
  fetchDiscussionError: '',
  mutateDiscussionLoading: false,
  mutateDiscussionError: '',
  fetchPostsLoading: false,
  fetchPostsError: '',
  fetchCommentsLoading: false,
  fetchCommentsError: '',
  deleteCommentLoading: false,
  deleteCommentError: '',
  addCommentLoading: false,
  addCommentError: '',
  deletePostLoading: false,
  deletePostError: ''
};

export const discussionReducer: Reducer<DiscussionState> = (
  state = initialDiscussionState,
  action
) => {
  switch (action.type) {
    case actions.FETCH_DISCUSSION_SUMMARIES: {
      const { page } = action.payload as actions.FetchDiscussionSummariesPayload;

      return {
        ...state,
        discussionSummaries: {
          ...state.discussionSummaries,
          items: page === 1 ? [] : state.discussionSummaries.items,
          isLoading: true,
          error: ''
        }
      };
    }

    case actions.FETCH_DISCUSSION_SUMMARIES_SUCCESS: {
      const payload = action.payload as actions.FetchDiscussionSummariesSuccessPayload;
      const { items, currentPage, totalCount } = payload;

      return {
        ...state,
        discussionSummaries: {
          ...state.discussionSummaries,
          items: currentPage === 1 ? items : state.discussionSummaries.items.concat(items),
          isLoading: false,
          error: '',
          currentPage,
          totalCount
        }
      };
    }

    case actions.FETCH_DISCUSSION_SUMMARIES_FAILURE: {
      const payload = action.payload as actions.FetchDiscussionSummariesFailurePayload;

      return {
        ...state,
        discussionSummaries: {
          ...state.discussionSummaries,
          isLoading: false,
          error: payload.message
        }
      };
    }

    case actions.FETCH_DISCUSSION: {
      return { ...state, fetchDiscussionLoading: true, fetchDiscussionError: '' };
    }
    case actions.FETCH_DISCUSSION_SUCCESS: {
      const discussion = action.payload as actions.FetchDiscussionSuccess;
      return {
        ...state,
        fetchDiscussionLoading: false,
        fetchDiscussionError: '',
        discussions: {
          ...state.discussions,
          [discussion.id]: {
            ...createMutated(discussion)
          }
        }
      };
    }
    case actions.FETCH_DISCUSSION_FAILURE: {
      const { message } = action.payload as actions.FetchDiscussionFailure;
      return { ...state, fetchDiscussionLoading: false, fetchDiscussionError: message };
    }

    case actions.SAVE_DISCUSSION: {
      const { data, discussionId } = action.payload as actions.SaveDiscussion;
      const currentDiscusssion = state.discussions[discussionId] ?? {
        original: initialDiscussion,
        changed: initialDiscussion
      };
      return {
        ...state,
        mutateDiscussionLoading: true,
        mutateDiscussionError: '',
        discussions: {
          ...state.discussions,
          [discussionId]: {
            ...mergeMutated(currentDiscusssion, data)
          }
        }
      };
    }
    case actions.SAVE_DISCUSSION_SUCCESS: {
      return { ...state, mutateDiscussionLoading: false, mutateDiscussionError: '' };
    }
    case actions.SAVE_DISCUSSION_FAILURE: {
      const {
        error,
        discussionId,
        discussionStateBeforeSave
      } = action.payload as actions.SaveDiscussionFailure;
      const currentDiscusssion = state.discussions[discussionId] ?? {
        original: initialDiscussion,
        changed: initialDiscussion
      };
      return {
        ...state,
        mutateDiscussionLoading: false,
        mutateDiscussionError: error.message,
        discussions: {
          ...state.discussions,
          [discussionId]: {
            ...spreadMutated(currentDiscusssion, discussionStateBeforeSave)
          }
        }
      };
    }
    case courseActions.DELETE_LESSON_SUCCESS: {
      const { lessonId, type } = action.payload as courseActions.DeleteLessonSuccess;

      if (type !== LessonDiscriminatorEnum.DISCUSSION) {
        return state;
      }

      const { [lessonId]: deletedDiscussion, ...newDiscussions } = state.discussions;

      return {
        ...state,
        discussions: newDiscussions
      };
    }

    case actions.FETCH_POSTS: {
      return { ...state, fetchPostsLoading: true, fetchPostsError: '' };
    }
    case actions.FETCH_POSTS_SUCCESS: {
      const { items, totalCount, discussionId, page } = action.payload as actions.FetchPostsSuccess;
      const currentItems = state.posts[discussionId]?.items || [];

      return {
        ...state,
        fetchPostsLoading: false,
        fetchPostsError: '',
        posts: {
          ...state.posts,
          [discussionId]: {
            totalCount,
            items: page === 1 ? items : currentItems.concat(items),
            currentPage: page
          }
        }
      };
    }
    case actions.FETCH_POSTS_FAILURE: {
      const { message } = action.payload as actions.FetchPostsFailure;
      return { ...state, fetchPostsLoading: false, fetchPostsError: message };
    }

    case actions.FETCH_COMMENTS: {
      return { ...state, fetchCommentsLoading: true, fetchCommentsError: '' };
    }
    case actions.FETCH_COMMENTS_SUCCESS: {
      const { totalCount, items, postId, page } = action.payload as actions.FetchCommentsSuccess;
      const currentItems = state.comments[postId]?.items ?? [];
      const reversedItems = items.reverse();
      return {
        ...state,
        fetchPCommentsLoading: false,
        fetchPCommentsError: '',
        comments: {
          ...state.comments,
          [postId]: {
            totalCount,
            items: page === 1 ? reversedItems : uniqBy(reversedItems.concat(currentItems), 'id'),
            currentPage: page
          }
        }
      };
    }
    case actions.FETCH_COMMENTS_FAILURE: {
      const { message } = action.payload as actions.FetchCommentsFailure;
      return { ...state, fetchCommentsLoading: false, fetchCommentsError: message };
    }

    case actions.FETCH_INITIAL_COMMENTS: {
      return { ...state, fetchCommentsLoading: true, fetchCommentsError: '' };
    }

    case actions.FETCH_INITIAL_COMMENTS_SUCCESS: {
      const items = action.payload as actions.FetchInitialCommentsSuccess;
      const newCommentState = {};
      Object.keys(items).forEach(id => {
        const comments = items[id] || { items: [], totalCount: 0 };
        newCommentState[id] = {
          totalCount: comments.totalCount,
          items: comments.items.reverse()
        };
      });
      return {
        ...state,
        fetchCommentsLoading: false,
        fetchCommentsError: '',
        comments: {
          ...state.comments,
          ...newCommentState
        }
      };
    }

    case actions.FETCH_INITIAL_COMMENTS_FAILURE: {
      const { message } = action.payload as actions.FetchInitialCommentsFailure;
      return { ...state, fetchCommentsLoading: false, fetchCommentsError: message };
    }

    case actions.ADD_COMMENT: {
      return { ...state, addCommentLoading: true, addCommentError: '' };
    }
    case actions.ADD_COMMENT_SUCCESS: {
      const { postId, comment } = action.payload as actions.AddCommentSuccess;
      const currentState = state.comments[postId] ?? initialPaginatedState;
      const nextState = {
        items: [...currentState.items, comment],
        totalCount: currentState.totalCount + 1,
        currentPage: currentState.currentPage || 0
      };
      return {
        ...state,
        addCommentLoading: false,
        addCommentError: '',
        comments: {
          ...state.comments,
          [postId]: nextState
        }
      };
    }
    case actions.ADD_COMMENT_FAILURE: {
      const { message } = action.payload as actions.AddCommentFailure;
      return { ...state, addCommentLoading: false, addCommentError: message };
    }

    case actions.EDIT_COMMENT: {
      return { ...state, editCommentLoading: true, editCommentError: '' };
    }
    case actions.EDIT_COMMENT_SUCCESS: {
      const { postId, comment } = action.payload as actions.EditCommentSuccess;
      const currentState = state.comments[postId] ?? initialPaginatedState;
      const nextState = {
        items: currentState.items.map(item => (item.id === comment.id ? comment : item)),
        totalCount: currentState.totalCount,
        currentPage: currentState.currentPage || 0
      };
      return {
        ...state,
        editCommentLoading: false,
        editCommentError: '',
        comments: {
          ...state.comments,
          [postId]: nextState
        }
      };
    }
    case actions.EDIT_COMMENT_FAILURE: {
      const { message } = action.payload as actions.EditCommentFailure;
      return { ...state, editCommentLoading: false, editCommentError: message };
    }

    case actions.DELETE_COMMENT: {
      return { ...state, deleteCommentLoading: true, deleteCommentError: '' };
    }
    case actions.DELETE_COMMENT_SUCCESS: {
      const { postId, commentId } = action.payload as actions.DeleteCommentSuccess;
      const currentState = state.comments[postId] ?? initialPaginatedState;
      const nextState = {
        items: [...currentState.items.filter(item => item.id !== commentId)],
        totalCount: currentState.totalCount > 0 ? currentState.totalCount - 1 : 0,
        currentPage: currentState.currentPage || 0
      };
      return {
        ...state,
        deleteCommentLoading: false,
        deleteCommentError: '',
        comments: {
          ...state.comments,
          [postId]: nextState
        }
      };
    }
    case actions.DELETE_COMMENT_FAILURE: {
      const { message } = action.payload as actions.DeleteCommentFailure;
      return { ...state, deleteCommentLoading: false, deleteCommentError: message };
    }

    case actions.DELETE_POST: {
      return { ...state, deletePostLoading: true, deletePostError: '' };
    }
    case actions.DELETE_POST_SUCCESS: {
      const { postId, discussionId } = action.payload as actions.DeletePostSuccess;
      const currentState = state.posts[discussionId] ?? initialPaginatedState;
      const nextState = {
        items: [...currentState.items.filter(item => item.id !== postId)],
        currentPage: currentState.currentPage,
        totalCount: currentState.totalCount > 0 ? currentState.totalCount - 1 : 0
      };

      const comments = { ...state.comments };
      delete comments[postId];

      return {
        ...state,
        deletePostLoading: false,
        deletePostError: '',
        posts: {
          ...state.posts,
          [discussionId]: nextState
        },
        comments: { ...comments }
      };
    }
    case actions.DELETE_POST_FAILURE: {
      const { message } = action.payload as actions.DeletePostFailure;
      return { ...state, deletePostLoading: false, deletePostError: message };
    }

    default:
      return state;
  }
};
