export const SET_REPORT_TYPE = 'SET_REPORT_TYPE';
export const SET_REPORT_TYPE_FAILURE = 'SET_REPORT_TYPE_FAILURE';

export type SetReportType = {
  reportType: string;
};

export const setReportType = (reportType: string): Action<SetReportType> => ({
  type: SET_REPORT_TYPE,
  payload: {
    reportType
  }
});

export const SET_CLEAR_FILTERS = 'SET_CLEAR_FILTERS';
export const SET_CLEAR_FILTERS_SUCCESS = 'SET_CLEAR_FILTERS_SUCCESS';
export const SET_CLEAR_FILTERS_FAILURE = 'SET_CLEAR_FILTERS_FAILURE';

export type SetClearFilters = {
  isClearFilters: boolean;
};

export const setClearFilters = (isClearFilters: boolean): Action<SetClearFilters> => ({
  type: SET_CLEAR_FILTERS,
  payload: {
    isClearFilters
  }
});

export const FETCH_COURSE_TITLES = 'FETCH_COURSE_TITLES';
export const FETCH_COURSE_TITLES_SUCCESS = 'FETCH_COURSE_TITLES_SUCCESS';
export const FETCH_COURSE_TITLES_FAILURE = 'FETCH_COURSE_TITLES_FAILURE';

export type FetchSelectedCourses = {};

export const fetchCourseTitles = (): Action<FetchSelectedCourses> => ({
  type: FETCH_COURSE_TITLES,
  payload: {}
});

export const SET_SELECTED_COURSE_IDS = 'SET_SELECTED_COURSE_IDS';
export const SET_SELECTED_COURSE_IDS_SUCCESS = 'SET_SELECTED_COURSE_IDS_SUCCESS';
export const SET_SELECTED_COURSE_IDS_FAILURE = 'SET_SELECTED_COURSE_IDS_FAILURE';

export type SetSelectedCourseIds = {
  selectedCourseIds: string;
};

export const setSelectedCourseIds = (selectedCourseIds: string): Action<SetSelectedCourseIds> => ({
  type: SET_SELECTED_COURSE_IDS,
  payload: {
    selectedCourseIds
  }
});

export const SET_SELECTED_USER_GROUP_IDS = 'SET_SELECTED_USER_GROUP_IDS';
export const SET_SELECTED_USER_GROUP_IDS_SUCCESS = 'SET_SELECTED_USER_GROUP_IDS_SUCCESS';
export const SET_SELECTED_USER_GROUP_IDS_FAILURE = 'SET_SELECTED_USER_GROUP_IDS_FAILURE';

export type SetSelectedUserGroups = {
  selectedUserGroupIds: string[];
};

export const setSelectedUserGroups = (
  selectedUserGroupIds: string[]
): Action<SetSelectedUserGroups> => ({
  type: SET_SELECTED_USER_GROUP_IDS,
  payload: {
    selectedUserGroupIds
  }
});

export const REMOVE_SELECTED_USER_GROUP_ID = 'REMOVE_SELECTED_USER_GROUP_ID';
export const REMOVE_SELECTED_USER_GROUP_ID_SUCCESS = 'REMOVE_SELECTED_USER_GROUP_ID_SUCCESS';
export const REMOVE_SELECTED_USER_GROUP_ID_FAILURE = 'REMOVE_SELECTED_USER_GROUP_ID_FAILURE';

export type RemoveSelectedUserGroup = {
  selectedUserGroupIds: string[];
};

export const removeSelectedUserGroups = (
  selectedUserGroupIds: string[]
): Action<RemoveSelectedUserGroup> => ({
  type: REMOVE_SELECTED_USER_GROUP_ID,
  payload: {
    selectedUserGroupIds
  }
});

export const FETCH_USER_GROUP_NAMES = 'FETCH_USER_GROUP_NAMES';
export const FETCH_USER_GROUP_NAMES_SUCCESS = 'FETCH_USER_GROUP_NAMES_SUCCESS';
export const FETCH_USER_GROUP_NAMES_FAILURE = 'FETCH_USER_GROUP_NAMES_FAILURE';

export type FetchUserGroupNames = { selectedCourseIds?: string };

export const fetchUserGroupNames = (selectedCourseIds?: string): Action<FetchUserGroupNames> => ({
  type: FETCH_USER_GROUP_NAMES,
  payload: {
    selectedCourseIds
  }
});

export const FETCH_REPORT_ITEMS = 'FETCH_REPORT_ITEMS';
export const FETCH_REPORT_ITEMS_SUCCESS = 'FETCH_REPORT_ITEMS_SUCCESS';
export const FETCH_REPORT_ITEMS_FAILURE = 'FETCH_REPORT_ITEMS_FAILURE';

export type FetchReportItems = {};

export const fetchReportItems = (): Action<FetchReportItems> => ({
  type: FETCH_REPORT_ITEMS,
  payload: {}
});
