import * as React from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import {
  ActionDialogBody,
  ActionDialogFooter,
  ActionDialogIcon,
  Box,
  Button,
  CloseDialogButton,
  Dialog,
  Input,
  Typography,
  WarningIcon
} from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';

type Props = {
  isOpen: boolean;
  onClose: () => void;

  title: string;
  description: string;

  inviteCode: string;
  onRegenerateCode: () => void;
};

export const InviteCodeModal: React.FC<Props> = ({
  title,
  description,
  isOpen,
  onClose,
  inviteCode,
  onRegenerateCode
}) => {
  const { t } = useTranslation();
  const [showConfirmation, setShowConfirmation] = React.useState(false);

  const handleOpenWarning = () => {
    setShowConfirmation(true);
  };

  const handleCloseWarning = () => {
    setShowConfirmation(false);
  };

  const handleConfirmRegenerate = () => {
    setShowConfirmation(false);
    onRegenerateCode();
  };

  return (
    <StyledDialog usePortal={true} isOpen={isOpen}>
      <Box mt={4} mb={5} mx={6}>
        <Text variant="titleLarge" component="h2">
          {title}
        </Text>

        <Typography variant="normal" color="textMuted">
          {description}
        </Typography>

        <Box mt={2}>
          <AnimatePresence>
            {showConfirmation && (
              <motion.div
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: 'auto', opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
              >
                <Box flex flexDirection="column" textAlign="center">
                  <ActionDialogIcon icon={WarningIcon} iconColor="orange" />
                  <ActionDialogBody
                    title={t('registration-settings.manual-code.warning.title')}
                    description={t('registration-settings.manual-code.warning.description')}
                  />
                  <Box flex justifyContent="center" alignItems="center" mt={3}>
                    <ActionDialogFooter
                      confirmButtonVariant="warning"
                      isClosable={true}
                      confirmText={t('registration-settings.manual-code.warning.confirmation')}
                      onCancel={handleCloseWarning}
                      onClose={handleCloseWarning}
                      onConfirm={handleConfirmRegenerate}
                    />
                  </Box>
                </Box>
              </motion.div>
            )}
          </AnimatePresence>

          {!showConfirmation && (
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 'auto', opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
            >
              <Box flex flexDirection="column" alignItems="center">
                <StyledInput
                  value={inviteCode.toUpperCase()}
                  isDisabled={true}
                  id="invite_code"
                  hasMargin={false}
                  isFullWidth={true}
                  data-autosave-ignore={true} //TODO: https://safetyculture.atlassian.net/browse/TRAINING-555
                />

                <Button
                  mt={2}
                  variant="primary"
                  onClick={handleOpenWarning}
                  data-autosave-ignore={true} //TODO: https://safetyculture.atlassian.net/browse/TRAINING-555
                >
                  {t('registration-settings.manual-code.regenerate-code')}
                </Button>
              </Box>
            </motion.div>
          )}
        </Box>
      </Box>

      <CloseDialogButton onClick={onClose} />
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)(
  ({ theme }) => css`
    width: 100%;
    max-width: 340px;
    @media (min-width: ${theme.breakpoints.sm}rem) {
      max-width: 600px;
    }
  `
);

const StyledInput = styled(Input)(
  ({ theme }) => css`
    flex-grow: 1 ${theme.lineClamp(1)};
    padding: 0px ${theme.space(2)}px;
    min-width: 105px;
  `
);
