import * as React from 'react';

import * as ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { Typography } from '@edapp/ed-components';

export const SAVING_LABEL_TEST_ID = 'saving-label';

type Props = {
  saving: boolean;
  errorMsg?: string;
};

/**
 * @deprecated
 * Please use LoadingToastNotification instead
 */
const SavingLabel: React.FC<Props> = ({ saving, errorMsg }) => {
  const { t } = useTranslation();
  const [show, setShow] = React.useState(saving || false);
  const showTimerRef = React.useRef<NodeJS.Timeout | null>(null);
  const hideTimerRef = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    const newValue = !!saving || !!errorMsg;
    showTimerRef.current = setTimeout(
      () => {
        setShow(newValue);
      },
      !!newValue ? 0 : 2000
    );

    // in case saving is not back to false force hide after 3.5s
    hideTimerRef.current = setTimeout(() => {
      setShow(false);
    }, 3500);

    return () => {
      if (showTimerRef.current) {
        clearTimeout(showTimerRef.current);
      }
      if (hideTimerRef.current) {
        clearTimeout(hideTimerRef.current);
      }
    };
  }, [saving, errorMsg]);

  const label =
    !saving && !!errorMsg
      ? `${t('common.failed')} ${errorMsg}`
      : !!saving
      ? t('common.saving-changes')
      : t('common.saved');

  return ReactDOM.createPortal(
    <PositionWrapper visible={show}>
      <Label
        variant="subtitle2"
        color="white"
        visible={show}
        error={!saving && !!errorMsg}
        testId={SAVING_LABEL_TEST_ID}
      >
        {label}
      </Label>
    </PositionWrapper>,
    document.body
  );
};

type VisibilityProps = { visible: boolean };
const PositionWrapper = styled.div<VisibilityProps>(
  ({ visible }) => css`
    width: 100%;
    display: flex;
    justify-content: center;
    position: fixed;
    top: ${visible ? '50px' : 0};
    z-index: 1062;
    pointer-events: none;
    transition: top 300ms;
  `
);

const Label = styled(Typography)<VisibilityProps & { error: boolean }>(
  ({ theme, visible, error = false }) => css`
    visibility: ${visible ? 'visible' : 'hidden'};
    opacity: ${visible ? 1 : 0};
    padding: ${theme.space(1.5)}px;
    border: ${!error ? theme.colors.blueHover : theme.colors.red};
    border-radius: 4px;
    background-color: ${!error ? theme.colors.blue : theme.colors.red};
    transition: background-color 300ms, opacity 300ms, visibility 300ms;
  `
);

export default SavingLabel;
