import { call, fork, put, select, takeEvery } from 'redux-saga/effects';

import type { RequestTypes } from '@edapp/request';
import { RequestActionTypes, RequestUtils } from '@edapp/request';
import type { EdExceptionType } from '@edapp/request';
import { getHippoUrl, getUserToken } from '@rio/store/config/selectors';

const cleanUri = (uri: string) => (uri.indexOf('/') === 0 ? uri.slice(1) : uri);

function* handleRequest(action: Action<RequestTypes.RequestPayloadType>): any {
  const { type, payload } = action;
  const {
    uri,
    successAction,
    failureAction,
    includeCredentialsCookie,
    headers,
    isFormData
  } = payload;
  let { data } = payload;
  const hippoUrl: ReturnType<typeof getHippoUrl> = yield select(getHippoUrl);

  let url = includeCredentialsCookie ? `/${cleanUri(uri)}` : `${hippoUrl}/${cleanUri(uri)}`;

  const requestParams = type.split('_');
  const requestMethod = requestParams[1];
  let userToken: ReturnType<typeof getUserToken> = null;

  if (requestParams[0] === 'AUTHED') {
    userToken = yield select(getUserToken);
  }

  if (requestMethod === 'GET' && !!data) {
    url = RequestUtils.getUrlWithEncodedParams(url, data);
    data = undefined;
  }

  const credentials = !!includeCredentialsCookie ? 'same-origin' : undefined;

  try {
    const response = yield call(
      RequestUtils.httpFetch,
      requestMethod,
      url,
      userToken,
      data || null,
      credentials,
      headers,
      isFormData
    );

    const responseAction =
      typeof successAction === 'function'
        ? successAction(response)
        : { type: successAction, payload: response };

    yield put(responseAction);
  } catch (e) {
    const error: EdExceptionType = e;
    const errorResponse = error.errorResponse
      ? {
          ...error.errorResponse,
          statusCode: error?.statusCode
        }
      : {
          type: 'Error',
          message: 'Failed!',
          statusCode: e.statusCode
        };

    const errorAction =
      typeof failureAction === 'function'
        ? failureAction(errorResponse)
        : { type: failureAction, payload: errorResponse };

    yield put(errorAction);
  } finally {
    yield put({
      type: RequestActionTypes.REQUEST_COMPLETED
    });
  }
}

function* watchRequests() {
  yield takeEvery(
    [
      RequestActionTypes.AUTHED_GET_REQUEST,
      RequestActionTypes.AUTHED_POST_REQUEST,
      RequestActionTypes.AUTHED_DELETE_REQUEST,
      RequestActionTypes.UNAUTHED_GET_REQUEST,
      RequestActionTypes.UNAUTHED_POST_REQUEST,
      RequestActionTypes.AUTHED_PUT_REQUEST,
      RequestActionTypes.AUTHED_PATCH_REQUEST
    ],
    handleRequest
  );
}

const sagas = [fork(watchRequests)];

export default sagas;
