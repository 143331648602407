import * as actions from './actions';
import type { PlaylistsState } from './types';

export const initialPlaylistState: PlaylistsState = {
  playlists: [],
  fetchPlaylistsLoading: false,
  fetchPlaylistsError: '',
  totalCount: 0,
  playlistItems: {},
  playlistCourses: {},
  playlistDirty: false,
  fetchPlaylistItemLoading: false,
  fetchPlaylistItemError: '',
  mutatePlaylistItemLoading: false,
  mutatePlaylistItemError: '',
  fetchPlaylistItemCoursesLoading: false,
  fetchPlaylistItemCoursesError: '',
  removePlaylistError: '',
  fetchPlaylistTranslationLoading: false,
  fetchPlaylistTranslationError: ''
};

export const playlistsReducer: Reducer<PlaylistsState> = (state = initialPlaylistState, action) => {
  switch (action.type) {
    case actions.CREATE_PLAYLIST: {
      return { ...state, mutatePlaylistItemLoading: true, mutatePlaylistItemError: '' };
    }
    case actions.CREATE_PLAYLIST_SUCCESS:
    case actions.CLEAR_DRAFT_PLAYLIST_ITEM: {
      const items = { ...state.playlistItems };
      const courses = { ...state.playlistCourses };

      delete items['new'];
      delete courses['new'];

      return {
        ...state,
        mutatePlaylistItemLoading: false,
        mutatePlaylistItemError: '',
        playlistDirty: false,
        playlistItems: { ...items },
        playlistCourses: { ...courses }
      };
    }
    case actions.CREATE_PLAYLIST_FAILURE: {
      const { message } = action.payload as actions.CreatePlaylistFailure;
      return { ...state, mutatePlaylistItemLoading: false, mutatePlaylistItemError: message };
    }

    case actions.SET_PLAYLIST: {
      const { id, playlist } = action.payload as actions.SetPlaylist;
      return {
        ...state,
        playlistDirty: true,
        playlistItems: {
          ...state.playlistItems,
          [id]: {
            ...state.playlistItems[id],
            ...playlist
          }
        }
      };
    }

    case actions.ACKNOWLEDGE_PREREQUISITES_WARNING: {
      const { id } = action.payload as actions.AcknowledgePrerequisitesWarning;
      return {
        ...state,
        playlistItems: {
          ...state.playlistItems,
          [id]: {
            ...state.playlistItems[id],
            isAcknowledgedWarning: true
          }
        }
      };
    }

    case actions.ADD_PLAYLIST_TRANSLATION: {
      const { id, translation } = action.payload as actions.AddPlaylistTranslation;
      const playlist = state.playlistItems[id];
      return {
        ...state,
        playlistDirty: true,
        playlistItems: {
          ...state.playlistItems,
          [id]: {
            ...playlist,
            translations: playlist.translations
              ? [...playlist.translations, translation]
              : [translation]
          }
        }
      };
    }

    case actions.UPDATE_PLAYLIST_TRANSLATION: {
      const { id, languageCode, translation } = action.payload as actions.UpdatePlaylistTranslation;
      const playlist = state.playlistItems[id];
      if (!playlist.translations) {
        return state;
      }
      return {
        ...state,
        playlistDirty: true,
        playlistItems: {
          ...state.playlistItems,
          [id]: {
            ...playlist,
            translations: playlist.translations?.map(existingTranslation =>
              existingTranslation.locale.code === languageCode ? translation : existingTranslation
            )
          }
        }
      };
    }

    case actions.REMOVE_PLAYLIST_TRANSLATION: {
      const { id, languageCode } = action.payload as actions.RemovePlaylistTranslation;
      const playlist = state.playlistItems[id];

      return {
        ...state,
        playlistDirty: true,
        playlistItems: {
          ...state.playlistItems,
          [id]: {
            ...playlist,
            translations:
              playlist.translations?.filter(
                translation => translation.locale.code !== languageCode
              ) || []
          }
        }
      };
    }

    case actions.SAVE_PLAYLIST: {
      return { ...state, mutatePlaylistItemLoading: true, mutatePlaylistItemError: '' };
    }
    case actions.SAVE_PLAYLIST_SUCCESS: {
      return {
        ...state,
        mutatePlaylistItemLoading: false,
        mutatePlaylistItemError: '',
        playlistDirty: false
      };
    }
    case actions.SAVE_PLAYLIST_FAILURE: {
      const { message } = action.payload as actions.SavePlaylistFailure;
      return { ...state, mutatePlaylistItemLoading: false, mutatePlaylistItemError: message };
    }

    case actions.FETCH_PLAYLISTS: {
      return { ...state, fetchPlaylistsLoading: true, fetchPlaylistsError: '' };
    }
    case actions.FETCH_PLAYLISTS_SUCCESS: {
      const { items, totalCount, page } = action.payload as actions.FetchPlaylistsSuccess;

      const playlists = page === 1 ? [...items] : [...state.playlists, ...items];

      return {
        ...state,
        fetchPlaylistsLoading: false,
        fetchPlaylistsError: '',
        playlists,
        totalCount
      };
    }
    case actions.FETCH_PLAYLISTS_FAILURE: {
      const { message } = action.payload as actions.FetchPlaylistsFailure;
      return { ...state, fetchPlaylistsLoading: false, fetchPlaylistsError: message };
    }

    case actions.REMOVE_PLAYLIST: {
      const { playlistId } = action.payload as actions.RemovePlaylist;

      const newList = [...state.playlists].filter(playlist => playlist.id !== playlistId);

      const newItems = { ...state.playlistItems };
      delete newItems[playlistId];

      return {
        ...state,
        totalCount: state.totalCount - 1,
        playlists: newList,
        playlistItems: newItems,
        removePlaylistError: ''
      };
    }

    case actions.REMOVE_PLAYLIST_FAILURE: {
      const { message } = action.payload as actions.RemovePlaylistFailure;
      return { ...state, removePlaylistError: message };
    }

    case actions.FETCH_PLAYLIST_ITEM: {
      return { ...state, fetchPlaylistItemLoading: true, fetchPlaylistItemError: '' };
    }
    case actions.FETCH_PLAYLIST_ITEM_SUCCESS: {
      const playlist = action.payload as actions.FetchPlaylistItemSuccess;

      return {
        ...state,
        fetchPlaylistItemLoading: false,
        fetchPlaylistItemError: '',
        playlistItems: {
          ...state.playlistItems,
          [playlist.id]: playlist
        }
      };
    }
    case actions.FETCH_PLAYLIST_ITEM_FAILURE: {
      const { message } = action.payload as actions.FetchPlaylistItemFailure;
      return { ...state, fetchPlaylistItemLoading: false, fetchPlaylistItemError: message };
    }

    case actions.PLAYLIST_ADD_COURSES: {
      const { id, courses } = action.payload as actions.PlaylistAddCourses;
      const existingCourses = state.playlistCourses[id] || [];
      return {
        ...state,
        playlistDirty: true,
        playlistCourses: {
          ...state.playlistCourses,
          [id]: [...existingCourses, ...courses]
        }
      };
    }

    case actions.FETCH_PLAYLIST_COURSES: {
      return { ...state, fetchPlaylistItemCoursesLoading: true, fetchPlaylistItemCoursesError: '' };
    }

    case actions.FETCH_PLAYLIST_COURSES_SUCCESS: {
      const { courses, playlistId } = action.payload as actions.FetchPlaylistCoursesSuccess;

      return {
        ...state,
        fetchPlaylistItemCoursesLoading: false,
        fetchPlaylistItemCoursesError: '',
        playlistCourses: {
          ...state.playlistCourses,
          [playlistId]: [...courses] || []
        }
      };
    }

    case actions.FETCH_PLAYLIST_COURSES_FAILURE: {
      const { message } = action.payload as actions.FetchPlaylistCoursesFailure;
      return {
        ...state,
        fetchPlaylistItemCoursesLoading: false,
        fetchPlaylistItemCoursesError: message
      };
    }

    case actions.REMOVE_COURSE_FROM_PLAYLIST: {
      const { playlistId, courseId } = action.payload;
      const currentPlaylistCourses = state.playlistCourses[playlistId];
      const courses = currentPlaylistCourses.filter(course => course.id !== courseId) || [];
      return {
        ...state,
        playlistDirty: true,
        playlistCourses: {
          ...state.playlistCourses,
          [playlistId]: [...courses]
        }
      };
    }
    case actions.PLAYLIST_REORDER_COURSES: {
      const { id, courses } = action.payload;
      return {
        ...state,
        playlistDirty: true,
        playlistCourses: {
          ...state.playlistCourses,
          [id]: courses
        }
      };
    }

    case actions.PLAYLIST_FETCH_NEW_TRANSLATION: {
      return {
        ...state,
        fetchPlaylistTranslationLoading: true,
        fetchPlaylistTranslationError: ''
      };
    }

    case actions.PLAYLIST_FETCH_NEW_TRANSLATION_SUCCESS: {
      return {
        ...state,
        fetchPlaylistTranslationLoading: false,
        fetchPlaylistTranslationError: ''
      };
    }

    case actions.PLAYLIST_FETCH_NEW_TRANSLATION_FAILURE: {
      const { message } = action.payload as actions.SavePlaylistFailure;

      return {
        ...state,
        fetchPlaylistTranslationLoading: false,
        fetchPlaylistTranslationError: message
      };
    }

    case actions.PLAYLIST_LIST_CHANGE_LEXO_RANK: {
      const { playlists } = action.payload as actions.PlaylistListChangeLexoRank;
      return {
        ...state,
        playlists: [...playlists]
      };
    }

    default:
      return state;
  }
};
