import { fork, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import { RequestActions } from '@edapp/request';
import { routes } from '@rio/router';
import type { LmsStoreState } from '@rio/store/types';

import * as actions from './actions';
import { getBanner } from './selectors';
import type { Banner } from './types';

function* handleCreateBanner(action: Action<actions.CreateBanner>) {
  const { payload } = action;
  const { id, courseTitle, ...bannerData } = payload.banner;
  yield put(
    RequestActions.postAuthed(
      `api/banners`,
      actions.CREATE_BANNER_SUCCESS,
      actions.CREATE_BANNER_FAILURE,
      false,
      { ...bannerData }
    )
  );

  const { success, failure } = yield race({
    success: take(actions.CREATE_BANNER_SUCCESS),
    failure: take(actions.CREATE_BANNER_FAILURE)
  });

  if (failure) {
    // Handled in the reducer
    return;
  }

  const responseId = (success as Action<actions.CreateBannerSuccess>).payload;
  const { bannerType, isCustomised, destinationUrl } = bannerData;

  itly.bannerCreated({
    banner_type: bannerType,
    has_url: !!destinationUrl,
    auto_branding: bannerType === 'Course' && !isCustomised,
    uploaded_image: bannerType === 'Course' ? !!isCustomised : true
  });

  payload.history.replace(routes.banner.getRoute({ bannerId: responseId }));
}

function* handleFetchBannerItem(action: Action<actions.FetchBannerItem>) {
  const { payload } = action;

  yield put(
    RequestActions.getAuthed(
      `api/banners/${payload.bannerId}`,
      actions.FETCH_BANNER_ITEM_SUCCESS,
      actions.FETCH_BANNER_ITEM_FAILURE,
      false
    )
  );
}

function* handleFetchBanners(action: Action<actions.FetchBanners>) {
  const { page, pageSize, includeCourseBanners } = action.payload;

  yield put(
    RequestActions.getAuthed(
      `api/banners`,
      data => ({
        type: actions.FETCH_BANNERS_SUCCESS,
        payload: {
          ...data,
          page
        }
      }),
      actions.FETCH_BANNERS_FAILURE,
      false,
      { page, pageSize, includeCourseBanners }
    )
  );
}

function* handleRemoveBanner(action: Action<actions.RemoveBanner>) {
  const { payload } = action;
  yield put(
    RequestActions.deleteAuthed(
      `/api/banners/${payload.bannerId}`,
      actions.REMOVE_BANNER_SUCCESS,
      () => ({
        type: actions.REMOVE_BANNER_FAILURE,
        payload: {
          type: 'Error',
          message:
            'Oops! Something went wrong while trying to delete your banner. Please refresh your browser or click here.'
        }
      })
    )
  );
  itly.bannerDeleted({ banner_id: payload.bannerId });
}

function* handleSaveBannerIsPublished(action: Action<actions.SaveBannerIsPublished>) {
  const { bannerId, isPublished } = action.payload;

  yield put(
    RequestActions.patchAuthed(
      `/api/banners/${bannerId}`,
      actions.SAVE_BANNER_IS_PUBLISHED_SUCCESS,
      actions.SAVE_BANNER_IS_PUBLISHED_FAILURE,
      false,
      { isPublished: isPublished }
    )
  );
}

function* handleSaveBanner(action: Action<actions.SaveBanner>) {
  const { bannerId } = action.payload;

  const { courseTitle, ...bannerData }: Banner = yield select((store: LmsStoreState) =>
    getBanner(store, bannerId)
  );

  yield put(
    RequestActions.putAuthed(
      `/api/banners/${bannerId}`,
      actions.SAVE_BANNER_SUCCESS,
      actions.SAVE_BANNER_FAILURE,
      false,
      {
        ...bannerData
      }
    )
  );
  const { success } = yield race({
    success: take(actions.SAVE_BANNER_SUCCESS),
    failure: take(actions.SAVE_BANNER_FAILURE)
  });

  if (success) {
    const { bannerType, isCustomised, destinationUrl } = bannerData;
    itly.bannerUpdated({
      banner_id: bannerId,
      banner_type: bannerType,
      has_url: !!destinationUrl,
      auto_branding: bannerType === 'Course' && !isCustomised,
      uploaded_image: bannerType === 'Course' ? !!isCustomised : true
    });
  }
}

function* handleUpdateRank(action: Action<actions.UpdateRank>) {
  const { ids } = action.payload;

  yield put(
    RequestActions.putAuthed(
      `/api/banners/rank`,
      actions.UPDATE_RANK_SUCCESS,
      actions.UPDATE_RANK_FAILURE,
      false,
      { ids: ids }
    )
  );
}

function* watchCreateBanner() {
  yield takeLatest(actions.CREATE_BANNER, handleCreateBanner);
}

function* watchFetchBannerItem() {
  yield takeLatest(actions.FETCH_BANNER_ITEM, handleFetchBannerItem);
}

function* watchFetchBanners() {
  yield takeLatest(actions.FETCH_BANNERS, handleFetchBanners);
}

function* watchRemoveBanner() {
  yield takeLatest(actions.REMOVE_BANNER, handleRemoveBanner);
}

function* watchSaveBannerIsPublished() {
  yield takeLatest(actions.SAVE_BANNER_IS_PUBLISHED, handleSaveBannerIsPublished);
}

function* watchSaveBanner() {
  yield takeLatest(actions.SAVE_BANNER, handleSaveBanner);
}

function* watchUpdateRank() {
  yield takeLatest(actions.UPDATE_RANK, handleUpdateRank);
}

const sagas = [
  fork(watchCreateBanner),
  fork(watchFetchBannerItem),
  fork(watchFetchBanners),
  fork(watchRemoveBanner),
  fork(watchSaveBannerIsPublished),
  fork(watchSaveBanner),
  fork(watchUpdateRank)
];

export default sagas;
