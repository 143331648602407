import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Checkbox } from '@edapp/ed-components';
import type { LmsStoreState } from '@rio/store/types';
import { setUserGroupItem } from '@rio/store/userGroups/actions';

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

const Freeze: React.FunctionComponent<Props> = ({ freezeUsers, setUserGroupItem }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Checkbox
        checked={freezeUsers}
        onChange={e => setUserGroupItem({ usergroup: { freezeUsers: e.target.checked } })}
        title={t('user-groups.freeze-users.title')}
        description={t('user-groups.freeze-users.description')}
      />
    </div>
  );
};

const mapStateToProps = (state: LmsStoreState) => ({
  freezeUsers: state.userGroups.userGroupItem.usergroup.freezeUsers
});

const mapDispatchToProps = (dispatch: any) => ({
  setUserGroupItem: bindActionCreators(setUserGroupItem, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Freeze);
