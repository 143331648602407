import * as React from 'react';
import { components as SelectComponent } from 'react-select';
import type { ContainerProps } from 'react-select/src/components/containers';
import type { Option } from '../types';

export function SelectContainer<OptionValueType>(props: ContainerProps<Option<OptionValueType>>) {
  return (
    <SelectComponent.SelectContainer
      {...props}
      innerProps={
        {
          ...props.innerProps,
          'data-testid': props.selectProps.testId
        } as any
      }
    />
  );
}
