import { delay } from 'redux-saga';
import { fork, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { LessonDiscriminatorEnum, routes as authoringRoutes } from '@edapp/authoring-logic';
import { RequestActions } from '@edapp/request';
import { mergeDeep } from '@edapp/utils';

import type { Action, EdErrorResponseType } from '../types';
import * as actions from './actions';
import { initialCourseState } from './constants';
import { getCourse, getCourseById } from './selectors';
import type { CourseType } from './types';
import type { StoreState } from './types';

function* handleFetchCourse(action: Action<actions.FetchCourse>) {
  const { courseId } = action.payload;
  if (courseId === 'new') {
    return;
  }

  yield put(
    RequestActions.getAuthed(
      `/api/courses/${courseId}`,
      actions.FETCH_COURSE_SUCCESS,
      actions.FETCH_COURSE_FAILURE
    )
  );
}

function* handleUpdateCourseTags({
  payload: { courseId, tags }
}: Action<actions.UpdateCourseTags>) {
  yield put(
    RequestActions.putAuthed(
      `/api/courses/${courseId}/tags`,
      () => ({
        type: actions.UPDATE_COURSE_TAGS_SUCCESS,
        payload: {
          courseId,
          tags
        }
      }),
      actions.UPDATE_COURSE_TAGS_FAILURE,
      false,
      { tagIds: tags }
    )
  );
}

function* handleEnableGroupTrainingCourse({
  payload: { courseId, isGroupTrainingEnabled }
}: Action<actions.UpdateGroupTraining>) {
  yield put(
    RequestActions.putAuthed(
      `/api/courses/${courseId}/group-training`,
      () => ({
        type: actions.UPDATE_GROUP_TRAINING_SUCCESS,
        payload: {
          courseId,
          isGroupTrainingEnabled
        }
      }),
      actions.UPDATE_GROUP_TRAINING_FAILURE,
      false,
      { isEnabled: isGroupTrainingEnabled }
    )
  );
}

function* handleCreateCourse(action: Action<actions.CreateCourse>) {
  const { title, collectionId, history, description, image } = action.payload;

  const data: CourseType = {
    ...initialCourseState,
    title,
    description: description ?? '',
    brandingImage: image ?? '',
    libraryImage: image ?? ''
  };
  if (!!collectionId) {
    data.collectionId = collectionId;
  }

  yield put(
    RequestActions.postAuthed(
      `/api/courses`,
      actions.CREATE_COURSE_SUCCESS,
      actions.CREATE_COURSE_FAILURE,
      false,
      data
    )
  );

  const { success, failure } = yield race({
    success: take(actions.CREATE_COURSE_SUCCESS),
    failure: take(actions.CREATE_COURSE_FAILURE)
  });

  if (failure) {
    // It's being handled in the reducer
    return;
  }

  const { id } = (success as Action<actions.CreateCourseSuccess>).payload;
  const route = authoringRoutes.allInOneEditor.getRoute({ courseId: id });

  history.push(route);
}

function* handleSaveCourse(action: Action<actions.SaveCourse>) {
  yield delay(2000);

  const { courseId } = action.payload;
  const courseData: ReturnType<typeof getCourse> = yield select((s: StoreState) =>
    getCourse(s, courseId)
  );

  const mergedData = mergeDeep({}, courseData, action.payload.data);

  yield put(
    RequestActions.putAuthed<{}, {}, EdErrorResponseType, actions.SaveCourseFailure>(
      `/api/courses/${courseId}`,
      actions.SAVE_COURSE_SUCCESS,
      error => ({
        type: actions.SAVE_COURSE_FAILURE,
        payload: {
          courseId,
          error,
          courseStateBeforeSave: action.payload.oldCourseData
        }
      }),
      false,
      mergedData
    )
  );
}

function* handleSaveCourseLeaderboard(action: Action<actions.SaveCourseLeaderboard>) {
  const { courseId, isEnabled } = action.payload;

  const oldCourseData: ReturnType<typeof getCourse> = yield select((s: StoreState) =>
    getCourse(s, courseId)
  );

  yield put(
    RequestActions.postAuthed<{}, {}, EdErrorResponseType, actions.SaveCourseFailure>(
      `/api/v2/leaderBoard/enable`,
      data => ({
        type: actions.SAVE_COURSE_LEADERBOARD_SUCCESS,
        payload: { courseId, leaderboard: { id: data, isEnabled } }
      }),
      error => ({
        type: actions.SAVE_COURSE_FAILURE,
        payload: {
          courseId,
          error,
          courseStateBeforeSave: oldCourseData
        }
      }),
      false,
      { courseId, isEnabled }
    )
  );
}

function* handleSetCourse(action: Action<actions.SetCourse>) {
  const { courseId, data } = action.payload;

  // Reducer updates optimistically when set is called
  // Preserve data in case of failed save
  const oldCourseData: ReturnType<typeof getCourse> = yield select((s: StoreState) =>
    getCourse(s, courseId)
  );
  yield put(actions.saveCourse(courseId, data, oldCourseData));
}

function* handleSetCourseLeaderboard(action: Action<actions.SetCourseLeaderboard>) {
  const { courseId, isEnabled } = action.payload;

  const oldCourseData: ReturnType<typeof getCourse> = yield select((s: StoreState) =>
    getCourse(s, courseId)
  );
  yield put(actions.saveCourseLeaderboard(courseId, isEnabled, oldCourseData));
}

function* handleSetUnpublishCourse({
  payload: { courseId, status: prevStatus }
}: Action<actions.UnpublishCourse>) {
  yield put(
    RequestActions.postAuthed<
      undefined,
      undefined,
      EdErrorResponseType,
      actions.SetUnpublishCourseFailure
    >(
      `/api/courses/${courseId}/unpublish`,
      actions.SET_UNPUBLISH_COURSE_SUCCESS,
      error => ({
        type: actions.SET_UNPUBLISH_COURSE_FAILURE,
        payload: {
          courseId,
          prevStatus,
          error
        }
      }),
      false,
      {}
    )
  );
}

function getArchiveUriByLessonType(lessonType: LessonDiscriminatorEnum, lessonId: string) {
  const lessonTypeUrl: Record<LessonDiscriminatorEnum, string> = {
    [LessonDiscriminatorEnum.DISCUSSION]: `/api/discussions/${lessonId}`,
    [LessonDiscriminatorEnum.ASSESSMENT]: `/api/assessments/${lessonId}`,
    [LessonDiscriminatorEnum.OBSERVATION]: `/api/observations/${lessonId}`,
    [LessonDiscriminatorEnum.VIRTUAL_CLASSROOM]: `/api/conferences/${lessonId}`,
    [LessonDiscriminatorEnum.LESSON]: `/api/lessons/${lessonId}`
  };

  return lessonTypeUrl[lessonType];
}

function* handleDeleteLesson(action: Action<actions.DeleteLesson>) {
  const { lessonId, courseId, type } = action.payload;

  yield put(
    RequestActions.deleteAuthed<{}, actions.DeleteLessonSuccess>(
      getArchiveUriByLessonType(type, lessonId),
      () => ({
        type: actions.DELETE_LESSON_SUCCESS,
        payload: { courseId, lessonId, type }
      }),
      actions.DELETE_LESSON_FAILURE
    )
  );
}

// Course User Groups

// Set
function* handleSetCourseUserGroups(action: Action<actions.SetCourseUserGroups>) {
  const {
    courseId,
    data: { universalAccess, userGroups }
  } = action.payload;

  const oldCourseData: CourseType = yield select(getCourseById(courseId));
  yield put(actions.saveCourseUserGroups(courseId, { universalAccess, userGroups }, oldCourseData));
}

// Save
function* handleSaveCourseUserGroups(action: Action<actions.SaveCourseUserGroups>) {
  yield delay(1000);

  const { courseId, data, oldCourseData } = action.payload;
  const { universalAccess, userGroups } = data;

  yield put(
    RequestActions.putAuthed<{}, {}, EdErrorResponseType, actions.SaveCourseFailure>(
      `/api/courses/${courseId}/usergroups`,
      actions.SAVE_COURSE_USER_GROUPS_SUCCESS,
      error => ({
        type: actions.SAVE_COURSE_USER_GROUPS_FAILURE,
        payload: {
          courseId,
          error,
          courseStateBeforeSave: oldCourseData
        }
      }),
      false,
      { universalAccess, userGroups }
    )
  );
}

// Course Prerequisites

// Set
function* handleSetCoursePrerequisites(action: Action<actions.SetCoursePrerequisites>) {
  const {
    courseId,
    data: { prerequisites }
  } = action.payload;

  const oldCourseData: CourseType = yield select(getCourseById(courseId));
  yield put(actions.saveCoursePrerequisites(courseId, { prerequisites }, oldCourseData));
}

// Save
function* handleSaveCoursePrerequisites(action: Action<actions.SaveCoursePrerequisites>) {
  yield delay(1000);

  const { courseId, data, oldCourseData } = action.payload;
  const { prerequisites } = data;

  yield put(
    RequestActions.putAuthed<{}, {}, EdErrorResponseType, actions.SaveCourseFailure>(
      `/api/courses/${courseId}/prerequisites`,
      actions.SAVE_COURSE_PREREQUISITES_SUCCESS,
      error => ({
        type: actions.SAVE_COURSE_PREREQUISITES_FAILURE,
        payload: {
          courseId,
          error,
          courseStateBeforeSave: oldCourseData
        }
      }),
      false,
      { prerequisites }
    )
  );
}

function* watchFetchCourse() {
  yield takeLatest(actions.FETCH_COURSE, handleFetchCourse);
}

function* watchUpdateCourseTags() {
  yield takeLatest(actions.UPDATE_COURSE_TAGS, handleUpdateCourseTags);
}

function* watchCreateCourse() {
  yield takeLatest(actions.CREATE_COURSE, handleCreateCourse);
}

function* watchSaveCourse() {
  yield takeLatest(actions.SAVE_COURSE, handleSaveCourse);
}

function* watchSaveCourseLeaderboard() {
  yield takeLatest(actions.SAVE_COURSE_LEADERBOARD, handleSaveCourseLeaderboard);
}

function* watchSetCourse() {
  yield takeLatest(actions.SET_COURSE, handleSetCourse);
}

function* watchSetCourseLeaderboard() {
  yield takeLatest(actions.SET_COURSE_LEADERBOARD, handleSetCourseLeaderboard);
}

function* watchSetUnpublishCourse() {
  yield takeLatest(actions.SET_UNPUBLISH_COURSE, handleSetUnpublishCourse);
}

function* watchDeleteLesson() {
  yield takeLatest(actions.DELETE_LESSON, handleDeleteLesson);
}

function* watchSetCourseUserGroups() {
  yield takeLatest(actions.SET_COURSE_USER_GROUPS, handleSetCourseUserGroups);
}

function* watchSaveCourseUserGroups() {
  yield takeLatest(actions.SAVE_COURSE_USER_GROUPS, handleSaveCourseUserGroups);
}

function* watchSetCoursePrerequisites() {
  yield takeLatest(actions.SET_COURSE_PREREQUISITES, handleSetCoursePrerequisites);
}

function* watchSaveCoursePrerequisites() {
  yield takeLatest(actions.SAVE_COURSE_PREREQUISITES, handleSaveCoursePrerequisites);
}

function* watchEnableGroupTrainingCourse() {
  yield takeLatest(actions.UPDATE_GROUP_TRAINING, handleEnableGroupTrainingCourse);
}

const sagas = [
  fork(watchFetchCourse),
  fork(watchUpdateCourseTags),
  fork(watchCreateCourse),
  fork(watchSaveCourse),
  fork(watchSaveCourseLeaderboard),
  fork(watchSetCourse),
  fork(watchSetCourseLeaderboard),
  fork(watchSetUnpublishCourse),
  fork(watchDeleteLesson),
  fork(watchSetCourseUserGroups),
  fork(watchSaveCourseUserGroups),
  fork(watchSetCoursePrerequisites),
  fork(watchSaveCoursePrerequisites),
  fork(watchEnableGroupTrainingCourse)
];

export default sagas;
