import * as React from 'react';
import styled from 'styled-components';
import SelectableListItem from './SelectableListItem';
import { Typography } from '../../../typography/Typography';
import type { Option } from '../types';

type Props<OptionValue> = {
  option: Option<OptionValue>;
  disabled?: boolean;
  labelIconStyle?: React.CSSProperties;
  testId?: string;
  onClick: (item: Option<OptionValue>) => void;
};

// The component takes a type argument to know the type of the option values passed in
export function SelectableListOption<OptionValue>({
  option,
  disabled,
  labelIconStyle,
  testId,
  onClick
}: Props<OptionValue>) {
  const isOptionDisabled = disabled || option.isDisabled;
  const handleOnClick = isOptionDisabled ? undefined : () => onClick(option);

  return (
    <SelectableListItem
      as="li"
      key={option.key}
      onClick={handleOnClick}
      isDisabled={isOptionDisabled}
      testId={testId}
    >
      {option.labelIcon && <option.labelIcon size="sm" color="textMuted" style={labelIconStyle} />}
      <StyledTypography variant="normal">{option.label}</StyledTypography>
    </SelectableListItem>
  );
}

const StyledTypography = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
