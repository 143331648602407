import * as React from 'react';
import { Typography } from '@edapp/ed-components';
import { Countdown } from '../shared';

type Props = {
  date: string;
} & React.ComponentProps<typeof Typography>;

export const LessonLimitResetCountdown: React.FC<Props> = ({ date, ...rest }) => {
  const renderFn = React.useCallback((daysRemaining: string) => {
    return (
      <Typography as="div" variant="subtext" {...rest}>
        Monthly limit resets in {daysRemaining}
      </Typography>
    );
  }, []);

  return <Countdown date={date}>{renderFn}</Countdown>;
};
