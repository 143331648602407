import * as React from 'react';

import type { FieldValidator } from 'final-form';
import styled from 'styled-components';

import type { Input } from '@edapp/ed-components';
import { EyeClosedIcon, EyeOpenIcon } from '@edapp/ed-components';
import { FormInput } from '@rio/components/form/FormInput';

type Props = {
  validate?: FieldValidator<string>;
  showError?: boolean;
} & Omit<React.ComponentProps<typeof Input>, 'value' | 'inputSuffix' | 'type'>;

export const PasswordFormInput: React.FC<Props> = ({
  name = 'password',
  validate,
  placeholder = 'Password',
  testId = 'password-input',
  isFullWidth = true,
  ...props
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const onShowPassword = () => setShowPassword(true);
  const onHidePassword = () => setShowPassword(false);

  return (
    <FormInput
      name={name}
      type={showPassword ? 'text' : 'password'}
      isFullWidth={isFullWidth}
      testId={testId}
      placeholder={placeholder}
      validate={validate}
      inputSuffix={
        showPassword ? (
          <HidePasswordButton color="text" onClick={onHidePassword} />
        ) : (
          <ShowPasswordButton color="text" onClick={onShowPassword} />
        )
      }
      {...props}
    />
  );
};

const ShowPasswordButton = styled(EyeClosedIcon)`
  :hover {
    cursor: pointer;
  }
`;

const HidePasswordButton = styled(EyeOpenIcon)`
  :hover {
    cursor: pointer;
  }
`;
