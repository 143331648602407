import type { LessonType } from '@edapp/authoring-logic';
import { initialLesson } from '@edapp/authoring-logic';

import type { StoreState } from './types';

export const getLesson = (state: StoreState, lessonId: string): LessonType => {
  const lesson = state.lesson.lessons[lessonId];

  if (!lesson) {
    return { ...initialLesson };
  }

  return { ...lesson.original };
};

export const getLessonFetchLoading = (state: StoreState) => state.lesson.lessonFetchLoading;

export const getLessonMutateLoading = (state: StoreState) => state.lesson.lessonMutateLoading;

export const getLessonMutateError = (state: StoreState) => state.lesson.lessonMutateError;

export const getCopyLessonLoading = (state: StoreState) => state.lesson.copyLessonLoading;

export const getCopyLessonError = (state: StoreState) => state.lesson.copyLessonError;
