import { matchPath } from 'react-router';
import startCase from 'lodash-es/startCase';

import type { Options } from './types';

/**
 *
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#page
 *
 * @param {string} name- Optional, default use document.title
 * @param {string} category - Optional
 * @param {Object} properties - Optional
 * @param {SegmentAnalytics.SegmentOpts} options - Optional
 * @param {Function} callback - Optional
 *
 */
function page(options?: Options) {
  if (window.analytics) {
    window.analytics.page(
      options?.category,
      options?.name ?? document.title,
      options?.properties,
      // Later if we have, we can put universal shared options as default
      options?.options,
      options?.callback
    );
  } else {
    options?.callback?.();
  }
}

/**
 *
 * @param {string} pathname - current pathname from history
 * @param {Object} maps - key-value mapping of routing name and path
 */
function getPageName(pathname: string, maps?: Record<string, string>) {
  if (maps) {
    const matchedRoute = Object.entries(maps).find(map =>
      matchPath(pathname, { path: map[1], exact: true })
    );

    if (matchedRoute?.[0]) {
      return startCase(matchedRoute?.[0]);
    }
  }
}

export { page, getPageName };
