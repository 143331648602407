export function downloadBlob(blob: Blob, filename: string): void {
  downloadUrl(window.URL.createObjectURL(blob), filename);
}

export function downloadUrl(url: string, filename: string, targetSelf?: boolean): void {
  const link = document.createElement('a');
  link.href = url;
  link.target = targetSelf ? '_self' : '_blank';
  link.download = filename;
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
