import type { ActionWithPayload, ActionsUnion } from '@edapp/utils';
import { createAction } from '@edapp/utils';
import type { CoursePublicDetailCourseLink } from './types';
import type { EdErrorResponseType } from '../../../../types';

export enum ActionType {
  FETCH_COURSE_PUBLIC_DETAIL_COURSE_LINKS = 'FETCH_COURSE_PUBLIC_DETAIL_COURSE_LINKS',
  FETCH_COURSE_PUBLIC_DETAIL_COURSE_LINKS_SUCCESS = 'FETCH_COURSE_PUBLIC_DETAIL_COURSE_LINKS_SUCCESS',
  FETCH_COURSE_PUBLIC_DETAIL_COURSE_LINKS_FAILURE = 'FETCH_COURSE_PUBLIC_DETAIL_COURSE_LINKS_FAILURE',

  CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINK = 'CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINK',
  CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINK_SUCCESS = 'CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINK_SUCCESS',
  CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINK_FAILURE = 'CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINK_FAILURE',

  CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINKS = 'CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINKS',
  CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINKS_SUCCESS = 'CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINKS_SUCCESS',
  CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINKS_FAILURE = 'CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINKS_FAILURE',

  DELETE_COURSE_PUBLIC_DETAIL_COURSE_LINK = 'DELETE_COURSE_PUBLIC_DETAIL_COURSE_LINK',
  DELETE_COURSE_PUBLIC_DETAIL_COURSE_LINK_SUCCESS = 'DELETE_COURSE_PUBLIC_DETAIL_COURSE_LINK_SUCCESS',
  DELETE_COURSE_PUBLIC_DETAIL_COURSE_LINK_FAILURE = 'DELETE_COURSE_PUBLIC_DETAIL_COURSE_LINK_FAILURE'
}

type FetchCoursePublicDetailCourseLinks = { courseId: string; publicDetailId: string };
export type FetchCoursePublicDetailCourseLinksAction = ActionWithPayload<
  ActionType.FETCH_COURSE_PUBLIC_DETAIL_COURSE_LINKS,
  FetchCoursePublicDetailCourseLinks
>;

type FetchCoursePublicDetailCourseLinksSuccess = {
  publicDetailId: string;
  courseLinks: CoursePublicDetailCourseLink[];
};
export type FetchCoursePublicDetailCourseLinksSuccessAction = ActionWithPayload<
  ActionType.FETCH_COURSE_PUBLIC_DETAIL_COURSE_LINKS_SUCCESS,
  FetchCoursePublicDetailCourseLinksSuccess
>;

type FetchCoursePublicDetailCourseLinksFailure = EdErrorResponseType;
export type FetchCoursePublicDetailCourseLinksFailureAction = ActionWithPayload<
  ActionType.FETCH_COURSE_PUBLIC_DETAIL_COURSE_LINKS_FAILURE,
  FetchCoursePublicDetailCourseLinksFailure
>;

type CreateCoursePublicDetailCourseLink = {
  courseId: string;
  publicDetailId: string;
  linkCourseId: string;
};
export type CreateCoursePublicDetailCourseLinkAction = ActionWithPayload<
  ActionType.CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINK,
  CreateCoursePublicDetailCourseLink
>;

type CreateCoursePublicDetailCourseLinkSuccess = CoursePublicDetailCourseLink;
export type CreateCoursePublicDetailCourseLinkSuccessAction = ActionWithPayload<
  ActionType.CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINK_SUCCESS,
  CreateCoursePublicDetailCourseLinkSuccess
>;

type CreateCoursePublicDetailCourseLinkFailure = EdErrorResponseType;
export type CreateCoursePublicDetailCourseLinkFailureAction = ActionWithPayload<
  ActionType.CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINK_FAILURE,
  CreateCoursePublicDetailCourseLinkFailure
>;

type CreateCoursePublicDetailCourseLinks = {
  courseId: string;
  publicDetailId: string;
  linkCourseIds: string[];
};
export type CreateCoursePublicDetailCourseLinksAction = ActionWithPayload<
  ActionType.CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINKS,
  CreateCoursePublicDetailCourseLinks
>;

type CreateCoursePublicDetailCourseLinksSuccess = {
  publicDetailId: string;
  courseLinks: CoursePublicDetailCourseLink[];
};
export type CreateCoursePublicDetailCourseLinksSuccessAction = ActionWithPayload<
  ActionType.CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINKS_SUCCESS,
  CreateCoursePublicDetailCourseLinksSuccess
>;

type CreateCoursePublicDetailCourseLinksFailure = EdErrorResponseType;
export type CreateCoursePublicDetailCourseLinksFailureAction = ActionWithPayload<
  ActionType.CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINKS_FAILURE,
  CreateCoursePublicDetailCourseLinksFailure
>;

type DeleteCoursePublicDetailCourseLink = {
  courseId: string;
  publicDetailId: string;
  courseLinkId: string;
};
export type DeleteCoursePublicDetailCourseLinkAction = ActionWithPayload<
  ActionType.DELETE_COURSE_PUBLIC_DETAIL_COURSE_LINK,
  DeleteCoursePublicDetailCourseLink
>;

type DeleteCoursePublicDetailCourseLinkSuccess = { publicDetailId: string; id: string };
export type DeleteCoursePublicDetailCourseLinkSuccessAction = ActionWithPayload<
  ActionType.DELETE_COURSE_PUBLIC_DETAIL_COURSE_LINK_SUCCESS,
  DeleteCoursePublicDetailCourseLinkSuccess
>;

type DeleteCoursePublicDetailCourseLinkFailure = EdErrorResponseType;
export type DeleteCoursePublicDetailCourseLinkFailureAction = ActionWithPayload<
  ActionType.DELETE_COURSE_PUBLIC_DETAIL_COURSE_LINK_FAILURE,
  DeleteCoursePublicDetailCourseLinkFailure
>;

export const actions = {
  fetchCoursePublicDetailCourseLinks: (
    payload: FetchCoursePublicDetailCourseLinks
  ): FetchCoursePublicDetailCourseLinksAction =>
    createAction(ActionType.FETCH_COURSE_PUBLIC_DETAIL_COURSE_LINKS, payload),
  fetchCoursePublicDetailCourseLinksSuccess: (
    payload: FetchCoursePublicDetailCourseLinksSuccess
  ): FetchCoursePublicDetailCourseLinksSuccessAction =>
    createAction(ActionType.FETCH_COURSE_PUBLIC_DETAIL_COURSE_LINKS_SUCCESS, payload),
  fetchCoursePublicDetailCourseLinksFailure: (
    payload: FetchCoursePublicDetailCourseLinksFailure
  ): FetchCoursePublicDetailCourseLinksFailureAction =>
    createAction(ActionType.FETCH_COURSE_PUBLIC_DETAIL_COURSE_LINKS_FAILURE, payload),

  createCoursePublicDetailCourseLink: (
    payload: CreateCoursePublicDetailCourseLink
  ): CreateCoursePublicDetailCourseLinkAction =>
    createAction(ActionType.CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINK, payload),
  createCoursePublicDetailCourseLinkSuccess: (
    payload: CreateCoursePublicDetailCourseLinkSuccess
  ): CreateCoursePublicDetailCourseLinkSuccessAction =>
    createAction(ActionType.CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINK_SUCCESS, payload),
  createCoursePublicDetailCourseLinkFailure: (
    payload: CreateCoursePublicDetailCourseLinkFailure
  ): CreateCoursePublicDetailCourseLinkFailureAction =>
    createAction(ActionType.CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINK_FAILURE, payload),

  createCoursePublicDetailCourseLinks: (
    payload: CreateCoursePublicDetailCourseLinks
  ): CreateCoursePublicDetailCourseLinksAction =>
    createAction(ActionType.CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINKS, payload),
  createCoursePublicDetailCourseLinksSuccess: (
    payload: CreateCoursePublicDetailCourseLinksSuccess
  ): CreateCoursePublicDetailCourseLinksSuccessAction =>
    createAction(ActionType.CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINKS_SUCCESS, payload),
  createCoursePublicDetailCourseLinksFailure: (
    payload: CreateCoursePublicDetailCourseLinksFailure
  ): CreateCoursePublicDetailCourseLinksFailureAction =>
    createAction(ActionType.CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINKS_FAILURE, payload),

  deleteCoursePublicDetailCourseLink: (
    payload: DeleteCoursePublicDetailCourseLink
  ): DeleteCoursePublicDetailCourseLinkAction =>
    createAction(ActionType.DELETE_COURSE_PUBLIC_DETAIL_COURSE_LINK, payload),
  deleteCoursePublicDetailCourseLinkSuccess: (
    payload: DeleteCoursePublicDetailCourseLinkSuccess
  ): DeleteCoursePublicDetailCourseLinkSuccessAction =>
    createAction(ActionType.DELETE_COURSE_PUBLIC_DETAIL_COURSE_LINK_SUCCESS, payload),
  deleteCoursePublicDetailCourseLinkFailure: (
    payload: DeleteCoursePublicDetailCourseLinkFailure
  ): DeleteCoursePublicDetailCourseLinkFailureAction =>
    createAction(ActionType.DELETE_COURSE_PUBLIC_DETAIL_COURSE_LINK_FAILURE, payload)
};

export type ActionTypes = ActionsUnion<typeof actions>;
