import * as React from 'react';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Box, ButtonLink, MailIcon, Typography } from '@edapp/ed-components';
import { rgba, useAppearance } from '@edapp/themes';
import PlanPermissionWrapper from '@rio/components/common/PermissionWrapper/PlanPermissionWrapper';

const SideFeedback: React.FC = () => {
  const { t } = useTranslation();
  const { isDarkMode } = useAppearance();

  return (
    <>
      <PlanPermissionWrapper accessPermission="ACCESS_FEEDBACK">
        <StyledBox flex={true} flexDirection="column" rounded boxShadow="deep">
          <StyledSliderButton
            href="https://safetyculture.com/edapp-product-feedback/"
            target="_blank"
            variant="dark"
            isDarkMode={isDarkMode}
          >
            <Box flex={true} justifyContent="center" alignItems="center">
              <Box pr={0.8} flex={true} justifyContent="center" alignItems="center">
                <MailIcon width={16} height={16} color={isDarkMode ? 'text' : 'white'} />
              </Box>
              <Typography>{t('product-feedback.title')}</Typography>
            </Box>
          </StyledSliderButton>
        </StyledBox>
      </PlanPermissionWrapper>
    </>
  );
};

const StyledSliderButton = styled(ButtonLink)<{ isDarkMode: boolean }>`
  background-color: ${({ theme }) => theme.colors.navy};
  transition: background-color 300ms;
  padding: 0;
  margin: 0;
  border: none;
  border-radius: ${({ theme }) => theme.sizes.borderRadius}px;

  :hover {
    background-color: ${({ theme }) => theme.colors.navyHover};
  }

  height: 100%;
  width: 100%;
  padding: ${({ theme }) => theme.space()}px;
  text-align: center;
  color: ${({ theme }) => rgba(theme.colors.white, 0.85)};
  font-weight: 300;
  transition: color 300ms;

  :hover,
  :focus,
  :active {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.white};
  }
  ${Typography} {
    color: ${({ theme, isDarkMode }) => theme.colors[isDarkMode ? 'text' : 'white']};
  }
`;
const StyledBox = styled(Box)`
  transform: rotate(-90deg);
  position: fixed;
  top: 45%;
  right: -38px;
  background-color: ${({ theme }) => theme.colors.navy};
  transition: background-color 300ms;

  :hover {
    background-color: ${({ theme }) => theme.colors.navyHover};
  }

  @media (max-width: 1079px) {
    display: none;
  }
`;

export default SideFeedback;
