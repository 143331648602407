/**
 * For displaying a confirmation prompt when leaving a page.
 * Accepts a callback that can conditionally display the prompt.
 */

import { useRef, useEffect } from 'react';

type Callback = (
  unloadEvent: BeforeUnloadEvent
) => string | boolean | null | undefined | Promise<any>;

export function useBeforeUnload(callback: Callback): void {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handleBeforeUnload = (unloadEvent: BeforeUnloadEvent) => {
      const result = callbackRef.current(unloadEvent);

      if (!result) {
        return;
      }

      unloadEvent.preventDefault();
      unloadEvent.returnValue = '';

      if (typeof result === 'string') {
        unloadEvent.returnValue = result;
        return result;
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  });
}
