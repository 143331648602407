import type { ActionWithPayload, ActionsUnion } from '@edapp/utils';
import { createAction } from '@edapp/utils';
import type { CourseTopic } from './types';
import type { EdErrorResponseType } from '../../../types';

export enum ActionType {
  FETCH_COURSE_TOPICS = 'FETCH_COURSE_TOPICS',
  FETCH_COURSE_TOPICS_SUCCESS = 'FETCH_COURSE_TOPICS_SUCCESS',
  FETCH_COURSE_TOPICS_FAILURE = 'FETCH_COURSE_TOPICS_FAILURE',

  CREATE_COURSE_TOPIC = 'CREATE_COURSE_TOPIC',
  CREATE_COURSE_TOPIC_SUCCESS = 'CREATE_COURSE_TOPIC_SUCCESS',
  CREATE_COURSE_TOPIC_FAILURE = 'CREATE_COURSE_TOPIC_FAILURE',

  UPDATE_COURSE_TOPIC = 'UPDATE_COURSE_TOPIC',
  UPDATE_COURSE_TOPIC_SUCCESS = 'UPDATE_COURSE_TOPIC_SUCCESS',
  UPDATE_COURSE_TOPIC_FAILURE = 'UPDATE_COURSE_TOPIC_FAILURE',

  DELETE_COURSE_TOPIC = 'DELETE_COURSE_TOPIC',
  DELETE_COURSE_TOPIC_SUCCESS = 'DELETE_COURSE_TOPIC_SUCCESS',
  DELETE_COURSE_TOPIC_FAILURE = 'DELETE_COURSE_TOPIC_FAILURE'
}

type FetchCourseTopics = { courseId: string };
export type FetchCourseTopicsAction = ActionWithPayload<
  ActionType.FETCH_COURSE_TOPICS,
  FetchCourseTopics
>;

type FetchCourseTopicsSuccess = CourseTopic[];
export type FetchCourseTopicsSuccessAction = ActionWithPayload<
  ActionType.FETCH_COURSE_TOPICS_SUCCESS,
  FetchCourseTopicsSuccess
>;

type FetchCourseTopicsFailure = EdErrorResponseType;
export type FetchCourseTopicsFailureAction = ActionWithPayload<
  ActionType.FETCH_COURSE_TOPICS_FAILURE,
  FetchCourseTopicsFailure
>;

type CreateCourseTopic = { courseId: string; title: string };
export type CreateCourseTopicAction = ActionWithPayload<
  ActionType.CREATE_COURSE_TOPIC,
  CreateCourseTopic
>;

type CreateCourseTopicSuccess = CourseTopic;
export type CreateCourseTopicSuccessAction = ActionWithPayload<
  ActionType.CREATE_COURSE_TOPIC_SUCCESS,
  CreateCourseTopicSuccess
>;

type CreateCourseTopicFailure = EdErrorResponseType;
export type CreateCourseTopicFailureAction = ActionWithPayload<
  ActionType.CREATE_COURSE_TOPIC_FAILURE,
  CreateCourseTopicFailure
>;

type UpdateCourseTopic = { id: string; courseId: string; title: string };
export type UpdateCourseTopicAction = ActionWithPayload<
  ActionType.UPDATE_COURSE_TOPIC,
  UpdateCourseTopic
>;

type UpdateCourseTopicSuccess = CourseTopic;
export type UpdateCourseTopicSuccessAction = ActionWithPayload<
  ActionType.UPDATE_COURSE_TOPIC_SUCCESS,
  UpdateCourseTopicSuccess
>;

type UpdateCourseTopicFailure = EdErrorResponseType;
export type UpdateCourseTopicFailureAction = ActionWithPayload<
  ActionType.UPDATE_COURSE_TOPIC_FAILURE,
  UpdateCourseTopicFailure
>;

type DeleteCourseTopic = { id: string; courseId: string };
export type DeleteCourseTopicAction = ActionWithPayload<
  ActionType.DELETE_COURSE_TOPIC,
  DeleteCourseTopic
>;

type DeleteCourseTopicSuccess = { id: string };
export type DeleteCourseTopicSuccessAction = ActionWithPayload<
  ActionType.DELETE_COURSE_TOPIC_SUCCESS,
  DeleteCourseTopicSuccess
>;

type DeleteCourseTopicFailure = EdErrorResponseType;
export type DeleteCourseTopicFailureAction = ActionWithPayload<
  ActionType.DELETE_COURSE_TOPIC_FAILURE,
  EdErrorResponseType
>;

export const actions = {
  fetchCourseTopics: (payload: FetchCourseTopics): FetchCourseTopicsAction =>
    createAction(ActionType.FETCH_COURSE_TOPICS, payload),
  fetchCourseTopicsSuccess: (payload: FetchCourseTopicsSuccess): FetchCourseTopicsSuccessAction =>
    createAction(ActionType.FETCH_COURSE_TOPICS_SUCCESS, payload),
  fetchCourseTopicsFailure: (payload: FetchCourseTopicsFailure): FetchCourseTopicsFailureAction =>
    createAction(ActionType.FETCH_COURSE_TOPICS_FAILURE, payload),

  createCourseTopic: (payload: CreateCourseTopic): CreateCourseTopicAction =>
    createAction(ActionType.CREATE_COURSE_TOPIC, payload),
  createCourseTopicSuccess: (payload: CreateCourseTopicSuccess): CreateCourseTopicSuccessAction =>
    createAction(ActionType.CREATE_COURSE_TOPIC_SUCCESS, payload),
  createCourseTopicFailure: (payload: CreateCourseTopicFailure): CreateCourseTopicFailureAction =>
    createAction(ActionType.CREATE_COURSE_TOPIC_FAILURE, payload),

  updateCourseTopic: (payload: UpdateCourseTopic): UpdateCourseTopicAction =>
    createAction(ActionType.UPDATE_COURSE_TOPIC, payload),
  updateCourseTopicSuccess: (payload: UpdateCourseTopicSuccess): UpdateCourseTopicSuccessAction =>
    createAction(ActionType.UPDATE_COURSE_TOPIC_SUCCESS, payload),
  updateCourseTopicFailure: (payload: UpdateCourseTopicFailure): UpdateCourseTopicFailureAction =>
    createAction(ActionType.UPDATE_COURSE_TOPIC_FAILURE, payload),

  deleteCourseTopic: (payload: DeleteCourseTopic): DeleteCourseTopicAction =>
    createAction(ActionType.DELETE_COURSE_TOPIC, payload),
  deleteCourseTopicSuccess: (payload: DeleteCourseTopicSuccess): DeleteCourseTopicSuccessAction =>
    createAction(ActionType.DELETE_COURSE_TOPIC_SUCCESS, payload),
  deleteCourseTopicFailure: (payload: DeleteCourseTopicFailure): DeleteCourseTopicFailureAction =>
    createAction(ActionType.DELETE_COURSE_TOPIC_FAILURE, payload)
};

export type ActionTypes = ActionsUnion<typeof actions>;
