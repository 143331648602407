import type { ColorsKeys } from '@edapp/themes';
import * as React from 'react';
import styled, { css } from 'styled-components';
import type { IconComponent } from '../../icons/base/BaseIcon';
import { CloseDialogButton } from '../CloseDialogButton';
import type { Dialog } from '../Dialog';
import { Body } from './layout/Body';
import { Footer } from './layout/Footer';
import { Header } from './layout/Header';
import { ResponsiveDialog } from '../../responsive-dialog/ResponsiveDialog';
import { useBreakpointMatchMedia } from '../../../hooks/useMatchMedia';

export const exitTestId = 'dialog-header-exit';

type DialogProps = React.ComponentProps<typeof Dialog>;

type DialogPosition = 'top' | 'middle' | 'bottom';

export type Props = {
  /** What text's displayed in the dialog header */
  headerIcon?: IconComponent;
  headerIconColor?: ColorsKeys;
  /** What's displayed in the dialog body */
  bodyContents: React.ReactElement;
  /** What's displayed in the dialog footer */
  footer?: React.ReactElement;
  /** Dialog Position */
  position?: DialogPosition;
  /** Hide the x to close button */
  hideCloseX?: boolean;
} & Omit<DialogProps, 'children'>;

export const DialogContent: React.FC<Props> = ({
  isOpen,
  useBackdrop,
  usePortal,
  isClosable = true,
  onClose,
  headerIcon,
  headerIconColor,
  bodyContents,
  footer,
  size = 'sm',
  hideCloseX = false,
  ...rest
}) => {
  const isMobile = useBreakpointMatchMedia('sm', 'max-width');

  return (
    <StyledContainer
      isOpen={isOpen}
      useBackdrop={useBackdrop}
      usePortal={usePortal}
      onClose={onClose}
      isClosable={isClosable}
      size={size}
      {...rest}
    >
      {isClosable && !isMobile && !hideCloseX && <CloseDialogButton testId={exitTestId} onClick={onClose} />}
      {!!headerIcon && <Header icon={headerIcon} iconColor={headerIconColor} />}
      <Body>{bodyContents}</Body>
      {footer && <Footer>{footer}</Footer>}
    </StyledContainer>
  );
};

const StyledContainer = styled(ResponsiveDialog)<Partial<Props>>(
  ({ theme, position }) => css`
    text-align: center;
    display: flex;
    flex-direction: column;

    // tablet - desktop
    @media (min-width: ${theme.breakpoints.sm}rem) {
      ${() => {
        switch (position) {
          case 'top':
            return css`
              top: 20%;
              border-top-left-radius: 8px;
              border-top-right-radius: 8px;
            `;
          case 'bottom':
            return css`
              top: auto;
              bottom: 20%;
              border-bottom-left-radius: 8px;
              border-bottom-right-radius: 8px;
            `;
          default:
            return css`
              top: 50%;
            `;
        }
      }}
    }
    // Legacy ipad need to get the bottom mobile view exactly 768x1024
    @media (max-width: ${theme.breakpoints.sm}rem) {
      top: auto;
    }
  `
);
