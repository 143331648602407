import { isEmpty, size } from 'lodash-es';
import { createSelector } from 'reselect';

import type { LmsStoreState } from '@rio/store/types';

import type { AnalyticsData } from '../types/types';
import { COURSE_STATUSES, SURVEY_TYPES } from './constants';
import type { Surveys, SurveysFilterState } from './types';

type DataProps = AnalyticsData<Surveys>;

const getSurveysData = (state: LmsStoreState) => state.analytics.surveys.data;

export const getSurveysFilterState = (state: LmsStoreState) => state.analytics.surveys.filter;

const selectors = {
  getSurveys: createSelector(getSurveysData, (data: DataProps) =>
    Object.values(data.items).map(survey => ({
      ...survey,
      lessons: Object.values(survey.lessons)
    }))
  ),

  getHasMoreSurveys: createSelector(
    getSurveysData,
    (data: DataProps) => size(data.items) < data.totalCount
  ),

  getIsNoSurveysFound: createSelector(
    getSurveysData,
    (data: DataProps) => !data.isLoading && isEmpty(data.items)
  ),

  getSurveysIsLoading: createSelector(getSurveysData, (data: DataProps) => data.isLoading),

  getSurveysTotalCount: createSelector(getSurveysData, (data: DataProps) => data.totalCount),

  getSurveysPage: createSelector(getSurveysData, (data: DataProps) => data.page),

  getSurveysPageSize: createSelector(getSurveysData, (data: DataProps) => data.pageSize),

  getIsFilterApplied: createSelector(
    getSurveysFilterState,
    (filter: SurveysFilterState) =>
      !!filter.searchTerm ||
      filter.courseStatus !== COURSE_STATUSES.ALL.value ||
      filter.surveyType !== SURVEY_TYPES.ALL.value
  )
};

export const {
  getSurveys,
  getHasMoreSurveys,
  getIsNoSurveysFound,
  getSurveysIsLoading,
  getSurveysTotalCount,
  getSurveysPage,
  getSurveysPageSize,
  getIsFilterApplied
} = selectors;

export default selectors;
