import { ActionDialog } from '@edapp/ed-components';
import React from 'react';

type Props = {
  onConfirm: () => void;
  onClose: () => void;
  onCancel: () => void;
  isSaving: boolean;
  isOpen: boolean;
};
export const UnsavedChangesWarningDialog: React.FC<Props> = ({
  onConfirm,
  onClose,
  onCancel,
  isOpen,
  isSaving
}) => (
  <ActionDialog
    isOpen={isOpen}
    onClose={onClose}
    onCancel={onCancel}
    title="Leave page without saving?"
    description="All changes will be lost. Do you want to save changes before leaving?"
    onConfirm={onConfirm}
    cancelText="Leave without saving"
    confirmText={isSaving ? 'Saving...' : 'Save changes'}
    disableConfirmButton={isSaving}
  />
);
