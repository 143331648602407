import type { ActionWithPayload, ActionsUnion } from '@edapp/utils';
import { createAction } from '@edapp/utils';
import type { CoursePublicDetailExcludedSlide } from './types';
import type { EdErrorResponseType } from '../../../../types';

export enum ActionType {
  FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDES = 'FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDES',
  FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDES_SUCCESS = 'FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDES_SUCCESS',
  FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDES_FAILURE = 'FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDES_FAILURE',

  CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE = 'CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE',
  CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_SUCCESS = 'CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_SUCCESS',
  CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_FAILURE = 'CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_FAILURE',

  DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE = 'DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE',
  DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_SUCCESS = 'DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_SUCCESS',
  DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_FAILURE = 'DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_FAILURE'
}

type FetchCoursePublicDetailExcludedSlides = { courseId: string; publicDetailId: string };
export type FetchCoursePublicDetailExcludedSlidesAction = ActionWithPayload<
  ActionType.FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDES,
  FetchCoursePublicDetailExcludedSlides
>;

type FetchCoursePublicDetailExcludedSlidesSuccess = {
  publicDetailId: string;
  excludedSlides: CoursePublicDetailExcludedSlide[];
};
export type FetchCoursePublicDetailExcludedSlidesSuccessAction = ActionWithPayload<
  ActionType.FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDES_SUCCESS,
  FetchCoursePublicDetailExcludedSlidesSuccess
>;

type FetchCoursePublicDetailExcludedSlidesFailure = EdErrorResponseType;
export type FetchCoursePublicDetailExcludedSlidesFailureAction = ActionWithPayload<
  ActionType.FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDES_FAILURE,
  FetchCoursePublicDetailExcludedSlidesFailure
>;

type CreateCoursePublicDetailExcludedSlide = {
  courseId: string;
  publicDetailId: string;
  slideId: string;
  lessonId: string;
};
export type CreateCoursePublicDetailExcludedSlideAction = ActionWithPayload<
  ActionType.CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE,
  CreateCoursePublicDetailExcludedSlide
>;

type CreateCoursePublicDetailExcludedSlideSuccess = CoursePublicDetailExcludedSlide;
export type CreateCoursePublicDetailExcludedSlideSuccessAction = ActionWithPayload<
  ActionType.CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_SUCCESS,
  CreateCoursePublicDetailExcludedSlideSuccess
>;

type CreateCoursePublicDetailExcludedSlideFailure = EdErrorResponseType;
export type CreateCoursePublicDetailExcludedSlideFailureAction = ActionWithPayload<
  ActionType.CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_FAILURE,
  CreateCoursePublicDetailExcludedSlideFailure
>;

type DeleteCoursePublicDetailExcludedSlide = {
  courseId: string;
  publicDetailId: string;
  id: string;
};
export type DeleteCoursePublicDetailExcludedSlideAction = ActionWithPayload<
  ActionType.DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE,
  DeleteCoursePublicDetailExcludedSlide
>;

type DeleteCoursePublicDetailExcludedSlideSuccess = { publicDetailId: string; id: string };
export type DeleteCoursePublicDetailExcludedSlideSuccessAction = ActionWithPayload<
  ActionType.DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_SUCCESS,
  DeleteCoursePublicDetailExcludedSlideSuccess
>;

type DeleteCoursePublicDetailExcludedSlideFailure = EdErrorResponseType;
export type DeleteCoursePublicDetailExcludedSlideFailureAction = ActionWithPayload<
  ActionType.DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_FAILURE,
  DeleteCoursePublicDetailExcludedSlideFailure
>;

export const actions = {
  fetchCoursePublicDetailExcludedSlides: (
    payload: FetchCoursePublicDetailExcludedSlides
  ): FetchCoursePublicDetailExcludedSlidesAction =>
    createAction(ActionType.FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDES, payload),
  fetchCoursePublicDetailExcludedSlidesSuccess: (
    payload: FetchCoursePublicDetailExcludedSlidesSuccess
  ): FetchCoursePublicDetailExcludedSlidesSuccessAction =>
    createAction(ActionType.FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDES_SUCCESS, payload),
  fetchCoursePublicDetailExcludedSlidesFailure: (
    payload: FetchCoursePublicDetailExcludedSlidesFailure
  ): FetchCoursePublicDetailExcludedSlidesFailureAction =>
    createAction(ActionType.FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDES_FAILURE, payload),

  createCoursePublicDetailExcludedSlide: (
    payload: CreateCoursePublicDetailExcludedSlide
  ): CreateCoursePublicDetailExcludedSlideAction =>
    createAction(ActionType.CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE, payload),
  createCoursePublicDetailExcludedSlideSuccess: (
    payload: CreateCoursePublicDetailExcludedSlideSuccess
  ): CreateCoursePublicDetailExcludedSlideSuccessAction =>
    createAction(ActionType.CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_SUCCESS, payload),
  createCoursePublicDetailExcludedSlideFailure: (
    payload: CreateCoursePublicDetailExcludedSlideFailure
  ): CreateCoursePublicDetailExcludedSlideFailureAction =>
    createAction(ActionType.CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_FAILURE, payload),

  deleteCoursePublicDetailExcludedSlide: (
    payload: DeleteCoursePublicDetailExcludedSlide
  ): DeleteCoursePublicDetailExcludedSlideAction =>
    createAction(ActionType.DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE, payload),
  deleteCoursePublicDetailExcludedSlideSuccess: (
    payload: DeleteCoursePublicDetailExcludedSlideSuccess
  ): DeleteCoursePublicDetailExcludedSlideSuccessAction =>
    createAction(ActionType.DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_SUCCESS, payload),
  deleteCoursePublicDetailExcludedSlideFailure: (
    payload: DeleteCoursePublicDetailExcludedSlideFailure
  ): DeleteCoursePublicDetailExcludedSlideFailureAction =>
    createAction(ActionType.DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_FAILURE, payload)
};

export type ActionTypes = ActionsUnion<typeof actions>;
