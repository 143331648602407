import type { AchievementTranslationResponse } from '@edapp/achievements';
import { CustomAchievementsActionTypes } from './actions';
import type { CustomAchievementsActionsUnionType, CustomAchievementsState } from './types';

export const initialCustomAchievementsState: CustomAchievementsState = {
  achievementState: {
    isLoading: false,
    isTranslationLoading: false,
    isSaving: false,
    isModified: false,
    isPublishModalOpen: false,
    validationState: {
      title: true,
      recipe: true
    }
  },
  achievementsListState: {
    isLoading: false,
    isDeleting: false,
    hasViewedCA: false
  },
  componentsState: {
    isLoading: false
  }
};

export const customAchievementsReducer = (
  state: CustomAchievementsState = initialCustomAchievementsState,
  action: CustomAchievementsActionsUnionType
): CustomAchievementsState => {
  switch (action.type) {
    case CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT: {
      return {
        ...state,
        achievementState: {
          ...state.achievementState,
          isLoading: true,
          achievement: undefined,
          error: undefined
        }
      };
    }
    case CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_SUCCESS: {
      return {
        ...state,
        achievementState: {
          ...state.achievementState,
          isLoading: false,
          isModified: false,
          achievement: action.payload.data,
          error: undefined
        }
      };
    }
    case CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_FAILURE: {
      const { message } = action.payload;
      return {
        ...state,
        achievementState: { ...state.achievementState, isLoading: false, error: message }
      };
    }

    case CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_LIST: {
      return {
        ...state,
        achievementsListState: {
          ...state.achievementsListState,
          isLoading: true,
          achievementsList: undefined,
          error: undefined
        }
      };
    }
    case CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_LIST_SUCCESS: {
      return {
        ...state,
        achievementsListState: {
          ...state.achievementsListState,
          isLoading: false,
          achievementsList: action.payload.data,
          error: undefined
        }
      };
    }
    case CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_LIST_FAILURE: {
      const { message } = action.payload;
      return {
        ...state,
        achievementsListState: {
          ...state.achievementsListState,
          isLoading: false,
          error: message
        }
      };
    }

    case CustomAchievementsActionTypes.CREATE_CUSTOM_ACHIEVEMENT: {
      return {
        ...state,
        achievementState: { ...state.achievementState, isLoading: true, error: undefined }
      };
    }
    case CustomAchievementsActionTypes.CREATE_CUSTOM_ACHIEVEMENT_SUCCESS: {
      return {
        ...state,
        achievementState: {
          ...state.achievementState,
          isLoading: false,
          achievement: action.payload.data,
          isModified: false,
          error: undefined
        }
      };
    }
    case CustomAchievementsActionTypes.CREATE_CUSTOM_ACHIEVEMENT_FAILURE: {
      const { message } = action.payload;
      return {
        ...state,
        achievementState: {
          ...state.achievementState,
          isLoading: false,
          error: message
        }
      };
    }

    case CustomAchievementsActionTypes.SET_CUSTOM_ACHIEVEMENT: {
      return {
        ...state,
        achievementState: {
          ...state.achievementState,
          isSaving: true,
          error: undefined
        }
      };
    }
    case CustomAchievementsActionTypes.SET_CUSTOM_ACHIEVEMENT_SUCCESS: {
      return {
        ...state,
        achievementState: {
          ...state.achievementState,
          isSaving: false,
          achievement: action.payload.data,
          isModified: false,
          error: undefined
        }
      };
    }
    case CustomAchievementsActionTypes.SET_CUSTOM_ACHIEVEMENT_FAILURE: {
      const { message } = action.payload;
      return {
        ...state,
        achievementState: {
          ...state.achievementState,
          isSaving: false,
          error: message
        }
      };
    }

    case CustomAchievementsActionTypes.MODIFY_CUSTOM_ACHIEVEMENT: {
      const achievement = state.achievementState.achievement;
      if (!achievement) {
        return state;
      }

      return {
        ...state,
        achievementState: {
          ...state.achievementState,
          achievement: {
            ...achievement,
            ...action.payload.achievement
          },
          isModified: true,
          validationState: {
            title: action.payload.achievement.title
              ? true
              : state.achievementState.validationState.title,
            recipe: action.payload.achievement.recipes
              ? true
              : state.achievementState.validationState.recipe
          }
        }
      };
    }

    case CustomAchievementsActionTypes.ADD_NEW_CUSTOM_ACHIEVEMENT: {
      return {
        ...state,
        achievementState: {
          ...state.achievementState,
          achievement: action.payload.achievement
        }
      };
    }

    case CustomAchievementsActionTypes.REMOVE_TRANSLATION_CUSTOM_ACHIEVEMENT: {
      const achievement = state.achievementState.achievement;
      if (!achievement) {
        return state;
      }
      const newTranslations = achievement.translations?.filter(({ locale }) => {
        return locale.code !== action.payload.code;
      });
      return {
        ...state,
        achievementState: {
          ...state.achievementState,
          achievement: {
            ...achievement,
            translations: newTranslations ?? null
          },
          isTranslationLoading: false,
          isModified: true
        }
      };
    }

    case CustomAchievementsActionTypes.MODIFY_TRANSLATION_CUSTOM_ACHIEVEMENT: {
      const achievement = state.achievementState.achievement;
      if (!achievement) {
        return state;
      }
      const translations: AchievementTranslationResponse[] | null =
        achievement.translations?.map(
          (translation): AchievementTranslationResponse => {
            if (translation.locale.code === action.payload.translation.locale.code) {
              return action.payload.translation;
            }
            return translation;
          }
        ) ?? null;
      return {
        ...state,
        achievementState: {
          ...state.achievementState,
          achievement: {
            ...achievement,
            translations
          },
          isModified: true
        }
      };
    }

    case CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_TRANSLATION: {
      return {
        ...state,
        achievementState: {
          ...state.achievementState,
          isTranslationLoading: true
        }
      };
    }

    case CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_TRANSLATION_FAILURE:
    case CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENT_TRANSLATION_SUCCESS: {
      return {
        ...state,
        achievementState: {
          ...state.achievementState,
          isTranslationLoading: false
        }
      };
    }

    case CustomAchievementsActionTypes.ADD_TRANSLATION_CUSTOM_ACHIEVEMENT: {
      const achievement = state.achievementState.achievement;
      if (!achievement) {
        return state;
      }
      const { res, fromLanguage } = action.payload;
      return {
        ...state,
        achievementState: {
          ...state.achievementState,
          achievement: {
            ...achievement,
            // If it is the first translation we need to set defaultLocale to fromLanguage
            defaultLocale: achievement?.defaultLocale ? achievement?.defaultLocale : fromLanguage,
            translations: [
              ...(achievement.translations ?? []),
              {
                locale: res.locale,
                values: {
                  title: res.values.title,
                  description: res.values.description,
                  completion: res.values.completion
                }
              }
            ]
          },
          isModified: true
        }
      };
    }

    case CustomAchievementsActionTypes.SET_VALIDITY_CUSTOM_ACHIEVEMENT: {
      const isValid = action.payload.validity.title && action.payload.validity.recipe;
      return {
        ...state,
        achievementState: {
          ...state.achievementState,
          validationState: action.payload.validity,
          isSaving: !isValid ? false : state.achievementState.isSaving
        }
      };
    }

    case CustomAchievementsActionTypes.DELETE_CUSTOM_ACHIEVEMENT: {
      return {
        ...state,
        achievementsListState: {
          ...state.achievementsListState,
          isDeleting: true
        }
      };
    }
    case CustomAchievementsActionTypes.DELETE_CUSTOM_ACHIEVEMENT_SUCCESS: {
      return {
        ...state,
        achievementsListState: {
          ...state.achievementsListState,
          isDeleting: false,
          achievementsList: state.achievementsListState.achievementsList?.filter(
            achievementItem => achievementItem.id !== action.payload.id
          )
        }
      };
    }
    case CustomAchievementsActionTypes.BULK_DELETE_CUSTOM_ACHIEVEMENT: {
      return {
        ...state,
        achievementsListState: {
          ...state.achievementsListState,
          isDeleting: true
        }
      };
    }
    case CustomAchievementsActionTypes.BULK_DELETE_CUSTOM_ACHIEVEMENT_SUCCESS: {
      return {
        ...state,
        achievementsListState: {
          ...state.achievementsListState,
          isDeleting: false,
          achievementsList: state.achievementsListState.achievementsList?.filter(
            achievementItem => !action.payload.ids.includes(achievementItem.id)
          )
        }
      };
    }
    case CustomAchievementsActionTypes.DELETE_CUSTOM_ACHIEVEMENT_FAILURE: {
      return {
        ...state,
        achievementsListState: {
          ...state.achievementsListState,
          isDeleting: true,
          error: action.payload.message
        }
      };
    }

    case CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENTS_COMPONENTS: {
      return {
        ...state,
        componentsState: {
          isLoading: true
        }
      };
    }
    case CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENTS_COMPONENTS_SUCCESS: {
      return {
        ...state,
        componentsState: {
          isLoading: false,
          components: action.payload.data
        }
      };
    }
    case CustomAchievementsActionTypes.FETCH_CUSTOM_ACHIEVEMENTS_COMPONENTS_FAILURE: {
      return {
        ...state,
        componentsState: {
          isLoading: false,
          error: action.payload.message
        }
      };
    }
    case CustomAchievementsActionTypes.CLEAR_CUSTOM_ACHIEVEMENT: {
      return {
        ...state,
        achievementState: {
          ...initialCustomAchievementsState.achievementState
        }
      };
    }

    case CustomAchievementsActionTypes.SET_IS_PUBLISH_MODAL_OPEN: {
      return {
        ...state,
        achievementState: { ...state.achievementState, isPublishModalOpen: action.payload }
      };
    }

    case CustomAchievementsActionTypes.SET_RECIPE_FEATURE_ID_TITLE: {
      if (!state.achievementState.achievement) {
        return { ...state };
      }
      return {
        ...state,
        achievementState: {
          ...state.achievementState,
          achievement: {
            ...state.achievementState.achievement,
            recipes: state.achievementState.achievement.recipes.map(recipe => ({
              ...recipe,
              featureIdTitle:
                recipe.featureId === action.payload.featureId
                  ? action.payload.title
                  : recipe.featureIdTitle
            }))
          }
        }
      };
    }

    case CustomAchievementsActionTypes.SET_HAS_VIEWED_CUSTOM_ACHIEVEMENTS_SUCCESS: {
      return {
        ...state,
        achievementsListState: {
          ...state.achievementsListState,
          hasViewedCA: true
        }
      };
    }

    default: {
      return state;
    }
  }
};
