import * as React from 'react';
import SettingItem from './SettingItem';
import QRCode from 'qrcode.react';
import { DownloadIcon } from '@edapp/ed-components';
import { useTranslation } from 'react-i18next';

const QR_CODE_DOM_ID = 'qr-code';

type Props = {
  inviteLink: string;
  onDownloadQRCode?: () => void;
};

export const DownloadQRCode: React.FC<Props> = ({ inviteLink, onDownloadQRCode }) => {
  const { t } = useTranslation();
  const [qrCodeDataUrl, setQRCodeDataUrl] = React.useState('');
  const downloadBtnRef = React.useRef<HTMLAnchorElement | null>(null);

  React.useEffect(() => {
    if (!inviteLink) {
      return;
    }

    const qrCode = document.querySelector(`#${QR_CODE_DOM_ID}`);
    if (!qrCode) {
      return;
    }

    setQRCodeDataUrl((qrCode as HTMLCanvasElement).toDataURL());
  }, [inviteLink]);

  const handleDownloadQRCode = React.useCallback(() => {
    if (!downloadBtnRef.current) {
      return;
    }

    onDownloadQRCode?.();
    downloadBtnRef.current.download = 'qrcode.png';
  }, [downloadBtnRef.current, onDownloadQRCode]);

  return (
    <>
      <SettingItem
        icon={DownloadIcon}
        innerRef={downloadBtnRef}
        href={qrCodeDataUrl}
        onClick={handleDownloadQRCode}
        text={t('registration-settings.download-qr-code')}
      />

      <QRCode size={140} value={inviteLink} hidden={true} id={QR_CODE_DOM_ID} />
    </>
  );
};
