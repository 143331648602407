export enum TranslationQueryKeyPrefixes {
  getCourseTranslationLinks = 'getCourseTranslationLinks'
}

export const translationQueryKeys = {
  getCourseTranslationLinks: (courseId: string) => [
    TranslationQueryKeyPrefixes.getCourseTranslationLinks,
    courseId
  ]
};
