import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import type { SelectableListOption } from '@edapp/ed-components';
import { SelectableList } from '@edapp/ed-components';
import type { RequestTypes } from '@edapp/request';
import { flattenPages } from '@edapp/request';
import type {
  SearchUserDetailResponse,
  SearchUserDetailsResponse
} from '@rio/api/user-groups/users';
import { UserType, useGetUserGroupUsers } from '@rio/api/user-groups/users';
import type { LmsStoreState } from '@rio/store/types';
import { setUserGroupItem } from '@rio/store/userGroups/actions';
import { getUserGroupId } from '@rio/store/userGroups/selectors';
import { useDebounce } from '@rio/utils/hooks';
import type { InfiniteData } from '@tanstack/react-query';

import { useSelectableListCounter } from './hooks';

type OptionValue = string;
type Option = SelectableListOption<OptionValue>;

const pageSize = 25;

function mapOption({ id, name }: SearchUserDetailResponse): Option {
  return {
    key: id,
    value: id,
    label: name
  };
}

function mapOptions(
  options: Option[],
  { items }: RequestTypes.PaginatedResponse<SearchUserDetailResponse>
): Option[] {
  return items.reduce<Option[]>(
    (acc, cur) => (acc.every(({ value }) => value !== cur.id) ? [...acc, mapOption(cur)] : acc),
    options
  );
}

export const Managers: React.FC = () => {
  const userGroupId = useSelector(getUserGroupId);
  const selectedManagerIds = useSelector(
    (store: LmsStoreState) => store.userGroups.userGroupItem.usergroup.managers
  );
  const { t } = useTranslation();

  const [options, setOptions] = React.useState<Option[]>([]);

  const [managerName, setUserName] = React.useState<string | undefined>(undefined);
  const debouncedUserName = useDebounce(managerName, 500);

  const handleSetOptions = (data: InfiniteData<SearchUserDetailsResponse>) => {
    setOptions(prev => mapOptions(prev, flattenPages(data)));
  };

  const selected = useGetUserGroupUsers(
    userGroupId,
    {
      pageSize,
      unSelected: false,
      userName: debouncedUserName,
      userType: UserType.Managers
    },
    {
      staleTime: Infinity,
      onSuccess: handleSetOptions
    }
  );
  const unselected = useGetUserGroupUsers(
    userGroupId,
    {
      pageSize,
      unSelected: true,
      userName: debouncedUserName,
      userType: UserType.Managers
    },
    {
      staleTime: Infinity,
      onSuccess: handleSetOptions
    }
  );

  const { selectedCount, unselectedCount, handleSelect, handleDeselect } = useSelectableListCounter(
    selected.totalCount,
    unselected.totalCount,
    managerName
  );

  const selectedIsLoading = selected.isLoading || selected.isFetchingNextPage;
  const unselectedIsLoading = unselected.isLoading || unselected.isFetchingNextPage;

  const dispatch = useDispatch();

  const handleManagersChange = (managers: string[]) => {
    dispatch(setUserGroupItem({ usergroup: { managers } }));
  };

  const handleSelectManager = (managerId: OptionValue) => {
    const manager = options.find(({ value }) => value === managerId);

    if (!manager) {
      throw new Error(`Couldn't find manager: ${managerId}`);
    }

    handleManagersChange([...selectedManagerIds, managerId]);
    handleSelect(manager);
  };

  const handleClearManager = (managerId: OptionValue) => {
    const manager = options.find(({ value }) => value === managerId);

    if (!manager) {
      throw new Error(`Couldn't find manager: ${managerId}`);
    }

    handleManagersChange(selectedManagerIds.filter(id => id !== managerId));
    handleDeselect(manager);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.currentTarget.value || undefined);
  };

  const handleClearFilter = () => {
    setUserName(undefined);
  };

  const handleSelectedChange = (managers: OptionValue[]) => {
    if (managers.length < pageSize && !selectedIsLoading && selected.hasNextPage) {
      selected.fetchNextPage?.();
    }
  };

  const handleUnselectedChange = (managers: OptionValue[]) => {
    if (managers.length < pageSize && !unselectedIsLoading && unselected.hasNextPage) {
      unselected.fetchNextPage?.();
    }
  };

  return (
    <div>
      <label>{t('user-groups.select-managers.title')}</label>
      <SelectableList<OptionValue>
        options={options}
        selectedValues={selectedManagerIds}
        unselectedBox={{
          title: t('user-groups.select-users.all-users'),
          showCount: unselectedCount,
          onSelect: handleSelectManager,
          onChange: handleUnselectedChange,
          onLoadMore: unselected.fetchNextPage
        }}
        selectedBox={{
          title: t('user-groups.select-users.selected-users'),
          showCount: selectedCount,
          onClear: handleClearManager,
          onChange: handleSelectedChange,
          onLoadMore: selected.fetchNextPage
        }}
        filter={{
          placeholder: t('user-groups.select-users.search-placeholder'),
          value: managerName,
          onChange: handleFilterChange,
          onClear: handleClearFilter
        }}
        loading={unselectedIsLoading || selectedIsLoading}
      />
    </div>
  );
};
