import * as React from 'react';

import { Alert, Typography, getVariantData } from '@edapp/ed-components';

type Props = React.ComponentProps<typeof Alert> & {
  title?: string;
  description?: React.ReactNode;
};

export const InfoBox: React.FC<Props> = ({
  title,
  description,
  variant,
  justifyContent = 'start',
  alignItems = 'center',
  ...rest
}) => {
  const { borderColor: colour } = getVariantData(variant);

  return (
    <Alert
      variant={variant}
      showIcon={true}
      justifyContent={justifyContent}
      alignItems={alignItems}
      {...rest}
    >
      {!!title && (
        <Typography variant="subtitle1" color={colour}>
          {title}
        </Typography>
      )}
      <Typography
        variant="small"
        pl={!title ? 'lg' : undefined}
        width={!title ? '100%' : undefined}
      >
        {!!description && description}
      </Typography>
    </Alert>
  );
};
