import * as React from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router';
import { ChevronDownIcon, ChevronRightIcon } from '../icons';
import { Typography } from '../typography';
import type { CrumbProps } from './types';
import { DropdownItem } from '../dropdown/DropdownItem';
import { Dropdown } from '../dropdown/Dropdown';

export const Crumb: React.FC<CrumbProps> = props => {
  const [isOpen, setMenuIsOpen] = React.useState(false);
  const history = useHistory();

  const Wrapper = props.crumbWrapper || React.Fragment;

  const onClick = () => {
    switch (props.type) {
      case 'route-crumb':
        if (props.useWindowLocationHref) {
          window.location.href = props.url;
        } else {
          history.push(props.url, props.state);
        }
        break;

      case 'action-crumb':
        setMenuIsOpen(!isOpen);
        break;

      case 'simple-crumb':
        break;

      default:
        throw Error(`Unknown crumb type`);
    }
  };

  return (
    <>
      <StyledCrumb>
        <Wrapper>
          <StyledCrumbLink
            as="a"
            variant="small"
            color="textMuted"
            onClick={props.type === 'simple-crumb' || !!props.crumbWrapper ? undefined : onClick}
            isLast={!!props.isLast}
            testId="crumb-link"
          >
            <StyledCrumbLinkText
              color={!!props.isLast ? 'text' : 'textMuted'}
              testId="crumb-link-text"
            >
              {props.title}
            </StyledCrumbLinkText>

            {props.type === 'action-crumb' && (
              <Dropdown
                isOpen={isOpen}
                onClick={setMenuIsOpen}
                trigger={
                  <StyledCrumbBtn>
                    <StyledCrumbActionIcon
                      size="sm"
                      color={!!props.isLast ? 'text' : 'textMuted'}
                    />
                  </StyledCrumbBtn>
                }
              >
                {props.menuActions.map(i => (
                  <DropdownItem key={`dropdown-item-${i.title}`} onClick={i.onClick}>
                    <Typography>{i.title}</Typography>
                  </DropdownItem>
                ))}
              </Dropdown>
            )}
          </StyledCrumbLink>
        </Wrapper>
      </StyledCrumb>

      {!props.isLast && (
        <StyledCrumbChevronWrapper>
          <StyledCrumbChevron size="sm" color={!!props.isLast ? 'text' : 'textMuted'} />
        </StyledCrumbChevronWrapper>
      )}
    </>
  );
};

const StyledCrumbChevron = styled(ChevronRightIcon)`
  display: inline-block;
  vertical-align: middle;
`;

const StyledCrumb = styled.li`
  text-decoration: none;
  cursor: default;
  display: inline-block;
  vertical-align: middle;
  margin-right: ${({ theme }) => theme.space()}px;
`;

const StyledCrumbChevronWrapper = styled(StyledCrumb)`
  margin-right: ${({ theme }) => theme.space()}px;
`;

const StyledCrumbLink = styled(Typography)<{ isLast?: boolean }>(
  ({ theme, onClick, isLast }) => css`
    text-decoration: none;
    display: flex;
    ${!!onClick && `cursor: pointer;`}

    :hover,
    :focus {
      color: ${theme.colors.textMuted};
    }

    ${!!isLast &&
    `
    :hover,
    :focus {
      text-decoration: none;
    }
  `}
  `
);

const StyledCrumbBtn = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const StyledCrumbActionIcon = styled(ChevronDownIcon)`
  margin-left: ${({ theme }) => theme.space()}px;
  text-decoration: none;
  height: 12px;
  display: inline-block;
`;

const StyledCrumbLinkText = styled(Typography)`
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: max-width 0.3s ease-in-out;
`;
