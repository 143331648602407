import { genId } from '@edapp/utils';

import type { Answer } from '../../store/types';
import type { DragToMatchData, ImagePairData, Methods } from './types';

export const methods: Methods = {
  connect: (data: any) => {
    return data.pairs.map((pair: any) => {
      const incorrect = data.pairs
        .filter((op: any) => {
          return op.left !== pair.left;
        })
        .map((op: any) => {
          return {
            type: op.rightType,
            content: op.right,
            correct: false,
            id: genId()
          };
        });

      return {
        question: {
          type: pair.leftType,
          category: data.title,
          content: pair.left,
          id: genId()
        },
        answers: [
          {
            content: pair.right,
            type: pair.rightType,
            correct: true,
            id: genId()
          }
        ].concat(incorrect)
      };
    });
  },
  'drag-to-match': (e: DragToMatchData) => {
    return e.list.map(pair => {
      const incorrect = e.list
        .filter(op => {
          return op.image !== pair.image;
        })
        .map(op => {
          return {
            type: 'text',
            text: op.name,
            content: op.name,
            correct: false
          };
        }) as Answer[];
      return {
        question: {
          type: 'image',
          category: e.title,
          content: pair.image,
          text: pair.image
        },
        answers: [
          {
            type: 'text',
            text: pair.name,
            content: pair.name,
            correct: true
          } as Answer
        ].concat(incorrect)
      };
    });
  },
  'image-pair': (e: ImagePairData) => {
    return e.groups.map((group: any) => {
      return {
        question: {
          type: 'text',
          category: e.title,
          content: e.title,
          text: e.title
        },
        answers: group.images.map((gi: any) => {
          return {
            type: 'image',
            content: gi.image,
            correct: gi.correct
          };
        })
      };
    });
  }
};

export default methods;
