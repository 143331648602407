import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { Text } from '@edapp/sc-web-ui';

import { TabCard } from '../tabs/TabCard';
import { Description, Required } from './styled';

import type { Props } from './types';

export const CONFIG_CARD_TEST_ID = 'config-card';

export const ConfigCard: React.FC<Props> = ({
  title,
  description,
  required = false,
  children,
  ...rest
}) => {
  const { t } = useTranslation();
  return (
    <TabCard {...rest}>
      {!!title && <Text variant="titleLarge">{title}</Text>}
      {!!required && (
        <Required as="span" variant="paragraph" color="textMuted">
          ({t('common.required')})
        </Required>
      )}
      {!!description && (
        <Description variant="normal" color="textMuted">
          {description}
        </Description>
      )}
      {children}
    </TabCard>
  );
};

export default ConfigCard;
