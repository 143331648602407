import { useHippoQuery } from '@edapp/request';

import { getUserGroupCatalogDetails } from './api';
import { QueryKeys } from './constants';
import type {
  CatalogDetailsResponse,
  UseCatalogDetailsOptions,
  UseCatalogDetailsResult
} from './types';

export const useGetUserGroupCatalogDetails = <TSelection = CatalogDetailsResponse>(
  userGroupId: string,
  options?: UseCatalogDetailsOptions<TSelection>
): UseCatalogDetailsResult<TSelection> =>
  useHippoQuery(
    [QueryKeys.userGroupCatalogDetails, userGroupId],
    (hippoUrl, userToken) => () => getUserGroupCatalogDetails(hippoUrl, userToken, userGroupId),
    options
  );
