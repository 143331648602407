import * as React from 'react';
import styled, { css } from 'styled-components';
import { Tooltip, Typography, CopyIcon, TrashIcon } from '@edapp/ed-components';
import { useCheckPermission } from '@edapp/permissions';
import type { PermissionResourcesType } from '@edapp/permissions';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

type Props = {
  id: string;
  collectionId: string;
  title?: string;
  description?: string;
  thumbnail?: string;
  contentProvider?: string;
  showDelete?: boolean;
  showCopyCourse?: boolean;
  handleDeleteCourse?: (id: string) => void;
  handleDeleteCourseCollection?: (id: string, title: string, count: number) => void;
  handleDuplicateCourse?: (id: string, collectionId?: string) => void;
  coursewareCount?: number;
  resourceId?: string;
  resource: PermissionResourcesType;
};

export const CoursewareCardActions: React.FC<Props> = ({
  id,
  collectionId,
  title = '',
  showCopyCourse = false,
  showDelete = true,
  handleDeleteCourse,
  handleDeleteCourseCollection,
  handleDuplicateCourse,
  coursewareCount = 0,
  resource,
  resourceId
}) => {
  const { collectionId: collectionPageId } = useParams<{ collectionId: string }>();
  const isCollectionPage = !!collectionPageId;

  const handleDelete = (e: React.MouseEvent<SVGSVGElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (resource === 'COURSE_COLLECTION') {
      handleDeleteCourseCollection?.(collectionId!, title, coursewareCount);
    } else {
      handleDeleteCourse?.(id);
    }
  };

  const onDuplicateCourse = (e: React.MouseEvent<SVGSVGElement>) => {
    e.preventDefault();
    e.stopPropagation();
    handleDuplicateCourse?.(id, isCollectionPage ? collectionPageId : undefined);
  };

  const publishPermitted = useCheckPermission({ resource, scope: 'PUBLISH', resourceId });
  const deletePermitted = useCheckPermission({ resource, scope: 'DELETE', resourceId });
  const editPermitted = useCheckPermission({ resource, scope: 'EDIT', resourceId });
  const { t } = useTranslation();

  return (
    <CardActionsWrapper>
      {!publishPermitted && !deletePermitted && (
        <Typography variant="subtitle2" color="textMuted">
          {!!editPermitted ? 'EDITABLE' : 'VIEW ONLY'}
        </Typography>
      )}

      {showCopyCourse && (
        <Tooltip content="Duplicate" size="xs" placement="bottom">
          <StyledCopyIcon
            size="sm"
            color="textMuted"
            onClick={onDuplicateCourse}
            testId="cardDuplicateButton"
          />
        </Tooltip>
      )}
      {deletePermitted && showDelete && (
        <Tooltip content={t('common.delete')} size="xs" placement="bottom">
          <StyledTrashIcon
            size="sm"
            color="textMuted"
            onClick={handleDelete}
            testId="cardDeleteButton"
          />
        </Tooltip>
      )}
    </CardActionsWrapper>
  );
};

const CardActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  line-height: 1;
`;

const iconStyle = css`
  margin-left: ${({ theme }) => theme.space()}px;
  display: inline-block;
  vertical-align: middle;
  &:hover {
    cursor: pointer;
    path {
      fill: ${({ theme }) => theme.colors.text};
    }
  }
`;

const StyledCopyIcon = styled(CopyIcon)`
  ${iconStyle}
`;

const StyledTrashIcon = styled(TrashIcon)`
  ${iconStyle}
`;
