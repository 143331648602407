import * as React from 'react';
import styled from 'styled-components';
import { Typography, ProgressBar } from '@edapp/ed-components';
import { getDaysRemaining } from './utils';

type LabelProps = React.ComponentProps<typeof Typography>;

export const Label: React.FC<LabelProps> = ({ children, ...rest }) => (
  <Typography as="label" variant="subtitle2" {...rest}>
    {children}
  </Typography>
);

type CountdownProps = {
  children: (daysRemaining: string) => React.ReactNode;
  date: string;
};

export const Countdown: React.FC<CountdownProps> = ({ children, date }) => {
  const daysRemaining = getDaysRemaining(new Date(date));

  return <>{children(daysRemaining)}</>;
};

type RatioCountProps = {
  over: number;
  under: number;
} & React.ComponentProps<typeof Typography>;

export const RatioCount: React.FC<RatioCountProps> = ({ over, under, ...rest }) => (
  <Typography as="span" variant="subtitle2" {...rest}>
    {over} / {under}
  </Typography>
);

export const StyledProgressBar = styled(ProgressBar)`
  margin: ${({ theme }) => theme.space()}px 0;
`;
