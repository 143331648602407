import * as React from 'react';
import { Typography } from '../typography';
import type { Props } from './types';
import { SUPPORT_EMAIL, SUPPORT_MAILTO } from './constants';

export const SupportMailTo: React.FC<Props> = ({ variant = 'small', color = 'blue' }) => (
  <Typography isLink variant={variant} color={color} href={SUPPORT_MAILTO}>
    {SUPPORT_EMAIL}
  </Typography>
);
