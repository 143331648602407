import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ErrorFallbackUI } from '@edapp/courseware-ui';
import type { SelectableListOption } from '@edapp/ed-components';
import { SelectableList, useDebounce, usePrevious } from '@edapp/ed-components';
import { useErrorExtraction } from '@edapp/request';
import { useGetUserGroupsWithFilter } from '@rio/api/user-groups/hooks';
import type {
  GetUserGroupsWithFiltersItem,
  GetUserGroupsWithFiltersResponse
} from '@rio/api/user-groups/types';
import { GetUserGroupsFilterBy } from '@rio/api/user-groups/types';
import type { LmsStoreState } from '@rio/store/types';
import { setUserGroupItem } from '@rio/store/userGroups/actions';
import type { InfiniteData } from '@tanstack/react-query';

import { useSelectableListCounter } from './hooks';

const PAGE_SIZE = 25;

type GroupOption = {
  data: GetUserGroupsWithFiltersItem;
  option: SelectableListOption<string>;
};

type Props = {
  groupId: string;
};

export const Groups: React.FC<Props> = ({ groupId }) => {
  // Redux state
  const selectedGroupsIds = useSelector(
    (s: LmsStoreState) => s.userGroups.userGroupItem.usergroup.groups
  );
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Local state
  const [searchTerm, setSearchTerm] = React.useState<string>('');
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [userGroupsMap, setUserGroupsMap] = React.useState<Map<string, GroupOption>>(new Map());
  const options = React.useMemo(
    () => Array.from(userGroupsMap.values()).map(({ option }) => option),
    [userGroupsMap]
  );
  const previousGroupId = usePrevious(groupId);

  const addNewlyLoadedUserGroups = React.useCallback(
    (data?: InfiniteData<GetUserGroupsWithFiltersResponse>) => {
      if (!data) {
        return;
      }

      setUserGroupsMap(prevUserGroupsMap => {
        const newMap = new Map(prevUserGroupsMap);
        data.pages.forEach(page => {
          page.items.forEach(item => {
            newMap.set(item.id, {
              data: item,
              option: {
                key: item.id,
                value: item.id,
                label: item.name
              }
            });
          });
        });
        return newMap;
      });
    },
    []
  );

  const isCreatingNewGroup = React.useMemo(() => groupId === 'new', [groupId]);

  const unselected = useGetUserGroupsWithFilter(
    {
      searchTerm: debouncedSearchTerm,
      excludeAncestorsForUserGroupId: isCreatingNewGroup ? '' : groupId,
      pageSize: PAGE_SIZE,
      filterBy: GetUserGroupsFilterBy.NOT_SELECTED
    },
    {
      onSuccess: addNewlyLoadedUserGroups,
      gcTime: Infinity,
      staleTime: Infinity
    }
  );

  const selected = useGetUserGroupsWithFilter(
    {
      searchTerm: debouncedSearchTerm,
      excludeAncestorsForUserGroupId: groupId,
      pageSize: PAGE_SIZE,
      filterBy: GetUserGroupsFilterBy.SELECTED
    },
    {
      onSuccess: addNewlyLoadedUserGroups,
      gcTime: Infinity,
      staleTime: Infinity,
      enabled: !isCreatingNewGroup
    }
  );

  const {
    selectedCount,
    unselectedCount,
    handleSelect,
    handleDeselect,
    resetCounters
  } = useSelectableListCounter(selected.totalCount, unselected.totalCount, searchTerm);

  React.useEffect(() => {
    if (previousGroupId !== groupId) {
      resetCounters();
    }
  }, [groupId, previousGroupId, resetCounters]);

  const handleGroupsChange = React.useCallback(
    (groups: string[]) => {
      dispatch(setUserGroupItem({ usergroup: { groups } }));
    },
    [dispatch]
  );

  const handleSelectGroup = React.useCallback(
    (groupId: string) => {
      const userGroup = userGroupsMap.get(groupId);
      if (userGroup) {
        handleGroupsChange([...selectedGroupsIds, userGroup.data.id]);
        handleSelect(userGroup.option);
      }
    },
    [handleGroupsChange, handleSelect, selectedGroupsIds, userGroupsMap]
  );

  const handleClearGroup = React.useCallback(
    (groupId: string) => {
      const userGroup = userGroupsMap.get(groupId);
      if (userGroup) {
        handleGroupsChange(
          selectedGroupsIds.filter(selectedId => selectedId !== userGroup.data.id)
        );
        handleDeselect(userGroup.option);
      }
    },
    [handleDeselect, handleGroupsChange, selectedGroupsIds, userGroupsMap]
  );

  const handleFilterChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.currentTarget.value || '');
  }, []);

  const handleClearFilter = React.useCallback(() => {
    setSearchTerm('');
  }, []);

  const unselectedGroupsIsLoading = React.useMemo(
    () => unselected.isLoading || unselected.isFetchingNextPage,
    [unselected.isFetchingNextPage, unselected.isLoading]
  );
  const selectedGroupsIsLoading = React.useMemo(
    () => selected.isLoading || selected.isFetchingNextPage,
    [selected.isFetchingNextPage, selected.isLoading]
  );

  const handleSelectedChange = React.useCallback(
    (groupIds: string[]) => {
      if (groupIds.length < PAGE_SIZE && !selectedGroupsIsLoading && selected.hasNextPage) {
        selected.fetchNextPage?.();
      }
    },
    [selected, selectedGroupsIsLoading]
  );

  const handleUnselectedChange = React.useCallback(
    (groupIds: string[]) => {
      if (groupIds.length < PAGE_SIZE && !unselectedGroupsIsLoading && unselected.hasNextPage) {
        unselected.fetchNextPage?.();
      }
    },
    [unselected, unselectedGroupsIsLoading]
  );

  const { errorMsg } = useErrorExtraction(selected.error || unselected.error);
  if (errorMsg) {
    return <ErrorFallbackUI text={errorMsg} />;
  }

  return (
    <div>
      <label>{t('user-groups.select-groups-for-collection.title')}</label>
      <p className="help-block">{t('user-groups.select-groups-for-collection.description')}</p>
      <SelectableList
        options={options}
        selectedValues={selectedGroupsIds}
        unselectedBox={{
          title: t('user-groups.select-groups-for-collection.all-groups'),
          showCount: unselectedCount,
          onSelect: handleSelectGroup,
          onChange: handleUnselectedChange,
          onLoadMore: unselected.fetchNextPage
        }}
        selectedBox={{
          title: t('user-groups.select-groups-for-collection.selected-groups'),
          showCount: selectedCount,
          onClear: handleClearGroup,
          onChange: handleSelectedChange,
          onLoadMore: selected.fetchNextPage
        }}
        filter={{
          placeholder: t('user-groups.select-groups-for-collection.search-placeholder'),
          value: searchTerm,
          onChange: handleFilterChange,
          onClear: handleClearFilter
        }}
        loading={unselectedGroupsIsLoading || selectedGroupsIsLoading}
      />
    </div>
  );
};
