import type { History } from 'history';

import type { EdErrorResponseType } from '@edapp/authoring-logic';

import type { CourseCollection, CourseCollectionCourseItem } from './types';

// Create
export const CREATE_COURSE_COLLECTION = 'CREATE_COURSE_COLLECTION';
export type CreateCourseCollection = { title?: string; history: History };
export const createCourseCollection = (
  history: History,
  title?: string
): Action<CreateCourseCollection> => ({
  type: CREATE_COURSE_COLLECTION,
  payload: { title, history }
});

export const CREATE_COURSE_COLLECTION_SUCCESS = 'CREATE_COURSE_COLLECTION_SUCCESS';
export type CreateCourseCollectionSuccess = string;

export const CREATE_COURSE_COLLECTION_FAILURE = 'CREATE_COURSE_COLLECTION_FAILURE';
export type CreateCourseCollectionFailure = EdErrorResponseType;

// Save
export const SAVE_COURSE_COLLECTION = 'SAVE_COURSE_COLLECTION';
export type SaveCourseCollection = {
  collectionId: string;
  data: Partial<CourseCollection>;
};
export const saveCourseCollection = (
  collectionId: string,
  data: Partial<CourseCollection>
): Action<SaveCourseCollection> => ({
  type: SAVE_COURSE_COLLECTION,
  payload: { collectionId, data }
});

export const SAVE_COURSE_COLLECTION_SUCCESS = 'SAVE_COURSE_COLLECTION_SUCCESS';

export const SAVE_COURSE_COLLECTION_FAILURE = 'SAVE_COURSE_COLLECTION_FAILURE';
export type SaveCourseCollectionFailure = EdErrorResponseType;

// Fetch
export const FETCH_COURSE_COLLECTION = 'FETCH_COURSE_COLLECTION';
export type FetchCourseCollection = { collectionId: string };
export const fetchCourseCollection = (collectionId: string): Action<FetchCourseCollection> => ({
  type: FETCH_COURSE_COLLECTION,
  payload: { collectionId }
});

export const FETCH_COURSE_COLLECTION_SUCCESS = 'FETCH_COURSE_COLLECTION_SUCCESS';
export type FetchCourseCollectionSuccess = CourseCollection;

export const FETCH_COURSE_COLLECTION_FAILURE = 'FETCH_COURSE_COLLECTION_FAILURE';
export type FetchCourseCollectionFailure = EdErrorResponseType;

// Clear
export const CLEAR_COURSE_COLLECTION = 'CLEAR_COURSE_COLLECTION';
export type ClearCourseCollection = {};
export const clearCourseCollection = (): Action<ClearCourseCollection> => ({
  type: CLEAR_COURSE_COLLECTION,
  payload: {}
});

// Fetch Course Collection's Course List
export const FETCH_COURSE_COLLECTION_COURSE_LIST = 'FETCH_COURSE_COLLECTION_COURSE_LIST';
export type FetchCourseCollectionCourseList = {
  page: number;
  pageSize: number;
  collectionId: string;
  includeDraft: boolean;
};
export const fetchCourseCollectionCourseList = (
  page: number,
  pageSize: number,
  collectionId: string,
  includeDraft: boolean
): Action<FetchCourseCollectionCourseList> => ({
  type: FETCH_COURSE_COLLECTION_COURSE_LIST,
  payload: { page, pageSize, collectionId, includeDraft }
});

export const FETCH_COURSE_COLLECTION_COURSE_LIST_SUCCESS =
  'FETCH_COURSE_COLLECTION_COURSE_LIST_SUCCESS';
export type FetchCourseCollectionCourseListSuccess = PaginatedResponse<CourseCollectionCourseItem>;

export const FETCH_COURSE_COLLECTION_COURSE_LIST_FAILURE =
  'FETCH_COURSE_COLLECTION_COURSE_LIST_FAILURE';
export type FetchCourseCollectionCourseListFailure = EdErrorResponseType;

// Change Rank Course Collection's Course List
export const CHANGE_RANK_COURSE_COLLECTION_COURSE_LIST =
  'CHANGE_RANK_COURSE_COLLECTION_COURSE_LIST';
export type ChangeRankCourseCollectionCourseList = {
  collectionId: string;
  courseIds: string[];
};
export const changeRankCourseCollectionCourseList = (
  collectionId: string,
  courseIds: string[]
): Action<ChangeRankCourseCollectionCourseList> => ({
  type: CHANGE_RANK_COURSE_COLLECTION_COURSE_LIST,
  payload: { collectionId, courseIds }
});

export const CHANGE_RANK_COURSE_COLLECTION_COURSE_LIST_SUCCESS =
  'CHANGE_RANK_COURSE_COLLECTION_COURSE_LIST_SUCCESS';

export const CHANGE_RANK_COURSE_COLLECTION_COURSE_LIST_FAILURE =
  'CHANGE_RANK_COURSE_COLLECTION_COURSE_LIST_FAILURE';
export type ChangeRankCourseCollectionCourseListFailure = EdErrorResponseType;

// Move course to collection
export const MOVE_COURSE_TO_COLLECTION = 'MOVE_COURSE_TO_COLLECTION';
export type MoveCourseToCollection = {
  collectionId: string;
  courseId: string;
  includeToCollection: boolean;
};
export const moveCourseToCollection = (
  collectionId: string,
  courseId: string,
  includeToCollection: boolean
): Action<MoveCourseToCollection> => ({
  type: MOVE_COURSE_TO_COLLECTION,
  payload: { collectionId, courseId, includeToCollection }
});

export const MOVE_COURSE_TO_COLLECTION_SUCCESS = 'MOVE_COURSE_TO_COLLECTION_SUCCESS';
export type MoveCourseToCollectionSuccess = {};

export const MOVE_COURSE_TO_COLLECTION_FAILURE = 'MOVE_COURSE_TO_COLLECTION_FAILURE';
export type MoveCourseToCollectionFailure = EdErrorResponseType;

// Delete Course
export const DELETE_COURSE_FROM_COLLECTION = 'DELETE_COURSE_FROM_COLLECTION';
export type DeleteCourseFromCollection = { courseId: string };
export const deleteCourseFromCollection = (
  courseId: string
): Action<DeleteCourseFromCollection> => ({
  type: DELETE_COURSE_FROM_COLLECTION,
  payload: { courseId }
});
