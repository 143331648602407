import { useQueryClient } from '@tanstack/react-query';

import { useAPIAccess, useErrorExtraction, useHippoMutation, useHippoQuery } from '@edapp/request';

import { getFeatures, updateFeatures } from './apis';
import { FeaturesQueryKeys } from './constants';
import type {
  FeaturesRequest,
  FeaturesResponse,
  UseGetFeaturesOptions,
  UseGetFeaturesResult,
  UseUpdateFeaturesOptions,
  UseUpdateFeaturesResult
} from './types';

export const useGetFeatures = <TSelection = FeaturesResponse>(
  options?: UseGetFeaturesOptions<TSelection>
): UseGetFeaturesResult<TSelection> => {
  const { token } = useAPIAccess();

  return useHippoQuery<FeaturesResponse, TSelection>(
    [FeaturesQueryKeys.features],
    (hippoUrl, userToken) => () => getFeatures(hippoUrl, userToken),
    {
      ...options,
      enabled: !!token // only fetch if users are logged in to avoid unnecessary requests with errors
    }
  );
};

export function useUpdateFeatures(options?: UseUpdateFeaturesOptions): UseUpdateFeaturesResult {
  const queryClient = useQueryClient();
  const result = useHippoMutation<undefined, FeaturesRequest>(
    (hippoUrl, userToken) => request => updateFeatures(hippoUrl, userToken, request),
    {
      ...options,
      onSuccess: (data, variables, context) => {
        queryClient.setQueryData<FeaturesRequest>([FeaturesQueryKeys.features], old => ({
          ...old,
          ...variables
        }));
        options?.onSuccess?.(data, variables, context);
      }
    }
  );
  const extraction = useErrorExtraction(result.error);
  return {
    ...result,
    ...extraction
  };
}
