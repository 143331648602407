import * as React from 'react';

import { useSelector } from 'react-redux';

import { FeatureFlagsProvider as BaseFeatureFlagsProvider } from '@edapp/feature-flags';
import { getFeatureFlagsUrl, getUserToken } from '@rio/store/config/selectors';

import { API } from './api';
import type { Flags } from './constants';

export const FeatureFlagsProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const featureFlagUrl = useSelector(getFeatureFlagsUrl);
  const userToken = useSelector(getUserToken);

  const { getFeatureFlags } = API({ baseUrl: featureFlagUrl, token: userToken || undefined });

  return (
    <BaseFeatureFlagsProvider<Flags> getFeatureFlags={getFeatureFlags}>
      {children}
    </BaseFeatureFlagsProvider>
  );
};
