import type { Banner, CourseBannerTrait, LinkBannerTrait } from './types';
import { t } from 'i18next';

export const initialLinkBannerProperties: LinkBannerTrait = {
  bannerType: 'Link',
  destinationUrl: null,
  isCustomised: null,
  courseId: null,
  courseTitle: null,
  mediaUrl: null
};

export const initialCourseBannerProperties: CourseBannerTrait = {
  bannerType: 'Course',
  destinationUrl: null,
  isCustomised: false,
  courseId: null,
  courseTitle: null,
  mediaUrl: null
};

export const initialBanner: Banner = {
  id: '',
  title: t('banners.untitled-banner'),
  isPublished: false,
  universalAccessEnabled: true,
  userGroups: [],
  ...initialLinkBannerProperties
};
