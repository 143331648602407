export enum FilterType {
  COURSES_FILTER = 'coursesFilter',
  USER_GROUPS_FILTER = 'userGroupsFilter',
  DATE_RANGE_FILTER = 'dateRangeFilter'
}

export type Filters = {
  [FilterType.COURSES_FILTER]: Filter<CourseTitles[], string>;
  [FilterType.USER_GROUPS_FILTER]: Filter<UserGroupNames[], string[]>;
  [FilterType.DATE_RANGE_FILTER]: {
    startDate: Date | undefined;
    endDate: Date | undefined;
  };
};

type Filter<I = string[] | string, S = string | string[]> = {
  items: I;
  selectedItemIds: S;
  isLoading: boolean;
};

export type CourseTitles = FilterResponse<{ title: string }>;

export type UserGroupNames = FilterResponse<{ name: string }>;

export type FilterResponse<AdditionalProps> = {
  id: string;
} & AdditionalProps;
