import * as React from 'react';
import type { OrgSettings } from './types';

export const OrgSettingsContext = React.createContext<OrgSettings | undefined>(undefined);

export const useOrgSettings = () => {
  const context = React.useContext(OrgSettingsContext);

  if (!context) {
    throw new Error(
      'Usage of "useOrgSettings" should be within the OrgSettingsContext.Provider component'
    );
  }

  return context;
};
