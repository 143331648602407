import { useHippoQuery } from '@edapp/request';

import { searchCatalogs } from './apis';
import { catalogQueryKeys } from './constants';
import type {
  SearchCatalogsRequest,
  SearchCatalogsResponse,
  UseSearchCatalogsOptions,
  UseSearchCatalogsResult
} from './types';

export const useSearchCatalogs = <TSelection = SearchCatalogsResponse>(
  request: SearchCatalogsRequest,
  options?: UseSearchCatalogsOptions<TSelection>
): UseSearchCatalogsResult<TSelection> =>
  useHippoQuery(
    catalogQueryKeys.searchCatalogs(request),
    (hippoUrl, userToken) => () => searchCatalogs(hippoUrl, userToken, request),
    options
  );
