import { createAction } from '@edapp/utils';
import type {
  BrainBoostListFilter,
  GetSpacedRepetitionAction,
  GetSpacedRepetitionSuccessAction,
  SpacedRepCourseConfig,
  SetHasSeenBrainBoostOnboardingAction,
  SetBrainBoostFilterAction,
  ClearBrainBoostFilterAction,
  GetBrainBoostSettingsAction,
  UpdateBrainBoostSettingsAction
} from './types';

export enum BrainBoostActionTypes {
  SET_HAS_SEEN_BRAIN_BOOST_ONBOARDING = 'SET_HAS_SEEN_BRAIN_BOOST_ONBOARDING',
  SET_HAS_SEEN_BRAIN_BOOST_ONBOARDING_SUCCESS = 'SET_HAS_SEEN_BRAIN_BOOST_ONBOARDING_SUCCESS',
  SET_HAS_SEEN_BRAIN_BOOST_ONBOARDING_FAILURE = 'SET_HAS_SEEN_BRAIN_BOOST_ONBOARDING_FAILURE',

  GET_COURSE_SPACED_REPETITION = 'GET_COURSE_SPACED_REPETITION',
  GET_COURSE_SPACED_REPETITION_SUCCESS = 'GET_COURSE_SPACED_REPETITION_SUCCESS',
  GET_COURSE_SPACED_REPETITION_FAILURE = 'GET_COURSE_SPACED_REPETITION_FAILURE',

  SET_BRAIN_BOOST_FILTER = 'SET_BRAIN_BOOST_FILTER',
  CLEAR_BRAIN_BOOST_FILTER = 'CLEAR_BRAIN_BOOST_FILTER',

  GET_BRAIN_BOOST_SETTINGS = 'GET_BRAIN_BOOST_SETTINGS',
  GET_BRAIN_BOOST_SETTINGS_SUCCESS = 'GET_BRAIN_BOOST_SETTINGS_SUCCESS',
  GET_BRAIN_BOOST_SETTINGS_FAILURE = 'GET_BRAIN_BOOST_SETTINGS_FAILURE',

  UPDATE_BRAIN_BOOST_SETTINGS = 'UPDATE_BRAIN_BOOST_SETTINGS',
  UPDATE_BRAIN_BOOST_SETTINGS_SUCCESS = 'UPDATE_BRAIN_BOOST_SETTINGS_SUCESS',
  UPDATE_BRAIN_BOOST_SETTINGS_FAILURE = 'UPDATE_BRAIN_BOOST_SETTINGS_FAILURE'
}

export const BrainBoostActions = {
  setHasSeenBrainBoostOnboarding: (): SetHasSeenBrainBoostOnboardingAction =>
    createAction(BrainBoostActionTypes.SET_HAS_SEEN_BRAIN_BOOST_ONBOARDING),

  setBrainBoostFilter: (filterState: BrainBoostListFilter): SetBrainBoostFilterAction =>
    createAction(BrainBoostActionTypes.SET_BRAIN_BOOST_FILTER, { filterState }),

  clearBrainBoostFilter: (): ClearBrainBoostFilterAction =>
    createAction(BrainBoostActionTypes.CLEAR_BRAIN_BOOST_FILTER),

  getBrainBoostSettings: (): GetBrainBoostSettingsAction =>
    createAction(BrainBoostActionTypes.GET_BRAIN_BOOST_SETTINGS),

  updateBrainBoostSettings: (slidesPerSession: number): UpdateBrainBoostSettingsAction =>
    createAction(BrainBoostActionTypes.UPDATE_BRAIN_BOOST_SETTINGS, { slidesPerSession }),

  getCourseSpacedRepetition: (id: string): GetSpacedRepetitionAction =>
    createAction(BrainBoostActionTypes.GET_COURSE_SPACED_REPETITION, { id }),

  getCourseSpacedRepetitionSuccess: (
    data: SpacedRepCourseConfig
  ): GetSpacedRepetitionSuccessAction =>
    createAction(BrainBoostActionTypes.GET_COURSE_SPACED_REPETITION_SUCCESS, { data })
};
