import type { DiscussionType } from '@edapp/hippo-client';
import type { DiscussionPost, StoreState } from './types';
import type { Comment } from '../shared';
import { initialDiscussion } from './constants';

export const getDiscussion = (state: StoreState, discussionId: string): DiscussionType => {
  const discussion = state.discussion.discussions[discussionId];

  if (!discussion) {
    return { ...initialDiscussion };
  }

  return { ...discussion.original };
};

export const getPosts = (
  state: StoreState,
  discussionId: string
): { totalCount: number; items: DiscussionPost[]; currentPage: number } => {
  const posts = state.discussion.posts[discussionId];

  if (!posts) {
    return { totalCount: 0, items: [], currentPage: 1 };
  }

  return posts;
};

export const getDiscussionComments = (
  state: StoreState,
  postId: string
): { totalCount: number; items: Comment[]; currentPage?: number } => {
  const comments = state.discussion.comments[postId];

  if (!comments) {
    return { totalCount: 0, items: [], currentPage: 1 };
  }

  return comments;
};

export const getFetchDiscussionLoadingStatus = (state: StoreState) =>
  state.discussion.fetchDiscussionLoading;

export const getDiscussionMutateLoading = (state: StoreState) =>
  state.discussion.mutateDiscussionLoading;

export const getFetchDiscussionError = (state: StoreState) => state.discussion.fetchDiscussionError;

export const getDiscussionSummariesItems = (state: StoreState) =>
  state.discussion.discussionSummaries.items;

export const getDiscussionSummariesItemsByCourseId = (state: StoreState, courseId: string) =>
  state.discussion.discussionSummaries.items.filter(item => item.courseId === courseId);

export const getDiscussionSummariesTotalCount = (state: StoreState) =>
  state.discussion.discussionSummaries.totalCount;

export const getDiscussionSummariesCurrentPage = (state: StoreState) =>
  state.discussion.discussionSummaries.currentPage;

export const getDiscussionSummariesLoadingStatus = (state: StoreState) =>
  state.discussion.discussionSummaries.isLoading;

export const getDiscussionSummariesError = (state: StoreState) =>
  state.discussion.discussionSummaries.error;

export const getAddDiscussionCommentLoadingStatus = (state: StoreState) =>
  state.discussion.addCommentLoading;

export const getAddDiscussionCommentError = (state: StoreState) => state.discussion.addCommentError;
