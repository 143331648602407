import * as React from 'react';
import styled, { css } from 'styled-components';
import { HStack } from '@edapp/sc-web-ui';
import { rgba } from '@edapp/themes';

export const CardHoverOverlay: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Overlay>
      <HStack
        align="flex-start"
        justify="flex-end"
        spacing="s1"
        style={{ height: '100%', padding: '1rem' }}
      >
        {children}
      </HStack>
    </Overlay>
  );
};

export const Overlay = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: ${({theme}) => css`linear-gradient(180deg, ${rgba(theme.colors.scOverlay, 0.5)} 0%, ${rgba(theme.colors.scOverlay, 0)} 100%)`};
`;
