import { thomasTemplates } from '../../templates';
import { fieldsToForm } from '../../utils/convert';
import type { RawThomasTemplate, TemplateType } from '../types';

/**
 * Note: templates name and description come from i18n based on the template id/type
 */
export const templates: TemplateType[] = thomasTemplates.map((template: RawThomasTemplate) => {
  return {
    ...template,
    model: fieldsToForm(template.model)
  };
});
