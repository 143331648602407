import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { UpgradeBlock } from '@edapp/courseware-ui';
import { NotificationsIcon } from '@edapp/ed-components';
import { PushNotification } from '@rio/components/common/PushNotification';
import { hasBillingPermission } from '@rio/store/config/selectors';
import { BillingPermission } from '@rio/utils/permissions/constants';

import type { Props } from './types';

export const UserGroupPushNotification: React.FC<Props> = ({ userGroupId }) => {
  const permitted = useSelector(hasBillingPermission(BillingPermission.ACCESS_PUSH_NOTIFICATIONS));
  const isNew = React.useMemo<boolean>(() => !userGroupId || userGroupId === 'new', [userGroupId]);
  const { t } = useTranslation();

  if (!permitted) {
    return (
      <UpgradeBlock
        title={t('user-groups.push-notifications.paywall-title')}
        description={t('user-groups.push-notifications.paywall-description')}
        icon={NotificationsIcon}
        fullWidth={false}
      />
    );
  }

  return (
    <PushNotification
      actionsRequestUri="/api/usergroups/pushNotification"
      description={t('user-groups.push-notifications.description')}
      formFieldDefaults={{
        title: t('user-groups.push-notifications.default-title'),
        body: t('user-groups.push-notifications.default-body')
      }}
      payload={{
        id: userGroupId
      }}
      disabled={isNew ? t('user-groups.push-notifications.save-button') || '' : undefined}
    />
  );
};
