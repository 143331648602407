import * as React from 'react';
import { useField } from 'react-final-form';
import type { FieldValidator } from 'final-form';
import { LabelledTextArea } from '@edapp/ed-components';
import { identity } from 'lodash-es';

type Props = {
  name: string;
  validate?: FieldValidator<string | undefined>;
  /**
   * Whether to keep this field in the form data when it is empty.
   * Set this to true for forms that connect into PUT/PATCH requests,
   * where is it is possible to set a field to an empty value
   * @default false
   */
  keepFieldOnEmpty?: boolean;
} & Omit<React.ComponentProps<typeof LabelledTextArea>, 'value'>;

export const FormTextArea: React.FC<Props> = ({
  name,
  validate,
  keepFieldOnEmpty,
  onChange,
  onBlur,
  ...props
}) => {
  const { input, meta } = useField(name, {
    validate,
    parse: keepFieldOnEmpty ? identity : undefined
  });

  const handleOnChange: React.ChangeEventHandler<HTMLTextAreaElement> = event => {
    input.onChange(event);
    onChange?.(event);
  };

  const handleOnBlur: React.FocusEventHandler<HTMLTextAreaElement> = event => {
    input.onBlur(event);
    onBlur?.(event);
  };

  const submitError = !meta.modifiedSinceLastSubmit && meta.submitError;
  const validationError = meta.touched && meta.error;

  return (
    <LabelledTextArea
      {...props}
      {...input}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      error={validationError || submitError}
    />
  );
};
