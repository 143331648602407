import type { DeviceType } from '@edapp/authoring-ui';
import type { ActionsUnion, ActionWithPayload } from '@edapp/utils';
import { createAction } from '@edapp/utils';
import type { Lesson, PptSlide, SaveLessonTitlesPayload, Slide } from './types';

export enum BetterPptxActionTypes {
  BETTER_PPTX_ADD_LESSON = 'BETTER_PPTX_ADD_LESSON',
  BETTER_PPTX_SELECT_LESSON = 'BETTER_PPTX_SELECT_LESSON',
  BETTER_PPTX_SELECT_SLIDE = 'BETTER_PPTX_SELECT_SLIDE',
  BETTER_PPTX_SET_LESSON = 'BETTER_PPTX_SET_LESSON',
  BETTER_PPTX_DELETE_SLIDE = 'BETTER_PPTX_DELETE_SLIDE',
  BETTER_PPTX_DELETE_LESSON = 'BETTER_PPTX_DELETE_LESSON',
  BETTER_PPTX_CHANGE_SLIDE = 'BETTER_PPTX_CHANGE_SLIDE',
  BETTER_PPTX_REORDER_SLIDE = 'BETTER_PPTX_REORDER_SLIDE',
  BETTER_PPTX_REORDER_LESSON = 'BETTER_PPTX_REORDER_LESSON',
  BETTER_PPTX_SELECT_DEVICE_TYPE = 'BETTER_PPTX_SELECT_DEVICE_TYPE',
  BETTER_PPTX_SET_PREVIEW_MODE = 'BETTER_PPTX_SET_PREVIEW_MODE',
  BETTER_PPTX_CLEAR = 'BETTER_PPTX_CLEAR',

  BETTER_PPTX_SAVE_LESSONS = 'BETTER_PPTX_SAVE_LESSONS',
  BETTER_PPTX_SAVE_LESSONS_SUCCESS = 'BETTER_PPTX_SAVE_LESSONS_SUCCESS',
  BETTER_PPTX_SAVE_LESSONS_FAILURE = 'BETTER_PPTX_SAVE_LESSONS_FAILURE',

  BETTER_PPTX_SAVE_SLIDES = 'BETTER_PPTX_SAVE_SLIDES',
  BETTER_PPTX_SAVE_SLIDES_SUCCESS = 'BETTER_PPTX_SAVE_SLIDES_SUCCESS',
  BETTER_PPTX_SAVE_SLIDES_FAILURE = 'BETTER_PPTX_SAVE_SLIDES_FAILURE',

  BETTER_PPTX_CREATE_COURSE = 'BETTER_PPTX_CREATE_COURSE',
  BETTER_PPTX_CREATE_COURSE_SUCCESS = 'BETTER_PPTX_CREATE_COURSE_SUCCESS',
  BETTER_PPTX_CREATE_COURSE_FAILURE = 'BETTER_PPTX_CREATE_COURSE_FAILURE',

  BETTER_PPTX_TRANSFORM_PPTX = 'BETTER_PPTX_TRANSFORM_PPTX',
  BETTER_PPTX_TRANSFORM_PPTX_SUCCESS = 'BETTER_PPTX_TRANSFORM_PPTX_SUCCESS',
  BETTER_PPTX_TRANSFORM_PPTX_FAILURE = 'BETTER_PPTX_TRANSFORM_PPTX_FAILURE',

  BETTER_PPTX_DELETE_COURSE = 'BETTER_PPTX_DELETE_COURSE',
  BETTER_PPTX_DELETE_COURSE_SUCCESS = 'BETTER_PPTX_DELETE_COURSE_SUCCESS',
  BETTER_PPTX_DELETE_COURSE_FAILURE = 'BETTER_PPTX_DELETE_COURSE_FAILURE',

  BETTER_PPTX_SELECT_SLIDES = 'BETTER_PPTX_SELECT_SLIDES',
  BETTER_PPTX_MOVE_SLIDES = 'BETTER_PPTX_MOVE_SLIDES',

  BETTER_PPTX_ADJUST_FAILED_TO_SAVE_SLIDE = 'BETTER_PPTX_ADJUST_FAILED_TO_SAVE_SLIDE',

  BETTER_PPTX_FINALISE = 'BETTER_PPTX_FINALISE'
}

export const BetterPptxActions = {
  addLesson: (title: string) =>
    createAction(BetterPptxActionTypes.BETTER_PPTX_ADD_LESSON, { title }),
  selectLesson: (lessonIndex: number) =>
    createAction(BetterPptxActionTypes.BETTER_PPTX_SELECT_LESSON, { lessonIndex }),
  selectSlide: (slideIndex: number) =>
    createAction(BetterPptxActionTypes.BETTER_PPTX_SELECT_SLIDE, { slideIndex }),
  setLesson: (lessonIndex: number, lesson: RecursivePartial<Lesson>) =>
    createAction(BetterPptxActionTypes.BETTER_PPTX_SET_LESSON, { lessonIndex, lesson }),
  deleteSlide: (lessonIndex: number, slideIndex: number) =>
    createAction(BetterPptxActionTypes.BETTER_PPTX_DELETE_SLIDE, { lessonIndex, slideIndex }),
  deleteLesson: (lessonIndex: number) =>
    createAction(BetterPptxActionTypes.BETTER_PPTX_DELETE_LESSON, { lessonIndex }),
  changeSlide: (lessonIndex: number, slideIndex: number, newSlide: Slide) =>
    createAction(BetterPptxActionTypes.BETTER_PPTX_CHANGE_SLIDE, {
      lessonIndex,
      slideIndex,
      newSlide
    }),
  reorderLesson: (fromIndex: number, toIndex: number) =>
    createAction(BetterPptxActionTypes.BETTER_PPTX_REORDER_LESSON, { fromIndex, toIndex }),
  reorderSlide: (lessonIndex: number, fromIndex: number, toIndex: number) =>
    createAction(BetterPptxActionTypes.BETTER_PPTX_REORDER_SLIDE, {
      lessonIndex,
      fromIndex,
      toIndex
    }),
  selectDeviceType: (deviceType: DeviceType) =>
    createAction(BetterPptxActionTypes.BETTER_PPTX_SELECT_DEVICE_TYPE, { deviceType }),
  setPreviewMode: (previewMode: boolean) =>
    createAction(BetterPptxActionTypes.BETTER_PPTX_SET_PREVIEW_MODE, { previewMode }),
  clear: () => createAction(BetterPptxActionTypes.BETTER_PPTX_CLEAR, {}),
  saveLessons: () => createAction(BetterPptxActionTypes.BETTER_PPTX_SAVE_LESSONS, {}),
  saveSlides: (lessonId: string, slides: Slide[]) =>
    createAction(BetterPptxActionTypes.BETTER_PPTX_SAVE_SLIDES, { lessonId, slides }),
  createCourse: () => createAction(BetterPptxActionTypes.BETTER_PPTX_CREATE_COURSE, {}),
  deleteCourse: () => createAction(BetterPptxActionTypes.BETTER_PPTX_DELETE_COURSE, {}),
  createCourseSuccess: (id: string) =>
    createAction(BetterPptxActionTypes.BETTER_PPTX_CREATE_COURSE_SUCCESS, { id }),
  transformPptx: (pptUrl: string) =>
    createAction(BetterPptxActionTypes.BETTER_PPTX_TRANSFORM_PPTX, { pptUrl }),
  selectSlides: (slideIndexes: number[]) =>
    createAction(BetterPptxActionTypes.BETTER_PPTX_SELECT_SLIDES, { slideIndexes }),
  moveSlides: (fromLessonIndex: number, toLessonIndex: number, slideIndexes: number[]) =>
    createAction(BetterPptxActionTypes.BETTER_PPTX_MOVE_SLIDES, {
      fromLessonIndex,
      toLessonIndex,
      slideIndexes
    }),
  adjustFailedToSaveSlide: (lessonId: string, slideIndex: number) =>
    createAction(BetterPptxActionTypes.BETTER_PPTX_ADJUST_FAILED_TO_SAVE_SLIDE, {
      lessonId,
      slideIndex
    }),
  finalise: () => createAction(BetterPptxActionTypes.BETTER_PPTX_FINALISE, {})
};

export type BetterPptxActionsUnionType =
  | ActionsUnion<typeof BetterPptxActions>
  | ActionWithPayload<BetterPptxActionTypes.BETTER_PPTX_CREATE_COURSE_SUCCESS, { id: string }>
  | ActionWithPayload<
      BetterPptxActionTypes.BETTER_PPTX_SAVE_LESSONS_SUCCESS,
      SaveLessonTitlesPayload
    >
  | ActionWithPayload<
      BetterPptxActionTypes.BETTER_PPTX_TRANSFORM_PPTX_SUCCESS,
      { pptSlides: PptSlide[] }
    >
  | ActionWithPayload<BetterPptxActionTypes.BETTER_PPTX_TRANSFORM_PPTX_FAILURE, {}>;
