import type { PublicDetailsState } from '../types';
import type * as Actions from './actions';
import type { ActionType } from './actions';
import type { CoursePublicDetailUrlSlug } from './types';
import type { Handler } from '../../../../types';

const set = (
  stateData: PublicDetailsState['data'],
  publicDetailId: string,
  data: CoursePublicDetailUrlSlug
): PublicDetailsState['data'] => ({
  ...stateData,
  [publicDetailId]: {
    ...stateData[publicDetailId],
    urlSlug: data
  }
});

type SlugHandler<Action = null> = Handler<PublicDetailsState, Action>;

const fetchUrlSlug: SlugHandler<Actions.FetchCoursePublicDetailUrlSlugAction> = state => ({
  ...state,
  loading: true
});

const fetchUrlSlugSuccess: SlugHandler<Actions.FetchCoursePublicDetailUrlSlugSuccessAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: '',
  data: set(state.data, action.payload.coursePublicDetailId, action.payload)
});

const fetchUrlSlugFailure: SlugHandler<Actions.FetchCoursePublicDetailSlugFailureAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: action.payload.message
});

const createUrlSlug: SlugHandler<Actions.CreateCoursePublicDetailUrlSlugAction> = state => ({
  ...state,
  loading: true
});

const createUrlSlugSuccess: SlugHandler<Actions.CreateCoursePublicDetailUrlSlugSuccessAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: '',
  data: set(state.data, action.payload.coursePublicDetailId, action.payload)
});

const createUrlSlugFailure: SlugHandler<Actions.CreateCoursePublicDetailUrlSlugFailureAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: action.payload.message
});

const updateUrlSlug: SlugHandler<Actions.UpdateCoursePublicDetailUrlSlugAction> = state => ({
  ...state,
  loading: true
});

const updateUrlSlugSuccess: SlugHandler<Actions.UpdateCoursePublicDetailUrlSlugSuccessAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: '',
  data: set(state.data, action.payload.coursePublicDetailId, action.payload)
});

const updateUrlSlugFailure: SlugHandler<Actions.UpdateCoursePublicDetailUrlSlugFailureAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: action.payload.message
});

export type Handlers = {
  [type in ActionType]: (
    state: PublicDetailsState,
    action: Actions.ActionTypes
  ) => PublicDetailsState;
};

export const handlers: Handlers = {
  FETCH_COURSE_PUBLIC_DETAIL_URL_SLUG: fetchUrlSlug,
  FETCH_COURSE_PUBLIC_DETAIL_URL_SLUG_SUCCESS: fetchUrlSlugSuccess,
  FETCH_COURSE_PUBLIC_DETAIL_URL_SLUG_FAILURE: fetchUrlSlugFailure,

  CREATE_COURSE_PUBLIC_DETAIL_URL_SLUG: createUrlSlug,
  CREATE_COURSE_PUBLIC_DETAIL_URL_SLUG_SUCCESS: createUrlSlugSuccess,
  CREATE_COURSE_PUBLIC_DETAIL_URL_SLUG_FAILURE: createUrlSlugFailure,

  UPDATE_COURSE_PUBLIC_DETAIL_URL_SLUG: updateUrlSlug,
  UPDATE_COURSE_PUBLIC_DETAIL_URL_SLUG_SUCCESS: updateUrlSlugSuccess,
  UPDATE_COURSE_PUBLIC_DETAIL_URL_SLUG_FAILURE: updateUrlSlugFailure
};
