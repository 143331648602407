import React from 'react';
import { Form } from 'react-final-form';
import styled, { css } from 'styled-components';

import {
    Box, Button, Label, LeftArrowIcon, Typography, ValidationErrorMessage
} from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';
import { useForgetPassword } from '@rio/api/accounts/authentication';
import { FormInput } from '@rio/components/form/FormInput';

import type { FormProps } from 'react-final-form';
type Fields = {
  email: string;
};

type Props = {
  email?: string;
  onSuccess: () => void;
  onClickBack: () => void;
};

export const ForgetPassword: React.FC<Props> = ({ email = '', onSuccess, onClickBack }) => {
  const { mutateAsync: forgetPassword } = useForgetPassword();

  const handleSubmit: FormProps<Fields>['onSubmit'] = React.useCallback(async values => {
    try {
      await forgetPassword({ email: values.email });
      onSuccess();
    } catch (err) {
      return 'An unexpected error has occurred.';
    }
  }, []);

  return (
    <StyledContainer>
      <Text variant="titleLarge" component="h2">
        Reset password
      </Text>
      <Typography>We’ll send you a link to reset your password.</Typography>
      <Form<Fields>
        initialValues={{ email }}
        onSubmit={handleSubmit}
        render={({ handleSubmit, submitting, submitErrors }) => {
          return (
            <StyledForm onSubmit={handleSubmit}>
              <Label htmlFor="email">Email</Label>
              <FormInput name="email" isFullWidth disabled={!!email} />
              {submitErrors && <ValidationErrorMessage>{submitErrors}</ValidationErrorMessage>}
              <Box flex justifyContent="space-between" mt={3} alignItems="center">
                <Typography role="button" isLink color="blue" tabIndex={0} onClick={onClickBack}>
                  <Box flex alignItems="center" gap={1}>
                    <LeftArrowIcon />
                    Back
                  </Box>
                </Typography>
                <Button variant="primary" size="md" type="submit" isLoading={submitting}>
                  Send it over
                </Button>
              </Box>
            </StyledForm>
          );
        }}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled(Box)`
  text-align: left;
`;

const StyledForm = styled.form(
  ({ theme }) => css`
    margin-top: ${theme.space(3)}px;
  `
);
