import type { Reducer } from 'redux';

import { RingoActionTypes } from '@edapp/authoring-logic';
import { createMutated, mergeMutated, spreadMutated } from '@edapp/utils/src/reducerUtils';

import * as actions from './actions';
import type { LessonState } from './types';

export const initialLessonState: LessonState = {
  lessonFetchError: '',
  lessonFetchLoading: false,
  lessonMutateError: '',
  lessonMutateLoading: false,
  lessons: {},

  courseLessonsFetchLoading: false,
  courseLessonsFetchError: '',
  courseLessons: {},

  miniCoursesFetchError: '',
  miniCoursesFetchLoading: false,
  miniCourses: [],

  copyLessonError: '',
  copyLessonLoading: false,
  successfullyCopiedLessonId: ''
};

export const lessonReducer: Reducer<LessonState> = (state = initialLessonState, action: any) => {
  switch (action.type) {
    case actions.COPY_LESSON_TO_COURSE: {
      return { ...state, copyLessonLoading: true, copyLessonError: '' };
    }
    case actions.COPY_LESSON_TO_COURSE_SUCCESS: {
      const { newLessonId } = action.payload as actions.CopyLessonToCourseSuccess;
      return {
        ...state,
        copyLessonError: '',
        copyLessonLoading: false,
        successfullyCopiedLessonId: newLessonId
      };
    }
    case actions.COPY_LESSON_TO_COURSE_FAILURE: {
      const { message } = action.payload as actions.CopyLessonToCourseFailure;
      return { ...state, copyLessonLoading: false, copyLessonError: message };
    }

    case actions.FETCH_MINI_COURSES: {
      return { ...state, miniCoursesFetchLoading: true, miniCoursesFetchError: '' };
    }
    case actions.FETCH_MINI_COURSES_SUCCESS: {
      const { items } = action.payload as actions.FetchMiniCoursesSuccess;
      return {
        ...state,
        miniCoursesFetchLoading: false,
        miniCoursesFetchError: '',
        miniCourses: items
      };
    }
    case actions.FETCH_MINI_COURSES_FAILURE: {
      const { message } = action.payload as actions.FetchMiniCoursesFailure;
      return { ...state, miniCoursesFetchLoading: false, miniCoursesFetchError: message };
    }

    case actions.FETCH_LESSON: {
      return { ...state, lessonFetchLoading: true, lessonFetchError: '' };
    }
    case actions.FETCH_LESSON_SUCCESS: {
      const lesson = action.payload as actions.FetchLessonSuccess;
      return {
        ...state,
        lessonFetchLoading: false,
        lessonFetchError: '',
        lessons: {
          ...state.lessons,
          [lesson.id]: {
            ...createMutated(lesson)
          }
        }
      };
    }
    case actions.FETCH_LESSON_FAILURE: {
      const { message } = action.payload as actions.FetchLessonFailure;
      return { ...state, lessonFetchLoading: false, lessonFetchError: message };
    }

    case actions.FETCH_LESSON_SIBLINGS: {
      return { ...state, courseLessonsFetchLoading: true, courseLessonsFetchError: '' };
    }
    case actions.FETCH_LESSON_SIBLINGS_SUCCESS: {
      const { courseId, lessons } = action.payload as actions.FetchLessonSiblingsSuccess;
      return {
        ...state,
        courseLessonsFetchLoading: false,
        courseLessonsFetchError: '',
        courseLessons: {
          ...state.courseLessons,
          [courseId]: lessons
        }
      };
    }
    case actions.FETCH_LESSON_SIBLINGS_FAILURE: {
      const { message } = action.payload as actions.FetchLessonSiblingsFailure;
      return { ...state, courseLessonsFetchLoading: false, courseLessonsFetchError: message };
    }

    case actions.SAVE_LESSON: {
      const { data, lessonId } = action.payload as actions.SaveLesson;
      return {
        ...state,
        lessonMutateLoading: true,
        lessonMutateError: '',
        lessons: {
          ...state.lessons,
          [lessonId]: {
            ...mergeMutated(state.lessons[lessonId], data)
          }
        }
      };
    }
    case actions.SAVE_LESSON_SUCCESS: {
      return { ...state, lessonMutateLoading: false, lessonMutateError: '' };
    }
    case actions.SAVE_LESSON_FAILURE: {
      const {
        error,
        lessonId,
        lessonStateBeforeSave
      } = action.payload as actions.SaveLessonFailure;
      return {
        ...state,
        lessonMutateLoading: false,
        lessonMutateError: error.message,
        lessons: {
          ...state.lessons,
          [lessonId]: {
            ...spreadMutated(state.lessons[lessonId], lessonStateBeforeSave)
          }
        }
      };
    }

    case actions.CLEAR_LESSON:
      return {
        ...initialLessonState,
        lessons: { ...state.lessons },
        courseLessons: { ...state.courseLessons },
        miniCourses: [...state.miniCourses]
      };

    default:
      return state;
  }
};
