import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NoAccessSvg, EmptyStateCard } from '@edapp/ed-components';

export const NoAccess: React.FC = () => {
  const { t } = useTranslation();

  return (
    <EmptyStateCard
      image={<NoAccessSvg />}
      title={t('common.no-access.title')}
      description={t('common.no-access.description')}
      showBackground={false}
      isFullScreen={true}
    />
  );
};
