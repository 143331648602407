import * as React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import styled, { css } from 'styled-components';

import { BillingRoutes } from '@edapp/billing';
import { Box, Button, EdIcon, Typography } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';

import type { IconComponent } from '@edapp/ed-components';
export type UpgradeBlockProps = {
  icon?: IconComponent | null;
  title?: string;
  description?: string;
  fullWidth?: boolean;
  buttonLink?: string;
  buttonTitle?: string;
  hideButton?: boolean;
};

export function UpgradeBlock({
  icon = EdIcon,
  fullWidth = true,
  buttonLink = BillingRoutes.selectPlans,
  buttonTitle = 'Explore Plans',
  hideButton = false,
  title,
  description,
  ...rest
}: UpgradeBlockProps) {
  const Icon = icon;

  return (
    <Wrapper {...rest}>
      <Row center="xs">
        <Col xs={12} lg={fullWidth ? 5 : 12}>
          {Icon !== null && (
            <IconBackground>
              <Icon size="lg" color="grey" />
            </IconBackground>
          )}
          {title && (
            <Box mb={1}>
              <Text variant="titleLarge" component="h2">
                {title}
              </Text>
            </Box>
          )}
          {description && <Typography variant="paragraph">{description}</Typography>}
          {!hideButton && (
            <UpgradeButton variant="primary" as="a" href={buttonLink}>
              {buttonTitle}
            </UpgradeButton>
          )}
        </Col>
      </Row>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  ${({ theme }) => css`
    padding: ${theme.space(4)}px;
    background: ${theme.colors.white};
    box-shadow: ${theme.shadows.shallow};
    text-align: center;
    margin: ${theme.space(2)}px 0;
  `}
`;

const IconBackground = styled.div`
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 25px;
  margin: 0 auto 16px;
  background-color: ${({ theme }) => theme.colors.lightGrey};
`;

const UpgradeButton = styled(Button)<{ href?: string }>`
  display: inline-block;
  padding: 8px 24px;
  background-color: ${({ theme }) => theme.colors.blue};
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  width: auto;
  border-radius: 4px;
  margin: 24px auto 0;
  &:hover,
  &:focus {
    background-color: ${({ theme }) => theme.colors.blueHover};
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
  }
`;
