/**
 * When LMS included in an iFrame notify parent that navigation should be prevented
 * @param block block navigation
 * @param customMessage custom message to show when blocking the navigation
 */
export const notifyParentWindowBlockNavigation = (block: boolean, customMessage?: string) => {
  if (window.self === window.top) return;
  const msg = {
    type: 'prevent_redirection',
    enable: block,
    message: customMessage || ''
  };
  window.parent.postMessage(JSON.stringify(msg), '*');
};

/**
 * When LMS included in an iFrame notify parent that redirection outside the iframe has to be performed to a specific path
 * @param path SC page taht we want to redirect
 */
export const sendRedirectToPageMessage = (path: string) => {
  if (window.self === window.top) return;
  const msg = {
    type: 'redirect',
    path: path
  };
  window.parent.postMessage(JSON.stringify(msg), '*');
};
