import { createSelector } from 'reselect';

import type { LmsStoreState } from '@rio/store/types';

import { initialCourseCollection } from './constants';
import type { CourseCollection } from './types';

const getCourseCollection = (state: LmsStoreState, collectionId: string): CourseCollection => {
  const courseCollection = state.courseCollection.courseCollections[collectionId];

  if (!courseCollection) {
    return { ...initialCourseCollection };
  }

  return { ...courseCollection.original };
};

const getCourseCollections = (state: LmsStoreState) => state.courseCollection.courseCollections;
const getIsCourseCollectionFetchLoading = (state: LmsStoreState) =>
  state.courseCollection.fetchLoading;
const getCourseMutateError = (state: LmsStoreState) => state.courseCollection.mutateError;
const getCourseMutateLoading = (state: LmsStoreState) => state.courseCollection.mutateLoading;
const getListFetchLoading = (state: LmsStoreState) => state.courseCollection.listFetchLoading;
const getListFetchError = (state: LmsStoreState) => state.courseCollection.listFetchError;
const getCourseCollectionCourses = (state: LmsStoreState) => state.courseCollection.courses;

const getCourseCollectionById = (courseCollectionId: string) =>
  createSelector(getCourseCollections, courseCollections =>
    !!courseCollections[courseCollectionId]
      ? { ...courseCollections[courseCollectionId].original }
      : { ...initialCourseCollection }
  );

const getIsCourseCollection = (courseCollectionId: string) =>
  createSelector(
    getCourseCollectionById(courseCollectionId),
    courseCollection => !!courseCollection.isManual
  );

const getCourseCollectionCourseById = (courseId: string) =>
  createSelector(getCourseCollectionCourses, courses =>
    courses.items.find(course => course.id === courseId)
  );

const getIsSetCourseCollectionCourseTags = (courseId: string) =>
  createSelector(getCourseCollectionCourseById(courseId), course => {
    return !!course && !!course?.tags ? course.tags.length > 0 : false;
  });
const getIsCollectionCoursePublished = (courseId: string) =>
  createSelector(getCourseCollectionCourseById(courseId), course =>
    !!course ? course?.isPublished : undefined
  );
const getIsCourseFromContentLibrary = (courseId: string) =>
  createSelector(getCourseCollectionCourseById(courseId), course =>
    !!course ? course.isFromContentLibrary : false
  );

export {
  getCourseMutateError,
  getCourseMutateLoading,
  getCourseCollectionById,
  getListFetchLoading,
  getListFetchError,
  getCourseCollection,
  getIsSetCourseCollectionCourseTags,
  getIsCourseFromContentLibrary,
  getCourseCollectionCourses,
  getCourseCollectionCourseById,
  getIsCollectionCoursePublished,
  getIsCourseCollectionFetchLoading,
  getIsCourseCollection
};
