import { isArray, isObject, keys } from 'lodash-es';

const isChildKey = (key: string) => ['help'].indexOf(key) === -1;

// Utils to normalize the YAML schema stored in Thomas
// It then becomes an array of normalized elements which have all a type and a name
// And for specific elements, they can also contain other elements (Repeater, Multi, Section)
// Will also compute a unique path for every children
export const fieldsToForm = (
  model: any,
  name: string = 'model',
  path: string = '',
  depth: number = 0
): any => {
  if (depth > 100) {
    return;
  }

  let np;

  // Array of just something from a type
  if (isArray(model.type) && model.type.length > 0 && model.type[0].type) {
    np = path ? path + '.' + name : name;
    const sm = model.type[0];
    const myElts = [fieldsToForm({ name: '', type: sm.type }, '', np + '[]', depth + 1)];

    return {
      name,
      type: 'repeater',
      path: np,
      min: model.min,
      max: model.max,
      help: model.help,
      elements: myElts
    };

    // Array of objects
  } else if (isArray(model.type)) {
    np = path ? path + '.' + name : name;
    return {
      name,
      type: 'repeater',
      path: np,
      min: model.min,
      max: model.max,
      help: model.help,
      elements: keys(model.type[0])
        .filter(isChildKey)
        .map((property: string) => {
          const me = model.type[0][property];
          return fieldsToForm(me, property, (path ? path + '.' + name : name) + '[]', depth + 1);
        })
    };

    // Leaf Node (Content node)
  } else if (model.type) {
    const cleanPath = path ? (name ? path + '.' + name : path) : name;
    // if (depth > 1) { cleanPath = cleanPath + '.' + model.type; }
    return { ...model, name, path: cleanPath };

    // Leaf node with multiple types
  } else if (model.types && isArray(model.types)) {
    return {
      name,
      type: 'multi',
      path: path ? path + '.' + name : name,
      elements: model.types.map((type: string) => {
        const elementModel = { ...model, type };
        delete elementModel.types;
        return fieldsToForm(elementModel, name, path, depth + 1);
      })
    };

    // Section node
  } else if (
    !model.type &&
    name !== 'model' &&
    isObject(model) &&
    isObject(model[Object.keys(model)[0]])
  ) {
    return {
      name,
      type: 'section',
      path: path ? path + '.' + name : name,
      help: (model as any).help,
      elements: keys(model)
        .filter(isChildKey)
        .map((property: string) => {
          const me = model[property];
          return fieldsToForm(me, property, path ? path + '.' + name : name, depth + 1);
        })
    };
  } else if (!model.type && name === 'model') {
    return {
      name,
      type: 'model',
      path: path ? path + '.' + name : name,
      help: model.help,
      elements: keys(model)
        .filter(isChildKey)
        .map((property: string) => {
          const me = model[property];
          return fieldsToForm(me, property, path ? path + '.' + name : name, depth + 1);
        })
    };
  } else {
    // console.error('Model not recognized', model);
    throw new Error('model-not-valid');
  }
};
