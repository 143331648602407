import * as React from 'react';
import { StyledSelectAllCheckbox, CheckboxLabel, ItemListBox, StyledBox } from './styled';
import { ItemsCheckListItem } from './ItemsCheckListItem';
import type { ItemCheckListProp } from './types';
import { useTranslation } from 'react-i18next';

export const ItemsCheckList: React.FC<ItemCheckListProp> = ({
  items,
  selectedItemIds,
  handleItemSelection,
  maxHeightList = '244px',
  showSelectAll = true
}) => {
  const { t } = useTranslation();
  const handleAllItemsClick = () => {
    if (selectedItemIds.length === 0) {
      handleItemSelection(items.map(item => item.id));
    } else {
      handleItemSelection([]);
    }
  };

  const handleItemClick = (itemId: string) => {
    if (selectedItemIds.includes(itemId)) {
      handleItemSelection(selectedItemIds.filter(id => id !== itemId));
    } else {
      handleItemSelection([...selectedItemIds, itemId]);
    }
  };

  return (
    <>
      {showSelectAll && (
        <StyledBox>
          <StyledSelectAllCheckbox
            onClick={handleAllItemsClick}
            alignItems="center"
            descriptionVariant="subtitle2"
            checked={selectedItemIds.length > 0}
            disabled={items.filter(item => !item.isDisabled).length === 0}
            testId={'bulk-select-checkbox'}
            undetermined={selectedItemIds.length > 0 && selectedItemIds.length !== items.length}
          >
            <CheckboxLabel>{t('courseware.common.select-all')}</CheckboxLabel>
          </StyledSelectAllCheckbox>
        </StyledBox>
      )}
      <ItemListBox scrollDirection="vertical" maxScrollSize={maxHeightList}>
        {items.map(item => (
          <ItemsCheckListItem
            {...item}
            key={item.id}
            isSelected={!!selectedItemIds.includes(item.id)}
            handleItemClick={handleItemClick}
          />
        ))}
      </ItemListBox>
    </>
  );
};
