import * as actions from './actions';
import type { TagsState } from './types';
import type { Reducer } from 'redux';

export const tagsReducer: Reducer<TagsState> = (state = [], action) => {
  switch (action.type) {
    case actions.FETCH_TAGS_SUCCESS: {
      return action.payload;
    }
    default:
      return state;
  }
};
