import { delay } from 'redux-saga';
import { fork, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { itly } from '@edapp/analytics-tracking';
import type { EdErrorResponseType } from '@edapp/authoring-logic';
import { RequestActions } from '@edapp/request';
import { RapidRefreshActionTypes, RapidRefreshActions } from '@rio/store/rapid-refresh/actions';
import { getRapidRefreshById } from '@rio/store/rapid-refresh/selectors';
import type {
  FetchRapidRefreshConfigAction,
  FetchRapidRefreshLessonPreviewAction,
  RapidRefreshConfigType,
  RapidRefreshLessonPreviewType,
  SaveRapidRefreshConfigAction,
  SaveRapidRefreshConfigFailure,
  SetRapidRefreshConfigAction
} from '@rio/store/rapid-refresh/types';
import type { LmsStoreState } from '@rio/store/types';

// Fetch
function* handleFetchRapidRefreshConfig(action: FetchRapidRefreshConfigAction) {
  const { id } = action.payload;

  yield put(
    RequestActions.getAuthed<RapidRefreshConfigType>(
      `api/rapid-refresh/${id}/config`,
      data => {
        return {
          type: RapidRefreshActionTypes.FETCH_RAPID_REFRESH_CONFIG_SUCCESS,
          payload: {
            ...data
          }
        };
      },
      RapidRefreshActionTypes.FETCH_RAPID_REFRESH_CONFIG_FAILURE
    )
  );
}

// Set
function* handleSetRapidRefreshConfig(action: SetRapidRefreshConfigAction) {
  const { id, partialData } = action.payload;

  const oldState: RapidRefreshConfigType = yield select(getRapidRefreshById(id));
  yield put(RapidRefreshActions.saveRapidRefreshConfig(id, oldState, partialData));
}

// Save
function* handleSaveRapidRefreshConfig(action: SaveRapidRefreshConfigAction) {
  const { id, oldState, partialData } = action.payload;
  yield delay(1000);

  if (partialData?.leaderboardEnabled) itly.leaderboardEnabled({ type: 'Rapid Refresh' });
  else itly.leaderboardDisabled({ type: 'Rapid Refresh' });

  yield put(
    RequestActions.putAuthed<
      RapidRefreshConfigType,
      RapidRefreshConfigType,
      EdErrorResponseType,
      SaveRapidRefreshConfigFailure
    >(
      `api/rapid-refresh/${id}/config`,
      RapidRefreshActions.saveRapidRefreshConfigSuccess,
      error => RapidRefreshActions.saveRapidRefreshConfigFailure(id, oldState, error),
      false,
      partialData
    )
  );

  const { success } = yield race({
    success: take(RapidRefreshActionTypes.SAVE_RAPID_REFRESH_CONFIG_SUCCESS),
    failure: take(RapidRefreshActionTypes.SAVE_RAPID_REFRESH_CONFIG_FAILURE)
  });

  if (!!success) {
    const hasLessonPreview: boolean = yield select<LmsStoreState>(
      state => !!state.rapidRefresh.lessonPreviews[id]
    );

    // Refetch lesson preview to ensure it matches the latest config
    if (hasLessonPreview) {
      yield put(RapidRefreshActions.fetchRapidRefreshLessonPreview(id));
    }
  }
}

// Fetch Lesson
function* handleFetchRapidRefreshLessonPreview(action: FetchRapidRefreshLessonPreviewAction) {
  const { id } = action.payload;

  yield put(
    RequestActions.getAuthed<RapidRefreshLessonPreviewType>(
      `api/rapid-refresh/${id}/lesson`,
      RapidRefreshActionTypes.FETCH_RAPID_REFRESH_LESSON_PREVIEW_SUCCESS,
      RapidRefreshActionTypes.FETCH_RAPID_REFRESH_LESSON_PREVIEW_FAILURE
    )
  );
}

function* watchFetchRapidRefreshConfig() {
  yield takeLatest(
    RapidRefreshActionTypes.FETCH_RAPID_REFRESH_CONFIG,
    handleFetchRapidRefreshConfig
  );
}

function* watchSetRapidRefreshConfig() {
  yield takeLatest(RapidRefreshActionTypes.SET_RAPID_REFRESH_CONFIG, handleSetRapidRefreshConfig);
}

function* watchSaveRapidRefreshConfig() {
  yield takeLatest(RapidRefreshActionTypes.SAVE_RAPID_REFRESH_CONFIG, handleSaveRapidRefreshConfig);
}

function* watchFetchRapidRefreshLessonPreview() {
  yield takeLatest(
    RapidRefreshActionTypes.FETCH_RAPID_REFRESH_LESSON_PREVIEW,
    handleFetchRapidRefreshLessonPreview
  );
}

export const rapidRefreshSagas = [
  fork(watchFetchRapidRefreshConfig),
  fork(watchSetRapidRefreshConfig),
  fork(watchSaveRapidRefreshConfig),
  fork(watchFetchRapidRefreshLessonPreview)
];
