import styled, { css } from 'styled-components';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Box, TickIcon, BackgroundImage } from '@edapp/ed-components';

export const StyledRow = styled(Row)`
  margin: 0;
  width: 100%;
`;

export const ContentContainer = styled(Box)`
  height: 100%;
`;

export const ImageCol = styled(Col)`
  min-width: 200px;
`;

export const Image = styled(BackgroundImage)`
  height: 100%;
  max-height: 100%;
  min-height: 320px;
  width: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top;
`;

export const StyledTickIcon = styled(TickIcon)(
  ({ theme }) => css`
    margin-right: ${theme.space(2)}px;
  `
);
