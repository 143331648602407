import * as React from 'react';
import styled from 'styled-components';

export type Props = {};

export const Body: React.FC<React.PropsWithChildren<Props>> = ({ children }) => <StyledBody>{children}</StyledBody>;

const StyledBody = styled.div`
  text-align: center;
`;
