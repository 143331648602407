import { fork, put, select, takeLatest } from 'redux-saga/effects';

import { RequestActions } from '@edapp/request';
import type { LmsStoreState } from '@rio/store/types';

import * as actions from './actions';

function* handleFetchCourseTitles() {
  yield put(
    RequestActions.getAuthed(
      `/api/courses/getCourseTitles`,
      actions.FETCH_COURSE_TITLES_SUCCESS,
      actions.FETCH_COURSE_TITLES_FAILURE,
      false
    )
  );
}

function* watchHandleFetchCourseTitles() {
  yield takeLatest(actions.FETCH_COURSE_TITLES, handleFetchCourseTitles);
}

function* handleFetchUserGroupNames(action: Action<actions.FetchUserGroupNames>) {
  const { payload } = action;

  yield put(
    RequestActions.getAuthed(
      `/api/usergroups/GetUserGroupTitles`,
      actions.FETCH_USER_GROUP_NAMES_SUCCESS,
      actions.FETCH_USER_GROUP_NAMES_FAILURE,
      false,
      { courseId: payload.selectedCourseIds }
    )
  );
}

function* watchHandleFetchUserGroupNames() {
  yield takeLatest(actions.FETCH_USER_GROUP_NAMES, handleFetchUserGroupNames);
}

function* handleFetchReportItems() {
  const state: LmsStoreState = yield select();
  const { data, filters, reportType } = state.analytics.actionableReports;

  const selectedCourseIds = filters.coursesFilter.selectedItemIds;
  const selectedUserGroupIds = filters.userGroupsFilter.selectedItemIds;

  const pageCount = data.items.length === 0 && data.page === 1 ? data.page : data.page + 1;

  const courseIdParam = !!selectedCourseIds ? { courseId: selectedCourseIds } : undefined;

  const userGroupIdsParam =
    Array.isArray(selectedUserGroupIds) && selectedUserGroupIds.length > 0
      ? { userGroupIds: selectedUserGroupIds }
      : undefined;

  yield put(
    RequestActions.getAuthed(
      `api/analytics/actionable/${reportType}`,
      actions.FETCH_REPORT_ITEMS_SUCCESS,
      actions.FETCH_REPORT_ITEMS_FAILURE,
      false,
      {
        page: pageCount,
        pageSize: data.pageSize,
        ...courseIdParam,
        ...userGroupIdsParam
      }
    )
  );
}

function* watchHandleFetchReportItems() {
  yield takeLatest(actions.FETCH_REPORT_ITEMS, handleFetchReportItems);
}

const sagas = [
  fork(watchHandleFetchCourseTitles),
  fork(watchHandleFetchUserGroupNames),
  fork(watchHandleFetchReportItems)
];

export default sagas;
