import * as React from 'react';
import styled from 'styled-components';
import { RightSmIcon, Typography } from '@edapp/ed-components';

type ViewAllButtonProps = {
  onClick: () => void;
};
export const ViewAllButton: React.FC<ViewAllButtonProps> = ({ onClick }) => (
  <StyledViewAllButton as="a" href="#" onClick={onClick}>
    <StyledViewAllText variant="normal">See All</StyledViewAllText>
    <StyledViewAllIcon size="sm" />
  </StyledViewAllButton>
);

const StyledViewAllButton = styled.div`
  display: inline-block;
  vertical-align: middle;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.textMuted};

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    span {
      color: ${({ theme }) => theme.colors.text};
    }
  }
`;

const StyledViewAllText = styled(Typography)`
  color: ${({ theme }) => theme.colors.textMuted};
  padding-right: ${({ theme }) => `${theme.space()}px`};
`;

const StyledViewAllIcon = styled(RightSmIcon)`
  display: inline-block;
  vertical-align: middle;
`;
