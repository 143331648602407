import { RequestUtils } from '@edapp/request';
import type { DrawsResponse } from './types';

export async function getLotteryDraws(hippoUrl: string, userToken: string) {
  return await RequestUtils.httpFetch<DrawsResponse>(
    'GET',
    `${hippoUrl}/api/lottery/draws`,
    userToken
  );
}
