import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { Box, Radio, Typography } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';
import PermissionWrapper from '@rio/components/common/PermissionWrapper/PermissionWrapper';
import type { LmsStoreState } from '@rio/store/types';
import { setUserGroupItem } from '@rio/store/userGroups/actions';
import type { UserGroupContains } from '@rio/store/userGroups/types';

export type UIUserGroupContains = UserGroupContains | 'dynamic';

type Props = { groupId: string };

export const Contains: React.FC<Props> = ({ groupId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { userGroupContains, isDynamicGroup, dynamicUserGroupEnabled } = useSelector(
    (state: LmsStoreState) => {
      const { userGroupItem } = state.userGroups;
      return {
        userGroupContains: userGroupItem.usergroup.userGroupContains,
        isDynamicGroup: userGroupItem.usergroup.isDynamicGroup,
        dynamicUserGroupEnabled: userGroupItem.app.dynamicUserGroupEnabled
      };
    },
    shallowEqual
  );

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const v = e.currentTarget.value as UIUserGroupContains;
    switch (v) {
      case 'dynamic':
        dispatch(
          setUserGroupItem({
            usergroup: { userGroupContains: 'users', isDynamicGroup: true }
          })
        );
        break;

      case 'groups':
        dispatch(
          setUserGroupItem({
            usergroup: { userGroupContains: 'groups', isDynamicGroup: false }
          })
        );
        break;

      case 'users':
        dispatch(
          setUserGroupItem({
            usergroup: { userGroupContains: 'users', isDynamicGroup: false }
          })
        );
        break;

      default:
        throw Error(`Unknown userGroupContains ${v}`);
    }
  };

  return (
    <PermissionWrapper
      resource="USER"
      scope={'EDIT'}
      accessPermission={'ACCESS_DYNAMIC_USER_GROUPS'}
    >
      {dynamicPermited => {
        const disableDynamic = !dynamicPermited || !dynamicUserGroupEnabled;

        return (
          <Box flex="1 1 50%" flexWrap="wrap" justifyContent="space-between">
            <RadioWrapper>
              <Radio
                value="users"
                onChange={onChange}
                checked={!isDynamicGroup && userGroupContains === 'users'}
                isDisabled={groupId !== 'new'}
              >
                <Text variant="labelLarge" component="h4">
                  {t('user-groups.types.normal-user-group.name')}
                </Text>
                <Typography color="textMuted">
                  {t('user-groups.types.normal-user-group.description')}
                </Typography>
              </Radio>
            </RadioWrapper>
            <RadioWrapper>
              <Radio
                value="groups"
                onChange={onChange}
                checked={!isDynamicGroup && userGroupContains === 'groups'}
                isDisabled={groupId !== 'new'}
                labelTestId="group-collection-radio"
              >
                <Text variant="labelLarge" component="h4" data-testId="group-title-collection">
                  {t('user-groups.types.user-group-collection.name')}
                </Text>
                <Typography color="textMuted" testId="group-description-collection">
                  {t('user-groups.types.user-group-collection.description')}
                </Typography>
              </Radio>
            </RadioWrapper>
            <RadioWrapper>
              <Radio
                value="dynamic"
                isDisabled={disableDynamic || groupId !== 'new'}
                onChange={onChange}
                checked={!!isDynamicGroup}
                labelTestId="group-dynamic-radio"
              >
                <Text variant="labelLarge" component="h4" data-testId="group-title-dynamic">
                  {t('user-groups.types.dynamic-group.name')}
                </Text>
                <Typography color="textMuted" testId="group-description-dynamic">
                  {t('user-groups.types.dynamic-group.description')}
                </Typography>
              </Radio>
            </RadioWrapper>
          </Box>
        );
      }}
    </PermissionWrapper>
  );
};

const RadioWrapper = styled(Box).attrs(() => ({
  pt: 2,
  px: 2,
  m: 1,
  bgColor: 'white',
  flex: '1 1 auto'
}))(
  ({ theme }) => css`
    border-radius: ${theme.sizes.borderRadius}px;
    border: 1px solid ${theme.colors.grey};
    max-width: ${`calc(50% - ${theme.space(2)}px)`};
  `
);
