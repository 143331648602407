import * as React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { CoursewareSvg, EmptyStateCard, Typography, SupportMailTo } from '@edapp/ed-components';

type Props = {
  title?: string;
  description?: React.ReactNode;
};

export const ErrorNotFound: React.FC<Props> = ({ title, description }) => {
  const { t } = useTranslation();

  return (
    <EmptyStateCard
      image={<CoursewareSvg width={160} />}
      title={title ?? t('common.not-found.title')}
      showBackground={false}
      isFullScreen={true}
    >
      <Typography color="textMuted" variant="small">
        {description ?? (
          <Trans
            i18nKey="common.not-found.description"
            components={{
              supportLink: <SupportMailTo />
            }}
          />
        )}
      </Typography>
    </EmptyStateCard>
  );
};
