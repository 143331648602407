export enum LoadingStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  PAUSED = 'PAUSED'
}

export enum LoadingActionTypes {
  CLEAR_LOADING_STATUS = 'CLEAR_LOADING_STATUS',
  CLEAR_ALL_PENDING = 'CLEAR_ALL_PENDING',
  PAUSE_ALL_PENDING = 'PAUSE_ALL_PENDING',
  RESUME_ALL_PENDING = 'RESUME_ALL_PENDING'
}
