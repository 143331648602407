import { RequestUtils } from '@edapp/request';

import type {
  CompleteRegistrationRequest,
  ForgetPasswordRequest,
  GetLinkedAccountResponse,
  GetTokenAndEmailReponse,
  GetTokenAndEmailRequest,
  GetTokenReponse,
  LinkAccountRequest,
  LinkAccountResponse,
  LoginRequest,
  LoginResponse,
  ResendInviteRequest,
  ResendInviteResponse,
  SuperAdminSwitchAccountRequest,
  SwitchAccountRequest
} from './types';

export const login = (emilyUrl: string, request: LoginRequest) => {
  return RequestUtils.httpFetch<LoginResponse, LoginRequest>(
    'POST',
    `${emilyUrl}/login`,
    undefined,
    request
  );
};

export const forgetPassword = (hippoUrl: string, request: ForgetPasswordRequest) => {
  return RequestUtils.httpFetch<void, ForgetPasswordRequest>(
    'POST',
    `${hippoUrl}/forgot-password`,
    undefined,
    request
  );
};

export const getToken = (emilyUrl: string) => {
  return RequestUtils.httpFetch<GetTokenReponse>(
    'GET',
    `${emilyUrl}/user/current/token`,
    undefined
  );
};

export const completeRegistration = (emilyUrl: string, request: CompleteRegistrationRequest) => {
  return RequestUtils.httpFetch<string, CompleteRegistrationRequest>(
    'POST',
    `${emilyUrl}/login/reset-password`,
    undefined,
    request
  );
};

export const logout = (emilyUrl: string) => {
  return RequestUtils.httpFetch<void, { redirect: boolean }>(
    'GET',
    `${emilyUrl}/logout`,
    undefined,
    { redirect: false } // not redirect to '/' after logging out since this is an API to call, if you desire so please redirect users to `/logout` instead of using this API
  );
};

export const validateTokenRegistration = (hippoUrl: string, token: string) => {
  return RequestUtils.httpFetch<GetTokenAndEmailReponse, GetTokenAndEmailRequest>(
    'POST',
    `${hippoUrl}/api/users/verify-reset-password-token`,
    undefined,
    { token }
  );
};

export const registrationResendInvitation = (hippoUrl: string, token: string) => {
  return RequestUtils.httpFetch<ResendInviteResponse, ResendInviteRequest>(
    'POST',
    `${hippoUrl}/api/users/resend-user-invitation`,
    undefined,
    { token }
  );
};

export const getLinkedAccounts = (emilyUrl: string) => {
  return RequestUtils.httpFetch<GetLinkedAccountResponse>(
    'GET',
    `${emilyUrl}/accounts/switch-account`,
    undefined
  );
};

export const switchAccount = (emilyUrl: string, payload: SwitchAccountRequest) => {
  return RequestUtils.httpFetch<unknown, SwitchAccountRequest>(
    'POST',
    `${emilyUrl}/accounts/switch-account`,
    undefined,
    payload
  );
};

/**
 * This endpoint returns 302 for regardless of success or failure.
 * 302 status code is treated as an error for httpFetch, so
 * we just need to catch the error and ignore it.
 */
export const switchAccountSuperAdmin = async (
  emilyUrl: string,
  payload: SuperAdminSwitchAccountRequest
) => {
  try {
    await RequestUtils.httpFetch<unknown, SuperAdminSwitchAccountRequest>(
      'POST',
      `${emilyUrl}/admin/switch-account`,
      undefined,
      payload
    );
  } catch {}
};

export const linkAccount = (emilyUrl: string, payload: LinkAccountRequest) => {
  return RequestUtils.httpFetch<LinkAccountResponse, LinkAccountRequest>(
    'POST',
    `${emilyUrl}/accounts/link-account`,
    undefined,
    payload
  );
};
