import styled, { css } from 'styled-components';

import { Box, Button, ChevronDownIcon, ChevronUpIcon } from '@edapp/ed-components';

export const Navbar = styled.nav`
  border-bottom: 1px solid transparent;
  background-color: ${({ theme }) => theme.colors.white};
  border-color: ${({ theme }) => theme.colors.greyHover};
`;

export const NavbarContainer = styled.div(
  ({ theme }) => css`
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 ${theme.space(3)}px;
    height: 48px;

    @media screen and (max-width: ${theme.breakpoints.md}rem) {
      padding: 0 0px;

      > li > a {
        padding: ${({ theme }) => `${theme.space(1.5)}px ${theme.space(1)}px`};
      }
    }
  `
);

export const NavbarLogo = styled.a(
  ({ theme }) => css`
    margin: 4px 0 4px -4px;
    display: block;
    float: left;
    overflow: hidden;
    cursor: pointer;

    img {
      width: 40px;
      height: 40px;
      padding: ${theme.space(1)}px;
      object-fit: contain;
    }
    @media screen and (max-width: ${theme.breakpoints.md}rem) {
      padding: 0 ${theme.space(1)}px;
      margin: ${theme.space(0.5)}px 0;
    }
  `
);

export const NavbarCollapse = styled(Box)(
  ({ theme }) => css`
    border-top: 0;
    box-shadow: none;
    max-height: 80vh;
    background-color: ${theme.colors.white};

    @media (min-width: ${theme.breakpoints.md}rem) {
      display: flex;
      width: auto;
    }
    @media (max-width: ${theme.breakpoints.md}rem) {
      margin-bottom: ${theme.space(1)}px;
      clear: both;
      overflow-y: scroll;
      overflow-x: hidden;
    }

    @media print {
      display: none;
    }
  `
);

export const NavbarLeft = styled.ul(
  ({ theme }) => css`
    margin-bottom: 0px;
    padding-left: ${theme.space(1)}px;
    @media (max-width: ${theme.breakpoints.md}rem) {
      float: left;
      margin: 0;
      flex-shrink: 0;
      margin-right: ${theme.space(1)}px;
      padding-left: 0.5em;
      width: -webkit-fill-available;
      > li {
        width: 100%;
      }

      // TODO: https://safetyculture.atlassian.net/browse/TRAINING-535
      .dropdown-backdrop {
        display: none;
      }
    }
    > li {
      position: relative;
      display: block;
      float: left;
    }

    > li > a {
      padding-top: ${theme.space(2)}px;
      padding-bottom: ${theme.space(1)}px;
      transition: all 300ms;
      line-height: ${theme.space(3)}px;
      height: ${theme.space(6)}px;
      position: relative;
      padding: ${theme.space(1)}px;
    }
  `
);

export const NavbarRight = styled.ul(
  ({ theme }) => css`
    @media (max-width: ${theme.flexboxgrid.breakpoints.md}rem) {
      padding: 0 0.5em;
      margin-right: 4px;
    }

    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    align-items: center;
    min-width: 0;
    white-space: nowrap;
    justify-content: flex-end;
    margin-bottom: 0;
  `
);

export const DropdownToggle = styled.a<{ isActive: boolean }>(
  ({ theme, isActive }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: ${isActive ? theme.colors.text : theme.colors.textMuted};

    @media (min-width: ${theme.breakpoints.md}rem) {
      &:hover,
      &:focus {
        background-color: ${theme.colors.lightBlue};
        text-decoration: none;
        color: ${({ theme }) => `${theme.colors.text}`};
      }
    }

    @media (max-width: ${theme.breakpoints.md}rem) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:hover,
      &:focus {
        text-decoration: none;
        color: ${theme.colors.text};
      }
    }
  `
);

const iconStyles = css`
  display: inline-block;
  margin-left: ${({ theme }) => theme.space(0.25)}px;
  vertical-align: middle;
  line-height: 0;
  font-weight: bold;
  path {
    fill: ${({ theme }) => theme.colors.text};
  }
  :before {
    line-height: 1em;
  }
`;

export const ChevronUp = styled(ChevronUpIcon)`
  ${iconStyles}
`;

export const ChevronDown = styled(ChevronDownIcon)`
  ${iconStyles}
`;

export const Divider = styled.li(
  ({ theme }) => css`
    height: 1px;
    margin: ${theme.space(0.5)}px 0;
    overflow: hidden;
    background-color: ${theme.colors.grey};
    position: relative;
    z-index: 1;
  `
);

export const DropdownMenu = styled.ul(
  ({ theme }) => css`
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1900;
    float: left;
    min-width: 160px;
    padding: 0 0;
    list-style: none;
    font-size: ${theme.space(2)}px;
    text-align: left;
    background-color: ${theme.colors.white};
    border: none;
    border-color: ${theme.colors.grey};
    background-clip: padding-box;
    border-radius: 4px;
    box-shadow: 0 4px ${theme.space(1)}px ${theme.colors.grey};
    margin: -1px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    > li > a {
      display: block;
      padding: 6px 12px;
      margin: 0 0;
      clear: both;
      white-space: nowrap;
      cursor: pointer;
      transition: background-color 300ms;
    }

    > li > a > .icon {
      margin-left: -2px;
    }

    > li:first-child > a {
      padding-top: ${theme.space(1)}px;
    }

    @media screen and (max-width: ${theme.breakpoints.md}rem) {
      transition: max-height 0.8s linear;
      position: static;
      float: none;
      width: auto;
      margin-top: 0;

      border: 0;
      box-shadow: none;

      > li {
        width: 100%;
      }
    }
  `
);

export const SubMenuOptionItem = styled.li<{
  disabled?: boolean;
  isActive?: boolean;
}>`
  text-decoration: none;

  &:hover > a:hover,
  > li > a:focus {
    text-decoration: none;
    background-color: ${({ disabled }) =>
      !disabled ? ({ theme }) => theme.colors.lightBlue : 'transparent'};
  }

  > a,
  > a:hover,
  > a:focus {
    cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
    font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
    color: ${({ disabled, theme, isActive }) =>
      isActive
        ? `${theme.colors.black}`
        : !disabled
        ? `${theme.colors.text}`
        : `${theme.colors.textMuted}`};

    @media screen and (max-width: ${({ theme }) => theme.breakpoints.md}rem) {
      padding: 12px 24px !important;
    }
  }
`;

export const NavbarToggle = styled(Button)(
  ({ theme }) => css`
    display: none;
    position: relative;
    float: right;
    padding: ${theme.space(1)}px;
    margin: ${theme.space(0.5)}px 0;
    border: none;
    border-radius: ${theme.space(1)}px;
    height: 40px;

    &:focus {
      outline: 0;
    }
    @media screen and (max-width: ${theme.breakpoints.md}rem) {
      display: block;
      text-align: right;
      margin-right: ${theme.space(0.5)}px;
    }
  `
);

export const NavbarMobileMenu = styled(Box)(
  ({ theme }) => css`
    display: none;
    position: relative;
    float: right;
    margin: ${theme.space(1)}px 0;
    border: none;

    &:focus {
      outline: 0;
    }
    @media screen and (max-width: ${theme.breakpoints.md}rem) {
      display: block;
      text-align: right;
      margin-right: ${theme.space(0.5)}px;
    }
  `
);
