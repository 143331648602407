import { useHippoMutation, useHippoQuery } from '@edapp/request';
import type {
  UseHippoMutationOptions,
  UseHippoMutationResult,
  UseHippoQueryOptions,
  UseHippoQueryResult
} from '@edapp/request';
import { useEmilyMutation, useEmilyQuery } from '@rio/api/hooks';
import type {
  UseEmilyMutationOptions,
  UseEmilyMutationResult,
  UseEmilyQueryOptions,
  UseEmilyQueryResult
} from '@rio/api/types';
import { multiTabLogout } from '@rio/utils/multiTabLogout';

import {
  completeRegistration,
  forgetPassword,
  getLinkedAccounts,
  getToken,
  linkAccount,
  login,
  logout,
  registrationResendInvitation,
  switchAccount,
  switchAccountSuperAdmin,
  validateTokenRegistration
} from './api';
import { QueryKeys } from './constants';
import type {
  CompleteRegistrationRequest,
  GetLinkedAccountResponse,
  GetTokenAndEmailReponse,
  GetTokenReponse,
  LinkAccountRequest,
  LinkAccountResponse,
  LoginRequest,
  LoginResponse,
  ResendInviteRequest,
  ResendInviteResponse,
  SuperAdminSwitchAccountRequest
} from './types';

export const useLogin = (
  options?: UseEmilyMutationOptions<LoginResponse, Omit<LoginRequest, '_csrf'>>
): UseEmilyMutationResult<LoginResponse, Omit<LoginRequest, '_csrf'>> => {
  return useEmilyMutation(
    (emilyCmsUrl, _, csrfToken) => payload => login(emilyCmsUrl, { ...payload, _csrf: csrfToken! }),
    options
  );
};

export const useForgetPassword = (
  options?: UseEmilyMutationOptions<void, { email: string }>
): UseEmilyMutationResult<void, { email: string }> => {
  return useEmilyMutation(
    (emilyCmsUrl, _, csrfToken) => payload =>
      forgetPassword(emilyCmsUrl, { reset: payload.email, _csrf: csrfToken! }),
    options
  );
};

export const useFetchToken = (
  options?: UseEmilyQueryOptions<GetTokenReponse>
): UseEmilyQueryResult<GetTokenReponse> => {
  return useEmilyQuery([QueryKeys.token], emilyCmsUrl => () => getToken(emilyCmsUrl), options);
};

/**
 * Used to reset user password, update first and last name and logs user in
 */
export const useCompleteRegistration = (
  options?: UseEmilyMutationOptions<string, CompleteRegistrationRequest>
): UseEmilyMutationResult<string, CompleteRegistrationRequest> => {
  return useEmilyMutation(
    emilyCmsUrl => payload => completeRegistration(emilyCmsUrl, payload),
    options
  );
};

/**
 * Used to validate token and get Email + ApplicationId
 */
export const useValidateTokenRegistration = (
  token: string,
  options?: UseHippoQueryOptions<GetTokenAndEmailReponse, GetTokenAndEmailReponse>
): UseHippoQueryResult<GetTokenAndEmailReponse> => {
  return useHippoQuery(
    [QueryKeys.registrarionValidateToken, token],
    hippoUrl => () => validateTokenRegistration(hippoUrl, token),
    options
  );
};

export const useLogout = (
  options?: UseEmilyQueryOptions<void>
): UseEmilyQueryResult<void> & { logout: UseEmilyQueryResult<void>['refetch'] } => {
  const logoutQuery = useEmilyQuery(
    ['logout'], // not needed actually since this is a mutation and not a query
    emilyCmsUrl => () => logout(emilyCmsUrl),
    {
      enabled: false,
      ...options,
      onSuccess: (...args) => {
        multiTabLogout();
        options?.onSuccess?.(...args);
      }
    }
  );
  return { logout: logoutQuery.refetch, ...logoutQuery };
};

/**
 * Used to resend invite to users
 */
export const useRegistrationResendInvitation = (
  options?: UseHippoMutationOptions<ResendInviteResponse, ResendInviteRequest>
): UseHippoMutationResult<ResendInviteResponse, ResendInviteRequest> => {
  return useHippoMutation(
    hippoCmsUrl => payload => registrationResendInvitation(hippoCmsUrl, payload.token),
    options
  );
};

/**
 * Switch / Link Accounts
 */
export const useGetLinkedAccounts = (
  options?: UseEmilyQueryOptions<GetLinkedAccountResponse>
): UseEmilyQueryResult<GetLinkedAccountResponse> => {
  return useEmilyQuery(
    [QueryKeys.token],
    emilyCmsUrl => () => getLinkedAccounts(emilyCmsUrl),
    options
  );
};

export const useSwitchAccount = (
  options?: UseEmilyMutationOptions<unknown, { id: string }>
): UseEmilyMutationResult<unknown, { id: string }> => {
  return useEmilyMutation(
    (emilyCmsUrl, _, csrfToken) => payload =>
      switchAccount(emilyCmsUrl, { switch: payload.id, _csrf: csrfToken! }),
    options
  );
};

/**
 * Please note that this endpoint always returns 302 status
 * regardless of success or failure. So there is no way to
 * detect errors from this endpoint. You will need to reload
 * the page to see the impersonated application updated.
 */
export const useSwitchAccountSuperAdmin = (
  options?: UseEmilyMutationOptions<unknown, SuperAdminSwitchAccountRequest>
): UseEmilyMutationResult<unknown, SuperAdminSwitchAccountRequest> => {
  return useEmilyMutation(
    emilyCmsUrl => request => switchAccountSuperAdmin(emilyCmsUrl, request),
    options
  );
};

export const useLinkAccount = (
  options?: UseEmilyMutationOptions<LinkAccountResponse, LinkAccountRequest>
): UseEmilyMutationResult<LinkAccountResponse, LinkAccountRequest> => {
  return useEmilyMutation(emilyCmsUrl => payload => linkAccount(emilyCmsUrl, payload), options);
};
