import * as React from 'react';

import styled, { css } from 'styled-components';

import { Box, TextIncreaseIcon } from '@edapp/ed-components';

type Props = {
  size?: 'lg' | 'md' | 'sm';
  backgroundColor: string;
  fontColor: string;
};

export const TextColorThumbnail: React.FC<Props> = ({
  size = 'lg',
  backgroundColor,
  fontColor
}) => {
  return (
    <Container containerSize={size} backgroundColor={backgroundColor} fontColor={fontColor}>
      <TextIncreaseIcon size="xl" />
    </Container>
  );
};

const Container = styled(Box)<{
  containerSize: Props['size'];
  backgroundColor: string;
  fontColor: string;
}>(({ theme, containerSize, backgroundColor, fontColor }) => {
  const sizePx = containerSize === 'lg' ? 128 : containerSize === 'md' ? 80 : 64;
  return css`
    width: ${sizePx}px;
    height: ${sizePx}px;
    background-color: ${backgroundColor};
    border-radius: ${theme.sizes.borderRadius * 2}px;
    text-align: center;

    ${TextIncreaseIcon} {
      height: inherit;

      path {
        fill: ${fontColor};
      }
    }
  `;
});
