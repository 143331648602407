import * as React from 'react';
import { Button, WarningIcon, Box, EmptyStateCard } from '@edapp/ed-components';
import { useTranslation } from 'react-i18next';

type Props = {
  text?: string;
  description?: string;
  actionText?: string;
  action?: () => void;
};

export const ErrorFallbackUI: React.FC<Props> = ({
  text,
  description,
  action = () => location.reload(),
  actionText,
  ...rest
}) => {
  const { t } = useTranslation();
  const title = text ? text : t('error-fallback.default-title');
  const buttonText = actionText ? actionText : t('common.try-again');
  return (
    <EmptyStateCard
      icon={WarningIcon}
      iconColor="red"
      title={title}
      description={description}
      {...rest}
    >
      <Box mt={3}>
        <Button onClick={action}>{buttonText}</Button>
      </Box>
    </EmptyStateCard>
  );
};
