import * as React from 'react';
import styled from 'styled-components';
import { inputStyle } from './Input';
import type { InputProps, NumberInputProps } from './types';

export const NUMBER_INPUT_TEST_ID = 'number-input';

export function enforceBounds(
  value?: number,
  min?: number,
  max?: number,
  isInteger?: boolean
): number | undefined {
  if (value == null) {
    return undefined;
  }

  if (min != null) {
    value = Math.max(value, min);
  }

  if (max != null) {
    value = Math.min(value, max);
  }

  if (isInteger) {
    value = Math.floor(value);
  }

  return value;
}

export const NumberInput: React.FC<NumberInputProps> = ({
  testId = NUMBER_INPUT_TEST_ID,
  value,
  onChange,
  min,
  max,
  isInteger,
  disabled,
  ...rest
}) => {
  const [stateValue, setStateValue] = React.useState<number | undefined>(value);

  React.useEffect(() => {
    setStateValue(enforceBounds(value, min, max, isInteger));
  }, [value, min, max, isInteger]);

  const handleChange = (changeEvent: React.ChangeEvent<HTMLInputElement>) => {
    const { value: newValue } = changeEvent.currentTarget;
    const newNumber =
      newValue === '' ? undefined : enforceBounds(parseFloat(newValue), min, max, isInteger);

    onChange?.(newNumber);

    if (value == null) {
      setStateValue(newNumber);
    }
  };

  return (
    <StyledNumberInput
      type="number"
      value={`${stateValue}`}
      onChange={handleChange}
      min={min}
      max={max}
      testId={testId}
      disabled={disabled}
      {...rest}
    />
  );
};

export const StyledNumberInput = styled.input<Omit<InputProps, 'onChange'>>`
  ${inputStyle}
`;
