import type { DiscussionType } from '@edapp/hippo-client';
import type { DiscussionSummary, DiscussionPost } from './types';
import type { Comment } from '../shared';
import type {
  PaginatedPayload,
  PaginatedResponse,
  EdErrorResponseType,
  Action,
  RecursivePartial
} from '../types';

// Fetch
export const FETCH_DISCUSSION_SUMMARIES = 'FETCH_DISCUSSION_SUMMARIES';
export type FetchDiscussionSummariesPayload = PaginatedPayload<{
  searchTerm: string;
  courseId: string;
}>;
export const fetchDiscussionSummaries = (
  searchTerm: string,
  courseId: string,
  page: number,
  pageSize: number = 25
): Action<FetchDiscussionSummariesPayload> => ({
  type: FETCH_DISCUSSION_SUMMARIES,
  payload: { searchTerm, courseId, page, pageSize }
});

export const FETCH_DISCUSSION_SUMMARIES_SUCCESS = 'FETCH_DISCUSSION_SUMMARIES_SUCCESS';
export type FetchDiscussionSummariesSuccessPayload = PaginatedResponse<DiscussionSummary>;

export const FETCH_DISCUSSION_SUMMARIES_FAILURE = 'FETCH_DISCUSSION_SUMMARIES_FAILURE';
export type FetchDiscussionSummariesFailurePayload = EdErrorResponseType;

export const FETCH_DISCUSSION = 'FETCH_DISCUSSION';
export type FetchDiscussion = { discussionId: string };
export const fetchDiscussion = (discussionId: string): Action<FetchDiscussion> => ({
  type: FETCH_DISCUSSION,
  payload: { discussionId }
});

export const FETCH_DISCUSSION_SUCCESS = 'FETCH_DISCUSSION_SUCCESS';
export type FetchDiscussionSuccess = DiscussionType;

export const FETCH_DISCUSSION_FAILURE = 'FETCH_DISCUSSION_FAILURE';
export type FetchDiscussionFailure = EdErrorResponseType;

export const FETCH_POSTS = 'FETCH_POSTS';
export type FetchPosts = PaginatedPayload<{ discussionId: string }>;
export const fetchPosts = (
  discussionId: string,
  page: number = 1,
  pageSize: number = 10
): Action<FetchPosts> => ({
  type: FETCH_POSTS,
  payload: { discussionId, page, pageSize }
});

export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS';
export type FetchPostsSuccess = {
  discussionId: string;
  page: number;
  totalCount: number;
  items: DiscussionPost[];
};

export const FETCH_POSTS_FAILURE = 'FETCH_POSTS_FAILURE';
export type FetchPostsFailure = EdErrorResponseType;

export const FETCH_COMMENTS = 'FETCH_COMMENTS';
export type FetchComments = PaginatedPayload<{ discussionId: string; postId: string }>;
export const fetchComments = (
  discussionId: string,
  postId: string,
  page: number = 1,
  pageSize: number = 5
): Action<FetchComments> => ({
  type: FETCH_COMMENTS,
  payload: { discussionId, postId, page, pageSize }
});

export const FETCH_COMMENTS_SUCCESS = 'FETCH_COMMENTS_SUCCESS';
export type FetchCommentsSuccess = {
  totalCount: number;
  items: Comment[];
  postId: string;
  page: number;
};

export const FETCH_COMMENTS_FAILURE = 'FETCH_COMMENTS_FAILURE';
export type FetchCommentsFailure = EdErrorResponseType;

export const FETCH_INITIAL_COMMENTS = 'FETCH_INITIAL_COMMENTS';
export type FetchInitialComments = PaginatedPayload<{ postIds: string[]; discussionId: string }>;

export const fetchInitialComments = (
  postIds: string[],
  discussionId: string,
  pageSize: number = 2
): Action<FetchInitialComments> => ({
  type: FETCH_INITIAL_COMMENTS,
  payload: { postIds, page: 1, pageSize, discussionId }
});

export const FETCH_INITIAL_COMMENTS_SUCCESS = 'FETCH_INITIAL_COMMENTS_SUCCESS';
export type FetchInitialCommentsSuccess = {
  [postId: string]: { totalCount: number; items: Comment[] } | undefined;
};

export const FETCH_INITIAL_COMMENTS_FAILURE = 'FETCH_COMMENTS_FAILURE';
export type FetchInitialCommentsFailure = EdErrorResponseType;

// Set & Save
export const SET_DISCUSSION = 'SET_DISCUSSION';
export type SetDiscussion = {
  discussionId: string;
  data: RecursivePartial<DiscussionType>;
  skipSave: boolean;
};
export const setDiscussion = (
  discussionId: string,
  data: RecursivePartial<DiscussionType>,
  skipSave: boolean = false
): Action<SetDiscussion> => ({
  type: SET_DISCUSSION,
  payload: { discussionId, data, skipSave }
});

export const SAVE_DISCUSSION = 'SAVE_DISCUSSION';
export type SaveDiscussion = {
  discussionId: string;
  data: RecursivePartial<DiscussionType>;
  oldData: DiscussionType;
};
export const saveDiscussion = (
  discussionId: string,
  data: RecursivePartial<DiscussionType>,
  oldData: DiscussionType
): Action<SaveDiscussion> => ({
  type: SAVE_DISCUSSION,
  payload: { discussionId, data, oldData }
});

export const SAVE_DISCUSSION_SUCCESS = 'SAVE_DISCUSSION_SUCCESS';

export const SAVE_DISCUSSION_FAILURE = 'SAVE_DISCUSSION_FAILURE';
export type SaveDiscussionFailure = {
  discussionId: string;
  discussionStateBeforeSave: DiscussionType;
  error: EdErrorResponseType;
};

export const ADD_COMMENT = 'ADD_COMMENT';
export type AddComment = {
  text: string;
  postId: string;
  discussionId: string;
};
export const addComment = (
  discussionId: string,
  postId: string,
  text: string
): Action<AddComment> => ({
  type: ADD_COMMENT,
  payload: { text, postId, discussionId }
});

export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export type AddCommentSuccess = { postId: string; comment: Comment };

export const ADD_COMMENT_FAILURE = 'ADD_COMMENT_FAILURE';
export type AddCommentFailure = EdErrorResponseType;

export const EDIT_COMMENT = 'EDIT_COMMENT';
export type EditComment = {
  text: string;
  commentId: string;
  postId: string;
  discussionId: string;
};
export const editComment = (
  discussionId: string,
  postId: string,
  commentId: string,
  text: string
): Action<EditComment> => ({
  type: EDIT_COMMENT,
  payload: { text, commentId, postId, discussionId }
});

export const EDIT_COMMENT_SUCCESS = 'EDIT_COMMENT_SUCCESS';
export type EditCommentSuccess = { postId: string; comment: Comment };

export const EDIT_COMMENT_FAILURE = 'EDIT_COMMENT_FAILURE';
export type EditCommentFailure = EdErrorResponseType;

// Delete
export const DELETE_POST = 'DELETE_POST';
export type DeletePost = {
  postId: string;
  discussionId: string;
};
export const deletePost = (postId: string, discussionId: string): Action<DeletePost> => ({
  type: DELETE_POST,
  payload: { postId, discussionId }
});

export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS';
export type DeletePostSuccess = { postId: string; discussionId: string };

export const DELETE_POST_FAILURE = 'DELETE_POST_FAILURE';
export type DeletePostFailure = EdErrorResponseType;

export const DELETE_COMMENT = 'DELETE_COMMENT';
export type DeleteComment = {
  postId: string;
  commentId: string;
};
export const deleteComment = (postId: string, commentId: string): Action<DeleteComment> => ({
  type: DELETE_COMMENT,
  payload: { postId, commentId }
});

export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT_SUCCESS';
export type DeleteCommentSuccess = { postId: string; commentId: string };

export const DELETE_COMMENT_FAILURE = 'DELETE_COMMENT_FAILURE';
export type DeleteCommentFailure = EdErrorResponseType;
