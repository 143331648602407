import * as React from 'react';

import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

import type { StyleConfiguration } from '@edapp/authoring-logic';
import { Badge, Box, Typography } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';

import { PostPill } from '../../discussions/post-pill/PostPill';
import { usePostStatus } from '../../discussions/post-pill/usePostStatus';
import type { PostStatus } from '../../discussions/types';
import { cssPropertiesToStyledCss } from '../../utils/cssPropertiesToStyledCss';

type Props = {
  title: string;
  description: string;
  config?: StyleConfiguration;
  incompleteTaskCount?: number;
  status?: PostStatus;
  statusOnClick?: () => void;
  action?: React.ReactNode | ((styleObject: React.CSSProperties) => React.ReactNode);
};

export const CoursewareHeader: React.FC<Props> = ({
  title,
  description,
  config,
  status,
  statusOnClick,
  action,
  incompleteTaskCount,
  ...rest
}) => {
  const styleObject: React.CSSProperties = React.useMemo(
    () => ({
      backgroundColor: config?.colors?.background ?? '',
      backgroundImage: !!config?.background ? `url("${config.background}")` : 'none',
      color: config?.colors?.text || ''
    }),
    [config]
  );

  const statusStyle = usePostStatus(status);

  return (
    <StyledWrapper p={3} pt={10} styleObject={styleObject} {...rest}>
      <MotionBox px="lg">
        <MaxWidthWrapper flex flexDirection="column" gap={2}>
          {title && (
            <Text
              component="h3"
              data-testid="post-title"
              variant="titleMedium"
              textAlign="center"
              color="currentColor"
              clamp={2}
            >
              {title}
            </Text>
          )}
          {description && <Desc color="inherit">{description}</Desc>}
        </MaxWidthWrapper>
      </MotionBox>
      <StickyHeader px="lg" py="md">
        {!!status && (
          <Box pb="sm">
            {incompleteTaskCount && incompleteTaskCount > 0 ? (
              <Badge value={incompleteTaskCount}>
                <PostPill {...statusStyle} onClick={statusOnClick} />
              </Badge>
            ) : (
              <PostPill {...statusStyle} onClick={statusOnClick} />
            )}
          </Box>
        )}

        {typeof action === 'function' ? action(styleObject) : action}
      </StickyHeader>
    </StyledWrapper>
  );
};

const MotionBox = motion(Box);
const StyledWrapper = styled(Box)<{ styleObject: React.CSSProperties }>`
  ${({ styleObject, theme }) => css`
    background-color: ${theme.colors.blue};
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-bottom: 1px solid ${theme.colors.grey};
    color: ${theme.colors.white};
    box-sizing: border-box;
    ${() => cssPropertiesToStyledCss(styleObject)};
  `}
`;

const StickyHeader = styled(Box)`
  position: relative;
  text-align: center;
  z-index: 10;
`;

const MaxWidthWrapper = styled(Box)`
  margin: auto;
  overflow: hidden;
`;

const Desc = styled(Typography)`
  display: block;
  text-align: center;
  white-space: pre-line;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  max-height: 200px;
  overflow-y: auto;
`;
