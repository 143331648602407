import { CoursewareStatus } from '@edapp/ed-components';
import type { MandatoryNoDueDateType } from '@edapp/authoring-logic';
import type { CourseType, StyleConfigurationType } from './types';

export enum MandatoryTypes {
  NotSpecified = 'not-specified',
  DueBy = 'due-by',
  RelativeDueBy = 'relative-due-by',
  NoDueDate = 'no-due-date',
  RecurringCompletionDate = 'recurring-completion-date',
  RecurringPublishDate = 'recurring-publish-date',
  RecurringStaticDate = 'recurring-static-date'
}

export const initialStyleConfiguration: StyleConfigurationType = {
  colors: {},
  hasScormExitButton: false,
  preventLogoutOnExit: false,
  disableInteractionSummary: false,
  hasAnswerFeedback: false,
  pagination: true,
  language: '',
  direction: 'ltr',
  disableLessonScore: false,
  minimumScore: 0,
  indicator: true,
  pageNumbers: true,
  logo: '',
  background: '',
  enableCustomCSS: false,
  customCSS: '',
  soundDisabled: false,
  preferences: {
    useParentBackgroundImage: true,
    useParentColors: true,
    useParentCustomCss: true,
    useParentLogo: true
  }
};

export const initialMandatoryState: MandatoryNoDueDateType = {
  type: MandatoryTypes.NoDueDate,
  enabled: false
};

export const initialCourseState: CourseType = {
  id: '',
  modified: undefined,
  created: undefined,
  thumbnail: '',
  title: '',
  description: '',
  brandingImage: '',
  brandingTextColor: 'white',
  externalIdentifier: '',
  hasNextLessonButton: true,
  planning: {},
  timeSpent: undefined,
  totalNumberOfUsers: undefined,
  usageInformation: undefined,
  universalAccess: false,
  userGroups: [],
  metaTags: {},
  collectionId: '',
  collectionTitle: '',
  collectionRank: 0,
  collectionIsManual: false,
  prerequisites: [],
  sequentialPlaylists: [],
  lessons: [],
  thomasUrl: '',
  config: {
    colors: {},
    hasScormExitButton: false,
    preventLogoutOnExit: false,
    disableInteractionSummary: false,
    hasAnswerFeedback: false,
    pagination: true,
    language: '',
    direction: 'ltr',
    disableLessonScore: false,
    minimumScore: 0,
    indicator: true,
    pageNumbers: true,
    logo: '',
    background: '',
    enableCustomCSS: false,
    customCSS: '',
    soundDisabled: false,
    preferences: {
      useParentBackgroundImage: true,
      useParentColors: true,
      useParentCustomCss: true,
      useParentLogo: true
    }
  },
  internationalisation: {
    locale: 'en'
  },
  display: {
    active: false
  },
  courseStatus: CoursewareStatus.DRAFT,
  publishedDate: undefined,
  publishOn: undefined,
  unpublishOn: undefined,
  completionCriteria: {
    openToComplete: false,
    percentage: 100,
    lessons: [],
    milestone: 'percentage',
    certificate: {
      enabled: true
    }
  },
  dueBy: {},
  mandatory: initialMandatoryState,
  logo: '',
  logoBackgroundColor: 'navy',
  modules: [],
  tags: [],
  libraryImage: '',
  isContentReady: false,
  isFromContentLibrary: false,
  isSponsored: false,
  isInappropriate: false,
  companyName: '',
  microCredential: null,
  applicationProfileId: '',
  leaderboard: null,
  versionNumber: 1,
  hasUnpublishedChanges: false,
  userPermissions: [],
  generationStatus: 'Generated'
};

export enum CourseTagTypes {
  CATEGORY = 'Category',
  INDUSTRY = 'Industry'
}
