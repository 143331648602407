import * as React from 'react';
import GOLD_COIN from '../../assets/gold-coin.json';
import SILVER_COIN from '../../assets/silver-coin.json';
import BRONZE_COIN from '../../assets/bronze-coin.json';
import { Lottie, Typography } from '@edapp/ed-components';
import styled, { css } from 'styled-components';

export const RankingOrder: React.FC<{
  rank: number;
  isNotAttempted: boolean;
  textSize: 'subtitle1' | 'small';
}> = ({ rank, isNotAttempted, textSize }) => {
  if (isNotAttempted) {
    return <StyledTypography variant={textSize}>-</StyledTypography>;
  }
  switch (rank) {
    case 1:
      return <StyledLottie data={GOLD_COIN} autoplay={true} loop={true} />;
    case 2:
      return <StyledLottie data={SILVER_COIN} autoplay={true} loop={true} />;
    case 3:
      return <StyledLottie data={BRONZE_COIN} autoplay={true} loop={true} />;
    default:
      return <StyledTypography variant={textSize}>{rank}</StyledTypography>;
  }
};

const StyledTypography = styled(Typography)(
  ({ theme }) => css`
    ${theme.lineClamp(1)}
    overflow: visible !important;
    min-width: 16px;
    flex-shrink: 0;
    text-align: center;
  `
);

const StyledLottie = styled(Lottie)`
  width: 64px;
  flex-shrink: 0;
  height: 100%;
`;
