import type {
  SetUserGroupItem,
  FetchUserGroup,
  FetchUserGroupSuccess,
  FetchUserGroupFailure,
  FetchUserGroupStatus,
  FetchUserGroupStatusSuccess,
  FetchUserGroupStatusFailure,
  SaveUserGroup,
  SaveUserGroupSuccess,
  SaveUserGroupFailure,
  FetchUserGroupNames,
  FetchUserGroupNamesSuccess,
  FetchUserGroupNamesFailure
} from './types';
import { createAction } from '@edapp/utils';

export enum ActionType {
  SET_USER_GROUP_ITEM = 'SET_USER_GROUP_ITEM',

  FETCH_USER_GROUP = 'FETCH_USER_GROUP',
  FETCH_USER_GROUP_SUCCESS = 'FETCH_USER_GROUP_SUCCESS',
  FETCH_USER_GROUP_FAILURE = 'FETCH_USER_GROUP_FAILURE',

  FETCH_USER_GROUP_STATUS = 'FETCH_USER_GROUP_STATUS',
  FETCH_USER_GROUP_STATUS_SUCCESS = 'FETCH_USER_GROUP_STATUS_SUCCESS',
  FETCH_USER_GROUP_STATUS_FAILURE = 'FETCH_USER_GROUP_STATUS_FAILURE',

  SAVE_USER_GROUP = 'SAVE_USER_GROUP',
  SAVE_USER_GROUP_SUCCESS = 'SAVE_USER_GROUP_SUCCESS',
  SAVE_USER_GROUP_FAILURE = 'SAVE_USER_GROUP_FAILURE',

  FETCH_USER_GROUP_NAMES = 'FETCH_USER_GROUP_NAMES',
  FETCH_USER_GROUP_NAMES_SUCCESS = 'FETCH_USER_GROUP_NAMES_SUCCESS',
  FETCH_USER_GROUP_NAMES_FAILURE = 'FETCH_USER_GROUP_NAMES_FAILURE'
}

export const setUserGroupItem: SetUserGroupItem = userGroup =>
  createAction(ActionType.SET_USER_GROUP_ITEM, { userGroup });

// FETCH
export const fetchUserGroup: FetchUserGroup = id =>
  createAction(ActionType.FETCH_USER_GROUP, { id });
export const fetchUserGroupSuccess: FetchUserGroupSuccess = payload =>
  createAction(ActionType.FETCH_USER_GROUP_SUCCESS, payload);
export const fetchUserGroupFailure: FetchUserGroupFailure = payload =>
  createAction(ActionType.FETCH_USER_GROUP_FAILURE, payload);

// Fetch User Group Status
export const fetchUserGroupStatus: FetchUserGroupStatus = id =>
  createAction(ActionType.FETCH_USER_GROUP_STATUS, { id });
export const fetchUserGroupStatusSuccess: FetchUserGroupStatusSuccess = payload =>
  createAction(ActionType.FETCH_USER_GROUP_STATUS_SUCCESS, payload);
export const fetchUserGroupStatusFailure: FetchUserGroupStatusFailure = payload =>
  createAction(ActionType.FETCH_USER_GROUP_STATUS_FAILURE, payload);

// SAVE
export const saveUserGroup: SaveUserGroup = (id, history) =>
  createAction(ActionType.SAVE_USER_GROUP, { id, history });
export const saveUserGroupSuccess: SaveUserGroupSuccess = payload =>
  createAction(ActionType.SAVE_USER_GROUP_SUCCESS, payload);
export const saveUserGroupFailure: SaveUserGroupFailure = payload =>
  createAction(ActionType.SAVE_USER_GROUP_FAILURE, payload);

// *FETCH USERGROUPS
export const fetchUserGroupNames: FetchUserGroupNames = () =>
  createAction(ActionType.FETCH_USER_GROUP_NAMES, {});
export const fetchUserGroupNamesSuccess: FetchUserGroupNamesSuccess = payload =>
  createAction(ActionType.FETCH_USER_GROUP_NAMES_SUCCESS, payload);
export const fetchUserGroupNamesFailure: FetchUserGroupNamesFailure = payload =>
  createAction(ActionType.FETCH_USER_GROUP_NAMES_FAILURE, payload);
