import * as React from 'react';
import styled, { css } from 'styled-components';

type ImageProps = {
  src: string;
  height?: number;
  width?: number;
};

export const ImagePlaceholder: React.FC<ImageProps> = ({ src, height, width, ...rest }) => {
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    setLoaded(false);
  }, [src]);

  const _handleLoaded = () => {
    setLoaded(true);
  };

  return (
    <Wrapper {...rest}>
      <Image src={src} onLoad={_handleLoaded} width={width} height={height} loaded={loaded} />
    </Wrapper>
  );
};

const Wrapper = styled.span(
  ({ theme }) => css`
    display: inline-block;
    background-color: ${theme.colors.lightGrey};
  `
);

const Image = styled.img<{ loaded: boolean } & Pick<ImageProps, 'height' | 'width'>>(
  ({ loaded, height, width }) => css`
    width: ${width ? `${width}px` : '100%'};
    height: ${height ? `${height}px` : 'auto'};
    ${!loaded &&
    css`
      visibility: hidden;
    `}
    ${!height &&
    !loaded &&
    css`
      padding-bottom: 100%;
      height: 0;
    `}
  `
);
