import React from 'react';

import { useFetchUser } from '@edapp/user-info';
import { LoadingIndicator } from '@rio/containers/Courseware/CourseReview/LoadingIndicator';

import { ModalTransition, ModalTransitionContainer } from './Transition';
import { ForgetPassword } from './steps/ForgetPassword';
import { ForgetPasswordSuccess } from './steps/ForgetPasswordSuccess';
import { LoginModal } from './steps/LoginModal';
import { UpdateName } from './steps/UpdateName';

enum Step {
  Login,
  ForgetPassword,
  ForgetPasswordSuccess,
  UpdateName
}

type Props = {
  email?: string;
  isLoggedIn?: boolean;
  isLoading?: boolean;
  onSuccess: () => Promise<void> | void;
};

export const AuthenticationModalContent: React.FC<Props> = ({
  email,
  isLoggedIn,
  isLoading,
  onSuccess
}) => {
  const [currentStep, setCurrentStep] = React.useState<Step>(
    isLoggedIn ? Step.UpdateName : Step.Login
  );
  const { refetch: refetchUserInfo } = useFetchUser({ enabled: false });

  const handleAuthSuccess = async () => {
    try {
      await onSuccess();
      const response = await refetchUserInfo();
      const firstName = response.data?.userFirstName || '';
      const lastName = response.data?.userLastName || '';
      if (!firstName || !lastName) {
        setCurrentStep(Step.UpdateName);
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <ModalTransitionContainer initialHeight={316}>
      {(() => {
        if (isLoading) {
          return <LoadingIndicator />;
        }
        switch (currentStep) {
          case Step.Login: {
            return (
              <ModalTransition key={Step.Login}>
                <LoginModal
                  email={email}
                  onSuccess={handleAuthSuccess}
                  onClickForgetPassword={() => setCurrentStep(Step.ForgetPassword)}
                />
              </ModalTransition>
            );
          }
          case Step.ForgetPassword: {
            return (
              <ModalTransition key={Step.ForgetPassword}>
                <ForgetPassword
                  email={email}
                  onSuccess={() => setCurrentStep(Step.ForgetPasswordSuccess)}
                  onClickBack={() => setCurrentStep(Step.Login)}
                />
              </ModalTransition>
            );
          }
          case Step.ForgetPasswordSuccess: {
            return (
              <ModalTransition key={Step.ForgetPasswordSuccess}>
                <ForgetPasswordSuccess onClickOk={() => setCurrentStep(Step.Login)} />
              </ModalTransition>
            );
          }
          case Step.UpdateName: {
            return (
              <ModalTransition key={Step.UpdateName}>
                <UpdateName />
              </ModalTransition>
            );
          }
        }
      })()}
    </ModalTransitionContainer>
  );
};
