import type * as moment from 'moment';

// * Pre processing Actionable Report Types
export type UserIdAndUserGroupId = {
  userId: string;
  userGroupId: string;
};

export type ActionableReportItem =
  | NotCompletedReportItem
  | NotStartedReportItem
  | NotLoggedInReportItem;

export type NotCompletedReportItem = {
  email: string;
  lastLoginDate: ActionableReportsDate;
  totalLessons: number;
  lessonsCompleted: number;
} & UserIdAndUserGroupId;

export type NotStartedReportItem = {
  email: string;
  lastLoginDate: ActionableReportsDate;
} & UserIdAndUserGroupId;

export type NotLoggedInReportItem = {
  appUserId?: string;
  userGroupId: string;
  email: string;
  registered?: ActionableReportsDate;
};

export type LowCompletionReportItem = NotCompletedReportItem;

// * ACTIONABLE REPORT ITEM
export type ActionableReport =
  | NotCompletedActionableReport
  | NotStartedActionableReport
  | NotLoggedInActionableReport
  | LowCompletionActionableReport;

export type UserAndUserGroupNames = {
  user: string;
  userGroup: string;
};

export type ActionableReportsDate = moment.Moment | Date | string;

export type NotCompletedActionableReport = NotCompletedActionableReportItem & UserAndUserGroupNames;

export type NotStartedActionableReport = NotStartedActionableReportItem & UserAndUserGroupNames;

export type NotLoggedInActionableReport = NotLoggedInActionableReportItem & UserAndUserGroupNames;

export type LowCompletionActionableReport = NotCompletedActionableReport;

export type NotCompletedActionableReportItem = {
  loginDate: ActionableReportsDate;
  completionRate: number;
};

export type NotStartedActionableReportItem = {
  lastLoginDate: ActionableReportsDate;
};

export type NotLoggedInActionableReportItem = {
  registered: ActionableReportsDate;
};

export type LowCompletionActionableReportItem = NotCompletedActionableReportItem;

export type ReportItem = {
  userId: string;
  email: string;
  userGroupId: string;
  lessonsCompleted?: number;
  lastLoginDate?: Date | string;
  totalLessons?: number;
};

// * ADDITIONAL PROPS
export enum ReportTypes {
  NOT_COMPLETED = 'notCompleted',
  NOT_STARTED = 'notStarted',
  NOT_LOGGED_IN = 'notLoggedIn',
  LOW_COMPLETION = 'lowCompletion'
}

export type AdditionalActionableReportsProps = {
  isClearSelectedOptions: boolean;
  reportType:
    | ReportTypes.NOT_COMPLETED
    | ReportTypes.NOT_STARTED
    | ReportTypes.NOT_LOGGED_IN
    | ReportTypes.LOW_COMPLETION;
};
