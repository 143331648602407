/// <reference types="./animated-number-react" />

import * as React from 'react';
import styled, { css } from 'styled-components';
import { LEADERBOARD_MY_POSITION_ID } from '../../constants';
import type { RankingParticipant } from '../../types';
import { Avatar, Box, Typography } from '@edapp/ed-components';
import { rgba } from '@edapp/themes';
import { RankingOrder } from './RankingOrder';

type Props = RankingParticipant & {
  textSize: 'subtitle1' | 'small';
  textTransform?: React.CSSProperties['textTransform'];
  color?: string;
  isNotAttempted?: boolean;
  isTransparent?: boolean;
};

const LeaderboardRankingItem: React.FC<Props> = ({
  isMe,
  color,
  textSize,
  textTransform,
  isNotAttempted,
  participantName,
  participantAvatarUrl,
  totalScore,
  rank,
  type,
  isTransparent = false
}) => (
  <Wrapper
    id={isMe ? LEADERBOARD_MY_POSITION_ID : undefined}
    isMe={isMe}
    color={color}
    isTransparent={isTransparent}
  >
    <StyledBox
      flex={true}
      flexDirection="row"
      alignItems="center"
      textAlign="left"
      px={1}
      py={2}
      gap={2}
    >
      <RankingOrder textSize={textSize} isNotAttempted={isNotAttempted} rank={rank} />
      <Avatar src={participantAvatarUrl} alt={participantName} />
      <ParticipantName variant={textSize} textTransform={textTransform}>
        {isMe && type !== 'group' ? 'You' : participantName}
      </ParticipantName>
    </StyledBox>

    <Typography variant={textSize}>{totalScore}</Typography>
  </Wrapper>
);

type StyledProps = { isMe: boolean; isTransparent?: boolean; color?: string };

// We need to set max-width to 'hack' css into letting use use ellipses without width: Zpx
const StyledBox = styled(Box)`
  max-width: 90%;
  width: 100%;
  height: 100%;
`;

const ParticipantName = styled(Typography)(
  ({ theme }) => css`
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    display: inline-block !important;
    white-space: nowrap !important;
    ${theme.lineClamp(1)}
  `
);

const Wrapper = styled.div<StyledProps>(
  ({ theme, color = 'text', isMe, isTransparent }) => css`
    color: ${theme.colors[color] || color};
    background-color: ${theme.colors.white};
    &:first-child {
      border-top-right-radius: ${theme.sizes.borderRadius}px;
      border-top-left-radius: ${theme.sizes.borderRadius}px;
    }
    &:last-child {
      border-bottom-right-radius: ${theme.sizes.borderRadius}px;
      border-bottom-left-radius: ${theme.sizes.borderRadius}px;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding-left: ${theme.space(1)}px;
    padding-right: ${theme.space(2)}px;

    ${!!isTransparent &&
    css`
      background-color: ${rgba(theme.colors.fixedBlack, 0.1)};
    `};

    ${!!isMe &&
    !isTransparent &&
    css`
      color: ${theme.colors.blue};
      border: 1px solid ${theme.colors.blue};
    `};

    ${!!isMe &&
    isTransparent &&
    css`
      background-color: ${rgba(theme.colors.white, 0.25)};
    `};
  `
);

export { LeaderboardRankingItem };
