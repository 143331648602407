import type { LeaderboardType, RankingParticipant } from '../../types';
import { differenceInDays } from 'date-fns';

type ParticipantsType = {
  currentUser?: RankingParticipant;
  topThree: RankingParticipant[];
  otherParticipants: RankingParticipant[];
};

export const getParticipants = (participants: RankingParticipant[]): ParticipantsType => {
  const initialReduceState: ParticipantsType = { topThree: [], otherParticipants: [] };

  return participants.reduce((acc, item, index) => {
    if (!acc.currentUser && item.isMe) acc.currentUser = item;
    if (index < 3) {
      acc.topThree.push(item);
    } else {
      acc.otherParticipants.push(item);
    }

    return acc;
  }, initialReduceState);
};

export const getDaysDiffMessage = (startDate: Date, endDate: Date) => {
  const startDateDiffDays = differenceInDays(startDate, new Date());
  const endDateDiffDays = differenceInDays(endDate, new Date());

  if (startDateDiffDays > 0) {
    return `Starts in ${startDateDiffDays} day${startDateDiffDays === 1 ? '' : 's'}!`;
  }

  if (endDateDiffDays < 0) {
    return 'Ended!';
  } else if (endDateDiffDays === 0) {
    return 'Ends today!';
  } else {
    return `Ends in ${endDateDiffDays} day${endDateDiffDays === 1 ? '' : 's'}!`;
  }
};

export const filterParticipants = (
  participants: RankingParticipant[],
  leaderboardType: LeaderboardType
) => {
  if (leaderboardType === 'Course') return participants;
  if (leaderboardType === 'Group') {
    return participants.filter(participant => participant.type === 'group');
  } else return participants.filter(participant => participant.type === 'individual');
};
