import type { FileTypeResult } from 'file-type';
import { fromBuffer } from 'file-type/browser';
import isSVG from 'is-svg';

import { genId } from '@edapp/utils';

import { templates } from '../store/form/templateData';
import type { ContentNavigationCourse } from '../store/form/types';
import type { SlideType, TemplateType } from '../store/types';
import { extractQuestions } from './questions/lib';

export { supportedFormats, supportedFormatsAsString } from '@edapp/utils';

export const getFileType = (
  file: File
): Promise<{
  ext: FileTypeResult['ext'] | 'svg';
  mime: FileTypeResult['mime'] | 'image/svg+xml';
}> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = () => reject(null);
    if (file.type.includes('svg')) {
      reader.readAsText(file);
      reader.onload = () => {
        const result = reader.result as string;
        if (isSVG(result)) {
          resolve({ ext: 'svg', mime: 'image/svg+xml' });
        } else {
          reject(null);
        }
      };
    } else {
      reader.readAsArrayBuffer(file);
      reader.onload = () => {
        const buffer = reader.result as ArrayBuffer;
        // this package doesn't support text-based formats like .txt, .csv, .svg, etc.
        fromBuffer(buffer).then(result => {
          result ? resolve(result) : reject(result);
        });
      };
    }
  });
};

export const updateSlideMetadata = (slide: SlideType) => {
  const slideCopy = JSON.parse(JSON.stringify(slide));
  const questions = extractQuestions(slideCopy); // add questions[] as metadata.questions
  slideCopy.metadata = { questions };

  return slideCopy;
};

export const addDefaultSlideChallengeConfiguration = (slide: SlideType): SlideType => {
  const challengeConfig = slide.data.challenge_configuration || {};
  const weighting = challengeConfig.weighting ?? 1;
  return {
    ...slide,
    data: {
      ...slide.data,
      challenge_configuration: {
        ...challengeConfig,
        weighting
      }
    }
  };
};

/**
 * Get Template from Slide type
 * @param slide
 */
export const getTemplateFromSlide = (slide: SlideType) =>
  templates.find((template: TemplateType) => template.type === slide.type);

/**
 * Update navigation display index
 * @param slides
 */
export const updateSlidesDisplayIndexes = (slides: ReadonlyArray<SlideType>): SlideType[] => {
  const slidesCopy = JSON.parse(JSON.stringify(slides));
  return slidesCopy.map((s: SlideType, i: number) => {
    s.displayIndex = `${i + 1}`;
    return s;
  });
};

/**
 * Get Template index from a slide
 * @param slide
 */
export const getTemplateIndexFromSlide = (slide: SlideType) =>
  templates.findIndex((template: TemplateType) => template.type === slide.type);

/**
 * Tries to get frist lesson from the first course that contains lessons
 * @param courses
 * @param lessonId
 */
export const getFirstLessonFromFirstCourse = (courses: ContentNavigationCourse[]) => {
  const firstCourseWithLesson = courses.find(c => !!c.lessons && c.lessons.length > 0);
  if (!firstCourseWithLesson) {
    return undefined;
  }
  return firstCourseWithLesson.lessons[0];
};

/**
 * Escape all special char from regex
 * @param text to escape
 */
export const escapeRegExp = (text: string) => text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

export const replacePlaceholderId = (id: string, forceReplace: boolean = false): string =>
  id === '#GENUUID#' || forceReplace ? genId() : id;

// Surveys & Multiple choice data.question
export const generateQuestionId = (slide: SlideType, forceReplace: boolean = false): SlideType => {
  const id = slide.data && slide.data.question && slide.data.question.id;

  return id
    ? {
        ...slide,
        data: {
          ...slide.data,
          question: {
            ...slide.data.question,
            id: replacePlaceholderId(id, forceReplace)
          }
        }
      }
    : slide;
};
// Carousel templates data.answers
export const generateAnswerIds = (slide: SlideType, forceReplace: boolean = false): SlideType => {
  const answers = slide.data && slide.data.answers ? slide.data.answers : [];
  return answers.length
    ? {
        ...slide,
        data: {
          ...slide.data,
          answers: answers.map((answer: { id: string; content?: string }) => ({
            ...answer,
            id: replacePlaceholderId(answer.id, forceReplace)
          }))
        }
      }
    : slide;
};

export const ensureValidSubtype = (slide: SlideType) => ({
  ...slide,
  subtype: slide.subtype || 'default'
});

export const updateSlide = (slide: SlideType, forceReplace: boolean = false) => {
  const cleanSubtype = ensureValidSubtype(slide);
  const cleanQuestions = generateQuestionId(cleanSubtype, forceReplace);
  const cleanAnswers = generateAnswerIds(cleanQuestions, forceReplace);
  return updateSlideMetadata(cleanAnswers);
};

// Check whether the slide is an online slide or not
export const isOnlineSlide = (slide: SlideType) => {
  switch (slide.type) {
    case 'default':
      return slide.data?.titleType === 'iframe';

    case 'video':
    case 'video-collection':
      return !!slide?.data?.stream;

    case 'youtube-video-embed':
    case 'vimeo-video-embed':
    case 'url':
    case 'peer-authoring':
    case 'scorm':
    case 'aicc':
      return true;

    default:
      return false;
  }
};
