import { Pill, Typography } from '@edapp/ed-components';
import type { IconComponent } from '@edapp/ed-components';
import type { ColorsKeys } from '@edapp/themes';
import * as React from 'react';
import styled from 'styled-components';

export type PostPillProps = {
  pillBackgroundColor: ColorsKeys;
  pillTextColor: ColorsKeys;
  pillIcon: IconComponent;
  text: string;
  onClick?: () => void;
};

export const PostPill: React.FC<PostPillProps> = ({
  pillBackgroundColor,
  pillTextColor,
  pillIcon: Icon,
  text,
  onClick
}) => (
  <StyledPill onClick={onClick} backgroundColor={pillBackgroundColor} textColor={pillTextColor}>
    <Icon color={pillTextColor} />
    <Typography color={pillTextColor} variant="subtitle2" ml={1}>
      {text}
    </Typography>
  </StyledPill>
);

const StyledPill = styled(Pill)`
  cursor: pointer;
`;
