import { RequestUtils } from '@edapp/request';
import type {
  ApplicationContextRequest,
  ApplicationContextResponse,
  ApplicationResponse
} from './types';

export const getApplicationContext = async (
  hippoUrl: string,
  userToken: string,
  request?: ApplicationContextRequest
): Promise<ApplicationContextResponse> => {
  return await RequestUtils.httpFetch<ApplicationContextResponse, ApplicationContextRequest>(
    'GET',
    `${hippoUrl}/api/application/context`,
    userToken,
    request
  );
};

export const getApplication = async (
  hippoUrl: string,
  userToken: string
): Promise<ApplicationResponse> => {
  return await RequestUtils.httpFetch<ApplicationResponse>(
    'GET',
    `${hippoUrl}/api/accounts/settings`,
    userToken
  );
};

export const getApplicationAndContext = async (
  hippoUrl: string,
  userToken: string
): Promise<ApplicationResponse & ApplicationContextResponse> => {
  const [application, context] = await Promise.all([
    getApplication(hippoUrl, userToken),
    getApplicationContext(hippoUrl, userToken)
  ]);
  return {
    ...application,
    ...context
  };
};
