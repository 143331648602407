import RequestActionTypes from './constants';
import type { Reducer, Action, RequestPayloadType, RequestStateType } from './types';

export const intialRequestState: RequestStateType = {
  inProgress: 0
};

export const reducer: Reducer<RequestStateType> = (
  state = intialRequestState,
  action: Action<RequestPayloadType>
) => {
  switch (action.type) {
    case RequestActionTypes.UNAUTHED_POST_REQUEST:
    case RequestActionTypes.UNAUTHED_GET_REQUEST:
    case RequestActionTypes.AUTHED_POST_REQUEST:
    case RequestActionTypes.AUTHED_GET_REQUEST:
    case RequestActionTypes.AUTHED_DELETE_REQUEST:
    case RequestActionTypes.AUTHED_PUT_REQUEST:
    case RequestActionTypes.AUTHED_PATCH_REQUEST:
    case RequestActionTypes.REQUEST_RESTARTED:
      return {
        ...state,
        inProgress: state.inProgress + 1
      };
    case RequestActionTypes.REQUEST_COMPLETED:
    case RequestActionTypes.REQUEST_STOPPED:
      return {
        ...state,
        inProgress: state.inProgress - 1
      };
    default:
      return state;
  }
};
