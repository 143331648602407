import * as React from 'react';

import styled, { css } from 'styled-components';

import { Box, Divider, Typography } from '@edapp/ed-components';

import type { LeaderboardType, RankingParticipant } from '../../types';
import { LeaderboardRankingItem } from '../leaderboard-ranking-item/LeaderboardRankingItem';
import { SummaryRankingItem } from '../summary-ranking-item/SummaryRankingItem';
import { filterParticipants, getParticipants } from './utils';

type Props = {
  participants: RankingParticipant[];
  leaderboardType: LeaderboardType;
  isTransparent?: boolean;

  /**
   * @default `black`
   */
  brandingTextColor?: 'black' | 'white';

  /**
   * To extend with styled()
   *
   * @type {string}
   */
  className?: string;
};

const LeaderboardRanking: React.FC<Props> = ({
  participants,
  brandingTextColor,
  leaderboardType,
  isTransparent,
  className
}) => {
  const allParticipantsMenuListRef = React.useRef<HTMLDivElement | null>(null);
  const filteredParticipants = filterParticipants(participants, leaderboardType);

  if (filteredParticipants.length === 0) {
    return (
      <Wrapper flex="1 1 auto" px={2} py={3}>
        <Typography color={brandingTextColor || 'text'}>No participants</Typography>
      </Wrapper>
    );
  }

  const { currentUser, otherParticipants } = getParticipants(filteredParticipants);

  return (
    <Wrapper className={className} flex="1 1 auto" flexDirection="column" gap={2} px={2} py={3}>
      {currentUser && <SummaryRankingItem {...currentUser} />}
      {otherParticipants.length > 0 && (
        <OtherParticipantsContainer ref={allParticipantsMenuListRef}>
          {otherParticipants.map((item, index) => (
            <React.Fragment key={item.rank}>
              {index !== 0 && <Divider />}
              <LeaderboardRankingItem
                color={brandingTextColor}
                textSize="small"
                isTransparent={isTransparent}
                {...item}
              />
            </React.Fragment>
          ))}
        </OtherParticipantsContainer>
      )}
    </Wrapper>
  );
};

const OtherParticipantsContainer = styled(Box).attrs({
  rounded: true,
  border: true
})`
  position: relative;

  @media (max-width: 376px) {
    margin: ${({ theme }) => theme.space()}px;
  }
`;

const Wrapper = styled(Box)(
  ({ theme }) => css`
    max-width: 601px;
    height: 100%;
    width: calc(100% - ${theme.space(2)}px);
  `
);

export default LeaderboardRanking;
