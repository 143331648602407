import * as React from 'react';
import type { MenuItemType, SubmenuItemType } from '../types';
import { AnimatePresence, motion } from 'framer-motion';
import { DropdownToggle, DropdownMenu, ChevronUp, ChevronDown } from '../styled';
import { Box, Divider, Typography, useEventListener } from '@edapp/ed-components';
import { SubMenuItem } from './SubMenuItem';
import { useNavbarContext } from '../context';

type Props = {
  item: MenuItemType;
};

export const MenuItemWithSubmenu: React.FC<Props> = ({ item }) => {
  const dropdownRef = React.useRef<HTMLAnchorElement>(null);
  const dropdownMenuRef = React.useRef<HTMLUListElement>(null);
  const { canShowUpgradeActions, isMenuSectionPermitted } = useNavbarContext();

  const [expandedSubMenu, setExpandedSubMenu] = React.useState(false);

  const currentPath = window.location.pathname;

  useEventListener('mousedown', (event: MouseEvent) => {
    if (
      dropdownMenuRef?.current &&
      !dropdownMenuRef?.current.contains(event.target as Node) &&
      !dropdownRef?.current?.contains(event.target as Node)
    ) {
      setExpandedSubMenu(false);
    }
  });

  const toggleDropdown = () => {
    if (dropdownMenuRef?.current) {
      setExpandedSubMenu(!expandedSubMenu);
    }
  };

  const menuSectionIsActive = React.useMemo(() => {
    let isActive = item.rx && new RegExp(item.rx).test(currentPath);
    isActive = isActive || item.submenu?.reduce((m, e) => m || e.link === currentPath, false);

    return isActive || false;
  }, [currentPath, item]);

  /**
   * Some submenus may be empty after permission checks, in which
   * case we should not render the submenu group header.
   *
   * Example: There are no items under the Analytics > Engagement submenu
   * in SC Training, so we should not render the Engagement header.
   */
  const shouldRenderSubmenu = React.useCallback(
    (menuItem: SubmenuItemType) =>
      menuItem.submenu?.some(
        submenuItem => isMenuSectionPermitted(submenuItem) || canShowUpgradeActions
      ),
    [canShowUpgradeActions, isMenuSectionPermitted]
  );

  return (
    <li id={item.title.toLowerCase() + '-menu-item'}>
      <DropdownToggle
        ref={dropdownRef}
        data-toggle="dropdown"
        href="#"
        onClick={toggleDropdown}
        isActive={menuSectionIsActive}
      >
        {item.title}
        {expandedSubMenu ? <ChevronUp size="xs" /> : <ChevronDown size="xs" />}
      </DropdownToggle>
      <AnimatePresence initial={false}>
        <motion.div
          initial={{ opacity: 0, height: '0' }}
          animate={{ opacity: 1, height: expandedSubMenu ? 'auto' : '0' }}
          exit={{ opacity: 0, height: '0' }}
          transition={{ duration: 0.4, ease: 'easeInOut', clamp: true }}
          style={{ overflow: expandedSubMenu ? 'visible' : 'hidden' }}
        >
          <DropdownMenu
            role="menu"
            ref={dropdownMenuRef}
            style={{ display: expandedSubMenu ? 'block' : 'none' }}
          >
            {item.submenuGroups?.map(
              (g, index) =>
                shouldRenderSubmenu(g) && (
                  <React.Fragment key={'subgroup-' + g.title + index}>
                    {index > 0 && <Divider />}

                    <Box my={0.5}>
                      <Typography
                        variant="subtext2"
                        key={`dropdown-header-${index}`}
                        color="textMuted"
                        pl={1.5}
                      >
                        {g.title}
                      </Typography>
                    </Box>

                    {g.submenu?.map((i, index) => (
                      <SubMenuItem key={'submenu-item' + i.title + index} item={i} />
                    ))}
                  </React.Fragment>
                )
            )}

            {item.submenu?.map((i, index) => (
              <SubMenuItem key={'submenu-item' + i.title + index} item={i} />
            ))}
          </DropdownMenu>
        </motion.div>
      </AnimatePresence>
    </li>
  );
};
