import type { Variants } from 'framer-motion';

export const collapseTransition: Variants = {
  initial: { height: 0, opacity: 0 },
  expand: {
    height: 'auto',
    opacity: 1,
    transition: {
      height: { duration: 0.3 },
      opacity: { duration: 0.2, delay: 0.1 }
    } // delay opacity to avoid overlapping
  },
  collapse: {
    height: 0,
    opacity: 0,
    transition: { height: { duration: 0.3 }, opacity: { duration: 0.2 } } // opacity is faster, same reason
  }
};

export const fadeInTransition: Variants = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: { ease: 'anticipate' } },
  exit: { opacity: 0, transition: { ease: 'anticipate' } }
};
