import type {
  AssessmentSummary,
  AssessmentSubmissionSummary,
  AssessmentSubmission,
  AssessmentSubmissionFeedback,
  AssessmentSubmissionStatus
} from './types';
import type { AssessmentType } from '@edapp/hippo-client';
import type { Comment } from '../shared';
import type {
  EdErrorResponseType,
  Action,
  RecursivePartial,
  PaginatedPayload,
  PaginatedResponse
} from '../types';

export const FETCH_ASSESSMENT_SUMMARIES = 'FETCH_ASSESSMENT_SUMMARIES';
export type FetchAssessmentSummariesPayload = PaginatedPayload<{ searchTerm: string }>;
export const fetchAssessmentSummaries = (
  searchTerm: string,
  page = 1,
  pageSize = 25
): Action<FetchAssessmentSummariesPayload> => ({
  type: FETCH_ASSESSMENT_SUMMARIES,
  payload: { searchTerm, page, pageSize }
});

export const FETCH_ASSESSMENT_SUMMARIES_SUCCESS = 'FETCH_ASSESSMENT_SUMMARIES_SUCCESS';
export type FetchAssessmentSummariesSuccessPayload = PaginatedResponse<AssessmentSummary>;

export const FETCH_ASSESSMENT_SUMMARIES_FAILURE = 'FETCH_ASSESSMENT_SUMMARIES_FAILURE';
export type FetchAssessmentSummariesFailurePayload = EdErrorResponseType;

// Fetch
export const FETCH_ASSESSMENT = 'FETCH_ASSESSMENT';
export type FetchAssessment = { assessmentId: string };
export const fetchAssessment = (assessmentId: string): Action<FetchAssessment> => ({
  type: FETCH_ASSESSMENT,
  payload: { assessmentId }
});

export const FETCH_ASSESSMENT_SUCCESS = 'FETCH_ASSESSMENT_SUCCESS';
export type FetchAssessmentSuccess = AssessmentType;

export const FETCH_ASSESSMENT_FAILURE = 'FETCH_ASSESSMENT_FAILURE';
export type FetchAssessmentFailure = EdErrorResponseType;

export const FETCH_SUBMISSION = 'FETCH_SUBMISSION';
export type FetchSubmission = { assessmentId: string; submissionId: string };
export const fetchSubmission = (
  assessmentId: string,
  submissionId: string
): Action<FetchSubmission> => ({
  type: FETCH_SUBMISSION,
  payload: { assessmentId, submissionId }
});

export const FETCH_SUBMISSION_SUCCESS = 'FETCH_SUBMISSION_SUCCESS';
export type FetchSubmissionSuccess = AssessmentSubmission;

export const FETCH_SUBMISSION_FAILURE = 'FETCH_SUBMISSION_FAILURE';
export type FetchSubmissionFailure = EdErrorResponseType;

export const FETCH_SUBMISSION_FEEDBACK = 'FETCH_SUBMISSION_FEEDBACK';
export type FetchSubmissionFeedback = { assessmentId: string; submissionId: string };
export const fetchSubmissionFeedback = (
  assessmentId: string,
  submissionId: string
): Action<FetchSubmissionFeedback> => ({
  type: FETCH_SUBMISSION_FEEDBACK,
  payload: { assessmentId, submissionId }
});

export const FETCH_SUBMISSION_FEEDBACK_SUCCESS = 'FETCH_SUBMISSION_FEEDBACK_SUCCESS';
export type FetchSubmissionFeedbackSuccess = {
  feedback: AssessmentSubmissionFeedback;
  submissionId: string;
};

export const FETCH_SUBMISSION_FEEDBACK_FAILURE = 'FETCH_SUBMISSION_FEEDBACK_FAILURE';
export type FetchSubmissionFeedbackFailure = EdErrorResponseType;

export const FETCH_SUBMISSION_COMMENTS = 'FETCH_SUBMISSION_COMMENTS';
export type FetchSubmissionComments = PaginatedPayload<{
  assessmentId: string;
  submissionId: string;
}>;
export const fetchSubmissionComments = (
  assessmentId: string,
  submissionId: string,
  page: number = 1,
  pageSize: number = 2
): Action<FetchSubmissionComments> => ({
  type: FETCH_SUBMISSION_COMMENTS,
  payload: { assessmentId, submissionId, page, pageSize }
});

export const FETCH_SUBMISSION_COMMENTS_SUCCESS = 'FETCH_SUBMISSION_COMMENTS_SUCCESS';
export type FetchSubmissionCommentsSuccess = {
  totalCount: number;
  items: Comment[];
  submissionId: string;
  page: number;
};

export const FETCH_SUBMISSION_COMMENTS_FAILURE = 'FETCH_SUBMISSION_COMMENTS_FAILURE';
export type FetchSubmissionCommentsFailure = EdErrorResponseType;

// Set & Save
export const SET_ASSESSMENT = 'SET_ASSESSMENT';
export type SetAssessment = {
  assessmentId: string;
  data: RecursivePartial<AssessmentType>;
  skipSave: boolean;
};
export const setAssessment = (
  assessmentId: string,
  data: RecursivePartial<AssessmentType>,
  skipSave: boolean = false
): Action<SetAssessment> => ({
  type: SET_ASSESSMENT,
  payload: { assessmentId, data, skipSave }
});

export const SAVE_ASSESSMENT = 'SAVE_ASSESSMENT';
export type SaveAssessment = {
  assessmentId: string;
  data: RecursivePartial<AssessmentType>;
  oldData: AssessmentType;
};
export const saveAssessment = (
  assessmentId: string,
  data: RecursivePartial<AssessmentType>,
  oldData: AssessmentType
): Action<SaveAssessment> => ({
  type: SAVE_ASSESSMENT,
  payload: { assessmentId, data, oldData }
});

export const SAVE_ASSESSMENT_SUCCESS = 'SAVE_ASSESSMENT_SUCCESS';

export const SAVE_ASSESSMENT_FAILURE = 'SAVE_ASSESSMENT_FAILURE';
export type SaveAssessmentFailure = {
  assessmentId: string;
  assessmentStateBeforeSave: AssessmentType;
  error: EdErrorResponseType;
};

export const ADD_SUBMISSION_COMMENT = 'ADD_SUBMISSION_COMMENT';
export type AddSubmissionComment = {
  content: string;
  submissionId: string;
  assessmentId: string;
};
export const addSubmissionComment = (
  assessmentId: string,
  submissionId: string,
  content: string
): Action<AddSubmissionComment> => ({
  type: ADD_SUBMISSION_COMMENT,
  payload: { content, submissionId, assessmentId }
});

export const ADD_SUBMISSION_COMMENT_SUCCESS = 'ADD_SUBMISSION_COMMENT_SUCCESS';
export type AddSubmissionCommentSuccess = { submissionId: string; comment: Comment };

export const ADD_SUBMISSION_COMMENT_FAILURE = 'ADD_SUBMISSION_COMMENT_FAILURE';
export type AddSubmissionCommentFailure = EdErrorResponseType;

export const ADD_SUBMISSION_FEEDBACK = 'ADD_SUBMISSION_FEEDBACK';
export type AddSubmissionFeedback = {
  content: string;
  status: AssessmentSubmissionStatus;
  submissionId: string;
  assessmentId: string;
};
export const addSubmissionFeedback = (
  assessmentId: string,
  submissionId: string,
  content: string,
  status: AssessmentSubmissionStatus
): Action<AddSubmissionFeedback> => ({
  type: ADD_SUBMISSION_FEEDBACK,
  payload: { content, status, submissionId, assessmentId }
});

export const ADD_SUBMISSION_FEEDBACK_SUCCESS = 'ADD_SUBMISSION_FEEDBACK_SUCCESS';
export type AddSubmissionFeedbackSuccess = {
  submissionId: string;
  feedback: AssessmentSubmissionFeedback;
};

export const ADD_SUBMISSION_FEEDBACK_FAILURE = 'ADD_SUBMISSION_FEEDBACK_FAILURE';
export type AddSubmissionFeedbackFailure = EdErrorResponseType;

export const EDIT_SUBMISSION_COMMENT = 'EDIT_SUBMISSION_COMMENT';
export type EditSubmissionComment = {
  content: string;
  commentId: string;
  submissionId: string;
};
export const editSubmissionComment = (
  submissionId: string,
  commentId: string,
  content: string
): Action<EditSubmissionComment> => ({
  type: EDIT_SUBMISSION_COMMENT,
  payload: { content, commentId, submissionId }
});

export const EDIT_SUBMISSION_COMMENT_SUCCESS = 'EDIT_SUBMISSION_COMMENT_SUCCESS';
export type EditSubmissionCommentSuccess = { submissionId: string; comment: Comment };

export const EDIT_SUBMISSION_COMMENT_FAILURE = 'EDIT_SUBMISSION_COMMENT_FAILURE';
export type EditSubmissionCommentFailure = EdErrorResponseType;

export const EDIT_SUBMISSION_FEEDBACK = 'EDIT_SUBMISSION_FEEDBACK';
export type EditSubmissionFeedback = {
  assessmentId: string;
  submissionId: string;
  content: string;
  feedbackId: string;
  status: AssessmentSubmissionStatus;
};
export const editSubmissionFeedback = (
  assessmentId: string,
  submissionId: string,
  feedbackId: string,
  content: string,
  status: AssessmentSubmissionStatus
): Action<EditSubmissionFeedback> => ({
  type: EDIT_SUBMISSION_FEEDBACK,
  payload: { content, status, feedbackId, submissionId, assessmentId }
});

export const EDIT_SUBMISSION_FEEDBACK_SUCCESS = 'EDIT_SUBMISSION_FEEDBACK_SUCCESS';
export type EditSubmissionFeedbackSuccess = {
  submissionId: string;
  feedback: AssessmentSubmissionFeedback;
};

export const EDIT_SUBMISSION_FEEDBACK_FAILURE = 'EDIT_SUBMISSION_FEEDBACK_FAILURE';
export type EditSubmissionFeedbackFailure = EdErrorResponseType;

// Delete
export const DELETE_SUBMISSION_COMMENT = 'DELETE_SUBMISSION_COMMENT';
export type DeleteSubmissionComment = {
  submissionId: string;
  commentId: string;
};
export const deleteSubmissionComment = (
  submissionId: string,
  commentId: string
): Action<DeleteSubmissionComment> => ({
  type: DELETE_SUBMISSION_COMMENT,
  payload: { submissionId, commentId }
});

export const DELETE_SUBMISSION_COMMENT_SUCCESS = 'DELETE_SUBMISSION_COMMENT_SUCCESS';
export type DeleteSubmissionCommentSuccess = { submissionId: string; commentId: string };

export const DELETE_SUBMISSION_COMMENT_FAILURE = 'DELETE_SUBMISSION_COMMENT_FAILURE';
export type DeleteSubmissionCommentFailure = EdErrorResponseType;

// Assessment submissions
enum SortOrderType {
  ASCENDING = 'Asc',
  DESCENDING = 'Desc'
}

export const FETCH_ASSESSMENT_SUBMISSION_SUMMARIES = 'FETCH_ASSESSMENT_SUBMISSION_SUMMARIES';
export type FetchAssessmentSubmissionSummariesPayload = {
  assessmentId: string;
  page: number;
  pageSize: number;
  status: SortOrderType;
  created: SortOrderType;
};
export const fetchAssessmentSubmissionSummaries = (
  assessmentId: string,
  _: string,
  page: number,
  pageSize = 25,
  status = SortOrderType.ASCENDING,
  created = SortOrderType.DESCENDING
): Action<FetchAssessmentSubmissionSummariesPayload> => ({
  type: FETCH_ASSESSMENT_SUBMISSION_SUMMARIES,
  payload: { assessmentId, page, pageSize, status, created }
});

export const FETCH_ASSESSMENT_SUBMISSION_SUMMARIES_SUCCESS =
  'FETCH_ASSESSMENT_SUBMISSION_SUMMARIES_SUCCESS';
export type FetchAssessmentSubmissionSummariesSuccessPayload = {
  assessmentId: string;
} & PaginatedResponse<AssessmentSubmissionSummary>;

export const FETCH_ASSESSMENT_SUBMISSION_SUMMARIES_FAILURE =
  'FETCH_ASSESSMENT_SUBMISSION_SUMMARIES_FAILURE';
export type FetchAssessmentSubmissionSummariesFailurePayload = EdErrorResponseType;
