import type { Appearance } from './types';

export enum DeviceType {
  PHONE = 'PHONE',
  TABLET = 'TABLET',
  DESKTOP = 'DESKTOP',
  FULLSCREEN = 'FULLSCREEN',
  CUSTOM = 'CUSTOM'
}

/**
 * Maximum width Thomas supports without changing views with the border is 1024px.
 * This scale enforces the view without the borders.
 */
export const LESSON_DESKTOP_SCALE = 960 / 1024;

export const devices: Record<DeviceType, Appearance> = {
  [DeviceType.PHONE]: {
    width: 360,
    height: 640,
    scale: 1
  },
  [DeviceType.TABLET]: {
    width: 480,
    height: 768,
    scale: 0.6
  },
  [DeviceType.DESKTOP]: {
    width: 960,
    height: 640,
    scale: 0.75
  },
  [DeviceType.CUSTOM]: {
    width: 360,
    height: 640,
    scale: 1
  },
  // To update to full screen dimensions
  [DeviceType.FULLSCREEN]: {
    width: 100,
    height: 100,
    scale: 1
  }
};

/**
 * The amount of space between the container and the device.
 */
export const deviceSpace: Record<
  DeviceType,
  {
    verticalSpace: number; // top and bottom, in px
  }
> = {
  [DeviceType.PHONE]: {
    verticalSpace: 32 * 2
  },
  [DeviceType.TABLET]: {
    verticalSpace: 32 * 2
  },
  [DeviceType.DESKTOP]: {
    verticalSpace: 48 * 2
  },
  [DeviceType.CUSTOM]: {
    verticalSpace: 48 * 2
  },
  [DeviceType.FULLSCREEN]: {
    verticalSpace: 48 * 2
  }
};
