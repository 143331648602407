import * as React from 'react';
import { Typography } from '../../typography';

type Props = {
  className?: string;
  testId?: string;
} & React.ComponentProps<typeof Typography>;

export const ValidationErrorMessage: React.FC<Props> = ({
  className,
  children,
  testId,
  ...rest
}) => {
  if (children == null) {
    return null;
  }

  return (
    <Typography
      className={className}
      as="p"
      variant="small"
      color="red"
      testId={testId}
      pt={0.5}
      pb={2}
      {...rest}
    >
      {children}
    </Typography>
  );
};
