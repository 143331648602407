import * as React from 'react';
import { Box } from '@edapp/ed-components';
import { RestrictionBlock } from '../block';
import type { Props } from './types';

export const RestrictionCard: React.FC<Props> = props => (
  <Box flexGrow={1} bgColor="white" border rounded={true} p={4} testId="restriction-card">
    <RestrictionBlock {...props} />
  </Box>
);
