import { put, fork, takeLatest } from 'redux-saga/effects';
import { RequestActions } from '@edapp/request';
import type {
  FetchCourseTopicsAction,
  CreateCourseTopicAction,
  UpdateCourseTopicAction,
  DeleteCourseTopicAction
} from './actions';
import { ActionType, actions } from './actions';

const topics = (courseId: string) => `/api/courses/${courseId}/topics`;
const topic = (courseId: string, id: string) => `${topics(courseId)}/${id}`;

function* handleFetchCourseTopics({ payload }: FetchCourseTopicsAction) {
  yield put(
    RequestActions.getAuthed(
      topics(payload.courseId),
      ActionType.FETCH_COURSE_TOPICS_SUCCESS,
      ActionType.FETCH_COURSE_TOPICS_FAILURE,
      false
    )
  );
}

function* handleCreateCourseTopic({ payload }: CreateCourseTopicAction) {
  yield put(
    RequestActions.postAuthed(
      topics(payload.courseId),
      ActionType.CREATE_COURSE_TOPIC_SUCCESS,
      ActionType.CREATE_COURSE_TOPIC_FAILURE,
      false,
      payload
    )
  );
}

function* handleUpdateCourseTopic({ payload }: UpdateCourseTopicAction) {
  yield put(
    RequestActions.patchAuthed(
      topic(payload.courseId, payload.id),
      ActionType.UPDATE_COURSE_TOPIC_SUCCESS,
      ActionType.UPDATE_COURSE_TOPIC_FAILURE,
      false,
      payload
    )
  );
}

function* handleDeleteCourseTopic({ payload }: DeleteCourseTopicAction) {
  yield put(
    RequestActions.deleteAuthed(
      topic(payload.courseId, payload.id),
      () => actions.deleteCourseTopicSuccess({ id: payload.id }),
      ActionType.DELETE_COURSE_TOPIC_FAILURE,
      false,
      payload
    )
  );
}

function* watchFetchCourseTopics() {
  yield takeLatest(ActionType.FETCH_COURSE_TOPICS, handleFetchCourseTopics);
}

function* watchCreateCourseTopic() {
  yield takeLatest(ActionType.CREATE_COURSE_TOPIC, handleCreateCourseTopic);
}

function* watchUpdateCourseTopic() {
  yield takeLatest(ActionType.UPDATE_COURSE_TOPIC, handleUpdateCourseTopic);
}

function* watchDeleteCourseTopic() {
  yield takeLatest(ActionType.DELETE_COURSE_TOPIC, handleDeleteCourseTopic);
}

export const sagas = [
  fork(watchFetchCourseTopics),
  fork(watchCreateCourseTopic),
  fork(watchUpdateCourseTopic),
  fork(watchDeleteCourseTopic)
];
