import { RequestUtils } from '@edapp/request';
import type {
  AutocompleteRequest as ApplicationNameAutocompleteRequest,
  AutocompleteResponse as ApplicationNameAutocompleteResponse
} from './types';

export function getApplicationNameAutocomplete(
  hippoUrl: string,
  userToken: string,
  searchTerm: string
): Promise<ApplicationNameAutocompleteResponse> {
  return RequestUtils.httpFetch<
    ApplicationNameAutocompleteResponse,
    ApplicationNameAutocompleteRequest
  >('GET', `${hippoUrl}/api/accounts/autocomplete`, userToken, {
    searchTerm: encodeURIComponent(searchTerm)
  });
}
