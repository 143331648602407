import * as React from 'react';

import type { SelectableListOption } from '@edapp/ed-components';
import { selectableListMatchesFilter } from '@edapp/ed-components';
import { clamp } from '@rio/utils/clamp';

export type OptionValue = string;
export type Option = SelectableListOption<OptionValue>;
export type Options = { [key: string]: Option & { selected: boolean } };

export function useSelectableListCounter(
  selectedTotal: number = 0,
  unselectedTotal: number = 0,
  filter?: string
) {
  const [changes, setChanges] = React.useState<Options>({});

  const handleChange = (option: Option, selected: boolean) => {
    setChanges(({ [option.value]: existingOption, ...prev }) =>
      !!existingOption ? prev : { ...prev, [option.value]: { ...option, selected } }
    );
  };

  const handleSelect = (option: Option) => {
    handleChange(option, true);
  };

  const handleDeselect = (option: Option) => {
    handleChange(option, false);
  };

  const resetCounters = () => {
    setChanges({});
  };

  const { selectedChangesCount, unselectedChangesCount } = Object.values(changes).reduce<{
    selectedChangesCount: number;
    unselectedChangesCount: number;
  }>(
    (acc, { label, selected }) => {
      if (!!filter && !selectableListMatchesFilter(label, filter)) {
        return acc;
      } else {
        return selected
          ? {
              unselectedChangesCount: acc.unselectedChangesCount - 1,
              selectedChangesCount: acc.selectedChangesCount + 1
            }
          : {
              unselectedChangesCount: acc.unselectedChangesCount + 1,
              selectedChangesCount: acc.selectedChangesCount - 1
            };
      }
    },
    { selectedChangesCount: 0, unselectedChangesCount: 0 }
  );

  const selectedCount = clamp(selectedTotal + selectedChangesCount, 0);

  const unselectedCount = clamp(unselectedTotal + unselectedChangesCount, 0);

  return {
    selectedCount,
    unselectedCount,
    handleSelect,
    handleDeselect,
    resetCounters
  };
}
