import * as React from 'react';
import { CircleCrossIcon } from '../../../icons';
import type { IndicatorProps } from 'react-select/src/components/indicators';
import styled, { css } from 'styled-components';
import type { Option } from '../types';

// The ClearIndicator "X" is actually unmounted when no value is selected
export function ClearIndicator<OptionValueType>({
  innerProps
}: IndicatorProps<Option<OptionValueType>>) {
  return <StyledCircleCrossIcon {...innerProps} data-testid="clear-button" />;
}

const StyledCircleCrossIcon = styled(CircleCrossIcon)`
  cursor: pointer;

  ${({ theme }) => css`
    color: ${theme.colors.textMuted};
    &:hover {
      color: ${theme.colors.text};
    }
  `}
`;
