import type {
  PaginatedRequest,
  PaginatedResponse,
  UseHippoPaginationOptions,
  UseHippoPaginationResult
} from '@edapp/request';

// this type is duplicate with the one that we have in
// react-workspaces/apps/rio/src/store/userGroups/types.ts
// in the future we want to use this rather.
export type UserGroupContains = 'users' | 'groups';

export type UserGroup = {
  userGroupId: string;
  userGroupName: string;
  userGroupContains: UserGroupContains;
  isAccountGroup: boolean;
  isDynamicGroup: boolean;
  isPlaylistGroup: boolean;
};

export enum GetUserGroupsFilterBy {
  SELECTED = 'Selected',
  NOT_SELECTED = 'NotSelected',
  NONE = 'None'
}

export type GetUserGroupsWithFiltersRequest = PaginatedRequest & {
  searchTerm: string;
  excludeAncestorsForUserGroupId: string;
  filterBy: GetUserGroupsFilterBy;
};
export type GetUserGroupsWithFiltersItem = {
  id: string;
  name: string;
  hasChildren: boolean;
};
export type GetUserGroupsWithFiltersResponse = PaginatedResponse<GetUserGroupsWithFiltersItem>;
export type GetUserGroupsWithFiltersOptions = UseHippoPaginationOptions<
  GetUserGroupsWithFiltersResponse
>;
export type GetUserGroupsWithFiltersResult = UseHippoPaginationResult<
  GetUserGroupsWithFiltersResponse
>;

export type GroupSiteHierarchyRequestFilter = 'Group' | 'Site' | undefined;
export type GroupSiteHierarchyRequest = {
  courseId: string;
  parentGroupId: string | null;
  filterBy?: GroupSiteHierarchyRequestFilter;
  searchTerm?: string;
} & PaginatedRequest;
