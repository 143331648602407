import styled, { css } from 'styled-components';
import type { Props } from './types';

export const THUMBNAIL_TEST_ID = 'thumbnail';

export const Thumbnail = styled.div.attrs<Props>(({ testId = THUMBNAIL_TEST_ID }) => ({
  'data-testid': testId
}))<Props>(
  ({ height = 136, width = 136, imageUrl, disabled, theme, isStacked }) => css`
    background-image: ${imageUrl};
    background-color: ${imageUrl ? 'transparent' : theme.colors.lightGrey};
    width: ${width}px;
    height: ${height}px;
    border-radius: ${theme.sizes.borderRadius}px;
    opacity: ${disabled && !isStacked ? 0.6 : 1};
    flex-shrink: 0;
    background-position: center;
    background-size: cover;
    position: relative;
  `
);
