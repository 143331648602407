import type { ActionWithPayload, ActionsUnion } from '@edapp/utils';
import { createAction } from '@edapp/utils';
import type { CoursePublicDetailUrlSlug } from './types';
import type { EdErrorResponseType } from '../../../../types';

export enum ActionType {
  FETCH_COURSE_PUBLIC_DETAIL_URL_SLUG = 'FETCH_COURSE_PUBLIC_DETAIL_URL_SLUG',
  FETCH_COURSE_PUBLIC_DETAIL_URL_SLUG_SUCCESS = 'FETCH_COURSE_PUBLIC_DETAIL_URL_SLUG_SUCCESS',
  FETCH_COURSE_PUBLIC_DETAIL_URL_SLUG_FAILURE = 'FETCH_COURSE_PUBLIC_DETAIL_URL_SLUG_FAILURE',

  CREATE_COURSE_PUBLIC_DETAIL_URL_SLUG = 'CREATE_COURSE_PUBLIC_DETAIL_URL_SLUG',
  CREATE_COURSE_PUBLIC_DETAIL_URL_SLUG_SUCCESS = 'CREATE_COURSE_PUBLIC_DETAIL_URL_SLUG_SUCCESS',
  CREATE_COURSE_PUBLIC_DETAIL_URL_SLUG_FAILURE = 'CREATE_COURSE_PUBLIC_DETAIL_URL_SLUG_FAILURE',

  UPDATE_COURSE_PUBLIC_DETAIL_URL_SLUG = 'UPDATE_COURSE_PUBLIC_DETAIL_URL_SLUG',
  UPDATE_COURSE_PUBLIC_DETAIL_URL_SLUG_SUCCESS = 'UPDATE_COURSE_PUBLIC_DETAIL_URL_SLUG_SUCCESS',
  UPDATE_COURSE_PUBLIC_DETAIL_URL_SLUG_FAILURE = 'UPDATE_COURSE_PUBLIC_DETAIL_URL_SLUG_FAILURE'
}

type FetchCoursePublicDetailUrlSlug = { courseId: string; publicDetailId: string };
export type FetchCoursePublicDetailUrlSlugAction = ActionWithPayload<
  ActionType.FETCH_COURSE_PUBLIC_DETAIL_URL_SLUG,
  FetchCoursePublicDetailUrlSlug
>;

type FetchCoursePublicDetailUrlSlugSuccess = CoursePublicDetailUrlSlug;
export type FetchCoursePublicDetailUrlSlugSuccessAction = ActionWithPayload<
  ActionType.FETCH_COURSE_PUBLIC_DETAIL_URL_SLUG_SUCCESS,
  FetchCoursePublicDetailUrlSlugSuccess
>;

type FetchCoursePublicDetailUrlSlugFailure = EdErrorResponseType;
export type FetchCoursePublicDetailSlugFailureAction = ActionWithPayload<
  ActionType.FETCH_COURSE_PUBLIC_DETAIL_URL_SLUG_FAILURE,
  FetchCoursePublicDetailUrlSlugFailure
>;

type CreateCoursePublicDetailUrlSlug = {
  courseId: string;
  publicDetailId: string;
  urlSlug: string;
};
export type CreateCoursePublicDetailUrlSlugAction = ActionWithPayload<
  ActionType.CREATE_COURSE_PUBLIC_DETAIL_URL_SLUG,
  CreateCoursePublicDetailUrlSlug
>;

type CreateCoursePublicDetailUrlSlugSuccess = CoursePublicDetailUrlSlug;
export type CreateCoursePublicDetailUrlSlugSuccessAction = ActionWithPayload<
  ActionType.CREATE_COURSE_PUBLIC_DETAIL_URL_SLUG_SUCCESS,
  CreateCoursePublicDetailUrlSlugSuccess
>;

type CreateCoursePublicDetailUrlSlugFailure = EdErrorResponseType;
export type CreateCoursePublicDetailUrlSlugFailureAction = ActionWithPayload<
  ActionType.CREATE_COURSE_PUBLIC_DETAIL_URL_SLUG_FAILURE,
  CreateCoursePublicDetailUrlSlugFailure
>;

type UpdateCoursePublicDetailUrlSlug = {
  courseId: string;
  publicDetailId: string;
  id: string;
  urlSlug: string;
};
export type UpdateCoursePublicDetailUrlSlugAction = ActionWithPayload<
  ActionType.UPDATE_COURSE_PUBLIC_DETAIL_URL_SLUG,
  UpdateCoursePublicDetailUrlSlug
>;

type UpdateCoursePublicDetailUrlSlugSuccess = CoursePublicDetailUrlSlug;
export type UpdateCoursePublicDetailUrlSlugSuccessAction = ActionWithPayload<
  ActionType.UPDATE_COURSE_PUBLIC_DETAIL_URL_SLUG_SUCCESS,
  UpdateCoursePublicDetailUrlSlugSuccess
>;

type UpdateCoursePublicDetailUrlSlugFailure = EdErrorResponseType;
export type UpdateCoursePublicDetailUrlSlugFailureAction = ActionWithPayload<
  ActionType.UPDATE_COURSE_PUBLIC_DETAIL_URL_SLUG_FAILURE,
  UpdateCoursePublicDetailUrlSlugFailure
>;

export const actions = {
  fetchCoursePublicDetailUrlSlug: (payload: FetchCoursePublicDetailUrlSlug) =>
    createAction(ActionType.FETCH_COURSE_PUBLIC_DETAIL_URL_SLUG, payload),
  fetchCoursePublicDetailUrlSlugSuccess: (payload: FetchCoursePublicDetailUrlSlugSuccess) =>
    createAction(ActionType.FETCH_COURSE_PUBLIC_DETAIL_URL_SLUG_SUCCESS, payload),
  fetchCoursePublicDetailUrlSlugFailure: (payload: FetchCoursePublicDetailUrlSlugFailure) =>
    createAction(ActionType.FETCH_COURSE_PUBLIC_DETAIL_URL_SLUG_FAILURE, payload),

  createCoursePublicDetailUrlSlug: (payload: CreateCoursePublicDetailUrlSlug) =>
    createAction(ActionType.CREATE_COURSE_PUBLIC_DETAIL_URL_SLUG, payload),
  createCoursePublicDetailUrlSlugSuccess: (payload: CreateCoursePublicDetailUrlSlugSuccess) =>
    createAction(ActionType.CREATE_COURSE_PUBLIC_DETAIL_URL_SLUG_SUCCESS, payload),
  createCoursePublicDetailUrlSlugFailure: (payload: CreateCoursePublicDetailUrlSlugFailure) =>
    createAction(ActionType.CREATE_COURSE_PUBLIC_DETAIL_URL_SLUG_FAILURE, payload),

  updateCoursePublicDetailUrlSlug: (payload: UpdateCoursePublicDetailUrlSlug) =>
    createAction(ActionType.UPDATE_COURSE_PUBLIC_DETAIL_URL_SLUG, payload),
  updateCoursePublicDetailUrlSlugSuccess: (payload: UpdateCoursePublicDetailUrlSlugSuccess) =>
    createAction(ActionType.UPDATE_COURSE_PUBLIC_DETAIL_URL_SLUG_SUCCESS, payload),
  updateCoursePublicDetailUrlSlugFailure: (payload: UpdateCoursePublicDetailUrlSlugFailure) =>
    createAction(ActionType.UPDATE_COURSE_PUBLIC_DETAIL_URL_SLUG_FAILURE, payload)
};

export type ActionTypes = ActionsUnion<typeof actions>;
