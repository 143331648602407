import type { PublicDetailsState } from './types';
import { initialState } from './constants';
import type * as Actions from './actions';
import type { ActionType } from './actions';
import type { CoursePublicDetail } from './types';
import type { Handlers as CourseLinksHandlers } from './course-links/reducer';
import { handlers as courseLinksHandlers } from './course-links/reducer';
import type { Handlers as ExcludedLessonsHandlers } from './excluded-lessons/reducer';
import { handlers as excludedLessonsHandlers } from './excluded-lessons/reducer';
import type { Handlers as ExcludedSlidesHandlers } from './excluded-slides/reducer';
import { handlers as excludedSlidesHandlers } from './excluded-slides/reducer';
import type { Handlers as RatingLinksHandlers } from './rating-links/reducer';
import { handlers as ratingLinksHandlers } from './rating-links/reducer';
import type { Handlers as UrlSlugsHandlers } from './url-slugs/reducer';
import { handlers as urlSlugsHandlers } from './url-slugs/reducer';
import type { Handler } from '../../../types';

const merge = (
  stateData: PublicDetailsState['data'],
  publicDetailId: string,
  data: Partial<CoursePublicDetail>
): PublicDetailsState['data'] => ({
  ...stateData,
  [publicDetailId]: {
    ...stateData[publicDetailId],
    ...data
  }
});

type PublicDetailHandler<Action = null> = Handler<PublicDetailsState, Action>;

const fetchPublicDetail: PublicDetailHandler<Actions.FetchCoursePublicDetailAction> = state => ({
  ...state,
  loading: true
});

const fetchPublicDetailSuccess: PublicDetailHandler<Actions.FetchCoursePublicDetailSuccessAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: '',
  data: merge(state.data, action.payload.id, action.payload)
});

const fetchPublicDetailFailure: PublicDetailHandler<Actions.FetchCoursePublicDetailFailureAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: action.payload.message
});

const createPublicDetail: PublicDetailHandler<Actions.CreateCoursePublicDetailAction> = state => ({
  ...state,
  loading: true
});

const createPublicDetailSuccess: PublicDetailHandler<Actions.CreateCoursePublicDetailSuccessAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: '',
  data: merge(state.data, action.payload.id, action.payload)
});

const createPublicDetailFailure: PublicDetailHandler<Actions.CreateCoursePublicDetailFailureAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: action.payload.message
});

const updatePublicDetail: PublicDetailHandler<Actions.UpdateCoursePublicDetailAction> = (
  state,
  action
) => ({
  ...state,
  data: action.payload.thumbnail
    ? merge(state.data, action.payload.id, { thumbnail: action.payload.thumbnail })
    : state.data,
  loading: true
});

const updatePublicDetailSuccess: PublicDetailHandler<Actions.UpdateCoursePublicDetailSuccessAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: '',
  data: merge(state.data, action.payload.id, action.payload)
});

const updatePublicDetailFailure: PublicDetailHandler<Actions.UpdateCoursePublicDetailFailureAction> = (
  state,
  action
) => ({
  ...state,
  loading: false,
  error: action.payload.message
});

export type Handlers = {
  [type in ActionType]: (
    state: PublicDetailsState,
    action: Actions.ActionTypes
  ) => PublicDetailsState;
} &
  CourseLinksHandlers &
  ExcludedLessonsHandlers &
  ExcludedSlidesHandlers &
  RatingLinksHandlers &
  UrlSlugsHandlers;

const handlers: Handlers = {
  FETCH_COURSE_PUBLIC_DETAIL: fetchPublicDetail,
  FETCH_COURSE_PUBLIC_DETAIL_SUCCESS: fetchPublicDetailSuccess,
  FETCH_COURSE_PUBLIC_DETAIL_FAILURE: fetchPublicDetailFailure,

  CREATE_COURSE_PUBLIC_DETAIL: createPublicDetail,
  CREATE_COURSE_PUBLIC_DETAIL_SUCCESS: createPublicDetailSuccess,
  CREATE_COURSE_PUBLIC_DETAIL_FAILURE: createPublicDetailFailure,

  UPDATE_COURSE_PUBLIC_DETAIL: updatePublicDetail,
  UPDATE_COURSE_PUBLIC_DETAIL_SUCCESS: updatePublicDetailSuccess,
  UPDATE_COURSE_PUBLIC_DETAIL_FAILURE: updatePublicDetailFailure,

  ...courseLinksHandlers,
  ...excludedLessonsHandlers,
  ...excludedSlidesHandlers,
  ...ratingLinksHandlers,
  ...urlSlugsHandlers
};

type Reducer = (state: PublicDetailsState, action: Actions.ActionTypes) => PublicDetailsState;

export const reducer: Reducer = (state = initialState, action) =>
  handlers[action.type]?.(state, action) || state;
