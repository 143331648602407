import * as React from 'react';

import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';
import { Route as ReactRouterRoute, Redirect } from 'react-router-dom';

import { Box, Loading } from '@edapp/ed-components';
import { NoAccess } from '@rio/containers/no-access';
import { hasAccessToRoute, isUXPEnabled } from '@rio/store/config/selectors';

import type {
  ReactRouterRouteProps,
  RestrictedRouteProps,
  RouteProps,
  WithRouteDefinition
} from './types';

const Route: React.FC<RouteProps> = ({ name, component: Component, ...props }) => {
  const isUxp = useSelector(isUXPEnabled);

  const fallbackUI = isUxp ? (
    <Loading />
  ) : (
    <Box flex={true} justifyContent="center" p={3}>
      <Loading />
    </Box>
  );

  return (
    <ReactRouterRoute<ReactRouterRouteProps>
      redirects={props.redirects}
      {...props}
      render={routeProps => (
        <>
          {!isUxp && <Helmet title={` Ed LMS · ${name}`} />}

          <React.Suspense fallback={fallbackUI}>
            <Component {...routeProps} />
          </React.Suspense>
        </>
      )}
    />
  );
};

const RestrictedRoute: React.FC<RestrictedRouteProps> = ({
  restrictions: { billingPermission, userPermission, role },
  path,
  component,
  ...props
}) => {
  const hasAccess = useSelector(hasAccessToRoute(billingPermission, userPermission, role));

  return <Route path={path} component={hasAccess ? component : NoAccess} {...props} />;
};

export const withRouteDefinition: WithRouteDefinition = (
  routes,
  { restrictions, redirects, path, ...props }
) => {
  if (redirects) {
    redirects.forEach(redirection =>
      routes.push(<Redirect key={redirection} exact={true} from={redirection} to={path} />)
    );
  }

  routes.push(
    restrictions ? (
      <RestrictedRoute key={path} restrictions={restrictions} path={path} {...props} />
    ) : (
      <Route key={path} path={path} {...props} />
    )
  );

  return routes;
};
