import * as React from 'react';

import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import type { SelectableListOption } from '@edapp/ed-components';
import { Typography } from '@edapp/ed-components';
import { SelectableList } from '@edapp/ed-components';
import { setUserGroupItem } from '@rio/store/userGroups/actions';
import { getUserGroupDraws } from '@rio/store/userGroups/selectors';
import { getDraws, getIsFetchingDraws } from '@rio/store/userGroups/selectors';

export const Draws: React.FC = () => {
  const isLoading = useSelector(getIsFetchingDraws);
  const draws = useSelector(getDraws);
  const selectedDrawIds = useSelector(getUserGroupDraws);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleDrawsChange = (draws: string[]) => {
    dispatch(setUserGroupItem({ usergroup: { draws } }));
  };

  if (!isLoading && draws.length === 0) {
    return (
      <div>
        <label>{t('user-groups.select-prize-draws.title')}</label>
        <p className="help-block">
          <Trans
            i18nKey="user-groups.select-prize-draws.no-prize-draws"
            components={{
              anchor: <Typography isLink={true} weight="600" href="/draw/new" />
            }}
          />
        </p>
      </div>
    );
  }

  const options = draws.map<SelectableListOption<string>>(({ id, name }) => ({
    key: id,
    value: id,
    label: name
  }));

  return (
    <div>
      <label>{t('user-groups.select-prize-draws.title')}</label>
      <p className="help-block">{t('user-groups.select-prize-draws.description')}</p>
      <SelectableList<string>
        options={options}
        selectedValues={selectedDrawIds}
        unselectedBox={{
          title: t('user-groups.select-prize-draws.all-draws'),
          onSelectAll: true,
          showCount: true
        }}
        selectedBox={{
          title: t('user-groups.select-prize-draws.selected-draws'),
          onClearAll: true,
          showCount: true,
          onChange: handleDrawsChange
        }}
        disabled={isLoading}
        loading={isLoading}
      />
    </div>
  );
};
