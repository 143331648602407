import * as React from 'react';
import type { Feedback, SettingsDialogContextState, FeedbackVariant } from './types';

const SettingsDialogContext = React.createContext<SettingsDialogContextState | undefined>(
  undefined
);

export function useSettingsDialog() {
  const context = React.useContext(SettingsDialogContext);

  if (!context) {
    throw new Error("Usage of 'useSettingsDialog' should be within the SettingsDialogProvider");
  }

  return context;
}

export const SettingsDialogProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [feedback, setFeedback] = React.useState<Feedback>({});

  const handleSetFeedback = (variant: FeedbackVariant, msg?: string | React.ReactNode) => {
    setFeedback({ variant, msg });
  };

  const handleResetFeedback = () => {
    setFeedback({});
  };

  return (
    <SettingsDialogContext.Provider
      value={{ feedback, setFeedback: handleSetFeedback, resetFeedback: handleResetFeedback }}
    >
      {children}
    </SettingsDialogContext.Provider>
  );
};
