import { put, fork, takeLatest } from 'redux-saga/effects';
import { RequestActions } from '@edapp/request';
import type {
  FetchCoursePublicDetailCourseLinksAction,
  CreateCoursePublicDetailCourseLinkAction,
  CreateCoursePublicDetailCourseLinksAction,
  DeleteCoursePublicDetailCourseLinkAction
} from './actions';
import { ActionType, actions } from './actions';
import type { CoursePublicDetailCourseLink } from './types';

const courseLinks = (courseId: string, publicDetailId: string) =>
  `/api/courses/${courseId}/public-details/${publicDetailId}/course-links`;
const courseLink = (courseId: string, publicDetailId: string, courseLinkId: string) =>
  `${courseLinks(courseId, publicDetailId)}/${courseLinkId}`;

function* handleFetchCourseLinks({ payload }: FetchCoursePublicDetailCourseLinksAction) {
  yield put(
    RequestActions.getAuthed<CoursePublicDetailCourseLink[]>(
      courseLinks(payload.courseId, payload.publicDetailId),
      courseLinks =>
        actions.fetchCoursePublicDetailCourseLinksSuccess({
          publicDetailId: payload.publicDetailId,
          courseLinks
        }),
      ActionType.FETCH_COURSE_PUBLIC_DETAIL_COURSE_LINKS_FAILURE
    )
  );
}

function* handleCreateCourseLink({ payload }: CreateCoursePublicDetailCourseLinkAction) {
  yield put(
    RequestActions.postAuthed(
      courseLinks(payload.courseId, payload.publicDetailId),
      ActionType.CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINK_SUCCESS,
      ActionType.CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINK_FAILURE,
      false,
      payload
    )
  );
}

function* handleCreateCourseLinks({ payload }: CreateCoursePublicDetailCourseLinksAction) {
  yield put(
    RequestActions.postAuthed<CoursePublicDetailCourseLink[]>(
      `${courseLinks(payload.courseId, payload.publicDetailId)}/multi`,
      courseLinks =>
        actions.createCoursePublicDetailCourseLinksSuccess({
          publicDetailId: payload.publicDetailId,
          courseLinks
        }),
      ActionType.CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINK_FAILURE,
      false,
      payload
    )
  );
}

function* handleDeleteCourseLink({ payload }: DeleteCoursePublicDetailCourseLinkAction) {
  yield put(
    RequestActions.deleteAuthed(
      courseLink(payload.courseId, payload.publicDetailId, payload.courseLinkId),
      () =>
        actions.deleteCoursePublicDetailCourseLinkSuccess({
          publicDetailId: payload.publicDetailId,
          id: payload.courseLinkId
        }),
      ActionType.DELETE_COURSE_PUBLIC_DETAIL_COURSE_LINK_FAILURE
    )
  );
}

function* watchFetchCourseLinks() {
  yield takeLatest(ActionType.FETCH_COURSE_PUBLIC_DETAIL_COURSE_LINKS, handleFetchCourseLinks);
}

function* watchCreateCourseLink() {
  yield takeLatest(ActionType.CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINK, handleCreateCourseLink);
}

function* watchCreateCourseLinks() {
  yield takeLatest(ActionType.CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINKS, handleCreateCourseLinks);
}

function* watchDeleteCourseLink() {
  yield takeLatest(ActionType.DELETE_COURSE_PUBLIC_DETAIL_COURSE_LINK, handleDeleteCourseLink);
}

export const sagas = [
  fork(watchFetchCourseLinks),
  fork(watchCreateCourseLink),
  fork(watchCreateCourseLinks),
  fork(watchDeleteCourseLink)
];
