import type { Answer } from '../../store/types';
import type {
  ConstructData,
  ConstructSentenceData,
  Methods,
  MissingWordData,
  StrikeOutData
} from './types';

const regexWords = /(\b[^\s]+\b)/;

const tokenize = (sentence: any) => {
  return sentence
    .split(regexWords)
    .map((a: any) => {
      return a.replace(/[^a-zA-Z0-9]+/g, '');
    })
    .filter((a: any) => {
      return a;
    });
};

const wordInSentence = (sentence: any) => {
  return (w: any) => {
    return tokenize(sentence).indexOf(w) >= 0;
  };
};

export const methods: Methods = {
  construct: (e: ConstructData) => {
    return e.words.filter(wordInSentence).map((w: any) => {
      return {
        question: {
          type: 'text',
          category: e.title,
          text: w,
          content: w
        },
        answers: [
          {
            type: 'text',
            content: w,
            text: w,
            correct: true
          },
          {
            type: 'incorrect-answer',
            content: w,
            text: w,
            correct: false
          }
        ]
      };
    });
  },
  'construct-sentence': (e: ConstructSentenceData) => {
    return {
      question: {
        content: e.title,
        text: e.title,
        category: e.title,
        type: 'text'
      },
      answers: [
        {
          content: 'Correct sentence',
          text: 'Correct sentence',
          correct: true,
          type: 'text'
        },
        {
          correct: false,
          content: 'Incorrect sentence',
          text: 'Incorrect sentence',
          type: 'incorrect-answer'
        }
      ]
    };
  },
  'missing-word': (e: MissingWordData) => {
    const correctAnswers = e.words.filter((w: any) => {
      return e.title.indexOf(w) > -1;
    });

    return correctAnswers.map(a => {
      const incorrectAnswers: Answer[] = e.words
        .filter(w => {
          return w !== a;
        })
        .map(b => {
          return {
            content: b,
            text: b,
            correct: false,
            type: 'text'
          };
        });

      return {
        question: {
          type: 'text',
          category: e.title,
          content: a,
          text: a
        },
        answers: [
          {
            type: 'text',
            content: a,
            text: a,
            correct: true
          } as Answer
        ].concat(incorrectAnswers)
      };
    });
  },
  strikeout: (e: StrikeOutData) => {
    const title = e.title;
    return e.words.map((w: any) => {
      return {
        question: {
          type: 'text',
          category: title.replace(w.replaces, w.incorrect),
          content: w.incorrect,
          text: w.incorrect
        },
        answers: [
          {
            type: 'text',
            content: w.incorrect,
            text: w.incorrect,
            correct: true
          },
          {
            correct: false,
            content: w.incorrect,
            text: w.incorrect,
            type: 'incorrect-answer'
          }
        ]
      };
    });
  }
};

export default methods;
