import * as React from 'react';
import { useHistory } from 'react-router';

import { page, getPageName } from './utils';
import type { RouterPageViewProps } from './types';

/**
 *
 * @param {Object} routesMap - key-value mapping of routing name and path
 * @param {SegmentPageOptions} options - Segment Page options excludes 'name'
 *
 */
const RouterPageView: React.FC<React.PropsWithChildren<RouterPageViewProps>> = ({
  children,
  routesMap,
  category,
  options,
  properties,
  callback
}) => {
  const history = useHistory();

  React.useEffect(() => {
    const unlisten = history.listen(({ hash, pathname }) => {
      const pageName = getPageName(pathname, routesMap);
      const hashName = hash ? ` ${hash}` : '';

      // Once full SPA then we can ignore click back button on browser
      // if (action !== 'POP') {
      page({
        name: pageName ? `${pageName}${hashName}` : '',
        category,
        properties,
        options,
        callback
      });
      // }
    });

    return unlisten;
  }, []);
  return <>{children}</>;
};

export default RouterPageView;
