import * as React from 'react';
import styled, { css } from 'styled-components';
import { CircleOutlineIcon, CircleTickIcon, Typography } from '@edapp/ed-components';

type Props = {
  selected?: boolean;
  selectedIndex?: number;
};

const getSelectedOrderValue = (index?: number) => (index || index === 0 ? index + 1 : '');

export const SelectionOverlay: React.FC<React.PropsWithChildren<Props>> = ({ selected, selectedIndex, children }) => {
  const selectedOrderValue = getSelectedOrderValue(selectedIndex);

  return (
    <ActionWrapper>
      {children}
      <StyledCircleOutlineIcon color="white" />
      {!!selected &&
        (!!selectedOrderValue ? (
          <StyledBadge>
            <BadgeValue variant="subtext" color="white">
              {selectedOrderValue}
            </BadgeValue>
          </StyledBadge>
        ) : (
          <SelectedIcon color="blue" />
        ))}
    </ActionWrapper>
  );
};

const ActionWrapper = styled.div(
  ({ theme }) => `
  display: flex;
  align-items: center;
  position: absolute;
  top: ${theme.space(2)}px;
  right: ${theme.space(2)}px;
`
);

const StyledCircleOutlineIcon = styled(CircleOutlineIcon)`
  margin-left: ${({ theme }) => theme.space()}px;
  display: inline-block;
  height: 20px;
  width: 20px;
`;

const SelectedIcon = styled(CircleTickIcon)`
  position: absolute;
  top: 2px;
  right: 0;
  height: 16px;
  width: 16px;
  margin-right: 2px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
`;

const BadgeValue = styled(Typography)`
  ${({ theme }) => css`
    font-family: ${theme.fontFamily};
    letter-spacing: 0.25px;
    text-align: center;
  `}
`;

export const StyledBadge = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 3px;
  right: 3px;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.blue};
  border-radius: 50%;
  padding-top: 1px;
  width: 14px;
  height: 14px;
`;
