import * as React from 'react';

import { t } from 'i18next';
import styled from 'styled-components';

import { Box, CloseDialogButton, Typography } from '@edapp/ed-components';
import { Text } from '@edapp/sc-web-ui';

import { Tabs } from '../tabs';
import { SettingsDialogProvider } from './SettingsDialogContext';
import { StyledDialog } from './styled';
import type { Props, SettingsTabKeys } from './types';

export function SettingsDialog<Tabs extends SettingsTabKeys>({
  title = t('common.settings'),
  description,
  onClose,
  tabs,
  activeTab,
  onClickTab,
  ...rest
}: Props<Tabs>) {
  return (
    <StyledDialog onClose={onClose} {...rest}>
      <SettingsDialogProvider>
        <Box pt={4} px={4} pb={2} bgColor="white" flex flexDirection="column" gap={0.5}>
          <Text variant="titleLarge">{title}</Text>

          {!!description &&
            (typeof description === 'string' ? (
              <Typography variant="paragraph" color="textMuted">
                {description}
              </Typography>
            ) : (
              description
            ))}
        </Box>

        <Box px={4}>
          <StyledTabs tabs={tabs} activeTab={activeTab} onClickTab={onClickTab} />
        </Box>

        {!!onClose && <CloseDialogButton onClick={onClose} testId="close-setting-dialog-button" />}
      </SettingsDialogProvider>
    </StyledDialog>
  );
}

const StyledTabs = styled(Tabs)`
  padding: 0;
`;
