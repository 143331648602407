import { put, fork, takeLatest } from 'redux-saga/effects';
import { RequestActions } from '@edapp/request';
import type {
  FetchCoursePublicDetailExcludedLessonsAction,
  CreateCoursePublicDetailExcludedLessonAction,
  DeleteCoursePublicDetailExcludedLessonAction
} from './actions';
import ActionType, { actions } from './actions';
import type { CoursePublicDetailExcludedLesson } from './types';

const excludedLessons = (courseId: string, publicDetailId: string) =>
  `/api/courses/${courseId}/public-details/${publicDetailId}/excluded-lessons`;
const excludedLesson = (courseId: string, publicDetailId: string, excludedLessonId: string) =>
  `${excludedLessons(courseId, publicDetailId)}/${excludedLessonId}`;

function* handleFetchExcludedLessons({ payload }: FetchCoursePublicDetailExcludedLessonsAction) {
  yield put(
    RequestActions.getAuthed<CoursePublicDetailExcludedLesson[]>(
      excludedLessons(payload.courseId, payload.publicDetailId),
      excludedLessons =>
        actions.fetchCoursePublicDetailExcludedLessonsSuccess({
          publicDetailId: payload.publicDetailId,
          excludedLessons
        }),
      ActionType.FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSONS_FAILURE
    )
  );
}

function* handleCreateExcludedLesson({ payload }: CreateCoursePublicDetailExcludedLessonAction) {
  yield put(
    RequestActions.postAuthed(
      excludedLessons(payload.courseId, payload.publicDetailId),
      ActionType.CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_SUCCESS,
      ActionType.CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_FAILURE,
      false,
      payload
    )
  );
}

function* handleDeleteExcludedLesson({ payload }: DeleteCoursePublicDetailExcludedLessonAction) {
  yield put(
    RequestActions.deleteAuthed(
      excludedLesson(payload.courseId, payload.publicDetailId, payload.id),
      () =>
        actions.deleteCoursePublicDetailExcludedLessonSuccess({
          publicDetailId: payload.publicDetailId,
          id: payload.id
        }),
      ActionType.DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_FAILURE
    )
  );
}

function* watchFetchExcludedLessons() {
  yield takeLatest(
    ActionType.FETCH_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSONS,
    handleFetchExcludedLessons
  );
}

function* watchCreateExcludedLesson() {
  yield takeLatest(
    ActionType.CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON,
    handleCreateExcludedLesson
  );
}

function* watchDeleteExcludedLesson() {
  yield takeLatest(
    ActionType.DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON,
    handleDeleteExcludedLesson
  );
}

export const sagas = [
  fork(watchFetchExcludedLessons),
  fork(watchCreateExcludedLesson),
  fork(watchDeleteExcludedLesson)
];
