export const LOGGED_OUT_TIMESTAMP_KEY = 'logged-out-timestamp';

/**
 * Use this function to trigger multiple tabs logout
 */
export const multiTabLogout = () => {
  // reload the current tab
  window.location.href = '/login'; // TODO: https://ed-app.atlassian.net/browse/ED-28784 change back to reload
  // reload the other tabs
  window.localStorage.setItem(LOGGED_OUT_TIMESTAMP_KEY, Date.now().toString());
  // clear storage item
  window.localStorage.removeItem(LOGGED_OUT_TIMESTAMP_KEY);
};

/**
 * Logging out from one tab will also end the session on other tabs
 */
export function multiTabLogoutListener() {
  const handleStorageChange = (e: StorageEvent) => {
    if (e.key === LOGGED_OUT_TIMESTAMP_KEY) {
      const handleTabVisible = function () {
        // only reload tab when the tab is active
        if (document.visibilityState === 'visible' && e.newValue) {
          window.location.reload();
          window.removeEventListener('visibilitychange', handleTabVisible);
        }
      };
      document.addEventListener('visibilitychange', handleTabVisible);
    }
  };
  window.addEventListener('storage', handleStorageChange);
}
