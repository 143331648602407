import * as React from 'react';

import { usePersistedState } from '@edapp/ed-components';
import { SortByEnum, useGetCourseCollections } from '@edapp/hippo-client';
import { flattenPages } from '@edapp/request';

import type {
  CourseListContextProviderProps,
  CourseListContextType,
  SortType,
  UseCourseListContext
} from './types';
import { useFilterState } from './use-filter-state';

const CourseListContext = React.createContext<CourseListContextType | undefined>(undefined);

export const CourseListContextProvider: React.FC<React.PropsWithChildren<CourseListContextProviderProps>> = ({
  children,
  userId
}) => {
  const [sortState, setSortState] = usePersistedState<SortType>(
    undefined,
    `ed:course-list:sort:${userId}`
  );

  const {
    defaultFilterState,
    filterState,
    isFiltered,
    updateFilterState,
    resetFilterState
  } = useFilterState(userId);

  const {
    refetch,
    data,
    isFetching,
    error,
    fetchNextPage,
    hasNextPage = false
  } = useGetCourseCollections(
    {
      includes: {
        includeCourseSummary: true,
        includeDraftCourseInCount: true,
        orderByIsManual: false,
        includeNotManual: sortState?.by !== SortByEnum.COLLECTIONS
      },
      filter: filterState,
      sort: sortState
    },
    { refetchOnWindowFocus: false }
  );

  const { items: courseCollections, totalCount } = flattenPages(data);

  const isListEmpty = React.useMemo<boolean>(() => courseCollections.length === 0 && !isFetching, [
    courseCollections,
    isFetching
  ]);

  return (
    <CourseListContext.Provider
      value={{
        sortState,
        setSortState,
        defaultFilterState,
        filterState,
        updateFilterState,
        resetFilterState,
        courseCollections,
        totalCount,
        refetch,
        fetchNextPage,
        hasNextPage,
        isFetching,
        error,
        isListEmpty,
        isFiltered
      }}
    >
      {children}
    </CourseListContext.Provider>
  );
};

export const useCourseListContext: UseCourseListContext = () => {
  const context = React.useContext(CourseListContext);

  if (!context) {
    throw Error(`CourseListContext not available`);
  }

  return context;
};
