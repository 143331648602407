import type { ApplicationResponse, BillingPlanDetailsType } from '@edapp/hippo-client';
import type { UserResponse } from '@edapp/user-info';
import { createAction } from '@edapp/utils';

import { ConfigActionTypes } from './constants';

export const windowResized = (height: number, width: number) =>
  createAction(ConfigActionTypes.WINDOW_RESIZED, { height, width });

export const fetchUserToken = () => createAction(ConfigActionTypes.FETCH_USER_TOKEN);

export const setToken = (token: string) => createAction(ConfigActionTypes.SET_TOKEN, { token });

export const fetchLatestInvoice = () => createAction(ConfigActionTypes.FETCH_LATEST_INVOICE);

export const retryPayInvoice = (id: string) =>
  createAction(ConfigActionTypes.RETRY_PAY_INVOICE, { id });

export const updateUserFirstAndLastName = (firstName: string, lastName: string) =>
  createAction(ConfigActionTypes.UPDATE_USER_FIRST_AND_LAST_NAME, { firstName, lastName });

export const updateUserData = (data: Partial<UserResponse>) =>
  createAction(ConfigActionTypes.UPDATE_USER_DATA, data);
export const updateUserInfoLoading = (loading: boolean) =>
  createAction(ConfigActionTypes.UPDATE_USER_INFO_LOADING, loading);
export const updateApplicationData = (data: Partial<ApplicationResponse>) =>
  createAction(ConfigActionTypes.UPDATE_APPLICATION_DATA, data);
export const updateBillingData = (data: Partial<BillingPlanDetailsType>) =>
  createAction(ConfigActionTypes.UPDATE_BILLING_DATA, data);

export const ConfigActions = {
  windowResized,
  fetchUserToken,
  setToken,
  fetchLatestInvoice,
  retryPayInvoice,
  updateUserFirstAndLastName,
  updateUserData,
  updateApplicationData,
  updateBillingData
};
