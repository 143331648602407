import { put, fork, takeLatest } from 'redux-saga/effects';
import { RequestActions } from '@edapp/request';
import type {
  FetchCoursePublicDetailUrlSlugAction,
  CreateCoursePublicDetailUrlSlugAction,
  UpdateCoursePublicDetailUrlSlugAction
} from './actions';
import { ActionType } from './actions';

const urlSlugs = (courseId: string, publicDetailId: string) =>
  `/api/courses/${courseId}/public-details/${publicDetailId}/url-slugs`;
const urlSlug = (courseId: string, publicDetailId: string, urlSlugId: string) =>
  `${urlSlugs(courseId, publicDetailId)}/${urlSlugId}`;

function* handleFetchUrlSlug({ payload }: FetchCoursePublicDetailUrlSlugAction) {
  yield put(
    RequestActions.getAuthed(
      urlSlugs(payload.courseId, payload.publicDetailId),
      ActionType.FETCH_COURSE_PUBLIC_DETAIL_URL_SLUG_SUCCESS,
      ActionType.FETCH_COURSE_PUBLIC_DETAIL_URL_SLUG_FAILURE
    )
  );
}

function* handleCreateUrlSlug({ payload }: CreateCoursePublicDetailUrlSlugAction) {
  yield put(
    RequestActions.postAuthed(
      urlSlugs(payload.courseId, payload.publicDetailId),
      ActionType.CREATE_COURSE_PUBLIC_DETAIL_URL_SLUG_SUCCESS,
      ActionType.CREATE_COURSE_PUBLIC_DETAIL_URL_SLUG_FAILURE,
      false,
      payload
    )
  );
}

function* handleUpdateUrlSlug({ payload }: UpdateCoursePublicDetailUrlSlugAction) {
  yield put(
    RequestActions.putAuthed(
      urlSlug(payload.courseId, payload.publicDetailId, payload.id),
      ActionType.UPDATE_COURSE_PUBLIC_DETAIL_URL_SLUG_SUCCESS,
      ActionType.UPDATE_COURSE_PUBLIC_DETAIL_URL_SLUG_FAILURE,
      false,
      payload
    )
  );
}

function* watchFetchUrlSlug() {
  yield takeLatest(ActionType.FETCH_COURSE_PUBLIC_DETAIL_URL_SLUG, handleFetchUrlSlug);
}

function* watchCreateUrlSlug() {
  yield takeLatest(ActionType.CREATE_COURSE_PUBLIC_DETAIL_URL_SLUG, handleCreateUrlSlug);
}

function* watchUpdateUrlSlug() {
  yield takeLatest(ActionType.UPDATE_COURSE_PUBLIC_DETAIL_URL_SLUG, handleUpdateUrlSlug);
}

export const sagas = [fork(watchFetchUrlSlug), fork(watchCreateUrlSlug), fork(watchUpdateUrlSlug)];
