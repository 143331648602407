import { RequestUtils } from '@edapp/request';
import type { GetUserGroupsWithFiltersRequest, GetUserGroupsWithFiltersResponse } from './types';

export const getUserGroupsWithFilters = async (
  hippoUrl: string,
  userToken: string,
  request: GetUserGroupsWithFiltersRequest
): Promise<GetUserGroupsWithFiltersResponse> => {
  return await RequestUtils.httpFetch<
    GetUserGroupsWithFiltersResponse,
    GetUserGroupsWithFiltersRequest
  >('GET', `${hippoUrl}/api/usergroups/list`, userToken, request);
};
