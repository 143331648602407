import { Typography } from '@edapp/ed-components';
import * as React from 'react';
import styled from 'styled-components';

export type LabelProps = {
  className?: string;
  htmlFor?: string;
  text: string;
};

const StyledLabel = styled(Typography)`
  display: inline-block;
  max-width: 100%;
  font-weight: 600;
`;

const Label: React.FunctionComponent<LabelProps> = ({ className, htmlFor, text }) => {
  return (
    <StyledLabel as="label" className={className} htmlFor={htmlFor} color="text">
      {text}
    </StyledLabel>
  );
};

export default Label;
