import styled, { css } from 'styled-components';
import { IconButton } from '../../button/IconButton';
import { Input } from '../input/Input';
import type { Props } from './types';

export const INPUT_GROUP_TEST_ID = 'input-group';

export const InputGroup = styled.div.attrs<Props>(({ testId = INPUT_GROUP_TEST_ID }) => ({
  'data-testid': testId
}))(
  ({ theme }) => css`
    display: flex;
    flex-flow: row nowrap;

    & ${Input} {
      flex-grow: 1;
    }

    & ${IconButton} {
      margin-bottom: ${theme.space(2)}px;
    }

    & > * {
      border-radius: 0;
    }

    & > ${Input}:first-child, & > ${IconButton}:first-child {
      border-radius: ${theme.sizes.borderRadius}px 0 0 ${theme.sizes.borderRadius}px;
    }

    & > ${Input}:last-child, & > ${IconButton}:last-child {
      border-radius: 0 ${theme.sizes.borderRadius}px ${theme.sizes.borderRadius}px 0;
    }

    & > ${Input}:only-child {
      border-radius: ${theme.sizes.borderRadius}px;
    }
  `
);
