import * as React from 'react';

import { differenceInCalendarDays } from 'date-fns';
import styled from 'styled-components';

import { Box, Loading, Typography } from '@edapp/ed-components';
import { Tabs } from '@edapp/ed-components/src/common/tabs';
import type { TabsType } from '@edapp/ed-components/src/common/tabs';
import { removeUTCOffset } from '@edapp/utils';

import { LeaderboardType, RankingTypeEnum } from '../types';
import type { RankingParticipant } from '../types';
import LeaderboardRanking from './leaderboard-ranking/LeaderboardRanking';
import { getParticipants } from './leaderboard-ranking/utils';
import { TopThree } from './top-three/TopThree';

type LeaderboardProps = {
  type: LeaderboardType;
  rankings?: RankingParticipant[];
  endDate?: string;
  error?: string;
  loading?: boolean;
  i18n?: any;
  textOptions: {
    teams: string;
    global: string;
    expiredOneDayAgo: string;
    endsToday: string;
    expires: (days: number) => string;
    expiresIn: (days: number) => string;
  };
};
const Leaderboard: React.FunctionComponent<LeaderboardProps> = ({
  rankings = [],
  type,
  endDate,
  loading,
  error,
  textOptions
}) => {
  const [activeRankingType, setActiveRankingType] = React.useState(RankingTypeEnum.GLOBAL);

  if (loading) {
    return <StyledLoading />;
  }

  if (error || !rankings.length) {
    return <>{error}</>; // TODO: https://safetyculture.atlassian.net/browse/TRAINING-556
  }

  function getExpiredInText(endDate: string) {
    // Positive days = yet to expire
    // Negative days = has expired
    const daysBetween = differenceInCalendarDays(new Date(endDate), new Date());

    if (daysBetween > 0) {
      return textOptions.expires(daysBetween);
    }

    if (daysBetween === 0) {
      return textOptions.endsToday;
    }

    if (daysBetween === -1) {
      return textOptions.expiredOneDayAgo;
    } else {
      return textOptions.expiresIn(daysBetween * -1);
    }
  }

  const daysLeftMessage = !!endDate && getExpiredInText(removeUTCOffset(endDate)!);

  const filterRankings = (rankings: RankingParticipant[], activeRankingType: RankingTypeEnum) => {
    if (activeRankingType === RankingTypeEnum.GLOBAL) {
      return rankings.filter(ranking => ranking.type === 'individual');
    } else {
      return rankings.filter(ranking => ranking.type === 'group');
    }
  };

  const showRankingToggle =
    type === LeaderboardType.Course && rankings.some(ranking => ranking.type === 'group');

  const tabs: TabsType<RankingTypeEnum> = {
    [RankingTypeEnum.GLOBAL]: { title: textOptions.global, content: null },
    [RankingTypeEnum.TEAM]: { title: textOptions.teams, content: null }
  };

  const isCourseOrRRLeaderboard =
    type === LeaderboardType.Course || type === LeaderboardType.RapidRefresher;

  const filteredRankings = isCourseOrRRLeaderboard
    ? filterRankings(rankings, activeRankingType)
    : rankings;

  const { topThree } = getParticipants(filteredRankings);

  const onToggleRanking = (index: number) => {
    const activeType = Object.keys(tabs)?.[index] as RankingTypeEnum | undefined;
    if (!activeType) return;
    setActiveRankingType(activeType);
  };

  return (
    <Wrapper flexDirection="column" flex="1 1 auto">
      <TopThree topThreeUsers={topThree} />
      <Box
        flex="1 1 auto"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        bgColor="lightGrey"
      >
        {showRankingToggle && <StyledTabs tabs={tabs} onClickTab={onToggleRanking} isCentered />}
        {!!daysLeftMessage && (
          <Typography as="h3" variant="subtext2" color="textMuted" mt={3}>
            {daysLeftMessage}
          </Typography>
        )}
        <LeaderboardRanking participants={filteredRankings} leaderboardType={type} />
      </Box>
    </Wrapper>
  );
};

const Wrapper = styled(Box)`
  width: 100%;
  overflow: auto;
`;
const StyledTabs = styled(Tabs)`
  flex-shrink: 0;
`;

const StyledLoading = styled(Loading)`
  margin-top: ${({ theme }) => theme.space(6)}px;
`;

export default Leaderboard;
