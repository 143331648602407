import type { ActionWithPayload, ActionsUnion } from '@edapp/utils';
import { createAction } from '@edapp/utils';
import type { TargetedAudience } from './types';
import type { CoursePublicDetail } from './types';
import type { EdErrorResponseType } from '../../../types';

export enum ActionType {
  FETCH_COURSE_PUBLIC_DETAIL = 'FETCH_COURSE_PUBLIC_DETAIL',
  FETCH_COURSE_PUBLIC_DETAIL_SUCCESS = 'FETCH_COURSE_PUBLIC_DETAIL_SUCCESS',
  FETCH_COURSE_PUBLIC_DETAIL_FAILURE = 'FETCH_COURSE_PUBLIC_DETAIL_FAILURE',

  CREATE_COURSE_PUBLIC_DETAIL = 'CREATE_COURSE_PUBLIC_DETAIL',
  CREATE_COURSE_PUBLIC_DETAIL_SUCCESS = 'CREATE_COURSE_PUBLIC_DETAIL_SUCCESS',
  CREATE_COURSE_PUBLIC_DETAIL_FAILURE = 'CREATE_COURSE_PUBLIC_DETAIL_FAILURE',

  UPDATE_COURSE_PUBLIC_DETAIL = 'UPDATE_COURSE_PUBLIC_DETAIL',
  UPDATE_COURSE_PUBLIC_DETAIL_SUCCESS = 'UPDATE_COURSE_PUBLIC_DETAIL_SUCCESS',
  UPDATE_COURSE_PUBLIC_DETAIL_FAILURE = 'UPDATE_COURSE_PUBLIC_DETAIL_FAILURE'
}

type FetchCoursePublicDetail = { courseId: string };
export type FetchCoursePublicDetailAction = ActionWithPayload<
  ActionType.FETCH_COURSE_PUBLIC_DETAIL,
  FetchCoursePublicDetail
>;

type FetchCoursePublicDetailSuccess = CoursePublicDetail;
export type FetchCoursePublicDetailSuccessAction = ActionWithPayload<
  ActionType.FETCH_COURSE_PUBLIC_DETAIL_SUCCESS,
  FetchCoursePublicDetailSuccess
>;

type FetchCoursePublicDetailFailure = EdErrorResponseType;
export type FetchCoursePublicDetailFailureAction = ActionWithPayload<
  ActionType.FETCH_COURSE_PUBLIC_DETAIL_FAILURE,
  FetchCoursePublicDetailFailure
>;

type CreateCoursePublicDetail = {
  courseId: string;
  urlSlug?: string;
  overview?: string;
  thumbnail?: string;
  extraAuthors?: string;
  hideLessonsPreview?: boolean;
  isIndexed?: boolean;
  targetedAudience?: TargetedAudience;
};
export type CreateCoursePublicDetailAction = ActionWithPayload<
  ActionType.CREATE_COURSE_PUBLIC_DETAIL,
  CreateCoursePublicDetail
>;

type CreateCoursePublicDetailSuccess = CoursePublicDetail;
export type CreateCoursePublicDetailSuccessAction = ActionWithPayload<
  ActionType.CREATE_COURSE_PUBLIC_DETAIL_SUCCESS,
  CreateCoursePublicDetailSuccess
>;

type CreateCoursePublicDetailFailure = EdErrorResponseType;
export type CreateCoursePublicDetailFailureAction = ActionWithPayload<
  ActionType.CREATE_COURSE_PUBLIC_DETAIL_FAILURE,
  CreateCoursePublicDetailFailure
>;

type UpdateCoursePublicDetail = {
  id: string;
} & CreateCoursePublicDetail;
export type UpdateCoursePublicDetailAction = ActionWithPayload<
  ActionType.UPDATE_COURSE_PUBLIC_DETAIL,
  UpdateCoursePublicDetail
>;

type UpdateCoursePublicDetailSuccess = CoursePublicDetail;
export type UpdateCoursePublicDetailSuccessAction = ActionWithPayload<
  ActionType.UPDATE_COURSE_PUBLIC_DETAIL_SUCCESS,
  UpdateCoursePublicDetailSuccess
>;

type UpdateCoursePublicDetailFailure = EdErrorResponseType;
export type UpdateCoursePublicDetailFailureAction = ActionWithPayload<
  ActionType.UPDATE_COURSE_PUBLIC_DETAIL_FAILURE,
  UpdateCoursePublicDetailFailure
>;

export const actions = {
  fetchCoursePublicDetail: (payload: FetchCoursePublicDetail): FetchCoursePublicDetailAction =>
    createAction(ActionType.FETCH_COURSE_PUBLIC_DETAIL, payload),
  fetchCoursePublicDetailSuccess: (
    payload: FetchCoursePublicDetailSuccess
  ): FetchCoursePublicDetailSuccessAction =>
    createAction(ActionType.FETCH_COURSE_PUBLIC_DETAIL_SUCCESS, payload),
  fetchCoursePublicDetailFailure: (
    payload: FetchCoursePublicDetailFailure
  ): FetchCoursePublicDetailFailureAction =>
    createAction(ActionType.FETCH_COURSE_PUBLIC_DETAIL_FAILURE, payload),

  createCoursePublicDetail: (payload: CreateCoursePublicDetail): CreateCoursePublicDetailAction =>
    createAction(ActionType.CREATE_COURSE_PUBLIC_DETAIL, payload),
  createCoursePublicDetailSuccess: (
    payload: CreateCoursePublicDetailSuccess
  ): CreateCoursePublicDetailSuccessAction =>
    createAction(ActionType.CREATE_COURSE_PUBLIC_DETAIL_SUCCESS, payload),
  createCoursePublicDetailFailure: (
    payload: CreateCoursePublicDetailFailure
  ): CreateCoursePublicDetailFailureAction =>
    createAction(ActionType.CREATE_COURSE_PUBLIC_DETAIL_FAILURE, payload),

  updateCoursePublicDetail: (payload: UpdateCoursePublicDetail): UpdateCoursePublicDetailAction =>
    createAction(ActionType.UPDATE_COURSE_PUBLIC_DETAIL, payload),
  updateCoursePublicDetailSuccess: (
    payload: UpdateCoursePublicDetailSuccess
  ): UpdateCoursePublicDetailSuccessAction =>
    createAction(ActionType.UPDATE_COURSE_PUBLIC_DETAIL_SUCCESS, payload),
  updateCoursePublicDetailFailure: (
    payload: UpdateCoursePublicDetailFailure
  ): UpdateCoursePublicDetailFailureAction =>
    createAction(ActionType.UPDATE_COURSE_PUBLIC_DETAIL_FAILURE, payload)
};

export type ActionTypes = ActionsUnion<typeof actions>;
