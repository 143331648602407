import type * as React from 'react';

import { useCheckPermission } from '@rio/utils/permissions/hooks';
import type { PermissionType } from '@rio/utils/permissions/types';

export type PermissionWrapperProps = PermissionType & {
  children: React.ReactElement | ((permitted: boolean) => React.ReactElement | null) | null;
};

/**
 * This class checks for userPermission and/or acessPermissions
 */
const PermissionWrapper: React.FC<PermissionWrapperProps> = ({ children, ...permission }) => {
  const permitted = useCheckPermission(permission);

  if (typeof children === 'function') {
    return children(permitted);
  }

  if (!!permitted) {
    return children;
  }

  return null;
};

export default PermissionWrapper;
