import * as React from 'react';
import { StyledProgressBar } from '../shared';

type Props = {
  value: number;
};

export const LessonLimitProgressBar: React.FC<Props> = ({ value }) => {
  return <StyledProgressBar value={value} />;
};
