import * as React from 'react';
import type { LabelledTextAreaProps } from './types';
import { LabelledInput } from './LabelledInput';
import { TextArea } from '../input/TextArea';

export const LabelledTextArea: React.FC<LabelledTextAreaProps> = ({
  isFullWidth,
  spacing,
  label,
  helperText,
  className,
  testId,
  error,
  ...inputProps
}) => {
  const inputId = inputProps.id || inputProps.name;

  return (
    <LabelledInput
      name={inputProps.name}
      isFullWidth={isFullWidth}
      spacing={spacing}
      label={label}
      helperText={helperText}
      error={error}
      className={className}
    >
      <TextArea
        id={inputId}
        data-testid={testId ?? 'textarea'}
        hasMargin={false}
        error={!!error}
        {...inputProps}
      />
    </LabelledInput>
  );
};
