import { createAction } from '@edapp/utils';
import type { ConferenceRouteState } from './types';
import type { MeetingType } from '@edapp/hippo-client';
import type { History } from 'history';

export enum ConferenceActionTypes {
  FETCH_CONFERENCE = 'FETCH_CONFERENCE',
  FETCH_CONFERENCE_SUCCESS = 'FETCH_CONFERENCE_SUCCESS',
  FETCH_CONFERENCE_FAILURE = 'FETCH_CONFERENCE_FAILURE',

  CREATE_CONFERENCE = 'CREATE_CONFERENCE',
  CREATE_CONFERENCE_SUCCESS = 'CREATE_CONFERENCE_SUCCESS',
  CREATE_CONFERENCE_FAILURE = 'CREATE_CONFERENCE_FAILURE',

  UPDATE_CONFERENCE = 'UPDATE_CONFERENCE',
  UPDATE_CONFERENCE_SUCCESS = 'UPDATE_CONFERENCE_SUCCESS',
  UPDATE_CONFERENCE_FAILURE = 'UPDATE_CONFERENCE_FAILURE'
}

export const ConferenceActions = {
  fetchConference: (id: string) => createAction(ConferenceActionTypes.FETCH_CONFERENCE, id),
  createConference: (history: History, routeState: ConferenceRouteState, title?: string) =>
    createAction(ConferenceActionTypes.CREATE_CONFERENCE, { title, history, routeState }),
  updateConference: (id: string, conference: Partial<MeetingType>) =>
    createAction(ConferenceActionTypes.UPDATE_CONFERENCE, { id, conference })
};
