import * as React from 'react';

import styled from 'styled-components';

import { LOADING_COLOR_MAP } from '../../common/button/Button';
import { ButtonLink } from './ButtonLink';
import { iconButtonStyle } from './IconButton';
import type { IconButtonProps } from './types';

export const ICON_BUTTON_LINK_TEST_ID = 'icon-button-link';

export const IconButtonLink: React.FC<IconButtonProps<'a'>> = ({
  testId = ICON_BUTTON_LINK_TEST_ID,
  icon: IconComponent,
  variant,
  iconSize,
  iconColor,
  disabled,
  isDisabled,
  children,
  ...props
}) => {
  const internalDisabled = disabled || isDisabled;

  return (
    <StyledButtonLink variant={variant} isDisabled={internalDisabled} testId={testId} {...props}>
      {!!IconComponent && (
        <IconComponent
          color={
            isDisabled ? 'textMuted' : iconColor || (variant ? LOADING_COLOR_MAP[variant] : 'blue')
          }
          size={iconSize}
        />
      )}
      {children}
    </StyledButtonLink>
  );
};

const StyledButtonLink = styled(ButtonLink)<IconButtonProps>`
  ${iconButtonStyle}
  text-decoration: none;
  display: inline-flex;

  &:focus,
  &:hover {
    text-decoration: none !important;
  }
`;
