import type { CSSProperties } from 'react';

import type { Styles as ReactSelectStyles } from 'react-select/src/styles';
import type { DefaultTheme } from 'styled-components';

import { cssToJS } from '@edapp/themes';

// react-select accepts a style object with access to the styling of
// internal components, as well as some component state:
// https://react-select.com/styles

const fontStyles = (theme: DefaultTheme, variant: 'normal' | 'subtext2' = 'normal') => ({
  fontFamily: theme.fontFamily,
  ...cssToJS(theme.typography[variant])
});

export default function stylesFn(
  theme: DefaultTheme,
  extraMenuStyle?: CSSProperties
): ReactSelectStyles {
  return {
    control: (base, state) => ({
      ...base,
      fontFamily: theme.fontFamily,
      flexWrap: 'nowrap',
      minHeight: `${theme.space(5)}px`,
      boxShadow: 'none',
      borderColor: state.isFocused ? theme.colors.blue : theme.colors.grey,
      borderRadius: `${theme.sizes.borderRadius * 2}px`,
      background: theme.colors.white,
      transition: `border-color ${theme.transition.slow}`,
      color: state.selectProps.isDisabled ? theme.colors.textMuted : theme.colors.text,
      '&:hover': {
        borderColor: state.isFocused ? theme.colors.blue : theme.colors.greyHover
      }
    }),
    groupHeading: base => ({
      ...base,
      ...fontStyles(theme, 'subtext2'),
      marginBottom: 4,
      letterSpacing: '0.02em',
      textTransform: 'uppercase',
      // Text overflow must be hidden to work with list virtualization
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }),
    indicatorsContainer: (base, state) => ({
      ...base,
      color: state.selectProps.isDisabled ? theme.colors.textMuted : theme.colors.text
    }),
    indicatorSeparator: (base, state) => ({
      ...base,
      backgroundColor: theme.colors.grey,
      margin: `2px ${theme.space()}px`,
      visibility:
        !state.selectProps.isDisabled &&
        (state.selectProps.isClearable || state.selectProps.isMulti) &&
        state.hasValue
          ? 'visible'
          : 'hidden'
    }),
    input: base => ({
      ...base,
      ...fontStyles(theme),
      display: 'flex',
      padding: 0,
      margin: '1px 0 0 0',
      color: theme.colors.text,
      // The actual input element is rendered as a child
      '& input': {
        font: 'inherit',
        lineHeight: 'inherit'
      }
    }),
    menu: base => ({
      ...base,
      margin: `${theme.space(0.5)}px 0`,
      boxShadow: `${theme.shadows.shallow}`,
      borderRadius: `${theme.sizes.borderRadius * 2}px`,
      border: `1px solid ${theme.colors.greyHover}`,
      background: theme.colors.white,
      overflow: 'hidden',
      zIndex: 999,
      ...extraMenuStyle
    }),
    menuPortal: base => ({
      ...base,
      zIndex: 2000
    }),
    option: (base, state) => ({
      ...base,
      ...fontStyles(theme),
      color: state.isDisabled ? theme.colors.textMuted : theme.colors.text,
      cursor: state.isDisabled ? 'initial' : 'pointer',
      background: state.isFocused
        ? theme.colors.lightBlue
        : state.isSelected
        ? theme.colors.lightGrey
        : theme.colors.white,
      padding: `${theme.space()}px ${theme.space(2)}px`,
      // Text overflow must be hidden to work with list virtualization
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '&:active': {
        background: state.isDisabled ? theme.colors.white : theme.colors.lightBlue
      }
    }),
    placeholder: base => ({
      ...base,
      ...fontStyles(theme),
      color: theme.colors.textMuted,
      margin: 0,
      width: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      marginRight: 0
    }),
    singleValue: (base, state) => ({
      ...base,
      ...fontStyles(theme),
      color: state.selectProps.isDisabled ? theme.colors.textMuted : theme.colors.text,
      margin: 0
    }),
    valueContainer: base => ({
      ...base,
      margin: `0 0 0 ${theme.space()}px`,
      padding: `${theme.space(0.5)}px 0`
    })
  };
}
