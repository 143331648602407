import { updateSlideMetadata } from '@edapp/authoring-logic';
import { genId } from '@edapp/utils';
import { defaultLessonConfig } from './constants';
import type { BetterPptxState, Lesson, PptSlide, Slide } from './types';

export const updateLesson = (
  state: BetterPptxState,
  lessonIndex: number,
  updateCallback: (data: Lesson) => Lesson
): BetterPptxState => {
  return {
    ...state,
    lessons: state.lessons.map((l, i) => {
      if (i === lessonIndex) {
        return updateCallback(l);
      }

      return l;
    })
  };
};

export const updateLessonById = (
  state: BetterPptxState,
  lessonId: string,
  updateCallback: (data: Lesson) => Lesson
): BetterPptxState => {
  return {
    ...state,
    lessons: state.lessons.map(l => {
      if (l.id === lessonId) {
        return updateCallback(l);
      }

      return l;
    })
  };
};

export const createLesson = (title: string, slides: PptSlide[]): Lesson => {
  return {
    title,
    slides: slides
      // filter slides with no candidates
      .filter(s => !!s.conversionCandidates.length && !!s.conversionCandidates[0])
      .map<Slide>((s, i) => {
        const candidates = s.conversionCandidates;
        return {
          ...candidates[0],
          suggestedByPpt: true,
          pptSlideIndex: i
        };
      }),
    config: defaultLessonConfig
  };
};

export const createSimpleImageSlide = (
  url: string,
  pptSlideIndex: number,
  suggested: boolean
): Slide => ({
  id: genId(),
  pptSlideIndex,
  suggestedByPpt: suggested,
  metadata: updateSlideMetadata({} as any),
  type: 'image',
  subtype: 'image',
  name: 0,
  displayIndex: 0,
  templateName: 'Single image',
  templateDescription: 'Show a single image',
  permanent: false,
  data: {
    socialLearning: {
      enabled: false
    },
    narration: {
      progress_on_completion: false,
      can_not_be_skipped: false,
      audio_file: null
    },
    url: url,
    size: null,
    prompt: 'Select the image for more details',
    lightbox: null,
    compose: true,
    autofocus: false,
    tapAnywhere: false,
    caption: {
      title: '',
      content: '',
      background: null,
      textColor: null
    },
    doneText: "Ok, I'm done"
  }
});
