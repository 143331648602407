import * as React from 'react';

import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import type { RouteComponentProps } from 'react-router';
import { Grid } from 'react-styled-flexboxgrid';

import { ErrorFallbackUI } from '@edapp/courseware-ui';
import { Loading } from '@edapp/courseware-ui';
import { Box } from '@edapp/ed-components';
import SavingLabel from '@rio/components/common/SavingLabel/SavingLabel';
import { RestrictionCard } from '@rio/components/common/restriction-prompts/card';
import UserGroupItemBreadcrumb from '@rio/components/user-groups/user-group-item/UserGroupItemBreadcrumb/UserGroupItemBreadcrumb';
import UserGroupItemContent from '@rio/components/user-groups/user-group-item/UserGroupItemContent/UserGroupItemContent';
import UserGroupItemTitle from '@rio/components/user-groups/user-group-item/UserGroupItemTitle/UserGroupItemTitle';
import { hasBillingPermission } from '@rio/store/config/selectors';
import type { LmsStoreState } from '@rio/store/types';
import { fetchUserGroup, fetchUserGroupStatus } from '@rio/store/userGroups/actions';
import { getIsMutatingUserGroup, getMutatingUserGroupError } from '@rio/store/userGroups/selectors';
import { BillingPermission } from '@rio/utils/permissions/constants';

import UserGroupStatusDialog from './UserGroupStatusDialog';

// Query the back end for a maximum of 2 minutes
// before asking the user to come back at another time
const MAX_STATUS_CALLS = 24;

type Props = RouteComponentProps<{ groupId: string }> &
  typeof mapDispatchToProps &
  ReturnType<typeof mapStateToProps>;

type State = {
  statusDialogOpen: boolean;
};

class UserGroupItem extends React.Component<Props, State> {
  checkStatusInterval: any;
  checkStatusCalls: number;

  constructor(props: Props) {
    super(props);

    this.state = {
      statusDialogOpen: false
    };

    this.checkStatusCalls = 0;
  }

  componentWillMount() {
    const { groupId } = this.props.match.params;
    this.props.fetchUserGroup(groupId);
    if (groupId !== 'new' && this.props.isDynamicGroup) {
      this.props.fetchUserGroupStatus(groupId);
    }
  }

  componentDidUpdate() {
    const { groupId } = this.props.match.params;
    if (groupId === 'new') {
      return;
    }

    if (
      !this.props.allowedToEditStatus &&
      !this.checkStatusInterval &&
      this.checkStatusCalls < MAX_STATUS_CALLS
    ) {
      this.setStatusInterval();
    } else if (!!this.props.allowedToEditStatus) {
      this.clearStatusInterval();
    }
  }

  componentWillUnmount() {
    this.clearStatusInterval();
  }

  setStatusInterval = () => {
    this.checkStatusInterval = setInterval(() => {
      const { groupId } = this.props.match.params;
      this.props.fetchUserGroupStatus(groupId);
      ++this.checkStatusCalls;

      if (this.checkStatusCalls > MAX_STATUS_CALLS) {
        this.clearStatusInterval();
        this.setState({ statusDialogOpen: true });
      }
    }, 5000);
  };

  clearStatusInterval = () => {
    clearInterval(this.checkStatusInterval);
    this.checkStatusInterval = null;
  };

  onCloseDialog = () => {
    window.location.href = `/user-groups`;
  };

  renderContent() {
    if (this.props.fetchError) {
      return <ErrorFallbackUI text={this.props.fetchError} />;
    }

    if (this.props.fetchLoading) {
      return <Loading />;
    }

    return (
      <>
        <SavingLabel saving={this.props.mutating} errorMsg={this.props.mutateError} />

        <Grid>
          {this.props.hasUserGroupAccess && (
            <UserGroupItemBreadcrumb groupId={this.props.match.params.groupId} />
          )}

          <UserGroupItemTitle groupId={this.props.match.params.groupId} />
        </Grid>
        <Grid>
          {this.props.hasUserGroupAccess ? (
            <UserGroupItemContent groupId={this.props.match.params.groupId} />
          ) : (
            <Box flex={true} justifyContent="center" m="lg">
              <RestrictionCard
                imgSrc="image/upload/v1605295254/user-groups/UG_Image.png"
                title="Deliver the right content to the right learners with User Groups"
                description="Organise your learners by job title, geographic location, team structures and more."
                benefitTitle="With User Groups you can"
                benefits={[
                  'Assign different courses to different learners',
                  'Appoint managers to track and manage course completion for their group',
                  'Track group performance with separate analytics',
                  'Create leaderboards and prize draws between different groups of learners'
                ]}
                learnMore="https://support.edapp.com/user-groups"
              />
            </Box>
          )}
        </Grid>

        <UserGroupStatusDialog open={this.state.statusDialogOpen} onClose={this.onCloseDialog} />
      </>
    );
  }

  render() {
    return (
      <>
        {!this.props.isUxp && (
          <Helmet title={`Ed LMS - User Group "${this.props.userGroupName}"`} />
        )}

        {this.renderContent()}
      </>
    );
  }
}

const mapStateToProps = (state: LmsStoreState) => ({
  fetchLoading: state.userGroups.fetchLoading,
  fetchError: state.userGroups.fetchError,

  mutating: getIsMutatingUserGroup(state),
  mutateError: getMutatingUserGroupError(state),

  isDynamicGroup: state.userGroups.userGroupItem.usergroup.isDynamicGroup,

  userGroupName: state.userGroups.userGroupItem.usergroup.name,

  checkStatusLoading: state.userGroups.checkStatusLoading,
  checkStatusError: state.userGroups.checkStatusError,
  allowedToEditStatus: state.userGroups.allowedToEditStatus,

  hasUserGroupAccess: hasBillingPermission(BillingPermission.ACCESS_USER_GROUPS)(state),

  isUxp: state.config.uxpEnabled
});

const mapDispatchToProps = {
  fetchUserGroup,
  fetchUserGroupStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(UserGroupItem);
