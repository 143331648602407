import { cloneDeep, mergeWith } from 'lodash-es';
import { fork, put, race, select, take, takeLatest } from 'redux-saga/effects';

import { CoursewareVersionType, LessonDiscriminatorEnum } from '@edapp/authoring-logic';
import { RequestActions } from '@edapp/request';

import type { Action } from '../types';
import * as actions from './actions';
import { getLesson } from './selectors';
import type { LessonSiblingType, LessonType, StoreState } from './types';
import { mapLessonTypeToUpdateLessonType } from './utils';

function* handleFetchMiniCourses() {
  yield put(
    RequestActions.getAuthed(
      `/api/courses`,
      actions.FETCH_MINI_COURSES_SUCCESS,
      actions.FETCH_MINI_COURSES_FAILURE,
      false,
      {
        pageSize: 2000,
        page: 1
      }
    )
  );
}

function* handleFetchLesson(action: Action<actions.FetchLesson>) {
  const { lessonId, versionType } = action.payload;

  if (!lessonId) return;

  yield put(
    RequestActions.getAuthed(
      `/api/lessons/${lessonId}`,
      actions.FETCH_LESSON_SUCCESS,
      actions.FETCH_LESSON_FAILURE,
      false,
      {
        versionType: versionType
      }
    )
  );

  const { success, failure } = yield race({
    success: take(actions.FETCH_LESSON_SUCCESS),
    failure: take(actions.FETCH_LESSON_FAILURE)
  });

  if (failure) {
    // It's being handled in the reducer
    return;
  }

  const { courseId } = (success as Action<actions.FetchLessonSuccess>).payload;
  yield put(actions.fetchLessonSiblings(courseId));
}

function* handleFetchLessonSiblings(action: Action<actions.FetchLessonSiblings>) {
  const { courseId } = action.payload;
  yield put(
    RequestActions.getAuthed<LessonSiblingType[], actions.FetchLessonSiblingsSuccess>(
      `/api/course/${courseId}/lessons`,
      data => ({
        type: actions.FETCH_LESSON_SIBLINGS_SUCCESS,
        payload: { courseId, lessons: data }
      }),
      actions.FETCH_LESSON_SIBLINGS_FAILURE,
      undefined,
      {
        // By default LMS requests should be using the latest version
        versionType: action.payload.versionType || CoursewareVersionType.Latest
      }
    )
  );
}

function* handleSetLesson(action: Action<actions.SetLesson>) {
  const { data, lessonId, skipSave } = action.payload;
  if (!!skipSave) {
    return;
  }

  let oldLessonData: LessonType = yield select((s: StoreState) => getLesson(s, lessonId));
  // fetch lesson if it doesn't exist
  if (!oldLessonData?.id) {
    yield put(actions.fetchLesson(lessonId, CoursewareVersionType.Latest));
    const { failure } = yield race({
      success: take(actions.FETCH_LESSON_SUCCESS),
      failure: take(actions.FETCH_LESSON_FAILURE)
    });
    if (failure) return;
    oldLessonData = yield select((s: StoreState) => getLesson(s, lessonId));
  }

  yield put(actions.saveLesson(lessonId, data, oldLessonData));

  // If it enables `Use Course Branding`
  // We need to retrieve the lesson to get the information from the course
  // Background & Logo
  // Ideally, we could check if the value is in the store and avoid refetching the lesson
  if (!oldLessonData.useCourseBranding && !!data.useCourseBranding) {
    yield race({
      success: take(actions.SAVE_LESSON_SUCCESS),
      failure: take(actions.SAVE_LESSON_FAILURE)
    });
    yield put(actions.fetchLesson(lessonId, CoursewareVersionType.Latest));
  }
}

function* handleSaveLesson(action: Action<actions.SaveLesson>) {
  const { lessonId } = action.payload;
  if (lessonId === 'new') {
    return;
  }

  const lessonData: LessonType = yield select((s: StoreState) => getLesson(s, lessonId));

  const data = cloneDeep(lessonData);
  mergeWith(data, action.payload.data); // this mutates data

  const updateArgs = mapLessonTypeToUpdateLessonType(data);
  yield put(
    RequestActions.putAuthed<{}, {}, any, actions.SaveLessonFailure>(
      `/api/lessons/${lessonId}`,
      actions.SAVE_LESSON_SUCCESS,
      error => ({
        type: actions.SAVE_LESSON_FAILURE,
        payload: { lessonId, error, lessonStateBeforeSave: action.payload.oldData }
      }),
      false,
      updateArgs
    )
  );
}

function* handleCopyLesson({
  payload: { lessonId, courseId, skipModule, lessonType }
}: Action<actions.CopyLessonToCourse>) {
  yield put(
    RequestActions.postAuthed(
      getCopyUriByLessonType(lessonType, lessonId),
      actions.COPY_LESSON_TO_COURSE_SUCCESS,
      actions.COPY_LESSON_TO_COURSE_FAILURE,
      false,
      { lessonId, courseId, skipModule }
    )
  );
}

function getCopyUriByLessonType(lessonType: LessonDiscriminatorEnum, lessonId: string) {
  const lessonTypeUrl: Record<LessonDiscriminatorEnum, string> = {
    [LessonDiscriminatorEnum.DISCUSSION]: `/api/discussions/copy`,
    [LessonDiscriminatorEnum.ASSESSMENT]: `/api/assessments/copy`,
    [LessonDiscriminatorEnum.OBSERVATION]: `/api/observations/${lessonId}/copy`,
    [LessonDiscriminatorEnum.VIRTUAL_CLASSROOM]: `/api/conferences/copy`,
    [LessonDiscriminatorEnum.LESSON]: `/api/lessons/copy`
  };

  return lessonTypeUrl[lessonType];
}

function* watchFetchMiniCourses() {
  yield takeLatest(actions.FETCH_MINI_COURSES, handleFetchMiniCourses);
}

function* watchFetchLesson() {
  yield takeLatest(actions.FETCH_LESSON, handleFetchLesson);
}

function* watchFetchLessonSiblings() {
  yield takeLatest(actions.FETCH_LESSON_SIBLINGS, handleFetchLessonSiblings);
}

function* watchSetLesson() {
  yield takeLatest(actions.SET_LESSON, handleSetLesson);
}

function* watchSaveLesson() {
  yield takeLatest(actions.SAVE_LESSON, handleSaveLesson);
}
function* watchCopyLesson() {
  yield takeLatest(actions.COPY_LESSON_TO_COURSE, handleCopyLesson);
}

const sagas = [
  fork(watchFetchMiniCourses),
  fork(watchFetchLesson),
  fork(watchSetLesson),
  fork(watchSaveLesson),
  fork(watchFetchLessonSiblings),
  fork(watchCopyLesson)
];

export default sagas;
