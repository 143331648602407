import { useHippoQuery } from '@edapp/request';
import type { UseHippoQueryOptions, UseHippoQueryResult } from '@edapp/request/src/types';
import { getUser, getUserInfo } from './api';
import { QueryKeys } from './constants';
import type { UserInfo, UserResponse } from './types';

export function useFetchUser(
  options?: UseHippoQueryOptions<UserResponse>
): UseHippoQueryResult<UserResponse> {
  return useHippoQuery(
    [QueryKeys.GET_USER],
    (hippoUrl, userToken) => () => getUser(hippoUrl, userToken),
    options
  );
}

export type UseGetUserInfoOptions = UseHippoQueryOptions<UserInfo>;

export type UseGetUserInfoResult = UseHippoQueryResult<UserInfo>;

export type UseGetUserInfo = (options?: UseGetUserInfoOptions) => UseGetUserInfoResult;

export const useGetUserInfo: UseGetUserInfo = options =>
  useHippoQuery(
    ['user-info'],
    (hippoUrl, userToken) => () => getUserInfo(hippoUrl, userToken),
    options
  );
