import { RequestUtils } from '@edapp/request';

import type {
  ChangeRankRequest,
  DeleteCourseCollectionRequest,
  FilterInitResponse,
  GetCourseCollectionsRequest,
  GetCourseCollectionsResponse,
  GetFilteredCourseCollectionsRequest,
  GetFilteredCourseCollectionsResponse,
  JoinedCourseCollectionsRequest
} from './types';
import { SortByEnum } from './types';

export const getCourseCollections = (
  hippoUrl: string,
  userToken: string,
  payload: JoinedCourseCollectionsRequest
) => {
  if (payload.sort?.by === SortByEnum.COLLECTIONS) {
    return RequestUtils.httpFetch<GetCourseCollectionsResponse, GetCourseCollectionsRequest>(
      'GET',
      `${hippoUrl}/api/courseCollections`,
      userToken,
      {
        includeCourseSummary: true,
        includeDraftCourseInCount: true,
        includeNotManual: false,
        orderByIsManual: false,
        page: payload.page
      }
    );
  }

  if (payload.sort || payload.filter) {
    return RequestUtils.httpFetch<
      GetFilteredCourseCollectionsResponse,
      GetFilteredCourseCollectionsRequest
    >('POST', `${hippoUrl}/api/courseCollections/search`, userToken, {
      page: payload.page,
      filter: payload.filter,
      sort: payload.sort
    });
  }

  return RequestUtils.httpFetch<GetCourseCollectionsResponse, GetCourseCollectionsRequest>(
    'GET',
    `${hippoUrl}/api/courseCollections`,
    userToken,
    payload.includes
      ? {
          ...payload.includes,
          page: payload.page
        }
      : {
          includeCourseSummary: true,
          includeDraftCourseInCount: true,
          includeNotManual: true,
          orderByIsManual: false,
          page: payload.page
        }
  );
};

export const changeRankCourseCollections = (
  hippoUrl: string,
  userToken: string,
  payload: ChangeRankRequest
): Promise<void> => {
  return RequestUtils.httpFetch(
    'PUT',
    `${hippoUrl}/api/courseCollections/rank`,
    userToken,
    payload
  );
};

export const deleteCourseCollection = (
  hippoUrl: string,
  userToken: string,
  collectionId: string,
  cascade: boolean
): Promise<void> => {
  return RequestUtils.httpFetch<void, DeleteCourseCollectionRequest>(
    'DELETE',
    `${hippoUrl}/api/courseCollections/${collectionId}`,
    userToken,
    { cascade }
  );
};

export const getCourseCollectionFilterInit = (hippoUrl: string, userToken: string) =>
  RequestUtils.httpFetch<FilterInitResponse>(
    'GET',
    `${hippoUrl}/api/courseCollections/search-filters/init`,
    userToken
  );
