import { RequestUtils } from '@edapp/request';
import type { StarBarsResponse } from './types';

export async function getStarbars(hippoUrl: string, userToken: string) {
  return await RequestUtils.httpFetch<StarBarsResponse>(
    'GET',
    `${hippoUrl}/api/lottery/starbars`,
    userToken
  );
}
