import * as React from 'react';

import { EnvironmentContext } from '@edapp/environment-context';
import type { ConfigStore } from '@rio/store/config/types';

type Props = {
  config: ConfigStore;
};

export const EnvironmentProvider: React.FC<React.PropsWithChildren<Props>> = ({ children, config }) => {
  return (
    <EnvironmentContext.Provider
      value={{
        publicCanvaApiKey: config.publicCanvaApiKey,
        publicBranchKey: config.publicBranchKey,
        publicBrazeApiKey: config.publicBrazeApiKey,
        publicKnockApiKey: config.publicKnockApiKey,
        cloudinaryConfig: config.cloudinaryConfig,

        learnersAppUrl: config.learnersAppUrl,
        featureFlagsUrl: config.featureFlagsUrl,
        fjordUrl: config.fjordUrl,
        romeoUrl: config.romeoUrl,
        safetyCultureUrl: config.safetyCultureUrl,
        supportUrl: config.supportUrl,
        youTubeUrl: config.youTubeUrl,
        websiteUrl: config.websiteUrl
      }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
};
