import * as React from 'react';
import styled, { css } from 'styled-components';
import type { Props } from './types';
import type { ColorsKeys } from '@edapp/themes';

export const ProgressBar = React.forwardRef(
  (
    { filledColor, unfilledColor, overflowColor, value, ...rest }: Props,
    ref: React.Ref<HTMLDivElement>
  ) => {
    const hasOverflow = value > 1;

    const background = hasOverflow ? overflowColor || 'red' : unfilledColor || 'lightGrey';

    const filledBarWidth = (hasOverflow ? 0.9 : value) * 100;

    return (
      <Wrapper ref={ref} {...rest}>
        {!!filledBarWidth && <FilledBar width={filledBarWidth} color={filledColor || 'blue'} />}
        <UnfilledBar color={background} />
      </Wrapper>
    );
  }
);

const Wrapper = styled.div(
  ({ theme }) => css`
    border-radius: ${theme.sizes.borderRadius}px;
    height: ${theme.space()}px;
    display: flex;
    overflow: hidden;
  `
);

const Bar = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['color', 'width'].includes(prop) && defaultValidatorFn(prop)
})<{ color: ColorsKeys }>`
  background: ${({ theme, color }) => theme.colors[color]};
  height: 100%;
`;

const FilledBar = styled(Bar)<{ width: number }>(
  ({ width }) => css`
    width: ${width}%;
    ${width < 100 && 'margin-right: 2px;'}
  `
);

const UnfilledBar = styled(Bar)`
  flex: 1 1 auto;
`;
