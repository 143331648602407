import type { InputProps } from '@edapp/ed-components';
import * as React from 'react';
import { uniqueId } from 'lodash-es';
import {
  StyledMultiPillInputWrapper,
  StyledCrossIcon,
  StyledInput,
  StyledPill,
  StyledPillTitle
} from './styled';
import type { FieldValidator } from 'final-form';

export type Pill = {
  /**
   * unique id
   */
  id: string;
  /**
   * text shown in the pull
   */
  title: string;
  /**
   * only applicable if pillValidator is passed
   */
  valid?: boolean;
};

type Props = {
  /**
   * List of pills
   */
  pills: Array<Pill>;
  /**
   * onChange with 2 args, existing pills and input text
   */
  onChange: (pills: Array<Pill>, text?: string) => void;
  /**
   * keys pressed to trigger adding a new pill, 'Enter' key is the default value
   */
  newPillTriggerKeys?: string[];
  /**
   * Input placeholder
   */
  placeholder?: string;
  /**
   * validate each pill. On error, the pill will become red.
   */
  pillValidator?: FieldValidator<string>;
  /**
   * Duplicated inputs will be invalid.
   */
  noDuplicate?: boolean;
} & Omit<InputProps, 'onChange'>;

type State = {
  newPillText: string;
};

class MultiPillInput extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      newPillText: (props.value as string) ?? ''
    };
  }

  onKeyDown = (e: React.KeyboardEvent) => {
    const { newPillTriggerKeys = [] } = this.props;
    switch (e.key) {
      case 'Backspace': {
        if (!this.state.newPillText && this.props.pills.length > 0) {
          const id = this.props.pills[this.props.pills.length - 1].id;
          this.onRemovePill(id);
        }
        break;
      }

      default:
        if (['Enter'].concat(newPillTriggerKeys).includes(e.key)) {
          e.preventDefault();
          this.onAddPill();
        }
        return;
    }
  };

  handleDuplicate = (pills: Pill[]) => {
    const { pillValidator } = this.props;
    const memo = new Set();
    return pills.map(pill => {
      if (!memo.has(pill.title)) {
        memo.add(pill.title);
        return { ...pill, valid: !pillValidator?.(pill.title) };
      } else {
        return { ...pill, valid: false };
      }
    });
  };

  onChange = (pills: Pill[], text: string) => {
    const newPills = this.props.noDuplicate ? this.handleDuplicate(pills) : pills;
    this.props.onChange([...newPills], text);
  };

  onAddPill = () => {
    const { newPillText } = this.state;
    if (!newPillText) {
      return;
    }
    const { pills, name, pillValidator } = this.props;
    const newPill: Pill = {
      id: uniqueId(`multipillinput${name ? `_${name}` : ''}_`),
      title: newPillText,
      valid: typeof pillValidator === 'function' ? !pillValidator(newPillText) : true
    };
    this.setState({ newPillText: '' }, () => {
      this.onChange([...pills, newPill], newPillText);
    });
  };

  onRemovePill = (id: string) => {
    const { newPillText } = this.state;
    const { pills } = this.props;
    this.onChange([...pills.filter(i => i.id !== id)], newPillText);
  };

  render() {
    const { newPillText } = this.state;
    const { pills, placeholder, ...rest } = this.props;

    const showPlaceholder = !newPillText && pills.length === 0;

    return (
      <StyledMultiPillInputWrapper>
        {pills.map(pill => (
          <StyledPill key={pill.id} error={pill.valid === false}>
            <StyledPillTitle variant="subtext">{pill.title}</StyledPillTitle>
            <StyledCrossIcon onClick={() => this.onRemovePill(pill.id)} size="xs" />
          </StyledPill>
        ))}

        <StyledInput
          {...rest}
          value={newPillText}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            this.setState({ newPillText: e.target.value });
            this.props.onChange(pills, e.target.value);
          }}
          onKeyDown={this.onKeyDown}
          onBlur={e => {
            this.onAddPill();
            rest?.onBlur?.(e);
          }}
          transparent={true}
          hasMargin={false}
          placeholder={showPlaceholder ? placeholder : ''}
          testId={this.props['data-testid']}
        />
      </StyledMultiPillInputWrapper>
    );
  }
}

export default MultiPillInput;
