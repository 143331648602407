import * as React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';
import { ErrorFallbackUI } from '../common/error-fallback-ui/ErrorFallbackUI';
import { CoursesLoading as Loading } from './components/Loading';
import { NoResults } from './components/NoResults';
import type { CourseDialogBodyProps } from './types';
import { ContentCardsList } from '../common/content-cards-list/ContentCardsList';
import { useTranslation } from 'react-i18next';

export const CoursePickerDialogBody: React.FC<CourseDialogBodyProps> = (
  props: CourseDialogBodyProps
) => {
  return (
    <StyledCoursePickerBody>
      <BodyContents {...props} />
    </StyledCoursePickerBody>
  );
};

const BodyContents: React.FC<CourseDialogBodyProps> = ({
  error,
  loading,
  items,
  onClose,
  paginated,
  orderedSelect,
  selectedIds,
  onSelect
}) => {
  const { t } = useTranslation();

  if (error) {
    return <ErrorFallbackUI text={error} actionText={t('common.close')} action={onClose} />;
  }

  if (loading && !paginated) {
    return <Loading />;
  }

  if (!items.length && !loading) {
    return <NoResults />;
  }

  if (paginated) {
    return (
      <InfiniteScroll
        initialLoad={false}
        loadMore={paginated.onLoadMore}
        hasMore={paginated.hasMore}
        pageStart={1}
        useWindow={false}
        threshold={150}
      >
        <ContentCardsList
          courses={items as any[]}
          onSelect={onSelect}
          selectedCourseIds={selectedIds}
          orderedSelect={!!orderedSelect}
          selectText={t('common.select')}
          selectedText={t('common.selected')}
          byText={t('common.by')}
        />
        {loading && <Loading />}
      </InfiniteScroll>
    );
  }

  return (
    <ContentCardsList
      courses={items as any[]}
      onSelect={onSelect}
      selectedCourseIds={selectedIds}
      orderedSelect={!!orderedSelect}
      selectText={t('common.select')}
      selectedText={t('common.selected')}
      byText={t('common.by')}
    />
  );
};

const StyledCoursePickerBody = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGrey};
  width: 100%;
  height: 80%;
  overflow: auto;
`;
