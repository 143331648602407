import * as React from 'react';
import type { Props } from './types';

export const NoAccessSvg: React.FC<Props> = ({ width = 130, height = 130 }) => (
  <svg width={width} height={height} viewBox="0 0 130 130" fill="none">
    <circle cx="65" cy="65" r="65" fill="#DCF6FF" />
    <path
      d="M66.1628 23.7494L66 23.6933L65.8372 23.7494L31.8372 35.4605L31.5 35.5767V35.9333V67.0483C31.5 79.1665 37.5563 90.4829 47.6393 97.2048L65.7226 109.26L66 109.445L66.2774 109.26L84.3607 97.2048C94.4437 90.4829 100.5 79.1665 100.5 67.0483V35.9333V35.5767L100.163 35.4605L66.1628 23.7494Z"
      fill="#ADEFFF"
      stroke="#00BAFF"
    />
    <path
      d="M84.0834 96.7888L66 108.844V24.2222L100 35.9333V67.0483C100 78.9993 94.0272 90.1596 84.0834 96.7888Z"
      fill="#86E8FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66 38C71.5228 38 76 42.5146 76 48.0836L76 56L72 56L72 47.7493C72 44.5741 69.1489 42 66 42C62.8511 42 60 44.9083 60 48.0836L60 56L56 56L56 48.0836C56 42.5146 60.4772 38 66 38Z"
      fill="#766CFF"
    />
    <rect x="72" y="51" width="4" height="3" fill="#5F53FF" />
    <rect x="56" y="51" width="4" height="3" fill="#5F53FF" />
    <path
      d="M80.804 53.3892C82.0764 53.6221 83 54.7246 83 56.0103V79.5056C83 80.5174 82.2731 81.385 81.2718 81.5684C70.845 83.4772 60.155 83.4772 49.7282 81.5684C48.7269 81.385 48 80.5174 48 79.5056L48 56.0103C48 54.7246 48.9236 53.6221 50.196 53.3892C60.3135 51.5369 70.6865 51.5369 80.804 53.3892Z"
      fill="#00BAFF"
    />
    <path
      d="M68.25 64.4494C68.25 65.8021 67.0747 66.8987 65.625 66.8987C64.1753 66.8987 63 65.8021 63 64.4494C63 63.0966 64.1753 62 65.625 62C67.0747 62 68.25 63.0966 68.25 64.4494Z"
      fill="#0E1829"
    />
    <path
      d="M64.4101 65.2165C64.4374 64.9362 64.6893 64.7215 64.991 64.7215H66.259C66.5607 64.7215 66.8126 64.9362 66.8399 65.2165L67.3594 70.5484C67.3685 70.6424 67.3071 70.73 67.2111 70.7598C66.1816 71.0801 65.0684 71.0801 64.0389 70.7598C63.9429 70.73 63.8815 70.6424 63.8906 70.5484L64.4101 65.2165Z"
      fill="#0E1829"
    />
  </svg>
);
