import * as React from 'react';
import type { AnimationLifecycles } from 'framer-motion';
import type { Props, ContainerVariants } from './types';
import { Container } from './styled';

export const ResizeContainer: React.FC<Props> = ({
  overflow = 'auto',
  children,
  initialHeight = 0,
  ...props
}) => {
  // For hiding overflow and disabling buttons while animations are in progress
  const [isAnimating, setIsAnimating] = React.useState<boolean>(false);
  // Every time the wizard content (children) changes, we recalculate its dimensions
  // in order to resize the height
  const [contentHeight, setContentHeight] = React.useState(initialHeight);

  const handleAnimationStart = () => {
    setIsAnimating(true);
  };

  const handleAnimationComplete: AnimationLifecycles['onAnimationComplete'] = (definition) => {
    if (!!(definition as ContainerVariants)?.height) {
      setIsAnimating(false);
    }
  };

  const refCallback = React.useCallback<React.RefCallback<HTMLElement>>(contentElement => {
    if (!contentElement) {
      return;
    }

    setContentHeight(contentElement.getBoundingClientRect().height);

    const observer = new MutationObserver(() => {
      setContentHeight(contentElement.getBoundingClientRect().height);
    });

    observer.observe(contentElement, { attributes: true, childList: true, subtree: true });

    const handleResize = () => {
      setContentHeight(contentElement.getBoundingClientRect().height);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      observer.disconnect();
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const containerVariants: ContainerVariants = {
    height: contentHeight
  };

  return (
    <Container
      {...props}
      initial={false}
      animate={containerVariants}
      transition={{ clamp: true }}
      overflow={overflow}
      isAnimating={isAnimating}
      onAnimationStart={handleAnimationStart}
      onAnimationComplete={handleAnimationComplete}
    >
      {children(refCallback)}
    </Container>
  );
};
