import * as React from 'react';
import styled, { css } from 'styled-components';
import { LeftArrowIcon, RightArrowIcon } from '../../../icons';
import { Loading } from '../../../loading/Loading';

type Props = {
  isVertical: boolean;
};

export const SelectableListArrows: React.FC<Props> = ({ isVertical }) =>
  isVertical ? <VerticalSelectableListArrows /> : <HorizontalSelectableListArrows />;

export const SelectableListLoading: React.FC<Props> = ({ isVertical }) => {
  const Container = isVertical ? VerticalContainer : HorizontalContainer;
  return (
    <Container>
      <Loading />
    </Container>
  );
};

const HorizontalSelectableListArrows = () => (
  <HorizontalContainer>
    <StyledLeftArrowIcon />
    <RightArrowIcon />
  </HorizontalContainer>
);

const HorizontalContainer = styled.div`
  margin-top: 36px;
  display: flex;
  align-self: center;
  flex-basis: 32px;
  align-items: center;
  justify-content: center;
  flex-flow: column nowrap;
  user-select: none;
`;

const StyledLeftArrowIcon = styled(LeftArrowIcon)`
  margin-bottom: ${({ theme }) => theme.space()}px;
`;

const VerticalSelectableListArrows = () => (
  <VerticalContainer>
    <RotatedLeftArrowIcon />
    <RotatedRightArrowIcon />
  </VerticalContainer>
);

const VerticalContainer = styled(HorizontalContainer)`
  margin-top: ${({ theme }) => theme.space(2)}px;
  flex-flow: row nowrap;
  flex-basis: 16px;
  width: 100%;
`;

const rotatedIconStyle = css`
  transform: rotate(90deg);
`;

const RotatedLeftArrowIcon = styled(LeftArrowIcon)`
  ${rotatedIconStyle}
`;

const RotatedRightArrowIcon = styled(RightArrowIcon)`
  ${rotatedIconStyle}
`;
