import * as React from 'react';
import styled from 'styled-components';
import { Section } from './Section';
import type { ContentLibrarySectionType } from '@edapp/content-library';
import { SwipeableViews, SwipeableViewSlide } from '@edapp/ed-components';
import { SECTIONED_COURSES_VIEW, COURSE_LIST_VIEW } from '../constants';

type Props = {
  viewIndex: number;
  onChangeView: (index: number) => void;
  items: ContentLibrarySectionType[];
  onSelect: (id: string) => void;
  selectedCourseIds: string[];
  orderedSelect?: boolean;
  loading: boolean;
  onViewBack: () => void;
  onViewAll: (sectionId: string) => void;
  selectedSection: ContentLibrarySectionType[];
};

export const testId = 'course-sections';

export const Sections: React.FC<Props> = ({
  items,
  onChangeView,
  onSelect,
  orderedSelect,
  selectedCourseIds,
  viewIndex,
  onViewAll,
  onViewBack,
  selectedSection
}) => {
  const swipeableViewsRef = React.useRef<HTMLDivElement>(null);

  const scrollContentSectionToTop = (swipeableViews: HTMLDivElement | null) => {
    if (!!swipeableViews && !!swipeableViews.parentNode) {
      swipeableViews.parentNode['scrollTop'] =
        swipeableViews.offsetTop - swipeableViews.parentNode['offsetTop'];
    }
  };

  React.useEffect(() => {
    if (viewIndex === COURSE_LIST_VIEW && !!swipeableViewsRef) {
      scrollContentSectionToTop(swipeableViewsRef.current);
    }
  }, [viewIndex]);

  return (
    <StyledSwipeableViews
      viewIndex={viewIndex}
      onChangeViewIndex={onChangeView}
      data-testid={testId}
      wrapperRef={swipeableViewsRef}
    >
      <Section
        key={'course-sections-view'}
        items={viewIndex === SECTIONED_COURSES_VIEW ? items : []}
        onSelect={onSelect}
        selectedCourseIds={selectedCourseIds}
        orderedSelect={!!orderedSelect}
        onChangeView={onChangeView}
        viewIndex={viewIndex}
        onViewAll={onViewAll}
        onViewBack={onViewBack}
      />
      <Section
        key={'course-list-view'}
        items={viewIndex === SECTIONED_COURSES_VIEW ? items : selectedSection}
        onSelect={onSelect}
        selectedCourseIds={selectedCourseIds}
        orderedSelect={!!orderedSelect}
        viewIndex={viewIndex}
        onChangeView={onChangeView}
        onViewAll={onViewAll}
        onViewBack={onViewBack}
      />
    </StyledSwipeableViews>
  );
};

const StyledSwipeableViews = styled(SwipeableViews)`
  & {
    ${SwipeableViewSlide} {
      display: inline-flex;
      vertical-align: top;
    }
  }
`;
