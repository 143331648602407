import React from 'react';

import { DialogContent } from '@edapp/ed-components';
import { Flags, useFeatureFlags } from '@edapp/feature-flags';
import { ApplicationInfoSelectors, useUserInfo } from '@edapp/user-info';

import { AuthenticationModalContent } from './content/AuthenticationModalContent';
import { useApiUnauthorized } from './useApiUnauthorized';

export const AuthenticationModal: React.FC = () => {
  const { isUnauthorized } = useApiUnauthorized();
  const { applicationInfo } = useUserInfo();
  const isUxp = ApplicationInfoSelectors.isUXP(applicationInfo);

  const [enableAuthenticationModal] = useFeatureFlags([Flags.enableAuthenticationModal]);

  if (!enableAuthenticationModal) return null;

  // SC has its own authentication
  if (isUxp || !isUnauthorized) return null;

  return (
    <DialogContent
      isOpen={true}
      size="xs"
      isClosable={false}
      blurBackdrop
      withOverlay
      bodyContents={
        <AuthenticationModalContent
          onSuccess={() => {
            window.location.reload();
          }}
          isLoggedIn={false}
        />
      }
    />
  );
};
