import { t } from 'i18next';

import { LessonDiscriminatorEnum } from '@edapp/authoring-logic';
import {
  ChartIcon,
  ChatIcon,
  DocumentCheckIcon,
  DocumentIcon,
  PencilIcon,
  VideoCameraIcon
} from '@edapp/ed-components';
import type { IconComponent } from '@edapp/ed-components';

export function getLessonTypeText(type: LessonDiscriminatorEnum, getShortName?: boolean) {
  switch (type) {
    case LessonDiscriminatorEnum.ASSESSMENT:
      return getShortName ? t('all-in-one-builder.new-assessment.title') : t('common.assignment');
    case LessonDiscriminatorEnum.DISCUSSION:
      return getShortName ? t('all-in-one-builder.new-discussion.title') : t('common.discussion');
    case LessonDiscriminatorEnum.VIRTUAL_CLASSROOM:
      return getShortName
        ? t('all-in-one-builder.new-virtual-classroom.title')
        : t('common.virtual-classroom');
    case LessonDiscriminatorEnum.OBSERVATION:
      return getShortName
        ? t('all-in-one-builder.new-observation.title')
        : t('common.practical-assessment');
    case LessonDiscriminatorEnum.LESSON:
    default:
      return getShortName ? t('all-in-one-builder.new-lesson.title') : t('common.lesson');
  }
}

export function getLessonTypeIcon(type?: LessonDiscriminatorEnum): IconComponent {
  switch (type) {
    case LessonDiscriminatorEnum.LESSON:
      return ChartIcon;
    case LessonDiscriminatorEnum.DISCUSSION:
      return ChatIcon;
    case LessonDiscriminatorEnum.ASSESSMENT:
      return PencilIcon;
    case LessonDiscriminatorEnum.OBSERVATION:
      return DocumentCheckIcon;
    case LessonDiscriminatorEnum.VIRTUAL_CLASSROOM:
      return VideoCameraIcon;
    default:
      return DocumentIcon;
  }
}
