import { delay } from 'redux-saga';
import { fork, put, race, select, take, takeLatest } from 'redux-saga/effects';

import type { DiscussionType } from '@edapp/hippo-client';
import { RequestActions } from '@edapp/request';

import type { Action, EdErrorResponseType } from '../types';
import * as actions from './actions';
import { getDiscussion } from './selectors';
import type { DiscussionPost, StoreState } from './types';
import { isNewDiscussion } from './utils';

function* handleFetchDiscussionSummaries(action: Action<actions.FetchDiscussionSummariesPayload>) {
  const {
    //searchTerm,
    courseId,
    page,
    pageSize
  } = action.payload;

  yield put(
    RequestActions.getAuthed(
      `/api/discussions/summaries`,
      data => ({
        type: actions.FETCH_DISCUSSION_SUMMARIES_SUCCESS,
        payload: {
          ...data,
          currentPage: page
        }
      }),
      actions.FETCH_DISCUSSION_SUMMARIES_FAILURE,
      false,
      {
        // searchTerm,
        courseId,
        page,
        pageSize
      }
    )
  );
}

function* handleFetchDiscussion({ payload: { discussionId } }: Action<actions.FetchDiscussion>) {
  yield put(
    RequestActions.getAuthed(
      `/api/discussions/${discussionId}/latest`,
      actions.FETCH_DISCUSSION_SUCCESS,
      actions.FETCH_DISCUSSION_FAILURE,
      undefined
    )
  );
}

function* handleSetDiscussion(action: Action<actions.SetDiscussion>) {
  const { data, discussionId, skipSave } = action.payload;
  if (!!skipSave) {
    return;
  }

  const oldDiscussionData: DiscussionType = yield select((s: StoreState) =>
    getDiscussion(s, discussionId)
  );
  yield put(actions.saveDiscussion(discussionId, data, oldDiscussionData));
}

function* handleSaveDiscussion(action: Action<actions.SaveDiscussion>) {
  yield delay(2000);

  const { discussionId } = action.payload;
  if (isNewDiscussion(discussionId)) {
    return;
  }

  const discussionData: DiscussionType = yield select((s: StoreState) =>
    getDiscussion(s, discussionId)
  );

  yield put(
    RequestActions.patchAuthed<{}, {}, EdErrorResponseType, actions.SaveDiscussionFailure>(
      `/api/discussions/${discussionId}`,
      actions.SAVE_DISCUSSION_SUCCESS,
      error => ({
        type: actions.SAVE_DISCUSSION_FAILURE,
        payload: { discussionId, error, discussionStateBeforeSave: action.payload.oldData }
      }),
      false,
      discussionData
    )
  );
}

function* handleFetchPosts(action: Action<actions.FetchPosts>) {
  const { discussionId, page, pageSize } = action.payload;

  yield put(
    RequestActions.getAuthed(
      `/api/discussions/${discussionId}/posts`,
      data => ({
        type: actions.FETCH_POSTS_SUCCESS,
        payload: { ...data, discussionId, page }
      }),
      actions.FETCH_POSTS_FAILURE,
      false,
      { page, pageSize, commentsPage: 1, commentsPageSize: 2 }
    )
  );

  const {
    success
  }: { success: Action<{ items: DiscussionPost[]; totalCount: number }> } = yield race({
    success: take(actions.FETCH_POSTS_SUCCESS),
    failure: take(actions.FETCH_POSTS_FAILURE)
  });

  if (success) {
    const postIds = success.payload.items.map(i => i.id);
    yield put(actions.fetchInitialComments(postIds, discussionId));
  }
}

function* handleFetchComments(action: Action<actions.FetchComments>) {
  const { discussionId, postId, page, pageSize } = action.payload;
  yield put(
    RequestActions.getAuthed(
      `/api/discussions/${discussionId}/posts/${postId}/comments`,
      data => ({ type: actions.FETCH_COMMENTS_SUCCESS, payload: { ...data, postId, page } }),
      actions.FETCH_COMMENTS_FAILURE,
      false,
      { page, pageSize }
    )
  );
}

function* handleFetchInitialComments(action: Action<actions.FetchInitialComments>) {
  const { postIds, page, pageSize, discussionId } = action.payload;
  yield put(
    RequestActions.getAuthed(
      `/api/discussions/${discussionId}/posts/comments`,
      actions.FETCH_INITIAL_COMMENTS_SUCCESS,
      actions.FETCH_INITIAL_COMMENTS_FAILURE,
      false,
      { page, pageSize, postIds }
    )
  );
}

function* handleAddComment(action: Action<actions.AddComment>) {
  const { text, postId, discussionId } = action.payload;

  yield put(
    RequestActions.postAuthed(
      `/api/discussions/${discussionId}/posts/${postId}/comments`,
      data => ({
        type: actions.ADD_COMMENT_SUCCESS,
        payload: { comment: data, postId }
      }),
      actions.ADD_COMMENT_FAILURE,
      false,
      { content: text }
    )
  );
}

function* handleEditComment(action: Action<actions.EditComment>) {
  const { text, postId, commentId } = action.payload;

  yield put(
    RequestActions.patchAuthed(
      `/api/comments/${commentId}`,
      data => ({
        type: actions.EDIT_COMMENT_SUCCESS,
        payload: { comment: data, postId }
      }),
      actions.EDIT_COMMENT_FAILURE,
      false,
      { content: text }
    )
  );
}

function* handleDeleteComment(action: Action<actions.DeleteComment>) {
  const { postId, commentId } = action.payload;

  yield put(
    RequestActions.deleteAuthed(
      `/api/comments/${commentId}`,
      () => ({
        type: actions.DELETE_COMMENT_SUCCESS,
        payload: { commentId, postId }
      }),
      actions.DELETE_COMMENT_FAILURE,
      false
    )
  );
}

function* handleDeletePost(action: Action<actions.DeletePost>) {
  const { postId, discussionId } = action.payload;

  yield put(
    RequestActions.deleteAuthed(
      `/api/discussions/${discussionId}/posts/${postId}`,
      () => ({
        type: actions.DELETE_POST_SUCCESS,
        payload: { postId, discussionId }
      }),
      actions.DELETE_POST_FAILURE,
      false
    )
  );
}

function* watchFetchDiscussionSummaries() {
  yield takeLatest(actions.FETCH_DISCUSSION_SUMMARIES, handleFetchDiscussionSummaries);
}

function* watchFetchDiscussion() {
  yield takeLatest(actions.FETCH_DISCUSSION, handleFetchDiscussion);
}

function* watchSetDiscussion() {
  yield takeLatest(actions.SET_DISCUSSION, handleSetDiscussion);
}

function* watchSaveDiscussion() {
  yield takeLatest(actions.SAVE_DISCUSSION, handleSaveDiscussion);
}

function* watchFetchPosts() {
  yield takeLatest(actions.FETCH_POSTS, handleFetchPosts);
}

function* watchFetchComments() {
  yield takeLatest(actions.FETCH_COMMENTS, handleFetchComments);
}

function* watchFetchInitialComments() {
  yield takeLatest(actions.FETCH_INITIAL_COMMENTS, handleFetchInitialComments);
}

function* watchAddComment() {
  yield takeLatest(actions.ADD_COMMENT, handleAddComment);
}

function* watchEditComment() {
  yield takeLatest(actions.EDIT_COMMENT, handleEditComment);
}

function* watchDeleteComment() {
  yield takeLatest(actions.DELETE_COMMENT, handleDeleteComment);
}

function* watchDeletePost() {
  yield takeLatest(actions.DELETE_POST, handleDeletePost);
}

const sagas = [
  fork(watchFetchDiscussionSummaries),
  fork(watchFetchDiscussion),
  fork(watchSetDiscussion),
  fork(watchSaveDiscussion),
  fork(watchFetchPosts),
  fork(watchFetchComments),
  fork(watchFetchInitialComments),
  fork(watchAddComment),
  fork(watchEditComment),
  fork(watchDeleteComment),
  fork(watchDeletePost)
];

export default sagas;
