import type { ColorsKeys } from '@edapp/themes';
import * as React from 'react';
import styled, { css, useTheme } from 'styled-components';
import { Box } from '../box/Box';
import { CircleCrossIcon, CircleInfoIcon, CircleTickIcon, CrossIcon, WarningIcon } from '../icons';

import type { IconComponent } from '../icons/base/BaseIcon';
import { Typography } from '../typography/Typography';
import type { Props, Variant } from './types';

type VariantData = {
  icon: IconComponent;
  borderColor: ColorsKeys;
  backgroundColor: ColorsKeys;
};

export const getVariantData = (variant?: Variant): VariantData => {
  switch (variant) {
    case 'error':
      return { icon: CircleCrossIcon, borderColor: 'red', backgroundColor: 'lightRed' };
    case 'warning':
      return { icon: WarningIcon, borderColor: 'orange', backgroundColor: 'lightOrange' };
    case 'success':
      return { icon: CircleTickIcon, borderColor: 'green', backgroundColor: 'lightGreen' };
    default:
      return { icon: CircleInfoIcon, borderColor: 'blue', backgroundColor: 'lightBlue' };
  }
};

export const Alert: React.FC<Props> = ({
  alignItems = 'center',
  justifyContent = 'center',
  flexWrap,
  isClosable = false,
  showIcon,
  variant,
  onClose,
  testId,
  children,
  ...rest
}) => {
  const theme = useTheme();

  const [openState, setOpenState] = React.useState(true);

  if (!openState) {
    return null;
  }

  const variantData = getVariantData(variant);

  const handleClose = (event: React.MouseEvent) => {
    setOpenState(false);
    onClose?.();
    event.stopPropagation();
  };

  return (
    <AlertContainer
      data-testid="alert-container"
      borderColor={variantData.borderColor}
      backgroundColor={variantData.backgroundColor}
      showIcon={showIcon}
      {...rest}
    >
      <Box
        flex={true}
        flexDirection="row"
        flexWrap={flexWrap}
        alignItems={alignItems}
        justifyContent={justifyContent}
        testId={testId}
      >
        {!!showIcon && (
          <variantData.icon
            size="md"
            color={variantData.borderColor}
            style={{
              marginTop: `${theme.space(0.25)}px`,
              marginRight: `${theme.space(1.5)}px`,
              flexShrink: 0
            }}
          />
        )}
        <Typography>{children}</Typography>
      </Box>

      {isClosable && <StyledCrossIcon color="textMuted" size="sm" onClick={handleClose} />}
    </AlertContainer>
  );
};

type AlertContainerProps = {
  backgroundColor: ColorsKeys;
  borderColor: ColorsKeys;
  showIcon?: boolean;
};

const AlertContainer = styled.div<AlertContainerProps>(
  ({ theme, borderColor, backgroundColor, showIcon, onClick }) => css`
    background-color: ${theme.colors[backgroundColor]};
    border: 1px solid ${theme.colors[borderColor]};
    padding: ${theme.space(2)}px ${theme.space(6)}px ${theme.space(2)}px
      ${showIcon ? theme.space(2) : theme.space(6)}px;
    border-radius: ${theme.sizes.borderRadius * 2}px;
    position: relative;
    width: 100%;
    z-index: 1;
    ${!!onClick && 'cursor: pointer;'};
    @media screen and (min-width: ${theme.flexboxgrid.breakpoints.sm}rem) {
      padding-left: ${showIcon ? theme.space(3) : theme.space(8)}px;
      padding-right: ${theme.space(8)}px;
    }
  `
);

const StyledCrossIcon = styled(CrossIcon)(
  ({ theme }) => css`
    top: ${theme.space(2.5)}px;
    right: ${theme.space(3)}px;
    position: absolute;
    cursor: pointer;
    align-self: center;
  `
);
