import type { Reducer } from 'redux';

import { CoursewareStatus } from '@edapp/ed-components';
import { createMutated, mergeMutated, spreadMutated } from '@edapp/utils';

import { ActionType as PublicDetailsActions } from '../courseware/courses/public-details/actions';
import { ActionType as PublicDetailCourseLinksActions } from '../courseware/courses/public-details/course-links/actions';
import PublicDetailExcludedLessonsActions from '../courseware/courses/public-details/excluded-lessons/actions';
import { ActionType as PublicDetailExcludedSlidesActions } from '../courseware/courses/public-details/excluded-slides/actions';
import { ActionType as PublicDetailRatingLinksActions } from '../courseware/courses/public-details/rating-links/actions';
import { ActionType as TopicsActions } from '../courseware/courses/topics/actions';
import * as lessonActions from '../lesson/actions';
import type { EdErrorResponseType } from '../types';
import * as actions from './actions';
import type { CourseState } from './types';
import { findCourseIdByLessonId } from './utils';

export const initialCourseState: CourseState = {
  lessonDeleteError: '',
  lessonDeleteLoading: false,
  courseMutateLoading: false,
  courseMutateError: '',
  courseFetchLoading: false,
  courseFetchError: undefined,
  courses: {}
};

export const courseReducer: Reducer<CourseState> = (state = initialCourseState, action) => {
  switch (action.type) {
    case actions.FETCH_COURSE: {
      return { ...state, courseFetchLoading: true, courseFetchError: undefined };
    }

    case actions.FETCH_COURSE_SUCCESS: {
      const course = action.payload as actions.FetchCourseSuccess;
      return {
        ...state,
        courseFetchLoading: false,
        courseFetchError: undefined,
        courses: {
          ...state.courses,
          [course.id]: {
            ...createMutated(course)
          }
        }
      };
    }
    case actions.FETCH_COURSE_FAILURE: {
      const error = action.payload as actions.FetchCourseFailure;
      return { ...state, courseFetchLoading: false, courseFetchError: error };
    }

    case actions.SAVE_COURSE_LEADERBOARD:
    case actions.SAVE_COURSE_USER_GROUPS:
    case actions.SAVE_COURSE_PREREQUISITES:
    case actions.SAVE_COURSE: {
      const { courseId, data } = action.payload;

      return {
        ...state,
        courseMutateLoading: true,
        courseMutateError: '',
        courses: {
          ...state.courses,
          [courseId]: {
            ...mergeMutated(state.courses[courseId], data)
          }
        }
      };
    }

    case actions.SET_COURSE_SKIP_SAVE: {
      const { courseId, data } = action.payload;
      return {
        ...state,
        courseMutateError: '',
        courses: {
          ...state.courses,
          [courseId]: {
            ...mergeMutated(state.courses[courseId], data)
          }
        }
      };
    }

    case actions.SAVE_COURSE_SUCCESS: {
      const { id: courseId, microCredential } = action.payload as actions.SaveCourseSuccess;

      return {
        ...state,
        courseMutateLoading: false,
        courseMutateError: '',
        courses: {
          ...state.courses,
          [courseId]: {
            ...mergeMutated(state.courses[courseId], { microCredential })
          }
        }
      };
    }

    case actions.SAVE_COURSE_LEADERBOARD_SUCCESS: {
      const { leaderboard, courseId } = action.payload as actions.SaveCourseLeaderboardSuccess;

      return {
        ...state,
        courseMutateLoading: false,
        courseMutateError: '',
        courses: {
          ...state.courses,
          [courseId]: {
            ...mergeMutated(state.courses[courseId], { leaderboard })
          }
        }
      };
    }
    case actions.SAVE_COURSE_USER_GROUPS_FAILURE:
    case actions.SAVE_COURSE_PREREQUISITES_FAILURE:
    case actions.SAVE_COURSE_FAILURE: {
      const {
        courseId,
        courseStateBeforeSave,
        error
      } = action.payload as actions.SaveCourseFailure;
      return {
        ...state,
        courseMutateLoading: false,
        courseMutateError: error.message,
        courses: {
          ...state.courses,
          [courseId]: {
            ...spreadMutated(state.courses[courseId], courseStateBeforeSave)
          }
        }
      };
    }

    case actions.CREATE_COURSE:
    case PublicDetailCourseLinksActions.CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINK:
    case PublicDetailCourseLinksActions.DELETE_COURSE_PUBLIC_DETAIL_COURSE_LINK:
    case PublicDetailExcludedLessonsActions.CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON:
    case PublicDetailExcludedLessonsActions.DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON:
    case PublicDetailExcludedSlidesActions.CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE:
    case PublicDetailExcludedSlidesActions.DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE:
    case PublicDetailRatingLinksActions.CREATE_COURSE_PUBLIC_DETAIL_RATING_LINK:
    case PublicDetailRatingLinksActions.DELETE_COURSE_PUBLIC_DETAIL_RATING_LINK:
    case PublicDetailsActions.CREATE_COURSE_PUBLIC_DETAIL:
    case PublicDetailsActions.UPDATE_COURSE_PUBLIC_DETAIL:
    case TopicsActions.CREATE_COURSE_TOPIC:
    case TopicsActions.UPDATE_COURSE_TOPIC:
    case TopicsActions.DELETE_COURSE_TOPIC: {
      return {
        ...state,
        courseMutateLoading: true,
        courseMutateError: ''
      };
    }

    case actions.CREATE_COURSE_SUCCESS:
    case actions.SAVE_COURSE_USER_GROUPS_SUCCESS:
    case actions.SAVE_COURSE_PREREQUISITES_SUCCESS:
    case PublicDetailCourseLinksActions.CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINK_SUCCESS:
    case PublicDetailCourseLinksActions.DELETE_COURSE_PUBLIC_DETAIL_COURSE_LINK_SUCCESS:
    case PublicDetailExcludedLessonsActions.CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_SUCCESS:
    case PublicDetailExcludedLessonsActions.DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_SUCCESS:
    case PublicDetailExcludedSlidesActions.CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_SUCCESS:
    case PublicDetailExcludedSlidesActions.DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_SUCCESS:
    case PublicDetailRatingLinksActions.CREATE_COURSE_PUBLIC_DETAIL_RATING_LINK_SUCCESS:
    case PublicDetailRatingLinksActions.DELETE_COURSE_PUBLIC_DETAIL_RATING_LINK_SUCCESS:
    case PublicDetailsActions.CREATE_COURSE_PUBLIC_DETAIL_SUCCESS:
    case PublicDetailsActions.UPDATE_COURSE_PUBLIC_DETAIL_SUCCESS:
    case TopicsActions.CREATE_COURSE_TOPIC_SUCCESS:
    case TopicsActions.UPDATE_COURSE_TOPIC_SUCCESS:
    case TopicsActions.DELETE_COURSE_TOPIC_SUCCESS: {
      return {
        ...state,
        courseMutateLoading: false,
        courseMutateError: ''
      };
    }

    case actions.CREATE_COURSE_FAILURE:
    case PublicDetailCourseLinksActions.CREATE_COURSE_PUBLIC_DETAIL_COURSE_LINK_FAILURE:
    case PublicDetailCourseLinksActions.DELETE_COURSE_PUBLIC_DETAIL_COURSE_LINK_FAILURE:
    case PublicDetailExcludedLessonsActions.CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_FAILURE:
    case PublicDetailExcludedLessonsActions.DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_LESSON_FAILURE:
    case PublicDetailExcludedSlidesActions.CREATE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_FAILURE:
    case PublicDetailExcludedSlidesActions.DELETE_COURSE_PUBLIC_DETAIL_EXCLUDED_SLIDE_FAILURE:
    case PublicDetailRatingLinksActions.CREATE_COURSE_PUBLIC_DETAIL_RATING_LINK_FAILURE:
    case PublicDetailRatingLinksActions.DELETE_COURSE_PUBLIC_DETAIL_RATING_LINK_FAILURE:
    case PublicDetailsActions.CREATE_COURSE_PUBLIC_DETAIL_FAILURE:
    case PublicDetailsActions.UPDATE_COURSE_PUBLIC_DETAIL_FAILURE:
    case TopicsActions.CREATE_COURSE_TOPIC_FAILURE:
    case TopicsActions.UPDATE_COURSE_TOPIC_FAILURE:
    case TopicsActions.DELETE_COURSE_TOPIC_FAILURE: {
      const { message } = action.payload as EdErrorResponseType;

      return {
        ...state,
        courseMutateLoading: false,
        courseMutateError: message
      };
    }

    // Bulk Publish Lessons
    case lessonActions.SET_BULK_PUBLISH_LESSON: {
      const { lessonIds } = action.payload as lessonActions.BulkPublishLesson;

      if (lessonIds.length === 0) {
        return state;
      }

      const courseId = findCourseIdByLessonId(lessonIds[0], state.courses);
      if (!courseId) {
        return state;
      }

      const course = state.courses[courseId];

      const newLessons = course.original.lessons.map(l => {
        // check if the lessonId is present in the published list
        if (lessonIds.includes(l.id)) {
          return { ...l, isPublished: true };
        }
        return l;
      });

      return {
        ...state,
        courses: {
          ...state.courses,
          [courseId]: {
            ...spreadMutated(course, { lessons: [...newLessons] })
          }
        }
      };
    }

    // Schedule Course
    case actions.SET_SCHEDULE_COURSE: {
      const { courseId, publishOn } = action.payload as actions.ScheduleCourse;

      const course = state.courses[courseId];

      if (!course) {
        return state;
      }

      return {
        ...state,
        courses: {
          ...state.courses,
          [courseId]: {
            ...spreadMutated(course, {
              display: { active: true },
              courseStatus: CoursewareStatus.SCHEDULED,
              publishOn
            })
          }
        }
      };
    }

    // Publish Course
    case actions.SET_PUBLISH_COURSE: {
      const { courseId, tags } = action.payload as actions.PublishCourse;

      const course = state.courses[courseId];

      if (!course) {
        return state;
      }

      return {
        ...state,
        courses: {
          ...state.courses,
          [courseId]: {
            ...spreadMutated(course, {
              display: { active: true },
              courseStatus: CoursewareStatus.PUBLISHED,
              tags
            })
          }
        }
      };
    }

    // Unpublish Course
    case actions.SET_UNPUBLISH_COURSE: {
      const { courseId } = action.payload as actions.PublishCourse;
      const course = state.courses[courseId];

      if (!course) {
        return state;
      }

      return {
        ...state,
        courseMutateLoading: true,
        courseMutateError: '',
        courses: {
          ...state.courses,
          [courseId]: {
            ...spreadMutated(course, {
              display: { active: false },
              courseStatus: CoursewareStatus.DRAFT,
              publishOn: undefined
            })
          }
        }
      };
    }
    case actions.SET_UNPUBLISH_COURSE_SUCCESS: {
      return { ...state, courseMutateLoading: false, courseMutateError: '' };
    }
    case actions.SET_UNPUBLISH_COURSE_FAILURE: {
      const { courseId, prevStatus, error } = action.payload as actions.SetUnpublishCourseFailure;
      const course = state.courses[courseId];

      if (!course) {
        return state;
      }

      return {
        ...state,
        courseMutateLoading: false,
        courseMutateError: error.message,
        courses: {
          ...state.courses,
          [courseId]: {
            ...spreadMutated(course, {
              display: { active: true },
              courseStatus: prevStatus
            })
          }
        }
      };
    }

    case actions.DELETE_LESSON: {
      return { ...state, lessonDeleteLoading: true, lessonDeleteError: '' };
    }
    case actions.DELETE_LESSON_SUCCESS: {
      const { courseId, lessonId } = action.payload as actions.DeleteLessonSuccess;
      const newLessons = state.courses[courseId].original.lessons.filter(
        lesson => lesson.id !== lessonId
      );
      return {
        ...state,
        lessonDeleteLoading: false,
        lessonDeleteError: '',
        courses: {
          ...state.courses,
          [courseId]: {
            ...spreadMutated(state.courses[courseId], { lessons: newLessons })
          }
        }
      };
    }
    case actions.DELETE_LESSON_FAILURE: {
      const { message } = action.payload as actions.DeleteLessonFailure;
      return {
        ...state,
        lessonDeleteLoading: false,
        lessonDeleteError: message
      };
    }

    case actions.CLEAR_COURSE: {
      return {
        ...initialCourseState,
        courses: { ...state.courses }
      };
    }

    case actions.UPDATE_COURSE_TAGS: {
      return {
        ...state,
        courseMutateLoading: true,
        courseMutateError: ''
      };
    }

    case actions.UPDATE_COURSE_TAGS_SUCCESS: {
      const { courseId, tags } = action.payload as actions.UpdateCourseTags;
      const course = state.courses[courseId];
      // * NOTE: it's been triggered from list so no course data
      if (!course) {
        return {
          ...state,
          courseMutateLoading: false,
          courseMutateError: ''
        };
      }

      return {
        ...state,
        courseMutateLoading: false,
        courseMutateError: '',
        courses: {
          ...state.courses,
          [courseId]: mergeMutated(course, {
            tags
          })
        }
      };
    }

    case actions.UPDATE_COURSE_TAGS_FAILURE: {
      const { message } = action.payload as EdErrorResponseType;

      return {
        ...state,
        courseMutateLoading: false,
        courseMutateError: message
      };
    }

    case actions.UPDATE_GROUP_TRAINING: {
      return {
        ...state,
        courseMutateLoading: true,
        courseMutateError: ''
      };
    }

    case actions.UPDATE_GROUP_TRAINING_SUCCESS: {
      const { courseId, isGroupTrainingEnabled } = action.payload;
      const course = state.courses[courseId];

      return {
        ...state,
        courseMutateLoading: false,
        courseMutateError: '',
        courses: {
          ...state.courses,
          [courseId]: mergeMutated(course, {
            isGroupTrainingEnabled
          })
        }
      };
    }

    case actions.UPDATE_GROUP_TRAINING_FAILURE: {
      const { message } = action.payload as EdErrorResponseType;

      return {
        ...state,
        courseMutateLoading: false,
        courseMutateError: message
      };
    }

    default:
      return state;
  }
};
