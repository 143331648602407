import * as React from 'react';
import { Box } from './Box';
import type { ColorsKeys } from '@edapp/themes';
import { rgba } from '@edapp/themes';
import styled, { css } from 'styled-components';
import type { ScrollShadowBoxProps } from './types';
import type { MaxWidthProperty } from 'csstype';

export const ScrollShadowBox = React.forwardRef<HTMLDivElement, ScrollShadowBoxProps>(
  (
    {
      scrollDirection,
      maxScrollSize,
      scrollBackgroundColor = 'white',
      scrollShadowStrength = 0.1,
      shadowSize = 100,
      showScrollBar = false,
      ...props
    },
    ref
  ) =>
    scrollDirection === 'horizontal' ? (
      <HorizontalScrollShadowBox
        {...(props as React.HTMLAttributes<HTMLDivElement>)}
        maxWidth={maxScrollSize}
        ref={ref}
        backgroundColor={scrollBackgroundColor}
        shadowStrength={scrollShadowStrength}
        shadowSize={shadowSize}
        showScrollBar={showScrollBar}
      />
    ) : (
      <VerticalScrollShadowBox
        {...(props as React.HTMLAttributes<HTMLDivElement>)}
        maxHeight={maxScrollSize}
        ref={ref}
        backgroundColor={scrollBackgroundColor}
        shadowStrength={scrollShadowStrength}
        shadowSize={shadowSize}
        showScrollBar={showScrollBar}
      />
    )
);

const HorizontalScrollShadowBox = styled(Box)<{
  maxWidth: MaxWidthProperty<string>;
  backgroundColor: ColorsKeys;
  shadowStrength: number;
  shadowSize: number;
  showScrollBar: boolean;
}>(
  ({ theme, maxWidth, backgroundColor, shadowStrength, shadowSize, showScrollBar }) => css`
    position: relative;
    max-width: ${maxWidth};
    overflow-x: scroll;
    background: linear-gradient(
        0.25turn,
        ${theme.colors[backgroundColor]},
        ${rgba(theme.colors[backgroundColor], 0)} 90%
      ),
      linear-gradient(
        0.75turn,
        ${theme.colors[backgroundColor]},
        ${rgba(theme.colors[backgroundColor], 0)} 90%
      ),
      radial-gradient(
        at left 50%,
        ${rgba(theme.colors.fixedBlack, shadowStrength)},
        ${rgba(theme.colors.fixedBlack, 0)} ${shadowSize}%
      ),
      radial-gradient(
        at right 50%,
        ${rgba(theme.colors.fixedBlack, shadowStrength)},
        ${rgba(theme.colors.fixedBlack, 0)} ${shadowSize}%
      );

    background-repeat: no-repeat;
    background-color: ${theme.colors[backgroundColor]};
    background-size: 60px 100%, 60px 100%, 20px 150%, 20px 150%;
    background-position: left center, right center, left center, right center;
    background-attachment: local, local, scroll, scroll;

    ${!showScrollBar &&
    `// Hide scrollbar
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }`}
  `
);

export const VerticalScrollShadowBox = styled(Box)<{
  maxHeight: MaxWidthProperty<string>;
  backgroundColor: ColorsKeys;
  shadowStrength: number;
  shadowSize: number;
  showScrollBar: boolean;
}>(
  ({ theme, maxHeight, backgroundColor, shadowStrength, shadowSize, showScrollBar }) => css`
    position: relative;
    max-height: ${maxHeight};
    overflow-y: scroll;
    background: linear-gradient(
        ${theme.colors[backgroundColor]} 30%,
        ${rgba(theme.colors[backgroundColor], 0)}
      ),
      linear-gradient(
        ${rgba(theme.colors[backgroundColor], 0)},
        ${theme.colors[backgroundColor]} 70%
      ),
      radial-gradient(
        farthest-side at 50% 0,
        ${rgba(theme.colors.fixedBlack, shadowStrength)},
        ${rgba(theme.colors.fixedBlack, 0)} ${shadowSize}%
      ),
      radial-gradient(
        farthest-side at 50% 100%,
        ${rgba(theme.colors.fixedBlack, shadowStrength)},
        ${rgba(theme.colors.fixedBlack, 0)} ${shadowSize}%
      );

    background-repeat: no-repeat;
    background-color: ${theme.colors[backgroundColor]};
    background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
    background-position: top center, bottom center, top center, bottom center;
    background-attachment: local, local, scroll, scroll;

    ${!showScrollBar &&
    `// Hide scrollbar
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }`}
  `
);
