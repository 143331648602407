export type FileFormatType =
  | 'ai-document'
  | 'briefcase-document'
  | 'aicc'
  | 'audio'
  | 'image'
  | 'scorm'
  | 'video'
  | 'powerpoint'
  | 'csv'
  | 'spreadsheet'
  | 'certificate';

/**
 * takes a string that is either "audio", "video", "image"
 * and gives a list of the supported extensions
 * @param type string
 */
export const supportedFormats = (type: FileFormatType): string[] => {
  switch (type) {
    case 'briefcase-document':
      return [
        'jpg',
        'jpeg',
        'png',
        'gif',
        'svg',
        'pages',
        'numbers',
        'key',
        'doc',
        'docx',
        'ppt',
        'pptx',
        'xls',
        'xlsx',
        'mp4',
        'mov',
        'pdf',
        'mp3',
        'aac',
        'txt'
      ];
    case 'aicc':
      return ['zip'];
    case 'audio':
      return ['mp3'];
    case 'image':
      return ['jpg', 'jpeg', 'png', 'gif', 'svg', 'heic'];
    case 'scorm':
      return ['zip'];
    case 'video':
      return ['mp4', 'mov', 'webm'];
    case 'powerpoint':
      return ['pptx'];
    case 'csv':
      return ['csv'];
    case 'spreadsheet':
      return ['xlsx', 'xlsm'];
    case 'certificate':
      return ['cer', 'crt', 'pem'];
    case 'ai-document':
      return ['pptx', 'docx', 'pdf']
    default:
      throw Error(`Unknown format type ${type}`);
  }
};

/**
 * this formats the extentions the way the accept property of file inputs wants it
 * @param type string
 */
export const supportedFormatsAsString = (type: FileFormatType) =>
  supportedFormats(type)
    .map((f: string) => (f === '*' ? '*' : `.${f}`))
    .join(',');

export const genId = () => `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;

const s4 = () =>
  Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);

const ONE_MB = 1000 * 1000;
const MAX_IMAGE_RECOMMENDED_SIZE = 1.5 * ONE_MB;
const MAX_AUDIO_RECOMMENDED_SIZE = 2 * ONE_MB;
const MAX_VIDEO_RECOMMENDED_SIZE = 10 * ONE_MB;
const MAX_PPTX_RECOMMENDED_SIZE = 100 * ONE_MB;
const MAX_AI_DOCUMENT_SIZE = 100 * ONE_MB;

export const getRecommendedSizeLimit = (type: FileFormatType) => {
  switch (type) {
    case 'audio':
      return MAX_AUDIO_RECOMMENDED_SIZE;
    case 'video':
      return MAX_VIDEO_RECOMMENDED_SIZE;
    case 'image':
      return MAX_IMAGE_RECOMMENDED_SIZE;
    case 'powerpoint':
      return MAX_PPTX_RECOMMENDED_SIZE;
    case 'ai-document':
      return MAX_AI_DOCUMENT_SIZE;
    case 'aicc':
    case 'scorm':
    default:
      return Number.MAX_SAFE_INTEGER;
  }
};
export const getSizeLimit = getRecommendedSizeLimit;

export const MAX_FILE_SIZE = 100 * ONE_MB;
export const MAX_IMAGE_SIZE = 40 * ONE_MB;

export const MAX_FILE_NAME_LENGTH = 125;

export const getMaximumSizeLimit = (type: FileFormatType) => {
  switch (type) {
    case 'video':
      return MAX_FILE_SIZE;
    case 'audio':
    case 'image':
      return MAX_IMAGE_SIZE;
    case 'powerpoint':
    case 'aicc':
    case 'scorm':
    default:
      return Infinity;
  }
};
