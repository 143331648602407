import * as React from 'react';

import { useSelector } from 'react-redux';

import { Box, ButtonLink } from '@edapp/ed-components';
import { getPlanEndDate, getPlanStartDate } from '@rio/store/config/selectors';

const NavProTrialLimits: React.FC = () => {
  const trialStartDate = useSelector(getPlanStartDate);
  const trialEndDate = useSelector(getPlanEndDate);

  if (!(trialStartDate && trialEndDate)) {
    return null;
  }

  return (
    <Box flex={true} flexDirection="column" pb={2} px="sm" textAlign="center">
      <ButtonLink variant="primary" href="/subscribe/upgrade" isFullWidth={true}>
        Buy EdApp Pro now
      </ButtonLink>
    </Box>
  );
};

export default NavProTrialLimits;
