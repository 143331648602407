import * as React from 'react';

import { pluralize } from 'humanize-plus';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import type { SelectableListOption } from '@edapp/ed-components';
import { SelectableList } from '@edapp/ed-components';
import type {
  SearchCourseDetailResponse,
  SearchCourseDetailResponses
} from '@rio/api/user-groups/courses';
import { useGetUserGroupCourseDetails } from '@rio/api/user-groups/courses';
import { setUserGroupItem } from '@rio/store/userGroups/actions';
import {
  getSelectedCourseIds,
  getUserGroupContains,
  getUserGroupId
} from '@rio/store/userGroups/selectors';
import { useDebounce } from '@rio/utils/hooks';
import type { InfiniteData } from '@tanstack/react-query';

import { useSelectableListCounter } from './hooks';

const pageSize = 25;

type OptionValue = string;
type Option = SelectableListOption<OptionValue>;
type Course = {
  course: SearchCourseDetailResponse;
  option: Option;
};
type CoursesType = {
  [courseId: string]: Course;
};

function mapOption({ id, title, linkedCourseIds }: SearchCourseDetailResponse): Option {
  const languageCount = linkedCourseIds.length + 1;

  return {
    key: id,
    value: id,
    label:
      title +
      (languageCount > 1 ? `  ( ${languageCount} ${pluralize(languageCount, 'language')} )` : '')
  };
}

function reduceCourses(
  prevCourses: CoursesType,
  nextCourses: PaginatedResponse<SearchCourseDetailResponse>
): CoursesType {
  return nextCourses.items.reduce<CoursesType>(
    (acc, cur) =>
      !acc[cur.id]
        ? {
            ...acc,
            [cur.id]: {
              course: cur,
              option: mapOption(cur)
            }
          }
        : acc,
    prevCourses
  );
}

function flattenCourses(courses: CoursesType, data?: InfiniteData<SearchCourseDetailResponses>) {
  return data?.pages.reduce<CoursesType>(reduceCourses, courses) ?? courses;
}

export const Courses: React.FC = () => {
  const { t } = useTranslation();
  const userGroupId = useSelector(getUserGroupId);
  const userGroupContains = useSelector(getUserGroupContains);
  const selectedCourseIds = useSelector(getSelectedCourseIds);

  const dispatch = useDispatch();

  const [courses, setCourses] = React.useState<CoursesType>({});

  const [courseTitle, setCourseTitle] = React.useState<string | undefined>(undefined);
  const debouncedCourseTitle = useDebounce(courseTitle, 500);

  const handleSetCourses = (data: InfiniteData<SearchCourseDetailResponses>) => {
    setCourses(prev => flattenCourses(prev, data));
  };

  const unselected = useGetUserGroupCourseDetails(
    userGroupId,
    {
      pageSize,
      unSelected: true,
      courseTitle: debouncedCourseTitle
    },
    {
      staleTime: Infinity,
      onSuccess: handleSetCourses
    }
  );

  const selected = useGetUserGroupCourseDetails(
    userGroupId,
    {
      pageSize,
      unSelected: false,
      courseTitle: debouncedCourseTitle
    },
    {
      staleTime: Infinity,
      onSuccess: handleSetCourses
    }
  );

  const { selectedCount, unselectedCount, handleSelect, handleDeselect } = useSelectableListCounter(
    selected.totalCount,
    unselected.totalCount,
    courseTitle
  );

  const unselectedIsLoading = unselected.isLoading || unselected.isFetchingNextPage;
  const selectedIsLoading = selected.isLoading || selected.isFetchingNextPage;

  const handleChangeCourses = (courses: string[]) => {
    dispatch(setUserGroupItem({ usergroup: { courses } }));
  };

  const handleSelectCourse = (courseId: OptionValue) => {
    const { course, option } = courses[courseId];
    handleChangeCourses([...selectedCourseIds, course.id, ...course.linkedCourseIds]);
    handleSelect(option);
  };

  const handleClearCourse = (courseId: OptionValue) => {
    const { course, option } = courses[courseId];
    handleChangeCourses(
      selectedCourseIds.filter(
        selectedId => selectedId !== course.id && !course.linkedCourseIds.includes(selectedId)
      )
    );
    handleDeselect(option);
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCourseTitle(event.currentTarget.value || undefined);
  };

  const handleClearFilter = () => {
    setCourseTitle(undefined);
  };

  const handleSelectedChange = (courses: string[]) => {
    if (courses.length < pageSize && !selectedIsLoading && selected.hasNextPage) {
      selected.fetchNextPage?.();
    }
  };

  const handleUnselectedChange = (courses: string[]) => {
    if (courses.length < pageSize && !unselectedIsLoading && unselected.hasNextPage) {
      unselected.fetchNextPage?.();
    }
  };

  if (userGroupContains === 'groups') {
    return (
      <div>
        <label>{t('common.courses')}</label>
        <p className="help-block">{t('user-groups.select-courses.user-group-collection-info')}</p>
      </div>
    );
  }

  return (
    <div>
      <label>{t('common.courses')}</label>
      <p className="help-block">{t('user-groups.select-courses.description')}</p>
      <SelectableList<OptionValue>
        options={Object.values(courses).map(({ option }) => option)}
        selectedValues={selectedCourseIds}
        unselectedBox={{
          title: t('user-groups.select-courses.all-courses'),
          showCount: unselectedCount,
          onSelect: handleSelectCourse,
          onChange: handleUnselectedChange,
          onLoadMore: unselected.fetchNextPage
        }}
        selectedBox={{
          title: t('user-groups.select-courses.selected-courses'),
          showCount: selectedCount,
          onClear: handleClearCourse,
          onChange: handleSelectedChange,
          onLoadMore: selected.fetchNextPage
        }}
        filter={{
          placeholder: t('user-groups.select-courses.placeholder'),
          value: courseTitle,
          onChange: handleFilterChange,
          onClear: handleClearFilter
        }}
        loading={unselectedIsLoading || selectedIsLoading}
      />
    </div>
  );
};
