export enum RankingTypeEnum {
  GLOBAL = 'global',
  TEAM = 'team'
}
export type RankingParticipant = {
  participantId: string;
  participantName: string;
  participantAvatarUrl?: string;
  totalScore: number;
  isMe: boolean;
  rank: number;
  totalParticipants: number;
  type: 'individual' | 'group';
  isNotAttempted: boolean;
};

export enum LeaderboardType {
  Group = 'Group',
  Individual = 'Individual',
  RapidRefresher = 'RapidRefresher',
  Course = 'Course'
}
type Colors = {
  background: string;
  experimental: boolean;
  text: string;
  theme: string;
};

export type BaseLeaderboardInstance = {
  leaderBoardInstanceId: string;
  leaderBoardName: string;
  leaderBoardStartDate: string;
  leaderBoardEndDate: string;
  rankings: RankingParticipant[];
  backgroundUrl: string | null;
  colors: Colors | null;
};

export type LeaderboardInstance = BaseLeaderboardInstance &
  (
    | {
        leaderBoardType: LeaderboardType.Group | LeaderboardType.Individual;
        courseId: null;
      }
    | {
        courseId: string;
        leaderBoardType: LeaderboardType.RapidRefresher | LeaderboardType.Course;
      }
  );
